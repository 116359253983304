import { useEffect, useState, useCallback } from 'react';

import { getListOfUsers } from 'services/Users';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { USERS_LIST_HEADERS } from '../helpers/usersListHeaders';
import { usersToRows } from '../helpers/usersToRows';
import { INITIAL_SORT_SELECTION } from '../../../../constants/initialSortSelection';
import { removeUser } from 'services/Users';
import { getFiltersCount, useAvailableFilters } from 'components/Common/Grid';
import { USERS_LIST_FILTER_OPTIONS } from '../../constants/usersListFilterOptions';
import { INIT_PAGINATION_VALUES } from '../../../../DistributorsAccounts/constants/initialPaginationValues';
import { getShowedRows } from '../../../../DistributorsAccounts/helpers/getShowedRows';
import { useAccess } from 'hooks/useAccess';
import { CommonConstants } from 'Constants';
import { isAdmin } from 'services/Auth';
import { useSearchParams } from 'react-router-dom';

const { userPermissions } = CommonConstants;

const loadUsersFromApi = async (setIsLoading, setUsersList, setRecordsTotal) => {
    setIsLoading(true);
    try {
        const userList = await getListOfUsers();
        setUsersList(userList);
        setRecordsTotal(userList.length);
    } catch (error) {
        addErrorNotification('common__error--internal-server');
    } finally {
        setIsLoading(false);
    }
};

const deleteUserFromApi = async (userUid, setIsLoading, setUsersList, setRecordsTotal) => {
    setIsLoading(true);
    try {
        await removeUser({ userUid });
        await loadUsersFromApi(setIsLoading, setUsersList, setRecordsTotal);
        addSuccessNotification('common__success--user-deleted');
    } catch (error) {
        addErrorNotification('common__error--internal-server');
    } finally {
        setIsLoading(false);
    }
};

const ADD_USER_URL = '/settings/users/user';
const isAdminUser = isAdmin();

export const useUsersList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [usersList, setUsersList] = useState([]);
    const [originRows, setOriginRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtersCount, setFiltersCount] = useState(0);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [uuId, setUuid] = useState('');
    const [sortSelection, setSortSelection] = useState(INITIAL_SORT_SELECTION);
    const [filters, setFilters] = useState({});
    const [availableFilters, setAvailableFilters] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);

    const hasManageUserAccess = useAccess(userPermissions.ManageUser);
    const hasCreateUserAccess = hasManageUserAccess && !isAdminUser;

    const [searchParams] = useSearchParams();
    const accOwnerUID = searchParams.get('uuid');

    useEffect(() => {
        loadUsersFromApi(setIsLoading, setUsersList, setRecordsTotal);
    }, [setIsLoading, setUsersList, setRecordsTotal]);

    const onConfirmModalOpen = useCallback((userID) => {
        setUuid(userID);
        setConfirmModalOpen(true);
    }, [setUuid, setConfirmModalOpen]);

    useEffect(() => {
        const newRows = usersToRows({ users: usersList, onConfirmModalOpen, hasManageUserAccess, accOwnerUID });
        setOriginRows(newRows);
    }, [usersList, setOriginRows, onConfirmModalOpen]);

    useEffect(() => {
        const newFiltersCount = getFiltersCount(availableFilters, filters);
        setFiltersCount(newFiltersCount);
    }, [availableFilters, filters, setFiltersCount]);

    useEffect(() => {
        if (originRows.length) {
            const { total, newShowedRows } = getShowedRows(
                    originRows,
                    {
                        ...{},
                        filters,
                        sortSelection,
                        paginationData,
                        filtersOptions: USERS_LIST_FILTER_OPTIONS
                    }
            );
            setRows(newShowedRows);
            setRecordsTotal(total);
        }
    }, [originRows, filters, sortSelection, paginationData, setRows, setRecordsTotal]);

    const onFiltersReset = useCallback(() => {
        setFiltersCount(0);
        const newRows = usersToRows({ users: usersList, onConfirmModalOpen, hasManageUserAccess, accOwnerUID });
        setOriginRows(newRows);
    }, [setFiltersCount, setOriginRows, usersList, onConfirmModalOpen, hasManageUserAccess]);

    const onSortChange = useCallback((newSorting) => {
        if (isLoading) {
            return;
        }
        setSortSelection(newSorting);
    }, [isLoading, setSortSelection]);

    const onCancelRemoveUser = useCallback(() => {
        setUuid('');
        setConfirmModalOpen(false);
    }, [setUuid, setConfirmModalOpen]);

    const onConfirmRemoveUser = useCallback(() => {
        onCancelRemoveUser();
        deleteUserFromApi(uuId, setIsLoading, setUsersList, setRecordsTotal);
    }, [uuId, setIsLoading, onCancelRemoveUser, setUsersList, setRecordsTotal]);

    const onFiltersChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, [setFilters]);

    useAvailableFilters(USERS_LIST_FILTER_OPTIONS, originRows, onFiltersChange, setAvailableFilters);

    const onPaginateChange = useCallback(({ page, recordsPerPage }) => {
        const newPaginationData = { pageSize: recordsPerPage, pageNumber: page };
        setPaginationData(newPaginationData);
    }, [setPaginationData]);

    const onFiltersAndSortReset = useCallback(() => {
        onFiltersReset();
        setSortSelection(INITIAL_SORT_SELECTION);
    }, [onFiltersReset, setSortSelection]);

    return {
        isLoading,
        rows,
        hasCreateUserAccess,
        addUserUrl: ADD_USER_URL,
        headers: USERS_LIST_HEADERS,
        filtersCount,
        onFiltersReset,
        sortSelection,
        onSortChange,
        isConfirmModalOpen,
        onCancelRemoveUser,
        onConfirmRemoveUser,
        filters,
        availableFilters,
        onFiltersChange,
        recordsTotal,
        paginationData,
        onPaginateChange,
        filtersOptions: USERS_LIST_FILTER_OPTIONS,
        onFiltersAndSortReset
    };
};
