import i18next from 'i18next';

import api from './api';

const basePath = 'func-render-pdf';

const pdfApi = {
    downloadAwardTrophyCase(data) {
        return api.request({
            url: basePath,
            method: 'POST',
            headers: { 'Content-Language': i18next.resolvedLanguage },
            responseType: 'blob',
            data: { data, template: 'trophy-case-results' }
        });
    }
};

export default pdfApi;
