import React from 'react';
import { useTranslation } from 'react-i18next';

import { SortingItem } from '../SortingItem';

import './ExpandableSortingItem.scss';

export const ExpandableSortingItem = ({ label, options, sortingItemProps = {} }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-exapdable-sorting-item">
            <div className="hme-exapdable-sorting-item__header">
                <span className="hme-exapdable-sorting-item__title">{t(label)}</span>
            </div>

            <div className="hme-exapdable-sorting-item__options-category-list">
                {options.map((option) => {
                    const { label: optionLabel, value } = option;

                    return <SortingItem
                        key={optionLabel}
                        label={optionLabel}
                        value={value}
                        {...sortingItemProps}
                    />;
                })}
            </div>
        </div>
    );
};
