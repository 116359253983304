import { sendMessage, fetchMessages, fetchGroupedStores } from 'services/TextConnect';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';

import { TOAST_OPTIONS } from './constants';

export const processSendMessage = async ({ payload, setIsLoading }) => {
    setIsLoading(true);

    try {
        await sendMessage(payload);

        setTimeout(() => {
            addSuccessNotification('text-n-connect__send-message--message-successfully-sent', TOAST_OPTIONS);
        }, 0);
    } catch (err) {
        addErrorNotification(err.message, TOAST_OPTIONS);
    }

    setIsLoading(false);
};

export const getMessages = async (params) => {
    try {
        return await fetchMessages(params);
    } catch (err) {
        addErrorNotification(err.message, TOAST_OPTIONS);
    }
};

export const getGroupedStores = async () => {
    try {
        return await fetchGroupedStores();
    } catch (err) {
        addErrorNotification(err.message, TOAST_OPTIONS);
    }
};
