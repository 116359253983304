// Following labels are not to be translated
export const SSOConfigLabels = {
    SSO_TARGET_URL: 'SSO TARGET URL',
    X509: 'X509 CERTIFICATE',
    LOGOUT_REDIRECT: 'LOGOUT REDIRECT URL',
    ASSERTION_URL: 'HME ASSERTION URL',
    ENTITY_ID: 'HME ENTITY ID'
};
export const SSOType = [
    {
        text: 'settings_sso_configuration_SAML',
        value: 'SAML'
    },
    {
        text: 'settings_sso_configuration_OAUTH2',
        value: 'OAUTH2'
    }
];
export const SSOTestStatus = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    UNREACHABLE: 'unreachable'
}

export const SSO_CERT_LENGTH = 18;

export const HME_SETTINGS = {
    assertionUrl: 'https://sso-auth.hmecloud.com/api/sso-callback',
    entityID: 'hmecloud.com'
};

export const SSO_AUDIT_ACTIONS = {
    FAILED_TEST: 'Failed Test',
    SUCCESSFUL_TEST: 'Successful Test'
};