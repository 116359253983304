import React from 'react';

import { InfoField } from 'library/InfoField';

export const Account = ({ task }) => (
    <>
        <InfoField label='Search By'>
            Account
        </InfoField>
        <InfoField label='Account Email Address'>
            {task.AdditinionalParams.searchParams.accountUserEmail}
        </InfoField>
    </>
);
