/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import './ViewDetails.css';
import SystemSettings from '../components/Stores/SystemSettings';
import SystemStatus from '../components/Stores/SystemStatus';
import RemoteSystemActions from '../components/Stores/RemoteSystemActions';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';
import { getStoreDetails } from '../actions/viewDetails';
import AuthenticationService from '../components/Security/AuthenticationService';
import { isDistributor } from 'services/Auth';
import CommonLoader from '../components/Alerts/CommonLoader';
import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { checkAccess, getPermission } from '../actions/permissions';
import { bindActionCreators } from 'redux';
import { get } from 'lodash/fp';
import { scheduledUpgradesApi } from '../api/scheduledUpgradesApi';
import PropTypes from 'prop-types';
import { withReactRouter } from 'HOCs/withReactRouter';

const {
    deviceType: {
        nexeo: {
            id: nexeoDeviceTypeId
        } = {}
    } = {}
} = CommonConstants;

class ViewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommonLoader: true,
            deviceUid: '',
            scheduledUpgrade: null
        };
        // Initilizing the services.
        this.authService = new AuthenticationService(Config.authBaseUrl);
        // Binding the methods
        this.displayRemoteSystemActions = this.displayRemoteSystemActions.bind(this);

        props.getPermission();
    }

    async UNSAFE_componentWillMount() {
        const { queryParams } = this.props;
        const deviceUid = queryParams.get('duid');
        this.setState({
            deviceUid,
            showCommonLoader: true
        });
        // TODO: add error handler-may fails and no sense to send next request
        const scheduledUpgrade = await scheduledUpgradesApi.getLatestScheduledUpgradeByDeviceUID({ deviceUID: deviceUid });
        if ( scheduledUpgrade ) {
            this.setState({
                scheduledUpgrade
            });
        }
        console.log(`scheduledUpgrade: `, scheduledUpgrade);
        await this.props.getStoreDetails(deviceUid, () => {
            this.setState({ showCommonLoader: false });
        });
    }

    get isLinkWearDevice() {
        const deviceTypeId = get(['storeViewDetails', 'systemStatus', 0, 'Device_DeviceType_ID'], this.props);

        return deviceTypeId == CommonConstants.deviceType.linkWear.id;
    }

    get isEVDDevice() {
        const deviceTypeId = get(['storeViewDetails', 'systemStatus', 0, 'Device_DeviceType_ID'], this.props);

        return deviceTypeId == CommonConstants.deviceType.evd.id;
    }

    displayRemoteSystemActions(systemStatus = {}) {
        const {
            storeViewDetails
        } = this.props;
        const {
            deviceUid,
            scheduledUpgrade
        } = this.state;
        // Checking is the device is online.
        const {
            Device_IsActive: isActive,
            Device_DeviceType_ID: deviceTypeId
        } = systemStatus;
        const {
            userPermissions: {
                PerformDeviceRestart: performDeviceRestart
            } = {},
            externalPermissions,
            deviceType: {
                linkWear: {
                    id: linkWearId
                }
            }
        } = CommonConstants;

        // Check for the reboot permission for the user or is the logged in user is admin.
        const accessPerformed = checkAccess(performDeviceRestart)
            || (isDistributor() && checkAccess(externalPermissions.ManageDevicesBasic));

        if (!isActive || !accessPerformed || this.isLinkWearDevice) {
            return;
        }

        return (
            <RemoteSystemActions
                systemStatus={systemStatus}
                systemActions={storeViewDetails}
                deviceUid={deviceUid}
                scheduledUpgrade={scheduledUpgrade}
            />
        );
    }
    render() {
        const {
            storeViewDetails: {
                systemStatus: [
                    {
                        Device_Name: deviceName,
                        Device_DeviceType_ID: deviceTypeID
                    } = {}
                ] = []
            } = {},
            storeViewDetails: {
                systemStatus: [systemStatus] = []
            } = {},
            storeViewDetails,
            i18n,
            t
        } = this.props;
        const {
            showCommonLoader,
        } = this.state;
        const {
            userPermissions: {
                RemoteConnect: remoteConnect
            } = {}
        } = CommonConstants;
        const userPermissions = checkAccess(remoteConnect);
        const isNexeo = deviceTypeID === nexeoDeviceTypeId;

        const showSystemSettings = i18n.resolvedLanguage === 'en-US' && deviceName && deviceName !== 'CIB' && !isNexeo && !this.isLinkWearDevice;
        if (!storeViewDetails) {
            return (
                <div>{t('common__loading')}</div>
            );
        }

        return (<div>
            <CommonLoader showLoader={showCommonLoader} message="common__loading" />
            <div className={'viewDetailsSection ' + (showCommonLoader ? 'hide' : '')}>
                <div className="device_details row">
                    <div className="col-sm-12 col-md-6 col-lg-5" >
                        <SystemStatus data={storeViewDetails} scheduledUpgrade={this.state.scheduledUpgrade} userPermissions={userPermissions} />
                        {this.displayRemoteSystemActions(systemStatus)}
                    </div>
                    {
                        !this.isEVDDevice &&
                        <div className="col-sm-12 col-md-6 col-lg-7">
                        {
                            showSystemSettings && <SystemSettings data={storeViewDetails} />
                        }
                        </div>
                    }
                </div>
            </div>
        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        storeViewDetails: state.viewDetails.storeViewDetails
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                getPermission: getPermission,
                getStoreDetails: getStoreDetails
            }, dispatch
    );
}

ViewDetails.propTypes = {
    queryParams: PropTypes.object
};

export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter
)(ViewDetails);
