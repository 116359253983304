/**
 * LearnMore React Component for the Learn More button
 * in the Welcome Tile
 */
import React from "react";
import { Config } from "../../../../Config";
import AuthenticationService from "../../../Security/AuthenticationService";
import NavigationServices from "../../../Common/NavigationServices";

class LearnMore extends React.Component {
  constructor(props) {
    super(props);
    this.authService = new AuthenticationService(Config.authBaseUrl);
    this.navigation = new NavigationServices();
  }

  render() {
    return <div />;
  }
}

export default LearnMore;
