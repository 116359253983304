import React from 'react';
import { cond, T } from 'ramda';

import { taskConfig } from 'constants/applyStoreSettingsTask'
import { Section } from 'library/Section';
import { DownloadedDevices } from './DownloadedDevices';
import { UploadedDevices } from './UploadedDevices';

const downloadedStatuses = [taskConfig.statuses.WAITING_FOT_UPLOAD, taskConfig.statuses.CANCELED];

const DevicesList = cond([
    [({ task }) => downloadedStatuses.includes(task.Task_Status), DownloadedDevices],
    [T, UploadedDevices]
]);

export const DevicesSection = ({ task, countries, onDownloadLinkClick }) => {
    return (
        <Section number={2} className='hme-view-store-settings-task-info-devices'>
            <DevicesList task={task} countries={countries} onDownloadLinkClick={onDownloadLinkClick}/>
        </Section>
    );
};
