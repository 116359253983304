/* eslint-disable react/prop-types */
import React from 'react';

import Layout from 'components/Common/Layout';

import './HMELayout.scss';
import classNames from 'classnames';

// Why?
export const HMELayout = ({ children, variants = [], ...props }) => (
    <div className={classNames('hme-layout', variants.map((variant) => `hme-layout-${variant}`))}>
        <Layout Params={props}>{ children }</Layout>
    </div>
);
