import { fetchAnnouncementSettings, postAnnouncementSettings } from 'services/AnnouncementSettings';

import { selectNotificationFrequency } from './AnnouncementDurationSettings/selectors';
import { notificationDurationTypes, timeLabelTranslations } from './constants';

export const getAnnouncementSettings = async () => {
    const { settings, durationUnits } = await fetchAnnouncementSettings();

    const settingsMap = settings.reduce((mapAccum, { SettingType, ...setting }) => {
        mapAccum[SettingType] = {
            ...setting,
            SettingType,
            DisplayIntervalUnit: {
                text: timeLabelTranslations.get(setting.DisplayIntervalUnit.toLowerCase()),
                value: setting.DisplayIntervalUnit
            },
            DisplayDurationUnit: {
                text: timeLabelTranslations.get(setting.DisplayDurationUnit.toLowerCase()),
                value: setting.DisplayDurationUnit
            },
            DisplayPeriodUnit: {
                text: timeLabelTranslations.get(setting.DisplayPeriodUnit.toLowerCase()),
                value: setting.DisplayPeriodUnit
            },
            error: undefined
        };

        return mapAccum;
    }, {});

    const durationUnitsOptions = durationUnits.map((item = '') => ({
        text: timeLabelTranslations.get(item.toLowerCase()),
        value: item
    }));

    return {
        settings: settingsMap,
        durationUnits: durationUnitsOptions
    };
};

export const saveAnnouncementSettings = ({ settings }) => {
    const apiModels = settings.map((item) => {
        const { DisplayPeriodUnit: periodUnit, DisplayIntervalUnit: intervalUnit, DisplayDurationUnit: durationUnit } = item;
        let apiModel = {
            ...item,
            DisplayPeriodUnit: periodUnit.value,
            DisplayIntervalUnit: intervalUnit.value,
            DisplayDurationUnit: durationUnit.value
        };

        // set to notifications notification frequency values
        const { DisplayDurationUnit, DisplayIntervalUnit, DisplayDuration, DisplayInterval } = selectNotificationFrequency(settings);
        if (notificationDurationTypes.includes(item.SettingType)) {
            apiModel = {
                ...apiModel,
                DisplayDurationUnit: DisplayDurationUnit.value,
                DisplayIntervalUnit: DisplayIntervalUnit.value,
                DisplayInterval,
                DisplayDuration
            };
        }

        return apiModel;
    });

    return postAnnouncementSettings(apiModels);
};
