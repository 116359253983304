import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tip } from 'library/Tip';
import { Grid } from 'components/Common/Grid';
import { DeviceUpgradeStatuses, DeviceUpgradeStatusesMap } from 'constants/NEXEOUpgradeStatuses';
import { DateLib } from '@hme-cloud/utility-common';

import { DeviceUpgradeInfo } from './DeviceUpgradeInfo/DeviceUpgradeInfo';
import { DeviceUpgradeStatusMessage } from '../../../Common/DeviceUpgradeStatusMessage/DeviceUpgradeStatusMessage';

import './DeviceUpgradeStatus.scss';

const DeviceUpgradeStatusesText = {
    CREATED: 'admin-system-status__upgrade-status--created',
    SENT: 'admin-system-status__upgrade-status--sent',
    DOWNLOAD_FIRMWARE_STARTED: 'admin-system-status__upgrade-status--downloading',
    DOWNLOAD_FIRMWARE_COMPLETED: 'admin-system-status__upgrade-status--downloaded',
    FIRMWARE_UPDATE_STARTED: 'admin-system-status__upgrade-status--installing',
    FIRMWARE_UPDATE_COMPLETED: 'admin-system-status__upgrade-status--installed',
    UPDATE_FAILED: 'admin-system-status__upgrade-status--failed',
};

const gridOptions = [
    {
        text: 'common__status',
        property: 'status',
        flex: 29,
        className: 'hme-grid-cell-show-all-content status-cell',
    },
    {
        text: 'common__done--percent',
        property: 'progress',
        flex: 20,
        headerClassName: 'hme-grid-right-aligned-cell progress-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-right-aligned-cell progress-cell',
    },
    {
        text: 'common__date-time',
        property: 'dateTime',
        flex: 51,
        className: 'hme-grid-cell-show-all-content hme-grid-right-aligned-cell',
    },
];

const getDateTime = (task, statusKey, serialNumber, t) => {
    const status = task.statuses[serialNumber]?.[statusKey] || task.statuses[statusKey];
    return status
        ? new DateLib(status.lastUpdatedAt).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME)
        : t('common__na');
};    

const formatStatusesRows = ({ task, serialNumber, t }) => {
    const isDownloadFailed =
        task.status === DeviceUpgradeStatuses.UPDATE_FAILED &&
        !task.statuses[DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED];

    const isInstallFailed =
        task.status === DeviceUpgradeStatuses.UPDATE_FAILED &&
        !task.statuses[DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED];

    const rowsMap = {};
    const statusKeys = Object.keys(DeviceUpgradeStatusesMap).map((status) => +status);

    // format payload for every status before rendering
    statusKeys.forEach((statusKey) => {
        if (statusKey === DeviceUpgradeStatuses.SENT && (task.statuses[statusKey] || task.statuses[serialNumber]?.[statusKey])) {
            rowsMap[DeviceUpgradeStatuses.SENT] = {
                status: t(DeviceUpgradeStatusesText[DeviceUpgradeStatusesMap[statusKey]]),
                progress: '100%',
                dateTime: getDateTime(task, statusKey, serialNumber, t),
            };
        }

        if (
            statusKey === DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_STARTED &&
            (task.statuses[statusKey] || task.statuses[serialNumber]?.[statusKey]) &&
            (task.status === DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_STARTED || isDownloadFailed)
        ) {
            rowsMap[DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_STARTED] = {
                status: !isDownloadFailed
                    ? t(DeviceUpgradeStatusesText[DeviceUpgradeStatusesMap[statusKey]])
                    : t(DeviceUpgradeStatusesText[
                        DeviceUpgradeStatusesMap[DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED]
                    ]),
                progress: `${task.downloadPercentage}%`,
                dateTime: getDateTime(task, statusKey, serialNumber, t),
            };
        }

        if (
            statusKey === DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED &&
            !rowsMap[DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_STARTED]
        ) {
            rowsMap[DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED] = {
                rowClassName: !task.statuses[statusKey] ? 'status-not-applicable' : '',
                status: t(DeviceUpgradeStatusesText[DeviceUpgradeStatusesMap[statusKey]]),
                progress: task.statuses[statusKey] ? `${task.downloadPercentage}%` : t('common__na'),
                dateTime: getDateTime(task, statusKey, serialNumber, t),
            };
        }

        // show installation statuses
        if (
            statusKey === DeviceUpgradeStatuses.FIRMWARE_UPDATE_STARTED &&
            (task.statuses[statusKey] || task.statuses[serialNumber]?.[statusKey]) &&
            (task.status === DeviceUpgradeStatuses.FIRMWARE_UPDATE_STARTED || isInstallFailed)
        ) {
            rowsMap[DeviceUpgradeStatuses.FIRMWARE_UPDATE_STARTED] = {
                status: !isInstallFailed
                    ? t(DeviceUpgradeStatusesText[DeviceUpgradeStatusesMap[statusKey]])
                    : t(DeviceUpgradeStatusesText[
                        DeviceUpgradeStatusesMap[DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED]
                    ]),
                progress: `${task.installPercentage}%`,
                dateTime: getDateTime(task, statusKey, serialNumber, t),
            };
        }

        if (
            statusKey === DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED &&
            !rowsMap[DeviceUpgradeStatuses.FIRMWARE_UPDATE_STARTED]
        ) {
            rowsMap[DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED] = {
                rowClassName: !(task.statuses[statusKey] || task.statuses[serialNumber]?.[statusKey])
                    ? 'status-not-applicable'
                    : '',
                status: t(DeviceUpgradeStatusesText[DeviceUpgradeStatusesMap[statusKey]]),
                progress: (task.statuses[statusKey] || task.statuses[serialNumber]?.[statusKey]) ? `${task.installPercentage}%` : t('common__na'),
                dateTime: getDateTime(task, statusKey, serialNumber, t),
            };
        }

        if (
            statusKey === DeviceUpgradeStatuses.UPDATE_FAILED &&
            (task.statuses[statusKey] || task.statuses[serialNumber]?.[statusKey]) &&
            (isDownloadFailed || isInstallFailed)
        ) {
            rowsMap[DeviceUpgradeStatuses.UPDATE_FAILED] = {
                rowClassName: 'status-failed',
                status: t(DeviceUpgradeStatusesText[DeviceUpgradeStatusesMap[statusKey]]),
                progress: '',
                dateTime: getDateTime(task, statusKey, serialNumber, t),
            };
        }
    });

    return Object.keys(rowsMap).map((key) => rowsMap[key]);
};

export const DeviceUpgradeStatus = ({ title, task, serialNumber }) => {
    const { t } = useTranslation();
    const rows = task ? formatStatusesRows({ task, serialNumber, t }) : [];
    const { createdBy = '-', upgradeVersion = '-' } = task || {};

    return (
        <>
            <Tip
                placement="bottom"
                title={t(title)}
                withCloseButton
                iconClassName="icon-clock"
                className="device-upgrade-tooltip"
            >
                <DeviceUpgradeInfo createdBy={createdBy} upgradeVersion={upgradeVersion} />
                <Grid rows={rows} isLoading={false} headers={gridOptions} />
                <DeviceUpgradeStatusMessage task={task} serialNumber={serialNumber} />
            </Tip>
        </>
    );
};
