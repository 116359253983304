import React, { useEffect, useState } from 'react';
import { compose } from 'ramda';
import { Grid } from 'components/Common/Grid';
import { useTranslation } from 'react-i18next';
import { deleteSchedules, getAllSchedules } from 'api/pbiReports';
import CommonLoader from 'components/Alerts/CommonLoader';
import timeZoneApi from 'api/timeZoneApi';
import { Link } from '../Common/Link/Link';
import { ConfirmPopupComponent } from '../../library/ConfirmPopup/ConfirmPopup.js';
import { useNavigate, useLocation } from 'react-router-dom';
import withAccountEmail from 'HOCs/withAccountEmail';
import { withHMELayout } from 'HOCs/withHMELayout';
import { addSuccessNotification } from 'services/Notifications';
import { NotificationsList } from 'library/NotificationsList';
import { useCheckAccount } from 'hooks/useCheckAccount';
import AccountErrorMessage from 'components/Common/AccountErrorMessage';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { sortTimeZones } from 'helpers/sortTimeZones';
import { ReportsHeader } from 'components/Header/ReportsHeader';
import { TileList } from 'library/TileList';
import { useGlobalState } from 'globalState';
import { MOBILE_SCHEDULE_HEADERS } from './Constants';

import { ReactComponent as Pencil } from 'assets/icons/IconEditBlue.svg';

import './PbiScheduler.scss';

const PbiScheduler = () => {
    const { t } = useTranslation();
    const { screenType } = useGlobalState();
    const [schedules, setSchedules] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [showCommonLoader, setShowCommonLoader] = useState(true);
    const [isEnabled, setIsEnabled] = useState(true);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [removedID, setRemovedID] = useState(null);
    const navigate = useNavigate();
    const { search } = useLocation();
    const [detailsChecked, setDetailsChecked] = useState({
        noStores: false,
        noPermissions: false,
        accountError: false,
        checked: false
    });
    const {
        noStores,
        noPermissions,
        accountError
    } = detailsChecked;

    const isAdmin = baseAuthService.isAdmin();
    const userEmail = isAdmin ? baseAuthService.getUserEmail() : baseAuthService.getLoggedInUserEmail();
    const getSchedulerInfo = async () => {
        const { recordset } = await getAllSchedules({ userEmail });
        const timeZonesData = await timeZoneApi.getAll();
        /*
        omitting first timezone named "(GMT) Coordinated Universal Time"
        as it doesn't include timezone code i.e. +0 or -0
        */
        const [invalidTZ, ...validTZ] = timeZonesData;
        setTimeZones(sortTimeZones(validTZ));
        setShowCommonLoader(false);
        setSchedules(recordset);
        if (search) {
            const status = search.split('=')[1];
            if (status === 'created') {
                addSuccessNotification(t('scheduler__notification-created-success'), { autoClose: 3000 });
            }
            if (status === 'updated') {
                addSuccessNotification(t('scheduler__notification-updated-success'), { autoClose: 3000 });
            }
        }
        console.log('render');
    };
    // check account and get scheduler info
    useCheckAccount(getSchedulerInfo, setShowCommonLoader, setDetailsChecked, detailsChecked);
    useEffect(() => {
        if (schedules.length > 9) {
            setIsEnabled(false);
        } else {
            setIsEnabled(true);
        }
    }, [schedules]);
    const onHandleRemove = (id) => {
        setRemovedID(id);
        setShowCancelModal(true);
        if (search) {
            navigate('/new-reports-scheduler/');
        }
    };
    const onHandleConfirm = async (id) => {
        await deleteSchedules({ id });
        addSuccessNotification(t('scheduler__remove-scheduled-report'), { autoClose: 3000 });
        const schedulesFiltered = schedules.filter((schedule) => schedule.id !== id);
        setSchedules(schedulesFiltered);
        setShowCancelModal(false);
        setRemovedID(null);
    };
    const onHandleHide = () => {
        setShowCancelModal(false);
        setRemovedID(null);
    };
    const RECURRENCE_VALUES = {
        MONTHLY: 0,
        WEEKLY: 1,
        DAILY: 2
    };
    const weekDays = [t('common__sunday'), t('common__monday'), t('common__tuesday'), t('common__wednesday'),
        t('common__thursday'), t('common__friday'), t('common__saturday')];
    const monthDays = [
        t('scheduler__monthly-01'),
        t('scheduler__monthly-02'),
        t('scheduler__monthly-03'),
        t('scheduler__monthly-04'),
        t('scheduler__monthly-05'),
        t('scheduler__monthly-06'),
        t('scheduler__monthly-07'),
        t('scheduler__monthly-08'),
        t('scheduler__monthly-09'),
        t('scheduler__monthly-10')
    ];
    const headers = [{
        text: <span>{t('scheduler__name')}</span>,
        property: 'schedule_name',
        sortable: false
    }, {
        text: <span>{t('scheduler__mails')}</span>,
        property: 'schedule_mails',
        sortable: false
    }, {
        text: <span>{t('scheduler__subject')}</span>,
        property: 'schedule_subject',
        sortable: false
    }, {
        text: <span>{t('common__type')}</span>,
        property: 'schedule_type',
        sortable: false
    }, {
        text: <span>{t('scheduler__recurrence')}</span>,
        property: 'schedule_recurrence',
        sortable: false
    }, {
        text: <span>{t('common__timezone')}</span>,
        property: 'schedule_timezone',
        sortable: false
    }, {
        text: <span>{t('common__format')}</span>,
        property: 'schedule_format',
        sortable: false
    }, {
        text: <span className="header-align">{t('common__actions')}</span>,
        property: 'schedule_actions',
        sortable: false
    }];

    const getDayOfWeek = (recurrence, index) => {
        const source = recurrence === RECURRENCE_VALUES.MONTHLY ? monthDays : weekDays;
        return source[index];
    };

    const viewOrEditScheduled = (id) => {
        const editUrl = isAdmin ? `/new-reports-scheduler/edit?id=${id}&uuid=${baseAuthService.getUUID()}` :
        `/new-reports-scheduler/edit?id=${id}`;
        navigate(editUrl);
    };
    const recurrences = [
        t('scheduler__recurrence-monthly'),
        t('scheduler__recurrence-weekly'),
        t('scheduler__recurrence-daily')
    ];
    const tableRows = schedules.map((schedule, index) => {
        let { frequency, day_of_month, day_of_week, timezone_code, timezone_index, id, format, report_type: reportType } = schedule;
        frequency = parseInt(frequency);
        const recurrence = recurrences[frequency];
        const actions = (
            <span className="scheduled-actions">
                <button className="action-btn" onClick={() => viewOrEditScheduled(id)}>
                    <Pencil className="scheduled-actions__edit-icon" />
                    {t('common__view-edit')}
                </button>
                <span className="separator">|</span>
                <button className="action-btn" onClick={() => onHandleRemove(id)}>
                    {t('common__remove')}
                </button>
            </span>
        );
        const mails = (
            <span className="clean-padding">
                {schedule.mails}
            </span>
        );
        const subject = (
            <span className="clean-padding">
                {schedule.subject}
            </span>
        );
        const timeZoneName = timeZones[timezone_index].Name;
        const timezone = (
            <span>
                {timeZoneName}
            </span>
        );
        reportType = reportType === 'trends'? 'Trends' : 'Summary';
        if (frequency === RECURRENCE_VALUES.MONTHLY) {
            frequency = getDayOfWeek(RECURRENCE_VALUES.MONTHLY, parseInt(day_of_month) - 1);
        } else if (frequency === RECURRENCE_VALUES.WEEKLY) {
            frequency = getDayOfWeek(RECURRENCE_VALUES.WEEKLY, parseInt(day_of_week));
        } else {
            frequency = '';
        }
        const columnMappings = {
            'schedule_name': schedule.name,
            'schedule_mails': mails,
            'schedule_subject': subject,
            'schedule_recurrence': recurrence,
            'schedule_type': reportType,
            'schedule_timezone': timezone,
            'schedule_format': format,
            'schedule_actions': actions,
            'Actions': actions
        };
        return columnMappings;
    });

    const cancelMessageWarning = (
        <div className="text-dark text-lg modal-message">
            <p>
                {t('scheduler__modal-message')}
                <br />
                {t('scheduler__modal-message-confirmation')}
            </p>
        </div>
    );
    return (
        <>
            {accountError &&
                <div className="noStores">
                    <AccountErrorMessage {...{
                        noStores,
                        noPermissions,
                        t
                    }} />
                </div>
            }{!accountError && (<>
                <CommonLoader showLoader={showCommonLoader} message={'common__loading'} />
                <NotificationsList />
                {!showCommonLoader && (<>
                    <div>
                        <ConfirmPopupComponent
                            show={showCancelModal}
                            message={cancelMessageWarning}
                            onConfirm={() => {
                                onHandleConfirm(removedID);
                            }}
                            confirmationVerb={t('common__continue')}
                            onHide={onHandleHide}
                            title={t('common__double-checking')}
                        />
                    </div>
                    <div className="top-container">
                        <ReportsHeader isAdmin={isAdmin} />
                        {
                            !isAdmin && <Link
                                to="/new-reports-scheduler/create"
                                className={
                                    `list-view-btn-grey button-shape scheduler-page__create-btn ${!isEnabled ? 'btn-disabled' : 'create-btn'}`
                                }
                                label={t('scheduler__btn-create')}
                                primary
                                button
                                disabled={!isEnabled}
                            />
                        }
                    </div>
                    <div className="scheduler-container scheduler-container--indented">
                        <div className="mt-3">
                            {
                                screenType.isDesktop ? (
                                    <Grid rows={tableRows} headers={headers} showOverflow={false} />
                                ) : (
                                    <TileList rows={tableRows} headers={MOBILE_SCHEDULE_HEADERS} />
                                )
                            }
                        </div>
                    </div>
                </>)}
            </>)}
        </>
    );
};

export default compose(
        withHMELayout({
            contentClasses: ['scheduler-page']
        }),
        withAccountEmail
)(PbiScheduler, { ownerClassName: 'scheduler-list-page__owner-email-wrapper' });
