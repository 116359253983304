import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Title } from 'library/Title';
import { Accordion } from 'library/Accordion';
import { InfoField as LibraryInfoField } from 'library/InfoField';

import { history as histories, historyField } from '../types';
import { mapToConfigurationHistory } from '../helpers';

import './ConfigurationHistory.scss';

const InfoField = ({ label, isChanged, value, isFullWidth, ...props }) => {
    const { t } = useTranslation();

    const labelBox = (
        <>
            {t(label)}
            {isChanged && (
                <span className="configuration-history__info-field--changed">
                    {' '}
                    - <strong>{t('vaio-provider__configuration-history__changed')}</strong>
                </span>
            )}
        </>
    );

    return (
        <LibraryInfoField
            className={classNames('configuration-history__info-field', {
                'configuration-history__info-field--full-width': isFullWidth
            })}
            label={labelBox}
            key={label}
            {...props}
        >
            {value}
        </LibraryInfoField>
    );
};

InfoField.propTypes = historyField;

export const ConfigurationHistory = ({ histories = [], hiddenRows = [] }) => {
    const { t } = useTranslation();

    const formattedConfigurationHistory = useMemo(() => {
        return histories?.map((history) => mapToConfigurationHistory(history, t, hiddenRows));
    }, [histories, t]);

    const formatDateTitle = useCallback((changedAt) => `${t('common__date')} ${changedAt}`, [t]);

    return (
        <div className="configuration-history">
            {formattedConfigurationHistory.map(({ data = [], changedAt = '' }, idx) => (
                <Accordion isExpanded={false} title={formatDateTitle(changedAt)} className="configuration-history__accordion" key={idx}>
                    {data.map(({ title = '', fields = [] }) => {
                        return (
                            <div className="configuration-history__item" key={title}>
                                <Title className="configuration-history__item-title">{t(title)}</Title>

                                <div className="configuration-history__item-content">
                                    {fields.map(({ label = '', value = '', isChanged = false, isFullWidth }) => {
                                        return <InfoField
                                            label={label}
                                            value={value}
                                            isChanged={isChanged}
                                            isFullWidth={isFullWidth}
                                            key={label}
                                        />;
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </Accordion>
            ))}
        </div>
    );
};

ConfigurationHistory.propTypes = {
    histories
};
