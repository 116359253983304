import { useMemo } from 'react';

import { useAccess } from 'hooks/useAccess';
import { isDistributor } from 'services/Auth';
import { CommonConstants } from 'Constants';
import { navigationService } from 'components/Common/NavigationServices';

const isDistributorUser = isDistributor();
const { deviceType, userPermissions } = CommonConstants;

const unconnectableDevices = [deviceType.linkWear.name, deviceType.evd.name];

export const useConnectableDevice = ({ isActive, isPreconfigured, deviceName, deviceUserID, deviceVersion }) => {
    const hasDistributorManageDeviceBasic = isDistributorUser && useAccess(CommonConstants.externalPermissions.ManageDevicesBasic);
    const hasRemoteConnectAccess = useAccess(userPermissions.RemoteConnect);

    const isConnectable = useMemo(() => {
        return (
            !isPreconfigured &&
            deviceName &&
            deviceVersion &&
            isActive &&
            !unconnectableDevices.includes(deviceName)
        );
    }, [deviceName, isPreconfigured]);

    const connectUrl = useMemo(() => {
        return isConnectable ? navigationService.getMenu('connect', deviceUserID, deviceVersion, null, deviceName) : '';
    }, [isConnectable, deviceUserID, deviceVersion, deviceName]);

    return {
        isConnectable,
        connectUrl,
        hasAccessToRemoteConnect: hasRemoteConnectAccess || hasDistributorManageDeviceBasic
    };
};
