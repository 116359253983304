import { isLoggedIn, isDFARequired, isMasquerade } from 'services/Auth';

const isPreLoginUrl = (url) => {
    return [
        '/',
        '/admin',
        '/admin/',
        '/validateDfaAuth',
        '/validateAccount',
        '/validatePartnerAccount',
        '/partner'
    ].includes(url) || url.indexOf('/manageAccount') !== -1;
};

const navigateTo = (currentUrl, result) => {
    if (currentUrl === result.url) {
        return null;
    }

    return result;
};

const ignoreNavigateBackList = ['/logout'];

const getReferrerState = (url) => {
    if (ignoreNavigateBackList.includes(url)) {
        return {};
    }

    return {
        referrer: url,
    };
};

export const getNavigateUrl = (url, search) => {
    const hasToken = isLoggedIn();
    const dfaRequired = isDFARequired() && !isMasquerade();
    const preLoginUrl = isPreLoginUrl(url);
    const referrer = getReferrer();

    if (!hasToken && !preLoginUrl) {
        // store url to local storage to navigate back after login
        setReferrer(`${url}${search}`);

        return navigateTo(url, {
            url: '/',
            state: {
                ...getReferrerState(url),
            }
        });
    }

    if (hasToken && dfaRequired) {
        return navigateTo(url, {
            url: '/validateDfaAuth',
            state: {
                ...getReferrerState(url),
            }
        });
    }

    if (hasToken && !dfaRequired && preLoginUrl) {
        return navigateTo(url, {
            url: '/welcome',
            state: {
                ...getReferrerState(url),
            }
        });
    }

    if (!hasToken && preLoginUrl && referrer) {
        removeReferrer();

        // navigate back to the prelogin url with the attached referrer state
        return {
            url,
            state: {
                ...getReferrerState(referrer),
            },
        };
    }

    return null;
};

export const setReferrer = (url) => window.localStorage.setItem('referrer', url);
export const getReferrer = () => window.localStorage.getItem('referrer');
export const removeReferrer = () => window.localStorage.removeItem('referrer');
