/* eslint-disable react/prop-types */
import './SenderSection.scss';
import React, { useEffect, useState } from 'react';
import { Section } from 'library/Section';
import { Toggle } from 'library/Toggle';
import { ReactComponent as UserIcon } from 'assets/icons/smackTalkUser.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/smackTalkStore.svg';
import { SelectStacked } from 'library/SelectStacked';
import { useTranslation } from 'react-i18next';

const toggles = {
    user: 'user',
    store: 'store'
};

export const SenderSection = ({
    col,
    number,
    disabled = false,
    title,
    className,
    onChange,
    profile,
    smacktalkData,
    store,
    setStore,
    sender,
    setSender,
    ...rest
}) => {
    const [user, setUser] = useState('');
    const [stores, setStores] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (profile) {
            const firstName = profile['User_FirstName'] ?
                `${profile['User_FirstName'].toUpperCase().slice(0, 1)}${profile['User_FirstName'].slice(1)}` : '';
            const lastName = profile['User_LastName'] ?
                `${profile['User_LastName'].toUpperCase().slice(0, 1)}.` : '';

            setUser(`${firstName} ${lastName}`);
        }
    }, [profile]);

    useEffect(() => {
        if (smacktalkData) {
            const ownedStores = smacktalkData.stores.filter((s) =>
                s.requestCompanyUID === s.storeCompanyUID);
            setStores(ownedStores);
        }
    }, [smacktalkData]);

    const onToggleChange = (e) => {
        setSender(e);
        setStore('');
        if (sender === toggles.user) {
            return onChange({
                sender: e,
                store: '',
                user: profile
            });
        }
        onChange({
            sender: e,
            store,
            user: profile
        });
    };

    return (
        <Section
            col={col}
            number={number}
            disabled={disabled}
            title = {title}
            className = {className}
            {...rest}
        >
            <Toggle value={sender} onChange={onToggleChange} isDisabled={false} label={
                <>
                    {t('common__from').toUpperCase()}
                </>
            }>
                {[{
                    text: (<>
                        <UserIcon/>
                        {user}
                    </>),
                    key: toggles.user,
                    value: toggles.user
                }, {
                    text: (<>
                        <StoreIcon/>
                        {t('smack-talk__tab__sender-section--specific-store')}
                    </>),
                    key: toggles.store,
                    value: toggles.store
                }]}
            </Toggle>
            {sender === toggles.store && <SelectStacked
                placeholder={
                    stores && stores.length ?
                    t('common__store__select-store') :
                    t('common__loading')}
                value={store}
                onChange={(value) => {
                    setStore(value);
                    onChange({
                        sender: toggles.store,
                        store: value,
                        user: profile
                    });
                }}
            >
                {stores.map((s, i) => ({
                    text: s.label,
                    value: String(i)
                }))}
            </SelectStacked>}
        </Section>
    );
};
