import React from 'react';
import { cond, T } from 'ramda';

import { taskConfig } from 'constants/applyStoreSettingsTask';
import { Upload } from './Upload';
import { View } from './View';
import { Cancel } from './Cancel';

const { statuses } = taskConfig;

const isStatus = expected => ({ status }) => expected === status;

const isWaitingForUpload = isStatus(statuses.WAITING_FOT_UPLOAD);

const WaitingForUploadActions = ({ task, onUpload, onCancel }) => (
    <>
        <Upload task={task} onUpload={onUpload} />
        {' | '}
        <View task={task} />
        {' | '}
        <Cancel task={task} onCancel={onCancel} />
    </>
);

export const Actions = cond([
    [isWaitingForUpload, WaitingForUploadActions],
    [T, View]
]);