import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { getPermissionsList } from 'services/Permissions';
import { createDistributorRole } from 'services/Roles';
import { getQueryString } from 'helpers/distributor';
import { getPermissionItems } from '../../Common/helpers/getPermissionItems';
import { getCheckedByDefault } from '../../Common/helpers/getCheckedByDefault';
import { isRoleValid, trimmedKeys } from '../../Common/validators/role';
import { trimValues } from 'helpers';

const load = async (accountUuid, setRole, setPermissions, setIsLoading) => {
    setIsLoading(true);

    try {
        const permissions = await getPermissionsList({ accountUuid });
        setPermissions(getPermissionItems(permissions));
        setRole({
            name: '',
            description: '',
            permissions: getCheckedByDefault(permissions),
        });
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

const save = async (accountUuid, role, queryParams, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await createDistributorRole({
            accountUuid,
            role: trimValues(role, trimmedKeys),
        });

        const search = getQueryString(queryParams);

        navigate(`/accounts/roles${search}`);
        setTimeout(() => {
            addSuccessNotification('add-role__success--created');
        }, 1000);
    } catch(err) {
        addErrorNotification(err.message);
        setIsLoading(false);
    }
};

export const useDistributorCreate = () => {
    const { t } = useTranslation();
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [role, setRole] = useState({
        name: '',
        description: '',
        permissions: [],
    });
    const [permissions, setPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const auuid = useMemo(() => queryParams.get('auuid'), [queryParams]);
    const isSaveButtonDisabled = useMemo(() => !isRoleValid(role), [role]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onSave = useCallback(() => {
        save(auuid, role, queryParams, navigate, setIsLoading);
    }, [auuid, role, queryParams, navigate, setIsLoading]);

    useEffect(() => {
        load(auuid, setRole, setPermissions, setIsLoading);
    }, [auuid, setPermissions, setIsLoading]);

    return {
        title: t('roles__create-role-btn'),
        role,
        permissions,
        isLoading,
        isSaveButtonDisabled,
        hasRemove: false,
        onRoleChange: setRole,
        onCancel,
        onSave,
    };
};
