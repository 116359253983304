import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { Toggle } from 'library/Toggle';
import { ErrorLabel } from 'library/ErrorLabel';
import { isFormFieldDisabled } from '../../helpers';

import './AccountStatus.css';

// TODO handle values with translation
const accountStatusList = [
    {
        text: 'common__active',
        value: true,
    },
    {
        text: 'common__inactive',
        value: false,
    },
];

export const AccountStatus = ({ initialValues, formErrors, onChange, isReadOnly }) => {
    const [isAccountActive, setIsAccountActive] = useState(initialValues.isAccountActive || false);
    const { t } = useTranslation();
    const isFieldDisabled = useMemo(() => isFormFieldDisabled(), []);

    useEffect(() => {
        onChange({
            isAccountActive,
        });
    }, [isAccountActive]);

    return (
        <div className="hme-account-form-section hme-account-form-subscription-billing-cycle">
            <Label>{t('account__section__form--account-status')}</Label>
            <Toggle value={isAccountActive} onChange={setIsAccountActive} isDisabled={isFieldDisabled} isReadOnly={isReadOnly}>
                {accountStatusList}
            </Toggle>
            {formErrors.isAccountActive && <ErrorLabel>{t(formErrors.isAccountActive)}</ErrorLabel>}
        </div>
    );
};
