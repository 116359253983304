import api from './api';

const paths = {
    getAll: 'timezones'
}

const timeZoneApi = {
    getAll(params) {
        return api.request({
            url: paths.getAll,
            method: 'GET',
            params: { ...params }
        });
    }
};

export default timeZoneApi;
