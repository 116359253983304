export const MOBILE_GRID_HEADERS = [
    {
        column: [
            { text: 'common__account', property: 'User_EmailAddress' },
            { text: 'common__company-type', property: 'Company_Type' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
        ],
    },
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__store__name', property: 'Store_Name' },
            { text: 'common__lane-config', property: 'Device_LaneConfig_Name' },
            { text: 'common__device-server-type', property: 'DeviceServerType' },
            { text: 'common__device__version', property: 'Device_MainVersion' },
        ],
    },
];

export const MOBILE_NEXEO_GRID_HEADERS = [
    {
        column: [
            { text: 'common__account', property: 'User_EmailAddress' },
            { text: 'common__company-type', property: 'Company_Type' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
            { text: 'common__device__product-id', property: 'Device_Product_ID' },
        ],
    },
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__store__name', property: 'Store_Name' },
            { text: 'common__lane-config', property: 'Device_LaneConfig_Name' },
            { text: 'common__device__version', property: 'Device_MainVersion' },
            { text: 'common__settings__version', property: 'Device_SettingVersion' },
        ],
    },
];

export const MOBILE_STORE_GRID_HEADERS = [
    {
        column: [
            { text: 'common__account', property: 'User_EmailAddress' },
            { text: 'common__company-type', property: 'Company_Type' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
        ],
    },
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__store__name', property: 'Store_Name' },
            { text: 'common__lane-config', property: 'Device_LaneConfig_Name' },
            { text: 'common__device-server-type', property: 'DeviceServerType' },
            { text: 'common__device__version', property: 'Device_MainVersion' },
        ],
    },
];

export const MOBILE_STORE_NEXEO_GRID_HEADERS = [
    {
        column: [
            { text: 'common__account', property: 'User_EmailAddress' },
            { text: 'common__company-type', property: 'Company_Type' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
            { text: 'common__device__product-id', property: 'Device_Product_ID' },
        ],
    },
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__store__name', property: 'Store_Name' },
            { text: 'common__lane-config', property: 'Device_LaneConfig_Name' },
            { text: 'common__device__version', property: 'Device_MainVersion' },
            { text: 'common__settings__version', property: 'Device_SettingVersion' },
        ],
    },
];
