import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'library/DatePicker';

import { renewTokenTypes } from '../../../constants';
import { DateLib } from '@hme-cloud/utility-common';

// according to AC it should be 15 days but we should substract 1 day for DatePicker component
const MAX_AVALIABLE_DATE_RANGE = 14;

const OriginalExpDateLabel = ({ tokenExpirationDate } = {}) => {
    const { t } = useTranslation();

    return (
        <div className="hme-api-management-renew-token-modal__option">
            <span>{t('api-token-management__renew-token__expire-token-option__title')}</span>
            <span>
                {t('api-token-management__renew-token__expire-token-option__description--original', {
                    date: new DateLib(tokenExpirationDate).format(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT),
                    interpolation: { escapeValue: false }
                })}
            </span>
        </div>
    );
};

const TodayExpDateLabel = () => {
    const { t } = useTranslation();

    return (
        <div className="hme-api-management-renew-token-modal__option">
            <span>{t('api-token-management__renew-token__expire-token-option__title')}</span>
            <span>
                {t('api-token-management__renew-token__expire-token-option__description--today', {
                    date: new DateLib().format(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT),
                    interpolation: { escapeValue: false }
                })}
            </span>
        </div>
    );
};

const SpecificExpDateLabel = ({ isDatepickerDisabled = true, onDateChange, dateFormat, tokenExpirationDate, date } = {}) => {
    const { t } = useTranslation();

    const [minDate, maxDate] = useMemo(() => {
        const minAvailableDate = new DateLib().startOf('day').addDays(1);
        const maxAvailableDate = DateLib.min(new DateLib(minAvailableDate).addDays(MAX_AVALIABLE_DATE_RANGE), new DateLib(tokenExpirationDate));

        return [minAvailableDate, maxAvailableDate];
    }, [tokenExpirationDate]);

    return (
        <div className="hme-api-management-renew-token-modal__option">
            <span>{t('api-token-management__renew-token__expire-token-option__title')}</span>
            <span>{t('api-token-management__renew-token__expire-token-option__description--specific-date')}</span>

            <DatePicker
                isDisabled={isDatepickerDisabled}
                multiple={false}
                months={1}
                minDate={minDate}
                maxDate={maxDate}
                onChange={onDateChange}
                dateFormat={dateFormat}
                value={date}
                label={t('admin__token-management__token-expiration-date')}
            />
        </div>
    );
};

export const getRadioOptions = (props = {}) => [
    {
        id: renewTokenTypes.ORIGINAL_EXP_DATE,
        value: renewTokenTypes.ORIGINAL_EXP_DATE,
        label: <OriginalExpDateLabel {...props} />
    },
    {
        id: renewTokenTypes.TODAY_EXP_DATE,
        value: renewTokenTypes.TODAY_EXP_DATE,
        label: <TodayExpDateLabel {...props} />
    },
    {
        id: renewTokenTypes.SPECIFIC_EXP_DATE,
        value: renewTokenTypes.SPECIFIC_EXP_DATE,
        label: <SpecificExpDateLabel {...props} />
    }
];
