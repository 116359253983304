import { DateLib } from '@hme-cloud/utility-common';

export const formDataValidator = (formConditions, t) => {
    const formErrorConditions = [
        {
            key: 'name',
            condition: formConditions.name,
            message: t('new-vaio-provider__error--provider-name')
        }, {
            key: 'email',
            condition: formConditions.email,
            message: t('new-vaio-provider__error--provider-email')
        }, {
            key: 'botName',
            condition: formConditions.botName,
            message: t('new-vaio-provider__error--bot-name')
        }, {
            key: 'keyExpiration',
            condition: formConditions.keyExpiration,
            message: t('new-vaio-provider__error--key-expiration-date')
        }, {
            key: 'url',
            condition: formConditions.url,
            message: t('new-vaio-provider__error--url')
        }, {
            key: 'httpsPort',
            condition: formConditions.httpsPort,
            message: t('new-vaio-provider__error--https-port')
        }, {
            key: 'wssPort',
            condition: formConditions.wssPort,
            message: t('new-vaio-provider__error--wss-port')
        }, {
            key: 'vaioKey',
            condition: formConditions.vaioKey,
            message: t('new-vaio-provider__error--key')
        }, {
            key: 'reconnectionDelay',
            condition: formConditions.reconnectionDelay,
            message: t('new-vaio-provider__error--reconnection-time')
        }
    ];

    const formError = formErrorConditions.reduce(
            (errors, el) => (el.condition && (errors[el.key] = el.message), errors),
            {}
    );

    return formError;
};

export const isExpired = (expDate) => {
    const today = new DateLib().startOfDay();
    const expirationDate = new DateLib(expDate).startOfDay();

    return expirationDate.diff(today, 'days') < 0;
};
