/* eslint-disable react/prop-types */
import './FilterFooter.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Button';

export const FilterFooter = ({
    isApplyEnabled,
    onApply,
    onHide,
    cancelLabel = 'common__cancel',
    okLabel = 'common__ok'
}) => {
    const { t } = useTranslation();

    return (
        <div className="hme-joined-filters-popup-footer">
            <Button
                onClick={onHide}
            >
                {t(cancelLabel)}
            </Button>
            <Button
                variants={['submit']}
                disabled={!isApplyEnabled}
                onClick={onApply}
            >
                {t(okLabel)}
            </Button>
        </div>
    );
};
