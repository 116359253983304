import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';

import { Hamburger } from './Hamburger';
import { MenuItem } from '../MenuItem';
import { AccountItem } from './AccountItem';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { navigationService } from 'components/Common/NavigationServices';
import { LanguageSelect, LanguageModal } from 'library/LanguageSelect';
import { isLoggedIn, isAdmin } from 'services/Auth';
import { NotificationsCenterLink } from 'components/Header/MenuItem/NotificationsCenterLink';

import logoSrc from 'assets/icons/logoMobile.png';

import './MobileHeader.scss';

const defaultMenuItems = [];

const logOutItem = {
    icon: <i className='icon icon-log-out' />,
    className: 'hme-header--mobile__log-out',
    tKey: 'account-dropdown__log-out',
};

const isMenuShown = baseAuthService.isLoggedIn() &&
    baseAuthService.isShowHeader(window.location.pathname) &&
    !navigationService.isLoginPage();
const homeUrl = isLoggedIn() ? '/welcome' : baseAuthService.getAppUrl();

const showMenu = () => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
}

const hideMenu = () => {
    document.body.style.overflow = 'auto';
};

export const MobileHeader = ({
    userName,
    menuItems = defaultMenuItems,
    activeTab,
    onLogout
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAccountOpen, setIsAccountOpen] = useState(false);
    const [opennedItem, setOpennedItem] = useState(activeTab);
    const [isLngModalOpen, setIsLngModalOpen] = useState(false);
    const topMenuItems = useMemo(() => menuItems.filter(item => item.topMenu), [menuItems]);
    const dropMenuItems = useMemo(() => menuItems.filter(item => !item.topMenu), [menuItems]);
    const hiddenRefs = useRef({});

    const onToggle = useCallback(() => {
        setIsOpen(!isOpen);

        // For scroll disabling
        if (isOpen) {
            hideMenu();
        } else {
            showMenu();
        }
    }, [isOpen, setIsOpen]);

    const onAccountClick = useCallback(() => {
        setIsAccountOpen(!isAccountOpen)
    }, [isAccountOpen, setIsAccountOpen]);

    const onMenuItemClick = useCallback((name) => {
        // Some animation related code
        if (opennedItem && hiddenRefs.current[opennedItem]) {
            hiddenRefs.current[opennedItem].parentNode.style.height = hiddenRefs.current[opennedItem].offsetHeight + 'px';
        }

        window.requestAnimationFrame(() => {
            const newName = name === opennedItem ? null : name;

            setOpennedItem(newName);
        });
    }, [opennedItem, setOpennedItem]);

    const onLngModalShow = useCallback(() => {
        setIsLngModalOpen(true);
        setIsAccountOpen(false);
    }, [isOpen, setIsLngModalOpen]);

    const onLngModalHide = useCallback(() => {
        setIsLngModalOpen(false);
    }, [setIsLngModalOpen]);

    useEffect(() => {
        if (!isOpen) {
            const activeTab = menuItems.find(({ isActive }) => isActive) || null;

            setOpennedItem(activeTab && activeTab.name);
        }
    }, [isOpen, menuItems, setOpennedItem]);

    return (
        <div className={classNames('hme-header--mobile', {
            'hme-header--mobile--open': isOpen,
        })}>
            <a href={`${homeUrl}`} className='hme-header--mobile__link'><img src={logoSrc} className='hme-header--mobile__logo' alt="HME Logo" /></a>
            {
                isMenuShown &&
                <>
                    <div className='hme-header--mobile__top-menu'>
                        {
                            topMenuItems.map((item) => (
                                <MenuItem {...item} key={item.name} />
                            ))
                        }
                    </div>
                    <NotificationsCenterLink />
                    <Hamburger onClick={onToggle} isOpen={isOpen} />
                    <div className='hme-header--mobile__content'>
                        <div className='hme-header--mobile__content__items'>
                            {
                                dropMenuItems.map((item) => (
                                    <React.Fragment key={item.name}>
                                        <MenuItem {...item} onClick={onMenuItemClick} />
                                        {
                                            item.items && item.items.length !== 0 ?
                                            <div
                                                className='hme-header--mobile__content__sub-items'
                                                style={{
                                                    height: opennedItem === item.name ? hiddenRefs.current[item.name] && hiddenRefs.current[item.name].offsetHeight : 0,
                                                }}
                                                onTransitionEnd={(e) => e.target.style.height = opennedItem === item.name ? 'auto' : 0}
                                            >
                                                    <div className='hme-header--mobile__content__sub-items__hidden' ref={(element) => hiddenRefs.current[item.name] = element}>
                                                        {
                                                            item.items.map(subItem => (
                                                                <MenuItem {...subItem} key={subItem.name} />
                                                            ))
                                                        }
                                                    </div>
                                                <div className='hme-header--mobile__content__sub-items__visible'>
                                                {
                                                        item.items.map(subItem => (
                                                            <MenuItem {...subItem} key={subItem.name} />
                                                        ))
                                                    }
                                                </div>
                                            </div> :
                                            null
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        <AccountItem
                            userName={userName}
                            isOpen={isAccountOpen}
                            onAccountClick={onAccountClick}
                            onLngItemClick={onLngModalShow}
                        />
                        <MenuItem {...logOutItem} onClick={onLogout} />
                    </div>
                </>
            }
            {
                !isMenuShown && !isAdmin() &&
                <LanguageSelect />
            }
            <LanguageModal show={isLngModalOpen} onHide={onLngModalHide} onChange={onLngModalHide} />
        </div>
    );
};
