/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';

import { ReactComponent as IconSortUp } from 'assets/icons/IconSortUp.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './SortableBoardNew.scss';

const tableTKeys = {
    'Cars': 'common__cars',
    'Avg': 'common__avg',
    'Goal%': 'common__goal--percent',
    'removecolumm': 'common__remove-column',
    'setdefaultsort': 'common__set-ranking-metric',
    'Current Hour': 'common__current-period--hour',
    'Previous Hour': 'common__previous-period--hour',
    'Current Day': 'common__current-period--day',
    'Previous Day': 'common__previous-period--day',
    'Current Daypart': 'common__current-period--daypart',
    'Previous Daypart': 'common__previous-period--daypart',
    'Current Rolling Hour': 'common__current-period--rollinghour',
    'Previous Rolling Hour': 'common__previous-period--rollinghour'
};

class SortableBoardNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: props.tableData,
            firstTable: props.firstTable,
            EmptyTableDropDown: props.EmptyTableDropDown,
        };
        this.handleSortEnd = this.handleSortEnd.bind(this);
        this.handleSortEndTable = this.handleSortEndTable.bind(this);
        this.handleRemoveTableClick = this.handleRemoveTableClick.bind(this);
        this.handleAddItemClick = this.handleAddItemClick.bind(this);
        this.handleCogMenuClick = this.handleCogMenuClick.bind(this);
        this.columnAddMenuClick = this.columnAddMenuClick.bind(this);
        this.setStateWrapper = ( state ) => {
            const cb = () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.tableData);
                }
            };
            this.setState(state, cb);
        };
    }

    componentDidUpdate(prevProps) {
        const hideGoalChanged = this.props.hideGoal !== prevProps.hideGoal;
        const propsChanged = this.props.tableData !== prevProps.tableData ||
            hideGoalChanged;
        if (propsChanged) {
            this.setState({
                tableData: this.props.tableData,
                EmptyTableDropDown: this.props.EmptyTableDropDown
            });
        }
        if (hideGoalChanged && this.props.tableData.length && this.props.onChange) {
            this.setStateWrapper({
                tableData: this.props.tableData
            });
        }
    }

    handleSortEnd(sortorder) {
        const tables = this.state.tableData;

        tables.map((item, i) => {
            if (item.header === sortorder.header) {
                item.columns = arrayMove(
                        item.columns,
                        sortorder.oldIndex,
                        sortorder.newIndex
                );
            }
        });
        this.setStateWrapper({ tableData: tables });
    }

    handleSortEndTable(sortorder) {
        let tableData = this.state.tableData;
        tableData = arrayMove(tableData, sortorder.oldIndex, sortorder.newIndex);
        this.props.handleSortEndTable(sortorder);
        this.setStateWrapper({ tableData: tableData });
    }

    handleRemoveTableClick(index, header) {
        const tableData = this.state.tableData;
        tableData.splice(index, 1);
        const EmptyTableDropDown = this.state.EmptyTableDropDown;
        EmptyTableDropDown.map((data) => {
            if (data.name === header) {
                data.visible = true;
            }
        });

        this.setStateWrapper({
            EmptyTableDropDown: EmptyTableDropDown,
            tableData: tableData,
            isThirdTabeFilled: !this.state.isThirdTabeFilled
        });
    }

    handleAddItemClick(e) {
        const item = e.target.attributes['alt'].nodeValue;
        const EmptyTableDropDown = this.state.EmptyTableDropDown;
        let tableId = '';
        EmptyTableDropDown.map((data) => {
            if (data.name === item) {
                data.visible = false;
                tableId = data.id;
            }
        });
        const tableData = this.state.tableData;
        const newTable = {
            id: tableId,
            disabled: false,
            header: item,
            columns: [
                {
                    isremove: false,
                    default: true,
                    sortasc: false,
                    header: 'Cars',
                    data: [66, 59, 85]
                },
                {
                    isremove: false,
                    default: false,
                    sortasc: false,
                    header: 'Avg',
                    data: [165, 171, 166]
                },
                {
                    isremove: this.props.hideGoal,
                    default: false,
                    sortasc: false,
                    header: 'Goal%',
                    data: [77, 71, 69]
                }
            ]
        };
        newTable.header = item;
        // if (tableData.length === 0) {
        //   newTable.columns[1].default = true
        //   newTable.columns[1].sortasc = true
        // }
        if (tableData.length > 0) {
            const newSecondTable = { ...newTable };
            newSecondTable.columns[0].default = false;
            tableData.push(newSecondTable);
        } else {
            tableData.push(newTable);
        }

        this.setStateWrapper({
            EmptyTableDropDown: EmptyTableDropDown,
            tableData: tableData,
            isThirdTabeFilled: !this.state.isThirdTabeFilled
        });
    }

    handleCogMenuClick({ header, buttontext, thead }) {
        const tables = this.state.tableData;
        tables.map((item) => {
            if (item.header === thead) {
                item.columns.map((col) => {
                    if (buttontext === 'removecolumm') {
                        if (col.header === header) {
                            col.isremove = true;
                        }
                    } else {
                        if (col.header === header) {
                            col.default = true;
                            col.sortasc = !col.sortasc;
                        } else {
                            col.default = false;
                        }
                    }
                });
            } else {
                if (buttontext === 'setdefaultsort') {
                    item.columns.map((col) => {
                        col.default = false;
                    });
                }
            }
        });
        this.setStateWrapper({ tableData: tables });
    }

    columnAddMenuClick(e, thead) {
        const tables = this.state.tableData;
        tables.map((item, i) => {
            if (item.header === thead) {
                item.columns.map((col, i) => {
                    if (col.header === e.target.attributes['alt'].nodeValue) {
                        col.isremove = false;
                    }
                });
            }
        });
        this.setStateWrapper({ tableData: tables });
    }

    render() {
        const { t } = this.props;
        const emptyslots = [];
        const firstTable = this.props.firstTable;
        const hideGoal = this.props.hideGoal;
        const tableData = this.state.tableData;
        for (let i = 0; i < this.props.renderEmptyContext; i++) {
            emptyslots.push(
                    <li className="ui-empty-slot" id={'slot-' + (i + 1)}>
                        {this.props.renderEmptyContext===2 ? <UITableDropDownTranslated
                            Items={this.props.EmptyTableDropDown}
                            disabled={!(i === tableData.length)}
                            onItemClick={this.handleAddItemClick}
                            className="add-table dropdown"
                            toggleClassName="btn btn-secondary btn-md dropdown-toggle"
                            toggleButtonIcon="fa fa-plus"
                            toggleButtonCaption={'leaderboard__template__sortable-board-add-section-optional'}
                        />: <UITableDropDownTranslated
                            Items={this.props.EmptyTableDropDown}
                            disabled={!(i === tableData.length)}
                            onItemClick={this.handleAddItemClick}
                            className="add-table dropdown"
                            toggleClassName="btn btn-secondary btn-md dropdown-toggle"
                            toggleButtonIcon="fa fa-plus"
                            toggleButtonCaption={'leaderboard__template__sortable-board-add-section'}
                        />}
                    </li>);
        }
        return (
            <div id="containment-wrapper" className="sortableboard hme-new-sortable-board">
                <div id="drags-container">
                    <ul className="table-slots">
                        <li className="ui-empty-slot" />
                        {emptyslots}
                    </ul>
                    <ul className="sortable-tables ui-sortable">
                        <UITableDraggable
                            header={firstTable.header}
                            data={firstTable.columns}
                            disabled={firstTable.disabled}
                            hideGoal={hideGoal}
                            additionalClass={this.props.additionalClass}
                            isPreview={this.props.isPreview}
                        />
                        <SortableTableList
                            items={tableData}
                            onSortEnd={this.handleSortEndTable}
                            axis="xy"
                            transitionDuration={500}
                            onRemove={this.handleRemoveTableClick}
                            onColumnSortEnd={this.handleSortEnd}
                            onCogMenuClick={this.handleCogMenuClick}
                            useDragHandle={true}
                            columnAddMenuClick={this.columnAddMenuClick}
                            hideGoal={hideGoal}
                            additionalClass={this.props.additionalClass}
                            isPreview={this.props.isPreview}
                        />
                    </ul>
                </div>
            </div>
        );
    }
}

const SortableBoardNewTranslated = withTranslation()(SortableBoardNew);
export { SortableBoardNewTranslated };

class UITableDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
        this.toggle = this.toggle.bind(this);
        this.itemClick = this.itemClick.bind(this);
    }

    toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    itemClick(e) {
        this.props.onItemClick(e);
        this.toggle();
    }

    render() {
        const { t } = this.props;
        const dropDownItems = (items) => {
            return items.map((item, i) => {
                // Don't render the addGoal link if hideGoal preference is true
                if (this.props.hideGoal && item.name === 'Goal%') {
                    return '';
                }

                return (
                    <li key={i} className={(item.visible ? 'show' : 'hide')}>
                        <a onClick={this.itemClick} alt={item.name}>
                            {t(tableTKeys[item.name])}
                        </a>
                    </li>
                );
            }).filter((el) => el);
        };
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                className={this.props.className}>
                <DropdownToggle
                    disabled={this.props.disabled || !dropDownItems(this.props.Items).length ? 'disabled' : ''}
                    className={this.props.toggleClassName + ` ${!dropDownItems(this.props.Items).length && this.props.hideGoal && 'hidden'}`} >
                    <span className={this.props.toggleButtonIcon}/><span className='leaderboard-templates-draggable__label'>{t(this.props.toggleButtonCaption)}</span>
                </DropdownToggle>
                <DropdownMenu>{dropDownItems(this.props.Items)}</DropdownMenu>
            </Dropdown>
        );
    }
}

const UITableDropDownTranslated = withTranslation()(UITableDropDown);

class UITableDraggable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: []
        };
        this.removeTable = this.removeTable.bind(this);
        this.columnAddMenuClick = this.columnAddMenuClick.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.onCogMenuClick = this.onCogMenuClick.bind(this);
    }

    removeTable() {
        this.props.onRemove();
    }

  onSortEnd = ({ oldIndex, newIndex }) => {
      const header = this.props.header;
      this.props.onColumnSortEnd({ oldIndex, newIndex, header });
  }

  onCogMenuClick = (cogmenu) => {
      if (cogmenu.buttontext === 'removecolumm') {
          this.state.headers.push({ name: cogmenu.header, visible: true });
      }
      this.props.onCogMenuClick(cogmenu);
  }

  columnAddMenuClick(e) {
      const headers = this.state.headers;
      headers.map((item, i) => {
          if (item.name === e.target.attributes['alt'].nodeValue) {
              headers.splice(i, 1);
          }
      });
      this.props.columnAddMenuClick(e, this.props.header);
      this.setState({ headers: headers });
  }

  render() {
      const headers = [];
      this.props.data.map((item) => {
          if (item.isremove) {
              headers.push({ name: item.header, visible: true });
          }
      });

      const addCrossIcon = (disabled) => {
          if (disabled) {
              return '';
          } else {
              return (
                  <div className="table-toolbar" >
                      <button className="remove-widget" onClick={this.removeTable} />
                  </div>
              );
          }
      };

      const disableColumns = (data) => {
          return data.map((item) => {
              if (item.header === 'Goal' && this.props.hideGoal) {
                  return '';
              }
              return <UIColumnDraggable columns={item} disabled={this.props.disabled} columncount={data.length} hideGoal={this.props.hideGoal} isPreview={this.props.isPreview}/>;
          });
      };

      const colSlotsCont = (data) => {
          return data.map((item, i) => {
              return <UIColumnSlotCont disabled={(i===headers.length)} headers={headers} columnAddMenuClick={this.columnAddMenuClick} hideGoal={this.props.hideGoal} />;
          });
      };
      return (
          <li
              className={ 'dash-table ui-state-default ui-draggable' + (this.props.disabled ? ' disabled' : '')}
              id="leaderboard-templates-draggable"
              data-table-id="t0"
          >
              {addCrossIcon(this.props.disabled || this.props.isPreview)}
              <table className="cont-table">
                  <thead>
                      <tr className="hd">
                          <th><DragHandle header={this.props.header} style={this.props.style} /></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>
                              <div className="col-cont">
                                  {this.props.disabled ? ''
                    :
                    <ul className="col-slots-cont">
                        {colSlotsCont(this.props.data)}
                    </ul>
                                  }
                                  {this.props.disabled ?
                    <ul className="sortable-cols ui-sortable">
                        {disableColumns(this.props.data)}
                    </ul>
                    :
                    <SortableList
                        items={this.props.data}
                        tableHeader={this.props.header}
                        onSortEnd={this.onSortEnd}
                        lockAxis="x"
                        axis="x"
                        transitionDuration={0}
                        onCogMenuClick={this.onCogMenuClick}
                        useDragHandle={true}
                        hideGoal={this.props.hideGoal}
                        isPreview={this.props.isPreview}
                    />}
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </li>
      );
  }
}

class UIColumnDraggable extends React.Component {
    constructor(props) {
        super(props);
        this.onMenuClick = this.onMenuClick.bind(this);
    }

    onMenuClick(header, buttontext, thead) {
        this.props.onCogMenuClick({ header, buttontext, thead });
    }

    render() {
        const LIColumn = (item) => {
            let style = {};
            if (this.props.columncount > 3) {
                style = { width: '24.7%' };
            }

            if (this.props.disabled || (!this.props.disabled && !item.isremove)) {
                return (
                    <li style={style} data-colname="StoreRank" className="column hme-leaderboard-draggable-columns">
                        <table className={'subrank ' + (item.default ? 'default-sort' : '')}>
                            <thead>
                                <tr className="subhd">
                                    <th>
                                        {item.default ? <span onClick={() => this.onMenuClick(item.header, 'setdefaultsort', this.props.tableHeader)}
                                            className={(item.sortasc ? 'sort-asc' : 'sort-desc')}><IconSortUp /></span> : ''}
                                        <DragColumnHeaderHandle header={item.header === 'Store' ? 'storeNumber' : item.header} />
                                        {this.props.disabled ? '' :
                                        <UITableDropDownTranslated
                                            Items={[{ name: 'removecolumm', visible: true }, { name: 'setdefaultsort', visible: true }]}
                                            disabled={this.props.isPreview}
                                            onItemClick={(e) => this.onMenuClick(item.header, e.target.attributes['alt'].nodeValue, this.props.tableHeader)}
                                            className={'dropdown col-settings'}
                                            toggleClassName="dropdown-toggle"
                                            toggleButtonIcon="fa fa-cog"
                                        />}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{TRColumn(item.data)}</tbody>
                        </table>
                    </li>
                );
            } else {
                return '';
            }
        };

        const TRColumn = (data) => {
            return data.map((item, i) => {
                if (i % 2 === 0) {
                    return (
                        <tr>
                            <td>{item}</td>
                        </tr>
                    );
                } else {
                    return (
                        <tr className="rshade">
                            <td>{item}</td>
                        </tr>
                    );
                }
            });
        };
        return LIColumn(this.props.columns);
    }
}

class UIColumnSlotCont extends React.Component {
    render() {
        return (
            <li>
                <UITableDropDownTranslated
                    Items={this.props.headers}
                    disabled={this.props.disabled}
                    className="dropdown add-column"
                    toggleClassName="btn btn-secondary btn-sm dropdown-toggle"
                    toggleButtonIcon="fa fa-plus"
                    onItemClick={this.props.columnAddMenuClick}
                    hideGoal={this.props.hideGoal}
                    toggleButtonCaption={'leaderboard__template__sortable-board-add-column'}
                />
            </li>
        );
    }
}

const DragColumnHeaderHandle = SortableHandle(({ header, style, clickHandle }) => {
    const { t } = useTranslation();
    return (<a className="sort-link" onClick={clickHandle} style={style} title={t(tableTKeys[header])} >{t(tableTKeys[header])}</a>);
});

const DragHandle = SortableHandle(({ header, style }) => {
    const { t } = useTranslation();
    return (<span className="theadspan" style={style} title={t(tableTKeys[header])}>{t(tableTKeys[header])}
</span>);
});

const SortableTableItem = SortableElement(({ idx, value, onRemove, onColumnSortEnd, onCogMenuClick, columnAddMenuClick, hideGoal, additionalClass, isPreview }) => (
    <UITableDraggable
        header={value.header}
        data={value.columns}
        disabled={value.disabled}
        onRemove={() => onRemove(idx, value.header)}
        onColumnSortEnd={onColumnSortEnd}
        onCogMenuClick={onCogMenuClick}
        columnAddMenuClick={columnAddMenuClick}
        style={{ cursor: 'move' }}
        hideGoal={hideGoal}
        isPreview={isPreview}
    />
));

const SortableTableList = SortableContainer(({ items, onRemove, onColumnSortEnd, onCogMenuClick, columnAddMenuClick, hideGoal, additionalClass, isPreview }) => {
    return (
        <ul className="sortable-tables ui-sortable">
            {items.map((value, index) => (
                <SortableTableItem
                    key={`item-${index}`}
                    index={index}
                    value={value}
                    onRemove={onRemove}
                    idx={index}
                    onColumnSortEnd={onColumnSortEnd}
                    onCogMenuClick={onCogMenuClick}
                    columnAddMenuClick={columnAddMenuClick}
                    hideGoal={hideGoal}
                    additionalClass={additionalClass}
                    isPreview={isPreview}
                />
            ))}
        </ul>
    );
});

const SortableItem = SortableElement(({ value, tableHeader, onCogMenuClick, isPreview }) => (
    <UIColumnDraggable columns={value} onCogMenuClick={onCogMenuClick} tableHeader={tableHeader} style={{ cursor: 'pointer' }} isPreview={isPreview}/>
));

const SortableList = SortableContainer(({ items, tableHeader, onRemove, onCogMenuClick, hideGoal, isPreview }) => {
    const shownItems = items.filter(({ isremove }) => !isremove);

    return (
        <ul className="sortable-cols ui-sortable">
            {
                shownItems.map((value, index) => {
                    if (value.header == 'Goal%' && hideGoal) {
                        return '';
                    }
                    return (
                        <SortableItem key={`item-${index}`}
                            index={index}
                            value={value}
                            onCogMenuClick={onCogMenuClick}
                            tableHeader={tableHeader}
                            hideGoal={hideGoal}
                            isPreview={isPreview}/>
                    );
                }
                )}
        </ul>
    );
});
