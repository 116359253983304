import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { GridCell } from '../GridCell';
import { ExpandCell, CheckboxCell } from '../GridRow';

import './TitlesRow.scss';

const TitlesRowComponent = ({
    titles,
    selectable,
    expandable,
    onHoverChange,
    className,
    odd,
    hovered
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={classNames(['hme-grid-header-titles-row', className])}
            onMouseLeave={onHoverChange && (() => onHoverChange(false))}
            onMouseEnter={onHoverChange && (() => onHoverChange(true))}
        >
            <CheckboxCell selectable={selectable} checked={false} onChange={null} />
            <ExpandCell expandable={expandable} hidden />
            {titles.map(({ text, flex }) => (
                <GridCell flex={flex} lastInBlock key={text}>
                    {t(text)}
                </GridCell>
            ))}
        </div>
    );
}

// Render titles line header
export const TitlesRow = cond([
    [ ({ titles }) => titles.length === 0, () => null ],
    [ T, TitlesRowComponent ]
]);
