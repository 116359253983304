import * as leaderboard from '../actionTypes/Leaderboard/Leaderboard'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import AuthenticationService from '../components/Security/AuthenticationService'
const _ = require('underscore')

function getLeaderBoardSuccess (LeaderBoardList) {
  return {
    type: leaderboard.GET_LEADERBOARDGROUPS,
    LeaderBoardList: LeaderBoardList
  }
}


function getTemplatesSuccess (TemplatesList) {
  return {
    type: leaderboard.GET_LEADERBOARDTEMPLATES,
    TemplatesList: TemplatesList
  }
}

export const getLeaderBoardList = (callBack, companyUId) => {
  const api = new Api()

  let url
  url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBGroups
  return (dispatch, getState) => {
    const state = getState()
    let sortParams = state.leaderboardGroups.leaderBoardGroups.sortParams
    if (sortParams) {
      url = url + '?sortColumn=' + (sortParams.sortBy ? sortParams.sortBy : '') + '&sortType=' + (sortParams.sortType ? (sortParams.sortType === 'ASC' ? 1 : 0) : '')
    }
    url = url + '&companyUId=' + companyUId;
    api.getData(url, data => {
      let List = data.data
      dispatch(getLeaderBoardSuccess(List))
      callBack(List)
    })
  }
}



export const getTemplateList = (callBack, companyUId) => {
  const api = new Api()

  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBTemplates
  return (dispatch, getState) => {
    const state = getState();
    url = url + '?companyUId=' + companyUId;
    api.getData(url, data => {
      let List = data.data
      dispatch(getTemplatesSuccess(List))
      callBack(List)
    })
  }
}

export const sortLeaderBoard = (sortParams) => {
  return {
    type: leaderboard.SET_SORT_PARAMS_LEADERBOARD,
    sortParams: sortParams
  }
}
