import React from 'react';
import PropTypes from 'prop-types';
import { InfoField } from 'library/InfoField';
import { useTranslation } from 'react-i18next';

import './DeviceDetails.scss';
import { StatusIcon } from 'library/StatusIcon';
import { Link } from 'react-router-dom';
import { SystemInformationAccountLink } from 'library/SystemInformationAccountLink';
import { CommonConstants } from 'Constants';
import { getProfile } from 'services/Auth';

const DeviceDetails = ({ formattedDisplay, device = {} }) => {
    const { t } = useTranslation();
    const {
        deviceIsActive,
        storeName,
        storeNumber,
        accountEmail,
        deviceMainVersion,
        deviceSerialNumber,
        deviceMacAddress
    } = formattedDisplay;

    const getEmailLink = () => {
        const profile = getProfile();
        const urlBase = CommonConstants.AdminEditAccountPage;
        const urlParams = `?uuid=${device.User_UID}&type=${device.Company_Type}`;

        if (!profile.admin) {
            return <SystemInformationAccountLink device={device} profile={profile} />;
        }

        return (
            <Link to={urlBase + urlParams}>
                {accountEmail.value}
            </Link>
        );
    };

    return (
        <>
            <div className="hme-admin-system-status-linkewear-left-column">
                <InfoField label={t(deviceIsActive.label)}>
                    {t(deviceIsActive.value)}
                    <StatusIcon online={deviceIsActive.isActive} />
                </InfoField>
                <InfoField label={t(storeName.label)}>
                    {storeName.value}
                </InfoField>
                <InfoField label={t(storeNumber.label)}>
                    <Link to={storeNumber.link}>
                        {storeNumber.value}
                    </Link>
                </InfoField>
                <InfoField label={t(accountEmail.label)}>
                    {getEmailLink()}
                </InfoField>
            </div >
            <div className="hme-admin-system-status-linkewear-right-column">
                <InfoField label={t(deviceMainVersion.label)}>
                    {deviceMainVersion.value}
                </InfoField>
                <InfoField label={t(deviceSerialNumber.label)}>
                    {deviceSerialNumber.value}
                </InfoField>
                <InfoField label={t(deviceMacAddress.label)}>
                    {deviceMacAddress.value}
                </InfoField>
            </div>
        </>
    );
};

// Props validation
DeviceDetails.propTypes = {
    formattedDisplay: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired
};

export default DeviceDetails;