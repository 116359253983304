import compareDeviceTypes from '../Device/compareDeviceTypes';
import { CommonConstants } from '../../Constants'
const {
    deviceType: {
        nexeo: {
            name: nexeoDeviceName,
        } = {},
    } = {},
} = CommonConstants

 /**
 * filter devices that should be shown for store
 * @param {array} devices
 */
const getFilteredStoreDevices = (devices) => {

    /*
    * chainFilterInit -
    * Used for flexible ability to add new filtration methods for the other types of devices
    */
    const chainFilterInit = (devices) => ({
        devices,
        filterZooms() {
            const devices = this.devices;
            const deviceTypes = Object.values(CommonConstants.deviceType);
            let normalizedDevices = devices.map(device => {
                const deviceType = deviceTypes.find(dt => dt.name == device.Device_Name);
                device.Device_DeviceType_ID = deviceType ? deviceType.id : 0;
                return device;
            });

            const zoomNitroExists = normalizedDevices.some(device =>
                compareDeviceTypes(device, CommonConstants.deviceType.zoomNitro)
            );

            if (zoomNitroExists) {
                normalizedDevices = normalizedDevices.filter(device =>
                    !(compareDeviceTypes(device, CommonConstants.deviceType.zoom) && !device.Device_IsActive)
                );

                // better to change name to 'ZOOM Nitro' one time here
                normalizedDevices.forEach(device => {
                    // ZOOM Nitro device should be on 4 or 5 main version only
                    if (device.Device_Name === 'ZOOM' && CommonConstants.deviceType.zoomNitro.majorVersions.includes(+device.Device_MainVersion.substring(0, 1))) {
                        device.Device_Name = "ZOOM Nitro";
                    }
                });
                this.devices = normalizedDevices;
            }
            return this;
        },
        filterNexeos() {
            const devices = this.devices;
            const isNexeoOnline = devices.some(device => device['Device_Name'] === nexeoDeviceName && device.Device_IsActive);
            if (isNexeoOnline) {
                const nexeoFiltered = devices.filter(device => {
                    if (device['Device_Name'] !== nexeoDeviceName) return true; // do not filter the other types
                    return device.Device_IsActive               
                })
                this.devices = nexeoFiltered;
                return this;
            }
            const isNexeoPreconfigured = devices.some(device => device['Device_Name'] === nexeoDeviceName && device.Device_IsPreconfigured);
            if (isNexeoPreconfigured) {
                const nexeoFiltered = devices.filter(device => {
                    if (device['Device_Name'] !== nexeoDeviceName) return true; // do not filter the other types
                    return device.Device_IsPreconfigured               
                })
                this.devices = nexeoFiltered;
            }
            return this;
        },
        getDevices() {
            return this.devices;
        }
    });
    const filteredDevices =
        chainFilterInit(devices)
        .filterZooms()
        .filterNexeos()
        .getDevices();
    
    return filteredDevices; 
}

export default getFilteredStoreDevices;