import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import DashboardComponent from '../components/Dashboard/DashboardComponent'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import Api from '../Api'
import AuthenticationService from '../components/Security/AuthenticationService'

class DashboardContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      basicUser: false,
      dashboardData: {
      }
    }
    this.api = new Api()
  }

  UNSAFE_componentWillMount () {
    this.getAccountSubscriptionType()
  }

  getAccountSubscriptionType () {
    this.api = new Api()
    this.authService = new AuthenticationService(Config.authBaseUrl)
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.getAdminAcount + '?uuid=' + this.authService.getUUID()
    this.api.getData(url, data => {
      if (data.status) {
        if (data.data[0].Account_Subscription_ID === 4 || data.data[0].Account_Subscription_ID === 6 || data.data[0].Account_Subscription_ID === 15) {
          this.setState({basicUser: false})
        } else {
          this.setState({basicUser: true})
        }
      }
    })
  }

  render () {
    const { t } = this.props;

    if (this.state.basicUser) {
      return (
        <div className='reportBasicUser '>
          <h3 className='up_head'>{t('common__upgrade-account-to-premium')}</h3>
          <h5 className='up_sub'>{t('common__call-to-upgrade')}:</h5>
          <div className='contactList'>
            <div className='contact-d row'>
              <span className='contact-dr col-12 col-sm-4 col-md-2'>{t('common__domestic-sales--title')}:</span>
              <span className='col-sm-8 col-12 col-md-10'>{t('common__domestic-sales--info')}</span>
            </div>
            <div className='contact-d row'>
              <span className='contact-dr col-12 col-sm-4 col-md-2'>{t('common__international-sales--title')}:</span>
              <span className='col-sm-8 col-12 col-md-10'>{t('common__international-sales--info-regions')}</span>
            </div>
            <div className='contact-d row'>
              <span className='contact-dr col-12 col-sm-4 col-md-2' />
              <span className='col-sm-8 col-12 col-md-10'>{t('common__international-sales--info-other-countries')}</span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <DashboardComponent />
      )
    }
  }
}

export default compose(
  withTranslation()
)(DashboardContainer);
