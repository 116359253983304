import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from 'library/Section';
import { SingleSelect } from 'library/SingleSelect';

import './HmeDetails.scss';

export const HmeDetails = ({ initialValues, distributors, onChange }) => {
    const [distributorId, setDistributorId] = useState(initialValues.distributorId || '');

    const { t } = useTranslation();

    const distributorsList = useMemo(
        () =>
            [
                {
                    text: 'common__select-dealer-distributor',
                    value: '',
                },
                ...distributors.map((distributor) => ({
                    text: distributor.Company_Name,
                    value: distributor.Company_ID,
                }))
            ],
        [distributors],
    );

    useEffect(() => {
        onChange({
            distributorId,
        });
    }, [distributorId]);

    return (
        <Section className="company-info-form-hme-details-section" title="my-account__hme-details">
            <div className="hme-company-info-form-section">
                <SingleSelect
                    isReadOnly
                    value={distributorId}
                    placeholder={t('common__select-dealer-distributor')}
                    label={t('common__distributor')}
                    onChange={setDistributorId}
                    items={distributorsList}
                />
            </div>
        </Section>
    );
};
