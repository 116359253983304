/* eslint-disable complexity */
import React from 'react';
import { DateLib } from '@hme-cloud/utility-common';
import { DeviceUpgradeStatuses } from 'constants/NEXEOUpgradeStatuses';
import { DeviceUpgradeStatusCell } from './DeviceUpgradeStatusCell';

const getLastUpdatedStatus = (statuses) => {
    if (!statuses) {
        return null;
    }

    return Object.keys(statuses).reduce((recentStatus, key) => {
        const { lastUpdatedAt } = statuses[key] || {};

        if (!lastUpdatedAt) {
            return recentStatus;
        }

        if (!recentStatus || (new DateLib(lastUpdatedAt)).isAfter(recentStatus.lastUpdatedAt)) {
            return statuses[key];
        }

        return recentStatus;
    }, null);
}

/**
 * Find most recent status of device by date (lastUpdatedAt)
 * @param {object} -- statuses object to search through
 * @param {string} -- device serial number
 *
 * @returns{number} most recent status (-1 is default)
 */
const findMostRecentStatus = (taskStatuses, deviceSerialNum) => {
    // split the statuses into the serial number and the rest
    const { [deviceSerialNum]: statusesSerial, ...statuses } = taskStatuses || {};

    const lastUpdatedStatusSerial = getLastUpdatedStatus(statusesSerial);
    const lastUpdatedStatus = getLastUpdatedStatus(statuses);

    if (!lastUpdatedStatusSerial && !lastUpdatedStatus) {
        return -1;
    }

    if (!lastUpdatedStatusSerial) {
        return lastUpdatedStatus.status;
    }

    if (!lastUpdatedStatus) {
        return lastUpdatedStatusSerial.status;
    }

    const lastUpdatedStatusSerialDate = new DateLib(lastUpdatedStatusSerial.lastUpdatedAt);
    const lastUpdatedStatusDate = new DateLib(lastUpdatedStatus.lastUpdatedAt);

    return lastUpdatedStatusDate.isAfter(lastUpdatedStatusSerialDate) 
        ? lastUpdatedStatus.status
        : lastUpdatedStatusSerial?.status;
};

/**
 * Get the most recent upgrade status for the device
 * Note that sometimes the success status comes after a failure
 * @param {Object} task - The task object
 * @param {String} deviceSerialNum - The device serial number
 * @return {React.Component} - The upgrade status HTML
 */
export const getUpgradeStatusCell = (task, deviceSerialNum) => {
  // Some statuses start with statusID (ie: 1,2) and some have the deviceSerialNum as the key. We need to merge these
  // ie: statuses: { 1: {details}, 2: {details}, 'deviceSerialNum': { 4: {details}, 5: {details} }
  const status = findMostRecentStatus(task.statuses, deviceSerialNum);


  if (status === DeviceUpgradeStatuses.CREATED) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--created" />;
  } else if (status === DeviceUpgradeStatuses.SENT) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--sent" />;
  } else if (status === DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_STARTED) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--downloading" />;
  } else if (status === DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--downloaded" />;
  } else if (status === DeviceUpgradeStatuses.FIRMWARE_UPDATE_STARTED) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--installing" />;
  } else if (status === DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--complete" className="complete" />;
  } else if (status === DeviceUpgradeStatuses.UPDATE_FAILED) {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--failed" className="failed" />;
  } else {
    return <DeviceUpgradeStatusCell status="admin-system-status__upgrade-status--unknown" />;
  }
};

