import { useState, useEffect, useCallback } from 'react';
import { getSupportedDeviceUpgradeVersions } from 'services/Device';

export const useLoadDeviceVersions = () => {
    const [deviceVersions, setDeviceVersions] = useState([]);
    const [isDeviceVersionsLoading, setIsDeviceVersionsLoading] = useState(false);
    const [deviceVersionsLoadingError, setDeviceVersionsLoadingError] = useState(null);

    const loadDeviceVersions = useCallback(async () => {
        try {
            setIsDeviceVersionsLoading(true);
            setDeviceVersionsLoadingError(null);
            const deviceVersions = (await getSupportedDeviceUpgradeVersions()) || [];

            setDeviceVersions(deviceVersions);
        } catch (e) {
            setDeviceVersions([]);
            setDeviceVersionsLoadingError(e);
        }

        setIsDeviceVersionsLoading(false);
    }, []);

    useEffect(() => { loadDeviceVersions(); }, []);

    return { deviceVersions, isDeviceVersionsLoading, deviceVersionsLoadingError, loadDeviceVersions };
};
