import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';
import { Label } from 'library/Label';
import { Slider } from 'library/Slider';
import { Title } from 'library/Title';
import { InfoField } from 'library/InfoField';
import { OnOff } from 'library/OnOff';

import './EngineeringSettings.css';


export const EngineeringSettings = ({ initialValues, isReadOnly }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-account-form-section hme-account-engineering-settings">
            <Title>Engineering settings</Title>
            <InfoField>
                <div className="hme-account-engineering-settings-info-icon">
                    <InfoIcon />
                </div>
                <div className="hme-account-engineering-settings-info">
                    {t('admin__account__section__form--engineering-settings-info')}
                </div>
            </InfoField>
            <div className="hme-account-form-columns">
                <div className="hme-account-form-column">
                    <Label>{t('admin__account__section__form--engineering-settings-data-push')}</Label>
                    <OnOff
                        disabled
                        checked={initialValues.isDataServicePush}
                        isReadOnly={isReadOnly}
                    />
                </div>
                <div className="hme-account-form-column">
                    <Label>{t('admin__account__section__form--engineering-settings-data-pull')}</Label>
                    <OnOff
                        disabled
                        checked={initialValues.isDataServicePull}
                        isReadOnly={isReadOnly}
                    />
                </div>
            </div>
        </div>
    );
};
