import React from 'react';
import { Checkbox } from 'library/Checkbox';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { Button } from 'library/Button';
import { Tip } from 'library/Tip';
import { SearchInput } from 'components/Inputs';
import { useTranslation } from 'react-i18next';

import './TargetDeviceHeader.scss';

export const TargetDeviceHeader = ({
    devices,
    gridFiltersCount,
    searchValue,
    toggableDisabled,
    onSearchChange,
    onFiltersReset,
    onFiltersClear,
    isDisabledHidden,
    onDisabledViewChange,
    isNexeoSourceType,
}) => {
    const { t } = useTranslation();
    const tipMessage = isNexeoSourceType
        ? t('apply-device-settings-new__target-grid__hide-incompatible__tip-admin-nexeo')
        : t('apply-device-settings-new__target-grid__hide-incompatible__tip-admin-zoom');

    return (
        <div className="hme-admin-target-device-list__filters-row">
            <div className="hme-admin-target-device-list__filters-panel">
                <span className="hme-admin-target-device-list__target-count">
                    {devices.length}
                    <span className="hme-admin-target-device-list__target-count-label">
                        {devices.length === 1 ? t('common__device-found') : t('common__devices-found')}
                    </span>
                </span>
                <Button variants={['transparent']} onClick={() => onFiltersClear()}>
                    {t('common__remove-all')}
                </Button>
                {onFiltersReset && (
                    <div className="hme-admin-target-device-list__filters-count">
                        <GridFiltersCount count={gridFiltersCount} onFiltersReset={onFiltersReset} />
                    </div>
                )}
                <div className="hme-admin-target-device-list__hide-disabled">
                    <Checkbox
                        label={t('apply-device-settings-new__hide-incompatible-devices')}
                        checked={isDisabledHidden}
                        onChange={(value) => onDisabledViewChange && onDisabledViewChange(value)}
                    />
                    <Tip className='hme-admin-target-device-list__hide-disabled__tip'>
                        {tipMessage}
                    </Tip>
                </div>
            </div>
            <SearchInput
                value={searchValue}
                onChange={onSearchChange}
                placeholder={t('apply-device-settings-new__target-grid__search-placeholder')}
            />
        </div>
    );
};
