import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import './RemainingCount.scss';

const RemainingCount = ({
    maxLength,
    value,
    messageKey = 'smack-talk__tab__manage-messages--characters-remaining'
}) => {
    const { t } = useTranslation();
    const text = useMemo(() => {
        if (!maxLength) {
            return '';
        }

        const length = value ? value.length : 0;
        const count = maxLength < length ? 0 : maxLength-length;
        return t(messageKey, { count: count, max: maxLength });
    }, [t, messageKey, maxLength, value]);

    return (
        <span className="hme-remaining-count">{text}</span>
    );
};

export { RemainingCount };
