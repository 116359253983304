import React from 'react';
import { SnapshotForm } from '../SnapshotForm';

const snapshotsDefault = { nexeo: [] };

export const NexeoForm = ({
    params,
    onParamsChange,
    isSnapshotsLoading,
    snapshots = snapshotsDefault,
}) => {
    return (
        <SnapshotForm
            deviceType='NEXEO'
            params={params}
            onParamsChange={onParamsChange}
            snapshots={snapshots.nexeo}
            isSnapshotsLoading={isSnapshotsLoading}
        />
    );
};
