import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { equals, isEmpty } from 'ramda';

import { hasAllRequired } from 'helpers';
import { Section } from 'library/Section';
import { TimeZoneSelect } from 'library/TimeZoneSelect';
import { TimeRangeSelector } from 'library/TimeRangeSelector';
import { DaySelector } from 'library/DaySelector';
import { DateSelector } from 'library/DateSelector';
import { DateLib } from '@hme-cloud/utility-common';
import { SaveFooter } from 'library/SaveFooter';
import { SINGLE_SELECT_VARIANTS, SingleSelect } from 'library/SingleSelect';
import { useTimeZoneSelect } from 'library/TimeZoneSelect/hooks/useTimeZoneSelect';
import { NotificationsList } from 'library/NotificationsList';
import { InputComponent } from 'components/Inputs';
import { ErrorLabel } from 'library/ErrorLabel';
import { PUBLIC_ROUTES } from 'constants/routes';
import { LoadingModal } from 'library/LoadingModal';
import { Loader } from 'library/Loader';

import { RewardImageInput } from '../RewardImageInput';
import { DEFAULT_METRIC, METRICS, METRIC_DETAILS } from '../Constants';
import { Stores } from './Stores';
import { Results } from './Results';

import './ViewEdit.scss';

const selectVariants = [SINGLE_SELECT_VARIANTS.NO_LABEL];
const emptyArr = [];

const TODAY_DATE = new DateLib().startOf('day');

const generalSectionRequiredFields = ['name'];
const contestTimeRequiredFields = ['from', 'to', 'startDate', 'endDate'];

// eslint-disable-next-line no-empty-function
const empty = () => {};
const valueChange = () => empty;

export const ViewEditForm = ({
    isSavingInProgress = false,
    showResults = false,
    isCheckingAvailableStores,

    values,
    storesTree = emptyArr,
    errors = {},
    isReadOnly = false,
    results,
    isResultsLoading,

    onSubmit = empty,

    onValueChange = valueChange,
    onImageDrop = empty,
    onImageRemove = empty,
    onSchduledTimePeriodChange = empty,
    onStoresSearchQueryChange = empty,
    onContestStoresChange = empty,
    onStartDateChange = empty,
    onEndDateChange = empty,
    onTimeZoneChange = empty,

    isOnlySelectedStoresShown,
    toggleSelectedStoresOnly,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isTimeZonesLoading, timeZones } = useTimeZoneSelect();
    const enabledSectionsRef = useRef({
        isSecondSectionEnabled: false,
        isStoresSelectionEnabled: false
    });

    const {
        name,
        description,
        metric,
        metricDetails,
        reward,
        rewardImg,
        timeZone,
        scheduledTimePeriod,
        recurrenceDays,
        startDate,
        endDate,
        contestStores,
        contestStoresSearchQuery,
    } = values;

    const originalValuesRef = useRef(values);

    const handleMetricDetailsChange = useCallback((newMetricDetails) => {
        onValueChange('metricDetails')(Number(newMetricDetails));
    }, [onValueChange]);

    const handleTimeZoneChange = useCallback((value) => {
        const selectedTimeZone = timeZones.find((tz) => tz.value === value);

        onTimeZoneChange(selectedTimeZone);

        return selectedTimeZone;
    }, [onTimeZoneChange, timeZones]);

    const handleCancel = useCallback(() => {
        navigate(PUBLIC_ROUTES.contestsList);
    }, [navigate]);

    const isSecondSectionEnabled = useMemo(() => {
        const isEnabled = hasAllRequired({ name }, generalSectionRequiredFields);

        if (!enabledSectionsRef.current.isSecondSectionEnabled && isEnabled) {
            enabledSectionsRef.current.isSecondSectionEnabled = true;
        }

        return isEnabled;
    }, [name]);

    const isStoresSelectionEnabled = useMemo(() => {
        const isEnabled = hasAllRequired({ ...scheduledTimePeriod, startDate, endDate }, contestTimeRequiredFields);

        if (!enabledSectionsRef.current.isStoresSelectionEnabled && isEnabled) {
            enabledSectionsRef.current.isStoresSelectionEnabled = true;
        }

        return isEnabled;
    }, [scheduledTimePeriod, startDate, endDate]);

    const hasErrors = useMemo(() => Object.values(errors).some((err) => !isEmpty(err)), [errors]);
    const areValuesSame = equals(originalValuesRef.current, values);

    const isSubmitEnabled = !isCheckingAvailableStores && !isReadOnly && !hasErrors && isSecondSectionEnabled && isStoresSelectionEnabled && !areValuesSame;

    const isMetricDetailsShown = metric !== DEFAULT_METRIC;

    useEffect(() => {
        if (timeZones.length > 0 && !timeZone?.text) {
            const newTimeZone = handleTimeZoneChange(timeZone?.value);

            originalValuesRef.current = { ...originalValuesRef.current, timeZone: newTimeZone };
        }
    }, [timeZones, timeZone, handleTimeZoneChange]);

    if (isTimeZonesLoading) {
        return (
            <div className="hme-contest-view-edit-form">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <div className="hme-contest-view-edit-form">
                <div className='hme-contest-view-edit-form__content'>
                    <Section
                        number={1}
                        showNumber={!isReadOnly}
                        title="contest__form__section__title--general"
                        className="hme-contest-view-edit-form__section hme-contest-view-edit-form__section--limited-width"
                    >
                        <InputComponent
                            label="common__name"
                            value={name}
                            onChange={onValueChange('name')}
                            maxLength={20}
                            isRequired={!isReadOnly}
                            isReadOnly={isReadOnly}
                            showRemaining={!isReadOnly}
                        />
                        <InputComponent
                            label="common__description"
                            value={description}
                            isReadOnly={isReadOnly}
                            onChange={onValueChange('description')}
                            maxLength={30}
                            showRemaining={!isReadOnly}
                        />

                        <SingleSelect
                            label={t('common__metric')}
                            items={METRICS}
                            value={metric}
                            isReadOnly={isReadOnly}
                            onChange={onValueChange('metric')}
                            variants={selectVariants}
                        />
                        {isMetricDetailsShown && (
                            <SingleSelect
                                label={`${t('common__metric')} ${t('common__details')}`}
                                items={METRIC_DETAILS}
                                value={metricDetails}
                                isReadOnly={isReadOnly}
                                onChange={(handleMetricDetailsChange)}
                                variants={selectVariants}
                            />
                        )}

                        <InputComponent
                            label="common__reward"
                            value={reward}
                            onChange={onValueChange('reward')}
                            maxLength={30}
                            showRemaining={!isReadOnly}
                            isReadOnly={isReadOnly}
                        />

                        <RewardImageInput
                            src={rewardImg}
                            onDrop={onImageDrop}
                            onImageRemove={onImageRemove}
                            readOnly={isReadOnly}
                            handleIsRewardImgShoulBeDisplayedChange={onValueChange('isRewardImgShoulBeDisplayed')}
                            error={errors.rewardImg}
                        />
                    </Section>

                    <Section
                        number={2}
                        showNumber={!isReadOnly}
                        title="contest__time"
                        className="hme-contest-view-edit-form__section hme-contest-view-edit-form__section--limited-width"
                        disabled={!enabledSectionsRef.current.isSecondSectionEnabled}
                    >
                        <TimeZoneSelect
                            currentTimeZone={timeZone?.value}
                            timeZones={timeZones}
                            onChange={handleTimeZoneChange}
                            variants={emptyArr}
                            isReadOnly={isReadOnly}
                            isRequired
                            error={errors.timeZone}
                        />
                        <TimeRangeSelector
                            from={scheduledTimePeriod.from}
                            to={scheduledTimePeriod.to}
                            readOnly={isReadOnly}
                            onChange={onSchduledTimePeriodChange}
                            isRequired
                        />
                        <DaySelector
                            checkedValues={recurrenceDays}
                            disabled={isReadOnly}
                            onChange={onValueChange('recurrenceDays')}
                            error={errors.recurrenceDays}
                        />
                        <DateSelector
                            label="common__start"
                            value={startDate}
                            minDate={TODAY_DATE}
                            isReadOnly={isReadOnly}
                            onChange={onStartDateChange}
                            months={1}
                            multiple={false}
                            isRequired={!isReadOnly}
                        />
                        <DateSelector
                            key={endDate}
                            label="common__end"
                            value={endDate}
                            minDate={startDate || TODAY_DATE}
                            isReadOnly={isReadOnly}
                            onChange={onEndDateChange}
                            months={1}
                            multiple={false}
                            isRequired={!isReadOnly}
                        />
                        {errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
                    </Section>

                    { showResults ?
                        <Results
                            results={results}
                            isResultsLoading={isResultsLoading}
                        /> :
                        <Stores
                            storesTree={storesTree}
                            contestStoresSearchQuery={contestStoresSearchQuery}
                            errors={errors}
                            disabled={!enabledSectionsRef.current.isStoresSelectionEnabled}
                            contestStores={contestStores}
                            onContestStoresChange={onContestStoresChange}
                            onStoresSearchQueryChange={onStoresSearchQueryChange}
                            isCheckingAvailableStores={isCheckingAvailableStores}
                            isOnlySelectedStoresShown={isOnlySelectedStoresShown}
                            toggleSelectedStoresOnly={toggleSelectedStoresOnly}
                        />
                    }
                </div>
            </div>

            <LoadingModal show={isSavingInProgress} />

            <SaveFooter
                onSave={!isSavingInProgress ? onSubmit : null}
                isSubmitEnabled={isSubmitEnabled}
                onCancel={handleCancel}
                cancelLabel={isReadOnly ? t('common__close') : t('common__cancel')}
                additionalClass={classNames('hme-contest-view-edit-form__footer', {
                    'hme-contest-view-edit-form__footer--readonly': isReadOnly
                })}
            />

            <NotificationsList />
        </>
    );
};
