/* eslint-disable react/prop-types */
import { Tile } from 'library/Tile';
import './ManageMessagesTile.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../helpers';
import { isDefaultUser } from '../../helpers';

export const ManageMessagesTile = ({ tile }) => {
    const {
        slider,
        category,
        categoryIcon,
        message,
        createdDate,
        createdBy,
        editRemoveAddons
    } = tile || {};
    const { t } = useTranslation();
    return (
        <Tile>
            <div className="hme-manage-messages-tile-inner">
                <div className="category-icon">
                    {categoryIcon}
                </div>
                <div className="manage-messages-data">
                    <div className="manage-messages-info">
                        <div className="category-label">
                            {category}
                        </div>
                    </div>

                    <dl  className="manage-messages-list">
                        <div>
                            <dd>{message}</dd>
                            {slider}
                        </div>
                    </dl>
                </div>
            </div>
            {!isDefaultUser(createdBy) &&
            <>
                <div className="hme-createdBy">
                    <p className="hme-creatdBy-title">{t('common__created-by')}</p>
                    <p className="hme-createdBy-email">{createdBy}</p>
                </div>
                <div className="hme-createdBy">
                    <p className="hme-creatdBy-title">{t('common__created-on')}</p>
                    <p className="hme-createdOn-email">{formatDateTime(createdDate, createdBy, 's')}</p>
                </div>
            </>}
            {!editRemoveAddons ? '' :
                <div className="hme-manage-messages-tile-controls">
                    {editRemoveAddons}
                </div>}
        </Tile>
    );
};

