import React from 'react';
import PropTypes from 'prop-types';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { SearchOptionable } from 'library/SearchOptionable';
import { DeviceTypeSection } from '../DeviceTypeSection';

import './Header.scss';
import { searchByOptions } from '../constants/addDeviceGridOptions';

export const Header = ({
    deviceType,
    availableDevices,
    onDeviceTypeSelected,
    searchValue,
    onSearchChange,
    onSearchSubmit,
    gridFiltersCount,
    onFiltersReset
}) => {
    return (
        <div className="add-store-device__header">
            <div className="add-store-device__header-title-wrapper">
                <DeviceTypeSection
                    deviceType={deviceType}
                    availableDevices={availableDevices}
                    onDeviceTypeChange={onDeviceTypeSelected}
                />
                <SearchOptionable
                    value={searchValue}
                    onChange={onSearchChange}
                    searchSelectOptions={searchByOptions}
                    onSearch={onSearchSubmit}
                />
                <div className="add-store-device__header-filters-count">
                    <GridFiltersCount count={gridFiltersCount} onFiltersReset={onFiltersReset} />
                </div>
            </div>
        </div>

    );
};

Header.propTypes = {
    deviceType: PropTypes.string,
    availableDevices: PropTypes.array,
    onDeviceTypeSelected: PropTypes.func,
    searchValue: PropTypes.object,
    onSearchChange: PropTypes.func,
    onSearchSubmit: PropTypes.func,
    gridFiltersCount: PropTypes.number,
    onFiltersReset: PropTypes.func
};
