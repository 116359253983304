import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSelect as Select } from 'library/SingleSelect';

import './CountrySelect.css';

export const CountrySelect = ({ label, countries, value, onChange }) => {
    const { t } = useTranslation();

    const countryOptions = useMemo(
        () => countries.map(({ Store_Country_ID, Country_Name }) => ({ value: Store_Country_ID, text: Country_Name })),
        [countries],
    );

    return (
        <div className="hme-device-upgrade-country-select">
            <Select
                isRequired
                variants={['label-inside']}
                placeholder={t('common__select-item')}
                value={value}
                label={t(label)}
                items={countryOptions}
                onChange={onChange}
            />
        </div>
    );
};
