import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

export const getAvailableDeviceUpgradeVersions = ({ device, deviceUpgradeVersions }) => {
    const deviceVersionSemver = BuildVersions.formatVersion(device.Device_MainVersion) || '0.0.0';

    const availableUpgradeVersions = deviceUpgradeVersions.reduce((acc, v) => {
        const versionSemver = BuildVersions.formatVersion(v.Version);
        if (BuildVersions.semverGt(versionSemver, deviceVersionSemver)) {
            acc[v.Version] = v;
        }

        return acc;
    }, {});

    return Object.values(availableUpgradeVersions).sort((a, b) => {
        return BuildVersions.semverGt(a.Version, b.Version) ? 1 : -1;
    });
};
