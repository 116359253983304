import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './Header.scss';

export const Header = ({ storeDetails }) => {
    const { t } = useTranslation();
    const { Store_Name: storeName = '', Store_Number: storeNumber = '' } = storeDetails;

    return <div className="hme-view-edit-store__header">
        {t('stores__view--edit--store__title', { storeName, storeNumber })}
    </div>;
};

Header.propTypes = {
    storeDetails: PropTypes.shape({
        Store_Name: PropTypes.string,
        Brand_Name: PropTypes.string,
        Store_Number: PropTypes.string
    })
};
