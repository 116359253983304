import React from 'react';

import { DeviceVersions } from '../List/DeviceVersions';
import { DeviceStatuses } from '../List/DivceStatuses';
import { DeviceProductID } from '../List/DeviceProductID';
import { StoreSerialNumbers } from '../List/StoreSerialNumbers';

export const getDeviceColumns = ({ devices }) => ({
    ProductID: (<DeviceProductID devices={devices} />),
    SerialNumber: (<StoreSerialNumbers devices={devices} />),
    Version: (<DeviceVersions devices={devices} />),
    Status: (<DeviceStatuses devices={devices} />)
});

export const mapDeviceColumns = ({ devices, ...row }) => ({
    ...row,
    ...getDeviceColumns({ devices })
});
