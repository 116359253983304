import * as React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


export const SimpleToolTip = ({children, text, className, placement = 'top'}) => {
    return (
      <OverlayTrigger
        placement={placement}
        overlay={(props) => <Tooltip className={className} {...props}>{text}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    );
  };