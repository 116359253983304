import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigationService } from '../../Common/NavigationServices';
import { getDeviceSettingsSubHeaders } from './getDeviceSettingsSubHeaders';

const DeviceSettingsSubHeader = () => {
    const { t } = useTranslation();
    const getSelectedMenu = navigationService.getSelectedMenu();
    const menus = getDeviceSettingsSubHeaders();
    const captions = {
        applyDeviceSettings: t('common__device-settings'),
        manageDeviceSettings: t('common__settings-snapshots'),
        applyStoreSettings: t('common__zoom-store-settings'),
        mergeDevices: t('common__merge-devices'),
        firmwareManagement: t('common__firmware-management'),
    };
    const active = (menu) => getSelectedMenu === menu ? 'active_tab_sub' : '';

    return (menus.includes(getSelectedMenu) &&
        <div>
            <div className='subMenu menuBar '>
                <div className='subHeaderSection'>
                    <ul className='subHeaders'>
                        {menus.map((menu, index) => (
                            <li key={index}>
                                <a
                                    className={'headerMenu ' + active(menu)}
                                    href={navigationService.getSubMenuUrl(menu)}
                                >{captions[menu]}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default DeviceSettingsSubHeader;
