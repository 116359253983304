import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { createCustomerNotification } from 'services/CustomerNotification';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { validateNotification } from '../../Form/validators/customerNotification';

const defaultNotification = {
    texts: {
        'en-us': ''
    },
    type: 1,
    isEnabled: false
};

const save = async (notification, setIsLoading, navigate) => {
    try {
        setIsLoading(true);
        await createCustomerNotification(notification);
        setIsLoading(false);
        navigate(-1);
        setTimeout(() => {
            addSuccessNotification('admin__customer-notification__create__notification__toast--success');
        }, 1000)
    } catch (err) {
        addErrorNotification(err.message);
    }
};

export const useCreate = () => {
    const [notification, setNotification] = useState(defaultNotification);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isSubmitable = useMemo(() => validateNotification(notification), [notification]);

    const onSave = useCallback(() => {
        save(notification, setIsLoading, navigate);
    }, [notification, navigate]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return {
        notification,
        isLoading,
        isSubmitable,
        title: 'admin__customer-notification__create__title',
        onSave,
        onCancel,
        onNotificationChange: setNotification
    };
};
