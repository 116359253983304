import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { deleteDataAsync, getDataAsync, patchDataAsync, postDataAsync } from 'Api';
import { getSearchString } from 'Api';

const { apiBaseUrl } = Config;
const { apiUrls } = CommonConstants;

/**
 * Get a Vaio Provider by UID
 * @param  {string} providerUID UID of the provider
 * @return {object} provider details
 */
export const getVaioProviderByUID = async (providerUID) => {
    const url = `${apiBaseUrl}${apiUrls.vaioProvider}?puid=${providerUID}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

/**
 * Create new Vaio Provider
 * @param  {object} newProviderDetails details of new provider
 * @return {object} response
 */
export const createVaioProvider = async (newProviderDetails) => {
    const url = `${apiBaseUrl}${apiUrls.vaioProviders}`;

    const response = await postDataAsync(url, newProviderDetails);

    if (!response.status) {
        let message = '';
        if (response.message === 'Provider already exists') {
            message = 'admin__account__vaio__provider-already-exists';
        } else {
            message = response.message || 'admin__account__vaio__provider-creation-failed';
        }
        throw new Error(message);
    }
    return response.data;
};

/**
 * Update vaio provider details
 * @param  {object} updatedProviderDetails details of new provider
 * @return {object} response
 */
export const updateVaioProvider = async (updatedProviderDetails) => {
    const url = `${apiBaseUrl}${apiUrls.vaioProvider}`;

    const response = await patchDataAsync(url, updatedProviderDetails);

    return response.data;
};

/**
 * Get list of vaio providers
 * @return {object} list of vaio providers
 */
export const getVaioProviders = async (isDetailed = true) => {
    const url = `${apiBaseUrl}${apiUrls.vaioProviders}?detailed=${isDetailed}`;
    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

/**
 * Get a Vaio Provider by UID
 * @param  {string} providerUID UID of the provider
 * @param {string} providerName details
 * @return {object} details of removed provider
 */
export const removeVaioProvider = async (providerUID, providerName) => {
    const url = `${apiBaseUrl}${apiUrls.vaioProvider}`;
    const params = { puid: providerUID, providerName };

    const response = await deleteDataAsync(url, params);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

/**
 * Get list of active vaio providers based on search creteria
 * @param  {string} criteria search criteria
 * @return {object} list of vaio providers
 */
export const getActiveVaioProvidersByCriteria = async (criteria) => {
    const searchParams = getSearchString({ criteria });
    const url = `${apiBaseUrl}${apiUrls.vaioProvidersActive}?${searchParams}`;
    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};
