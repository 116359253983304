import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';

export const gridOptions = [
    {
        text: 'common__device',
        flex: 1.3,
        property: 'modelName',
        className: 'hme-grid-cell-show-all-content hme-grid-model-name',
        headerClassName: 'hme-grid-model-name',
    },
    {
        text: 'common__device__serial-number',
        flex: 1.4,
        property: 'serialNumber',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'system-status__mac-address',
        flex: 1.5,
        property: 'macAddress',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        flex: 0.9,
        property: 'mainVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        text: 'system-status__devices-grid__latest-firmware-available',
        flex: 0.9,
        property: 'latestFirmwareAvailable',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        flex: 0.9,
        property: 'onLatestVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell hme-on-latest-version',
    },
    checkAccess(CommonConstants.adminPermissions.PerformDeviceUpdate)
        ? {
              text: 'common__actions',
              flex: 1.4,
              property: 'actionsComponent',
              headerClassName: 'hme-grid-centered-cell hme-upgrade-actions',
              className: 'hme-grid-cell-with-outside-content hme-grid-centered-cell hme-upgrade-actions',
          }
        : null,
].filter((item) => !!item);
