import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PartnerDataProvider, usePartnerProviders } from './PartnerData/PartnerData';

import { Login } from 'pages/Login';
import { ResetPassword } from 'pages/ResetPassword';
import { ForgotPassword } from 'pages/ForgotPassword';
import { PartnerAccountPages } from 'pages/Partners/Public/Account';
import { PartnerStoresStatusPage } from 'pages/Partners/Public/Stores/StatusPage';
import { SystemStatus as PartnerDeviceDetailsPage } from 'pages/Partners/Public/SystemStatus';
import { ProviderSettingsMainLayout, Providers, VaioViewEdit } from 'pages/Partners/Public/ProviderSettings';
import { StatusPage as PartnerTokenManagementStatusPage } from 'pages/Partners/Public/TokenManagement/StatusPage';
import { EditAPIManagementFormPartner } from 'pages/Partners/Public/TokenManagement/EditApiManagementForm/EditApiManagementForm';

import { getLayout } from 'helpers/getLayout';
import { COMMON_ROUTES, PARTNER_ROUTES } from 'constants/routes';
import Logout from 'components/Security/Logout';

import { commonRoutes } from '../Common';

const ProtectedRoute = ({ children }) => {
    const { isLoading, isVaioPartner } = usePartnerProviders();

    if (isLoading) {
        return null;
    }

    if (!isVaioPartner) {
        return <Navigate to={`/${PARTNER_ROUTES.welcome}`} replace />;
    }

    return children;
};

export const partnerRoutes = () => {
    return (
        <PartnerDataProvider>
            <Routes>
                {commonRoutes()}

                <Route path="partner">
                    <Route index element={getLayout(Login)} />
                    <Route path="manageAccount/forgotpassword" element={<ForgotPassword />} />
                    <Route path="manageAccount/resetpassword/:ruid" element={<ResetPassword />} />
                    <Route path={COMMON_ROUTES.settings} element={<ProtectedRoute><ProviderSettingsMainLayout /></ProtectedRoute>}>
                        <Route path=":puid" element={<VaioViewEdit />} />
                        <Route index element={<Providers />} />
                    </Route>
                    <Route path="stores" element={<PartnerStoresStatusPage />} />
                    <Route path="device/:duid" element={<PartnerDeviceDetailsPage />} />

                    <Route path="tokenManagement">
                        <Route path="edit" element={<EditAPIManagementFormPartner />} />
                        <Route index element={<PartnerTokenManagementStatusPage />} />
                    </Route>

                    <Route path=":activeTab" element={<PartnerAccountPages />} />
                    <Route path={COMMON_ROUTES.logout} element={<Logout />} />
                </Route>
            </Routes>
        </PartnerDataProvider>
    );
};
