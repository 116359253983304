/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Label } from 'library/Label';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

import './MultiSelectInput.scss';

export const MultiSelectInput = ({
    label = '',
    variants = [],
    className,
    onParentClick,
    onChange,
    children,
    isReadOnly = false,
    selections = [],
    showMoreHeight = 200,
    showMoreLabel = <DropdownIcon />,
    showLessLabel = <DropdownIcon />,
    ...inputProps
}) => {
    const [expanded, setExpanded] = useState(true);
    const [showMore, setShowMore] = useState(true);
    const inputEl = useRef(null);
    const containerEl = useRef(null);
    const onWrapperClick = () => {
        setExpanded(true);
        inputEl.current.focus();
    };
    useEffect(() => {
        if (containerEl.current.scrollHeight > showMoreHeight) {
            setShowMore(true);
            return;
        }
        setShowMore(false);
    }, [selections]);
    return (
        <div
            className={classNames(
                    'hme-multi-select-input-container',
                    className,
                    variants.map((variant) => `hme-multi-select-input-${variant}-variant`),
                    { 'expanded': expanded }
            )}
            onClick={onParentClick}
        >
            <Label>{label}</Label>
            <div className="hme-multi-select-input-wrapper">
                {<div
                    ref={containerEl}
                    className={classNames(
                            'hme-multi-select-input-bages',
                            { 'show-more': showMore }
                    )}
                    onClick={onWrapperClick}
                >
                    {selections && selections.length ? [...selections] : ''}
                    <input
                        ref={inputEl}
                        className={`hme-multi-select-input-component ${isReadOnly ? 'hide' : ''}`}
                        onChange={onChange && (({ target: { value } }) => onChange(value))}
                        {...inputProps}
                    />
                </div>}
                <span className={isReadOnly ? '' : 'hide'}>{inputProps.value}</span>
                {showMore &&
                        <span
                            className={classNames(
                                    'hme-multi-select-input-showMore',
                                    { 'expanded': expanded }
                            )}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? showLessLabel : showMoreLabel}
                        </span>
                }
                { children }
            </div>
        </div>
    );
};
