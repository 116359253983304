/* eslint-disable react/prop-types */
import './CategorySlide.scss';
import classNames from 'classnames';
import { Config } from 'Config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { categoryKeys } from '../../../constants';

export const CategorySlide = ({
    category = {},
    activeCategory = {},
    onChangeActiveCategory
}) => {
    const { t } = useTranslation();
    return (
        <div
            key={category.SmackTalkCategoryID}
            className={classNames(
                    'smacktalk-categories-carousel-slide',
                    { 'smacktalk-categories-carousel-slide--active': category.SmackTalkCategoryID === activeCategory.SmackTalkCategoryID }
            )}
            onClick={() => onChangeActiveCategory && onChangeActiveCategory(category)}
        >
            <img src={`${Config.blobNitroStorageBaseUrl}/${category.CategoryIcon}`} alt={t(categoryKeys[category.CategoryDesc])} />
            <div className="smacktalk-categories-carousel-slide-title"><span>{t(categoryKeys[category.CategoryDesc])}</span></div>
        </div>
    );
};
