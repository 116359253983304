import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { NotificationsList } from 'library/NotificationsList';
import { UploadModal } from 'library/UploadModal';
import { LoadingModal } from 'library/LoadingModal';
import { UploadErrorModal } from '../AdminStatusPage/UploadErrorModal';
import { Header } from './Header';
import { TaskInfo } from './TaskInfo';
import { Footer } from './Footer';
import { getTask, getBrands, getCountries, uploadTask, redownloadTask } from './Controller';

import './AdminViewTask.scss';

const loadBrands = async (setBrands, setLoading) => {
    setLoading(true);

    try {
        setBrands(await getBrands());
    } catch (err) {
        setBrands([]);
    }

    setLoading(false);
};

const loadCountries = async (setCountries, setLoading) => {
    setLoading(true);

    try {
        setCountries(await getCountries());
    } catch (err) {
        setCountries([]);
    }

    setLoading(false);
};

const loadTask = async (taskUid, setIsLoading, setTask, setError) => {
    try {
        setIsLoading(true);
        setError(null);
        const task = (await getTask(taskUid)) || null;

        setTask(task);
    } catch (e) {
        console.error('Error while loading task', e);
        setTask(null);
        setError(e)
    }

    setIsLoading(false);
};

const ViewTask = () => {
    const [task, setTask] = useState(null);
    const [isTaskLoading, setIsTaskLoading] = useState(true);
    const [taskLoadingError, setTaskLoadingError] = useState(null);
    const [countries, setCountries] = useState([]);
    const [isCountriesLoading, setIsCountriesLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const [isBrandsLoading, setIsBrandsLoading] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isUploadModalShown, setIsUploadModalShown] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const { taskUid } = useParams();

    const onShowUpload = useCallback(() => {
        setIsUploadModalShown(true);
    }, [setIsUploadModalShown]);

    const onCloseUpload = useCallback(() => {
        setIsUploadModalShown(false);
    }, [setIsUploadModalShown]);

    const onUpload = useCallback(async file => {
        setIsUploadModalShown(false);
        setIsUploading(true);

        const response = await uploadTask(task.Task_UID, file);
        setIsUploading(false);

        if (!response.status) {
            setErrors(response.errors);
            setIsErrorsShown(true);

            return;
        }

        loadTask(task.Task_UID, setIsTaskLoading, setTask, setTaskLoadingError);

        addNotification({
            message: 'The file was successfully uploaded and the task is in progress.',
            type: NOTIFICATION_TYPES.SUCCESS,
        });
    }, [task, setTask, setIsUploadModalShown, setIsUploading, setErrors, setIsErrorsShown, setIsTaskLoading, setTaskLoadingError]);

    const onErrorClose = useCallback(() => {
        setIsErrorsShown(false);
        setErrors([]);
    }, [setIsErrorsShown, setErrors]);

    const onTryAgain = useCallback(() => {
        setIsErrorsShown(false);
        setIsUploadModalShown(true);
        setIsErrorsShown(false);
    }, [setIsErrorsShown, setIsUploadModalShown, setIsErrorsShown]);

    const onDownloadLinkClick = useCallback(async () => {
        try {
            await redownloadTask(taskUid);
        } catch(err) { }
    }, [taskUid]);

    useEffect(() => {
        loadTask(taskUid, setIsTaskLoading, setTask, setTaskLoadingError);
    }, [taskUid, setIsTaskLoading, setTask, setTaskLoadingError]);

    useEffect(() => {
        loadCountries(setCountries, setIsCountriesLoading);
    }, [setCountries, setIsCountriesLoading]);

    useEffect(() => {
        loadBrands(setBrands, setIsBrandsLoading);
    }, [setBrands, setIsBrandsLoading]);

    useEffect(() => {
        setIsDataLoading(isTaskLoading || isCountriesLoading || isBrandsLoading);
    }, [isTaskLoading, isCountriesLoading, isBrandsLoading]);

    return (
        <div className='hme-view-store-settings-task hme-page-component'>
            <NotificationsList />
            <Header />
            <TaskInfo
                task={task}
                isLoading={isDataLoading}
                error={taskLoadingError}
                countries={countries}
                brands={brands}
                onDownloadLinkClick={onDownloadLinkClick}
            />
            <Footer task={task} onUpload={onShowUpload}/>
            <UploadModal
                show={isUploadModalShown}
                multiple={false}
                extensions='csv'
                onHide={onCloseUpload}
                onUpload={onUpload}
            />
            <LoadingModal show={isUploading} message='The device file is being uploaded…' />
            <UploadErrorModal
                show={isErrorsShown}
                errors={errors}
                onHide={onErrorClose}
                onTryAgain={onTryAgain}
            />
        </div>
    );
};

export const AdminViewTask = compose(
    withHMELayout()
)(ViewTask);
