import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Title } from 'library/Title';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import './Header.scss';

export const Header = ({ title, className, ActionButtons = [], ConfirmProps }) => {
    return (
        <div className={classNames('hme-system-status-header', className )}>
            <div className="hme-system-status-header__container">
                <Title className="hme-system-status-header__title">
                    {title}
                </Title>

                {ActionButtons?.length > 0 &&
                    <div className="hme-system-status-header__buttons-container">
                        {ActionButtons}
                    </div>
                }
            </div>

            {ConfirmProps && <ConfirmPopupComponent
                title="common__popup--title-confirm"
                dialogClassName={classNames('hme-system-status-header__modal', ConfirmProps.dialogClassName)}
                {...ConfirmProps}
            />}
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.node.isRequired,
    className: PropTypes.string,
    ActionButtons: PropTypes.arrayOf(PropTypes.node),
    ConfirmProps: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        message: PropTypes.message,
        onHide: PropTypes.func.isRequired,
        onConfirm: PropTypes.func,
        dialogClassName: PropTypes.string
    })
};
