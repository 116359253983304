import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tip } from 'library/Tip';

import './PortTip.scss';

export const PortTip = () => {
    const { t } = useTranslation();

    return (
        <div className="vaio-provider-form-port-tip">
            <Tip placement="bottom">
                {t('new-vaio-provider__form--port-tip')}
                <ol>
                    <li>
                        <div>URL - "wss://voiceoverai.xyz.com"</div>
                        <div>HTTPS PORT - "443"</div>
                        <div>WSS PORT - "443"</div>
                    </li>
                    <li>
                        <div>URL - "wss://voiceoverai.xyz.com:443/nexeo"</div>
                        <div>HTTPS PORT - "" </div>
                        <div>WSS PORT - ""</div>
                    </li>
                </ol>
            </Tip>
        </div>
    );
};
