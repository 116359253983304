const statuses = {
    DRAFT: 0,
    IN_PROGRESS: 1,
    CREATED: 2,
    FAILED: 3,
    SET_TO_EXPIRE: 11,
};

const statusKeys = Object.keys(statuses).reduce(
    (acc, key) => ({
        ...acc,
        [statuses[key]]: key,
    }),
    {},
);

export const snapshotConfig = {
    statuses,
    statusKeys,
};
