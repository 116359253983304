import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Checkbox } from 'library/Checkbox';
import { Label } from 'library/Label';

import './CheckboxGroup.scss';

const defaultVariants = [];
const defaultValue = [];

const CheckboxRow = ({ value, item, onChange }) => {
    const { t } = useTranslation();

    const onCheckboxChange = useCallback((checked) => {
        if (checked) {
            onChange([...value, item.value]);
        } else {
            onChange(value.filter((selectedValue) => selectedValue !== item.value));
        }
    }, [value, item.value, onChange]);

    const isChecked = useMemo(() => value.includes(item.value), [value, item.value]);

    return (
        <Checkbox
            {...item}
            label={typeof item.label === 'string' ? t(item.label) : item.label}
            checked={isChecked}
            onChange={onCheckboxChange}
        />
    );
};

export const CheckboxGroup = ({
    value = defaultValue,
    items,
    label,
    className,
    variants = defaultVariants,
    isRequired = false,
    onChange
}) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-checkbox-group', className, variants.map((variant) => (
            `hme-checkbox-group--${variant}`
        )))}>
            {
                label && <Label>
                    {typeof label === 'string' ? t(label) : label}
                    {' '}
                    {isRequired ? <span className='hme-checkbox-group__asterix'>*</span> : ''}
                </Label>
            }
            {
                items.map((item, index) => (
                    <CheckboxRow key={item.key || index} value={value} item={item} onChange={onChange} />
                ))
            }
        </div>
    );
};
