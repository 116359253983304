/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { Section } from 'library/Section';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent } from 'components/Inputs';
import { Select } from 'library/Select';

import './GeneralSection.scss';
import { RadioGroup } from 'library/RadioGroup';

// Expiration values are 1-24 hours, presented as a dropdown
const tokenExpirationValues = [{ text: '6 months', value: 6 }];
// for (let i = 2; i <= 12; i++) {
//     tokenExpirationValues.push({ text: `${i} months`, value: i });
// }

const getApiRadioOptions = (HME_APIS, t) => HME_APIS.map(({ text, value } = {}) => ({ text: t(text), value }));

export const GeneralSection = ({ formValues, setFormValues, onChange, formErrors, onTokenExpChange, HME_APIS = [] }) => {
    const { t } = useTranslation();
    const { tokenName, tokenExpiration, apiType } = formValues;
    const [providerType, setProviderType] = useState(apiType);

    useEffect(() => {
        setFormValues({
            ...formValues,
            apiType: providerType
        });
    }, [providerType]);
    return (
        <Section
            className="api-management-form-general"
            number={1}
            col={3}
            showNumber={false}
            title="common__general"
        >
            <div className="vaio-provider-form-input-first">
                <InputComponent
                    label={t('api-token-management__form--token-name')}
                    name="tokenName"
                    value={tokenName}
                    onChange={onChange}
                    isRequired
                    maxLength={32}
                    universalOnChange={true}
                />
                {formErrors.tokenName && <ErrorLabel>{t(formErrors.tokenName)}</ErrorLabel>}
            </div>
            <div className="vaio-provider-form-input">
                <Label className="vaio-provider-form-label">{t('api-token-management__form--token-expiration')}</Label>
                <Select
                    value={tokenExpiration}
                    placeholder={`-${t('new-vaio-provider__form--token-expiration-placeholder')}-`}
                    onChange={onTokenExpChange}
                    isDisabled={true}
                    isRequired={true}
                >
                    {tokenExpirationValues}
                </Select>
                {formErrors.tokenExpiration && <ErrorLabel>{t(formErrors.tokenExpiration)}</ErrorLabel>}
            </div>
            <div className="vaio-provider-form-input">
                <Label className="vaio-provider-form-label">{t('api-token-management__form--hme-apis')}</Label>
                <RadioGroup
                    key={providerType}
                    items={getApiRadioOptions(HME_APIS, t)}
                    value={providerType}
                    checked={true}
                    onChange={setProviderType}
                />
            </div>
        </Section>
    );
};

GeneralSection.propTypes = {
    formValues: PropTypes.object.isRequired,
    setFormValues: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    onTokenExpChange: PropTypes.func.isRequired,
    HME_APIS: PropTypes.array.isRequired
};
