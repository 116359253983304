import React from 'react';

import { StatusPage } from '../Common';
import { useDistributorStatus } from './hooks/useDistributorStatus';

export const DistributorStatus = () => {
    const state = useDistributorStatus();

    return (
        <StatusPage {...state} />
    );
};
