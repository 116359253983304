import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ isSubmitEnabled, onCancel, onApply }) => {
    const { t } = useTranslation();

    return (
        <div className="new-vaio-provider__footer">
            <Button onClick={onCancel}>{t('common__cancel')}</Button>
            <Button variants={['submit']} disabled={!isSubmitEnabled} onClick={onApply}>
                {t('common__save')}
            </Button>
        </div>
    );
};
