import { getAllDevices } from '../../services/Device';
import { settingApi } from '../../api/settingApi';

export const getDevices = async filters => {
    const requestFilters = {
        deviceIsActive: 1,
        deviceTypeId: 1,
        ...filters,
    }

    try {
        return await getAllDevices(requestFilters);
    } catch (err) {
        return [];
    }
}

export const applySettingsToMany = async (payload) => {
    let result = { status: false, data: [] };
    try {
        result = await settingApi.applyToMany(payload);
    } catch (error) {
        result = { status: false, message: error.message };
    }
    return result;
};

export const getApplySettingsTaskHistory = async () => {
    let result = { status: false, data: [] };
    try {
        result = await settingApi.getTasks();
    } catch (error) {
        result = { status: false, message: error.message };
    }
    return result;
};


