import React from 'react';

import { Tip } from 'library/Tip';

export const FieldTitle = ({ title, tipText, container }) => {
    return (
        <>
            {title} {' '}
            {
                tipText && (
                    <Tip className="admin-create-apply-device-settings-task-filters-tip" container={container} placement="bottom">
                        {tipText}
                    </Tip>
                )
            }
        </>
    );
};
