import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'library/Button';

const linkVariants = ['transparent'];

export const Actions = ({ suid }) => {
    const { t } = useTranslation();

    const storeUrl = `/admin/settings/stores/edit?suid=${suid}`;

    return (
        <Button as={Link} to={storeUrl} variants={linkVariants}>{t('common__view-edit')}</Button>
    );
};

Actions.propTypes = {
    suid: PropTypes.string.isRequired
};
