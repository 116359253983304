import { CommonConstants } from '../../Constants';
import { permissionsTKey } from '../../constants/permissions';
const {
    subscription: {
        nexeoCustomerOnly
    }
} = CommonConstants;

const exclusions = {
    pages: {
        [nexeoCustomerOnly]: [
            'dashboard',
            'reports',
            'leaderBoards',
            'smackTalk',
            'display',
            'lockDeviceSettings',
            'grouphierarchy',
            'distributors'
        ]
    },
    tKeys: {
        [nexeoCustomerOnly]: [
            permissionsTKey['ApplyDeviceSettings'],
            permissionsTKey['EditDeviceSettings'],
            permissionsTKey['EditLeaderboard'],
            permissionsTKey['EditStoreAdvanced'],
            permissionsTKey['SendSmackTalkMessage'],
            permissionsTKey['ViewLeaderboard'],
            permissionsTKey['CustomizeSmackTalk'],
            permissionsTKey['SmackTalk']
        ]
    }
};

export default exclusions;
