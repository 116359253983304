import React from 'react'
import { filterAXNumberValue } from 'helpers/filterInputNumberValues';

export function handleInputChange (e) {
  const { name, value } = e.target;
  let fieldValue = value;
  if(name === 'storeAXCustomerAccount'){
    fieldValue = filterAXNumberValue(fieldValue);
  }
  this.setState({ [name]: fieldValue });
}

export function handleCheckboxChange (e) {
  this.setState({
    [e.target.name]: e.target.checked
  })
}

export function generateTableBody() {
  return this.state.fieldsList.map(bodyField => {
    const {
      html,
      caption,
      isRequired,
      fieldType,
      name,
      fieldId,
      options,
      isDisabled,
      maxlength = 100
    } = bodyField;
    const stateValue = this.state[name];
    const disabledField = isDisabled || this.state.isPending;
    const fields = {
      'select': (
        <select
          name={name}
          id={fieldId}
          disabled={disabledField}
          value={stateValue}
          onChange={this.handleInputChange}
        >
          {options.map(op => {
            const { value, slot } = op;
            return (<option key={value} value={value}>{slot}</option>)
          })}
        </select>
      ),
      'input': (
        <input
          type='text'
          maxLength={maxlength}
          name={name}
          value={stateValue}
          disabled={disabledField}
          onChange={this.handleInputChange}
        />
      ),
      'checkbox': (
        <input
          type='checkbox'
          name={name}
          id={fieldId}
          checked={stateValue}
          disabled={disabledField}
          onChange={this.handleCheckboxChange}
        />
      )
    }

    return (
      <tr key={caption}>
        {caption && <th>
          <label htmlFor={html}>{caption}{isRequired ? (<span className='redText'>*&nbsp;</span>) : ''}</label>
        </th>}
        <td>
          {fields[fieldType]}
        </td>
      </tr>)
  })
}
