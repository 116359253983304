import { useEffect } from 'react';

import { useAsync } from 'hooks/useAsync';
import { loadTypes } from 'services/Partner';

const defaultTypes = [];

export const useLoadPartnersType = () => {
    const {
        run,
        error,
        isLoading,
        data: types,
    } = useAsync();

    useEffect(() => {
        run(loadTypes());
    }, []);

    return {
        isLoading,
        types: types || defaultTypes,
        error,
    };
};
