import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Api from '../../Api';
import { Config } from '../../Config';
import { CommonConstants } from '../../Constants';
import { compose } from 'ramda';
import { withHMELayout } from 'HOCs/withHMELayout';
import PropTypes from 'prop-types';
import { withReactRouter } from 'HOCs/withReactRouter';

/**
 *
 */
class ValidateAccount extends Component {
    /**
   *
   * @param {*} props
   */
    constructor(props) {
        super(props);
        this.state = {
            validation: false,
            message: '',
            loading: true
        };
        this.api = new Api();
    }
    /**
     *
     */
    UNSAFE_componentWillMount() {
        const { queryParams, isPartnerActivation } = this.props;
        const uuid = queryParams.get('uuid');
        const url = Config.apiBaseUrl + (isPartnerActivation ? CommonConstants.apiUrls.validatePartner  : CommonConstants.apiUrls.accountValidate) +'?uuid=' + uuid;
        this.api.getData(url, (result) => {
            if (result.status) {
                this.setState({
                    validation: true,
                    loading: false
                });
            } else {
                this.setState({
                    validation: false,
                    loading: false
                });
            }
        });
    }

    /**
     *
     * @return {JSX}
     */
    render() {
        const { t } = this.props;
        return (
            <section className="validateAccount">
                {
                    this.state.loading ?
                        <h3 className="validateAccount">
                            { t('validate-account__user-activating-account')}
                        </h3>
                        :
                    (this.state.validation ? ( <div className="validateAccount">
                        <h3>{ t('validate-account__user-account-activated') }</h3>
                        <Trans i18nKey="validate-account__login-message">
                            <a className="red_link" href={ this.props.isPartnerActivation ? "/partner" : "/"}>Click here</a> to login to your account.
                        </Trans>
                    </div> ) :
                ( <h3 className="validateAccount">
                    { t('validate-account__error__user-not-found') }
                </h3> ))
                }
            </section>
        );
    }
}

ValidateAccount.propTypes = {
    queryParams: PropTypes.object
};

export default compose(
        withHMELayout(),
        withTranslation(),
        withReactRouter
)(ValidateAccount);
