import React from 'react';
import classNames from 'classnames';

import Layout from 'components/Common/Layout';
import { GamificationMarketing } from 'pages/GamificationMarketing';
import { hasGamificationFeature, hasLeaderboardAccess } from 'actions/permissions';

// eslint-disable-next-line react/display-name
export const withGamificaionMarketingLayout = ({ className, ...layoutProps }) => (Component) => (props) => {
    const hasAccess = hasGamificationFeature('contests');
    const { hasSubscription } = hasLeaderboardAccess();

    return (
        <div className={classNames('hme-layout', className)}>
            {hasAccess ? (
                <Layout Params={layoutProps}>
                    <Component {...props} />
                </Layout>
            ) : (
                <GamificationMarketing hasNoSubscription={!hasSubscription} title="common__contests" />
            )}
        </div>
    );
};
