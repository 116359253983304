import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';

import './Footer.scss';
import { Link } from 'react-router-dom';


export const Footer = ({ isSubmitEnabled, onCancel, onApply }) => {
    const { t } = useTranslation();

    return (
        <div className="leaderboard-create-apply-device-settings-task-footer">
            {/* <Button onClick={onCancel}>Cancel</Button> */}
            <Link to={onCancel} className="hme-button-component">
                {t('common__cancel')}
            </Link>
            <Button
                variants={['submit']}
                disabled={!isSubmitEnabled}
                onClick={onApply}
            >
                {/* TODO: Use css padding */}
                &nbsp;&nbsp;&nbsp;&nbsp;{t('common__save')}&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
        </div>
    );
};
