import { useState } from 'react';
import { CommonConstants } from 'Constants';
import { getSnapshotsList } from '../services/Snapshots';

const { deviceType: { zoom, zoomNitro } } = CommonConstants;

export const useLoadSnapshots = () => {
    const [snapshots, setSnapshots] = useState([]);
    const [snapshotCount, setSnapshotCount] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    const loadSnapshots = async ({
        deviceType,
        pageNumber,
        itemsPerPage,
        sortSelection,
        gridFilters,
        searchValue,
        searchField
    }) => {
        try {
            setIsLoading(true);
            const SourceDeviceType = deviceType === zoomNitro.name ? zoom.name : deviceType;
            const sortColumn = Object.keys(sortSelection)[0];
            const sortDirection = sortSelection[sortColumn] === 1;

            const snapshotData = {
                sortColumn,
                sortingType: sortDirection,
                pageNumber: pageNumber + 1,
                itemsPerPage,
                appliedFilters: { SourceDeviceType: [SourceDeviceType], ...gridFilters },
                searchValue,
                searchField
            };

            const snapshotsData = await getSnapshotsList(snapshotData);

            setSnapshots(snapshotsData.snapshots);
            setSnapshotCount(snapshotsData.total);
        } catch (e) {
            setSnapshots([]);
            setSnapshotCount(0);
        }
        setIsLoading(false);
    }

    return { snapshots, snapshotCount, loadSnapshots, isLoading };
};
