import { useViewEidt } from './hooks/useViewEdit';

import { ViewEdit } from '../../Common';

export const ViewEditPartner = () => {
    const {
        isLoading,
        countries,
        regions,
        title,
        types,
        isRegionsLoading,
        partner,
        originPartner,
        onChange,
        onCancel,
        onSave,
        onResend,
        onMasquerade
    } = useViewEidt();

    return (
        <ViewEdit
            partner={partner}
            originPartner={originPartner}
            types={types}
            title={title}
            countries={countries}
            regions={regions}
            isLoading={isLoading}
            isRegionsLoading={isRegionsLoading}
            showStatus={true}
            showResend={originPartner?.isActive}
            onChange={onChange}
            onCancel={onCancel}
            onSave={onSave}
            onResend={onResend}
            onMasquerade={onMasquerade}
        />
    );
};
