import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { Label } from 'library/Label';

import { HierarchyItem } from '../../HierarchyBlock/HierarchyItem';

const sortItems = (items) => items.sort(({ Type: Type1 }, { Type: Type2 }) => {
    if (Type1 === Type2) {
        return 0;
    }

    if (Type1 === storeHierarchyNodeTypes.GROUP) {
        return -1;
    }

    return 1;
});

export const Selected = ({ selectedItems }) => {
    const { t } = useTranslation();

    const sortedItems = useMemo(() => sortItems(selectedItems), [selectedItems]);

    return (
        <div className='hme-manage-hierarchy__move-popup__selected'>
            <Label className='hme-manage-hierarchy__move-popup__label'>{t('stores-hierarchy__move-popup__selected')}</Label>
            <div className='hme-manage-hierarchy__move-popup__selected__items'>
                <div className='hme-manage-hierarchy__move-popup__selected__items__wrapper'>
                    {
                        sortedItems.map((item) => (
                            <HierarchyItem item={item} key={`${item.Type}_${item.Id}`} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};
