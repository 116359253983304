import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { DevicesList } from './DevicesList';
import { deviceConfig } from 'constants/device';
import './TargetDevicesSection.scss';

const { getLaneType } = deviceConfig;

export const TargetDevicesSection = ({
    sourceType,
    sectionNumber,
    col,
    disabled = false,
    searchType,
    selectedSnapshot,
    selectedDeviceType,
    targetDevices,
    params,
    ...deviceListProps
}) => {
    const { t } = useTranslation();

    return (
        <Section
            number={sectionNumber}
            col={col}
            title={t('create_new_task__select--select-destination-devices')}
            className="admin-create-apply-device-settings-task-target"
            disabled={disabled}
        >
            <DevicesList
                selectedSnapshot={selectedSnapshot}
                selectedDeviceType={selectedDeviceType}
                searchType={searchType}
                sourceType={sourceType}
                params={params}
                targetDevices={targetDevices.map(device => ({
                    ...device,
                    Device_LaneConfig_Name: getLaneType(device.Device_LaneConfig_ID),
                }))}
                {...deviceListProps}
            />
        </Section>
    );
};
