import * as storeDetailsActions from '../actionTypes/StoreDetails/storeDetails';
import Api from '../Api';
import { Config } from '../Config';
import { CommonConstants } from '../Constants';

// on success of getting stores for public user, dispatch the event
function getStoresSuccess(stores) {
    return {
        type: storeDetailsActions.INIT_STORESDETAILS,
        storeDetails: stores
    };
}

// get stores for client
export const initStoresDetails = (callback, isLockDeviceSettings = false) => {
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.getStores + '?isAdmin=0';
    return (dispatch, getState) => {
        const api = new Api();
        const state = getState();
        url += `&showOnlyAvailableForSettingLock=${Number(isLockDeviceSettings)}`;
        if (state.storeDetails.sortParams) {
            url = url + '&Sortby=' + state.storeDetails.sortParams.sortBy + '&sortType=' + state.storeDetails.sortParams.sortType;
        } else {
            url = url + '&Sortby=Brand_Name&sortType=ASC';
        }

        if (state.storeDetails.searchParams) {
            const { criteria, filter } = state.storeDetails.searchParams;
            const encodedCriteria = criteria ? encodeURIComponent(criteria) : '';
            url += `&criteria=${encodedCriteria}&filter=${filter || ''}`;
        }

        api.getData(url, (data) => {
            dispatch(getStoresSuccess(data));
            callback();
        });
    };
};

// on success of getting stores for admin, dispatch the event
function getAdminStoresSuccess(stores) {
    return {
        type: storeDetailsActions.ADMIN_STORESDETAILS,
        adminStoreDetails: stores
    };
}

// get stores for admin
export const adminStoresDetails = (callback, isLockDeviceSettings = false) => {
    const params = new URLSearchParams(window.location.search) || {};
    const uuid = params.get('uuid');
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.getStores + '?isAdmin=1';

    return (dispatch, getState) => {
        const api = new Api();
        const { storeDetails } = getState();
        url += `&showOnlyAvailableForSettingLock=${Number(isLockDeviceSettings)}`;

        if (storeDetails.paginationParams) {
            const { pageSize, pageNumber } = storeDetails.paginationParams;
            url = url + '&psize=' + pageSize + '&pno=' + pageNumber;
        } else {
            url = url + '&psize=10&pno=1';
        }

        if (storeDetails.sortParams) {
            const { sortBy = '', sortType ='' } = storeDetails.sortParams;
            url = url + '&Sortby=' + sortBy + '&sortType=' + sortType;
        } else {
            url = url + '&Sortby=Company_Name&sortType=ASC';
        }

        if (storeDetails.searchParams) {
            const { criteria, filter } = storeDetails.searchParams;
            const encodedCriteria = criteria ? encodeURIComponent(criteria) : '';
            url = url + '&criteria=' + (encodedCriteria) + '&filter=' + (filter || '');
        }

        if (uuid) {
            url = url + '&uuid=' + uuid;
        }

        api.getData(url, (data) => {
            dispatch(getAdminStoresSuccess(data));
            if (callback) {
                callback();
            }
        });
    };
};
// sort Stores
export const sortStores = (sortParams) => {
    return {
        type: storeDetailsActions.SET_SORT_PARAMS,
        sortParams
    };
};
// Search stores
export const setStoresSearchParams = (searchParams) => {
    return {
        type: storeDetailsActions.SET_SEARCH_PARAMS_STORES,
        searchParams
    };
};

// pagination  for Admin Stores page
export const paginationAdminStores = (paginationParams) => {
    return {
        type: storeDetailsActions.PAGINATION_STORESDETAILS,
        paginationParams
    };
};

export const deviceDetails = () => {
    const api = new Api();
    const url =
    'https://hme-dev-public-cloud-func.azurewebsites.net/api/store/getStore?suid=7F671C950B924E4CA61A5E7975765BB0';
    api.getData(url, (data) => {
        return data;
    });
};
