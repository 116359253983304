/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import withCharCounter from '../../../../../HOCs/withCharCounter';
import { TextArea } from '../../../../../library/TextArea';

const SmackTalkCounterContent = ({ charsLeft = 0, maxLength = 60 }) => {
    const { t } = useTranslation();
    return (<>
        <span className="hme-focused">
            {t('smack-talk__tab__manage-messages--characters-remaining', { count: charsLeft, max: maxLength })}
        </span>
        <span className="hme-blured">
            {t('smack-talk__tab__manage-messages--characters-info', { max: maxLength })}
        </span>
    </>);
};
const TextAreaWithCharCounter = withCharCounter(TextArea, SmackTalkCounterContent);

export { TextAreaWithCharCounter };
