import { useEffect, useCallback } from 'react';
import { getGroupedStores } from '../Controller';
import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';

export const useLoadTextConnectGroupedStores = () => {
    const { run: runLoadGroupedStores, data: groupedStores, error: groupStoresLoadingError, status } = useAsync();

    const loadGroupedStores = useCallback(() => {
        runLoadGroupedStores(getGroupedStores());
    }, []);

    useEffect(() => {
        loadGroupedStores();
    }, []);

    return {
        groupedStores: groupedStores || [],
        isGroupedStoresLoading: status === ASYNC_STATUS.PENDING,
        groupStoresLoadingError,
        loadGroupedStores,
    };
};
