export const DEFAULT_METRIC = 'Cars';

export const METRIC_DETAILS = [
    {
        text: 'contest__settings__metric-lane-total',
        value: 1
    },
    {
        text: 'contest__settings__metric-lane-total-two',
        value: 2
    }
];

export const METRICS = [
    {
        text: 'contest__settings__metric-car-count',
        value: DEFAULT_METRIC
    },
    {
        text: 'contest__settings__metric-avg-time',
        value: 'Avg',
        metricDetails: METRIC_DETAILS
    },
    {
        text: 'contest__settings__metric-goal-percent',
        value: 'Goal%',
        metricDetails: METRIC_DETAILS
    }
];
