/* eslint-disable react/prop-types */
import './CategorySection.scss';
import React, { useEffect, useState } from 'react';
import { Section } from 'library/Section';
import { ControlledCarousel } from './ControlledCarousel/ControlledCarousel';
import { ReactComponent as PrevIcon } from '../../../../images/left-arrow.svg';
import { Grid } from 'components/Common/Grid';
import { Label } from 'library/Label';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { categoryKeys, messageKeys } from '../../constants';

const DISPLAYED_FIELD = 'LeaderboardDisplayText';
const CUSTOM_CATEGORY_ID = 4;
const defaultCategories = [
    {
        CategoryDesc: 'smacktalk__category_boast',
        CategoryIcon: 'icons/smacktalk-category-boast.png',
        CategoryName: 'boast',
        SmackTalkCategoryID: 1,
        messages: []
    },
    {
        CategoryDesc: 'smacktalk__category_taunt',
        CategoryIcon: 'icons/smacktalk-category-taunt.png',
        CategoryName: 'taunt',
        SmackTalkCategoryID: 2,
        messages: []
    },
    {
        CategoryDesc: 'smacktalk__category_congratulate',
        CategoryIcon: 'icons/smacktalk-category-congratulate.png',
        CategoryName: 'congratulate',
        SmackTalkCategoryID: 3,
        messages: []

    },
    {
        CategoryDesc: 'smacktalk__category_custom',
        CategoryIcon: 'icons/smacktalk-category-custom.png',
        CategoryName: 'custom',
        SmackTalkCategoryID: 4,
        messages: []

    }
];

export const CategorySection = ({
    col,
    number,
    disabled = false,
    title,
    className,
    smacktalkData,
    selectedMessage,
    setSelectedMessage,
    ...rest
}) => {
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState({});
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const addTranslation = (rows, displayedField) => rows.map((m) => {
        m.translated = t(messageKeys[m[displayedField]]) || m[displayedField];
        return m;
    });

    const clearRowClassname = (rows) => rows.map((row) => ({ ...row, rowClassName: '' }));

    const selectMessage = (rows, selected) => {
        if (!selected || !Array.isArray(rows)) {
            return rows;
        }
        return rows.map((row) => {
            const clone = { ...row };
            if (clone['SmackTalkPredefinedMessageID'] === selected['SmackTalkPredefinedMessageID']) {
                clone['rowClassName'] = 'selected';
            }
            return clone;
        });
    };

    const populateCategories = (customCategories) => {
        return _.sortBy(_.unionBy(customCategories, defaultCategories, 'SmackTalkCategoryID'), ['SmackTalkCategoryID']);
    };

    useEffect(() => {
        if (smacktalkData) {
            const populatedCategories = populateCategories(smacktalkData.customCategories);
            setCategories(populatedCategories);
        }
    }, [smacktalkData]);

    useEffect(() => {
        setIsLoading(false);
    }, [messages]);

    useEffect(() => {
        if (activeCategory && Array.isArray(activeCategory.messages)) {
            setIsLoading(true);
            const translated = selectMessage(
                    clearRowClassname(
                            addTranslation(activeCategory.messages, DISPLAYED_FIELD)
                    ),
                    selectedMessage
            );
            setMessages(translated);
        }
    }, [activeCategory, selectedMessage, t]);

    const onCategoryChange = (category) => {
        setActiveCategory(category);
        setSelectedMessage();
    };

    return (
        <Section
            col={col}
            number={number}
            disabled={disabled}
            title = {title}
            className = {className}
            {...rest}
        >
            <Label>{t('common__category').toUpperCase()}</Label>
            <ControlledCarousel
                prevIcon={<PrevIcon/>}
                nextIcon={<PrevIcon/>}
                categories={categories}
                onCategoryChange={onCategoryChange}
                activeCategory={activeCategory}
            >
            </ControlledCarousel>
            {activeCategory && activeCategory.CategoryName ?
                <>
                    <Label className="category-label">
                        {t('smack-talk__tab__category-section--select-message', { category: t(categoryKeys[activeCategory.CategoryDesc]) })}
                    </Label>
                    <Grid
                        rows={messages}
                        headers={[{ text: '', property: 'translated' }]}
                        noRecordsMessage={
                            activeCategory.SmackTalkCategoryID === CUSTOM_CATEGORY_ID ?
                                t('smack-talk__tab__category-section--no-custom-messages') :
                                t('smack-talk__tab__category-section--no-messages-found')
                        }
                        rowKey="LeaderboardDisplayText"
                        onRowClick={setSelectedMessage}
                        isLoading={isLoading}
                    />
                </> : ''
            }
        </Section>
    );
};
