import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ onTest, onCancel, onSave, testDisabled, saveDisabled = false }) => {
    const { t } = useTranslation();
    return (
        <div className="sso-footer">
            <>
                <Button onClick={onTest} disabled={testDisabled}>{'TEST SSO'}</Button>
                <Button onClick={onCancel}>{t('common__cancel')}</Button>
                <Button variants={['submit']} disabled={saveDisabled} onClick={onSave}>
                    {t('common__save')}
                </Button>
            </>
        </div>
    );
};
