export const startYear = 2018;//for reset details.
export const noOfPreviousMonths = 6;

export const allMonths = [
    'common__all-months',
    'common__full-january',
    'common__full-february',
    'common__full-march',
    'common__full-april',
    'common__full-may',
    'common__full-june',
    'common__full-july',
    'common__full-august',
    'common__full-september',
    'common__full-october',
    'common__full-november',
    'common__full-december'
];

export const awardKeys = {
    'AwardName': {
        'record_best_avg_time_hour_name': 'awards__record-best-avg-time-hour-name',
        'record_most_cars_day_name': 'awards__record-most-cars-day-name',
        'most_cars_month_name': 'awards__most-cars-month-name',
        'record_best_avg_time_day_name': 'awards__record-best-avg-time-day-name',
        'best_avg_time_month_name': 'awards__best-avg-time-month-name',
        'best_%_goal_month_name': 'awards__best-%-goal-month-name',
        'goal_achiever_name': 'awards__goal-achiever-name',
        'speedster_name': 'awards__speedster-name',
        '%_goal_name': 'awards__%-goal-name',
        'sprint_name': 'awards__sprint-name',
        'streak_name': 'awards__streak-name',
        'underdog_name': 'awards__underdog-name',
        'trifecta_name': 'awards__trifecta-name',
        'record_most_cars_hour_name': 'awards__record-most-cars-hour-name',
        'triple_crown_name': 'awards__triple-crown-name'
    },
    'AwardShortName': {
        'record_best_avg_time_hour_s': 'awards__record-best-avg-time-hour-s',
        'record_most_cars_day_s': 'awards__record-most-cars-day-s',
        'most_cars_month_s': 'awards__most-cars-month-s',
        'record_best_avg_time_day_s': 'awards__record-best-avg-time-day-s',
        'best_avg_time_month_s': 'awards__best-avg-time-month-s',
        'best_%_goal_month_s': 'awards__best-%-goal-month-s',
        'goal_achiever_s': 'awards__goal-achiever-s',
        'speedster_s': 'awards__speedster-s',
        '%_goal_s': 'awards__%-goal-s',
        'sprint_s': 'awards__sprint-s',
        'streak_s': 'awards__streak-s',
        'underdog_s': 'awards__underdog-s',
        'trifecta_s': 'awards__trifecta-s',
        'record_most_cars_hour_s': 'awards__record-most-cars-hour-s',
        'triple_crown_s': 'awards__triple-crown-s'
    }
};
