const removeItems = (tree, items) => {
    return tree.map((node) => {
        if (items.includes(node.item)) {
            return null;
        }

        if (!node.children || node.children.length === 0) {
            return node;
        }

        return {
            ...node,
            children: removeItems(node.children, items),
        };
    }).filter((node) => node !== null);
}

export const deleteItems = (tree, items) => {
    if (items.length === 0) {
        return tree;
    }

    return removeItems(tree, items);
};
