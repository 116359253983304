import React, { useMemo, useState, useEffect } from 'react';
import { Grid, useAvailableFilters, applyGridFilters, applySearchValue } from 'components/Common/Grid';
import { Button } from 'library/Button';
import { useTranslation } from 'react-i18next';
import { CommonConstants } from 'Constants';

const baseGridHeaders = [
    {
        text: 'device-upgrades__grid__header--brand',
        property: 'brandName',
        className: 'hme-grid-cell-show-all-content',
        flex: 124,
    },
    {
        text: 'device-upgrades__grid__header--account',
        property: 'userEmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 188,
    },
    {
        text: 'device-upgrades__grid__header--store-number',
        property: 'storeNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 106,
    },
    {
        text: 'device-upgrades__grid__header--store-name',
        property: 'storeName',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
    },
    {
        text: 'common__device__serial-number',
        property: 'deviceSerial',
        className: 'hme-grid-cell-show-all-content',
        flex: 116,
    },
    {
        text: 'common__device__product-id',
        property: 'deviceProductID',
        className: 'hme-grid-cell-show-all-content',
        flex: 110,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--actions',
        property: 'actions',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
        flex: 74,
    },
];

const getGridHeaders = (deviceType) => {
    return deviceType === CommonConstants.deviceType.nexeo.name.toLowerCase()
        ? baseGridHeaders
        : baseGridHeaders.filter((header) => header.property !== 'deviceProductID');
};

const searchProperties = [
    'AccountOwner_EmailAddress',
    'Store_Number',
    'Store_Name',
    'Device_SerialNumber',
    'Device_Product_ID',
    'Device_MainVersion',
    'Brand_Name',
];

export const StoreDevicesGrid = ({ devices, search,selectedDeviceType, onRemoveDevice }) => {
    const [filteredDevices, setFilteredDevices] = useState(devices);
    const { t } = useTranslation();
    const gridHeaders = getGridHeaders(selectedDeviceType);

    useEffect(() => {
        const devicesFilteredBySearch = applySearchValue(devices, searchProperties, search, {
            minLength: 1,
        });

        setFilteredDevices(devicesFilteredBySearch);
    }, [devices, search]);

    const onRemove = (deviceID) => {
        setFilteredDevices(filteredDevices.filter((d) => d.Device_ID !== deviceID));
        onRemoveDevice(deviceID);
    };

    const rows = useMemo(
        () =>
            filteredDevices.map(
                ({
                    Brand_Name,
                    AccountOwner_EmailAddress,
                    Store_Number,
                    Store_Name,
                    Device_SerialNumber,
                    Device_Product_ID,
                    Device_MainVersion,
                    Device_ID,
                }) => ({
                    brandName: Brand_Name,
                    userEmailAddress: AccountOwner_EmailAddress,
                    storeNumber: Store_Number,
                    storeName: Store_Name,
                    deviceSerial: Device_SerialNumber,
                    deviceProductID: Device_Product_ID,
                    sourceVersion: Device_MainVersion,
                    actions: (
                        <Button variants={['transparent']} onClick={() => onRemove(Device_ID)}>
                            {t('common__remove')}
                        </Button>
                    ),
                }),
            ),
        [filteredDevices],
    );

    return (
        <div className="upgrade-devices-list hme-components">
            <Grid
                headers={gridHeaders}
                rows={rows}
                isLoading={false}
                rowKey="key"
                noRecordsMessage={t('common__no-devices--found')}
            />
        </div>
    );
};
