import classNames from 'classnames';

import './Separator.scss';

const defaultVariants = [];

export const SEPARATOR_VARIANTS = {
    TITLE: 'title',
    PURE: 'pure',
    HORIZONTAL: 'horizontal'
};

export const Separator = ({ variants = defaultVariants, className }) => {
    return (<span className={classNames(
        'hme-separator',
        variants.map((variant) => `hme-separator--${variant}`),
        className
    )} />);
};
