import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Loader } from 'library/Loader';
import { NotificationsList } from 'library/NotificationsList';
import { itemsProps } from 'library/SingleSelect';
import { TreeItemsProp } from 'library/Tree';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { Header } from './Header';
import { General } from './General';
import { Access } from './Access';
import { ActivityLog } from './ActivityLog';
import { Footer } from './Footer';
import { userProps } from './types/userProps';
import { logProps } from './types/logProps';

import './ViewEdit.scss';

export const ViewEdit = ({
    className,
    title,
    hasRemove,
    hasMasquerade,
    hasResendEmail,
    isLoading = false,
    isSendingEmail = false,
    isMasquerading = false,
    isAdminView = false,
    user,
    roles,
    activityLogs,
    showStatus,
    storesTree,
    masqueradeWarnings,
    isSaveButtonDisabled,
    onUserChange,
    onCancel,
    onSave,
    onRemove,
    onMasquerade,
    onResendEmail,
    validationErrors
}) => {
    const [isRemoveConfirmShown, setIsRemoveConfirmShown] = useState(false);
    const { t } = useTranslation();

    const onRemoveClick = useCallback(() => {
        setIsRemoveConfirmShown(true);
    }, [setIsRemoveConfirmShown]);

    const onCancelRemoveUser = useCallback(() => {
        setIsRemoveConfirmShown(false);
    }, [setIsRemoveConfirmShown]);

    const onConfirmRemoveUser = useCallback(() => {
        setIsRemoveConfirmShown(false);
        onRemove && onRemove();
    }, [onRemove, setIsRemoveConfirmShown]);

    if (isLoading) {
        return (
            <div className={classNames('hme-view-user', className)}>
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className={classNames('hme-view-user', 'hme-components', className)}>
            <NotificationsList />
            <Header
                title={title}
                isAdminView={isAdminView}
                hasRemove={hasRemove}
                hasMasquerade={hasMasquerade}
                hasResendEmail={hasResendEmail}
                isSendingEmail={isSendingEmail}
                isMasquerading={isMasquerading}
                masqueradeWarnings={masqueradeWarnings}
                onRemoveClick={onRemoveClick}
                onMasquerade={onMasquerade}
                onResendEmail={onResendEmail}
            />
            <div className="hme-view-user__sections">
                <div className="hme-view-user__sections__wrapper">
                    <General
                        user={user}
                        showStatus={showStatus}
                        isAdminView={isAdminView}
                        onUserChange={onUserChange}
                        validationErrors={validationErrors}
                    />
                    <Access
                        roles={roles}
                        user={user}
                        storesTree={storesTree}
                        onUserChange={onUserChange}
                        isAdminView={isAdminView}
                    />
                </div>
                {isAdminView && <ActivityLog activityLogs={activityLogs} />}
            </div>
            <Footer isSaveButtonDisabled={isSaveButtonDisabled} onCancel={onCancel} onSave={onSave} />
            <ConfirmPopupComponentGeneric
                show={isRemoveConfirmShown}
                title={t('common__double-checking')}
                message={t('add-user__confirm--remove')}
                actions={[
                    {
                        children: t('common__popup--cancel-action'),
                        onClick: onCancelRemoveUser,
                    },
                    {
                        children: t('common__popup--confirm-action'),
                        variants: ['submit'],
                        className: 'hme-users--distributor-account--confirm-button',
                        onClick: onConfirmRemoveUser,
                    },
                ]}
            />
        </div>
    );
}

ViewEdit.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    hasRemove: PropTypes.bool,
    hasMasquerade: PropTypes.bool,
    hasResendEmail: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSendingEmail: PropTypes.bool,
    isMasquerading: PropTypes.bool,
    isAdminView: PropTypes.bool,
    user: userProps,
    roles: itemsProps,
    activityLogs: PropTypes.arrayOf(logProps),
    storesTree: TreeItemsProp,
    masqueradeWarnings: PropTypes.arrayOf(PropTypes.string),
    showStatus: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool,
    onUserChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onRemove: PropTypes.func,
    onMasquerade: PropTypes.func,
    onResendEmail: PropTypes.func,
    validationErrors: PropTypes.shape({
        invalidEmailFormat: PropTypes.string
    }) || {}
};
