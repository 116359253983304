import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { t } from 'i18next'; // Non-hook version
import { pluck } from 'ramda';

import { DateLib } from '@hme-cloud/utility-common';
import { Paginate } from 'library/Paginate';
import { TileList } from 'library/TileList';
import { Grid, resetFilters, getFiltersCount } from 'components/Common/Grid'
import { useOnTrigger } from 'hooks/useTrigger';
import { PAGE_DEFAULT } from 'constants/paginationDefault';

import { accountTypePageSizes } from '../../../ManageDeviceSettings/DeviceSettings/constants';
import { AppliedBySearch, onAppliedBySearchChange, USER_TYPES } from './AppliedBySearch';
import { TargetDevicesList } from './TargetDevicesList';
import { getStatusCell } from '../helpers';
import {
    gridHeaderOptions,
    tileListHeaderOptions,
    availableSettingsFilters,
    availableStatusFilters,
    availableDeviceTypesFilters,
} from '../constants';
import {
    getRowClassName,
    getJobRowClassName,
    getTaskStatusValue,
    getTaskStatusCell,
    filterRows,
    getAllBrands,
    getAppliedBy,
    getVersions,
    getRowSettingValues,
    getRowStatusValues,
    getRowDeviceTypes,
    getSettingsGroupsText,
    getDeviceTypeName
} from '../helpers';

import './JobsList.scss';

const loadingText =  'common__loading';
const searchingText =  'common__searching';

const ALL_BRANDS_FILTER_TEXT = 'All brands';
const ALL_VERSIONS_FILTER_TEXT = 'All versions';

const getAppliedSettingsCell = ({ job, t }) => {
    if (job.sourceSnapshot.SettingName && job.settingsGroups.length > 0 && job.settingsGroups[0] !== null) {
        return (<>
            <div><span className='apply-device-settings__applied-settings__title'>{t('common__snapshot')}</span> - {job.sourceSnapshot.SettingName}</div>
            <div><span className='apply-device-settings__applied-settings__title'>{t('common__groups')}</span> - {getSettingsGroupsText(job.settingsGroups)}</div>
        </>);
    }

    if (job.sourceSnapshot.SettingName) {
        return (<>
            <div><span className='apply-device-settings__applied-settings__title'>{t('common__snapshot')}</span> - {job.sourceSnapshot.SettingName}</div>
        </>);
    }

    return (<>
        <div>{getSettingsGroupsText(job.settingsGroups) || t('apply-device-settings__grid__snapshot-is-removed')}</div>
    </>);
};

const jobToRow = ({ job, t }) => ({
    ...job,
    settingsGroupsText: getAppliedSettingsCell({ job, t }),
    devicesCount: job.destinationDevices.length,
    appliedBy: (job.user.User_FirstName || job.user.User_LastName) ?
        `${job.user.User_FirstName || ''} ${job.user.User_LastName || ''}` : job.CreatedBy,
    rowClassName: getRowClassName(job),
    getCellClassName: getJobRowClassName,
    createdAtTime: new DateLib(job.CreatedAt).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME),
    taskStatusCell: getTaskStatusCell(job.destinationDevices),
    taskStatusValue: getTaskStatusValue(job.destinationDevices),
    snapshotOfSN: job.sourceSnapshot.SettingName || job.sourceDevice.Device_SerialNumber,
    accountEmail: job.sourceSnapshot.SourceAccount || job.sourceDevice.User_EmailAddress,
    sourceBrandName: job.sourceDevice.Brand_Name || job.sourceSnapshot.SourceBrand,
    sourceVersion: job.sourceSnapshot.SourceSoftwareVersion || job.sourceDevice.Device_MainVersion,
    deviceTypeName: getDeviceTypeName(job.sourceDevice),
    destinationDevices: job.destinationDevices.map(device => ({
        ...device,
        deviceStatusCell: getStatusCell(device.ApplySettingsStatus)
    })),
    sourceProductID: job.sourceDevice?.Device_Product_ID,
});

export const JOBS_FILTER_OPTIONS = {
    sourceBrandName: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    sourceVersion: {
        title: 'common__device__version',
        allText: 'common__all-versions',
    },
    settingsGroupsText: {
        title: 'common__applied-settings-device',
    },
    taskStatusCell: {
        title: 'common__task-status',
        allText: 'common__all-statuses',
    },
    appliedBy: {
        title: 'common__applied-by',
    },
    deviceTypeName: {
        title: 'common__device-type',
        allText: 'common__all-device-types',
    },
};


export const JobsList = ({
    permissionType,
    jobs,
    filtersCount,
    isLoading,
    isSearching,
    words,
    deviceSearchType,
    resetFiltersTrigger,
    onFiltersCountChange,
}) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState(t(loadingText));
    const [total, setTotal] = useState(0);
    const [gridFilters, setGridFilters] = useState({
        sourceBrandName: [],
        sourceVersion: [],
        settingsGroupsText: [],
        taskStatusCell: [],
        deviceTypeName: [],
    });
    const [availableFilters, setAvailableFilters] = useState([]);
    const [isRendering, setIsRendering] = useState(false);
    const pageSizes = useMemo(()=> (accountTypePageSizes[permissionType] ?? accountTypePageSizes.DEFAULT), [permissionType]);
    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, [setPageNumber, setItemsPerPage]);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
        setPageNumber(PAGE_DEFAULT.page);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset)

    useEffect(() => {
        setRows(jobs.map(job => jobToRow({ job, t })));
        setTotal(jobs.length);
        setIsRendering(true);
    }, [jobs, setRows]);

    useEffect(() => {
        const brands = getAllBrands(rows);
        const appliedByUsers = getAppliedBy(rows);

        const appliedBy = appliedByUsers.map((abUser) => {
            const { isAppliedByDistributor, user } = rows.find((row) => row.appliedBy === abUser);

            return {
                appliedBy: abUser,
                isAppliedByDistributor,
                user,
            };
        });

        const uniqVersions = getVersions(rows);

        const settingValues = getRowSettingValues(rows);
        const statuses = getRowStatusValues(rows);
        const deviceTypes = getRowDeviceTypes(rows);

        setGridFilters({
            sourceBrandName: pluck('value', brands),
            sourceVersion: uniqVersions,
            settingsGroupsText: settingValues,
            taskStatusCell: statuses,
            appliedBy: appliedBy.map(ab => ab.appliedBy),
            deviceTypeName: deviceTypes,
        });

        setAvailableFilters([{
                property: 'sourceBrandName',
                allText: ALL_BRANDS_FILTER_TEXT,
                items: brands,
            }, {
                property: 'sourceVersion',
                allText: ALL_VERSIONS_FILTER_TEXT,
                items: uniqVersions.map(version => ({
                    text: version,
                    value: version,
                })),
            },
            {
                ...availableSettingsFilters,
                items: availableSettingsFilters
                    .items
                    .filter(item => settingValues.includes(item.value)),
            },
            {
                ...availableStatusFilters,
                items: availableStatusFilters.items.filter(item => statuses.includes(item.value)),
            },
            {
                property: 'appliedBy',
                allText: 'All',
                SearchItemsComponent: (props) => <AppliedBySearch permissionType={permissionType} {...props} />,
                onSearchItemsChange: onAppliedBySearchChange,
                defaultSearchValue: {
                    userType: USER_TYPES.ALL,
                    searchText: '',
                },
                noItemsFoundText: "No users were found.\nTry another name or email address.",
                items: appliedBy.map((appliedByAccount) => ({
                    value: appliedByAccount.appliedBy,
                    text: appliedByAccount.appliedBy,
                    isAppliedByDistributor: appliedByAccount.isAppliedByDistributor,
                    user: appliedByAccount.user,
                })),
            },
            {
                ...availableDeviceTypesFilters,
                items: availableDeviceTypesFilters.items.filter(item => deviceTypes.includes(item.value)),
            },
        ]);
    }, [rows, setGridFilters, setAvailableFilters]);

    useEffect(() => {
        const filteredData = filterRows(rows, words, gridFilters, deviceSearchType);

        setTotal(filteredData.length);

        const pagedJobs = filteredData.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage);

        setFilteredRows(pagedJobs);
        setIsRendering(false);
    }, [rows, words, gridFilters, deviceSearchType, pageNumber, itemsPerPage, pageSizes]);

    useEffect(() => {
        setPageNumber(PAGE_DEFAULT.page);
    }, [words]);

    useEffect(() => {
        const message = isLoading ? t(loadingText) : t(searchingText);

        setLoadingMessage(message);
    }, [isLoading, setLoadingMessage]);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    const onFiltersAndSortChange = useCallback(({ selectedFilters }) => {
        setGridFilters(selectedFilters);
        setPageNumber(PAGE_DEFAULT.page);
    }, []);
    const onFiltersChange = useCallback((newFilters) => {
        setGridFilters(newFilters);
        setPageNumber(PAGE_DEFAULT.page);
    }, []);

    return (
        <div className="tasks-table-admin">
            <Grid
                rows={filteredRows}
                headers={gridHeaderOptions}
                rowKey='taskUID'
                isLoading={isLoading || isSearching || isRendering}
                loadingText={loadingMessage}
                noRecordsMessage={t('common__no-tasks-found')}
                filters={gridFilters}
                availableFilters={availableFilters}
                ExpandComponent={TargetDevicesList}
                onFiltersChange={onFiltersChange}
                expandable
            />
            <TileList
                headers={tileListHeaderOptions}
                rows={filteredRows}
                filters={gridFilters}
                filtersCount={filtersCount}
                availableFilters={availableFilters}
                filtersOptions={JOBS_FILTER_OPTIONS}
                rowKey='taskUID'
                sortButtonName="common__filter"
                isLoading={isLoading}
                noRecordsMessage="common__no-tasks-found"
                onFiltersAndSortChange={onFiltersAndSortChange}
                onFiltersAndSortReset={onFiltersReset}
                onFiltersReset={onFiltersReset}
            />
            <Paginate
                className="apply-device-settings-paginate"
                page={pageNumber}
                recordsPerPage={itemsPerPage}
                pageSizes={pageSizes}
                total={total}
                onChange={onPaginationChange}
                hideSinglePage
            />
        </div>
    );
};
