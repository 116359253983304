import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';

export const notificationActions = {
    UPGRADE_DEVICE: 'UPGRADE_DEVICE',
    UPGRADE_IN_PROGRESS: 'UPGRADE_IN_PROGRESS',
    RECONNECT_DEVICE: 'RECONNECT_DEVICE',
    REBOOT_DEVICE: 'REBOOT_DEVICE',
};

export const showNotification = ({ action, status, t }) => {
    let notification;

    switch (action) {
        case notificationActions.UPGRADE_DEVICE:
            notification = status
                ? {
                      message: t('system-status__upgrade-device--success'),
                      type: NOTIFICATION_TYPES.SUCCESS,
                  }
                : {
                      message: t('system-status__upgrade-device--failure'),
                      type: NOTIFICATION_TYPES.ERROR,
                  };
            break;
        case notificationActions.UPGRADE_IN_PROGRESS:
            notification = {
                message: t('system-status__upgrade-in-progress'),
                type: NOTIFICATION_TYPES.ERROR
            };
            break;
        case notificationActions.RECONNECT_DEVICE:
            notification = status
                ? {
                      message: t('system-status__reconnect--success'),
                      type: NOTIFICATION_TYPES.SUCCESS,
                  }
                : {
                      message: t('system-status__reconnect--failure'),
                      type: NOTIFICATION_TYPES.ERROR,
                  };
            break;

        case notificationActions.REBOOT_DEVICE:
            notification = status
                ? {
                      message: t('system-status__reboot--success'),
                      type: NOTIFICATION_TYPES.SUCCESS,
                  }
                : {
                      message: t('system-status__reboot--failure'),
                      type: NOTIFICATION_TYPES.ERROR,
                  };
            break;
        default:
            notification = null;
    }

    if (notification) {
        addNotification(notification);
    }
};
