import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { SuccessInput } from 'library/SuccessInput';
import { SelectSnapshotModal } from './SelectSnapshotModal';

import './SelectSnapshot.scss';

export const SelectSnapshot = ({ snapshots = [], value, deviceType, isSnapshotsLoading = false, onChange }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [snapshot, setSnapshot] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        setSnapshot(snapshots.find(({ SettingTemplateID }) => SettingTemplateID == snapshot?.SettingTemplateID) || null);
    }, [snapshots, snapshot]);

    return (
        <>
            <SuccessInput
                variants={['snapshot']}
                label={t('common__snapshot')}
                value={snapshot ? snapshot.SettingName : ''}
                success={value}
                onFocus={e => e.target.blur()}
                onClick={() => setIsModalOpened(true)}
                readOnly
            >
                <span className='hme-select-snapshot'>{t('common__select-a-snapshot')}</span>
            </SuccessInput>
            <SelectSnapshotModal
                snapshots={snapshots}
                deviceType={deviceType}
                show={isModalOpened}
                onSnapshotSelect={
                    onChange &&
                    ((selectedSnapshot) => {
                        onChange(selectedSnapshot.SettingTemplateID);
                        setSnapshot(selectedSnapshot);
                        setIsModalOpened(false);
                    })
                }
                onHide={() => setIsModalOpened(false)}
                isSnapshotsLoading={isSnapshotsLoading}
            />
        </>
    );
};