import { useState, useEffect, useCallback } from 'react';

import { Button } from 'library/Button';
import { DateLib } from '@hme-cloud/utility-common';

import { Month } from './Month';
import { getMonthKey } from './utils';

import { ReactComponent as ArrowIcon } from 'assets/icons/down.svg';

import './CalendarView.scss';

const getMonths = (shownDate, count) => {
    const months = [];

    for (let i = 0; i < count; ++i) {
        const newDate = shownDate.clone().addMonths(i);

        months.push({
            shownDate: newDate,
            key: getMonthKey(newDate),
        });
    }

    return months;
};

const getFirstShownDate = (months, maxDate) => {
    const now = new DateLib();
    const max = new DateLib(maxDate);

    const monthsDate = now.clone().addMonths(months - 1);

    if (monthsDate.isAfter(max, 'month')) {
        return max.addMonths(-months + 1);
    }

    return now;
};

export const CalendarView = ({
    range,
    months = 2,
    minDate = null,
    maxDate = null,
    isSelectionMode,
    onDateClick,
    onDateBlur,
    onMouseLeave,
    onReset,
    onApply,
}) => {
    const [shownDate, setShownDate] = useState(new DateLib());
    const [monthsProps, setMonthsProps] = useState([]);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState(false);

    const onPrev = useCallback(() => {
        setShownDate(shownDate.clone().addMonths(-1));
    }, [getMonthKey(shownDate)]);

    const onNext = useCallback(() => {
        setShownDate(shownDate.clone().addMonths(1));
    }, [getMonthKey(shownDate)]);

    useEffect(() => {
        if (!monthsProps.length) {
            return;
        }

        const firstMonthShownDate = monthsProps[0].shownDate;
        const isDisabled = firstMonthShownDate.isSameMonth(minDate) || firstMonthShownDate.isBefore(minDate, 'month');

        setIsPrevDisabled(isDisabled);
    }, [monthsProps, minDate]);

    useEffect(() => {
        if (!monthsProps.length) {
            return;
        }

        const lastMonthShownDate = monthsProps[monthsProps.length - 1].shownDate;
        const isDisabled = lastMonthShownDate.isSame(maxDate, 'month') || lastMonthShownDate.isAfter(maxDate, 'month');

        setIsNextDisabled(isDisabled);
    }, [monthsProps, maxDate]);

    useEffect(() => {
        setShownDate(getFirstShownDate(months, maxDate));
    }, []);

    useEffect(() => {
        setMonthsProps(getMonths(shownDate, months));
    }, [getMonthKey(shownDate), months]);

    return (
        <div className='hme-calendar-selector' onMouseLeave={onMouseLeave}>
            <Button
                variants={['transparent']}
                className='hme-calendar-selector__nav-button hme-calendar-selector__nav-button--prev'
                onClick={onPrev}
                disabled={isPrevDisabled}
            ><ArrowIcon className='hme-calendar-selector__nav-button__icon--prev'/></Button>
            <Button
                onClick={onNext}
                variants={['transparent']}
                className='hme-calendar-selector__nav-button hme-calendar-selector__nav-button--next'
                disabled={isNextDisabled}
            ><ArrowIcon className='hme-calendar-selector__nav-button__icon--next'/></Button>
            {
                monthsProps.map((monthProps) => (
                    <Month
                        {...monthProps}
                        range={range}
                        minDate={minDate}
                        maxDate={maxDate}
                        isSelectionMode={isSelectionMode}
                        onDayClick={onDateClick}
                        onDayBlur={onDateBlur}
                    />
                ))
            }
            <div className='hme-calendar-selector__actions'>
                <Button
                    variants={['transparent']}
                    className='hme-calendar-selector__actions__reset'
                    onClick={onReset}
                >Clear</Button>
                {
                    onApply &&
                    <Button
                        variants={['submit']}
                        className='hme-calendar-selector__actions__apply'
                        onClick={onApply}
                        disabled={range === null || isSelectionMode}
                    >Apply</Button>
                }
            </div>
        </div>
    );
};
