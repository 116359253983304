export const GET_LEADERBOARDPREFERENCE = 'GET_LEADERBOARDPREFERENCE'
export const UPDATE_LEADERBOARDPREFERENCE = 'UPDATE_LEADERBOARDPREFERENCE'
export const GET_COMPANYLBVERSION = 'GET_COMPANYLBVERSION'
export const GET_ACCOUNTSUBSCRIPTIONTYPE = 'GET_ACCOUNTSUBSCRIPTIONTYPE'
export const GET_LEADERBOARDGROUPS = 'GET_LEADERBOARDGROUPS'
export const GET_LEADERBOARDTEMPLATES = 'GET_LEADERBOARDTEMPLATES'
export const SET_SORT_PARAMS_LEADERBOARD = 'SET_SORT_PARAMS_LEADERBOARD'

export const INVITELBGROUPUSER = "lbGroup/INVITELBGROUPUSER";
export const SET_INVITEDETAILS = "lbGroup/INVITELBGROUPUSER";
export const GET_LEADERBOARDGROUPUSERS = 'GET_LEADERBOARDGROUPUSERS'