import { pluck } from 'ramda';

export const resetFilters = (availableFilters, setGridFilters) => {
    const gridFilters = {};

    availableFilters.forEach(filter => {
        gridFilters[filter.property] = pluck('value', filter.items);
    });

    setGridFilters(gridFilters);
};
