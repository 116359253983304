import React from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { useViewEdit } from './hooks/useViewEdit';
import { Loader } from 'library/Loader';
import { NotificationsList } from 'library/NotificationsList';

import { GeneralInformationBlock } from '../Common/GeneralInformationBlock';
import { SubscriptionsBlock } from '../Common/SubscriptionsBlock';
import { Header } from './Header';
import { DevicesInformation } from './DevicesInformation';
import { Footer } from '../Common/Footer';

import './ViewEdit.scss';

const ViewEditComponent = () => {
    const {
        storeDetails,
        isStoreDetailsLoading,

        isNVDEnabled,
        isNitroSubscription,
        isNexeoCustomerOnlySubscription,
        isJTechCustomerOnlySubscription,
        jTechTierID,
        onJTechTierChange,
        initialVaioProvider,
        vaioSubscription,
        vaioSubscriptionList,
        onVaioSubChange,
        storeName,
        rows,
        nexeoSystemTierID,
        storeSystemTiers,
        isStoreSystemTiersLoading,
        setStoreName,
        onCancel,
        onSave,
        isSaveBtnDisabled,
        showAccountEmail,
        showNVDSubscriptionToggle
    } = useViewEdit();

    if (isStoreDetailsLoading || isStoreSystemTiersLoading) {
        return (
            <div className="hme-view-edit-store__loader">
                <NotificationsList />
                <Loader/>
            </div>
        );
    }

    return <div className="hme-view-edit-store">
        <NotificationsList />

        <div className="hme-view-edit-store__wrapper">
            <Header storeDetails={storeDetails} />

            <div className="hme-view-edit-store__content">
                <div className="hme-view-edit-store__info">
                    <section className="hme-view-edit-store__info-item">
                        <GeneralInformationBlock
                            storeName={storeName}
                            setStoreName={setStoreName}
                            storeDetails={storeDetails}
                            showAccountEmail={showAccountEmail}
                        />
                    </section>
                    <section className="hme-view-edit-store__info-item">
                        <SubscriptionsBlock
                            isNVDEnabled={isNVDEnabled}
                            showNVDSubscriptionToggle={showNVDSubscriptionToggle}
                            isNitroSubscription={isNitroSubscription}
                            isNexeoCustomerOnlySubscription={isNexeoCustomerOnlySubscription}
                            isJTechCustomerOnlySubscription={isJTechCustomerOnlySubscription}
                            jTechTierID={jTechTierID}
                            onJTechTierChange={onJTechTierChange}
                            initialVaioProvider={initialVaioProvider}
                            vaioSubscription={vaioSubscription}
                            subscriptionList={vaioSubscriptionList}
                            nitroSubscription={storeDetails.SubscriptionID || storeDetails.Account_Subscription_ID}
                            nexeoSystemTierID={nexeoSystemTierID}
                            storeSystemTiers={storeSystemTiers}
                            onVaioSubChange={onVaioSubChange}
                        />
                    </section>
                </div>

                <DevicesInformation rows={rows} />
            </div>
        </div>

        <Footer onCancel={onCancel} onSave={onSave} isSaveBtnDisabled={isSaveBtnDisabled} />
    </div>;
};

export const ViewEdit = compose(withHMELayout())(ViewEditComponent);
