import * as displaysetting from '../actionTypes/DisplaySettings/DisplaySettings'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'

function getDisplaySettingSuccess (displaySettings) {
  return {
    type: displaysetting.GET_DISPLAYPREFERENCE,
    displaySettings: displaySettings
  }
}

function SaveDisplaySettingSuccess (displaySettings) {
  return {
    type: displaysetting.SAVE_DISPLAYPREFERENCE,
    displaySettings: displaySettings
  }
}

export const getDisplaySetting = () => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getDisplaySettings
  return (dispatch, getState) => {
    const api = new Api()
    api.getData(url, data => {
      let displaySettingsData = data
      dispatch(getDisplaySettingSuccess(displaySettingsData))
    })
  }
}

export const SaveDisplaySetting = (data, callback) => {
  const url = Config.apiBaseUrl + CommonConstants.apiUrls.saveDisplaySettings
  return (dispatch, getState) => {
    const api = new Api()
    api.postData(url, data, result => {
      dispatch(SaveDisplaySettingSuccess(data))
      callback(result)
    })
  }
}
