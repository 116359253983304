import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'ramda';

import { CommonConstants } from 'Constants';
import { withAccess } from 'HOCs/withAccess';
import { checkAccess } from 'actions/permissions';
import { UsersList } from './UsersList';
import { DistributorCreateUser } from 'pages/Users/ViewEdit/DistributorCreateUser';
import { DistributorEditUser } from 'pages/Users/ViewEdit/DistributorEditUser';

const UsersComponents = ({
    addUserRedirectionURL,
    editUserRedirectionURL,
    accountUuid
}) => {
    return (
        <Routes>
            <Route path='/add-user' element={<DistributorCreateUser />} />
            <Route path='/edit-user' element={<DistributorEditUser />} />
            <Route index path='/' element={
                <UsersList
                    addUserRedirectionURL={addUserRedirectionURL}
                    editUserRedirectionURL={editUserRedirectionURL}
                    accountUuid={accountUuid}
                />
            } />
        </Routes>
    );
};

const hasAccess = checkAccess(CommonConstants.externalPermissions.ManageUsers);

export const Users = compose(
    withAccess({
        checkAccess: () => hasAccess
    })
)(UsersComponents);
