/* eslint-disable no-undefined */
/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import { Modal } from 'library/Modal';
import { Loader } from 'library/Loader';
import { Label } from 'library/Label';

import './LoadingModal.scss';
import { useTranslation } from 'react-i18next';

const defaultVariants = [];

export const LoadingModal = ({ message, className, variants = defaultVariants, ...modalProps }) => {
    const { t } = useTranslation();
    return (
        <Modal {...modalProps} dialogClassName={classNames('hme-loading-modal', className)}>
            <Loader variants={variants} />
            <Label>{message === undefined ? t('common__loading') : message}</Label>
        </Modal>
    );
};
