import React from 'react';

import { CenterMessage } from '../CenterMessage';

import { ReactComponent as SuccessIcon } from '../../../../assets/icons/successLarge.svg';

export const CenterSuccessMessage = ({ children }) => (
    <CenterMessage
        icon={<SuccessIcon className="center-message-icon-large mb-4" />}
    >
        {children}
    </CenterMessage>
);
