/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Modal } from 'library/Modal';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { Config } from 'Config';
import { accountAgreeTOS } from 'actions/account';
import {
    getNewTOSFromLocalStorage,
    getnewTOSFromToken,
    getProfile,
    setNewTOSInLocalStorage
} from 'services/Auth';

const UpdatedTOSModal = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const { t } = useTranslation();
    const url = Config.storageUrl;
    useEffect(()=>{
        setShow(!(getNewTOSFromLocalStorage() || getnewTOSFromToken()));
        const { User_UID, User_ID, User_OwnerAccount_ID } = getProfile();
        setData({ uuId: User_UID, UserID: User_ID, ownerAccountId: User_OwnerAccount_ID });
    }, []);

    const handleSubmit = () =>{
        accountAgreeTOS(data, (result)=>{
            if (result.status) {
                setNewTOSInLocalStorage(true);
                setShow(false);
            }
        });
    };
    const message = <>
        <p>{t('updatedTOS__modal-point-one')}</p>
        <p>{<Trans i18nKey="updatedTOS__modal-point-two">
            <a href={t('footer__user-agreement__url', { url })} target="_blank"
                className="fw-bold" rel="noreferrer">Service Agreement</a>
        </Trans>}</p>
        <p>{t('updatedTOS__modal-point-three')}</p>
    </>;
    return (
        <Modal
            backdrop="static"
            show={show}
            actions={[{
                children: t('common__proceed'),
                variants: ['submit'],
                onClick: () => {
                    handleSubmit();
                }
            }]}
            dialogClassName={classNames('confirm-popup')}
        >{message}</Modal>
    );
};

export default UpdatedTOSModal;
