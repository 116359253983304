import { useCallback } from 'react';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { NotificationCenterService } from 'services/NotificationCenter';

export const useRemoveNotiication = () => {
    const {
        run: runRemove,
        isLoading: isRemoving
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const removeNotification = useCallback((id) => {
        return runRemove(NotificationCenterService.delete(id));
    }, []);

    return {
        isRemoving,
        removeNotification
    }
};
