import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paginate } from 'library/Paginate';
import { Grid } from 'components/Common/Grid';
import { PAGE_DATA } from 'pages/Users/ViewEdit/Common/constants';

export const ProvidersList = ({
    rowKey = "id",
    headers,
    rows,
    filters,
    availableFilters,
    onFiltersChange,
    onSortChange,
    sortSelection,
    page,
    recordsPerPage,
    total,
    onPaginationChange
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid
                rowKey={rowKey}
                headers={headers}
                rows={rows}
                noRecordsMessage={t('vaio_grid_no-providers-found')}
                filters={filters}
                availableFilters={availableFilters}
                onFiltersChange={onFiltersChange}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
                className="hme-partner-providers__list"
            />
            <Paginate
                className="hme-partner-providers__paginate"
                page={page}
                recordsPerPage={recordsPerPage}
                total={total}
                onChange={onPaginationChange}
                pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
            />
        </>
    );
};
