import { USER_TYPES } from './AppliedBySearch';

const filterByUserType = (items, userType) => {
    switch (userType) {
        case USER_TYPES.ALL:
            return items;

        case USER_TYPES.DISTRIBUTORS:
            return items.filter(({ isAppliedByDistributor }) => isAppliedByDistributor);

        case USER_TYPES.CUSTOMER:
            return items.filter(
                ({ isAppliedByDistributor, user }) => !isAppliedByDistributor && user && user.User_ID,
            );

        case USER_TYPES.HME:
            return items.filter(
                ({ user }) => user && !user.User_ID,
            );
        default:
            return [];
    }
};

const filterByWords = (items, searchText) => {
    const words = searchText.toLowerCase().split(' ');

    return items.filter(({ value }) => words.every((word) => value.toLowerCase().includes(word)));
};

export const onAppliedBySearchChange = (items, filters) => {
    const fiteredItemsByUserType = filterByUserType(items, filters.userType);

    if (filters.searchText.length < 3) {
        return fiteredItemsByUserType;
    } else {
        return filterByWords(fiteredItemsByUserType, filters.searchText);
    }
};
