import { useTranslation } from 'react-i18next';

import { SaveFooter } from 'library/SaveFooter';
import { OnOff } from 'library/OnOff';
import { Label } from 'library/Label';
import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';

import { Header } from '../BrandSharing/Header';

import './CorporateBrandSharing.scss';

export const CorporateBrandSharing = ({
    isLoading,
    title,
    viewFranchise,
    isSaveEnabled,
    onViewFranchiseChange,
    onSubmit,
    onCancel,
}) => {
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <div className='hme-corporate-brand-sharing'>
                <Loader />
            </div>
        );
    }

    return (
        <div className='hme-corporate-brand-sharing'>
            <NotificationsList />
            <div className='hme-corporate-brand-sharing__content'>
                <Header title={title}/>

                <div className='hme-corporate-brand-sharing__field-item'>
                    <Label>{t('profile__brand-sharing--corporate__view-franchise')}</Label>
                    <OnOff checked={viewFranchise} onChange={onViewFranchiseChange} />
                </div>
            </div>
            <SaveFooter isSubmitEnabled={isSaveEnabled} onSave={onSubmit} onCancel={onCancel} />
        </div>
    );
};
