import * as displaysetting from '../../actionTypes/DisplaySettings/DisplaySettings'

const initialState = {
  displaySettings: {
    'UserID': '',
    'TimeFormat': 'ss',
    'PreferenceDash': '',
    'DashboardOrder': 'Current|Day, Previous|Day',
    'PreferencePrimeColors': '#00b027|#f3be08|#b40000',
    'PreferenceDashSort': 't1Avg | ASC',
    'PreferenceDefaultView': 'Day',
    'PreferenceDashPullins': '4',
    'PreferenceDashSortC': 't1Avg | ASC',
    'PreferenceStoreColumnName': 'Include',
    'PreferenceDashMetric': '2000'
  }
}

export default function displaySettings (state = initialState, action) {
  switch (action.type) {
    case displaysetting.GET_DISPLAYPREFERENCE:
      state.displaySettings = action.displaySettings
      return {
        ...state
      }
    default:
      return state
  }
}
