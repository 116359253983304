import React, { useState, useCallback } from 'react';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { useScreenWidthMatch } from 'globalState/slices';
import classNames from 'classnames';
import { TreeInput } from 'library/TreeInput';
import { Checkbox } from 'library/Checkbox';
import { Label } from 'library/Label';
import { SearchInput } from 'components/Inputs/SearchInput';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

const SEARCH_MIN_SYMBOLS = 3;

export const StoresSection = ({
    storesSearch,
    groupedStores,
    selectedStores,
    isOnlySelectedStoresShown,
    toggleSelectedStoresOnly,
    onGroupStoreChange,
    onInfoClick,
    onSearchChange,
}) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState(storesSearch);
    const { isMobile } = useScreenWidthMatch();

    const onSearchValueChange = useCallback(
        (newValue) => {
            setSearchValue(newValue);

            if (newValue.length === 0 || newValue.length >= SEARCH_MIN_SYMBOLS) {
                onSearchChange && onSearchChange(newValue);
            }
        },
        [onSearchChange],
    );

    const isOnlySelectedEmpty = isOnlySelectedStoresShown && selectedStores.length === 0;

    return (
        <Section
            number={1}
            className="text-connect__sections__section--stores"
            title={t('text-n-connect_tabs__section__title--stores')}
        >
            <div className="text-connect__sections__section__search-stores">
                <div className="text-connect__sections__section__search-stores__labels">
                    <Label>{t('common__to')}</Label>
                    {selectedStores.length > 0 && (
                        <Label>
                            <span>{selectedStores.length}</span> {t('common__stores')}
                        </Label>
                    )}
                </div>
                <div className="text-connect__sections__section__search-stores__input">
                    <SearchInput
                        value={searchValue}
                        placeholder={t(
                            isMobile
                                ? 'text-n-connect__search_stores__placeholder--mobile'
                                : 'text-n-connect__search_stores__placeholder',
                        )}
                        showClearIcon={true}
                        onChange={onSearchValueChange}
                    />
                    <InfoIcon
                        width={22}
                        height={22}
                        className="text-connect__sections__section__info-icon"
                        onClick={onInfoClick}
                    />
                </div>
            </div>
            <div
                className={classNames('text-connect__sections__section__search-stores__tree-wrapper', {
                    'text-connect__sections__section__search-stores__selected-shown': isOnlySelectedStoresShown,
                })}
            >
                <div className="text-connect__sections__section__search-stores__tree-header-block">
                    <Checkbox
                        checked={isOnlySelectedStoresShown}
                        onChange={toggleSelectedStoresOnly}
                        label={t('common__view-selected-stores')}
                    />
                </div>

                <TreeInput
                    value={selectedStores}
                    onChange={onGroupStoreChange}
                    selectable
                    isExpandedDefault
                    tree={isOnlySelectedEmpty ? [] : groupedStores}
                    emptyTreeMessage={
                        isOnlySelectedEmpty ? 'common__view-selected-stores--no-stores-selected' : 'common__no-results'
                    }
                />
            </div>
        </Section>
    );
};
