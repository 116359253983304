import React, { useState } from 'react';
import {DateLib} from 'helpers/DateLib';

import { getTokenExpirationType } from 'constants/token';

import { ExpiryDateCell } from '../../ExpiredDateCell';
import { Actions } from '../Actions';

const DEFAULT_DATE_FORMAT = DateLib.FORMAT_TYPES.DATE_AND_TIME;

const getFormattedDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => date ? new DateLib(date).format(dateFormat) : '';

const expirationTypeRowClassNames = {
    'expired': 'hme-partner-token-management__row--expired',
    'within-month-before-expiration': 'hme-partner-token-management__row--within-month-expired'
};

export const useTokensGrid = ({ tokens }) => {
    const [isLoadingModalShown, setIsLoadingModalShown] = useState(false);

    const rows = tokens.map((token) => {
        const expirationType = getTokenExpirationType(token.expiryDate);
        const formattedExpiryDate = getFormattedDate(token.expiryDate, DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT);

        return {
            ...token,
            originalExpiryDate: token.expiryDate,
            expiryDate: <ExpiryDateCell expirationType={expirationType} expiryDate={formattedExpiryDate} />,
            createdAt: getFormattedDate(token.createdAt),
            updatedAt: getFormattedDate(token.updatedAt),
            Actions: <Actions tokenId={token.id} tokenUID={token.uid} />,
            rowClassName: expirationTypeRowClassNames[expirationType]
        };
    });

    return {
        rows,
        isLoadingModalShown
    };
};
