import React, { useMemo } from 'react';
import classNames from 'classnames';

import { InputComponent } from 'components/Inputs';
import { ErrorLabel } from 'library/ErrorLabel';

import './Input.scss';

export const Input = ({ error, variants = [], className = '', ...props }) => {
    const newVariants = useMemo(() => {
        return [...variants, (error ? 'invalid' : '')];
    }, [error, variants]);

    return (
        <div className="hme-account-input">
            <InputComponent
                variants={newVariants}
                className={classNames(
                        'hme-account-input__wrapper',
                        className
                )}
                {...props}
            />
            {error && <ErrorLabel>{error}</ErrorLabel>}
        </div>
    );
};
