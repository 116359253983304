/* eslint-disable react/prop-types */
import React from 'react';

import { Title } from 'library/Title';

import './Header.scss';

export const Header = ({ title }) => (
    <div className="leaderboard-templates-header-generic">
        <Title>{title}</Title>
    </div>
);
