import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { DeviceStatus } from 'library/DeviceStatus';

export const StoreStatusesMobile = ({ devices }) => {
    const { t } = useTranslation();
    return (
        <div>
            {devices.map((device) => {
                const {
                    Device_UID: deviceUUID = '',
                    Device_IsActive: online = 0,
                    Device_IsPreconfigured: isPreconfigured = 0,
                    Device_Name: name = '',
                    Device_MainVersion: version = '',
                    Device_Product_ID: productID = '',
                    Device_SerialNumber: serialNumber = ''
                } = device;

                return (
                    <div key={deviceUUID}>
                        <div
                            className="hme-stores-mobile--partner__store__info__item"
                            key={deviceUUID}
                        >
                            <span className="hme-stores-mobile--partner__tile__sub__title">
                                {t('common__status')}
                            </span>
                            <div className="hme-stores-mobile--partner__tile__sub__value">
                                <DeviceStatus
                                    online={online}
                                    isPreconfigured={isPreconfigured}
                                    name={name}
                                    version={version}
                                    deviceUid={deviceUUID}
                                />
                            </div>
                        </div>
                        <>
                            <div className="hme-stores-mobile--partner__store__info__item">
                                <span className="hme-stores-mobile--partner__tile__sub__title">
                                    {t('common__device__serial-number--text')}
                                </span>
                                <span className="hme-stores-mobile--partner__tile__sub__value">
                                    <span className="hme-stores-mobile--partner__tile__sub__value">
                                        {serialNumber}
                                    </span>
                                </span>
                            </div>
                            <div className="hme-stores-mobile--partner__store__info__item">
                                <span className="hme-stores-mobile--partner__tile__sub__title">
                                    {t('common__device__product-id')}
                                </span>
                                <span className="hme-stores-mobile--partner__tile__sub__value">
                                    <span className="hme-stores-mobile--partner__tile__sub__value">
                                        {productID}
                                    </span>
                                </span>
                            </div>
                            <div className="hme-stores-mobile--partner__store__info__item">
                                <span className="hme-stores-mobile--partner__tile__sub__title">
                                    {t('common__device__version')}
                                </span>
                                <span className="hme-stores-mobile--partner__tile__sub__value">
                                    {version}
                                </span>
                            </div>
                        </>
                    </div>
                );
            })}
        </div>
    );
};

StoreStatusesMobile.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
