import { CommonConstants } from 'Constants';
import { pathSatisfies } from 'ramda';
import { DeviceUpgradeStatuses } from 'constants/NEXEOUpgradeStatuses';

const { deviceType: deviceTypes } = CommonConstants;

export const checkIsUpgradeVersionOptionsDisabled = ({
    device,
    peripheralDevice,
    upgradeVersionOptions,
    isScheduledUpgrade,
    currentUpgradeTaskStatus,
}) => {
    const isBaseStationScheduledUpgrade = isScheduledUpgrade && peripheralDevice.deviceTypeID === deviceTypes.nexeo.id;

    return (
        !device.Device_IsActive ||
        device.Device_IsPreconfigured ||
        !upgradeVersionOptions.length ||
        isBaseStationScheduledUpgrade ||
        (pathSatisfies(
            (p) => p !== null, // upgrade tasks object should exists and current task shoul not be present - change later if logic will be different
            ['upgradeTasks', 'currentUpgradeTask'],
            peripheralDevice,
        ) &&
            ![
                DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED,
                DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED,
                DeviceUpgradeStatuses.UPDATE_FAILED,
            ].includes(currentUpgradeTaskStatus))
    );
};
