import { DateLib } from '@hme-cloud/utility-common';

export const formatInstallSchedulePayload = ({ installRunDate, installScheduleDays, installTo, installFrom } = {}) => {
    if (!installRunDate) {
        return null;
    }

    const [installFromTime] = installFrom.split(' ');
    const [installToTime] = installTo.split(' ');

    return {
        startDate: installRunDate,
        weekDays: installScheduleDays.split(',').map(Number),
        startTime: `${installFromTime}:00`,
        endTime: `${installToTime}:00`,
    };
};

const getTime = (hours, minutes) => (new DateLib(0).addHours(hours).addMinutes(minutes).date.toISOString());

export const formatInstallScheduleResult = ({ startDate, weekDays, startTime, endTime } = {}) => {
    if (!startDate) {
        return null;
    }

    const [startHours, startMinutes] = startTime.split(':');
    const [endHours, endMinutes] = endTime.split(':');

    // TODO TBD - should we move this logic to the backend?
    return {
        startDate: new Date(startDate).toISOString(),
        weekDays: weekDays?.join(',') || '',
        // transform from '23:00:00' to iso string '1970-01-01T23:00:00.000Z' to be consistent with the format of upgrade start/end time
        startTime: getTime(startHours, startMinutes),
        endTime: getTime(endHours, endMinutes),
    };
};
