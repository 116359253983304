import * as headers from '../../actionTypes/Headers/headers'

const headerDetails = {
  userEmail: null,
  inviteResponseParams: {
    ParticipantID: null,
    Status: false
  }
}
export default function (state = headerDetails, action) {
  switch (action.type) {
    case headers.SET_USER_EMAIL:
      state.userEmail = action.userEmail
      return {
        ...state
      }
    case headers.SET_INVITE_RESPONSE:
    state.inviteResponseParams = action.inviteResponseParams;
      return {
        ...state
      };
    default:
      return state
  }
}
