import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Title as LibTitle } from 'library/Title';
import { Button } from 'library/Button';
import { TileDeviceList } from '../TileDeviceList';
import { nexeoTranslationKeysMap } from '../../../Common/constants';
import { PeripheralDevicesList } from '../PeripheralDevicesList';
import { getLastUpgradeDate } from '../helpers';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

const mapExpandedDevices = (devices, isExpanded) => devices.reduce((acc, d) => {
    if (d.isMultiple) {
        acc[d.deviceTypeID] = isExpanded;
    }

    return acc;
}, {});

const prepareDevices = ({
    device,
    peripheralDevices
}) => {
    const preparedPeripheralDevices = {};

    if (typeof peripheralDevices !== 'object') {
        return [];
    }

    Object.keys(peripheralDevices).forEach((deviceTypeKey) => {
        const peripheralDevice = peripheralDevices[deviceTypeKey];

        if (device.Device_IsPreconfigured) {
            (peripheralDevice.devices || []).forEach((d) => {
                d.macAddress = '';
                d.mainVersion = '';
                d.serialNumber = '';
            });
        }

        if (!peripheralDevice.isMultiple) {
            peripheralDevice.device = peripheralDevice.devices[0];
            const { lastUpgradeTask = null } = peripheralDevice.upgradeTasks || {};

            const isOnLatestVersion =
                !peripheralDevice.maxUpgradeVersion ||
                (peripheralDevice.minMainVersion &&
                    BuildVersions.semverGte(peripheralDevice.minMainVersion, peripheralDevice.maxUpgradeVersion)
                );

            peripheralDevice.onLatestVersion =
                !device.Device_IsPreconfigured && isOnLatestVersion ? <Trans i18nKey="common__yes" /> : <Trans i18nKey="common__no" />;
            peripheralDevice.latestFirmwareAvailable = peripheralDevice.maxUpgradeVersion;
            peripheralDevice.lastUpgradeDate = getLastUpgradeDate(lastUpgradeTask);
        }

        peripheralDevice.modelName = (
            <Trans
                i18nKey={
                    nexeoTranslationKeysMap[peripheralDevice.modelName] ||
                    peripheralDevice.modelName
                }
            />
        );
        peripheralDevice.expandHidden = !peripheralDevice.isMultiple;

        preparedPeripheralDevices[deviceTypeKey] = peripheralDevice;
    });

    return Object.values(preparedPeripheralDevices);
};

const GRID_OPTIONS = [
    {
        text: 'common__device__type--nexeo-device',
        flex: 1.3,
        property: 'modelName',
        className: 'hme-grid-model-name',
        headerClassName: 'hme-grid-model-name'
    },
    {
        text: 'common__device__serial-number',
        tabletScreenText: 'common__device__serial-number--text',
        flex: 1.4,
        property: 'device.serialNumber',
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'system-status__mac-address',
        flex: 1.5,
        property: 'device.macAddress'
    },
    {
        text: 'system-status__last-upgrade',
        flex: 0.9,
        property: 'lastUpgradeDate',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        flex: 0.9,
        property: 'device.mainVersion',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__latest-firmware-available',
        flex: 0.9,
        property: 'latestFirmwareAvailable',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        flex: 0.9,
        property: 'onLatestVersion',
        className: 'hme-grid-centered-cell hme-grid-cell--uppercase',
        headerClassName: 'hme-grid-centered-cell'
    }
];

const MOBILE_GRID_OPTIONS = [
    {
        column: GRID_OPTIONS.filter(({ property }) => property !== 'modelName') // removes "model-name" field for non desktop tile-list
    }
];

export const useDevicesGrid = ({
    device,
    peripheralDevices,
    deviceScheduledUpgrade
}) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([]);
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [expandedDevices, setExpandedDevices] = useState({});

    useEffect(() => {
        if (!device || !peripheralDevices || !deviceScheduledUpgrade) return;

        const preparedDevices = prepareDevices({
            device,
            peripheralDevices
        });

        setRows(preparedDevices);
        setExpandedDevices(mapExpandedDevices(preparedDevices, false));
    }, [device, peripheralDevices, deviceScheduledUpgrade]);

    const handleExpandAllChange = useCallback(() => {
        setIsAllExpanded(!isAllExpanded);
        setExpandedDevices(mapExpandedDevices(rows, !isAllExpanded));
    }, [isAllExpanded, rows]);

    const handleExpandChange = useCallback((deviceTypeID, isExpanded) => {
        setExpandedDevices((currentExpandedDevices) => ({
            ...currentExpandedDevices,
            [deviceTypeID]: isExpanded
        }));
    }, []);

    useEffect(() => {
        const expandedDeviceKeys = Object.keys(expandedDevices);

        const allExpanded = expandedDeviceKeys.every((k) => expandedDevices[k]);
        if (allExpanded) {
            setIsAllExpanded(true);
            return;
        }

        const allCollapsed = expandedDeviceKeys.every((k) => !expandedDevices[k]);
        if (allCollapsed) {
            setIsAllExpanded(false);
        }
    }, [expandedDevices]);

    const isExpandAllShown = useMemo(() => (
        !device?.Device_IsPreconfigured && rows.some((r) => r.isMultiple)
    ), [device, rows]);

    const Title = <div className="hme-system-status-devices-grid__headline">
        <LibTitle>{t('system-status__title__system-devices')}</LibTitle>
        {isExpandAllShown && (
            <Button
                variants={['transparent']}
                className="hme-system-status-devices-grid__headline-expand-all"
                onClick={handleExpandAllChange}
            >
                {isAllExpanded ? t('common__collapse-all') : t('common__expand-all')}
            </Button>
        )}
    </div>;

    return {
        Title,
        rows,
        rowKey: 'deviceTypeID',
        headers: GRID_OPTIONS,
        mobileHeaders: MOBILE_GRID_OPTIONS,
        gridProps: {
            ExpandComponent: PeripheralDevicesList,
            isAllExpanded,
            onExpandChange: handleExpandChange
        },
        tileProps: {
            ListItemComponent: TileDeviceList,
            listItemComponentProps: {
                isAllExpanded,
                expandedDevices,
                onExpandChange: handleExpandChange
            }
        }
    };
};
