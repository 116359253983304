import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PureButton } from 'library/PureButton';

import './HamburgerMenuButton.scss';

export const HamburgerMenuButton = ({ onClick, isOpen = false, className, ...props }) => {
    return (
        <PureButton
            onClick={onClick}
            className={classNames(
                'hme-hamburger-menu',
                {
                    'hme-hamburger-menu--opened': isOpen
                },
                className
            )}
            {...props}
        >
            <span></span>
        </PureButton>
    );
};

HamburgerMenuButton.propType = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string
};
