
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getToken, isAdmin } from 'services/Auth';
import { Config } from 'Config';

import './GetTraining.css';

export const GetTraining = () => {
    const { t } = useTranslation();

    const url = Config.trainingPortalBaseUrl + (isAdmin() ? '' : '/?token=' + getToken());

    return (
        <div className="tile-box get-training-box">
            <div className="get-training-video-box">
                <div className="get-training-video-box-titilebox">
                    <span className="get-training-video-box-titlebox-title">HME</span>
                    <button
                        onClick={() => {
                            window.open(url, '_blank');
                        }}
                        className="get-training-video-box-titlebox-button"
                    >
                        <span className="fa fa-play get-training-video-box-titlebox-playicon" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <div className="get-training-content">
                <h3 className="tile__training">{t('welcome__tile--training__title')}</h3>
                <p className="get-training-content-p">{t('welcome__tile--training__content')}</p>
                <a href={url} className="get-training-link" target="_blank" rel="noreferrer">
                    {t('welcome__tile--training__link')}
                </a>
            </div>
        </div>
    );
};
