import { useCallback } from 'react';

export const useMarkReadNotification = ({ setNotifications }) => {
    return useCallback((id) => {
        setNotifications((notifications) => notifications.map((notification) => {
            if (notification.id !== id) {
                return notification;
            }

            return {
                ...notification,
                readDate: new Date(),
            };
        }));
    }, []);
};
