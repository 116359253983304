import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'library/Button';
import { useConnectableDevice } from 'hooks/useConnectableDevice';

import { checkIsUpgradeAvailable } from '../../../Common/helpers';
import { PageTitle } from '../../../Common/PageTitle';

export const useDeviceActions = ({
    device,
    deviceSettings,
    onDeviceReconnect,
    onDeviceReboot
}) => {
    const { t } = useTranslation();

    const [isConfirmShown, setIsConfirmShown] = useState(false);
    const [confirmWindowDetails, setConfirmWindowDetails] = useState(null);

    const { isConnectable: isDeviceConnectable, connectUrl, hasAccessToRemoteConnect } =
        useConnectableDevice({
            isActive: Boolean(device.Device_IsActive),
            isPreconfigured: Boolean(device.Device_IsPreconfigured),
            deviceName: device.Device_Name || device.DeviceType_Name,
            deviceUserID: device.Device_UID,
            deviceVersion: device.Device_MainVersion
        });

    const isRemoteActionEnabled = useMemo(() => {
        const isUpgradeAvailable = checkIsUpgradeAvailable(deviceSettings);

        return (
            isDeviceConnectable &&
            isUpgradeAvailable &&
            !deviceSettings.upgradeInProgress
        );
    }, [isDeviceConnectable, deviceSettings]);

    const confirmPopupOptions = useMemo(() => ({
        reconnect: {
            message: t('system-status__reconnect-confirm-message'),
            handler: onDeviceReconnect
        },
        reboot: {
            message: t('system-status__reboot-confirm-message'),
            handler: onDeviceReboot
        }
    }), [onDeviceReconnect, onDeviceReboot, t]);

    const onConfirmHide = useCallback(() => {
        setIsConfirmShown(false);
    }, []);

    const showConfirm = useCallback((confirmType) => {
        setConfirmWindowDetails(confirmPopupOptions[confirmType]);
        setIsConfirmShown(true);
    }, [confirmPopupOptions]);

    const onForceReconnectClick = useCallback(() => showConfirm('reconnect'), [showConfirm]);
    const onRebootClick = useCallback(() => showConfirm('reboot'), [showConfirm]);

    const ActionButtons = useMemo(() => {
        if (!hasAccessToRemoteConnect) return [];

        return [
            <Button key="remote-access" as={Link} to={connectUrl} disabled={!isDeviceConnectable}>
                {t('system-status__action__remote-access')}
            </Button>,
            <Button key="force-reconnect" onClick={onForceReconnectClick} disabled={!isRemoteActionEnabled}>
                {t('system-status__action__force-reconnect')}
            </Button>,
            <Button key="reboot-system" onClick={onRebootClick} disabled={!isRemoteActionEnabled}>
                {t('system-status__action__reboot-system')}
            </Button>
        ];
    }, [
        connectUrl,
        isDeviceConnectable,
        isRemoteActionEnabled,
        hasAccessToRemoteConnect,
        onForceReconnectClick,
        onRebootClick,
        t
    ]);

    const title = useMemo(() => (
        <PageTitle
            deviceTypeTranslation="common__device__type--nexeo"
            storeName={device?.Store_Name}
            storeNumber={device?.Store_Number}
        />
    ), [device]);

    const ConfirmProps = useMemo(() => ({
        show: isConfirmShown,
        message: confirmWindowDetails?.message,
        cancelVerb: t('common__popup--cancel-action'),
        confirmationVerb: t('common__popup--confirm-action'),
        onHide: onConfirmHide,
        onConfirm: () => confirmWindowDetails?.handler(device)
    }), [isConfirmShown, confirmWindowDetails?.message, onConfirmHide, device]);

    return {
        title,
        ActionButtons,
        ConfirmProps
    };
};
