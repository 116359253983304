import React from "react";
import compareDates from 'helpers/sortComparators/date';
import { VaioFilterSearch } from "../VaioFilterSearch";

/* eslint-disable guard-for-in */

const valueExists = (value, uniqueColumnItems) => {
    for (const val of uniqueColumnItems) {
        if (val.text === value) return true;
    }
    return false;
};

/**
 * Indentify unique values in each column to create filters list
 * @param  {Array} providers list of providers
 * @return {Object} unique items from each column
 */
export const columnFilters = (providers) => {
    const uniqueColumnItems = { updatedBy: [], name: [], outboundAudio: [], botAudioSourceType: [], audioMode: [] };
    for (const provider of providers) {
        for (const column in uniqueColumnItems) {
            const isDuplicate = valueExists(provider[column], uniqueColumnItems[column]);
            if (!isDuplicate) {
                uniqueColumnItems[column].push({ text: provider[column], value: provider[column] });
            }
        }
    }

    return uniqueColumnItems;
};

/**
 * Create format required for setAvailableFilters & setGridFilters
 * @param  {Array} formattedProviders list of providers
 * @return {Object} filter details for setAvailableFilters & setGridFilters
 */

// SearchItemsComponent called in FilterCell component
export const formatFilters = (formattedProviders, onSearchItemsChange, t) => {
    const filters = columnFilters(formattedProviders);
    const formattedAvailableFilters = [{
        property: 'name',
        allText: t('common__all-providers'),
        items: filters.name,
        // called in FilterCell component
        SearchItemsComponent: (props) => <VaioFilterSearch
            searchBy="name"
            placeholder={t('vaio-provider-page__search__placeholder-type-provider-name')}
            {...props}
        />,
        onSearchItemsChange: onSearchItemsChange,
        defaultSearchValue: { searchText: '', searchBy: 'name' },
        noItemsFoundText: 'No users found'
    }, {
        property: 'audioMode',
        allText: t('common__all-inbound-audio-types'),
        items: filters.audioMode,
    }, {
        property: 'outboundAudio',
        allText: t('common__all-outbound-audio-types'),
        items: filters.outboundAudio,
    }, {
        property: 'botAudioSourceType',
        allText: t('common__all-voice-agent-types'),
        items: filters.botAudioSourceType,
    }, {
        property: 'updatedBy',
        allText: t('common__all-users'),
        items: filters.updatedBy,
        SearchItemsComponent: (props) => <VaioFilterSearch
            searchBy="updatedBy"
            placeholder={t('vaio-provider-page__search__placeholder-type-user-email')}
            {...props}
        />,
        onSearchItemsChange: onSearchItemsChange,
        defaultSearchValue: { searchText: '', searchBy: 'updatedBy' },
        noItemsFoundText: 'No users found'
    }];

    const formattedGridFilters = {
        name: filters.name.map((item) => item.text),
        outboundAudio: filters.outboundAudio.map((item) => item.text),
        audioMode: filters.audioMode.map((item) => item.text),
        updatedBy: filters.updatedBy.map((item) => item.text),
        botAudioSourceType: filters.botAudioSourceType.map((item) => item.text)
    };

    return { formattedAvailableFilters, formattedGridFilters };
};

export const sortProvidersByColumn = (providers, columnName, sortDirection) => {
    // Sort By Date
    switch (columnName) {
        case 'keyExpiration':
        case 'updatedDate':
            return [...providers].sort((a, b) => compareDates(a[columnName], b[columnName], sortDirection));
        default:
            break;
    }

    // Sort By String
    return [...providers].sort(
        (a, b) => a[columnName].toLowerCase().localeCompare(b[columnName].toLowerCase()) * sortDirection,
    );
};

export const paginationFilter = (providers, page, providersPerPage) => {
    const startIndex = page * providersPerPage;
    return providersPerPage === -1 ? providers : providers.slice(startIndex, startIndex + providersPerPage);
};
