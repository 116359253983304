import React, { useState, useEffect, useCallback } from 'react';

import { deviceConfig } from 'constants/device';
import {
    Grid,
    useAvailableFilters,
    applyGridFilters,
    applySearchValue,
    resetFilters,
    getFiltersCount,
} from 'components/Common/Grid';
import { useOnTrigger } from 'hooks/useTrigger';
import { StatusCell } from './StatusCell';

import './DevicesList.scss';

const { getLaneType } = deviceConfig;

const ALL_ACCOUNTS_FILTER_TEXT = 'All Accounts';
const ALL_VERSIONS_FILTER_TEXT = 'All Versions';
const ALL_BRANDS_TEXT = 'All Brands';
const ALL_LANE_TYPES = 'All Lane Config Types';
const ALL_COUNTRIES = 'All Countries';
const ALL_REGIONS = 'All States';
const ALL_TIMEZONES = 'All Time Zones';
const ALL_STATUSES_FILTER_TEXT = 'All Statuses';

const gridOptions = [{
    text: 'Device Status',
    property: 'statusCell',
}, {
    text: 'Device Serial #',
    property: 'Device_SerialNumber',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Account Email',
    property: 'User_EmailAddress',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Device Email',
    property: 'Device_EmailAccount',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'AX Customer Account #',
    property: 'Store_AX_Customer_Account',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Brand',
    property: 'Brand_Name',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Version',
    property: 'Device_MainVersion',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Store #',
    property: 'Store_Number',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Store Name',
    property: 'Store_Name',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Lane Config',
    property: 'laneType',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Address',
    property: 'addressLine',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Country',
    property: 'country',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'State',
    property: 'Store_Region',
    className: 'hme-grid-cell-show-all-content',
    headerClassName: 'hme-view-store-settings-task-uploaded-devices-state-cell',
}, {
    text: 'Time Zone',
    property: 'Timezone',
    className: 'hme-grid-cell-show-all-content',
    headerClassName: 'hme-view-store-settings-task-uploaded-devices-timezone-cell',
}];

const filtersOptions = {
    statusCell: {
        allText: ALL_STATUSES_FILTER_TEXT,
        valuePropName: 'Device_Status',
    },
    User_EmailAddress: {
        allText: ALL_ACCOUNTS_FILTER_TEXT,
    },
    Brand_Name: {
        allText: ALL_BRANDS_TEXT,
    },
    Device_MainVersion: {
        allText: ALL_VERSIONS_FILTER_TEXT,
    },
    laneType: {
        allText: ALL_LANE_TYPES,
        valuePropName: 'Device_LaneConfig_ID',
    },
    country: {
        allText: ALL_COUNTRIES,
        valuePropName: 'Store_Country_ID',
    },
    Store_Region: {
        allText: ALL_REGIONS,
    },
    Timezone: {
        allText: ALL_TIMEZONES,
    },
};

const getCountryName = (countryId, countries)  => {
    const country = countries.find(({ Id: id }) => id === countryId) || { Name: 'Unknown' };

    return country.Name;
}

const getAddressLine = ({ Store_AddressLine1, Store_AddressLine2, Store_AddressLine3 }) => (
    `${Store_AddressLine1 || ''}\n${Store_AddressLine2 || ''}\n${Store_AddressLine3 || ''}`
);

const devicesToRows = (devices, countries) => devices.map(device => ({
    ...device,
    country:  getCountryName(device.Store_Country_ID, countries),
    laneType: getLaneType(device.Device_LaneConfig_ID),
    addressLine: getAddressLine(device),
    statusCell: (<StatusCell status={device.Device_Status} />)
}));

const searchByProps = ['User_EmailAddress', 'Device_SerialNumber', 'Store_Number', 'Store_Name', 'Device_EmailAccount'];

export const DevicesList = ({
    devices,
    searchValue,
    countries,
    resetFiltersTrigger,
    onFiltersCountChange,
}) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});

    useEffect(() => {
        setRows(devicesToRows(devices, countries));
    }, [devices, countries, setRows]);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useEffect(() => {
        const rowsFilteredByGrid = applyGridFilters(filtersOptions, rows, gridFilters);
        const rowsFilteredBySearch = applySearchValue(rowsFilteredByGrid, searchByProps, searchValue);

        setFilteredRows(rowsFilteredBySearch);
    }, [rows, gridFilters, searchValue, setFilteredRows]);

    useAvailableFilters(filtersOptions, rows, setGridFilters, setAvailableFilters);

    return (
        <Grid
            rows={filteredRows}
            isLoading={false}
            headers={gridOptions}
            noRecordsMessage='No devices were found'
            availableFilters={availableFilters}
            filters={gridFilters}
            onFiltersChange={setGridFilters}
        />
    );
}
