export const TARGET_DEVICES_TILE_FILTER_OPTIONS = {
    Brand_Name: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    Device_MainVersion: {
        title: 'common__device__version',
        allText: 'common__all-versions',
    },
    Device_LaneConfig_Name: {
        title: 'common__lane-config',
        allText: 'common__all-lane-configs',
    },
};

export const NEXEO_TARGET_DEVICES_TILE_FILTER_OPTIONS = {
    Brand_Name: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    Device_MainVersion: {
        title: 'common__device__version',
        allText: 'common__all-versions',
    },
    Device_SettingVersion: {
        title: 'common__settings__version',
        allText: 'common__all-versions',
    },
    Device_LaneConfig_Name: {
        title: 'common__lane-config',
        allText: 'common__all-lane-configs',
    },
};
