import { useEffect, useState } from 'react';

export class Trigger {
    constructor() {
        this.handlers = [];
    }

    on(handler) {
        if (this.handlers.includes(handler)) {
            return;
        }

        this.handlers.push(handler);
    }

    off(handler) {
        this.handlers = this.handlers.filter((eventHandler) => eventHandler !== handler);
    }

    trigger(...args) {
        this.handlers.forEach((handler) => handler(...args));
    }
};

export const useTrigger = () => {
    const [trigger, setTrigger] = useState(null);

    useEffect(() => {
        setTrigger(new Trigger());
    }, []);

    return trigger;
};

export const useOnTrigger = (trigger, handler) => {
    useEffect(() => {
        if (!(trigger instanceof Trigger)) {
            return;
        }

        trigger.on(handler);

        return () => {
            trigger.off(handler);
        }
    }, [trigger, handler]);
};
