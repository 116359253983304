import React, { useState, useCallback } from 'react';
import { cond, T } from 'ramda';
import { useTranslation, Trans } from 'react-i18next';
import { Toggle } from 'library/Toggle';
import { Section } from 'library/Section';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { AccountForm } from './AccountForm';
import { StoreForm } from './StoreForm';
import { BrandForm } from './BrandForm';
import { filterTypes, filterTypeOptions } from '../../constants/filterTypes';

import './FilterSection.css';

const FilterForm = cond([
    [({ type }) => type === filterTypes.BRAND, (props) => <BrandForm {...props} />],
    [({ type }) => type === filterTypes.ACCOUNT, (props) => <AccountForm {...props} />],
    [({ type }) => type === filterTypes.STORE, (props) => <StoreForm {...props} />],
    [T, () => null],
]);

export const FilterSection = ({
    devices,
    filterType,
    brands,
    countries,
    deviceVersion,
    targetDeviceVersions,
    selectedDeviceType,
    selectedPeripheralDeviceType,
    brandFilters,
    sectionNumber,
    disabled = false,
    onSelectDevices,
    onSelectTimezone,
    onSelectStoresByBrand,
    onSelectStores,
    onFilterTypeSelection,
    removeDevicesTrigger,
    changeUpgradeInfoTrigger,
}) => {
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const [nextType, setNextType] = useState('');
    const { t } = useTranslation();

    const onFilterTypeChange = useCallback(
        (type) => {
            if (devices.length) {
                setNextType(type);
                setIsConfirmPopupShown(true);

                return;
            }

            onFilterTypeSelection(type);
        },
        [devices],
    );

    const onHide = useCallback(() => {
        setIsConfirmPopupShown(false);
    }, []);

    const onConfirm = useCallback(() => {
        setIsConfirmPopupShown(false);
        onFilterTypeSelection(nextType);
    }, [nextType]);

    return (
        <Section
            number={sectionNumber}
            title={t('device-upgrades__section__title--find-devices-upgrade')}
            disabled={disabled}
            className="create-device-upgrade-filters"
        >
            <Toggle
                value={filterType}
                onChange={onFilterTypeChange}
                label={t('device-upgrades__section__title--find-devices-by')}
            >
                {filterTypeOptions}
            </Toggle>
            <FilterForm
                type={filterType}
                brands={brands}
                countries={countries}
                devices={devices}
                deviceVersion={deviceVersion}
                targetDeviceVersions={targetDeviceVersions}
                selectedDeviceType={selectedDeviceType}
                selectedPeripheralDeviceType={selectedPeripheralDeviceType}
                brandFilters={brandFilters}
                onSelectDevices={onSelectDevices}
                onSelectTimezone={onSelectTimezone}
                onSelectStoresByBrand={onSelectStoresByBrand}
                onSelectStores={onSelectStores}
                removeDevicesTrigger={removeDevicesTrigger}
                changeUpgradeInfoTrigger={changeUpgradeInfoTrigger}
            />
            <ConfirmPopupComponent
                show={isConfirmPopupShown}
                message={
                    <>
                        <Trans i18nKey={'device-upgrades__section_warning__change-search-method'}>
                            <div className="admin-create-apply-device-settings-task-filters-confirm-line">
                                Only one search method can be used in Step 2 when creating a task. Selecting a different
                                search method now will remove all devices you have selected in Step 3.
                            </div>
                            <div>Do you wish to continue?</div>
                        </Trans>
                    </>
                }
                onHide={onHide}
                onConfirm={onConfirm}
                confirmationVerb={'Continue'}
            />
        </Section>
    );
};
