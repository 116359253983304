import { useTranslation } from 'react-i18next'

import { Title } from 'library/Title';
import { Label } from 'library/Label';

import './ContactHME.scss';

const titleVariants = ['section'];

export const ContactHME = () => {
    const { t } = useTranslation();

    return (
        <div className='hme-contact'>
            <Title variants={titleVariants}>{t('contact-hme__title')}</Title>
            <div className='hme-contact__labels'>
                <Label>{t('common__email')}</Label>
                <a
                    href={`mailto:${t('contact-hme__email')}`}
                    className='hme-contact__value hme-contact__email hme-s-size'
                >{t('contact-hme__email')}</a>
                <Label>{t('common__phone')}</Label>
                <span className='hme-contact__value hme-s-size'>{t('contact-hme__phone')}</span>
            </div>
            <div className='hme-contact__values hme-l-size'>
                <a
                    href={`mailto:${t('contact-hme__email')}`}
                    className='hme-contact__value hme-contact__email'
                >{t('contact-hme__email')}</a>
                <span className='hme-contact__value hme-l-size'>{t('contact-hme__phone')}</span>
            </div>
        </div>
    );
};
