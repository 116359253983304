export const defaultSorting = {
    rank: 1
};

export const MOBILE_SORT_OPTIONS = [
    {
        label: 'contest__stores-rank-sorting--asc',
        value: defaultSorting
    },
    {
        label: 'contest__stores-rank-sorting--desc',
        value: { rank: -1 }
    }
];
