import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { Config } from 'Config';
import AuthenticationService from 'components/Security/AuthenticationService';

import '../Footer/Footer.css';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.authService = new AuthenticationService(Config.authBaseUrl);
    this.state = {
      year: new Date().getFullYear(),
    };

    this.state.footerUrls = [
      {
        name: 'footer__user-agreement',
        url: 'footer__user-agreement__url',
      },
      {
        name: 'footer__privacy-policy',
        url: 'footer__privacy-policy__url',
      },
      {
        name: 'footer__california-supply',
        url: 'footer__california-supply__url',
      },
      {
        name: 'footer__cookie-policy',
        url: 'footer__cookie-police__url',
      },
      {
        name: 'footer__do-not-sell',
        url: 'footer__do-not-sell__url',
      },
    ];

    if (this.authService.isPartner()) {
      this.state.footerUrls.splice(1, 0, {
        name: 'footer__partner-data-agreement',
        url: 'footer__partner-data-agreement__url',
      });
    }

  }

  render() {
    const { footerUrls } = this.state;
    const { t } = this.props;
    const url = Config.storageUrl;

    return (
      <div className={`footer__container${window.location.pathname.indexOf('/storeRanking') !== -1 ? ' hidden' : ''}`}>
        <div className="footer__copyright">
          HM Electronics, Inc. &copy;{this.state.year}
        </div>
        <ul className="footer__links">
          {!this.authService.isAdmin() ? (
              footerUrls.map((item, index) => (
                <li key={index} className="footer__links-item">
                  <a href={t(item.url, { url })} target='_blank'>{t(item.name)}</a>
                </li>
              ))
          ): ""}
        </ul>
      </div>
    );
  }
}

export default compose(withTranslation())(Footer);
