import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileList } from 'library/TileList';
import { Grid } from 'components/Common/Grid';

import './SuggestionsList.scss';

export const SuggestionsList = ({
    suggestions,
    headers,
    mobileHeaders = [],
    noSuggestionText,
    rowKey,
    onSuggested,
    checkDisabled,
}) => {
    const { t } = useTranslation();

    const hasMobileHeaders = !!mobileHeaders.length;

    return (
        <>
            <div className='hme-auto-complete-suggestions-label'>{t('autocomplete-input__suggestions')}</div>
            <Grid
                rows={suggestions}
                headers={headers}
                noRecordsMessage={noSuggestionText}
                onRowClick={onSuggested}
                rowKey={rowKey}
                checkDisabled={checkDisabled}
            />
            {hasMobileHeaders && <TileList
                headers={mobileHeaders}
                rows={suggestions}
                rowKey={rowKey}
                noRecordsMessage={noSuggestionText}
                onRowClick={onSuggested}
            />}
        </>
    );
}
