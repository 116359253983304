import React, { Component } from 'react'
import { Trans } from 'react-i18next';
import { Tooltip } from 'react-tooltip'
import './Stores.css'
import { Config } from '../../Config'
import AuthenticationService from '../Security/AuthenticationService'
import NavigationService from '../Common/NavigationServices'

export default class CommonStoreMethod extends Component {
  constructor (props) {
    super(props)
    this.state = {};
    this.navigate = new NavigationService()
    this.authService = new AuthenticationService(Config.authBaseUrl)
  }

  // Checking the device status and remote connect permission and the Device_Name CIB.
  // This function is used in CIB and Zoom Components.
  renderStatus (device, isRemoteConnection) {
    const isActive = parseInt(device.Device_IsActive, 10) === 1;

    const ControlTag = isActive && isRemoteConnection ? 'a' : 'span';
    const htmlAttributes = {};
    if (isActive && isRemoteConnection) {
      htmlAttributes.href = this.navigate.getMenu('connect', device.Device_UID, device.Device_MainVersion, null, device.Device_Name);
      htmlAttributes.className = 'systemStatus_online_normal';
    }

    const tKey = device.Device_IsActive
      ? 'common__device__status--online'
      : 'common__device__status--offline'

    return <ControlTag {...htmlAttributes}><Trans i18nKey={tKey} /></ControlTag>
  }

  // Showing the device headers.
  // This function is used in CIB and Zoom Components.
  renderDeviceListHeaders (showSettingVersion) {
    const isAdmin = this.authService.isAdmin();

    return (
      <tr className='theader'>
        {isAdmin ? <th /> : ''}
        <th><Trans i18nKey='common__system__version' /></th>
        <th><Trans i18nKey='common__device__serial-number--text' /></th>
        <th><Trans i18nKey='common__system__status' /></th>
        {showSettingVersion ? (<th><Trans i18nKey='common__settings__version' /></th>) : ''}
        <th className='device-view' />
      </tr>
    )
  }

  formatText (text) {
    if (text) {
      if (text.length > 15) {
        let formattedText = text.substring(0, 15) + ' ...'
        return formattedText
      } else {
        return text
      }
    } else {
      return text
    }
  }

  // Showing the tool tip with name onhover ... (3 dots)
  // This function is used in Store detail admin and Store merge Components.
  renderText (text) {
    if (text.length > 15) {
      return (<div>
        <span data-tooltip-content={text} data-tooltip-variant="light" className='tooltipText'><span>{this.formatText(text)}</span></span>
        <Tooltip place='top' effect='solid' data-place='top' />
      </div>)
    } else {
      return (<span>{text}</span>)
    }
  }

}
