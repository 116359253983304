/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tip } from 'library/Tip';
import { Label } from 'library/Label';
import { SINGLE_SELECT_VARIANTS, SingleSelect } from 'library/SingleSelect';

const defaultSelectVariants = [SINGLE_SELECT_VARIANTS.MOBILE_LABEL_INSIDE];

export const StoresHierarchyLevel = ({ isEdit, hierarchyLevels, formValues, setFormValues }) => {
    const { t } = useTranslation();
    const [hierarchyLevelID, setHierarchyLevelID] = useState(isEdit ? formValues.hierarchyLevelID : 0);

    useEffect(() => {
        setFormValues({
            hierarchyLevelID
        });
    }, [hierarchyLevelID]);

    return (
        <div className="create-edit-scheduler__stores-hierarchy-level">
            <Label className="create-edit-scheduler__field-label">{t('reports__stores-hierarchy-level')}</Label>
            {' '}
            <Tip className="create-edit-scheduler__tooltip">{t('scheduler__report-stores-hierarchy-info-message')}</Tip>
            <SingleSelect
                isRequired={true}
                value={hierarchyLevelID}
                placeholder={t('common__select')}
                onChange={(event) => setHierarchyLevelID(event)}
                items={hierarchyLevels}
                className="create-edit-scheduler__select create-edit-scheduler__select--top"
                label={t('reports__stores-hierarchy-level')}
                variants={defaultSelectVariants}
            />
        </div>
    );
};
