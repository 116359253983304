export const tokenManagementBaseUrl = '/admin/tokenManagement';

export const DEFAULT_TOKEN_MANAGER = {
    id: null,
    uid: '',
    email: '',
    partnerId: null
};

export const tokenUsers = {
    hmeAccount: 'HME Account',
    partner: 'Partner'
};

export const HME_API_TYPES_ENUM = {
    VAIO: 'VAIO',
    DXS: 'DXS'
};

export const HME_API_ID_MAP = {
    1: HME_API_TYPES_ENUM.VAIO,
    2: HME_API_TYPES_ENUM.DXS
};

// This is to avoid showing test api values in the frontend
export const validHmeApiIds = [1, 2];

const basicFormData = {
    tokenName: '',
    tokenExpiration: 6,
    apiType: HME_API_TYPES_ENUM.VAIO,
    tokenUser: tokenUsers.partner,
    tokenManager: DEFAULT_TOKEN_MANAGER,
    email2: '',
    email3: '',
    expiryDate: '',
    lastRenewedDate: ''
};

export const FORM_DEFAULTS = {
    VAIO: {
        ...basicFormData,
        vaioProvider: ''
    },
    DXS: {
        ...basicFormData,
        cloudAccounts: [],
        apiType: HME_API_TYPES_ENUM.DXS
    }
};

export const VAIO_DEFAULTS = { ...FORM_DEFAULTS.VAIO };

export const REQUIRED_INPUTS = {
    VAIO: [
        'tokenName',
        'tokenExpiration',
        'apiType',
        'tokenUser',
        'tokenManager',
        'vaioProvider'
    ],
    DXS: [
        'tokenName',
        'tokenExpiration',
        'apiType',
        'tokenUser',
        'tokenManager',
        'cloudAccounts'
    ]
};

export const TOKEN_USER_TYPES = [
    { text: 'admin__token-management__token-user-type--hme-account', value: tokenUsers.hmeAccount },
    { text: 'admin__token-management__token-user-type--partner', value: tokenUsers.partner }
];

export const TOKEN_USER_TYPES_EDIT = [
    { text: 'admin__token-management__token-user-type--hme-account', value: tokenUsers.hmeAccount, disabled: true },
    { text: 'admin__token-management__token-user-type--partner', value: tokenUsers.partner }
];

export const TOKEN_INITIAL = {
    name: '',
    generatedToken: ''
};

export const HME_API_TYPES = {
    VAIO: {
        categoryID: 1,
        categoryName: 'VAIO Provider',
        genericLabel: 'api-token-management__form--hme-apis-vaio-value'
    },
    DXS: {
        categoryID: 2,
        categoryName: 'DXS Provider',
        genericLabel: 'api-token-management__form--hme-apis-dxs-value'
    }
};

export const PARTNER_CATEGORIES_MAPPING = {
    VAIO: { categoryID: 1, categoryName: 'VAIO Provider' },
    Nitro: { categoryID: 2, categoryName: 'Nitro Provider' },
    LinkWear: { categoryID: 3, categoryName: 'LinkWear Provider' },
    DXS: { categoryID: 4, categoryName: 'DXS Partner' }
};
