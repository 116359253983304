import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { RadioGroupItem, RadioGroupIndicator } from '@radix-ui/react-radio-group';

import './Item.scss';

const DEFAULT_VARIANTS = [];

export const Item = forwardRef(({ id, value, label, className, variants = DEFAULT_VARIANTS, ...props }, ref) => (
    <div
        className={classNames(
                'hme-radio-group-item',
                variants.map((variant) => `hme-radio-${variant}-variant`)
        )}
    >
        <RadioGroupItem {...props} id={id} value={value} ref={ref} className="hme-radio-group-item__container">
            <RadioGroupIndicator className="hme-radio-group-item__indicator" />
        </RadioGroupItem>
        <label htmlFor={id} className="hme-radio-group-item__label">
            {label}
        </label>
    </div>
));

Item.displayName = 'RadioGroupItem';
