import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { intersection } from 'ramda';

import { DateLib } from '@hme-cloud/utility-common';
import { PUBLIC_ROUTES } from 'constants/routes';
import { composeValidators, imageSize, minArrayItems, required } from 'components/Inputs/Validators';
import { commonErrorTranslations, INTERNAL_SERVER_ERROR_KEY } from 'constants/errors';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { createLeaderboardContest } from '../../../Common/Controller';
import { getBase64FromImage } from '../../../Common/RewardImageInput';
import { defaultContest } from '../../../Common/Constants';
import { REQUEST_SIZE_EXCEEDED_STATUS_CODE } from '../../../Common/Constants';
import { getNewRecurrenceDays, prepareScheduledTimePeriod } from '../../../Common/utils';
import { useStoresTree } from '../../../Common/hooks';

export const useCreate = () => {
    const { t, i18n: { resolvedLanguage } } = useTranslation();
    const navigate = useNavigate();

    const [isSavingInProgress, setIsSavingInProgress] = useState(false);

    const [values, setValues] = useState(defaultContest);

    const [errors, setErrors] = useState({
        recurrenceDays: ''
    });

    const {
        storesTree,
        contestStoreIds,
        setContestStoreIds,
        storesSearchQuery,
        handleStoresSearchQueryChange,
        checkIsStoresAvailable,
        isCheckingAvailableStores,
        isOnlySelectedStoresShown,
        toggleSelectedStoresOnly
    } = useStoresTree();

    const handleValueChange = useCallback((fieldName) => (value) => {
        setValues((currentValues) => ({
            ...currentValues,
            [fieldName]: value
        }));
    }, []);

    const handleErrorChange = useCallback((errorObj = {}) => {
        setErrors((currentErrors) => ({
            ...currentErrors,
            ...errorObj
        }));
    }, []);

    const handleTimeZoneChange = useCallback((selectedTimeZone) => {
        handleValueChange('timeZone')(selectedTimeZone);
        handleErrorChange({ timeZone: '' });
    }, [handleValueChange, handleErrorChange]);

    const updateRecurrenceDays = useCallback(
        ({ startDate, endDate }) => {
            if (!startDate || !endDate) {
                return;
            }

            const diff = endDate.diff(startDate, 'days');
            const { dayOfWeek: stardDateDayOfWeek } = startDate;

            if (diff < DateLib.AMOUNT_OF_WEEK_DAYS) {
                const newRecurrenceDays = getNewRecurrenceDays(stardDateDayOfWeek, diff + 1);

                handleValueChange('recurrenceDays')(newRecurrenceDays);
            } else {
                const allRecurrenceDays = Array.from({ length: DateLib.AMOUNT_OF_WEEK_DAYS }, (_, idx) => idx);

                handleValueChange('recurrenceDays')(allRecurrenceDays);
            }

            handleErrorChange({ contestStores: '' });
        },
        [handleValueChange, handleErrorChange]
    );

    const handleSchduledTimePeriodChange = useCallback(
        ({ from, to }) => {
            if (!from) {
                return;
            }

            const preparedPeriods = prepareScheduledTimePeriod({ from, to });

            handleValueChange('scheduledTimePeriod')({ from, to, periods: preparedPeriods });
            handleErrorChange({ contestStores: '' });
        },
        [handleValueChange, handleErrorChange]
    );

    const handleImageDrop = useCallback(
        async (files) => {
            const uploadedImage = await getBase64FromImage(files[0]);

            const imageSizeErr = await imageSize()(uploadedImage);

            handleValueChange('rewardImg')(uploadedImage);

            if (imageSizeErr) {
                handleErrorChange({ rewardImg: t('common__error--image-size-new') });
            } else {
                handleErrorChange({ rewardImg: '' });
            }
        },
        [handleValueChange, handleErrorChange, t]
    );

    const handleImageRemove = useCallback(() => {
        handleValueChange('rewardImg')(null);
        handleErrorChange({ rewardImg: '' });
    }, [handleErrorChange, handleValueChange]);

    const handleContestStoresChange = useCallback(
        (stores) => {
            setContestStoreIds(stores);
            handleErrorChange({ contestStores: '' });
        },
        [setContestStoreIds, handleErrorChange]
    );

    const handleStartDateChange = useCallback(
        (newStartDate) => {
            const newEndDate = newStartDate > values.endDate ? newStartDate : values.endDate;

            handleValueChange('startDate')(newStartDate);
            handleValueChange('endDate')(newEndDate);

            updateRecurrenceDays({ startDate: newStartDate, endDate: newEndDate });
        },
        [values.endDate, handleValueChange, updateRecurrenceDays]
    );

    const handleEndDateChange = useCallback(
        (newEndDate) => {
            handleValueChange('endDate')(newEndDate);

            updateRecurrenceDays({ startDate: values.startDate, endDate: newEndDate });
        },
        [values.startDate, handleValueChange, updateRecurrenceDays]
    );

    const handleSubmit = useCallback(async () => {
        let hasValidationError = false;

        const timeZoneValidationResult = composeValidators(
            required(t('common__timezone'))
        )(values.timeZone.value);

        const storesValidationResult = composeValidators(
            required(t('common__stores')),
            minArrayItems(2)
        )(contestStoreIds);

        const recurrenceDaysValidationResult = composeValidators(
            required(t('contest__time-period--days'))
        )(values.recurrenceDays);

        if (storesValidationResult) {
            handleErrorChange({ contestStores: storesValidationResult });

            hasValidationError = true;
        }

        if (timeZoneValidationResult) {
            handleErrorChange({ timeZone: timeZoneValidationResult });

            hasValidationError = true;
        }

        if (recurrenceDaysValidationResult) {
            handleErrorChange({ recurrenceDays: recurrenceDaysValidationResult });

            hasValidationError = true;
        }

        if (hasValidationError) {
            return;
        }

        try {
            setIsSavingInProgress(true);

            const result = await createLeaderboardContest({
                ...values,
                language: resolvedLanguage,
                contestStores: contestStoreIds
            });

            if (result.status) {
                setTimeout(() => {
                    addSuccessNotification('contest__create--success', { autoClose: 5000 });
                }, 300);

                navigate(PUBLIC_ROUTES.contestsList);
            }
        } catch (err) {
            if (err?.response?.status === REQUEST_SIZE_EXCEEDED_STATUS_CODE) {
                handleErrorChange({ rewardImg: t('common__error--fize-size-exceeded') });
            }

            if (err?.response?.data?.data?.message === 'Validation error') {
                const { formErrors } = err.response.data.data;

                if (formErrors.ContestStores) {
                    handleErrorChange({
                        contestStores: t(formErrors.ContestStores.tKey, { stores: formErrors.ContestStores.options.stores })
                    });
                }
            }

            addErrorNotification(
                err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY,
                { autoClose: 5000 }
            );
        } finally {
            setIsSavingInProgress(false);
        }
    }, [values, contestStoreIds, handleErrorChange, navigate, t, resolvedLanguage]);


    useEffect(() => {
        const { endDate, startDate, scheduledTimePeriod, recurrenceDays } = values;

        if (!(startDate || endDate)) {
            return;
        }

        const fullStartDate = new DateLib(startDate).setHours(parseInt(scheduledTimePeriod.from || 0, 10));
        const fullEndDate = new DateLib(endDate).setHours(parseInt(scheduledTimePeriod.to || 0, 10));

        const currentDate = new DateLib();

        const daysDiff = fullEndDate.diffDays(fullStartDate);
        const { dayOfWeek: stardDateDayOfWeek } = fullStartDate;

        const isStartDateBeforeCurrent = fullStartDate.isBefore(currentDate);

        if (isStartDateBeforeCurrent) {
            handleErrorChange({ startDate: t('contest__error__start-date-time') });

            return;
        }

        if (daysDiff < DateLib.AMOUNT_OF_WEEK_DAYS) {
            const newRecurrenceDays = getNewRecurrenceDays(stardDateDayOfWeek, daysDiff + 1);

            const intersectionLength = intersection(newRecurrenceDays, recurrenceDays).length;

            if (!intersectionLength || recurrenceDays.length > intersectionLength) {
                handleErrorChange({ recurrenceDays: t('contest__error__days-not-present-in-range') });

                return;
            }
        }

        if (scheduledTimePeriod.from && scheduledTimePeriod.to) {
            checkIsStoresAvailable({
                startDate,
                endDate,
                scheduledTimePeriod,
                recurrenceDays,
                timeZone: values.timeZone
            });
        }


        handleErrorChange({ recurrenceDays: '', startDate: '' });
    }, [
        values.startDate,
        values.endDate,
        values.scheduledTimePeriod,
        values.recurrenceDays,
        values.timeZone,
        handleErrorChange,
        t
    ]);

    return {
        isSavingInProgress,
        isCheckingAvailableStores,

        values: {
            ...values,
            contestStoresSearchQuery: storesSearchQuery,
            contestStores: contestStoreIds
        },
        errors,
        storesTree,

        onSubmit: handleSubmit,

        onValueChange: handleValueChange,
        onImageDrop: handleImageDrop,
        onImageRemove: handleImageRemove,
        onSchduledTimePeriodChange: handleSchduledTimePeriodChange,
        onStoresSearchQueryChange: handleStoresSearchQueryChange,
        onContestStoresChange: handleContestStoresChange,
        onStartDateChange: handleStartDateChange,
        onEndDateChange: handleEndDateChange,
        onTimeZoneChange: handleTimeZoneChange,

        isOnlySelectedStoresShown,
        toggleSelectedStoresOnly
    };
};
