import { useState, useCallback, useMemo } from 'react';

const empty = () => {};

export const defaultValue = {
    isAcceptModalShown: false,
    isDeclineModalShown: false,
    onAcceptClick: empty,
    onDeclineClick: empty,
    onAcceptConfirm: empty,
    onDeclineConfirm: empty,
    onAcceptHide: empty,
    onDeclineHide: empty,
};

export const useLeaderboardInvite = ({ onAccept, onDecline }) => {
    const [isAcceptModalShown, setIsAcceptModalShown] = useState(false);
    const [acceptData, setAcceptData] = useState(null);
    const [isDeclineModalShown, setIsDeclineModalShown] = useState(false);
    const [declineData, setDeclineData] = useState(null);

    const onAcceptClick = useCallback((newAcceptData) => {
        setAcceptData(newAcceptData);
        setIsAcceptModalShown(true);
    }, []);

    const onDeclineClick = useCallback((newDeclineData) => {
        setDeclineData(newDeclineData);
        setIsDeclineModalShown(true);
    }, []);

    const onAcceptConfirm = useCallback(() => {
        setIsAcceptModalShown(false);
        
        if (!acceptData) {
            return;
        }

        onAccept && onAccept(acceptData);
        setAcceptData(null);
    }, [acceptData, onAccept]);

    const onDeclineConfirm = useCallback(() => {
        setIsDeclineModalShown(false);
        
        if (!declineData) {
            return;
        }

        onDecline && onDecline(declineData);
        setDeclineData(null);
    }, [declineData, onDecline]);

    const onAcceptHide = useCallback(() => {
        setIsAcceptModalShown(false);
        setAcceptData(null);
    }, []);

    const onDeclineHide = useCallback(() => {
        setIsDeclineModalShown(false);
        setDeclineData(null);
    }, []);

    return useMemo(() => ({
        isAcceptModalShown,
        isDeclineModalShown,
        onAcceptClick,
        onDeclineClick,
        onAcceptConfirm,
        onDeclineConfirm,
        onAcceptHide,
        onDeclineHide
    }), [
        isAcceptModalShown,
        isDeclineModalShown,
        onAcceptClick,
        onDeclineClick,
        onAcceptConfirm,
        onDeclineConfirm,
        onAcceptHide,
        onDeclineHide
    ]);
};