import { useMemo, useState, useCallback, useEffect } from 'react';
import { equals } from 'ramda';

import { useOnFieldChange } from 'hooks/useOnFieldChange';
import { hasAllRequired } from 'helpers/object/hasAllRequired';
import { trimValues } from 'helpers/object/trimValues';
import { validEmailRegexp as emailRegExp } from 'constants/email';
import { phoneRegExp, mobileRegExp } from 'constants/phoneNumber';
import { postCodeRegExp } from 'constants/postCode';
import { getPartnerTokenTypeTranslation } from 'constants/token';

import { trimFields } from '../constants/trimFields';

const requiredFields = [
    'name',
    'types',
    'countryId',
    'firstName',
    'lastName',
    'email',
    'region'
];

const addValidationError = (errors, key, errorKey) => {
    if (!errors[key]) {
        errors[key] = [];
    }

    errors[key].push(errorKey);
};

const validatePartner = (partner, confirmEmail) => {
    const errors = {};

    if (partner.email.trim() !== confirmEmail.trim()) {
        addValidationError(errors, 'confirm', 'admin__account__section__form--validation--email-equal');
    }

    if (!partner.email.trim().match(emailRegExp)) {
        addValidationError(errors, 'email', 'common__error--invalid-email');
    }

    if (partner.phoneNumber.trim() && !partner.phoneNumber.trim().match(phoneRegExp)) {
        addValidationError(errors, 'phoneNumber', 'common__error--invalid-phone-number');
    }

    if (partner.mobileNumber.trim() && !partner.mobileNumber.trim().match(mobileRegExp)) {
        addValidationError(errors, 'mobileNumber', 'common__error--invalid-mobile-number');
    }

    if (partner.userOfficeNumber && !partner.userOfficeNumber.trim().match(phoneRegExp)) {
        addValidationError(errors, 'userOfficeNumber', 'common__error--invalid-phone-number');
    }

    if (partner.userMobileNumber && !partner.userMobileNumber.trim().match(mobileRegExp)) {
        addValidationError(errors, 'userMobileNumber', 'common__error--invalid-mobile-number');
    }

    if (partner.postCode.trim() && partner.countryId === 1 && !partner.postCode.trim().match(postCodeRegExp)) {
        addValidationError(errors, 'postCode', 'my-account__error__invalid-company-zipcode');
    }

    return errors;
};

export const useViewEdit = ({ partner, originPartner, countries, regions, types, onChange, onSubmit }) => {
    const [confirmEmail, setConfirmEmail] = useState('');
    const [validationErrors, setValidationErrors] = useState({});

    const onNameChange = useOnFieldChange(partner, 'name', onChange);
    const onTypesChange = useOnFieldChange(partner, 'types', onChange);
    const onAddress1Change = useOnFieldChange(partner, 'address1', onChange);
    const onAddress2Change = useOnFieldChange(partner, 'address2', onChange);
    const onRegionIdChange = useOnFieldChange(partner, 'region', onChange);
    const onRegionChange = useOnFieldChange(partner, 'region', onChange);
    const onLocalityChange = useOnFieldChange(partner, 'locality', onChange);
    const onPostCodeChange = useOnFieldChange(partner, 'postCode', onChange);
    const onPhoneNumberChange = useOnFieldChange(partner, 'phoneNumber', onChange);
    const onMobileNumberChange = useOnFieldChange(partner, 'mobileNumber', onChange);
    const onUserOfficeNumberChange = useOnFieldChange(partner, 'userOfficeNumber', onChange);
    const onUserMobileNumberChange = useOnFieldChange(partner, 'userMobileNumber', onChange);
    const onIsActiveChange = useOnFieldChange(partner, 'isActive', onChange);
    const onFirstNameChange = useOnFieldChange(partner, 'firstName', onChange);
    const onLastNameChange = useOnFieldChange(partner, 'lastName', onChange);
    const onEmailChange = useOnFieldChange(partner, 'email', onChange);

    const onSubmitClick = useCallback(() => {
        const errors = validatePartner(partner, confirmEmail);

        if (Object.keys(errors).length) {
            setValidationErrors(errors);

            return;
        }

        setValidationErrors({});

        onSubmit && onSubmit(trimValues(partner, trimFields));
    }, [partner, confirmEmail, onSubmit]);

    const onCountryIdChange = useCallback((countryId) => {
        onChange && onChange({
            ...partner,
            countryId,
            region: '',
        });
    }, [partner, onChange]);

    const isSubmitEnabled = useMemo(() => {
        if (!hasAllRequired(partner, requiredFields)) {
            return false;
        }

        if (!confirmEmail.trim()) {
            return false;
        }

        return !equals(trimValues(partner, trimFields), trimValues(originPartner, trimFields));
    }, [partner, originPartner, confirmEmail]);

    const countriesList = useMemo(() =>
        countries.map((country) => ({
            text: country.Name,
            value: country.Id,
        })),
    [countries]);

    const regionsList = useMemo(() =>
        regions.map((region) => ({
            text: region.Name,
            value: region.Name,
        })),
    [regions]);

    const typeOptions = useMemo(() => {
        return types.map(({ name, id }) => ({
            label: getPartnerTokenTypeTranslation(id),
            value: id,
            key: id,
        })).filter(({ label }) => {
            return label !== '';
        })
    }, [types]);

    useEffect(() => {
        originPartner && setConfirmEmail(originPartner.email);
    }, [originPartner]);

    return {
        countriesList,
        regionsList,
        typeOptions,
        confirmEmail,
        isSubmitEnabled,
        validationErrors,
        onNameChange,
        onTypesChange,
        onAddress1Change,
        onAddress2Change,
        onCountryIdChange,
        onRegionIdChange,
        onRegionChange,
        onLocalityChange,
        onPostCodeChange,
        onPhoneNumberChange,
        onMobileNumberChange,
        onUserOfficeNumberChange,
        onUserMobileNumberChange,
        onIsActiveChange,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onConfirmEmailChange: setConfirmEmail,
        onSubmitClick,
    };
};
