import { applyGridFilters, applySearchValue } from 'components/Common/Grid';

const accountsSearchProperties = (searchParams, searchProperties) => searchParams.selectValue
    ? [searchParams.selectValue]
    : searchProperties;

export const getFilteredAccounts = (accountsList, filters, searchParams, filtersOptions, searchProperties) => {
    const filteredAccountsList = applyGridFilters(filtersOptions, accountsList, filters);

    if (!searchParams) {
        return filteredAccountsList;
    }

    return applySearchValue(
            filteredAccountsList,
            accountsSearchProperties(searchParams, searchProperties),
            searchParams.searchValue,
            { minLength: 1 }
    );
};
