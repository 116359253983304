import React, { createContext, useState, useContext } from 'react';

const ReportTemplateContext = createContext();

export const useReportTemplateContext = () => useContext(ReportTemplateContext);

// eslint-disable-next-line react/prop-types
export const ReportTemplateProvider = ({ children }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    return (
        <ReportTemplateContext.Provider value={{ selectedTemplate, setSelectedTemplate }}>
            {children}
        </ReportTemplateContext.Provider>
    );
};
