import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateLib } from '@hme-cloud/utility-common';

import './Title.scss';

const days = [
    { key: 'Su', translation: 'common__day-abbreviation--sunday' },
    { key: 'M', translation: 'common__day-abbreviation--monday' },
    { key: 'Tu', translation: 'common__day-abbreviation--tuesday' },
    { key: 'W', translation: 'common__day-abbreviation--wednesday' },
    { key: 'Th', translation: 'common__day-abbreviation--thursday' },
    { key: 'F', translation: 'common__day-abbreviation--friday' },
    { key: 'Sa', translation: 'common__day-abbreviation--saturday' }
];

export const Title = ({ shownDate }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="hme-calendar-selector-month-title">{shownDate.format(DateLib.FORMAT_TYPES.MONTH_YEAR)}</div>
            <div>
                {
                    days.map((day) => (
                        <span className="hme-calendar-selector-month-title__week-cell" key={day.key}>{t(day.translation)}</span>
                    ))
                }
            </div>
        </>
    );
};
