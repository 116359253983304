import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import { ReactComponent as EmpltyIcon } from '../../images/forms/empty-checkbox.svg';
import { ReactComponent as CheckedIcon } from '../../images/forms/checked-checkbox.svg';
import { ReactComponent as PartialIcon } from '../../images/forms/partial-checkbox.svg';
import { ReactComponent as DisabledCheckedIcon } from '../../images/forms/disabled-checked-checkbox.svg';

import './Checkbox.scss';

// It is good when we have all values with the same type
// But for checkbox main values (checked and unchecked) are boolean
// And boolean has only 2 values.
// We need that types to simplify usage of checkbox component
// Notes: If you don't need partial value you can use just true/false
// But in case when you want to use PARTIAL state please use this structure for
// checked/unchecked values as well
export const CHECKBOX_VALUES = {
    UNCHECKED: false,
    PARTIAL: 'partial',
    CHECKED: true,
    DEFAULT_CHECKED: 'defaultChecked'
};

const isState = (state) => ({ value }) => state === value;

const isUncheckedState = isState(CHECKBOX_VALUES.UNCHECKED);
const isPartialState = isState(CHECKBOX_VALUES.PARTIAL);
const isCheckedState = isState(CHECKBOX_VALUES.CHECKED);
const isDefaultCheck = isState(CHECKBOX_VALUES.DEFAULT_CHECKED);

const CheckboxIcon = cond([
    [isUncheckedState, () => <EmpltyIcon className="hme-checkbox-component-icon" />],
    [isPartialState, () => <PartialIcon className="hme-checkbox-component-icon" />],
    [isCheckedState, () => <CheckedIcon className="hme-checkbox-component-icon" />],
    [isDefaultCheck, () => <DisabledCheckedIcon className="hme-checkbox-component-icon" />],
    [T, () => <></>]
]);

export const Checkbox = ({ checked, label = '', className, disabled = false, onChange, ...props }) => (
    <div
        className={classNames(
            'hme-checkbox-component',
            className,
            {
                'hme-checkbox-component-disabled': disabled
            },
            {
                'hme-checkbox-component-checked': checked
            }
        )}
        onClick={onChange && ((e) => !disabled && onChange(checked !== CHECKBOX_VALUES.CHECKED, e))}
    >
        <CheckboxIcon value={checked && disabled ? 'defaultChecked' : checked} />
        <input type="checkbox" className="hme-checkbox-input" checked={checked} readOnly disabled={disabled} { ...props }/>
        {label || label === 0 ? <span className="hme-checkbox-label">{label}</span> : null }
    </div>
);
