import React from 'react';
import { Link } from 'react-router-dom';

import { StoreNumberLink } from 'library/StoreNumberLink';
import { Section } from 'library/Section';
import { InfoField } from 'library/InfoField';
import { deviceConfig } from 'constants/device';
import { upgradeStatuses } from 'constants/upgradeStatuses';

import { DeviceActiveStatus } from '../DeviceActiveStatus'

import { useTranslation } from 'react-i18next';

import './InfoSection.scss';

const { getLaneType } = deviceConfig;

export const InfoSection = ({ device, storeCountry, deviceScheduledUpgrade, isScheduledUpgradeChanged }) => {
    const { t } = useTranslation();

    const storeAddress = [
            device.Store_AddressLine1,
            device.Store_AddressLine2,
            device.Store_AddressLine3,
            device.Store_AddressLine4,
        ]
    .filter(a => !!a)
    .map((a, index) => (<div key={index}>{a}</div>));

    const storeLocation = [
        device.Store_Locality,
        device.Store_Region,
        device.Store_PostCode,
    ]
    .filter(a => !!a)
    .join(', ');

    return (
        <Section className='hme-admin-system-status-info' title={t('system-status__title__system-information')}>
            <InfoField label={t('common__status')}>
                {
                    device.Device_IsPreconfigured
                        ? t('common__device__status--pre-configured')
                        : <DeviceActiveStatus device={device} />
                }
            </InfoField>
            <InfoField label={t('common__store__name')}>
                {device.Store_Name || '-'}
            </InfoField>
            <InfoField label={t('common__store__number--text')}>
                <StoreNumberLink storeUID={device.Store_UID}>{device.Store_Number}</StoreNumberLink>
            </InfoField>
            <InfoField label={t('common__brand')}>
                {device.Brand_Name || ''}
            </InfoField>
            <InfoField label={t('common__address')}>
                {storeAddress.length ? storeAddress : '-'}
                {storeLocation ? <div>{storeLocation}</div> : ''}
                {storeCountry ? <div>{storeCountry}</div> : ''}
            </InfoField>
            <InfoField label={t('common__account__email')}>
                <a href={`/admin/account?type=${device.Company_Type}&uuid=${device.User_UID}`}>{device.User_EmailAddress}</a>
            </InfoField>
            <InfoField label={t('common__ax-account--customer--text')}>
                {device.Store_AX_Customer_Account || '-'}
            </InfoField>
            <InfoField label={t('common__timezone')}>
                {device.Timezone || '-'}
            </InfoField>
            <InfoField label={t('system-status__lane-config')}>
                {getLaneType(device.Device_LaneConfig_ID)}
            </InfoField>
            <InfoField label={t('common__settings__version')}>
                {device.Device_SettingVersion}
            </InfoField>
            <InfoField label={t('system-status__scheduled-upgrade')}>
                {isScheduledUpgradeChanged ?
                <div className="mt-2">
                    <Link to={`/admin/settings/deviceUpgrades/${deviceScheduledUpgrade.DeviceScheduledUpgradeID}`}>
                        {deviceScheduledUpgrade.ScheduledUpgradeName}
                    </Link>
                    <label className={deviceScheduledUpgrade.DeviceUpgradeStatus === upgradeStatuses.FAILED ? 'red-font-text' : ''}>
                        {` - `}{deviceScheduledUpgrade.DeviceUpgradeStatus}
                    </label>
                </div> : t('admin-system-status__scheduled-upgrade__no-upgrade-scheduled')}
            </InfoField>

        </Section>
    );
};
