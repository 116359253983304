import React from 'react';

import { hasReportAccess } from '../actions/permissions';
import { Navigate } from 'react-router-dom';
import { baseAuthService } from 'components/Security/AuthenticationService';

/**
 * Checks given permission and renders 'access restricted' message or underlying page itself
 * @param {JSX.Element} Component
 * @return {JSX.Element}
 */

// eslint-disable-next-line react/display-name
const withNoReportsFallback = (Component, reportType) => (props) => {
    // if admin user, grant access by default
    const isGranted = baseAuthService.isAdmin() || hasReportAccess(reportType);

    return isGranted ? <Component {...props} /> :  <Navigate to="/forbidden" />;
};

export default withNoReportsFallback;
