import { useState, useEffect, useCallback } from 'react';
import { getBrands } from '../services/Brands';

export const useLoadBrands = () => {
    const [brands, setBrands] = useState([]);
    const [isBrandsLoading, setIsBrandsLoading] = useState(false);
    const [brandsLoadingError, setBrandsLoadingError] = useState(null);

    const loadBrands = useCallback(async () => {
        try {
            setIsBrandsLoading(true);
            setBrandsLoadingError(null);
            const brandsList = (await getBrands()) || [];

            setBrands(brandsList);
        } catch (e) {
            setBrands([]);
            setBrandsLoadingError(e);
        }

        setIsBrandsLoading(false);
    }, []);

    useEffect(() => { loadBrands(); }, []);

    return { brands, isBrandsLoading, brandsLoadingError, loadBrands };
};
