import { getAllDevices } from 'services/Device';
import { EventEmitter } from 'helpers/eventEmitter';

export class LoaderByBrands {
    constructor() {
       this._eventEmitter = new EventEmitter();
       this._isLoading = false;
       this._error = null;
       this._devices = [];
       this._request = null;
    }

    isLoading() {
        return this._isLoading;
    }

    getDevices() {
        return this._devices;
    }

    on(eventName, handler) {
        this._eventEmitter.on(eventName, handler);
    }

    off(eventName, handler) {
        this._eventEmitter.off(eventName, handler);
    }

    reset() {
        this._isLoading = false;
        this._error = null;
        this._devices = [];
    }

    async update(filters) {
        try {
            this._isLoading = true;
            this._eventEmitter.trigger('changed');
            const devices = await this._makeRequest(filters);
            this._onLoadSuccess(devices);
        } catch(err) {
            this._onLoadFailed(err);
        }

        this._eventEmitter.trigger('changed');
    }

    removeDevice() {
        
    }

    _onLoadSuccess(devices)  {
        this._devices = devices;
        this._isLoading = false;
        this._error = null;
    }

    _onLoadFailed(error) {
        this._devices = [];
        this._isLoading = false;
        this._error = error;
    }

    _getLoadDeviceParams(filters) {
        const result = {};

        Object.keys(filters)
            .filter(key => filters[key] !== '')
            .forEach(key => {
                result[key] = filters[key];
            });

        delete result.accountBrand;
        delete result.filterType;
        delete result.storeIds;

        return result;
    }

    _makeRequest(filters) {
        const params = this._getLoadDeviceParams(filters);

        return getAllDevices(params);
    }
}
