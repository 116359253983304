import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'; // Non-hook version
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce';

import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { Toggle } from 'library/Toggle';
import { SearchInput } from 'components/Inputs/SearchInput';
import { Label } from 'library/Label';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { DateFilter } from './DateFilter';

import './Header.scss';
import { accountTypes } from 'constants/accountTypes';

const debounceTimeout = 300;

const isSearchAvailable = searchString => searchString.length > 2;

const getWordsFromSearch = searchString => {
    if (isSearchAvailable(searchString)) {
        return searchString.toLowerCase().split(' ');
    }

    return [];
}

const onSearchStringChange = (searchString, onWordsChange) => {
    if (!onWordsChange) {
        return;
    }

    const words = getWordsFromSearch(searchString);
    onWordsChange(words);
};

// Without debouncing we have laggs with a lot of records on the page
const onSearchStringChangeDebounded = debounce(onSearchStringChange, debounceTimeout);

const searchStates = {
    SEARCH_ENABLED: 'SEARCH_ENABLED',
    SEARCH_DISABLED: 'SEARCH_DISABLED',
};

export const DEVICE_SEARCH_TYPES = {
    ALL: 'ALL',
    SOURCE: 'SOURCE',
    TARGET: 'TARGET',
};

const devicesSearchTypeItems = [{
    text: i18next.t('common__all-devices'),
    value: DEVICE_SEARCH_TYPES.ALL,
}, {
    text: i18next.t('common__source-devices'),
    value: DEVICE_SEARCH_TYPES.SOURCE,
}, {
    text: i18next.t('common__target-devices'),
    value: DEVICE_SEARCH_TYPES.TARGET,
}];

export const Header = ({
    permissionType,
    isDateRangeDisabled,
    deviceSearchType,
    filtersCount,
    onDateRangeChange,
    onWordsChange,
    onSearchStart,
    onFiltersReset,
    onDeviceSearchTypeChange,
}) => {
    const [searchString, setSearchString] = useState('');
    const [searchState, setSearchState] = useState(searchStates.SEARCH_DISABLED);
    const { t } = useTranslation();
    useEffect(() => {
        const newSearchState = isSearchAvailable(searchString) ?
            searchStates.SEARCH_ENABLED :
            searchStates.SEARCH_DISABLED;

        if (newSearchState === searchStates.SEARCH_ENABLED || newSearchState !== searchState) {
            onSearchStart && onSearchStart();
            onSearchStringChangeDebounded(searchString, onWordsChange);
        }

        setSearchState(newSearchState);
    }, [searchString, onSearchStart, setSearchState]);

    const createTaskUrl = useMemo(
        () => (permissionType === accountTypes.ADMIN ? '/admin/applyDeviceSettings' : '/settings/applyDeviceSettings'),
        [permissionType],
    );

    return (
        <div className="admin-apply-device-settings-taskbar">
            <Title>{t('common__apply-settings')}</Title>
            <div className='admin-apply-device-settings-taskbar-separated admin-apply-device-settings-taskbar-search-group'>
                <Label className='admin-apply-device-settings-taskbar-separated-label'>{t('common__search')}</Label>
                    <div className='admin-apply-device-settings-taskbar-separated-content'>
                        <Toggle value={deviceSearchType} onChange={onDeviceSearchTypeChange} isDisabled={isDateRangeDisabled}>{devicesSearchTypeItems}</Toggle>
                        <SearchInput
                            isDisabled={isDateRangeDisabled}
                            placeholder={t('common__serial-product-store-name-account')}
                            value={searchString}
                            onChange={value => setSearchString(value)}
                        />
                    </div>
            </div>
            <DateFilter label={t('common__show-me')} isDisabled={isDateRangeDisabled} onDateRangeChange={onDateRangeChange} />
            <div className='admin-apply-device-settings-taskbar-separated admin-apply-device-settings-taskbar-filters-count'>
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
            <div className='admin-apply-device-settings-taskbar-link'>
                <Button as={Link} to={createTaskUrl}>{t('common__create-new-task')}</Button>
            </div>
        </div>
    );
};
