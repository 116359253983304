
import React from 'react'
import '../Alerts/Alerts.css'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import { withReactRouter } from 'HOCs/withReactRouter';

class Message extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      key: this.props.location.state
    }
  }
  render () {
    const { t } = this.props;

    const message = t(this.state.key);

    return (
      <section>
        <div>
          <div className='forms clear'>
            <div className='appr'>
              <h4>{message}</h4>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Message.propTypes = {
    location: PropTypes.object
};

export default compose(
        withTranslation(),
        withReactRouter
)(Message);
