/* eslint-disable react/prop-types */
import React from 'react';
import { path } from 'ramda';
import classNames from 'classnames';

import { CheckboxCell } from '../CheckboxCell';
import { ExpandCell } from '../ExpandCell';
import { GridCell } from '../../GridCell';

export const Row = ({
    headers,
    data = {},
    selectable,
    isSelectionEnabled = true,
    expandable,
    expanded,
    expandHidden,
    selected,
    hovered,
    className,
    getCellClassName,
    odd = false,
    clickable = false,
    onSelectionChange,
    onExpandChange,
    onHoverChange,
    onClick,
    checkboxProps = {},
    showOverflow
}) => (
    <div
        className={classNames(['hme-grid-row', className, {
            'hme-grid-row-odd': odd,
            'hme-grid-row-hovered': hovered,
            'hme-grid-row-clickable': clickable
        }])}
        onMouseLeave={onHoverChange && (() => onHoverChange(false))}
        onMouseEnter={onHoverChange && (() => onHoverChange(true))}
        onMouseDown={onClick}
    >
        <CheckboxCell
            selectable={selectable}
            checked={selected}
            disabled={!isSelectionEnabled}
            onChange={onSelectionChange}
            {...checkboxProps}
        />
        <ExpandCell
            expandable={expandable}
            expanded={expanded}
            onExpandChange={(isExpanded) => onExpandChange(isExpanded)}
            hidden={expandHidden}
        />
        {
            headers.map(({ property, path: dataPath, lastInBlock, className, flex }) => (
                <GridCell
                    key={property}
                    lastInBlock={lastInBlock}
                    className={classNames(
                        className,
                        (showOverflow ? 'show-overflow': ''),
                        getCellClassName &&
                        data &&
                        Object.keys(data).length &&
                        getCellClassName(property, data)
                    )}
                    flex={flex}
                >
                    {path(dataPath, data)}
                </GridCell>
            ))
        }
    </div>
);
