/* eslint-disable react/prop-types */
import './Tile.scss';
import React from 'react';
import classNames from 'classnames';

export const Tile = ({ children, className }) => {
    return (
        <div className={classNames('hme-tile', className)}>
            {children}
        </div>
    );
};
