import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { notificationActions, showNotification, getStoreCountryName } from '../../Common/helpers';

import { useLoadDevice } from '../../Common/hooks/useLoadDevice';
import { useLoadDeviceScheduledUpgrade } from '../../Common/hooks/useLoadDeviceScheduledUpgrade';
import { useLoadDeviceSettings } from '../../Common/hooks/useLoadDeviceSettings';
import { useLoadDeviceRecentUpgradeTasks } from '../../Common/hooks/useLoadDeviceRecentUpgradeTasks';
import { useLoadDeviceUpgradeVersions } from '../../Common/hooks/useLoadDeviceUpgradeVersions';
import { useUpgradeDevice } from './useUpgradeDevice';
import { useLoadCountries } from '../../Common/hooks/useLoadCountries';
import { useReconnectDevice } from '../../Common/hooks/useReconnectDevice';
import { useRebootDevice } from '../../Common/hooks/useRebootDevice';
import { useReconnectLinkedDevice } from '../../Common/hooks/useReconnectLinkedDevice';
import { useRebootLinkedDevice } from '../../Common/hooks/useRebootLinkedDevice';

export const useSystemStatus = () => {
    const { t } = useTranslation();
    const { deviceUID } = useParams();
    const [upgradeTaskData, setUpgradeTaskData] = useState({});
    const [isSendUpgradeConfirmShown, setIsSendUpgradeConfirmShown] = useState(false);

    const { countries, isCountriesLoading } = useLoadCountries();
    const { device, isDeviceLoading, deviceLoadingError, loadDevice } = useLoadDevice(deviceUID);

    const { deviceSettings, isDeviceSettingsLoading, deviceSettingsLoadingError, loadDeviceSettings } =
      useLoadDeviceSettings(deviceUID);

      const { deviceRecentUpgradeTasks, isDeviceRecentUpgradeTasksLoading, deviceRecentUpgradeTasksLoadingError } =
          useLoadDeviceRecentUpgradeTasks(deviceUID);

    const { deviceUpgradeVersions, isDeviceUpgradeVersionsLoading, deviceUpgradeVersionsLoadingError } =
      useLoadDeviceUpgradeVersions(deviceUID);

    const { deviceScheduledUpgrade } = useLoadDeviceScheduledUpgrade(deviceUID);

    const { isDeviceReconnecting, reconnectDevice } = useReconnectDevice(loadDevice, loadDeviceSettings);

    const { isLinkedDeviceReconnecting, reconnectLinkedDevice } = useReconnectLinkedDevice(loadDevice, loadDeviceSettings);

    const { isLinkedDeviceRebooting, rebootLinkedDevice } = useRebootLinkedDevice(loadDevice, loadDeviceSettings);

    const { isDeviceRebooting, rebootDevice } = useRebootDevice(loadDevice, loadDeviceSettings);

    const { isDeviceUpgrading, upgradeDevice } = useUpgradeDevice(loadDevice, loadDeviceSettings);

    const onSendUpgrade = useCallback((upgradeTask) => {
        setUpgradeTaskData(upgradeTask);
        setIsSendUpgradeConfirmShown(true);
    }, []);

    const onSendUpgradeConfirmHide = useCallback(() => {
        setIsSendUpgradeConfirmShown(false);
    }, []);

    const onSendUpgradeConfirm = useCallback(async () => {
        const {status, message} = await upgradeDevice(upgradeTaskData);
        if(message && message === 'UPGRADE_IN_PROGRESS') {
            showNotification({ action: notificationActions.UPGRADE_IN_PROGRESS, status, t });
        } else {
            showNotification({ action: notificationActions.UPGRADE_DEVICE, status, t });
        }
    }, [upgradeTaskData, deviceUID, t]);

    const onDeviceReboot = useCallback(async (deviceToReboot) => {
        const status = await rebootDevice(deviceToReboot.Device_UID);
        showNotification({ action: notificationActions.REBOOT_DEVICE, status, t });
    }, [t]);

    const onDeviceReconnect = useCallback(async (deviceToReconnect) => {
        const status = await reconnectDevice(deviceToReconnect.Device_UID);
        showNotification({ action: notificationActions.RECONNECT_DEVICE, status, t });
    }, [t]);

    const onLinkedDeviceReboot = useCallback(async (deviceToReboot, deviceToLoad) => {
        const status = await rebootLinkedDevice(deviceToReboot.Device_UID, deviceToLoad.Device_UID);
        showNotification({ action: notificationActions.REBOOT_DEVICE, status, t });
    }, [t]);

    const onLinkedDeviceReconnect = useCallback(async (deviceToReconnect, deviceToLoad) => {
        const status = await reconnectLinkedDevice(deviceToReconnect.Device_UID, deviceToLoad.Device_UID);
        showNotification({ action: notificationActions.RECONNECT_DEVICE, status, t });
    }, [t]);

    const isDataLoading =
      isDeviceLoading ||
      isDeviceSettingsLoading ||
      isDeviceRecentUpgradeTasksLoading ||
      isDeviceUpgradeVersionsLoading ||
      isDeviceReconnecting ||
      isDeviceRebooting ||
      isLinkedDeviceReconnecting ||
      isLinkedDeviceRebooting ||
      isDeviceUpgrading ||
      isCountriesLoading;

    const error =
      deviceLoadingError ||
      deviceRecentUpgradeTasksLoadingError ||
      deviceUpgradeVersionsLoadingError ||
      deviceSettingsLoadingError;

    const storeCountry = useMemo(() => getStoreCountryName(device, countries), [device, countries]);

    return {
        device,
        deviceSettings,
        storeCountry,
        isDataLoading,
        error,
        onSendUpgrade,
        onDeviceReboot,
        onDeviceReconnect,
        onLinkedDeviceReboot,
        onLinkedDeviceReconnect,
        deviceRecentUpgradeTasks,
        deviceUpgradeVersions,
        deviceScheduledUpgrade,
        isSendUpgradeConfirmShown,
        onSendUpgradeConfirmHide,
        onSendUpgradeConfirm,
        upgradeTaskData
    };
};
