/* eslint-disable react/prop-types */
import React from 'react';
import { TilesContainer } from '../../../../../components/Common/TilesContainer';
import './HistoryTiles.scss';
import { TilesHeader } from './TilesHeader';
import { HistoryTile } from './HistoryTile/HistoryTile';
import { useTranslation } from 'react-i18next';

export const HistoryTiles = ({
    tiles=[],
    isLoading,
    filters,
    filtersCounter,
    availableFilters,
    onFiltersChange,
    resetFilters,
    resetSorts,
    filterLables,
    sortSelection,
    onSortChange
}) => {
    const { t } = useTranslation();
    return (
        <div className="hme-history-container-s">
            <TilesHeader
                tiles={tiles}
                isLoading={isLoading}
                filters={filters}
                filtersCounter={filtersCounter}
                availableFilters={availableFilters}
                onFiltersChange={onFiltersChange}
                resetFilters={resetFilters}
                resetSorts={resetSorts}
                filterLables={filterLables}
                sortSelection={sortSelection}
                onSortChange={onSortChange}
            />
            <TilesContainer
                tiles={tiles}
                isLoading={isLoading}
                TileComponent={HistoryTile}
                tileKey="messageId"
                noRecordsMessage={t('common__error--no-data-found')}
            />
        </div>
    );
};
