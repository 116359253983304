/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { INPUT_VARIANTS, InputComponent } from 'components/Inputs';
import { SINGLE_SELECT_VARIANTS, SingleSelect } from 'library/SingleSelect';

import { FieldsetTitle } from '../FieldsetTitle';
import EmailChips from '../../emailChips';

const defaultInputVariants = [INPUT_VARIANTS.LABEL_INSIDE_MOBILE_ONLY];
const defaultSelectVariants = [SINGLE_SELECT_VARIANTS.MOBILE_LABEL_INSIDE];

export const SectionOne = ({
    reportName,
    reportSubject,
    formatID,
    formats,
    emailChips,
    setEmailChips,
    setFormValues,
    reportNames,
    setHasDuplicateName,
    setIsInvalidEmail
}) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const handleTextInputChange = (event, field) => {
        setFormValues({
            [field]: event.trimStart()
        });
    };
    const handleChangeReportFormat = (event) => {
        const { text, value } = formats[event];
        setFormValues({
            formatID: value,
            format: text
        });
    };
    const handleDuplicateNames = () => {
        const nameToFind = reportName.toLocaleLowerCase().replace(/\s/gu, '').trim();
        const isDuplicated = reportNames.includes(nameToFind);
        setHasDuplicateName(isDuplicated);
        if (isDuplicated) {
            setErrorMessage(t('scheduler__message-error-duplicate-names'));
        } else {
            setErrorMessage('');
        }
    };

    return (
        <>
            <FieldsetTitle>{t('scheduler__create-header-one')}</FieldsetTitle>

            <div className="create-edit-scheduler__fields-wrapper">
                <div>
                    <InputComponent
                        value={reportName}
                        isRequired
                        placeholder={t('scheduler__create-report-name')}
                        showRemaining={true}
                        maxLength={30}
                        onBlur={handleDuplicateNames}
                        onChange={(event) => handleTextInputChange(event, 'reportName')}
                        label="scheduler__create-report-name"
                        variants={defaultInputVariants}
                        className="create-edit-scheduler__text-input"
                    />
                    {errorMessage &&
                        <p className="error">{errorMessage}</p>
                    }
                </div>
                <InputComponent
                    isRequired={true}
                    value={reportSubject}
                    onChange={(event) => handleTextInputChange(event, 'reportSubject')}
                    maxLength={30}
                    placeholder={t('scheduler__subject')}
                    showRemaining={true}
                    label="scheduler__subject"
                    variants={defaultInputVariants}
                    className="create-edit-scheduler__text-input"
                />
                <div>
                    <EmailChips
                        setFormValues={setFormValues}
                        setIsInvalidEmail={setIsInvalidEmail}
                        emailList={emailChips}
                        setEmailList={setEmailChips}
                    />
                </div>
                <SingleSelect
                    isRequired={true}
                    value={formatID}
                    placeholder="common__select"
                    onChange={handleChangeReportFormat}
                    items={formats}
                    label={t('scheduler__create-report-format')}
                    variants={defaultSelectVariants}
                />
            </div>
        </>
    );
};
