import React, { Children, isValidElement, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import { Button } from 'library/Button';

export { useTabs } from './hooks';

import './TabPanel.scss';

export const Tab = ({ text }) => (
    <Button>{text}</Button>
);

const activeTabVariants = ['tab', 'tab-active'];
const tabVariants = ['tab'];
const defaultVariants = [];

export const TAB_PANEL_VARIANTS = {
    SUBHEADER: 'subheader',
    TRANSPARENT: 'transparent',
};

/*
    Example of usage:
    <TabPanel activeTab={activeTab} onActiveTabChange={setActiveTab}>
        <Tab text='Downloaded' value='downloaded'>
            <DownloadComponent />
        </Tab>
        <Tab text='Uploaded' value='uploaded'>
            <UploadComponent />
        </Tab>
    </TabPanel>
*/

const TabButton = ({ text, isActive, disabled, value, onActiveTabChange }) => {
    const onClick = useCallback(() => {
        onActiveTabChange(value);
    }, [value, onActiveTabChange]);

    return (
        <Button
            variants={isActive ? activeTabVariants : tabVariants}
            disabled={disabled}
            onClick={onClick}
        >{text}</Button>
    );
};

export const TabPanel = ({ activeTab, onActiveTabChange, className, variants = defaultVariants, children }) => {
    const [tabs, setTabs] = useState([]);
    const [content, setContent] = useState(null);

    useEffect(() => {
        const newTabs = Children
            .toArray(children)
            .filter((tab) => isValidElement(tab) && tab.type === Tab)

        setTabs(newTabs);
    }, [children]);

    useEffect(() => {
        const active = tabs.find((tab) => tab.props.value === activeTab) || null;

        setContent(active && active.props.children);
    }, [tabs, activeTab]);

    return (
        <div className={classNames('hme-tab-panel', className)}>
            <div className={classNames('hme-tab-panel-tabs', variants.map((variant) => `hme-${variant}-tab-panel`))}>
                {
                    tabs.map(({ props: tabProps }) => tabProps.hidden ? null : (
                        <TabButton
                            key={tabProps.value}
                            text={tabProps.text}
                            disabled={tabProps.disabled}
                            value={tabProps.value}
                            isActive={tabProps.value === activeTab}
                            onActiveTabChange={onActiveTabChange}
                        />
                    ))
                }
            </div>
            <div className='hme-tab-panel-content'>
                {content}
            </div>
        </div>
    );
};
