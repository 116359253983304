const { checkIsNexeoTwoStepUpgrade } = require('./twoStepUpgrade');
const { findMaxVersion } = require('./findMaxVersion');

export const formDataValidator = (input) => {
    const {
        name,
        deviceType,
        peripheralDeviceType,
        targetDeviceVersions = [],
        maxTargetDeviceVersion,
        upgradeVersion,
        runDate,
        scheduleDays,
        installRunDate = '',
        installScheduleDays = '',
    } = input;
    const targetDeviceVersion = maxTargetDeviceVersion ?? findMaxVersion(targetDeviceVersions);

    const formErrorsConditions = [
        {
            key: 'name',
            condition: !name,
            message: 'device-upgrades__section__form__validation--upgrade-name',
        },
        {
            key: 'deviceType',
            condition: !deviceType,
            message: 'device-upgrades__section__form__validation--target-device-type',
        },
        {
            key: 'targetDeviceVersions',
            condition: targetDeviceVersions.length === 0,
            message: 'device-upgrades__section__form__validation--device-version',
        },
        {
            key: 'upgradeVersion',
            condition: !upgradeVersion,
            message: 'device-upgrades__section__form__validation--upgrade-version',
        },
        {
            key: 'runDate',
            condition: !runDate,
            message: 'device-upgrades__section__form__validation--device-upgrade-date',
        },
        {
            key: 'scheduleDays',
            condition: !scheduleDays,
            message: 'device-upgrades__section__form__validation--upgrade-week-days',
        },
    ];

    if (checkIsNexeoTwoStepUpgrade({
            deviceType,
            deviceVersion: targetDeviceVersion,
            peripheralDeviceType,
    })) {
        formErrorsConditions.push(...[{
            key: 'installRunDate',
            condition: !installRunDate,
            message: 'device-upgrades__section__form__validation--device-upgrade-date',
        },
        {
            key: 'installScheduleDays',
            condition: !installScheduleDays,
            message: 'device-upgrades__section__form__validation--upgrade-week-days',
        }]);
    }

    const formError = formErrorsConditions.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );

    return formError;
};

// form fields mapped to multiple validation messages
export const validationFieldsMessagesMap = {
    name: ['name'],
    deviceType: ['deviceType'],
    targetDeviceVersions: ['targetDeviceVersions'],
    upgradeVersion: ['upgradeVersion'],
    runDate: ['runDate'],
    scheduleDays: ['scheduleDays'],
    installScheduleDays: ['scheduleDays'],
    installRunDate: ['installRunDate'],
};
