/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components/Inputs';
import { AutoCompleteInput } from 'library/AutoCompleteInput';

import './StoreInputView.scss';

const defaultStoresHeaders = [{
    text: 'Store#',
    property: 'Store_Number',
    className: 'hme-store-input-view-store-number-cell',
    headerClassName: 'hme-store-input-view-store-number-cell',
    flex: 'none',
}, {
    text: 'Brand',
    property: 'Brand_Name',
    className: 'hme-store-input-view-brand-cell',
    headerClassName: 'hme-store-input-view-brand-cell',
    flex: 'none',
}, {
    text: 'Store Name',
    property: 'Store_Name',
    className: 'hme-store-input-view-store-name-cell',
    headerClassName: 'hme-store-input-view-store-name-cell',
    flex: 'none',
}, {
    text: 'common__device__serial-number',
    property: 'Device_SerialNumber',
    className: 'hme-store-input-view-device-serial-number-cell',
    headerClassName: 'hme-store-input-view-device-serial-number-cell',
    flex: 'none',
}, {
    text: 'common__device__product-id',
    property: 'Device_Product_ID',
    className: 'hme-store-input-view-device-serial-number-cell',
    headerClassName: 'hme-store-input-view-device-serial-number-cell',
    flex: 'none',
}, {
    text: 'Account',
    property: 'User_EmailAddress',
    className: 'hme-store-input-view-account-cell',
    headerClassName: 'hme-store-input-view-account-cell',
    flex: 'none',
}];

export const mobileGridHeaders = [
    {
        column: [
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__store__name', property: 'Store_Name' },
        ],
    },
    {
        column: [
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
            { text: 'common__device__product-id', property: 'Device_Product_ID' },
            { text: 'common__account', property: 'User_EmailAddress' },
        ],
    },
];

export const StoreInputView = ({
    stores,
    onStoreChange,
    isLoading = false,
    storesHeaders = defaultStoresHeaders,
    noSuggestionText = 'No device found.',
    placeholder = 'merge_devices__search__placeholder',
    ...inputProps
}) => {
    const { t } = useTranslation();
     return (
    <AutoCompleteInput
        className={classNames('hme-store-input-view', {
            'hme-store-input-view-no-stores': !isLoading && stores.length === 0,
        })}
        suggestions={stores}
        Component={SearchInput}
        headers={storesHeaders}
        mobileHeaders={mobileGridHeaders}
        rowKey='Store_ID'
        isLoading={isLoading}
        onSuggested={onStoreChange}
        noSuggestionText={noSuggestionText}
        placeholder={t(placeholder)}
        {...inputProps}
    />
)};
