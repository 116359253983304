import { CommonConstants } from 'Constants';

const {
    subscription: {
        jtechCustomerOnly,
        nexeoCustomerOnly,
        nitroData,
        nitroLeaderboard,
        nitroEssentials,
        nitroGamification
    }
} = CommonConstants;

const nitroSubscriptions = [nitroData, nitroLeaderboard, nitroEssentials, nitroGamification];

export const getSubscriptionsValue = (accountSubscriptionId) => {
    const isNexeoCustomerOnlySubscription = accountSubscriptionId === nexeoCustomerOnly;
    const isJTechCustomerOnlySubscription = accountSubscriptionId === jtechCustomerOnly;
    const isNitroSubscription = nitroSubscriptions.includes(accountSubscriptionId);

    return {
        isNexeoCustomerOnlySubscription,
        isJTechCustomerOnlySubscription,
        isNitroSubscription
    };
};
