import React, { useRef } from 'react';
import classNames from 'classnames';

import { isAccountOwner as checkIsAccountOwner } from 'services/Auth';
import { PUBLIC_ROUTES } from 'constants/routes';
import { navigationService } from 'components/Common/NavigationServices';

import { MenuItem } from '../../MenuItem';

import './AccountItem.scss';

const accountIcon = <i className='icon icon-account' />;

const accountUrl = navigationService.getUrl('accounts');
const helpUrl = navigationService.getUrl('help');
const companyUrl = navigationService.getUrl(PUBLIC_ROUTES.companyInfo);
const brandSharingUrl = navigationService.getUrl(PUBLIC_ROUTES.brandSharing);

const isAccountOwner = checkIsAccountOwner();

export const AccountItem = ({ userName, isOpen, onAccountClick, onLngItemClick }) => {
    const dropdownContainerRef = useRef();

    return (
        <div className='hme-account-dropdown--mobile'>
            <MenuItem
                className={classNames('hme-account-dropdown--mobile__menu-item', {
                    'hme-account-dropdown--mobile__menu-item--open': isOpen,
                })}
                icon={accountIcon}
                onClick={onAccountClick}
                tKey='account-dropdown__title--mobile'
                tOptions={{ userName }}
            />
            <div className='hme-account-dropdown--mobile__items' ref={dropdownContainerRef} style={{
                height: isOpen ? dropdownContainerRef.current.scrollHeight : 0
            }}>
                <MenuItem tKey="my-account__account-info" url={accountUrl} />
                <MenuItem tKey="my-account__company-info" url={companyUrl} />
                {isAccountOwner && <MenuItem tKey="profile__brand-sharing__title" url={brandSharingUrl} />}

                <MenuItem tKey='language-select__label' onClick={onLngItemClick} />
                <MenuItem tKey='common__help' url={helpUrl} />
            </div>
        </div>
    );
};
