import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { fetchTokenByUID } from 'services/TokenManagement';

import { tokenManagementBaseUrl, DEFAULT_TOKEN_MANAGER, FORM_DEFAULTS, HME_API_ID_MAP } from '../APIManagementForm/helpers/constants';

const tokenManagerAdaptor = (email = '') => {
    return {
        ...DEFAULT_TOKEN_MANAGER,
        email
    };
};

const tokenEmailsAdaptor = (emails = []) => {
    const { email: rawTokenManager = '' } = emails.find((email) => email.isTokenManager);
    const tokenManager = tokenManagerAdaptor(rawTokenManager);

    const [email2 = '', email3 = ''] = emails
            .filter((email) => !email.isTokenManager)
            .map(({ email }) => email);

    return {
        tokenManager,
        email2,
        email3
    };
};

const tokenAdaptor = (token = {}) => ({
    ...FORM_DEFAULTS[HME_API_ID_MAP[token?.apiId || 1]],
    ...token,
    apiType: HME_API_ID_MAP[token?.apiId || 1],
    ...tokenEmailsAdaptor(token.emails)
});

export const getAPITokenByUID = async (tokenUID, navigate, t) => {
    try {
        const token = await fetchTokenByUID(tokenUID);

        return tokenAdaptor(token);
    } catch (e) {
        navigate(tokenManagementBaseUrl);
        setTimeout(() => {
            addNotification({
                message: t('vaio_provider_provider-not-found'),
                autoClose: true,
                type: NOTIFICATION_TYPES.ERROR
            });
        }, 1000);
        return {};
    }
};
