import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'ramda';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import timeZoneApi from 'api/timeZoneApi';
import * as _ from 'underscore';
import { Config } from 'Config.js';
import { CommonConstants } from 'Constants';
import Api from 'Api';
import { getAllSchedules, getSchedules, saveSchedule, updateSchedule } from 'api/pbiReports';
import CommonLoader from 'components/Alerts/CommonLoader';
import AuthenticationService from 'components/Security/AuthenticationService';
import { createSelectSourceObject, getHierarchyLevels, getStoresIds, getStoreUID } from '../helpers';
import { NotificationsList } from 'library/NotificationsList';
import { addErrorNotification } from 'services/Notifications';
import withAccountEmail from 'HOCs/withAccountEmail';
import { withHMELayout } from 'HOCs/withHMELayout';
import { useCheckAccount } from 'hooks/useCheckAccount';
import AccountErrorMessage from 'components/Common/AccountErrorMessage';
import { getStoresTree } from 'pages/Users/ViewEdit/Common/helpers/getStoresTree';
import { scheduleSectionsValidator } from 'constants/pbiReports';
import { sortTimeZones } from 'helpers/sortTimeZones';
import { SaveFooter } from 'library/SaveFooter';
import { COMMON_ROUTES } from 'constants/routes';

import { SectionOne, SectionTwo, SectionThree } from './FormSections';
import { ReportsHeader } from 'components/Header/ReportsHeader';

import './createEditSchedule.scss';

// eslint-disable-next-line complexity
const CreateEditScheduler = () => {
    const { t } = useTranslation();
    const api = new Api();
    const authService = new AuthenticationService(Config.authBaseUrl);
    const [isEdit, setIsEdit] = useState(false);
    const [showCommonLoader, setShowCommonLoader] = useState(true);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        reportName: '',
        reportSubject: '',
        recipientEmail: '',
        formatID: 0,
        format: 'XLSX',
        timezoneID: 0,
        timezone_code: '',
        recurrenceID: 4,
        reportType: '',
        monthID: 0,
        weekID: null,
        stores: [],
        hierarchyLevelID: 0,
        timeMeasureID: 1,
        timeFormat: 'sec',
        pullIns: 0,
        daypartIDs: []
    });
    const {
        format,
        formatID,
        mails,
        monthID,
        reportType,
        recurrenceID,
        reportName,
        reportSubject,
        timezone_code,
        timezoneID,
        weekID,
        hierarchyLevelID,
        timeMeasureID,
        timeFormat,
        pullIns,
        daypartIDs
    } = formValues;
    const [timezones, setTimezones] = useState([]);
    const [available, setAvailable] = useState([]);
    const [storesTree, setStoresTree] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [isStoresReady, setIsStoresReady] = useState(false);
    const [searchParams] = useSearchParams();
    const [scheduledValues, setScheduledValues] = useState({});
    const [scheduledID, setScheduledID] = useState('');
    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const [emailChips, setEmailChips] = useState([]);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isSchedulerDataReady, setIsSchedulerDataReady] = useState(false);
    const [maxHierarchyLevel, setMaxHierarchyLevel] = useState(0);
    const [availableDayparts, setAvailableDayparts] = useState([]);
    const {
        formats,
        weekDays,
        monthDays,
        recurrences: recurrenceList,
        RECURRENCE_VALUES,
        reportTypes,
        hierarchyLevels: hierarchyLevelsList,
        timeMeasures: timeMeasuresList,
        timeFormats,
        pullInsList,
        daypartIDsList
    } = useMemo(() => createSelectSourceObject(t), []);
    const [reportNames, setReportNames] = useState([]);
    const [isLimitReached, setIsLimitReached] = useState(false);
    const [hasDuplicateName, setHasDuplicateName] = useState(false);
    const [detailsChecked, setDetailsChecked] = useState({
        noStores: false,
        noPermissions: false,
        accountError: false,
        checked: false
    });
    const [hierarchyLevels, setHierarchyLevels] = useState(hierarchyLevelsList);
    const [timeMeasures, setTimeMeasures] = useState(timeMeasuresList);
    const [recurrences, setRecurrences] = useState(recurrenceList);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        noStores,
        noPermissions,
        accountError
    } = detailsChecked;

    const isAdmin = authService.isAdmin();
    const userEmail = isAdmin ? authService.getUserEmail() : authService.getLoggedInUserEmail();
    const uuid = isAdmin ? authService.getUUID() : authService.getLoggedInUserUID();

    const initQueryParams = {
        uuid: uuid,
        timerDevicesAndNonNitroEssentialsOnly: true,
        includeDaypartIDs: true
    };

    const initComponent = async (stores) => {
        const { storeList, daypartIDs: availableDaypartIDs = [] } = stores;
        setAvailable(storeList);
        const data = await timeZoneApi.getAll();
        const { recordset } = await getAllSchedules({ userEmail });
        let reportNameList = [];
        if (!_.isEmpty(recordset)) {
            reportNameList = recordset.map((record) => {
                return record.name;
            });
        }

        daypartIDsList.forEach((daypart) => daypart.selected = false);
        if (availableDaypartIDs.length > 0) {
            setAvailableDayparts(daypartIDsList.filter((daypart) => availableDaypartIDs.includes(daypart.value)));
        } else {
            setAvailableDayparts(daypartIDsList);
        }

        /*
        omitting first timezone named "(GMT) Coordinated Universal Time"
        as it doesn't include timezone code i.e. +0 or -0
        */
        const [invalidTZ, ...validTZ] = data;
        const timezonesData = [{ text: 'common__select', value: '' }, ...sortTimeZones(validTZ)].map((zone, index) => ({
            text: zone.Name || zone.text,
            value: index
        }));
        setTimezones(timezonesData);
        let editMode = false;
        if (searchParams.has('id')) {
            const id = searchParams.get('id');
            setScheduledID(id);
            editMode = true;
            setIsEdit(editMode);
            const scheduledData = await getSchedules(id);
            reportNameList = reportNameList.filter((name) => scheduledData.recordset[0].name !== name);
            setScheduledValues(scheduledData.recordset[0]);
        }
        if (reportNameList.length >= 10 && editMode === false) {
            addErrorNotification(t('scheduler__message-error-limit-reached'), { autoClose: 3000 });
            setIsLimitReached(true);
        }
        reportNameList = reportNameList.map((name) => {
            return name.toLocaleLowerCase().replace(/\s/g, '').trim();
        });
        setReportNames(reportNameList);
        const getStores = () => {
            const baseUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getGroupHierarchyTree}`;
            const queryParams = `?zoomStoresOnly=true&uuid=${encodeURIComponent(uuid)}&filterOutNitroEssentialsStores=true`;
            const url = `${baseUrl}${queryParams}`;
            api.getData(url, (response) => {
                if (!_.isEmpty(response.data)) {
                    setStoresTree(getStoresTree(response.data));
                    if (!editMode) {
                        setShowCommonLoader(false);
                    }
                }
            }, (error) => {
                console.log(error);
            });
        };
        await getStores();
    };
    // check account and get scheduler info
    useCheckAccount(initComponent, setShowCommonLoader, setDetailsChecked, detailsChecked, '', initQueryParams);

    // eslint-disable-next-line complexity
    const onHandleSave = async () => {
        const store_id = getStoresIds(assigned, available).join();
        let code = '';
        if (timezone_code[0] === '+') {
            code = `${timezone_code.substring(1)}`;
        } else {
            code = timezone_code;
        }
        // Default option "select" has value 0 and it needs to be omitted in the final selection
        // which is why we are subtracting 1 from weekID to make it zero-indexed value
        const day_of_week = weekID !== null ? String(weekID - 1) : null;
        // Logic-wise monthID index starts from 1, so we are NOT subtracting 1
        const day_of_month = monthID !== null ? String(monthID) : null;
        const requestData = {
            body: '',
            day_of_month,
            day_of_week,
            format,
            frequency: recurrenceID.toString(),
            mails,
            name: reportName,
            report_type: reportType,
            status: true,
            store_id,
            subject: reportSubject,
            timezone_code: code,
            timezone_index: timezoneID - 1,
            userEmail,
            hierarchyLevelID: reportType !== 'multi' ? null : hierarchyLevelID,
            timeMeasureID: reportType === 'trends' ? null : timeMeasureID,
            timeFormat,
            pullIns,
            daypartIDs: formValues.reportType !== 'trends' && timeMeasureID === 1 ? daypartIDs.toString() : ''
        };
        setShowCommonLoader(true);
        try {
            if (!isEdit) {
                const response = await saveSchedule(requestData);
                if (!_.isEmpty(response)) {
                    navigate('/new-reports-scheduler/?status=created');
                }
            } else {
                requestData.id = scheduledID;
                const response = await updateSchedule(requestData);
                if (!_.isEmpty(response)) {
                    navigate('/new-reports-scheduler/?status=updated');
                }
            }
        } catch (error) {
            addErrorNotification('there was an error', { autoClose: 3000 });
            setShowCommonLoader(false);
        }
    };

    const handleSetFormValues = useCallback((newValue = {}) => {
        setFormValues((currentFormValues) => ({
            ...currentFormValues,
            ...newValue
        }));
    }, []);

    const handleCancel = useCallback(() => navigate(COMMON_ROUTES.scheduledReports), [navigate]);

    // Fill the form fiels only when the response data and the available stores list are loaded
    useEffect(() => {
        setShowCommonLoader(true);
        if (!_.isEmpty(scheduledValues) && available.length > 0) {
            const { text: formatText, value: formatValue } = formats.find((item) => item.text === scheduledValues.format.trim());
            let { text: timezoneText, value: timezoneValue } = timezones.find((item) => item.value === scheduledValues.timezone_index + 1);
            if (timezoneText) {
                timezoneText = timezoneText.substring(4, 7);
            }
            let weekValue = null;
            if (scheduledValues.day_of_week) {
                weekValue = weekDays.find((item) => item.value === parseInt(scheduledValues.day_of_week.trim()) + 1).value;
            }
            let monthValue = null;
            if (scheduledValues.day_of_month) {
                monthValue = monthDays.find((item) => item.value === parseInt(scheduledValues.day_of_month.trim())).value;
            }
            const storesIDs = scheduledValues.store_id.split(',');
            const storesUID = getStoreUID(storesIDs, available);
            setAssigned(storesUID);
            const recipentEmails = scheduledValues.mails.split(';');
            const currentDaypartIDs = scheduledValues.daypartIDs.length > 0 ? scheduledValues.daypartIDs.split(',').map(Number) : [];
            daypartIDsList.filter((daypart) => {
                if (currentDaypartIDs.includes(daypart.value)) {
                    daypart.selected = true;
                }
            });
            setEmailChips(recipentEmails);
            handleSetFormValues({
                reportName: scheduledValues.name,
                mails: scheduledValues.mails,
                reportSubject: scheduledValues.subject,
                formatID: formatValue,
                format: formatText,
                timezoneID: timezoneValue,
                timezone_code: timezoneText,
                recurrenceID: parseInt(scheduledValues.frequency.trim()),
                weekID: weekValue,
                monthID: monthValue,
                reportType: scheduledValues.report_type,
                hierarchyLevelID: scheduledValues.hierarchyLevelID,
                timeMeasureID: scheduledValues.timeMeasureID,
                timeFormat: scheduledValues.timeFormat.trim(),
                pullIns: scheduledValues.pullIns,
                daypartIDs: currentDaypartIDs
            });
            setIsSchedulerDataReady(true);
        }
    }, [scheduledValues, available, handleSetFormValues]);

    // Enable the save button only when the form is completed
    // eslint-disable-next-line complexity
    useEffect(() => {
        let incompleteFields = [];
        incompleteFields = scheduleSectionsValidator.requiredFields.filter((field) =>
            formValues[field] === ''
        );
        if ((reportType === 'trends' || reportType === 'single') && assigned.length !== 1) {
            incompleteFields.push('stores');
        } else if (reportType === 'multi' && assigned.length < 2) {
            incompleteFields.push('stores');
        }
        if (reportType === 'multi' && (hierarchyLevelID === null || hierarchyLevelID === undefined)) {
            incompleteFields.push('hierarchyGroup');
        }
        if (formValues.timezoneID === 0) {
            incompleteFields.push('timezoneID');
        }
        if (formValues.recurrenceID === RECURRENCE_VALUES.SELECT) {
            incompleteFields.push('recurrenceID');
        }
        if (formValues.recurrenceID === RECURRENCE_VALUES.WEEKLY && (weekID === null || !Number(weekID))) {
            incompleteFields.push('weekID');
        }
        if (formValues.recurrenceID === RECURRENCE_VALUES.MONTHLY && (monthID === null || !Number(monthID))) {
            incompleteFields.push('monthID');
        }
        if (reportType !== 'trends'
            && ((timeMeasureID === null || timeMeasureID === undefined)
            || timeMeasureID === 1 && daypartIDs.length === 0)) {
            incompleteFields.push('daypartIDs');
        }
        if (incompleteFields.length === 0) {
            setIsFormCompleted(true);
        } else {
            setIsFormCompleted(false);
        }
    }, [formValues, assigned]);

    useEffect(() => {
        if (reportType === 'trends') {
            const updateRecurrences = recurrenceList.filter((recurrence)=> recurrence.value !== RECURRENCE_VALUES.DAILY);
            setRecurrences(updateRecurrences);
            if (formValues.recurrenceID === RECURRENCE_VALUES.DAILY) {
                handleSetFormValues({ recurrenceID: RECURRENCE_VALUES.SELECT });
            }
            if (assigned.length > 1) {
                setErrorMessage(t('scheduler__error-participating-store-limit'));
            } else {
                setErrorMessage('');
            }
        } else {
            setErrorMessage('');
            setRecurrences(recurrenceList);
            if (reportType === 'single') {
                const dayPartTimeMeasureIndex = 1;
                setTimeMeasures(timeMeasuresList.slice(dayPartTimeMeasureIndex));
                setFormValues(({ timeMeasureID: currentTimeMeasureID, ...currentFormValues }) => ({
                    ...currentFormValues,
                    timeMeasureID: currentTimeMeasureID ? currentTimeMeasureID : timeMeasuresList[dayPartTimeMeasureIndex].value
                }));
                if (assigned.length > 1) {
                    setErrorMessage(t('scheduler__error-single-store'));
                } else {
                    setErrorMessage('');
                }
            } else {
                setTimeMeasures(timeMeasuresList);
                if (assigned.length === 1) {
                    setErrorMessage(t('scheduler__error-multi-store'));
                } else {
                    setErrorMessage('');
                }
            }
        }
    }, [reportType, assigned, handleSetFormValues]);

    useEffect(() => {
        if (storesTree.length > 0) {
            setIsStoresReady(true);
            setShowCommonLoader(false);
            let currentMax = maxHierarchyLevel;
            storesTree.map((branch) => {
                const level = getHierarchyLevels(branch);
                if (level > currentMax) {
                    currentMax = level;
                }
            });
            setMaxHierarchyLevel(currentMax);
        }
    }, [storesTree]);

    useEffect(() => {
        if (maxHierarchyLevel <= 1) {
            setHierarchyLevels(hierarchyLevelsList.slice(0, 1));
        } else if (maxHierarchyLevel === 2) {
            setHierarchyLevels(hierarchyLevelsList.slice(0, 2));
        } else if (maxHierarchyLevel === 3) {
            setHierarchyLevels(hierarchyLevelsList.slice(0, 3));
        } else if (maxHierarchyLevel === 4) {
            setHierarchyLevels(hierarchyLevelsList.slice(0, 4));
        } else {
            setHierarchyLevels(hierarchyLevelsList.slice(0, 5));
        }
    }, [maxHierarchyLevel]);

    if (accountError) {
        return (
            <div className="noStores">
                <AccountErrorMessage
                    t={t}
                    noStores={noStores}
                    noPermissions={noPermissions}
                />
            </div>
        );
    }

    return (
        <>
            <NotificationsList />
            <CommonLoader showLoader={showCommonLoader} message="common__loading" />
            {!showCommonLoader && <section className="create-edit-scheduler__wrapper">
                <ReportsHeader isAdmin={isAdmin} hideUserGuide className="create-edit-scheduler__header" />

                <div className="create-edit-scheduler__body">
                    <div className="create-edit-scheduler__form-wrapper">
                        <div className="create-edit-scheduler__form-fieldset create-edit-scheduler__form-fieldset--1">
                            <SectionOne
                                reportName={reportName}
                                reportSubject={reportSubject}
                                formatID={formatID}
                                formats={formats}
                                emailChips={emailChips}
                                setEmailChips={setEmailChips}
                                setFormValues={handleSetFormValues}
                                reportNames={reportNames}
                                setHasDuplicateName={setHasDuplicateName}
                                setIsInvalidEmail={setIsInvalidEmail}
                            />
                        </div>
                        <div className="create-edit-scheduler__form-fieldset create-edit-scheduler__form-fieldset--2">
                            {timezones.length > 0 &&
                                <SectionTwo
                                    timezoneID={timezoneID}
                                    timezones={timezones}
                                    recurrences={recurrences}
                                    recurrenceID={recurrenceID}
                                    RECURRENCE_VALUES={RECURRENCE_VALUES}
                                    reportTypes={reportTypes}
                                    timeFormats={timeFormats}
                                    timeFormat={timeFormat}
                                    pullInsList={pullInsList}
                                    pullIns={pullIns}
                                    timeMeasures={timeMeasures}
                                    weekID={weekID}
                                    monthID={monthID}
                                    weekDays={weekDays}
                                    monthDays={monthDays}
                                    formValues={formValues}
                                    setFormValues={handleSetFormValues}
                                />
                            }
                        </div>
                        <div className="create-edit-scheduler__form-fieldset create-edit-scheduler__form-fieldset--3">
                            <SectionThree
                                storesTree={storesTree}
                                assigned={assigned}
                                setAssigned={setAssigned}
                                isEdit={isEdit}
                                isSchedulerDataReady={isSchedulerDataReady}
                                isStoresReady={isStoresReady}
                                errorMessage={errorMessage}
                                hierarchyLevels={hierarchyLevels}
                                maxHierarchyLevel={maxHierarchyLevel}
                                formValues={formValues}
                                setFormValues={handleSetFormValues}
                                availableDayparts={availableDayparts}
                            />
                        </div>
                    </div>
                </div>

                <SaveFooter
                    isSubmitEnabled={isFormCompleted && !hasDuplicateName && !isLimitReached && !isInvalidEmail}
                    onSave={onHandleSave}
                    onCancel={handleCancel}
                    additionalClass="create-edit-scheduler__footer"
                />
            </section>}
        </>
    );
};

export default compose(
        withHMELayout({
            contentClasses: ['create-edit-scheduler']
        }),
        withAccountEmail
)(CreateEditScheduler, { ownerClassName: 'scheduler-page__owner-email-wrapper' });
