import React from 'react';
import classNames from 'classnames';

import { Label } from 'library/Label';

import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';

import './FileName.scss';

export const FileName = ({ fileName }) => (
    <div className={classNames('hme-file-input-file-name', {
        'hme-file-input-file-name-hidden': fileName.length === 0,
    })}>
        <SuccessIcon className='hme-file-input-file-name-icon' /><Label>{ fileName || 'No' }</Label>
    </div>
);
