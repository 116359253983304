import * as StoreDetailsPopup from '../actionTypes/StoreDetailsPopup/storeDetailsPopup'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'

export const closePopup = () => {
  return {
    type: StoreDetailsPopup.CLOSE_POPUP,
    payload: null
  }
}

export const openPopup = (isAdmin) => {
  return {
    type: StoreDetailsPopup.OPEN_POPUP,
    payload: isAdmin
  }
}

export const openPopupPreInstall = ({ preInstallPopUpComponent, isAdmin }) => {
  return {
    type: StoreDetailsPopup.OPEN_POPUP_PRE_INSTALL,
    payload: { preInstallPopUpComponent, isAdmin }
  }
}

export const initModal = (data) => {
  return {
    type: StoreDetailsPopup.INIT_STORE_POPUP,
    payload: data
  }
}

const getStoreData = (suid) => new Promise(res => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getStore + '?suid=' + suid
  const api = new Api()
  api.getData(url, data => {
    if (data.Device_Details && data.Device_Details[0].Timezone !== undefined) {
      let Timezone = data.Device_Details[0].Timezone
      data.storeDetails.Timezone = Timezone
    } else if ( data && data.key == 'noAccess') {
      console.log('User doesnot have access to store')
    } else {
      data.storeDetails.Timezone = null
    }
    res(data);
  })
})

export const initStoreDetail = (suid, isAdmin, callback) => {
  return (dispatch) => {
    getStoreData(suid)
      .then(data => {
        if (data && data.key != 'noAccess') {
          dispatch(initModal(data))
          dispatch(openPopup(isAdmin))
        }
        callback && callback(data)    
      })
      .catch(err => console.log(err));
  }
}

export const initPreInstallStoreDetail = (suid, preInstallPopUpComponent, isAdmin) => {
  return (dispatch) => {
    getStoreData(suid)
      .then(data => {
        dispatch(initModal(data))
        dispatch(openPopupPreInstall({ preInstallPopUpComponent, isAdmin }))
      })
      .catch(err => console.log(err));
  }
}
