import { useCallback } from 'react';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { NotificationCenterService } from 'services/NotificationCenter';

export const useReadNotification = () => {
    const {
        run: runRead,
        isLoading: isReading,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const readNotification = useCallback((id) => {
        return runRead(NotificationCenterService.read(id));
    }, []);

    return {
        readNotification,
        isReading
    };
};
