import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';
import { useLocation, useNavigate } from 'react-router-dom';

import { CenterLoader } from 'components/Common/CenterLoader';
import { NotificationsList } from 'library/NotificationsList';
import { getIp, isAdmin, isPartner, isLoggedIn, clearAuthData } from 'services/Auth';
import { addErrorNotification, dismissNotification } from 'services/Notifications';
import { login, checkTimeout, getLoginAttempts, verifyUsername } from './Controller';
import { Admin } from './Admin';
import { Client } from './Client';
import { defaultNotificationOptions } from 'constants/login';

import './Login.scss';
// We have to include that css file because it contains styles that are out of login page scope
import 'components/Security/Login.css';

const FormComponent = cond([
    [({ isLoading, isAdmin }) => isLoading && isAdmin, ({ t }) => (<CenterLoader>{t('common__loading')}</CenterLoader>)],
    [({ isAdmin }) => isAdmin, (props) => <Admin {...props} />],
    [T, (props) => <Client {...props} />]
]);

export const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [attempts, setAttempts] = useState(getLoginAttempts());
    const [isExceeded, setIsExceeded] = useState(!checkTimeout());
    const [maxAttempts, setMaxAttempts] = useState(5);
    const [isAdminState, setIsAdminState] = useState(isAdmin);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isPartnerState = useMemo(() => isPartner(), []);

    const ipLoadingPromise = useMemo(() => getIp(), []);

    const onLogin = useCallback(async (formData, ssoTokenData) => {
        setIsLoading(true);
        dismissNotification();

        const redirectUrl = (location.state && location.state.referrer) || '/welcome';

        try {
            const ip = await ipLoadingPromise;
            const loginResult = await login(formData, redirectUrl, ip, ssoTokenData);
            if (loginResult.success) {
                if (loginResult.redirectTo) {
                    navigate('/validateDfaAuth', { state: location.state || {} });
                }
                else if (loginResult.location) {
                    return loginResult.location
                }
                return;
            }

            setAttempts(loginResult.loginAttempts);
            setMaxAttempts(loginResult.maxAttempts);
            setIsExceeded(loginResult.isExceeded);

            setIsLoading(false);
        } catch (err) {
            // display INTERNAL SERVER ERROR- 500 only for public portal login
            if (!isAdminState) {
                addErrorNotification('common__error--internal-server', defaultNotificationOptions);
            }

            setIsLoading(false);
        }
    }, [ipLoadingPromise, location, setIsLoading, setAttempts]);

    useEffect(() => {
        if (!isLoggedIn() && isAdminState && !location.pathname.includes('/admin')) {
            clearAuthData();
            setIsAdminState(false);
        } else if (!isLoggedIn() && !isAdminState && location.pathname.includes('/admin')) {
            clearAuthData();
            setIsAdminState(true);
        }
        if (location.search) {
            let ssoTokenData;
            try {
                const decodedURL = decodeURIComponent(location.search.substring(1));
                ssoTokenData = JSON.parse(decodedURL.substring(decodedURL.indexOf('=') + 1));
            } catch (err) { 
                console.warn('Could not parse token data', err);
            }
            if (ssoTokenData && ssoTokenData.accessToken) {
                sessionStorage.setItem('ssoUserId', ssoTokenData.userId);
                onLogin({
                    username: ssoTokenData.username,
                }, ssoTokenData);
            }
        }
    }, [isAdminState, location]);

    const onVerifyUsername = useCallback(async (username) => {
        setAttempts(0);
        setMaxAttempts(0);
        setIsExceeded(0);

        verifyUsername(username);
    }, []);

    return (
        <div className="hme-login-page-container">
            <NotificationsList className="hme-login-page-notifications-list" limit={1} />
            <FormComponent
                className="hme-login-page"
                isAdmin={isAdminState}
                isLoading={isLoading}
                t={t}
                attempts={attempts}
                maxAttempts={maxAttempts}
                isExceeded={isExceeded}
                onLogin={onLogin}
                isPartner={isPartnerState}
                onVerifyUsername={onVerifyUsername}
            />
        </div>
    );
};
