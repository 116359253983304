import React from 'react';

import { useTranslation } from 'react-i18next';

import './DeviceUpgradeInfo.scss';

export const DeviceUpgradeInfo = ({ createdBy, upgradeVersion }) => {
    const { t } = useTranslation();

    return (
        <div className="device-upgrade-info">
            <div className="device-upgrade-info__box">
                <strong className="device-upgrade-info__title">{t('common__sent-by')}</strong>
                <p className="device-upgrade-info__text">{createdBy}</p>
            </div>
            <div className="device-upgrade-info__box">
                <strong className="device-upgrade-info__title">{t('common__firmware')}</strong>
                <p className="device-upgrade-info__text">{upgradeVersion}</p>
            </div>
        </div>
    );
};
