import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonConstants } from 'Constants';
import { Title } from 'library/Title';
import { Checkbox } from 'library/Checkbox';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

import './DeviceTypeSection.scss';

const { deviceType } = CommonConstants;
const allowedDeviceTypes = {
    [deviceType.zoomNitro.id]: 'common__device__type--zoom-nitro',
    [deviceType.nexeo.id]: 'common__device__type--nexeo-base',
    [NEXEO_PERIPHERAL_TYPES.IB7000.deviceTypeID]: 'common__device__type--ib7000',
};

export const DeviceTypeSection = ({
    deviceTypeID,
    versions,
    allVersionsApproved,
    onToggleVersion,
    onToggleAllVersionsApproved,
 }) => {
    const { t } = useTranslation();

    const uniqueVersions = useMemo(
        () =>
            versions.reduce((acc, curr) => {
                if (!acc.find((item) => item.version === curr.version)) {
                    acc.push(curr);
                }

                return acc;
            }, []),
        [versions],
    );

    return (
        <div className="firmware-management-view__section">
            <Title>{t(allowedDeviceTypes[deviceTypeID])}</Title>
            <div className="firmware-management-view__section__versions">
                <div className="firmware-management-view__section__subtitle">
                    <Checkbox
                        value={deviceTypeID}
                        label={t('firmware_management__view__not-required-approval')}
                        checked={allVersionsApproved}
                        disabled={false}
                        onChange={(checked) => onToggleAllVersionsApproved(checked, deviceTypeID)}
                    />
                </div>
                <div className="firmware-management-view__section__subtitle">
                    {t('firmware_management__view__firmware-versions')}
                </div>
                <div className="firmware-management-view__section__version-list-wrapper">
                    <div className="firmware-management-view__section__version-list">
                        {uniqueVersions.map(({ id, version, selected }) => (
                            <div className="firmware-management-view__section__version" key={id}>
                                <Checkbox
                                    value={id}
                                    label={version}
                                    checked={selected || allVersionsApproved}
                                    disabled={allVersionsApproved}
                                    onChange={(checked) => onToggleVersion(deviceTypeID, id, checked, allVersionsApproved)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
