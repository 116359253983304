import { Config } from '../../Config'
import AuthenticationService from '../Security/AuthenticationService'
import NavigationService from '../Common/NavigationServices'
import _ from 'underscore'
export default class CommonService {
  constructor () {
    this.state = {};
    this.authService = new AuthenticationService(Config.authBaseUrl)
    this.navigate = new NavigationService()
  }

  // common function, handles both add and edit role validation
  validateRoles (permissionsId, roleName) {
    let errors = []
    let isformValidation
    if (roleName.length === 0) {
      isformValidation = false
      errors.push('add-role__error--role-name-empty')
    }
    if (permissionsId.length === 0) {
      isformValidation = false
      errors.push('add-role__error--no-permission')
    }
    if (permissionsId.length > 0 && roleName.length > 0) {
      isformValidation = true
    }
    let result = {
      isformValidation: isformValidation,
      errors: errors
    }
    return result
  }

  removeDuplicate (permissionList, isEdit) {
    let permissionName = []
    let result = _.groupBy(permissionList, 'value')
    _.map(result, (obj, key) => {
      let name = {}
      let length = _.size(obj)
      if (length > 0 && length === 1) {
        name.value = obj[0].value
        name.isChecked = obj[0].isChecked
        name.index = obj[0].index
        permissionName.push(name)
      } else if (length > 1 && isEdit) {
        let objLength = (_.size(obj) - 1)
        if (obj[objLength].isChecked) {
          name.value = obj[objLength].value
          name.isChecked = obj[objLength].isChecked
          name.index = obj[objLength].index
          permissionName.push(name)
        }
      }
    })
    return permissionName
  }
  // Check for the dublicates and removes.
  // This function is used in CIB and Zoom Components.
  removeDuplicateDevices (DeviceId) {
    let deviceID = []
    let result = _.groupBy(DeviceId, 'device_ID')
    _.map(result, (obj, key) => {
      let deviceDetails = {}
      let length = _.size(obj)
      if (length > 0 && length === 1) {
        deviceDetails.device_ID = obj[0].device_ID
        deviceDetails.isChecked = obj[0].isChecked
        deviceDetails.isActive = obj[0].isActive
        deviceID.push(deviceDetails)
      } else if (length > 1) {
        let objLength = (_.size(obj) - 1)
        if (obj[objLength].isChecked) {
          deviceDetails.device_ID = obj[objLength].device_ID
          deviceDetails.isChecked = obj[objLength].isChecked
          deviceDetails.isActive = obj[objLength].isActive
          deviceID.push(deviceDetails)
        }
      }
    })
    return deviceID
  }
  // Checks for the enabling the remove system button.
  // This function is used in CIB and Zoom Components.
  enableRemoveBtn (event, deviceId, active) {
    let deviceIDs = []
    let disableRemove = false
    let input = {
      isChecked: event.currentTarget.checked,
      device_ID: deviceId,
      isActive: active
    }
    deviceIDs.push(input)
    let deviceDetails = this.removeDuplicateDevices(deviceIDs)
    if (deviceDetails.length > 0 && deviceDetails) {
      _.map(deviceDetails, (obj) => {
        if (obj.isChecked === true) {
          disableRemove = false
        } else {
          disableRemove = true
        }
      })
    } else {
      disableRemove = true
    }
    return disableRemove
  }

  // get the JSON object form API and convert to React Page display
  convertDBObjectToReact (dbObj, preferenceSort, tables) {
    let firstTable = tables.firstTable
    let tableData = tables.tableData
    let EmptyTableDropDown = tables.EmptyTableDropDown
    dbObj.map((item, i) => {
      const id = item.id
      if (i === 0) {
        firstTable = {
          id: id,
          disabled: true,
          header: item.title,
          columns: []
        }
        item.colOrder.map((col) => {
          if (col === 'StoreRank') {
            firstTable.columns.push({
              header: 'Rank',
              data: [1, 4, 8]
            })
          } else if (col === 'Store') {
            firstTable.columns.push({
              header: 'Store',
              data: [14165, 55171, 66188]
            })
          } else if (col === 'Store_Name') {
            firstTable.columns.push({
              header: 'Name',
              data: ['HM Eats\'', 'HM Eats\'', 'HM Eats\'']
            })
          } else {
            firstTable.columns.push({
              header: 'Goal',
              data: [210, 210, 210]
            })
          }
        })
      } else {
        let newTable = {
          id: id,
          disabled: false,
          header: item.title,
          columns: []
        }
        let removecar = true
        let removeavg = true
        let removegoal = true
        item.colOrder.map((col) => {
          if (col === (id + 'Cars')) {
            removecar = false
            let t1cars = {
              isremove: false,
              default: false,
              sortasc: false,
              header: 'Cars',
              data: [66, 59, 85]
            }
            t1cars.default = (preferenceSort.preference === (id + 'Cars '))
            t1cars.sortasc = (preferenceSort.sort === ' ASC')
            newTable.columns.push(t1cars)
          } else if (col === (id + 'Avg')) {
            removeavg = false
            let t1avg = {
              isremove: false,
              default: false,
              sortasc: false,
              header: 'Avg',
              data: [165, 171, 166]
            }
            t1avg.default = (preferenceSort.preference === (id + 'Avg '))
            t1avg.sortasc = (preferenceSort.sort === ' ASC')
            newTable.columns.push(t1avg)
          } else if (col === (id + 'Percent')) {
            removegoal = false
            let t1precent = {
              isremove: false,
              default: false,
              sortasc: false,
              header: 'Goal%',
              data: [77, 71, 69]
            }
            t1precent.default = (preferenceSort.preference === (id + 'Percent '))
            t1precent.sortasc = (preferenceSort.sort === ' ASC')
            newTable.columns.push(t1precent)
          }
        })
        if (item.colOrder.length < 3) {
          if (removegoal) {
            let t1precent = {
              isremove: true,
              default: false,
              sortasc: false,
              header: 'Goal%',
              data: [77, 71, 69]
            }
            newTable.columns.push(t1precent)
          }
          if (removeavg) {
            let t1avg = {
              isremove: true,
              default: false,
              sortasc: false,
              header: 'Avg',
              data: [165, 171, 166]
            }
            newTable.columns.push(t1avg)
          }
          if (removecar) {
            let t1cars = {
              isremove: true,
              default: false,
              sortasc: false,
              header: 'Cars',
              data: [66, 59, 85]
            }
            newTable.columns.push(t1cars)
          }
        }
        tableData.push(newTable)
        EmptyTableDropDown.map((header) => {
          if (header.name === item.title) {
            header.visible = false
          }
        })
      }
    })
    return { firstTable, tableData, EmptyTableDropDown }
    /// this.setState({ firstTable: firstTable, tableData: tableData })
  }

  //  Convert the React page JSON to DB JSON file
  convertReactToDBObject (firstTable, tableData) {
    let DashboardOrders = []
    let PreferenceDashSort = {}
    let PreferenceDashSortC = {}
    let preference = ''
    let Sort = ''
    let PreferenceDash = []
    let tableobj = {
      id: 't0',
      colOrder: ['StoreRank', 'Store', 'GoalA'],
      title: firstTable.header
    }
    if (firstTable.columns.length > 3) {
      tableobj.colOrder = ['StoreRank', 'Store', 'Store_Name', 'GoalA']
    }
    PreferenceDash.push(tableobj)
    tableData.map((item) => {
      let tdata = {
        id: item.id,
        colOrder: [],
        title: item.header
      }
      item.columns.map((col) => {
        if (!col.isremove) {
          if (col.header === 'Cars') {
            tdata.colOrder.push(tdata.id + 'Cars')
            if (col.default) {
              preference = tdata.id + 'Cars'
            }
          } else if (col.header === 'Avg') {
            tdata.colOrder.push(tdata.id + 'Avg')
            if (col.default) {
              preference = tdata.id + 'Avg'
            }
          } else {
            tdata.colOrder.push(tdata.id + 'Percent')
            if (col.default) {
              preference = tdata.id + 'Percent'
            }
          }
        }
        if (col.default) {
          Sort = col.sortasc ? 'ASC' : 'DESC'
        }
      })
      DashboardOrders.push(item.header.replace(/ /g, '|'))
      PreferenceDash.push(tdata)
    })
    let PreferenceDashSortObj = { preference, Sort }
    PreferenceDashSortC = (preference && Sort) ? preference + ' | ' + Sort : null
    PreferenceDashSort = preference + ' | ' + Sort
    let DashboardOrder = DashboardOrders.join(', ')
    let leaderBoardPreference = { PreferenceDash, PreferenceDashSortC, PreferenceDashSort, DashboardOrder, PreferenceDashSortObj }
    return leaderBoardPreference
  }

  getDefaultPreferenceDash (preferenceValues) {
    let preferenceDashObject = [
      {
        id: 't0',
        colOrder: ['StoreRank', 'Store', 'Store_Name', 'GoalA'],
        title: 'Stores'
      },
      {
        id: 't1',
        colOrder: ['t1Cars', 't1Avg', 't1Percent'],
        title: 'Current Day'
      },
      {
        id: 't2',
        colOrder: ['t2Cars', 't2Avg', 't2Percent'],
        title: 'Previous Day'
      }
    ]
    preferenceValues.PreferenceDash = JSON.stringify(preferenceDashObject)
  }

  getDefaultDisplaySettings (preferenceValues) {
    preferenceValues.TimeFormat = preferenceValues.TimeFormat ? preferenceValues.TimeFormat : 'ss'
    preferenceValues.PreferencePrimeColors = preferenceValues.PreferencePrimeColors ? preferenceValues.PreferencePrimeColors : '#00b04c|#dcba00|#b40000'
    preferenceValues.PreferenceDefaultView = preferenceValues.PreferenceDefaultView ? preferenceValues.PreferenceDefaultView : 'Day'
    preferenceValues.PreferenceDashPullins = preferenceValues.PreferenceDashPullins ? preferenceValues.PreferenceDashPullins : '11'
    preferenceValues.PreferenceStoreColumnName = preferenceValues.PreferenceStoreColumnName ? preferenceValues.PreferenceStoreColumnName : 'Exclude'
    preferenceValues.PreferenceDashMetric = preferenceValues.PreferenceDashMetric ? preferenceValues.PreferenceDashMetric : '2000'
    preferenceValues.PreferenceLanguage = preferenceValues.PreferenceLanguage ? preferenceValues.PreferenceLanguage : 'en'
    preferenceValues.PreferenceDashSort = preferenceValues.PreferenceDashSort ? preferenceValues.PreferenceDashSort : 't1Avg | ASC'
    preferenceValues.DashboardOrder = preferenceValues.DashboardOrder ? preferenceValues.DashboardOrder : 'Current|Day, Previous|Day'
    preferenceValues.PreferenceDashSortC = preferenceValues.PreferenceDashSortC ? preferenceValues.PreferenceDashSortC : 't1Avg | ASC'
    preferenceValues.PreferenceHideGoal = preferenceValues.PreferenceHideGoal ? preferenceValues.PreferenceHideGoal : '0';
  }
}
