import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { Label } from 'library/Label';
import { Radio } from 'library/Radio';
import { ErrorLabel } from 'library/ErrorLabel';
import { DEFAULT_SUBSCRIPTION_TYPE, isFormFieldDisabled, subscriptionTypeList } from '../../helpers';
import { CommonConstants } from 'Constants';

const { subscription } = CommonConstants;

import './SubscriptionType.css';
import classNames from 'classnames';

const packSubsciptionNitro = (id, isNitro) => `${id}-${Number(isNitro)}`;
const unpackSubscriptionNitro = (idNitro) => String(idNitro).split('-').map(Number);

const isNitroChange = (newSubscription, oldSubscription) => {
    const lbNitroSubscriptions = [Number(subscription.nitroLeaderboard), Number(subscription.nitroGamification)];
    const lbNitroSubscriptionsKeys = lbNitroSubscriptions
            .map((s) => packSubsciptionNitro(s, 1));

    const [newSubscriptionTypeValue, newSubscriptionIsNitro] = unpackSubscriptionNitro(newSubscription);
    const [oldSubscriptionTypeValue, oldSubscriptionIsNitro] = unpackSubscriptionNitro(oldSubscription);

    return (lbNitroSubscriptions.includes(oldSubscriptionTypeValue)
        && (!lbNitroSubscriptionsKeys.includes(newSubscription)
            || (newSubscriptionIsNitro === 1 && oldSubscriptionIsNitro === 0)
        )
    );
};

const matchSubscription = (subscriptionIdNitroA, subscriptionIdNitroB) => {
    // first of all trying to match complete subscription
    if (subscriptionIdNitroA === subscriptionIdNitroB) {
        return true;
    }

    // if subscription doesn't match, try to compare it with old-new style - same id, but with different IsNitro key
    // old subscriptions that should be matched to new - nitro essential & nitro data
    const mergedOldSubscriptions = [Number(subscription.basicWithUpgrade), Number(subscription.premiumStore)];
    const [subscriptionTypeIdA, subscriptionIsNitroA] = unpackSubscriptionNitro(subscriptionIdNitroA);
    const [subscriptionTypeIdB, subscriptionIsNitroB] = unpackSubscriptionNitro(subscriptionIdNitroB);
    if (mergedOldSubscriptions.includes(subscriptionTypeIdA) || mergedOldSubscriptions.includes(subscriptionTypeIdB)) {
        return subscriptionTypeIdA === subscriptionTypeIdB;
    }
};

export const SubscriptionType = ({ initialValues, formErrors, onChange, isReadOnly }) => {
    const defaultSubscriptionType = packSubsciptionNitro(DEFAULT_SUBSCRIPTION_TYPE, 1);
    const subscriptionNitroTypeList = useMemo(() => subscriptionTypeList.map((s) => ({
        ...s, valueNitro: packSubsciptionNitro(s.value, s.isNitro)
    })), [subscriptionTypeList]);
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const [isSubscriptionChangeConfirmed, setIsSubscriptionChangeConfirmed] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState(
            initialValues.subscriptionType || defaultSubscriptionType
    );

    const [newSubscriptionType, setNewSubscriptionType] = useState(null);

    const { t } = useTranslation();
    const isFieldDisabled = useMemo(() => isFormFieldDisabled(), []);

    useEffect(() => {
        onChange({
            subscriptionType
        });
    }, [subscriptionType]);

    const onConfirmHide = useCallback(() => {
        setIsConfirmPopupShown(false);
        setNewSubscriptionType(null);
    }, [setIsConfirmPopupShown, newSubscriptionType, setNewSubscriptionType]);

    const onConfirmProceed = useCallback(() => {
        setSubscriptionType(newSubscriptionType);
        setNewSubscriptionType(null);
        setIsConfirmPopupShown(false);
        setIsSubscriptionChangeConfirmed(true);
    }, [setIsConfirmPopupShown, newSubscriptionType, setNewSubscriptionType, setSubscriptionType, setIsSubscriptionChangeConfirmed]);

    const updateSubscription = useCallback((value) => {
        setNewSubscriptionType(value);
        if (isNitroChange(value, initialValues.subscriptionType) && !isSubscriptionChangeConfirmed) {
            setIsConfirmPopupShown(true);
        } else {
            setSubscriptionType(value);
        }
    }, [setSubscriptionType, setNewSubscriptionType, isSubscriptionChangeConfirmed]);

    const selectedSubscriptionText = useMemo(()=> {
        const selectedSubscription =
            subscriptionNitroTypeList.find( (type) => matchSubscription(subscriptionType, type.valueNitro)) || null;
        return selectedSubscription ? selectedSubscription.label : '-'
    }, [subscriptionType, subscriptionNitroTypeList]);

    return (
        <div className="hme-account-form-section hme-account-subscription-type">
            <Label>{t('account__section__form--subscription-type')}</Label>
            { isReadOnly ?
                <div>
                    <span>{selectedSubscriptionText}</span>
                </div>
                :
                subscriptionNitroTypeList.map(({ valueNitro, label, tKey }) => (
                    <div className="hme-account-form-box" key={valueNitro}>
                        <Radio
                            label={label}
                            checked={matchSubscription(subscriptionType, valueNitro)}
                            disabled={isFieldDisabled}
                            onChange={() => updateSubscription(valueNitro)}
                            name={label}
                            value={valueNitro}
                            id={label.split(' ').join('')}
                        />
                        {tKey ?
                        <div className={classNames('hme-account-form-description', { 'disabled': isFieldDisabled })}>{t(tKey)}</div>
                        : null
                        }
                    </div>
                ))}
            {formErrors.subscriptionType && <ErrorLabel>{t(formErrors.subscriptionType)}</ErrorLabel>}
            <ConfirmPopupComponent
                show={isConfirmPopupShown}
                message={<>
                    <div className="admin-create-apply-device-settings-task-filters-confirm-line">
                        {t('admin__account__section__form--confirm-change-subscription-warning')}
                    </div>
                    <div>{t('admin__account__section__form--confirm-change-subscription-proceed')}</div>
                </>}
                onHide={onConfirmHide}
                onConfirm={onConfirmProceed}
                confirmationVerb={t('common__continue')}
            />
        </div>
    );
};
