import { CommonConstants } from "Constants";

export const formatDisplayData = (data) => {
    if (!data) return {};

    const {
        Company_Type,
        User_UID,
        Device_IsActive,
        Device_EmailAccount,
        Device_MainVersion,
        Device_SerialNumber,
        Device_MacAddress,
        Store_Name,
        Store_Number,
        Store_UID,
    } = data;

    return {
        deviceIsActive: {
            label: 'common__status',
            isActive: Device_IsActive === 1,
            value: Device_IsActive === 1 ? 'common__device__status--online' : 'common__device__status--offline',
        },
        storeName: {
            label: 'common__store__name',
            value: Store_Name || '-'
        },
        storeNumber: {
            label: 'common__store__number--text',
            value: Store_Number,
            link: `${CommonConstants.PublicStoreSettingsPage}?suid=${Store_UID}`
        },
        accountEmail: {
            label: 'common__account__email',
            value: Device_EmailAccount,
            adminLink: `${CommonConstants.AdminEditAccountPage}?uuid=${User_UID}&type=${Company_Type}`
        },
        deviceMainVersion: {
            label: 'common__system__version',
            value: Device_MainVersion || '-'
        },
        deviceSerialNumber: {
            label: 'common__device__serial-number--text',
            value: Device_SerialNumber || '-'
        },
        deviceMacAddress: {
            label: 'system-status__mac-address',
            value: Device_MacAddress || '-'
        }
    };
};
