export function postData(url, data, onPopupClose) {
  const errors = []
  this.setState({ isPending: true, requestErrors: [] })
  return this.api.postData(url, data,
    result => {
      if (!result || !result.status) {
        const message = result && result.message;
        errors.push(message || 'common__error--internal-server');
        return this.setState({
          requestErrors: errors,
          isValid: false,
          isPending: false })
      }
      this.props.closePopup && this.props.closePopup();
      this.props.showSuccessMessage && this.props.showSuccessMessage();
      onPopupClose && onPopupClose(true);
    }, () => {
      errors.push('common__error--internal-server')
      this.setState({
        requestErrors: errors,
        isValid: false,
        isPending: false })
  })
}
