import React, { Component } from 'react'
import './Stores.css'
import AuthenticationService from '../Security/AuthenticationService'
import { Config } from '../../Config'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,

} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

// This component is used to display the SystemSettings.
class SystemSettings extends Component {
  constructor () {
    super()
    this.authService = new AuthenticationService(Config.authBaseUrl)
  }
  render () {
    const {
      data: {
        systemSettings = [],
      } = {}
    } = this.props
    if (!systemSettings.length) {
      return (
        <div className='system-settings-container'>
        </div>
      )
    }
    return (
      <div className='system-settings-container'>
        <h3 className='system-settings-header'>System Settings</h3>
        <Accordion className='system-settings-accordion' allowZeroExpanded={true}>
          {this.renderAccordion()}
        </Accordion>
      </div>
    )
  }
  // System Settings Accordion.
  renderAccordion () {
    const isAdmin = this.authService.isAdmin()
    if (!this.props.data) { return }
    const {
      data: {
        systemSettings = [],
      } = {},
    } = this.props
    const roleOptions = systemSettings.map(function (value, index) {
      // expanded property will open the initial AccordionItem or AccordionItem[0] on render.
      return (
        <AccordionItem
          key={index}
          expanded={index === 0}
          className={`settings-accordion-item ${isAdmin ? 'adminAccordion' : 'clientAccordion'}`}
        >
          <AccordionItemHeading className='settings-accordion-title'>
           <AccordionItemButton className='settings-accordion-header'>
              {value.name}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className='settings-accordion-content'>
            <Table data={value.value} />
          </AccordionItemPanel>
        </AccordionItem>
      )
    })
    return roleOptions
  }
}

class Table extends Component {
  render () {
    const { data = [] } = this.props
    let rows = data.map((item, index) => {
      return <TableRow
        key={item.index}
        data={item}
      />
    })
    return (
      <table>
        <thead>
          <tr className='settings-table-header'>
            <th>Settings</th>
            <th className='text-start'>Value</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }
}

const TableRow = (props = {}) => {
  const {
    data: {
      SettingInfo_Name: settingInfoName,
      DeviceSetting_SettingValue: settingValue,
    } = {},
  } = props
  return (
    <tr className='system-settings-row-data'>
      <td>
        <strong>{settingInfoName}:</strong>
      </td>
      <td className='text-start'>
        {settingValue}
      </td>
    </tr>
  )
}

export default SystemSettings
