import { useTranslation } from 'react-i18next';

import { DEFAULT_TEXT_LANGUAGE } from 'constants/customerNotifications';

export const useCurrentText = (notification) => {
    const { i18n } = useTranslation();

    const { resolvedLanguage } = i18n;
    const currentLanguage = resolvedLanguage.toLowerCase();

    return notification
    ? currentLanguage in notification.texts
        ? notification.texts[currentLanguage]
        : notification.texts[DEFAULT_TEXT_LANGUAGE]
    : null;
};
