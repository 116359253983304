import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TileList } from 'library/TileList';
import { Paginate } from 'library/Paginate';
import { Grid } from 'components/Common/Grid';
import { format, FORMAT_TYPES } from 'services/Date';
import { HISTORY_GRID_OPTIONS, HISTORY_MOBILE_SORT_OPTIONS, HISTORY_MOBILE_GRID_OPTIONS } from '../constants';
import { useLoadTextConnectMessages } from '../hooks/useLoadTextConnectMessages';
import { MessageSection } from './MessageSection';

import './History.scss';

const INIT_PAGINATION_VALUES = { recordsPerPage: 10, page: 0 };
const INIT_SORT_SELECTION = { createdAt: -1 };
const PAGE_SIZES = [10, 20, 50, 100, -1];

const INIT_PARAMS = {
    page: INIT_PAGINATION_VALUES.page,
    sortColumn: 'createdAt',
    sortingType: 0,
    recordsPerPage: INIT_PAGINATION_VALUES.recordsPerPage,
};

const messagesToRows = ({ id, messageCreatedBy, stores, message, createdAt }) => ({
    id,
    createdBy: messageCreatedBy.email,
    stores: stores.map((store) => store.name).join(', '),
    message,
    tileItemBottomSection: <MessageSection message={message} />,
    createdAt: format(FORMAT_TYPES.DATE_AND_TIME, createdAt),
});

const getSortData = (sortSelection) => {
    const [sortColumn] = Object.keys(sortSelection);
    const sortingType = sortSelection?.[sortColumn] === -1 ? 0 : 1;

    return { sortColumn, sortingType };
};

export const History = () => {
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELECTION);

    const { t } = useTranslation();
    const { messages, total, isTextConnectMessagesLoading, loadTextConnectMessages } =
        useLoadTextConnectMessages(INIT_PARAMS);

    const rows = useMemo(() => messages.map(messagesToRows), [messages]);

    const onPaginationChange = useCallback(
        (newPaginationData) => {
            setPaginationData(newPaginationData);

            const { sortColumn, sortingType } = getSortData(sortSelection);

            loadTextConnectMessages({
                page: newPaginationData.page,
                sortColumn,
                sortingType,
                recordsPerPage: newPaginationData.recordsPerPage,
            });
        },
        [sortSelection, loadTextConnectMessages],
    );

    const onSortChange = useCallback(
        (newSortData) => {
            setSortSelection(newSortData);

            const { sortColumn, sortingType } = getSortData(newSortData);

            loadTextConnectMessages({
                page: paginationData.page,
                sortColumn,
                sortingType,
                recordsPerPage: paginationData.recordsPerPage,
            });
        },
        [paginationData, loadTextConnectMessages],
    );

    return (
        <div className="text-connect__history">
            <div className="text-connect__history__list">
                <Grid
                    rowKey="id"
                    rows={rows}
                    headers={HISTORY_GRID_OPTIONS}
                    noRecordsMessage={t('text-n-connect__history--no-messages-found')}
                    isLoading={isTextConnectMessagesLoading}
                    loadingText={t('common__loading')}
                    onSortChange={onSortChange}
                    sortSelection={sortSelection}
                />
                <TileList
                    rowKey="id"
                    rows={rows}
                    headers={HISTORY_MOBILE_GRID_OPTIONS}
                    isLoading={isTextConnectMessagesLoading}
                    noRecordsMessage={t('text-n-connect__history--no-messages-found')}
                    onSortChange={onSortChange}
                    sortSelection={sortSelection}
                    sortOptions={HISTORY_MOBILE_SORT_OPTIONS}
                    sortButtonName="common__sort"
                />
            </div>
            <Paginate
                total={total}
                page={paginationData.page}
                onChange={onPaginationChange}
                recordsPerPage={paginationData.recordsPerPage}
                pageSizes={PAGE_SIZES}
            />
        </div>
    );
};
