import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { SingleSelect } from 'library/SingleSelect';

import './DeviceTypeForm.scss';

export const DeviceTypeForm = ({ title, number, deviceTypes, onDeviceChange, selectedDeviceType }) => {
    const { t } = useTranslation();

    return (
        <div className="create-snapshot-device-type">
            {title && (
                <Title>
                    {number && `${number}.  `}
                    {t(title)}
                </Title>
            )}
            <SingleSelect
                label={t('settings_snapshots__create_snapshot__form--source-device-type')}
                variants={['label-inside']}
                value={selectedDeviceType}
                onChange={onDeviceChange}
                items={deviceTypes}
            />
        </div>
    );
};
