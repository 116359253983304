import React from 'react';
import { Link } from 'react-router-dom';

import { taskConfig } from 'constants/applyStoreSettingsTask'
import { Button } from 'library/Button';

import './Footer.scss';

const { statuses } = taskConfig;

export const Footer = ({ task, onUpload }) => (
    <div className='hme-view-store-settings-task-footer'>
        <Link className='hme-view-store-settings-task-footer-close' to='/admin/applyStoreSettingsStatus'>
            <Button>Close</Button>
        </Link>
        {
            task && task.Task_Status === statuses.WAITING_FOT_UPLOAD ?
            <Button variants={['submit']} onClick={onUpload}>Upload File</Button> :
            null
        }
    </div>
);
