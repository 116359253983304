import React from 'react';
import { cond, T } from 'ramda';

import { BrandAccountGrid } from './BrandAccountGrid';
import { StoreGrid } from './StoreGrid';
import { filterTypes } from '../../FilterSection';

export const DevicesList = cond([
    [({ searchType }) => searchType === filterTypes.BRAND, (props) => <BrandAccountGrid {...props} />],
    [({ searchType }) => searchType === filterTypes.ACCOUNT, (props) => <BrandAccountGrid {...props} />],
    [({ searchType }) => searchType === filterTypes.STORE, (props) => <StoreGrid {...props} />],
    [T, () => null],
]);
