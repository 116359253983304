import { Constant } from '@hme-cloud/utility-common';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';
import { DeviceDropdown } from '../../DeviceDropdown';
import { checkIsScheduledUpgrade, checkIsUpgradeVersionOptionsDisabled } from '../helpers';

const { BuildVersions } = Constant;

const IB7000Key = NEXEO_PERIPHERAL_TYPES.IB7000.modelName;
const SM7000Key = NEXEO_PERIPHERAL_TYPES.SM7000.modelName;

const formatSpeakerDevices = ({ preparedPeripheralDevices }) => {
    const peripheralDevices = { ...preparedPeripheralDevices };

    // define common minMainVersion
    peripheralDevices[IB7000Key].minMainVersion = BuildVersions.sortVersions([
        peripheralDevices[IB7000Key].minMainVersion,
        peripheralDevices[SM7000Key].minMainVersion,
    ])[0];

    // define common upgrade versions
    const uniqueUpgradeVersions = Array.from(
        new Set([...peripheralDevices[IB7000Key].upgradeVersions, ...peripheralDevices[SM7000Key].upgradeVersions]),
    );
    peripheralDevices[IB7000Key].upgradeVersions = BuildVersions.sortVersions(uniqueUpgradeVersions);

    // define the list of speaker devices
    peripheralDevices[IB7000Key].devices = [
        ...peripheralDevices[IB7000Key].devices,
        ...peripheralDevices[SM7000Key].devices,
    ];

    return peripheralDevices;
};

const formatSpeakerDevicesDropdown = ({
    device,
    peripheralDevices,
    upgradeVersionOptions,
    isScheduledUpgrade,
    currentUpgradeTaskStatus,
    onDeviceVersionUpgrade,
}) => {
    const isUpgradeVersionOptionsDisabled = checkIsUpgradeVersionOptionsDisabled({
        device,
        peripheralDevice: peripheralDevices[IB7000Key],
        upgradeVersionOptions,
        isScheduledUpgrade,
        currentUpgradeTaskStatus,
    });

    return (
        <DeviceDropdown
            onChange={(value) => onDeviceVersionUpgrade(value)}
            placeholder="admin-system-status__action__send-upgrade"
            options={upgradeVersionOptions}
            isDisabled={isUpgradeVersionOptionsDisabled}
        />
    );
};

export const mergeSpeakerDevices = ({
    preparedPeripheralDevices,
    device,
    deviceScheduledUpgrade,
    onDeviceVersionUpgrade,
    t,
}) => {
    // merge only if both devices are present
    if (!preparedPeripheralDevices[IB7000Key] || !preparedPeripheralDevices[SM7000Key]) {
        return preparedPeripheralDevices;
    }

    const peripheralDevices = formatSpeakerDevices({ preparedPeripheralDevices });

    const { modelNameLangKey = '' } = NEXEO_PERIPHERAL_TYPES[IB7000Key];
    const upgradeVersionOptions = (peripheralDevices[IB7000Key].upgradeVersions || []).map((version) => ({
        name: `${t(modelNameLangKey)} v${version}`,
        deviceType: IB7000Key,
        version,
        deviceUID: device.Device_UID,
    }));

    const { currentUpgradeTask } = peripheralDevices[IB7000Key].upgradeTasks || {};

    const currentUpgradeTaskStatus = currentUpgradeTask ? currentUpgradeTask.status : null;

    const isScheduledUpgrade = checkIsScheduledUpgrade({ deviceScheduledUpgrade, currentUpgradeTaskStatus });

    peripheralDevices[IB7000Key].actionsComponent = formatSpeakerDevicesDropdown({
        device,
        peripheralDevices,
        upgradeVersionOptions,
        isScheduledUpgrade,
        currentUpgradeTaskStatus,
        onDeviceVersionUpgrade,
    });

    const { [SM7000Key]: _, ...mergedPeripheralDevices } = peripheralDevices;

    return mergedPeripheralDevices;
};
