import { useState, useEffect, useCallback } from 'react';
import { getServiceAccounts } from 'services/DataFeed';

export const useLoadFeeds = () => {
    const [feeds, setFeeds] = useState([]);
    const [feedsTotal, setFeedsTotal] = useState(0);
    const [isFeedsLoading, setIsFeedsLoading] = useState(false);
    const [feedsLoadingError, setFeedsLoadingError] = useState(null);

    const loadFeeds = useCallback(async () => {
        try {
            setIsFeedsLoading(true);
            setFeedsLoadingError(null);
            const feedList = (await getServiceAccounts()) || [];

            setFeeds(feedList);
            setFeedsTotal && setFeedsTotal(feedList.length)
        } catch (e) {
            setFeeds([]);
            setFeedsLoadingError(e);
        }

        setIsFeedsLoading(false);
    }, []);

    useEffect(() => { loadFeeds(); }, []);

    return { feeds, isFeedsLoading, feedsLoadingError, loadFeeds, feedsTotal };
};
