/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SaveCustomMessage } from './SaveCustomMessage';
import { MessagesLanding } from './MessagesLanding';
import { addNotification, dismissNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import customSmackTalkApi from 'api/customSmackTalkApi';
import { useTranslation } from 'react-i18next';

const EDITOR_ROUTE = '/editor';
const MANAGE_ROUTE = '/manage';

export const ManageMessages = () => {
    const mountedRef = useRef(true);
    const [tab, setTab] = useState();
    const [message, setMessage] = useState();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const deleteSuccessToster = t('smack-talk__tab__manage-messages--message-deleted');

    const isEditor = (path) => path === `${MANAGE_ROUTE}${EDITOR_ROUTE}`;

    const getMessagesData = async () => {
        setLoader(true);
        const result  = await customSmackTalkApi.getData() || {};
        if (!mountedRef.current) return null;
        setData(result);
        setLoader(false);
    };

    const onCancel = async () => {
        if (isEditor(tab)) {
            await getMessagesData();
        }
        navigate(location.pathname.split(EDITOR_ROUTE)[0], { replace: true });
    };

    const notify = () => {
        const successMessage = location.state && location.state.successMessage;
        if (successMessage) {
            dismissNotification();
            addNotification({
                message: successMessage,
                type: NOTIFICATION_TYPES.SUCCESS
            });
            navigate(location.pathname, { state: { ...(location.state || {}), successMessage: '' }, replace: true });
        }
    };

    useEffect(() => {
        const tab = `${MANAGE_ROUTE}${location.pathname.split(MANAGE_ROUTE)[1]}`;
        setTab(tab);
        if (isEditor(tab)) {
            const state = location.state && JSON.parse(location.state);
            if (state) {
                setMessage(state.message);
            }
        }
        notify();
        if (!data.length) {
            getMessagesData();
        }
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const onCreateMessage = (m) => {
        setMessage(m);
        const { actions: _, ...message } = m || {};
        // eslint-disable-next-line no-void
        const state = message && message.messageId ? { message } : void(0);
        navigate(`${location.pathname}${EDITOR_ROUTE}`, { state: JSON.stringify(state) });
    };

    const onSuccess = async (successMessage) => {
        if (isEditor(tab)) {
            await getMessagesData();
        }
        // eslint-disable-next-line no-void
        const state = successMessage ? { successMessage } : void(0);
        navigate(location.pathname.split(EDITOR_ROUTE)[0], { state, replace: true });
    };

    const onDeleteMessage = async (message) => {
        try {
            setLoader(true);
            await customSmackTalkApi.deleteCustom({
                MessageID: message.messageId
            });
            onSuccess(deleteSuccessToster);
        } catch (e) {
            console.error(e);
            await getMessagesData();
        } finally {
            navigate(0, { replace: true });
        }
    };

    return (
        isEditor(tab) ?
        <SaveCustomMessage
            message={message}
            onCancel={onCancel}
            onSuccess={onSuccess}
        /> :
        <MessagesLanding
            data={data}
            loader={loader}
            onCreateMessage={onCreateMessage}
            onDeleteMessage={onDeleteMessage}
        />
    );
};
