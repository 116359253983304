import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Api from '../../Api';
import { Chart } from 'react-google-charts';
import { CommonConstants } from '../../Constants';
import { translationKeysMap } from './translationsMap';
const goal = require('../../images/ldash_goal.png');

/** */
class LaneTotalAvgTime extends Component {
    /**
   * @param {*} props
   */
    constructor(props) {
        super(props);
        this.state = {
            showStores: this.props.showStores,
            Ascending: true,
            criteria: null,
            pageSize: 10,
            offset: 0,
            data: [],
            recordCount: 5000,
            options: {
                isStacked: true,
                legend: 'none',
                focusTarget: 'category',
                tooltip: { isHtml: true },
                seriesType: 'bars',
                series: { 0: { type: 'line', color: '#000000' } },
                colors: this.props.goalColors,
                width: 900,
                height: 370,
                chartArea: {
                    left: 70,
                    top: 30,
                    width: 678
                },
                fontSize: 12,
                bar: { groupWidth: '75%' },
                annotations: {
                    style: 'default',
                    textStyle: {
                        fontSize: 12,
                        bold: true,
                        color: 'white', // The color of the text.
                        auraColor: 'none', // The color of the text outline.
                        opacity: 1 // The transparency of the text.
                    },
                    boxStyle: {
                        stroke: '#888', // Color of the box outline.
                        strokeWidth: 1, // Thickness of the box outline.
                        rx: 5, // x-radius of the corner curvature.
                        ry: 5, // y-radius of the corner curvature.
                        gradient: { // Attributes for linear gradient fill.
                            color1: '#7fce00', // Start color for gradient.
                            color2: '#7fce00', // Finish color for gradient.
                            x1: '0%',
                            y1: '0%', // Where on the boundary to start and end the
                            x2: '100%',
                            y2: '100%', // color1/color2 gradient, relative to the
                            useObjectBoundingBoxUnits: false // If true, the boundary for x1, y1,
                        }
                    }
                },
                vAxis: { title: props.t('dashboard__avg-time-title'), titleTextStyle: { bold: false, italic: false, color: '#a6a6a6' } },
                hAxis: { title: '', titleTextStyle: { bold: false, italic: false, color: '#a6a6a6' } }
            }
        };
        this.api = new Api();
        this.state.rows = this.props.rows;
        this.state.columns = this.props.columns;
        this.state.colors = this.props.goalColors;
        this.state.events = this.props.events;
    }

    /**
     *
     * @return {JSX}
     */
    renderLegends() {
        const { t } = this.props;
        const events = this.state.events;

        if (events) {
            const legends = events.map((event, index) => {
                const color = CommonConstants.LaneTotalAvgColorsImages[index + 1];
                const imgUrl = require('../../images/' + color + '.png');
                if (imgUrl) {
                    return (
                        <div className="blabel">
                            <span><img src={imgUrl} alt="legends" /></span>
                            <span className="dash_goal_pad"> {t(translationKeysMap[event] || event)} </span>
                        </div>
                    );
                } else {
                    return (<div />);
                }
            });
            return legends;
        } else {
            return null;
        }
    }
    /**
     * @return {JSX}
     */
    render() {
        const { t } = this.props;

        return (
            <div className="chartSection">
                <Chart
                    chartType="ColumnChart"
                    data={[this.props.columns, ...this.props.rows]}
                    options={{
                        ...this.state.options,
                        vAxis: {
                            ...this.state.options.vAxis,
                            title: t('dashboard__avg-time-title')
                        }
                    }}
                    graph_id="LaneTotalAvgTime"
                    width={'900px'}
                    height={'370px'}
                    legend_toggle={false}
                />
                <h6 className="dp_label" translate="" key="daypart">{t('common__daypart')}</h6>
                <div className="cbar clear">
                    <div className="cmiddle">
                        {this.renderLegends()}
                        <div className="blabel">
                            <span><img src={goal} alt="goalIcon" /></span>
                            <span className="dash_goal_pad">{t('common__goal')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * @param {*} state
 * @return {*}
 */
function mapStateToProps(state) {
    return {
        systems: state.systems
    };
}
/**
 * @param {*} dispatch
 * @return {*}
 */
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
            }, dispatch
    );
}

const LaneTotalAvgTimeWithTranslation = compose(
        withTranslation()
)(LaneTotalAvgTime);

export default connect(mapStateToProps, matchDispatchToProps)(LaneTotalAvgTimeWithTranslation);
