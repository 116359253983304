import * as dashboard from '../../actionTypes/Dashboard/Dashboard'

const initialState = {
  'currentDayData': {
    'CurrentEndDate': null,
    'CurrentStartDate': null
  },
  'sortParams': {
    period: '',
    sortBy: '',
    sortType: ''
  },
  'dashboard': [
    {
      title: '',
      columns: [],
      rows: []
    }
  ],
  'userPreferences': [],
  'reportResponseType': null,
  'realTime': false,
  'state': true,
  'key': ''
}

export default function dashboardDetails (state = initialState, action) {
  switch (action.type) {
    case dashboard.GET_DASHBOARDDATA:
      state = action.dashboardData
      // state.userPreferences = action.dashboardData.userPreferences
      // state.reportResponseType = action.dashboardData.reportResponseType
      // state.currentDayData.CurrentEndDate = action.dashboardData.currentDayData === undefined ? '' :  action.dashboardData.currentDayData.CurrentEndDate
      // state.currentDayData.CurrentStartDate = action.dashboardData.currentDayData === undefined ? '' : action.dashboardData.currentDayData.CurrentStartDate
      // state.realTime = action.dashboardData.realTime
      // state.key = action.dashboardData.key
      // state.sortParams = (action.dashboardData.sortParams === undefined ? {} : action.dashboardData.sortParams)
      return {
        ...state
      }
    case dashboard.SET_SORTPARAMS:
      state.sortParams = action.sortParams
      // state.sortParams.period = action.sortParams.period
      // state.sortParams.sortBy = action.sortParams.sortBy
      // state.sortParams.sortType = action.sortParams.sortType
      return {
        ...state
      }
    default:
      return state
  }
}
