import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../Loader';
import { SINGLE_SELECT_VARIANTS, SingleSelect } from '../SingleSelect';
import { ErrorLabel } from 'library/ErrorLabel';
import { useTranslation } from 'react-i18next';

const DEFAULT_SELECT_VARIANTS = [SINGLE_SELECT_VARIANTS.LABEL_INSIDE];

export const TimeZoneSelect = ({
    isLoading,
    timeZones,
    currentTimeZone,
    onChange,
    variants = DEFAULT_SELECT_VARIANTS,
    isReadOnly = false,
    isRequired = false,
    error
}) => {
    const { t } = useTranslation();
    if (isLoading) {
        return <Loader/>;
    }

    return (
        <div>
            <SingleSelect
                label={t('common__timezone')}
                value={currentTimeZone}
                items={timeZones}
                onChange={onChange}
                variants={variants}
                className="hme-timezone-select"
                isRequired={isRequired}
                isReadOnly={isReadOnly}
            />
            {error && <ErrorLabel>{error}</ErrorLabel>}
        </div>
    );
};

const itemProp = PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
});

TimeZoneSelect.propTypes = {
    isLoading: PropTypes.bool,
    currentTimeZone: PropTypes.string.isRequired,
    timeZones: PropTypes.arrayOf(itemProp),
    onChange: PropTypes.func,
    variants: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
    isRequired: PropTypes.bool
};
