import React from 'react';

import { DeviceStatus } from 'library/DeviceStatus';
import getDeviceType from 'helpers/Device/getDeviceType';

export const StoreStatuses = ({ devices }) => (
    <>
        {
            devices.map((device) => {
                const { displayName } = getDeviceType(device);

                return (
                    <div className='hme-stores-status--public__grid__inner-cell' key={device.Device_UID}>
                        <DeviceStatus
                            online={device.Device_IsActive}
                            isPreconfigured={device.Device_IsPreconfigured}
                            name={displayName}
                            version={device.Device_MainVersion}
                            deviceUid={device.Device_UID}
                        />
                    </div>
                );
            })
        }
    </>
);
