import * as dashboard from '../actionTypes/Dashboard/Dashboard'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import AuthenticationService from '../components/Security/AuthenticationService'

function getTimeMeasureData (dashboardData) {
  return {
    type: dashboard.GET_DASHBOARDDATA,
    dashboardData: dashboardData
  }
}

function setSortParamsData (sortParams) {
  return {
    type: dashboard.SET_SORTPARAMS,
    sortParams: sortParams
  }
}

export const getTimeMeasure = (timeMeasureType, eventType, onInitLoad, sortObj, callback) => {
  const authService = new AuthenticationService(Config.authBaseUrl)
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getDashboard + '?isAdmin=' + (authService.isAdmin() ? '1' : '0') + '&reportType=' + timeMeasureType + '&eventType=' + eventType + '&hidePreconfiguredDevices=1'
  url = authService.getUUID() ? url + '&uuid=' + authService.getUUID() : url
  url = sortObj ? url + '&sortBy=' + sortObj.sortBy + '&sortType=' + sortObj.sortType + '&period=' + sortObj.period : url
  return (dispatch, getState) => {
    const api = new Api()
    api.getData(url, data => {
      dispatch(getTimeMeasureData(data))
      if (callback !== undefined) callback(data)
    }, error => {
      console.log('Error from dashboard action', error)
    })
  }
}

export const setSortParams = (sortObj) => {
  return (dispatch, getState) => {
    dispatch(setSortParamsData(sortObj))
  }
}
