export const getMasqueradeWarnings = ({ isActive, isVerified, isTOSAccepted }) => {
    const warnings = [];

    if (!isActive || !isVerified || !isTOSAccepted) {
        warnings.push('add-user__warning--masquerade-unavailable');
    }

    if (!isActive) {
        warnings.push('add-user__warning--inactive');
    }
    if (!isVerified) {
        warnings.push('add-user__warning--email-not-verified');
    }
    if (!isTOSAccepted) {
        warnings.push('add-user__warning--terms-not-accepted');
    }

    return warnings;
};
