const emptyValues = ['', undefined, null];

// compare ISO date strings for grid columns sorting
export default (a, b, order = 1) => {
    if (emptyValues.some((v) => v === a)) return 1;
    if (emptyValues.some((v) => v === b)) return -1;
    if (a === b) return 0;

    return a < b ? -order : order;
};
