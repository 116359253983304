import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

export const sortByVersion = (rows, direction) => (
    rows.map((row) => ({
        ...row,
        Device_Details: row.Device_Details
            .map((device) => ({
                ...device,
                normalizedVersion: BuildVersions.normalizeVersionString(device.Device_MainVersion),
            }))
            .sort((a, b) => {
                const firstValue = a.normalizedVersion;
                const secondValue = b.normalizedVersion;
                

                if (BuildVersions.sameVersion(firstValue, secondValue)) {
                    return 0;
                }

                return BuildVersions.semverLt(firstValue, secondValue) ? direction : -direction;
            }),
    }))
    .sort((a, b) => {
        const firstValue = a.Device_Details.length ? a.Device_Details[0].normalizedVersion : '0.0.0';
        const secondValue = b.Device_Details.length ? b.Device_Details[0].normalizedVersion : '0.0.0';

        if (BuildVersions.sameVersion(firstValue, secondValue)) {
            return 0;
        }

        return BuildVersions.semverLt(firstValue, secondValue) ? direction : -direction;
    })
);
