import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { SearchInput } from 'components/Inputs';

import './Header.scss';

export const Header = ({ accountSearchValue, onAccountSearch, isAccountSearchDisabled, onAccountLink }) => {
    const { t } = useTranslation();

    return (
        <div className="dxs-feed-header">
            <div className="dxs-feed-header-title-wrapper">
                <Title>{t('dxs-feeds__feed__header__title')}</Title>
            </div>
            <div className="dxs-feed-header-actions-wrapper">
                <SearchInput
                    value={accountSearchValue}
                    onChange={onAccountSearch}
                    showClearIcon
                    isDisabled={isAccountSearchDisabled}
                    placeholder={t('common__account__email')}
                />
                <div className="dxs-feed-header-link">
                    <Button onClick={onAccountLink}>{t('dxs-feeds__header__add-new-account-email')}</Button>
                </div>
            </div>
        </div>
    );
};
