import { fetchProvidersLinkedToPartner } from 'services/TokenManagement';

export const getProvidersLinkedToPartner = async ({ partnerUID }, requestOptions = {}) => {
    const { data, status } = await fetchProvidersLinkedToPartner({ partnerUID }, requestOptions);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data.providers;
};
