import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PARTNER_ROUTES } from 'constants/routes';
import { ASYNC_STATUS, useAsync } from 'hooks/useAsync';
import { addErrorNotification } from 'services/Notifications';
import { isPartner } from 'services/Auth'
import { VAIO_DEFAULTS } from 'pages/StoreFeatures/VaioProviderForm';

import { editVaioProvider, fetchProviderByUID } from '../../../../Controller';

export const useProviderDetails = () => {
    const { puid } = useParams();
    const navigate = useNavigate();
    const { run: runVaioProviderLoader, isLoading: isVaioProviderLoading, data: vaioProvider } = useAsync();
    const { run: runVaioProviderUpdate, status: updateStatus } = useAsync();

    const [vaioProviderData, setVaioProviderData] = useState(null);
    const [isConfirmationPopupShown, setIsConfirmationPopupShown] = useState(false);

    const closeConfirmationPopup = useCallback(() => setIsConfirmationPopupShown(false), []);

    const cancelProviderEditiing = useCallback(() => navigate(`/${PARTNER_ROUTES.settingsFull}`), [navigate]);

    const handleVaioProviderPreUpdate = useCallback((newValues) => {
        setIsConfirmationPopupShown(true);
        setVaioProviderData(newValues);
    }, []);

    const handleVaioProviderUpdate = useCallback(async () => {
        closeConfirmationPopup();
        await runVaioProviderUpdate(editVaioProvider(vaioProviderData));
        navigate(`/${PARTNER_ROUTES.settingsFull}`, {relative: 'path'});
    }, [runVaioProviderUpdate, vaioProviderData, closeConfirmationPopup]);

    useEffect(() => {
        runVaioProviderLoader(fetchProviderByUID(puid).then((vaioProvider) => {
            if (isPartner()) {
                vaioProvider.vaioProviderChangeHistory = vaioProvider.vaioProviderChangeHistory.slice(0, 5);
            }

            return {
                ...vaioProvider,
                httpsPort: vaioProvider.httpsPort || VAIO_DEFAULTS.httpsPort,
                wssPort: vaioProvider.wssPort || VAIO_DEFAULTS.wssPort
            };
        }).catch(() => {
            cancelProviderEditiing();
            setTimeout(() => {
                addErrorNotification('common__error--internal-server');
            }, 1000);
        }));
    }, [runVaioProviderLoader, puid, cancelProviderEditiing]);

    const isUpateInProgress = updateStatus === ASYNC_STATUS.PENDING;

    return {
        isVaioProviderLoading,
        vaioProvider,
        handleVaioProviderPreUpdate,
        cancelProviderEditiing,
        isConfirmationPopupShown,
        closeConfirmationPopup,
        handleVaioProviderUpdate,
        isUpateInProgress
    };
};
