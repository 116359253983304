import React from 'react';
import { useTranslation } from 'react-i18next';

import './MessageSection.scss';

export const MessageSection = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className="text-connect__history__message">
            <span className="text-connect__history__message__label">
                {t('text-n-connect__history__grid-header--message')}
            </span>
            <span className="text-connect__history__message__value">{message}</span>
        </div>
    );
};
