import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';

import { Toggle } from 'library/Toggle';
import { Section } from 'library/Section';
import { ZoomForm } from './ZoomForm';
import { ZoomNitroForm } from './ZoomNitroForm';
import { NexeoForm } from './NexeoForm';

import './SourceSection.scss';

export const deviceTypes = {
    ZOOM: 'zoom',
    ZOOM_NITRO: 'zoomNitro',
    NEXEO: 'nexeo',
};

const isType = type => ({ deviceType }) => type === deviceType;
const isZoomForm = isType(deviceTypes.ZOOM);
const isZoomNitroForm = isType(deviceTypes.ZOOM_NITRO);
const isNexeoForm = isType(deviceTypes.NEXEO);

const DeviceForm = cond([
    [isZoomForm, ZoomForm],
    [isZoomNitroForm, ZoomNitroForm],
    [isNexeoForm, NexeoForm],
    [T, () => null]
]);

const snapshotsDefault = {};

export const SourceSection = ({
    sectionNumber,
    col,
    disabled = false,
    params,
    onParamsChange,
    isSnapshotsLoading,
    snapshots = snapshotsDefault,
}) => {
    const { t } = useTranslation();
    const onSourceTypeChangeHandler = useCallback(selectedDeviceType => {
        onParamsChange({
            ...params,
            selectedDeviceType,
            sourceDevice: null,
            sourceSettingsList: null,
            settingTemplateID: '',
        });
    }, [params, onParamsChange]);

    return (
        <Section
            className='admin-create-apply-device-settings-task-source'
            number={sectionNumber}
            col={col}
            title={t('device-upgrades__section__title--select-source-device')}
            disabled={disabled}
        >
            <Toggle
                className='source-type-toggle'
                value={params.selectedDeviceType}
                label={t('common__device-type')}
                onChange={onSourceTypeChangeHandler}
            >
                {[{
                    text: t('common__device__type--zoom-nitro'),
                    value: deviceTypes.ZOOM_NITRO,
                }, {
                    text: t('common__device__type--zoom'),
                    value: deviceTypes.ZOOM,
                }, {
                    text: t('common__device__type--nexeo'),
                    value: deviceTypes.NEXEO,
                }]}
            </Toggle>
            <DeviceForm
                deviceType={params.selectedDeviceType}
                params={params}
                onParamsChange={onParamsChange}
                snapshots={snapshots}
                isSnapshotsLoading={isSnapshotsLoading}
            />
        </Section>
    );
};
