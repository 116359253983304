export const METRIC_NAMES = {
    Cars: 'common__cars',
    Avg: 'contest__settings__metric-avg-time',
    'Goal%': 'contest__result__goal-percent-met',
    DEFAULT: 'common__cars',
};

export const getMetricText = (metric) => {
    return metric ?
        METRIC_NAMES[metric] || METRIC_NAMES.DEFAULT :
        METRIC_NAMES.DEFAULT;
};
