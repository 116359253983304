import React from 'react';
import { Section } from 'library/Section';
import { UpgradeInfoStatus } from './UpgradeInfoStatus';
import { UpgradeInfoForm } from '../../UpgradeInfoForm';
import { upgradeStatuses } from '../../constants/upgradeStatuses';
import { UpgradeInfoDetails } from './UpgradeInfoDetails';
import { Footer } from './Footer';

import './UpgradeInfoSection.css';

export const UpgradeInfoSection = ({
    formErrors,
    deviceVersions,
    upgradeVersions,
    onUpgradeInfoChange,
    sectionNumber,
    scheduledUpgrade,
    upgradeStatus,
    isSubmitEnabled,
    onCancelUpgrade,
    onApplyUpgrade,
}) => {
    const isUpgradeFinished = [upgradeStatuses.COMPLETED, upgradeStatuses.FAILED, upgradeStatuses.CANCELLED].includes(upgradeStatus);
    const shouldFooterBeShown = [upgradeStatuses.SCHEDULED, upgradeStatuses.PENDING_APPROVAL].includes(upgradeStatus);

    return (
        <Section number={sectionNumber} className="view-device-upgrade-info">
            <UpgradeInfoStatus scheduledUpgrade={scheduledUpgrade} upgradeStatus={upgradeStatus} />
            {isUpgradeFinished ? (
                <UpgradeInfoDetails scheduledUpgrade={scheduledUpgrade} onChange={() => {}} />
            ) : (
                <>
                    <UpgradeInfoForm
                        scheduledUpgrade={scheduledUpgrade}
                        upgradeStatus={upgradeStatus}
                        formErrors={formErrors}
                        deviceVersions={deviceVersions}
                        upgradeVersions={upgradeVersions}
                        onChange={onUpgradeInfoChange}
                    />
                    {shouldFooterBeShown && (
                        <Footer isSubmitEnabled={isSubmitEnabled} onCancel={onCancelUpgrade} onApply={onApplyUpgrade} />
                    )}
                </>
            )}
        </Section>
    );
};
