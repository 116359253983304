import React, { useEffect, useState } from 'react';

import { Label } from 'library/Label';

import { LanguageSelect } from '../../Common/LanguageSelect';
import { Editor } from '../../Common/Editor';

export const NewLanguageEditor = ({
    currentLanguage,
    languages,
    isSelectShown,
    onLanguageChange,
    onLanguageRemove,
    value,
    onEdit
}) => {
    const [languagesToDisplay, setLanguagesToDisplay] = useState(languages);

    useEffect(() => {
        setLanguagesToDisplay([...languages, currentLanguage]);
    }, [languages, currentLanguage]);

    return (
        <>
            {!isSelectShown && (
                <LanguageSelect
                    value={currentLanguage.value}
                    languages={languagesToDisplay}
                    onLanguageChange={onLanguageChange}
                    onLanguageRemove={onLanguageRemove}
                />
            )}

            <Label className="hme-customer-notification-form__language-title">{currentLanguage.text}</Label>

            <Editor state={value} onChange={onEdit} className="hme-customer-notification-form__editor" />
        </>
    );
};
