/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Constant } from '@hme-cloud/utility-common';
import { SingleSelect as Select } from 'library/SingleSelect';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent as Input } from 'components/Inputs';
import { deviceTypes, deviceTypeOptions } from 'constants/device';
import { nexeoRestrictions } from 'constants/upgradeRestrictions';
import { NEXEO_BASE_STATION, NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';
import { DateLib } from '@hme-cloud/utility-common';
import { DeviceVersions } from './DeviceVersions';

import { upgradeStatuses } from '../constants';
import { initUpgradeForm } from '../helpers';
import { checkIsNexeoTwoStepUpgrade } from '../helpers/twoStepUpgrade';
import { UpgradeScheduleDate } from './UpgradeScheduleDate';

const { BuildVersions } = Constant;

import './UpgradeInfoForm.scss';

const MAX_UPGRADE_NAME_SIZE = 30;

const getPeripheralTypesOptions = ({ t }) => ([{
    text: t('admin-system-status__device-model-name--bs7000'),
    value: NEXEO_BASE_STATION.modelName,
}, {
    text: `${t('admin-system-status__device-model-name--ib7000')}/${t('admin-system-status__device-model-name--sm7000')}`,
    value: NEXEO_PERIPHERAL_TYPES.IB7000.modelName,
}]);

export const UpgradeInfoForm = ({
    scheduledUpgrade,
    upgradeStatus,
    deviceVersions = [],
    upgradeVersions = [],
    formErrors = {},
    onChange,
}) => {
    const { t } = useTranslation();
    const {
        ScheduledUpgradeName,
        DeviceTypeSource,
        PeripheralDeviceTypeSource,
        UpgradeFromVersions,
        UpgradeToVersion,
        UpgradeDateTime,
        InstallDateTime,
        InitialMaxTargetDeviceVersion,
    } = useMemo(() => initUpgradeForm(scheduledUpgrade), [scheduledUpgrade]);
    
    const [upgradeName, setUpgradeName] = useState(ScheduledUpgradeName);
    const [targetDeviceType, setTargetDeviceType] = useState(DeviceTypeSource);
    const [targetPeripheralDeviceType, setTargetPeripheralDeviceType] = useState(PeripheralDeviceTypeSource || NEXEO_BASE_STATION.modelName);
    const [upgradeVersion, setUpgradeVersion] = useState(UpgradeToVersion);
    const [installFormErrors, setInstallFormErrors] = useState({});
    const [targetDeviceVersions, setTargetDeviceVersions] = useState(UpgradeFromVersions);
    const [maxTargetDeviceVersion, setMaxTargetDeviceVersion] = useState(InitialMaxTargetDeviceVersion);

    const onTargetDeviceVersionsChange = (versions) => {
        setMaxTargetDeviceVersion(versions.length > 0 ? versions[versions.length - 1] : '');
        setTargetDeviceVersions(versions);
    };

    const onUpgradeVersionChange = (version) => {
        setUpgradeVersion(version);
    };

    const [deviceUpgradeDateTime, setDeviceUpgradeDateTime] = useState({
        deviceUpgradeDate: UpgradeDateTime.UpgradeStartDate,
        upgradeScheduleDays: UpgradeDateTime.DaysofWeek,
        deviceUpgradeTimeFrom: UpgradeDateTime.StartTime,
        deviceUpgradeTimeTo: UpgradeDateTime.EndTime
    });

    const [deviceInstallDateTime, setDeviceInstallDateTime] = useState({
        deviceUpgradeDate: InstallDateTime?.InstallStartDate || '',
        upgradeScheduleDays: InstallDateTime?.DaysofWeek || [],
        deviceUpgradeTimeFrom: InstallDateTime?.StartTime || '',
        deviceUpgradeTimeTo: InstallDateTime?.EndTime || ''
    });

    const peripheralTypesOptions = getPeripheralTypesOptions({ t });

    const isUpgradeScheduled = upgradeStatus === upgradeStatuses.SCHEDULED;
    const isUpgradeFailed = upgradeStatus === upgradeStatuses.FAILED;
    const isUpgradeInProgress = upgradeStatus === upgradeStatuses.IN_PROGRESS;
    const isUpgradeCancelled = upgradeStatus === upgradeStatuses.CANCELLED;

    useEffect(() => {
        if (targetDeviceType) {
            if (!deviceUpgradeDateTime.deviceUpgradeDate) {
                const nextDayDate = new DateLib().addDays(1);
                setDeviceUpgradeDateTime((prevDeviceUpgradeDateTime) => ({
                    ...prevDeviceUpgradeDateTime,
                    deviceUpgradeDate: nextDayDate,
                    upgradeScheduleDays: !(scheduledUpgrade && scheduledUpgrade.DaysofWeek)
                        ? [new DateLib(nextDayDate).dayOfWeek]
                        : prevDeviceUpgradeDateTime.upgradeScheduleDays,
                }));
            }

            // upgrade status exists for view/edit page only and should not reset the form
            if (!upgradeStatus) {
                resetInstallScheduleDate();
                setTargetDeviceVersions([]);
                setUpgradeVersion('');
            }
        }
    }, [targetDeviceType, targetPeripheralDeviceType]);

    useEffect(() => {
        if (checkIsNexeoTwoStepUpgrade({
            deviceType: targetDeviceType,
            deviceVersion: maxTargetDeviceVersion,
            peripheralDeviceType: targetPeripheralDeviceType,
        })) {
            const installFormErrors = {};
            if (formErrors.installRunDate) {
                installFormErrors.runDate = formErrors.installRunDate;
            }

            if (formErrors.installScheduleDays) {
                installFormErrors.scheduleDays = formErrors.installScheduleDays;
            }

            setInstallFormErrors(installFormErrors)
        }
    }, [formErrors, targetDeviceType, targetPeripheralDeviceType, maxTargetDeviceVersion]);

    useEffect(() => {
        onChange &&
            onChange({
                upgradeName,
                targetDeviceType,
                targetPeripheralDeviceType,
                targetDeviceVersions,
                maxTargetDeviceVersion,
                upgradeVersion,
                deviceUpgradeDate: deviceUpgradeDateTime.deviceUpgradeDate ? new DateLib(deviceUpgradeDateTime.deviceUpgradeDate).format(DateLib.FORMAT_TYPES.YEAR_MONTH_DATE) : '',
                upgradeScheduleDays: deviceUpgradeDateTime.upgradeScheduleDays.join(','),
                deviceUpgradeTimeFrom: deviceUpgradeDateTime.deviceUpgradeTimeFrom,
                deviceUpgradeTimeTo: deviceUpgradeDateTime.deviceUpgradeTimeTo,
                deviceInstallDate: deviceInstallDateTime.deviceUpgradeDate ? new DateLib(deviceInstallDateTime.deviceUpgradeDate).format(DateLib.FORMAT_TYPES.YEAR_MONTH_DATE) : '',
                installScheduleDays: deviceInstallDateTime.upgradeScheduleDays.join(','),
                deviceInstallTimeFrom: deviceInstallDateTime.deviceUpgradeTimeFrom,
                deviceInstallTimeTo: deviceInstallDateTime.deviceUpgradeTimeTo,
            });
    }, [
        upgradeName,
        targetDeviceType,
        targetPeripheralDeviceType,
        targetDeviceVersions,
        upgradeVersion,
        deviceUpgradeDateTime,
        deviceInstallDateTime
    ]);

    // show install schedule date section only for Nexeo devices with version >= 3.0.0
    const isInstallScheduleDateVisible =
        maxTargetDeviceVersion &&
        targetDeviceType === deviceTypes.NEXEO &&
        targetPeripheralDeviceType === NEXEO_BASE_STATION.modelName &&
        BuildVersions.semverGt(maxTargetDeviceVersion, nexeoRestrictions.minTwoStepUpgradeVersion);

    const resetInstallScheduleDate = useCallback(() => {
        if (!isInstallScheduleDateVisible) {
            setDeviceInstallDateTime(prevDeviceInstallDateTime => ({
                ...prevDeviceInstallDateTime,
                deviceUpgradeDate: null,
                upgradeScheduleDays: [],
            }));
        }
    }, [InstallDateTime, isInstallScheduleDateVisible]);

    const onUpgradeNameChange = useCallback((value) => {
        setUpgradeName(value.trimStart());
    }, []);

    const minUpgradeScheduleDate = useMemo(() => {
        if (!maxTargetDeviceVersion) {
            return new DateLib().addDays(1);
        }

        return targetDeviceType === deviceTypes.NEXEO &&
            targetPeripheralDeviceType !== NEXEO_PERIPHERAL_TYPES.IB7000.modelName &&
            BuildVersions.semverGt(maxTargetDeviceVersion, nexeoRestrictions.minTwoStepUpgradeVersion)
            ? new DateLib()
            : new DateLib().addDays(1);
    }, [maxTargetDeviceVersion, targetDeviceType, targetPeripheralDeviceType]);

    return (
        <div className="hme-create-device-upgrade-form">
            <div
                className={classNames([
                    'hme-create-device-upgrade-form-section',
                    'hme-input-text-with-remaining',
                    formErrors.name ? 'hme-validation-error' : '',
                ])}
            >
                <Input
                    variants={['label-inside']}
                    label={t('device-upgrades__section__form--upgrade-name')}
                    value={upgradeName}
                    onChange={onUpgradeNameChange}
                    isRequired
                    maxLength={MAX_UPGRADE_NAME_SIZE}
                    showRemaining
                    isDisabled={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                />
                {formErrors.name && <ErrorLabel>{t(formErrors.name)}</ErrorLabel>}
            </div>
            <div
                className={classNames([
                    'hme-create-device-upgrade-form-section',
                    formErrors.deviceType ? 'hme-validation-error' : '',
                ])}
            >
                <Select
                    isDisabled={isUpgradeScheduled || isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    variants={['label-inside']}
                    isRequired
                    label={t('device-upgrades__section__form--target-device-type')}
                    placeholder={t('device-upgrades__section__form--select-option')}
                    value={targetDeviceType}
                    onChange={setTargetDeviceType}
                    items={deviceTypeOptions}
                    withFilter={false}
                />
                {formErrors.deviceType && <ErrorLabel>{t(formErrors.deviceType)}</ErrorLabel>}
            </div>
            {targetDeviceType == deviceTypes.NEXEO && (<div
                className={classNames([
                    'hme-create-device-upgrade-form-section',
                    formErrors.peripheralDeviceType ? 'hme-validation-error' : '',
                ])}
            >
                <Select
                    isDisabled={isUpgradeScheduled || isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    variants={['label-inside']}
                    isRequired
                    label="Component"
                    placeholder={t('device-upgrades__section__form--select-option')}
                    value={targetPeripheralDeviceType}
                    onChange={setTargetPeripheralDeviceType}
                    items={peripheralTypesOptions}
                    className={'upgrade-peripheral-device-select'}
                    withFilter={false}
                />
            </div>)}

            <DeviceVersions
                allDeviceVersions={deviceVersions}
                targetDeviceVersions={targetDeviceVersions}
                upgradeVersion={upgradeVersion}
                initialValues={{
                    targetDeviceVersions: UpgradeFromVersions,
                    upgradeVersion: UpgradeToVersion,
                
                }}
                targetDeviceType={targetDeviceType}
                targetPeripheralDeviceType={targetPeripheralDeviceType}
                isDisabled={isUpgradeScheduled || isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                onTargetDeviceVersionsChange={onTargetDeviceVersionsChange}
                onUpgradeVersionChange={onUpgradeVersionChange}
                allUpgradeVersions={upgradeVersions}
            />

            {/* download date time */}
            <UpgradeScheduleDate
                title="device-upgrades__section__form--download-window"
                scheduledUpgrade={scheduledUpgrade}
                upgradeStatus={upgradeStatus}
                formErrors={formErrors}
                deviceUpgradeDateTime={deviceUpgradeDateTime}
                onDeviceUpgradeDateTimeChange={setDeviceUpgradeDateTime}
                minDate={minUpgradeScheduleDate}
                maxDate={deviceInstallDateTime.deviceUpgradeDate}
            />

            {/* install date time */}
            {isInstallScheduleDateVisible && (
                <UpgradeScheduleDate
                    title="device-upgrades__section__form--install-window"
                    scheduledUpgrade={scheduledUpgrade}
                    upgradeStatus={upgradeStatus}
                    formErrors={installFormErrors}
                    deviceUpgradeDateTime={deviceInstallDateTime}
                    onDeviceUpgradeDateTimeChange={setDeviceInstallDateTime}
                    minDate={deviceUpgradeDateTime.deviceUpgradeDate}
                />
            )}
        </div>
    );
};
