import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { MenuIcon } from '../MenuIcon';

export const MoreItem = ({ id, url, isActive, icon, label, additionalClasses }) => {
    const { t } = useTranslation();

    return (
        <Dropdown.Item href={url} className={classNames('headerMenu', additionalClasses, { active_tab: isActive })} id={id}>
            <MenuIcon icon={icon} />
            {t(label)}
        </Dropdown.Item>
    );
};
