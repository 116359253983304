/* eslint-disable complexity */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import './SectionsSection.scss';
import { LeaderBoardSortableSections } from 'components/LeaderBoard/LeaderBoardSortableSections';
import { Label } from 'library/Label';
import { Button } from 'library/Button';
import _ from 'underscore';
import { Tip } from 'library/Tip';

const columnTypes = ['Cars', 'Avg', 'Goal%'];
const tableIds = {
    'Stores': 't0',
    'Current Hour': 't1',
    'Previous Hour': 't2',
    'Current Day': 't3',
    'Previous Day': 't4',
    'Current Daypart': 't5',
    'Previous Daypart': 't6',
    'Current Rolling Hour': 't7',
    'Previous Rolling Hour': 't8'
};

const defaultEmptyTableDropDown = [
    { name: 'Current Hour', visible: true, id: 't1' },
    { name: 'Previous Hour', visible: true, id: 't2' },
    { name: 'Current Day', visible: true, id: 't3' },
    { name: 'Previous Day', visible: true, id: 't4' },
    { name: 'Current Daypart', visible: true, id: 't5' },
    { name: 'Previous Daypart', visible: true, id: 't6' },
    { name: 'Current Rolling Hour', visible: true, id: 't7' },
    { name: 'Previous Rolling Hour', visible: true, id: 't8' }
];
const defaultFirstTable = {
    id: 't0',
    disabled: true,
    header: 'Stores',
    columns: [
        {
            header: 'Rank',
            data: [1, 4, 8]
        },
        {
            header: 'Store',
            data: [44165, 55171, 66188]
        },
        {
            header: 'Goal',
            data: [210, 210, 210]
        }
    ]
};

const getDemoData = (aggregateType) => {
    switch (aggregateType) {
        case 'Cars':
            return [1, 4, 8];
        case 'Avg':
            return [44165, 55171, 66188];
        case 'Goal%':
            return [210, 210, 210];
        default:
            return null;
    }
};

const fillTableData = ({
    displayColumnOneType,
    columnOneAggregateTypeOne,
    columnOneAggregateTypeTwo,
    columnOneAggregateTypeThree,
    displayColumnTwoType,
    columnTwoAggregateTypeOne,
    columnTwoAggregateTypeTwo,
    columnTwoAggregateTypeThree,
    hideGoal
}) => {
    let columnOneList = [columnOneAggregateTypeOne, columnOneAggregateTypeTwo, columnOneAggregateTypeThree];
    // filter out null's from the list
    columnOneList = columnOneList.filter(Boolean);
    let columnTwoList = [columnTwoAggregateTypeOne, columnTwoAggregateTypeTwo, columnTwoAggregateTypeThree];
    // filter out null's from the list
    columnTwoList = columnTwoList.filter(Boolean);

    const columnOneRemoved = _.difference(columnTypes, columnOneList);
    const columnTwoRemoved = _.difference(columnTypes, columnTwoList);

    const columnOneAggThree = columnOneAggregateTypeThree || (columnOneRemoved.length == 2 ? columnOneRemoved[1] : columnOneRemoved[0]);
    const columnTwoAggThree = columnTwoAggregateTypeThree || (columnTwoRemoved.length == 2 ? columnTwoRemoved[1] : columnTwoRemoved[0]);
    return [{
        id: tableIds[displayColumnOneType],
        disabled: false,
        header: displayColumnOneType,
        columns: [
            {
                header: columnOneAggregateTypeOne,
                data: getDemoData(columnOneAggregateTypeOne),
                isremove: !columnOneAggregateTypeOne || (columnOneAggregateTypeOne == 'Goal%' && hideGoal),
                default: false,
                sortasc: true
            },
            {
                header: columnOneAggregateTypeTwo || columnOneRemoved[0],
                data: getDemoData(columnOneAggregateTypeTwo || columnOneRemoved[0]),
                isremove: !columnOneAggregateTypeTwo || (columnOneAggregateTypeTwo == 'Goal%' && hideGoal),
                default: false,
                sortasc: true
            },
            {
                header: columnOneAggregateTypeThree || columnOneAggThree,
                data: getDemoData(columnOneAggregateTypeThree || columnOneAggThree),
                isremove: !columnOneAggregateTypeThree || (columnOneAggregateTypeThree == 'Goal%' && hideGoal),
                default: false,
                sortasc: true
            }
        ]
    },
    {
        id: tableIds[displayColumnTwoType],
        disabled: false,
        header: displayColumnTwoType,
        columns: [
            {
                header: columnTwoAggregateTypeOne,
                data: getDemoData(columnTwoAggregateTypeOne),
                isremove: !columnTwoAggregateTypeOne || (columnTwoAggregateTypeOne == 'Goal%' && hideGoal),
                default: false,
                sortasc: true
            },
            {
                header: columnTwoAggregateTypeTwo || columnTwoRemoved[0],
                data: getDemoData(columnTwoAggregateTypeTwo || columnTwoRemoved[0]),
                isremove: !columnTwoAggregateTypeTwo || (columnTwoAggregateTypeTwo == 'Goal%' && hideGoal),
                default: false,
                sortasc: true
            },
            {
                header: columnTwoAggregateTypeThree || columnTwoAggThree,
                data: getDemoData(columnTwoAggregateTypeThree || columnTwoAggThree),
                isremove: !columnTwoAggregateTypeThree || (columnTwoAggregateTypeThree == 'Goal%' && hideGoal),
                default: false,
                sortasc: true
            }
        ]
    }];
};

const addNormalizedData = (table, { sortColumn }) => {
    const newTable = table.map(({ columns, ...rest }) =>
        ({ columns: columns.map((el) => ({ ...el })), ...rest }));
    const [defName, sortName, sortOrder] = sortColumn && sortColumn.split('|') || [];
    if (defName) {
        const tableIndex = newTable.findIndex((t) => t.header === defName);
        const colIndex = newTable[tableIndex].columns.findIndex((c) => c.header === sortName);
        newTable[tableIndex].columns[colIndex].default = true;
        newTable[tableIndex].columns[colIndex].sortasc = sortOrder === 'ASC';
    }
    return newTable;
};

const sortTableEntries = (tables) => {
    const sortedTable = tables.map((table,index)=>{

        // Find the index of the default column
        const defaultIndex = Math.max(table.columns.findIndex(column => column.default), 0);

        // Sort each column's data array based on the default column's sortasc value
        const defaultColumn = table.columns[defaultIndex];
        const sortFunction = defaultColumn.sortasc ? ((a, b) => a - b) : ((a, b) => b - a);
        table.columns.forEach(column => {
            column.data.sort(sortFunction);
        });
        return table;
    })
    return sortedTable;
}

const fillResultObject = (table, isHidingGoal) => {
    if (!table) {
        return {};
    }
    const columnOne = ({ header = null, columns } = {}) => {
        let columnOneAggregateTypeOne = null;
        let columnOneAggregateTypeTwo = null;
        let columnOneAggregateTypeThree = null;
        if (header) {
            // filter the removed cols and 'Goal%' if Hide Goal is selected
            let newColumns = columns.filter((col) => !col.isremove);
            newColumns = newColumns.filter((col) => !(col.header == 'Goal%' && isHidingGoal));
            // if the new cols list is empty, ie. col1 is empty hence return null
            if (newColumns.length == 0 ) {
                return null;
            }
            newColumns.map((col, j) => {
                if (j === 0) {
                    columnOneAggregateTypeOne = col.header;
                } else if (j === 1) {
                    columnOneAggregateTypeTwo = col.header;
                } else if (j === 2) {
                    columnOneAggregateTypeThree = col.header;
                }
            });
        }
        return {
            'DisplayColumnOneType': header,
            'ColumnOneAggregateTypeOne': columnOneAggregateTypeOne,
            'ColumnOneAggregateTypeTwo': columnOneAggregateTypeTwo,
            'ColumnOneAggregateTypeThree': columnOneAggregateTypeThree
        };
    };
    const columnTwo = ({ header = null, columns } = {}) => {
        let columnTwoAggregateTypeOne = null;
        let columnTwoAggregateTypeTwo = null;
        let columnTwoAggregateTypeThree = null;
        if (header) {
            // filter the removed cols and 'Goal%' if Hide Goal is selected
            let newColumns = columns.filter((col) => !col.isremove);
            newColumns = newColumns.filter((col) => !(col.header == 'Goal%' && isHidingGoal));
            newColumns.map((col, j) => {
                if (j === 0) {
                    columnTwoAggregateTypeOne = col.header;
                } else if (j === 1) {
                    columnTwoAggregateTypeTwo = col.header;
                } else if (j === 2) {
                    columnTwoAggregateTypeThree = col.header;
                }
            });
            return {
                'DisplayColumnTwoType': header,
                'ColumnTwoAggregateTypeOne': columnTwoAggregateTypeOne,
                'ColumnTwoAggregateTypeTwo': columnTwoAggregateTypeTwo,
                'ColumnTwoAggregateTypeThree': columnTwoAggregateTypeThree
            };
        } else {
            // return null object if header is null
            return {
                'DisplayColumnTwoType': null,
                'ColumnTwoAggregateTypeOne': null,
                'ColumnTwoAggregateTypeTwo': null,
                'ColumnTwoAggregateTypeThree': null
            };
        }
    };
    const getSortColumn = ( currentTable ) => {
        const defaultColumnTable = currentTable.find(
                ({ header = null, columns } = {}) =>
                    header && columns.some((c) => c.default && !c.isremove)
        );
        const defName = defaultColumnTable && defaultColumnTable.header;
        const defColum = defName && defaultColumnTable.columns.find((c) => c.default);
        const sortName = defColum && defColum.header;
        const isGoalAppeared = !(sortName === 'Goal%' && isHidingGoal);
        const sortOrder = defColum && defColum.sortasc ? 'ASC' : 'DESC';
        return {
            'SortColumn': defName && isGoalAppeared && [defName, sortName, sortOrder].join('|') || null
        };
    };
    let columnOneResult = columnOne(table[0]);
    let columnTwoResult = [];

    // if col1 is empty, then make col2 as col1 and null out col2
    if ( !columnOneResult ) {
        columnOneResult = columnOne(table[1]);
        columnTwoResult = columnTwo({ header: null, columns: [] });
    } else {
        columnTwoResult = columnTwo(table[1]);
    }
    return  { ...columnOneResult, ...columnTwoResult, ...getSortColumn(table) };
};

export const SectionsSection = ({
    template: {
        DisplayColumnOneType: displayColumnOneType,
        ColumnOneAggregateTypeOne: columnOneAggregateTypeOne,
        ColumnOneAggregateTypeTwo: columnOneAggregateTypeTwo,
        ColumnOneAggregateTypeThree: columnOneAggregateTypeThree,
        DisplayColumnTwoType: displayColumnTwoType,
        ColumnTwoAggregateTypeOne: columnTwoAggregateTypeOne,
        ColumnTwoAggregateTypeTwo: columnTwoAggregateTypeTwo,
        ColumnTwoAggregateTypeThree: columnTwoAggregateTypeThree,
        HideGoal: hideGoal,
        SortColumn: sortColumn
    } = {},
    template = {},
    onChange,
    col,
    number,
    disabled = false,
    title,
    className,
    isPreview,
    ...rest
}) => {
    const { t } = useTranslation();
    const [isHidingGoal, setIsHidingGoal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableDropDown, setTableDropDown] = useState(defaultEmptyTableDropDown);

    const sectionEl = useRef(null);

    const onTableDataChange = (table) => {
        const resultObj = fillResultObject(table, isHidingGoal);
        onChange(resultObj);
    };
    useEffect(() => {
        const table = fillTableData({
            displayColumnOneType,
            columnOneAggregateTypeOne,
            columnOneAggregateTypeTwo,
            columnOneAggregateTypeThree,
            displayColumnTwoType,
            columnTwoAggregateTypeOne,
            columnTwoAggregateTypeTwo,
            columnTwoAggregateTypeThree,
            hideGoal
        });
        const populatedTable = addNormalizedData(table, { sortColumn });
        const filteredTable = populatedTable
                .filter(( el ) => el.header)
                .filter(( el ) => !el.columns.every((column) => column.isremove));
        let sortedTable = sortTableEntries(filteredTable);
        setTableData(sortedTable);
        const newTableDropDown = defaultEmptyTableDropDown
                .filter(({ name } = {}) =>
                    !filteredTable.some(({ header } = {}) => header && header === name)
                )
                .map((el) => {
                    el.visible = true;
                    return el;
                });
        setTableDropDown(newTableDropDown);
        setIsHidingGoal(hideGoal);
    }, [template]);
    return (
        <Section
            col={col}
            number={number}
            disabled={disabled}
            title = {t(title)}
            className = {className}
            ref={sectionEl}
            {...rest}
        >
            <div className="leaderboard-sections-subtitle">
                <div className="leaderboard-sections-labelWithTooltip">
                    <Label>{t('leaderboard__template__lb-sections')}</Label>&nbsp;
                    <Tip className="leaderboard-templates-general-toggle-tip z-index-300" container={sectionEl}>
                        {t('leaderboard__template__lb-sections-tooltip')}
                    </Tip>
                </div>
                <p className="leaderboard-sections-subtitle__highlighted-text">{t('leaderboard__template__ranking-column')}</p>
            </div>
            <LeaderBoardSortableSections
                hideGoal={isHidingGoal}
                firstTable={defaultFirstTable}
                tableData={tableData}
                isPreview={isPreview}
                renderEmptyContext={tableData.length + 1 > 2 ? 2 : tableData.length + 1}
                EmptyTableDropDown={tableDropDown}
                additionalClass="leaderboard-templates"
                onChange={onTableDataChange}
            />
            {isPreview &&
            <div className="template-preview-cancel-button"><Button
                variants={['cancel']}
                disabled={!isPreview}
                onClick={rest.onHide}
            >
                {t('common__close')}
            </Button>
            </div>}
        </Section>
    );
};

