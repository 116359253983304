import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components/Inputs';
import { TreeInput } from 'library/TreeInput';
import { InfoBox, VARINATS } from 'library/InfoBox';
import { ErrorLabel } from 'library/ErrorLabel';
import { Section } from 'library/Section';
import { OverlayLoader } from 'library/OverlayLoader';
import { Checkbox } from 'library/Checkbox';

const infoboxVariants = [VARINATS.INFO];

export const Stores = ({
    storesTree,
    contestStoresSearchQuery,
    onStoresSearchQueryChange,
    errors,
    disabled,
    contestStores,
    onContestStoresChange,
    isCheckingAvailableStores,
    isOnlySelectedStoresShown,
    toggleSelectedStoresOnly
}) => {
    const { t } = useTranslation();

    return (
        <Section
            title={`${t('common__select')} ${t('stores-selector__participating-stores')}`}
            number={3}
            showNumber={true}
            className="hme-contest-view-edit-form__section hme-contest-view-edit-form__section--stores"
            disabled={disabled}
        >
            <InfoBox
                message="contest__store-select--working-hours-info"
                variants={infoboxVariants}
            />
            <SearchInput
                value={contestStoresSearchQuery}
                placeholder={t('stores-hierarchy__search__placeholder')}
                showClearIcon={true}
                onChange={onStoresSearchQueryChange}
            />
            {errors.contestStores && <ErrorLabel>{errors.contestStores}</ErrorLabel>}
            <div className="hme-contest-view-edit-form__tree-wrapper">
                <div className="hme-contest-view-edit-form__tree-header-block">
                    <Checkbox
                        checked={isOnlySelectedStoresShown}
                        onChange={toggleSelectedStoresOnly}
                        label={t('contest__form__view-selected-stores')}
                    />
                </div>
                <TreeInput
                    value={contestStores}
                    onChange={onContestStoresChange}
                    selectable
                    tree={storesTree}
                    emptyTreeMessage={
                        contestStores.length === 0 && isOnlySelectedStoresShown
                            ? 'contest__form__view-selected-stores--no-stores-selected'
                            : 'common__no-results'
                    }
                />

                {isCheckingAvailableStores && <OverlayLoader />}
            </div>
        </Section>
    );
};
