import React from 'react';

import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import './CancelConfirmPopup.scss';

export const CancelConfirmPopup = ({ onHide, onConfirm, ...props }) => (
    <ConfirmPopupComponent
        {...props}
        onHide={onHide}
        title='Just double checking…'
        message='Are you sure you want to cancel this task?'
        dialogClassName='apply-store-settings-status-confirm-cancel'
        actions={[{
            children: 'No, go back',
            onClick: onHide,
        }, {
            children: 'Continue',
            variants: ['submit'],
            onClick: () => {
                onConfirm && onConfirm();
                onHide && onHide();
            }
        }]}
    />
);