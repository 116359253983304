import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { getOwnerAccount } from '../actions/userAccounts';
import './withAccountEmail.css';

const withAccountEmail = (Component, { ownerClassName = '' } = {}) => {
    return (props) => {
        const [queryParams] = useSearchParams();
        const uuidInQuery = queryParams.get('uuid');

        const dispatch = useDispatch();

        useEffect(() => {
            if (uuidInQuery) {
                getOwnerAccount(uuidInQuery)(dispatch);
            }
        }, [uuidInQuery]);
        const ownerEmail = useSelector(({userAccounts}) => userAccounts.ownerAccount.User_EmailAddress, shallowEqual);

        return <section>
            {ownerEmail && <div className={classNames('ownerEmail', ownerClassName)}>Account Email: {ownerEmail}</div>}
            <Component {...props} ownerEmail={ownerEmail} />
        </section>;
    }
};

export default withAccountEmail;
