import React from 'react';
import { NavDropdown  } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WorldIcon } from 'assets/icons/world.svg';

import './LanguageItem.scss';

export const LanguageItem = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <NavDropdown.Item className='headerMenu hme-header-menu--language' id='hme-header-menu--language' onClick={onClick}>
            <WorldIcon className='hme-header-menu__icon ' />
            {t('language-select__label')}
        </NavDropdown.Item>
    );
};
