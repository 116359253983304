import { createContext, useContext, useMemo } from 'react';

import { useLeaderboardInvite, defaultValue as leaderboardInviteDefault } from './hooks/useLeaderboardInvite';
import { useOnRemove, onRemoveDefault } from './hooks/useOnRemove';
import { useOnRead, onReadDefault } from './hooks/useOnRead';
import { useActive, activeDefault } from './hooks/useActive';

const Context = createContext({
    leaderboardInvite: leaderboardInviteDefault,
    removeNotification: onRemoveDefault,
    readNotification: onReadDefault,
    active: activeDefault
});

export const useNotificationsCenterContext = () => useContext(Context);

// in case if some basic logic should be overwritten
export const useNotificationCenterValue = ({ leaderboardInvite, removeNotification, readNotification, active }) => {
    const leaderboardInviteData = useLeaderboardInvite(leaderboardInvite);
    const removeNotificationData = useOnRemove(removeNotification);
    const readNotificationData = useOnRead(readNotification);
    const activeData = useActive(active);

    return useMemo(() => ({
        leaderboardInvite: leaderboardInviteData,
        removeNotification: removeNotificationData,
        readNotification: readNotificationData,
        active: activeData,
    }), [leaderboardInviteData, removeNotificationData, readNotificationData, activeData]);
}

export const NotificationCenterProvider = ({ providerData, children }) => {
    const value = useNotificationCenterValue(providerData);

    return (
        <Context.Provider value={value}>{children}</Context.Provider>
    );
};
