import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';

const searchInItem = (item, search) => {
    switch (item.Type) {
        case storeHierarchyNodeTypes.GROUP:

            return item.Name.toLowerCase().includes(search);

        case storeHierarchyNodeTypes.STORE:

            return (item.Name && item.Name.toLowerCase().includes(search)) ||
                (item.StoreNumber && item.StoreNumber.toLowerCase().includes(search));

        default:

            return false;
    }
}

const applySearch = (tree, search) => {
    return tree.map((item) => {
        if (item.item.isEdit || searchInItem(item.item, search)) {
            return item;
        }

        if (item.children) {
            const newChildren = applySearch(item.children, search);

            if (!newChildren.length) {
                return null;
            }

            return {
                ...item,
                children: newChildren,
            };
        }

        return null;
    }).filter((item) => item !== null);
};

export const searchTree = (tree, search) => {
    if (search.length === 0) {
        return tree;
    }

    return applySearch(tree, search.toLowerCase());
};
