import React from 'react';
import { cond, T } from 'ramda';

import { InputComponent as Input } from 'components/Inputs';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import './SuccessInput.scss';

const SuccessIcon = cond([
    [({ success }) => success, () => <CheckIcon className="success-input-icon" />],
    [T, () => null]
]);

// Note: May be in the future will be better to make it as HOC
export const SuccessInput = ({ variants = [], success = false, children, ...inputProps }) => (
    <Input variants={ success ? [...variants, 'success'] : variants } {...inputProps}>
        <SuccessIcon success={success} />
        {children}
    </Input>
);
