import React, { useEffect, useState } from 'react';
import { reportNames } from './reportConstant';
import CommonLoader from 'components/Alerts/CommonLoader';
import { useReportTemplateContext } from './context/ReportTemplateContext';
import { Link, useNavigate } from 'react-router-dom';
import './ReportDashboard.scss';
import { getReportsOverview } from 'api/pbiReports';
import { COMMON_ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { ReportsHeader } from 'components/Header/ReportsHeader';
import { isAdmin } from 'services/Auth';
import { filterReportsBySubscription } from 'components/PbiReportScreen/helpers/FilterReportsBySubscription';
import { PbiReportScreen } from 'components/PbiReportScreen';

export const ReportDashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [recentReports, setRecentReports] = useState([]);
    const [recentTemplates, setRecentTemplates] = useState([]);
    const [activeTemplates, setActiveTemplates] = useState({});
    const [reportTiles, setReportTiles] = useState([]);
    const { t } = useTranslation();
    const { setSelectedTemplate } = useReportTemplateContext();
    const navigate = useNavigate();

    // Function to get landing page data from DB
    const getRecent = async (allowedReportTypes) => {
        const overview = await getReportsOverview({ allowedReportTypes });
        const { status, data, error } = overview;
        if (status) {
            setRecentReports(data.recentReports);
            setRecentTemplates(data.recentTemplates);
            setActiveTemplates(data.allTemplates);
        } else {
            console.error(error);
        }
        setIsLoading(false);
    };


    useEffect(()=>{
        setIsLoading(true);
        const reportList = filterReportsBySubscription();
        setReportTiles(reportList);
        const allowedReportTypes = reportList.map((report)=> report.type);
        getRecent(allowedReportTypes);
        setSelectedTemplate(null);
    }, []);


    const handleTemplateClick = (template) => {
        setSelectedTemplate(template);
        navigate(COMMON_ROUTES[template.ReportType]);
    };

    return (<>
        { isLoading ?
        (<CommonLoader showLoader={isLoading} message={'common__loading'} />)
        : (<>
            <ReportsHeader isAdmin={isAdmin()} />
            <div className="reports-overview">
                {/* Left Section - Recently Viewed Reports and Templates */}
                <div className="reports-overview__recently-viewed">
                    <div className="reports-overview__recent-reports">
                        <h3>{t('reports__recently-viewed')}</h3>
                        {recentReports.length > 0 ?
                        recentReports.map((report, idx) => (
                            <label key={idx} className="reports-overview__recent-item" >
                                <Link to={COMMON_ROUTES[report.ReportType]}>{t(reportNames[report.ReportType])}</Link>
                            </label>
                        ))
                        : <h4>{t('reports__no-recent-available')}</h4>
                        }
                    </div>
                    <div className="reports-overview__recent-templates">
                        <h3>{t('templates__recently-viewed')}</h3>
                        {recentTemplates.length > 0 ?
                        recentTemplates.map((template, idx) => (
                            <label key={idx} className="reports-overview__recent-item">
                                <Link
                                    to={COMMON_ROUTES[template.ReportType]}
                                    onClick={()=>{
                                        handleTemplateClick(template);
                                    }}
                                >
                                    {`${t(reportNames[template.ReportType])} - ${template.TemplateName}`}
                                </Link>
                            </label>
                        ))
                        : <h4>{t('templates__no-recent-available')}</h4>
                        }
                    </div>
                </div>

                {/* Middle Section - PowerBI KPI Metrics */}
                <div className="reports-overview__kpi-recent-activity">
                    <PbiReportScreen showOnlyReport={true} />
                </div>
                {/* Right Section - All Reports & Templates Information */}
                <div className="reports-overview__info">
                    <h3>{t('reports-overview__title')}</h3>
                    <div className="reports-overview__report-tiles">
                        {reportTiles.map((tile) => (
                            <div key={tile.id} className="reports-overview__tile">
                                <h2 className="reports-overview__tile-title">{t(tile.title)}</h2>
                                <p>{t(tile.description)}</p>
                                <div className="reports-overview__view-report">
                                    <Link to={tile.path} >{t('reports-overview__show-report')}</Link>
                                </div>
                                {tile.type !== 'rcd' &&
                                <>
                                    <h4 className="reports-overview__template-header">{t('sub-header--lb__templates')}</h4>
                                    {
                                        activeTemplates[tile.type] && activeTemplates[tile.type].length > 0 ?
                                        <div className="reports-overview__template-container">
                                            {/* Dividing this section in two to display border in between */}
                                            <div className="reports-overview__template-left-section">
                                                { activeTemplates[tile.type].slice(0, 5).map((template, idx) => {
                                                    return <div className={'reports-overview__template-container__template-item'} key={idx}>
                                                        <Link
                                                            to={tile.path}
                                                            onClick={()=>{
                                                                handleTemplateClick(template);
                                                            }}
                                                        >
                                                            {template.TemplateName}
                                                        </Link>
                                                    </div>;
                                                }) }
                                            </div>
                                            {activeTemplates[tile.type].length> 5 &&
                                            (<div className="reports-overview__template-right-section">
                                                { activeTemplates[tile.type].slice(5, 10).map((template, idx) => {
                                                    return <div className={'reports-overview__template-container__template-item'} key={idx}>
                                                        <Link
                                                            to={tile.path}
                                                            onClick={()=>{
                                                                handleTemplateClick(template);
                                                            }}
                                                        >
                                                            {template.TemplateName}
                                                        </Link>
                                                    </div>;
                                                }) }
                                            </div>)}
                                        </div>
                                        : <h4 className="reports-overview__not-available">{t('report-template__not-available')}</h4>
                                    }
                                </>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>) }
    </>);
};
