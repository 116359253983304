import { useMemo } from 'react';

import { useMedia } from 'hooks/useMedia';

const MOBILE_PERPESENTATION = 0;
const TABLET_PERPESENTATION = 1;
const DESKTOP_PERPESENTATION = 2;

const MOBILE_MEDIA_QUERY = 'screen and (max-width: 767px)';
const TABLET_MEDIA_QUERY = 'screen and (max-width: 1173px)';
const DESKTOP_MEDIA_QUERY = 'screen and (min-width: 1174px)';

export const useScreenWidthMatch = () => {
    const currentRepresentation = useMedia(
            [MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY, DESKTOP_MEDIA_QUERY],
            [MOBILE_PERPESENTATION, TABLET_PERPESENTATION, DESKTOP_PERPESENTATION],
            MOBILE_PERPESENTATION
    );

    return useMemo(() => ({
        isMobile: currentRepresentation === MOBILE_PERPESENTATION,
        isTablet: currentRepresentation === TABLET_PERPESENTATION,
        isDesktop: currentRepresentation === DESKTOP_PERPESENTATION
    }), [currentRepresentation]);
};
