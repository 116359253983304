import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'constants/routes';
import { Config } from 'Config';
import AuthenticationService from 'components/Security/AuthenticationService';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { useAsync } from 'hooks/useAsync';
import { prepareAccountDataDTO } from 'hooks/account/useAccountUpdate';

import { changePassword, agreeWithTOS, generateNewToken, verifyCurrentPassword } from '../Controller';

export const useChangePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { status, run } = useAsync();

    const handleChangePassword = useCallback(
            async (accountData) => {
                const authService = new AuthenticationService(Config.authBaseUrl);
                try {

                    await run(
                            verifyCurrentPassword({ uuId: accountData.uuId, currentPassword: accountData.currentPassword }, accountData.isPartnerUser).then(
                                    async (validationRes) => {
                                        if (!validationRes.validPassword) {
                                            throw new Error('Invalid credentials supplied');
                                        }
                                        const preparedUserDTO = prepareAccountDataDTO(accountData);

                                        const changePasswordRes = await Promise.all([
                                            agreeWithTOS({
                                                uuId: preparedUserDTO.uuId,
                                                UserID: preparedUserDTO.UserID,
                                                ownerAccountId: preparedUserDTO.ownerAccountId,
                                                isTOSAgreed: 1
                                            }, accountData.isPartnerUser),
                                            changePassword({
                                                ...preparedUserDTO,
                                                changeEmail: preparedUserDTO.userEmail,
                                                isAgree: 1
                                            }, accountData.isPartnerUser)
                                        ]);

                                        if (!changePasswordRes.every(({ status }) => status)) {
                                            const [{ message: errorMsg1 } = {}, { message: errorMsg2 } = {}] = changePasswordRes;
                                            throw new Error(errorMsg1 || errorMsg2 || 'Unsuccessful password change');
                                        }

                                        authService.clearAgreedAccount();
                                        const newTokenGenerationRes = await generateNewToken({
                                            userEmail: preparedUserDTO.userEmail,
                                            newPassword: preparedUserDTO.newPassword,
                                            isPartner: accountData.isPartnerUser
                                        });
                                        authService.setToken(newTokenGenerationRes.accessToken);

                                        return validationRes;
                                    }
                            )
                    );

                    addSuccessNotification(t('profile__account__new-user--success'));

                    setTimeout(() => {
                        navigate(accountData.isPartnerUser ? `/` : `/${PUBLIC_ROUTES.account}`);
                    }, 2000);
                } catch (err) {
                    if (err.message === 'Invalid credentials supplied') {
                        addErrorNotification(t('my-account__error__invalid-credentials'));
                    } else {
                        addErrorNotification(t('common__error--internal-server__p1'));
                    }
                }
            },
            [navigate, run, t]
    );

    return {
        isLoading: status === 'pending',
        handleChangePassword
    };
};
