import * as types from "../actionTypes/DynamicHomeScreen";
import Api from "../Api";
import { CommonConstants } from "../Constants";
import { Config } from "../Config";
import AuthenticationService from "../components/Security/AuthenticationService";

const api = new Api();
const _ = require("underscore");

export function getDefaultSetup() {
    return {
        type: types.GET_DEFAULT_SETUP
    };
}

function getStoreRankingSuccess (top3Bottom3, userPreferences) {
    return {
        type: types.GET_STORE_RANKING_SUCCESS,
        top3Bottom3: top3Bottom3,
        userPreferences: userPreferences
    };
}

function getStoreRankingFailure() {
    return {
        type: types.GET_STORE_RANKING_FAILURE
    };
}

export function getStoreRanking (dispatch) {

    const authService = new AuthenticationService(Config.authBaseUrl);
    let url = `${Config.apiBaseUrl + CommonConstants.apiUrls.getDashboard}?isAdmin=${authService.isAdmin() ? "1" : "0"}&reportType=day&eventType=2000&storeDashboard=1`;
    url = authService.getUUID() ? url + "&uuid=" + authService.getUUID() : url;

    api.getData(url, response => {
        //response = mockResponse;
        if (response) {
            try {
                const top3Bottom3 = getTop3Bottom3(response);
                return dispatch(getStoreRankingSuccess(top3Bottom3, response.userPreferences));
            } catch (e) {
                return dispatch(getStoreRankingFailure());
            }
        } else {
            return dispatch(getStoreRankingFailure());
        }
    }, error => {
        console.log("Error from dashboard action", error);
        return dispatch(getStoreRankingFailure());
    });

}

// this function modifies data to object in required format for ranking table
function getTop3Bottom3 (data) {

    const dashboard = data.dashboard;
    if (dashboard == null) throw "no data found";

    let rows = dashboard.find(x => x.title === "current day").rows;
    if (rows == null) throw "no data found";
    rows = rows.filter(x => x.storeRank.isANumber());
    if (rows == null) throw "no data found";

    rows.sort(compare);
    let topStores = rows.splice(0, 3);
    let bottomStores = rows.slice(-3);

    return {
        topThree: topStores,
        bottomThree: bottomStores
    };
}

function compare (a, b) {
    if (Number(a.storeRank) < Number(b.storeRank))
        return -1;
    if (Number(a.storeRank) > Number(b.storeRank))
        return 1;
    return 0;
}

// string prototype
String.prototype.isANumber = function() {return /^\d+$/.test(this);};
