import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';

import { Section } from 'library/Section';
import { Toggle } from 'library/Toggle';
import { Tip } from 'library/Tip';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { BrandForm } from 'library/BrandForm';
import { AccountForm } from './AccountForm';
import { StoreForm } from './StoreForm';

import './FilterSection.scss';

export const filterTypes = {
    BRAND: 'BRAND',
    ACCOUNT: 'ACCOUNT',
    STORE: 'STORE',
};

const FilterForm = cond([
    [({ type }) => type === filterTypes.BRAND, props => <BrandForm {...props} />],
    [({ type }) => type === filterTypes.ACCOUNT, props => <AccountForm {...props} />],
    [({ type }) => type === filterTypes.STORE, props => <StoreForm {...props} />],
    [T, () => null],
]);

export const FilterSection = ({
    sectionNumber,
    disabled = false,
    brands,
    countries,
    filters,
    sourceDevice,
    selectedDeviceType,
    isDevicesSelected,
    onFiltersChange,
    onFilterBlockChange,
}) => {
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const [nextType, setNextType] = useState('');
    const { t } = useTranslation();
    const onTypeChange = useCallback(newType => {
        if (!isDevicesSelected) {
            onFiltersChange({
                ...filters,
                filterType: newType,
            });
            onFilterBlockChange(newType);

            return;
        }

        setIsConfirmPopupShown(true);
        setNextType(newType);
    }, [
        isDevicesSelected,
        onFiltersChange,
        setNextType,
        setIsConfirmPopupShown,
        onFilterBlockChange
    ]);

    const onConfirm = useCallback(() => {
        onFiltersChange({
            ...filters,
            filterType: nextType,
        });

        onFilterBlockChange(nextType);
    }, [nextType, onFiltersChange, onFilterBlockChange]);

    const onHide = useCallback(() => {
        setIsConfirmPopupShown(false);
    }, [setIsConfirmPopupShown]);

    return (
        <Section
            number={sectionNumber}
            title={t('create_new_task__search--search_for_destination_devices')}
            disabled={disabled}
            className='admin-create-apply-device-settings-task-filters'
        >
            <Toggle value={filters.filterType} onChange={onTypeChange} label={
                <>
                    {t('common__search-by')}&nbsp;
                    <Tip className='admin-create-apply-device-settings-task-filters-tip'>
                        {t('create_new_task__search--search-by-tool-tip')}
                    </Tip>
                </>
            }>
                {[{
                    text: t('common__brand'),
                    value: filterTypes.BRAND,
                }, {
                    text: t('common__account'),
                    value: filterTypes.ACCOUNT,
                }, {
                    text: t('common__store'),
                    value: filterTypes.STORE,
                }]}
            </Toggle>
            <FilterForm
                type={filters.filterType}
                brands={brands}
                countries={countries}
                filters={filters}
                sourceDevice={sourceDevice}
                selectedDeviceType={selectedDeviceType}
                onFiltersChange={onFiltersChange}
            />
            <ConfirmPopupComponent
                show={isConfirmPopupShown}
                message={<>
                    <div className='admin-create-apply-device-settings-task-filters-confirm-line'>
                        {t('create_new_task__search--pop-up-warning')}
                    </div>
                    <div>{t('create_new_task__search--do-you-wish-to-continue')}</div>
                </>}
                onHide={onHide}
                onConfirm={onConfirm}
                confirmationVerb={t('common__continue')}
            />
        </Section>
    );
}
