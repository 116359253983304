/* eslint-disable complexity */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import 'url-search-params-polyfill';

import { CommonConstants } from '../../Constants';
import NavigationService from '../Common/NavigationServices';
import AuthFactory from '../../helpers/AuthFactory';
import DeviceStatusImage from './DeviceStatusImage';
import { laneConfigKeys } from 'constants/device';
import { ADMIN_ROUTES, PUBLIC_ROUTES } from 'constants/routes';

import './Stores.css';
import PropTypes from 'prop-types';
import { withReactRouter } from 'HOCs/withReactRouter';
import { SystemInformationAccountLink } from 'library/SystemInformationAccountLink';
import getDeviceType from 'helpers/Device/getDeviceType';
import { DateLib } from '@hme-cloud/utility-common';

const {
    deviceType: {
        nexeo: {
            id: nexeoDeviceTypeId
        } = {}
    } = {}
} = CommonConstants;
// This component is used for displaying the system status.
class SystemStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStores: this.props.showStores,
            url: null
        };
        this.navigate = new NavigationService();

        // Binding the methods.
        this.StoreNameCLick = this.StoreNameCLick.bind(this);
        this.AccountEmailClick = this.AccountEmailClick.bind(this);
        this.checkIsRemoteConnectionPermission = this.checkIsRemoteConnectionPermission.bind(this);

        this.authService = AuthFactory.AuthService;
        this.permissionSpecification = AuthFactory.PermissionSpecification;
    }
    componentDidMount() {
        const isAdmin = this.authService.isAdmin();
        const url = this.authService.getColdFusionAppUrl(isAdmin);
        const token = this.authService.getToken();
        const pathname = window.location.pathname;
        const isMasterSettings = pathname.includes('/masterSettings');
        this.setState({
            url,
            token,
            isMasterSettings,
            isAdmin
        });
    }

    StoreNameCLick() {
        const {
            location: {
                search
            } = {},
            data: {
                systemStatus: [
                    {
                        Store_UID: storeUID
                    } = {}
                ] = []
            } = {}
        } = this.props;
        const {
            isAdmin
        } = this.state;

        const params = new URLSearchParams(search);
        const uuid = params.get('uuid') || null;
        const base = isAdmin ? ADMIN_ROUTES.storeSettings : PUBLIC_ROUTES.storeSettings;
        const suidQuery = `?suid=${storeUID}`;
        const uuidQuery = uuid ? `&uuid=${uuid}` : '';
        window.location.href = `${base}${suidQuery}${uuidQuery}`;
    }

    AccountEmailClick() {
        const {
            data: {
                systemStatus: [
                    {
                        User_UID: userUID,
                        Company_Type: companyType
                    } = {}
                ] = []
            } = {}
        } = this.props;
        if (userUID && companyType) {
            window.location.href = `/admin/account?type=${companyType}&uuid=${userUID}`;
        }
    }


    getSystemName(device) {
        const {
            deviceType: {
                zoomNitro: {
                    name: zoomNitroName,
                    minMainVersion: zoomNitroMinMainVersion = ''
                } = {}
            } = {}
        } = CommonConstants;
        const {
            Device_DeviceType_ID: deviceTypeID,
            Device_MainVersion: deviceMainVersion
        } = device;
        const deviceType = getDeviceType(device);

        const isZoom = deviceTypeID === 1;
        const majorVersion = Number(String(deviceMainVersion).split('.')[0]);
        const isNitro = majorVersion >= zoomNitroMinMainVersion;
        const isZoomNitro = isZoom && isNitro;
        const systemName = isZoomNitro ? zoomNitroName : deviceType.displayName;
        return systemName;
    }

    // Checking the device status.
    renderStatus(device = {}) {
        const { t } = this.props;
        const { isAdmin } = this.state;
        const {
            Device_Name: deviceName,
            Device_IsPreconfigured: isPreconfigured,
            Device_IsActive: isActive,
            Device_UID: deviceUID,
            Device_MainVersion: deviceMainVersion
        } = device;
        const href = this.navigate.getMenu('connect', deviceUID, deviceMainVersion, null, deviceName);
        const systemName = this.getSystemName(device);

        const adminPrefix = isAdmin ? `${systemName} ` : '';
        const deviceOnline =`${adminPrefix}${t('common__device__status--online')}`;
        const deviceOffline =`${adminPrefix}${t('common__device__status--offline')}`;
        const deviceStatus = isActive ? deviceOnline : deviceOffline;

        const status = isActive === null ? '' : deviceStatus;
        const caption = isPreconfigured ?
            `${systemName} ${t('common__device__status--pre-configured')}` :
            status;
        const { hasRemoteConnectPermission, hasDistributorManageDeviceBasic } = this.permissionSpecification;
        const isOnline = !isPreconfigured && isActive;

        // If admin portal allow remoteConnect to CIB, on public portal do not allow remote
        // connect to CIB devices
        const isNotCIB = deviceName !== CommonConstants.deviceType.cib.name;
        const isNotLinkWear = deviceName !== CommonConstants.deviceType.linkWear.name;
        const isNotEVD = !this.isEVD();
        const haveHref = isOnline &&
            (hasRemoteConnectPermission || hasDistributorManageDeviceBasic) &&
            (!isAdmin ? isNotCIB : true) &&
            isNotLinkWear && isNotEVD;

        const attributes = {
            href: haveHref ? href : undefined,
            className: `statusText ${haveHref ? 'systemStatus_online_normal' : ''}`
        };

        const StatusTag = haveHref ? 'a' : 'span';
        return (<StatusTag {...attributes}>{caption}</StatusTag>);
    }

    // Check device status.
    checkIsRemoteConnectionPermission(displayData) {
        const {
            Device_IsActive: isActive,
            Device_IsPreconfigured: isPreconfigured
        } = displayData;
        const img = !isPreconfigured ? (<DeviceStatusImage isActive={Boolean(isActive)} />) : '';
        return (
            <td>
                {img}
                <span className="cstat systemStatusText">
                    {this.renderStatus(displayData)}
                </span>
            </td>
        );
    }

    isDeviceType(type) {
        const {
            systemStatus: [{ Device_Name = '' }]
        } = this.props.data || [{}];

        return Device_Name.toLowerCase() === type.toLowerCase();
    }

    isNexeo() {
        return this.isDeviceType('nexeo');
    }

    isEVD() {
        return this.isDeviceType('ZOOM Nitro/EVD');
    }

    render() {
        if (!this.props.data) return (<div />);
        const {
            isMasterSettings,
            isAdmin
        } = this.state;
        const {
            data: {
                systemStatus: [
                    displayData = {}
                ] = [],
                systemStatus: [
                    { // displayData
                        Device_SerialNumber: serialNumber,
                        Device_MainVersion: mainVersion,
                        Device_SettingVersion: settingVersion,
                        Brand_Name: brandName,
                        Store_Number: storeNumber,
                        Store_AddressLine1: addressLine1,
                        Store_Locality: locality,
                        Store_Region: region,
                        LaneConfig_Name: laneConfigName,
                        Device_MacAddress: macAddress,
                        Device_IsPreconfigured: isPreconfigured,
                        Device_CreatedBy: createdBy,
                        Device_Created_DTS: displayDataCreatedDTS,
                        Device_DeviceType_ID: deviceTypeID,
                        User_UID: userUID,
                        Company_Type: companyType,
                        User_EmailAddress: emailAddress,
                        Timezone: timezoneDescription = ''
                    } = {}
                ] = [],
                Device_Created_DTS: createdDTS,
                Device_LastMod_DTS: lastModDTS,
                Device_LastUpgrade: lastUpgrade,
                totalDevices
            } = {},
            scheduledUpgrade = {},
            t,
            i18n,
        } = this.props;


        const {
            deviceType: {
                linkWear: { id: linkWearDeviceTypeId }
            },
            userPermissions: {
                RemoteConnect: remoteConnect
            } = {}
        } = CommonConstants;
        const isNexeo = deviceTypeID === nexeoDeviceTypeId;
        const isEVD = this.isEVD();
        const isLinkWearDevice = deviceTypeID === linkWearDeviceTypeId;
        const formatDate = (date) => date ? new DateLib(date, {locale: i18n.resolvedLanguage}).format("LLLL dd, y HH:mm") : '';//include in utility-common
        const compositeStoreNumber = brandName ? `${brandName} - ${storeNumber}` : storeNumber;
        const systemName = this.getSystemName(displayData);
        const isScheduledUpgrade = scheduledUpgrade &&
                                  ['In Progress', 'Failed', 'Scheduled'].includes(scheduledUpgrade.DeviceUpgradeStatus) && !(scheduledUpgrade.DeviceUpgradeStatus == 'Failed' && scheduledUpgrade.InitialDeviceVersion != mainVersion);
        const isTimer = (systemName === 'ZOOM' || systemName === 'ZOOM Nitro');

        const publicUserProfile = !isAdmin ? this.authService.getProfile() : null;

        return (
            <div className="system-settings-status clear">
                <table className="system-status-table">
                    <tbody>
                        <tr key="settingsStoresSystemStatus">
                            <td colSpan="2"><h3 className="clear system_header">{t('common__system__status')}</h3></td>
                        </tr>
                        <tr key="settingsStoresSystemName">
                            <th>
                                <span>
                                    {isMasterSettings
                    ? t('common__device__serial-number--text')
                    : t('common__system__name')}
                                </span>
                            </th>
                            <td className="systemStatusText">
                                {isMasterSettings
                  ? serialNumber
                  : systemName}
                            </td>
                        </tr>
                        <tr key="settingsStoresSystemVersion">
                            <th><span>{t('common__system__version')}</span></th>
                            <td className="systemStatusText">{isPreconfigured ? '' : mainVersion}</td>
                        </tr>
                        {!isLinkWearDevice && !isEVD && <tr key="settingsDevicesSettingVersion">
                            <th><span>{t('common__settings__version')}</span></th>
                            <td className="systemStatusText">{settingVersion}</td>
                        </tr>}
                        <tr key="settingsDevicesRegisteredToStoreInfo">
                            <th>{t('system-status__associated-store-info')}</th>
                            <td>
                                <ul className="list-style-none registered-store-list">
                                    <li>
                                        {isMasterSettings
                      ? (<span>
                          {compositeStoreNumber}
                      </span>)
                      : (<a className="systemStatus_online_normal systemStatusText" onClick={this.StoreNameCLick}>
                          {compositeStoreNumber}
                      </a>)}
                                    </li>
                                    <li className="systemStatusText">{addressLine1}</li>
                                    <li className={locality || region ? 'systemStatusText': 'hidden'}>{locality}, {region}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr key="settingsDevicesRegisteredToAccountInfo">
                            <th>{t('system-status__associated-account-info')}</th>
                            <td>
                                {
                                    isAdmin
                                    ? <a className={(userUID && companyType ? 'systemStatus_online_normal systemStatusText' : '')} onClick={this.AccountEmailClick}>{emailAddress}</a>
                                    : <SystemInformationAccountLink
                                        device={this.props.data.systemStatus[0]}
                                        profile={publicUserProfile}
                                        className="systemStatus_online_normal systemStatusText"
                                    />
                                }
                            </td>
                        </tr>
                        <tr key="settingsDevicesTimezone" className={timezoneDescription ? '' : 'hidden'}>
                            <th>{t('common__timezone')}</th>
                            <td>
                                <span className="systemStatusText">{ timezoneDescription }</span>
                            </td>
                        </tr>
                        { !isLinkWearDevice &&
              <tr key="settingsDevicesLaneConfiguration">
                  <th><span>{t('system-status__lane-config')}</span></th>
                  <td><span className="systemStatusText">{laneConfigName !== null ? t(laneConfigKeys[laneConfigName]) : '' }</span></td>
              </tr>}
                        {!isMasterSettings &&
              (<tr key="settingsStoresSerialNumber">
                  <th><span>{t('common__device__serial-number--text')}</span></th>
                  <td className="systemStatusText">{isPreconfigured ? '' : serialNumber}</td>
              </tr>)}
                        {isAdmin && <tr key="settingsStoresMacAddress">
                            <th><span>{t('system-status__mac-address')}</span></th>
                            <td className="systemStatusText">{isPreconfigured ? '' : macAddress}</td>
                        </tr>}
                        {!isMasterSettings &&
              (<tr key="StoreSettingsHeaderStatus">
                  <th><span>{t('common__system__status')}</span></th>
                  {/* Checking for the remote connect permission and displaying the data. */}
                  {this.checkIsRemoteConnectionPermission(displayData)}
              </tr>)}
                        {!isMasterSettings && !isLinkWearDevice && !this.isNexeo() &&
              (<tr key="settingsDevicesFirstActivity">
                  <th><span>{t('system-status__activity--first')}</span></th>
                  <td className="systemStatusText">{formatDate(createdDTS)}</td>
              </tr>)}
                        {!isMasterSettings && !isLinkWearDevice && !this.isNexeo() &&
              (<tr key="settingsDevicesLastActivity">
                  <th><span>{t('system-status__activity--last')}</span></th>
                  <td className="systemStatusText">{formatDate(lastModDTS)}</td>
              </tr>)}
                        {!isMasterSettings && !isLinkWearDevice && !isEVD &&
              (<tr key="LastUpgrade">
                  <th><span>{t('system-status__last-upgrade')}</span></th>
                  <td className="systemStatusText">{formatDate(lastUpgrade)}</td>
              </tr>)}
                        {!isMasterSettings && !isNexeo && !isLinkWearDevice && !isEVD &&
              (<tr key="settingsDevicesNumberOfRecords">
                  <th><span>{t('system-status__number-of-records')}</span></th>
                  <td className="systemStatusText">{totalDevices ? parseInt(totalDevices, 10).toLocaleString() : null}</td>
              </tr>)}
                        {Boolean(isPreconfigured) &&
              (<tr key="settingsDevicePreConfiguredBy">
                  <th><span>{t('system-status__preconfigured-by')}</span></th>
                  <td className="systemStatusText">{createdBy}</td>
              </tr>)}
                        {Boolean(isPreconfigured) &&
              (<tr key="settingsDevicePreConfiguredDate">
                  <th><span>{t('system-status__preconfigured-date')}</span></th>
                  <td className="systemStatusText">{formatDate(displayDataCreatedDTS)}</td>
              </tr>)}
                        {isAdmin && isTimer && !isEVD && <tr key="settingsStoresScheduledUpgrade">
                            <th><span>{t('system-status__scheduled-upgrade')}</span></th>
                            <td className="systemStatusText">{isScheduledUpgrade ?
                                <div className="mt-2">
                                    <Link to={`/admin/settings/deviceUpgrades/${scheduledUpgrade.DeviceScheduledUpgradeID}`}>
                                        {scheduledUpgrade.ScheduledUpgradeName}{` `}
                                    </Link>
                                    <label className={scheduledUpgrade.DeviceUpgradeStatus === 'Failed' ? 'red-font-text' : ''}>
                                        {` - `}{scheduledUpgrade.DeviceUpgradeStatus}
                                    </label>
                                </div> : 'No upgrade scheduled'}</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        );
    }
}

SystemStatus.propTypes = {
    location: PropTypes.object
};

export default compose(
        withTranslation(),
        withReactRouter
)(SystemStatus);
