import { phoneRegExp } from 'constants/phoneNumber';
import { postCodeRegExp as zipRegExp } from 'constants/postCode';

export const companyInfoValidator = ({
    companyName,
    addressLine1,
    city,
    countryId,
    regionId,
    regionName,
    phoneNumber,
    zipcode
}) => {
    const formErrorsConditions = [
        {
            key: 'companyName',
            condition: !companyName,
            message: 'my-account__error__blank-company-name',
        },
        {
            key: 'addressLine1',
            condition: !addressLine1,
            message: 'my-account__error__blank-company-address',
        },
        {
            key: 'city',
            condition: !city,
            message: 'my-account__error__blank-company-city',
        },
        {
            key: 'countryId',
            condition: !countryId,
            message: 'admin__account__section__form--validation--email-confirm',
        },
        {
            key: 'phoneNumber',
            condition: !phoneNumber || !phoneRegExp.test(phoneNumber),
            message: 'my-account__error__blank-company-phone',
        },
        {
            key: 'regionId',
            condition: !regionId && !regionName,
            message: 'my-account__error__blank-company-state',
        },
        {
            key: 'zipcode',
            condition: !zipcode,
            message: 'my-account__error__blank-company-zipcode',
        },
        {
            key: 'zipcodeInvalid',
            condition: countryId === 1 && !zipRegExp.test(zipcode),
            message: 'my-account__error__invalid-company-zipcode',
        },

    ];

    const formError = formErrorsConditions.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );

    return formError;
};

// form fields mapped to multiple validation messages
export const validationFieldsMessagesMap = {
    companyName: ['companyName'],
    addressLine1: ['addressLine1'],
    city: ['city'],
    countryId: ['countryId'],
    phoneNumber: ['phoneNumber'],
    regionId: ['regionId'],
    zipcode: ['zipcode', 'zipcodeInvalid'],
};
