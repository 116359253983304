import { combineReducers } from 'redux'
import storeDetails from './storeDetails'
import viewDetails from './viewDetails'
import masterSettings from './masterSettings'
import systems from './systems'
import headers from './headers'
import StorePopupDetails from './storePopupReducer'
import users from './users'
import roles from './roles'
import dashboard from './dashboard'
import permissions from './permissions'
import country from './country'
import brands from './brands'
import userAccounts from './userAccounts'
import region from './region'
import distributor from './distributor'
import displaysetting from './displaysettings'
import storeRanking from './storeRanking'
import leaderboard from './leaderboard'
import companyLBVersion from './companyLBVersion'
import accountSubscriptionType from './accountSubscriptionType'
import leaderboardGroups from './leaderBoardGroups'
import dynamicHomeScreen from './dynamicHomeScreen'

export default combineReducers({
  storeDetails,
  viewDetails,
  StorePopupDetails,
  systems,
  masterSettings,
  headers,
  users,
  roles,
  dashboard,
  permissions,
  country,
  brands,
  userAccounts,
  region,
  distributor,
  displaysetting,
  storeRanking,
  leaderboard,
  companyLBVersion,
  accountSubscriptionType,
  leaderboardGroups,
  dynamicHomeScreen
})
