
/* AddDeviceComponent */
import { CommonConstants } from '../../../Constants'
// CoffeeTable component definition of header columns for main table
import {SORT_STRING, SORT_VERSION} from '../../Common/CoffeeTable/CoffeeTable'
export const tableHeaders = [
  {columnId: 'SettingTemplateID', label: '', sortType: undefined}, // NOTE: This column will be designated for selection radio buttons
  {columnId: 'SettingName', label: 'Name', sortType: SORT_STRING},
  {columnId: 'Description', label: 'Description', sortType: SORT_STRING},
  {columnId: 'SettingsVersion', label: 'Settings Version', sortType: SORT_VERSION},
  {columnId: 'SourceLaneConfig', label: 'Lane Type', sortType: SORT_STRING},
  {columnId: 'SourceDeviceType', label: 'Source Device Type', sortType: SORT_STRING},
  {columnId: 'SourceSN', label: 'Source Device Serial #', sortType: SORT_STRING},
]

export const devicesToAdd = [
  {Device_Name: CommonConstants.deviceType.zoom.name, deviceType: 'zoom'},
  {Device_Name: CommonConstants.deviceType.zoomNitro.name, deviceType: 'zoomNitro'},
  {Device_Name: CommonConstants.deviceType.nexeo.name, deviceType: 'nexeo'},
]

/* /AddDeviceComponent */