import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent as Input } from 'components/Inputs';
import { SINGLE_SELECT_VARIANTS, SingleSelect } from 'library/SingleSelect';

import './ContactInfo.css';

const selectVariants = [SINGLE_SELECT_VARIANTS.MOBILE_LABEL_INSIDE];

export const ContactInfo = ({ initialValues, countries, regions, formErrors = {}, onChange }) => {
    const [companyName, setCompanyName] = useState(initialValues.companyName || '');
    const [phoneNumber, setPhoneNumber] = useState(initialValues.phoneNumber || '');
    const [faxNumber, setFaxNumber] = useState(initialValues.faxNumber || '');
    const [city, setCity] = useState(initialValues.city || '');
    const [addressLine1, setAddressLine1] = useState(initialValues.addressLine1 || '');
    const [addressLine2, setAddressLine2] = useState(initialValues.addressLine2 || '');
    const [addressLine3, setAddressLine3] = useState(initialValues.addressLine3 || '');
    const [addressLine4, setAddressLine4] = useState(initialValues.addressLine4 || '');
    const [countryId, setCountryId] = useState(initialValues.countryId || '');
    const [regionId, setRegionId] = useState(initialValues.regionId || '');
    const [regionName, setRegionName] = useState(initialValues.regionName || '');
    const [zipcode, setZipcode] = useState(initialValues.zipcode || '');

    const initialCountryId = useMemo(() => initialValues.countryId, []);
    const initialRegionId = useMemo(() => initialValues.regionId, []);
    const initialRegionName = useMemo(() => initialValues.regionName, []);

    const { t } = useTranslation();

    const countriesList = useMemo(
        () =>
            countries.map((country) => ({
                text: country.Name,
                value: country.Id,
            })),
        [countries],
    );

    const regionsList = useMemo(
        () =>
            regions.map((region) => ({
                text: region.Name,
                value: region.Id,
            })),
        [regions],
    );

    // reset region on changing country id
    useEffect(() => {
        if (countryId === initialCountryId) {
            setRegionId(initialRegionId);
            setRegionName(initialRegionName);
        } else {
            setRegionId(null);
            setRegionName('');
        }
    }, [countryId]);

    useEffect(() => {
        onChange({
            companyName,
            phoneNumber,
            faxNumber,
            city,
            addressLine1,
            addressLine2,
            addressLine3,
            addressLine4,
            countryId,
            regionId,
            regionName,
            zipcode,
        });
    }, [
        companyName,
        phoneNumber,
        faxNumber,
        city,
        addressLine1,
        addressLine2,
        addressLine3,
        addressLine4,
        countryId,
        regionId,
        regionName,
        zipcode,
    ]);

    return (
        <Section className="company-info-form-contact-info-section" title="my-account__contact-info">
            <div className="company-info-form-contact-info-area">
                <div className="company-info-form-contact-info-column">
                    <div className="hme-company-info-form-section">
                        <Input
                            value={companyName}
                            onChange={setCompanyName}
                            isRequired
                            maxLength={100}
                            label="common__company__name"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.companyName && <ErrorLabel>{t(formErrors.companyName)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <Input
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            isRequired
                            maxLength={100}
                            label="common__phone-number"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.phoneNumber && <ErrorLabel>{t(formErrors.phoneNumber)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <Input
                            value={faxNumber}
                            onChange={setFaxNumber}
                            maxLength={100}
                            label="common__fax-number"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.faxNumber && <ErrorLabel>{t(formErrors.faxNumber)}</ErrorLabel>}
                    </div>
                </div>
                <div className="company-info-form-contact-info-column">
                    <div className="hme-company-info-form-section">
                        <Input
                            value={addressLine1}
                            onChange={setAddressLine1}
                            isRequired
                            maxLength={100}
                            label="common__address-line--1"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.addressLine1 && <ErrorLabel>{t(formErrors.addressLine1)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <Input
                            value={addressLine2}
                            onChange={setAddressLine2}
                            maxLength={100}
                            label="common__address-line--2"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.addressLine2 && <ErrorLabel>{t(formErrors.addressLine2)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <Input
                            value={addressLine3}
                            onChange={setAddressLine3}
                            maxLength={100}
                            label="common__address-line--3"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.addressLine3 && <ErrorLabel>{t(formErrors.addressLine3)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <Input
                            value={addressLine4}
                            onChange={setAddressLine4}
                            maxLength={100}
                            label="common__address-line--4"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.addressLine4 && <ErrorLabel>{t(formErrors.addressLine4)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <Input
                            value={city}
                            onChange={setCity}
                            isRequired
                            maxLength={100}
                            label="common__city"
                            variants={['label-inside-mobile-only']}
                        />
                        {formErrors.city && <ErrorLabel>{t(formErrors.city)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-section">
                        <SingleSelect
                            isRequired
                            value={countryId}
                            placeholder={t('common__select-country')}
                            label={t('common__country')}
                            onChange={setCountryId}
                            items={countriesList}
                            variants={selectVariants}
                        />
                        {formErrors.countryId && <ErrorLabel>{t(formErrors.countryId)}</ErrorLabel>}
                    </div>
                    <div className="hme-company-info-form-area">
                        <div className="hme-company-info-form-section hme-company-info-form-section--region">
                            {countryId === 1 ? (
                                <SingleSelect
                                    isRequired
                                    value={regionId}
                                    placeholder={t('common__select-your-state')}
                                    label={t('common__region')}
                                    onChange={setRegionId}
                                    items={regionsList}
                                    variants={selectVariants}
                                />
                            ) : (
                                <Input
                                    value={regionName}
                                    onChange={setRegionName}
                                    isRequired
                                    maxLength={100}
                                    label="common__region"
                                    variants={['label-inside-mobile-only']}
                                />
                            )}
                            {formErrors.regionId && <ErrorLabel>{t(formErrors.regionId)}</ErrorLabel>}
                        </div>
                        <div className="hme-company-info-form-section hme-company-info-form-section--zip">
                            <Input
                                value={zipcode}
                                onChange={setZipcode}
                                isRequired
                                maxLength={100}
                                label="common__zip"
                                variants={['label-inside-mobile-only']}
                            />
                            {formErrors.zipcode && <ErrorLabel>{t(formErrors.zipcode)}</ErrorLabel>}
                            {!formErrors.zipcode && formErrors.zipcodeInvalid && (
                                <ErrorLabel>{t(formErrors.zipcodeInvalid)}</ErrorLabel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};