import { useState } from 'react';

import { ViewEdit } from '../../Common';
import { defaultPartner } from '../../Common/constants/deaultPartner';
import { useCreatePartner } from './hooks/useCreatePartner';

export const CreatePartner = () => {
    const [partner, setPartner] = useState(defaultPartner);
    const {
        countries,
        regions,
        isLoading,
        isRegionsLoading,
        types,
        onCancel,
        onSave
    } = useCreatePartner(partner);

    return (
        <ViewEdit
            partner={partner}
            originPartner={defaultPartner}
            types={types}
            countries={countries}
            regions={regions}
            isLoading={isLoading}
            isRegionsLoading={isRegionsLoading}
            showStatus={false}
            onChange={setPartner}
            onCancel={onCancel}
            onSave={onSave}
        />
    );
};