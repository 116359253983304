/* eslint-disable require-jsdoc */
/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';
import PropTypes from 'prop-types';
import LBGroupTable from './LBGroupTable.js';
import SelectStores from './SelectStoresSection.js';
import LeaderboardMembers from './ParticipatingAccounts.js';
import ErrorAlert from '../Alerts/ErrorAlert';
import AuthenticationService from '../Security/AuthenticationService';
import { Link } from 'react-router-dom';
import { Config } from '../../Config';
import { CommonConstants } from '../../Constants';
import 'url-search-params-polyfill';
import Api from '../../Api';
import '../Group/ReportGroup.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { CenterLoader } from 'components/Common/CenterLoader';
import { get, save, remove, getCompanyDetail, setInviteDetails, inviteLBGroupUser, getLBGroupUsers, deleteLBGroupUser } from '../../actions/leaderboardGroup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'library/Modal';
import { getEmailIdCount } from './helpers/formatting';
import * as _ from 'underscore';
import './LeaderBoardGroup.scss';
import './lbGroupPage.css';
import { Footer } from './Footer';
import { Select } from 'library/Select';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { InputComponent } from '../Inputs';
import { Button } from 'library/Button';
import { TemplatePreview } from 'components/LeaderBoard/ProcessTemplates';
import { ReactComponent as IconPreview } from 'assets/icons/IconPreview.svg';
import { addErrorNotification, addSuccessNotification, dismissNotification } from 'services/Notifications';
import { NotificationsList } from 'library/NotificationsList';
import classNames from 'classnames';
import { withReactRouter } from 'HOCs/withReactRouter';
import { format, FORMAT_TYPES } from 'services/Date';
import { COMMON_ROUTES, PUBLIC_ROUTES } from 'constants/routes';

export const successTKey = {
    'recordsaved': 'common__record-saved',
    'inviteSuccess': 'add-leaderboard__success--invite'
};

export const errorTKey = {
    'GroupNameTaken': 'add-leaderboard__error--name-exist',
    'Status is required': 'add-leaderboard__error--empty-status',
    'Company not found': 'add-leaderboard__error--no-company',
    'Store list mismatch': 'add-leaderboard__error--store-list-mismatch',
    'Update failed': 'add-leaderboard__error--update-failed',
    'Company not found in Azure': 'add-leaderboard__error--no-azure-company',
    'Create Group failed': 'add-leaderboard__error--failed',
    'Store Group failed': 'add-leaderboard__error--store-failed',
    'requiredGroupUID': 'common__error--group-id-invalid',
    'companyExistsInLB': 'add-leaderboard__error-company-exists-in-lb',
    'companyDeniedInLB': 'add-leaderboard__error-company-denied',
    'companyPendingInLB': 'add-leaderboard__error-company-pending',
    'inviteFailed': 'add-leaderboard__error-invite-failed',
    'noEditLeaderboardPermission': 'add-leaderboard__error-no-edit-permission',
    'sameCompanyInvite': 'add-leaderboard__error-same-company',
    'noLeaderboardSubscription': 'add-leaderboard__error-no-lb-subscription',
    'notfound': 'add-leaderboard__error-email-not-found'
};

const LEADERBOARD_NAME_MAX_LENGTH = 30;

const { subscriptionLevels, apiUrls } = CommonConstants;

/**
 * Leaderboard Group Component
 */
class LeaderBoardGroup extends React.Component {
    /**
     * LeaderboardGroup component's contructor,
     * Set initial state
     * Bind function's instance to global instance
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            available: [],
            assigned: [],
            counter: 0,
            successMessage: '',
            errorMessage: '',
            isEdit: false,
            isAdmin: false,
            accountId: 100,
            GroupUID: null,
            isDefault: null,
            GroupNotSelectedError: '',
            saveSuccess: false,
            deleteSuccess: false,
            showCommonLoader: false,
            dbAssignedStoreUIDs: [],
            showStoreAssignment: false,
            showStoreAssignmentConfirm: false,
            companyDetails: {},
            selectedGroupID: '',
            showMultipleEmailsError: false,
            inviteeEmail: '',
            availableStores: [],
            groupMembers: [],
            inviteErrorMessage: '',
            defaultGroupId: null,
            isInvite: null,
            groupStoreList: null,
            checkedStores: [],
            treeList: [],
            DefaultGroupName: '',
            isAllLBGroupSelected: false,
            isNitroAccount: 0,
            isStoreChanged: false,
            lbSettingTemplates: [],
            lbSettingTemplateID: null,
            stepOneCompleted: false,
            stepTwoCompleted: false,
            groupName: null,
            groupDesc: null,
            formChanged: false,
            lbSettingTemplateName: true,
            groupDetails: null,
            noStoreSelectedPopup: false,
            editLBSavePopup: false,
            showInviteConfirm: false,
            showTemplatePreview: false,
            lbTemplatesData: [],
            lbTemplateUID: null
        };

        this.api = new Api();
        this.authService = new AuthenticationService(Config.authBaseUrl);
        this.renderButtonGroups = this.renderButtonGroups.bind(this);
        this.onSelectedItemsChange = this.onSelectedItemsChange.bind(this);
        this.makeTreeStructure = this.makeTreeStructure.bind(this);
        this.handleChangeGroupName = this.handleChangeGroupName.bind(this);
        this.handleChangeGroupDesc = this.handleChangeGroupDesc.bind(this);
        this.handleChangeInviteeEmail = this.handleChangeInviteeEmail.bind(this);
        this.onHide = this.onHide.bind(this);
        this.saveAssigned = this.saveAssigned.bind(this);
    }

    /**
     * Using React lifescycle method and set the default state
    */
    UNSAFE_componentWillMount() {
        this.setState({ showCommonLoader: true });

        const { queryParams, navigate } = this.props;
        const GroupUID = queryParams.get('GroupUID');
        const isDefault = queryParams.get('IsDefault') ? parseInt(queryParams.get('IsDefault')) : null;
        const isAdmin = queryParams.get('ia');
        const isInvite = queryParams.get('invite');

        this.userContext = this.authService.getProfile();

        this.setState({ GroupUID, isDefault, isAdmin, isInvite });
        if (GroupUID) {
            this.setState({ isEdit: true });
            this.populateGroupDetails(GroupUID);
            this.props.getLBGroupUsers(GroupUID, (data) => {
                if (data.status) {
                    const currentParticipant = data.data.find((member) => member.CompanyUID === this.userContext.Company_UID);
                    const isOwner = currentParticipant && currentParticipant.Status.toLowerCase() === 'owner';
                    const isParticipant = currentParticipant && currentParticipant.Status.toLowerCase() !== 'owner';

                    if (isOwner || isParticipant) {
                        this.setState({
                            showLoader: 'hidden',
                            groupMembers: data
                        });
                    } else if (isInvite && !isParticipant) {
                        setTimeout(() => {
                            addErrorNotification(
                                'leaderboard__template__error__no-access',
                                {
                                    autoClose: 5000
                                }
                            );
                        }, 1000);
                        navigate(`/${PUBLIC_ROUTES.lbGroups}`, { replace: true });
                    } else {
                        navigate(`/${COMMON_ROUTES.forbidden}`, { replace: true });
                    }
                } else {
                    console.log('Request failed.');
                }
            });
        }

        this.getCompanyUUID();
        this.isMasquerade = this.authService.isMasquerade();
        const { IsNitro: isNitroEnabled } = this.userContext;
        // change default checkbox state only for new LB
        this.setState({ isNitroAccount: isNitroEnabled });
    }

    /**
     * Get Company Details by UUID
     * Set received data to state
     */
    getCompanyUUID() {
        this.props.getCompanyDetail((data) => {
            if (data.status === false) {
                this.setState({ successMessage: data.data, errorMessage: '' });
            } else {
                this.setState({ companyDetails: data });
                this.getAvailableGroupStoreList(data.companyUId);
                this.getLBSettingTemplates(data.companyUId);
            }
        });
    }

    /**
     * Get LBSetting Templates From API and set to state
     * @param {string} companyUUID
     */
    getLBSettingTemplates(companyUUID) {
        this.setState({ available: [] });

        const url = `${Config.apiBaseUrl}${apiUrls.getLBSettingTemplates}?companyUID=${companyUUID}`;
        this.api.getData(
                url,
                (result) => {
                    if (result.status == true && result.data) {
                        this.setState({
                            lbSettingTemplates: _.map(result.data, (template) => ({ text: template.TemplateName, value: template.LBSettingTemplateID })),
                            lbTemplatesData: result.data
                        });

                        // Associate LB's with default template by for 3x accounts. 3x accounts can't use templates and are allowed only to customize the default one.
                        if (!this.state.isNitroAccount && result.data[0] && result.data[0].LBSettingTemplateID) {
                            this.setState({
                                lbSettingTemplateID: result.data[0].LBSettingTemplateID,
                                formChanged: true,
                                stepTwoCompleted: true
                            });
                        }
                    }
                },
                (error) => {
                    this.setState({ showCommonLoader: false, successMessage: '', errorMessage: error.message });
                }
        );
    }

    /**
     * Get Available Groups From API and set to state
     * @param {string} companyUUID
     */
    getAvailableGroupStoreList(companyUUID) {
        const { t } = this.props;
        this.setState({ available: [] });
        const url = Config.apiBaseUrl + apiUrls.getLBgroupsAvailable + '/' + companyUUID;
        this.api.getData(
                url,
                (data) => {
                    const GroupUID = this.state.GroupUID;
                    let available = data.data ? data.data : [];
                    // filter the available list so that it displays only comapany stores
                    available = _.filter(available, function(store) {
                        if (store.CompanyUID === null) {
                            store.disabled = true;
                        }
                        return store.CompanyUID === companyUUID || store.CompanyUID === null;
                    });
                    this.setState({ available });
                    if (GroupUID != null) {
                        // filter the available list so that it displays only other LB group stores(not including the current LBGroup)
                        const self = this;
                        const storeUIDs = _.filter(available, function(store) {
                            if (store.isDefault) {
                                store.GroupName += ' (Default)';
                                self.setState({ defaultGroupId: store.GroupUID });
                                self.setState({ DefaultGroupName: store.GroupName });
                            }
                            return store.GroupUID !== GroupUID;
                        });
                        this.setState({ available: storeUIDs });
                        this.makeTreeStructure(storeUIDs);
                    } else {
                        this.makeTreeStructure(available);
                        this.setState({ DefaultGroupName: _.find(available, (grp) => {
                            return grp.isDefault;
                        }).GroupName });
                    }
                    this.setState({ showCommonLoader: false });
                },
                (error) => {
                    this.setState({ showCommonLoader: false, successMessage: '', errorMessage: error.message });
                }
        );
    }

    /**
     * Convert Array to Tree data structure and set to state
     * @param {*} available
     */
    makeTreeStructure(available) {
        console.time();

        const res = _.chain(available)
                .sortBy('StoreName')
                .sortBy((str) => parseInt(str.StoreNumber))
                .groupBy('GroupName')
                .map((val, key) => {
                    if (!_.some(val, (store) => {
                        return store.disabled;
                    })) {
                        const dummyField = { CompanyUID: null, GroupName: key, GroupID: val[0].GroupID, isDefault: val[0].isDefault, disabled: true, StoreUID: null, StoreName: null, StoreNumber: null };
                        this.state.available.push(dummyField);
                    }
                    return {
                        GroupName: key,
                        GroupID: val[0].GroupID,
                        isDefault: val[0].isDefault,
                        Stores: val
                    };
                })
                .sortBy((str) => str.GroupName.toLowerCase())
                .sortBy((str) => !str.isDefault)
                .value();

        this.setState({ treeList: res, checkedStores: [] });
        console.timeEnd();
    }

    /**
     * Get Group Details from API and set to state
     * @param {string} GroupUID
     */
    populateGroupDetails(GroupUID) {
        GroupUID = GroupUID === undefined ? this.state.GroupUID : GroupUID;

        if (GroupUID) {
            this.props.get(GroupUID, (data) => {
                if (data.status === false) {
                    this.setState({ successMessage: data.data, errorMessage: '', assigned: [] });
                } else {
                    const groupDetails = data.groupDetails[0];
                    this.setState({
                        groupName: groupDetails.GroupName,
                        groupDesc: groupDetails.GroupDesc,
                        dbAssignedStoreUIDs: data.storesInGroup,
                        assigned: data.storesInGroup,
                        lbSettingTemplateID: groupDetails.LBSettingTemplateID,
                        lbSettingTemplateName: groupDetails.TemplateName,
                        groupDetails
                    });
                }
            });
        } else {
            this.setState({
                groupName: '',
                groupDesc: ''
            });
        }
    }

    /**
     * Render button groups template
     * @param {*} assigned
     * @return {JSX} buton group template
     */
    renderButtonGroups(assigned) {
        const { t } = this.props;
        return (
            <div className="row reportgroup-buttons">
                <div className="col-12 group-btns">
                    <div>
                        <button type="button" className="btn btn-primary save-group-btn" onClick={this.checkStoreSelection.bind(this, assigned)}>
                            {t('common__save')}
                        </button>
                    </div>
                    <div>
                        <span className={'btn-pipe'}> | </span>
                    </div>
                    <div>
                        <Link to={`/${PUBLIC_ROUTES.lbGroups}`} className="reportgroup-cancel">
                            {t('common__cancel')}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Redirect to Leaderboard Settings Page
     */
    lbSettings() {
        window.location.href = '/leaderboard';
    }

    /**
     * Remove group from Available Stores List and
     * add to Participating Stores List
     * @param {*} source
     * @param {*} target
     */
    moveToRight(source, target) {
        let index = 0;
        while (index < source.length) {
            const item = source[index];
            item.selected = false;
            if (_.contains(this.state.checkedStores, item.StoreUID)) {
                target.push(item);
                source.splice(index, 1);
            } else {
                index++;
            }
        }
    }

    /**
     * Remove Store from Participating Stores List and add to Available Store
     * @param {*} source
     * @param {*} target
     */
    moveToLeft(source, target) {
        let index = 0;
        while (index < source.length) {
            const item = source[index];
            if (item.selected) {
                item.selected = false;
                if (item.IsLBGroupStore != null && item.IsLBGroupStore === 1) {
                    // console.log("This is Current LB Store");
                    item.GroupName = this.state.DefaultGroupName;
                    item.isDefault = 1;
                }
                target.push(item);
                source.splice(index, 1);
            } else {
                index++;
            }
        }
    }

    /**
     * Mark store as selected
     * @param {object} store
     */
    onSelectedItemsChange(store) {
        const { assigned = [] } = this.state;
        const newAssigned = [...assigned];
        const currentItem = newAssigned.find((item) => item.StoreUID === store.StoreUID);
        if (currentItem) {
            currentItem.selected = !currentItem.selected;
            this.setState({ assigned: newAssigned });
        }
    }

    /**
     * Mark all stores as selected
     * @param {Event} event
     * @param {Array} list
     */
    selectAll(event, list) {
        const { target: { checked } } = event;
        list.map((item, index) => {
            if (item.CompanyUID === this.state.companyDetails.companyUId) {
                item.selected = checked;
            }
        });

        this.setState({ ...this.state, isAllLBGroupSelected: checked } );
    }

    /**
     * Add store to participating store
     */
    add() {
        this.setState({ isStoreChanged: true });
        const { checkedStores, available, assigned } = this.state;
        if (checkedStores.length != 0) {
            const newAvailable = [...available];
            const newAssigned = [...assigned];
            this.moveToRight(newAvailable, newAssigned);
            this.makeTreeStructure(newAvailable);
            this.setState({ available: newAvailable, assigned: newAssigned, formChanged: true });
        }
    }

    /**
     * Remove store from Participating Stores
     */
    remove() {
        this.setState({ isStoreChanged: true });
        if (_.some(this.state.assigned, (store) => {
            return store.selected;
        })) {
            const { assigned, available } = this.state;
            const newAssigned = [...assigned];
            const newAvailable = [...available];
            this.moveToLeft(newAssigned, newAvailable);
            this.makeTreeStructure(newAvailable);
            document.getElementById('assigned').checked = false;
            this.setState({ assigned: newAssigned, available: newAvailable, formChanged: true });
        }
    }

    /**
     * Function to display popup if there are no company stores selected
     * @param {Array} items
     */
    checkStoreSelection(items) {
        console.log('>>>>>>>>>>>>Saved List: ', items);
        const { isEdit } = this.state;
        const isStoreSelected = _.some(items, (item) => {
            return item.CompanyUID === this.state.companyDetails.companyUId;
        });
        console.log('Store selected in LB: ', isStoreSelected);
        if (!isStoreSelected) {
            this.setState({ noStoreSelectedPopup: true });
        } else {
            this.setState({ editLBSavePopup: true });
            if (!isEdit) {
                this.saveAssigned(items);
            }
        }
    }

    /**
   * Saves assigned stores
   * @param {Array} items
   */
    saveAssigned(items) {
        dismissNotification();
        let { groupName, groupDesc, isEdit } = this.state;
        this.setState({ showCommonLoader: true });
        const availList = _.filter(this.state.available, (store) => {
            return store.IsLBGroupStore === 1;
        });
        const { t, navigate } = this.props;
        groupName = groupName.trim();

        const storeUIDS = _.pluck(items, 'StoreUID');

        if (groupName === '' || groupName === undefined) {
            this.setState({
                errorMessage: 'add-group__error--empty-group-name',
                successMessage: '',
                showCommonLoader: false });
        } else {
            let data = {};
            const { isStoreChanged } = this.state;
            data = {
                GroupUID: this.state.GroupUID ? this.state.GroupUID : 0,
                storeUids: storeUIDS,
                groupName,
                groupDesc,
                isActive: 1,
                lastUpdatedBy: this.userContext.User_EmailAddress,
                companyUID: this.state.companyDetails.companyUId,
                defaultGroupId: this.state.defaultGroupId,
                defaultStoreUIDs: _.pluck(availList, 'StoreUID'),
                isMasquerade: this.isMasquerade,
                isStoreChanged,
                lbSettingTemplateID: this.state.lbSettingTemplateID
            };
            this.props.save(data, (data) => {
                console.log(data);
                if (data.status) {
                    this.setState({
                        successMessage: t(successTKey[data.key] || data.key),
                        errorMessage: '',
                        saveSuccess: true
                    });
                    navigate(`/${PUBLIC_ROUTES.lbGroups}`, { state: {} });
                    window.location.href = `/${PUBLIC_ROUTES.lbGroups}?statusId=3&isEdit=${isEdit}`;
                } else {
                    console.log('Error: ', data.key);

                    this.setState({
                        showCommonLoader: false,
                        successMessage: ''
                    });

                    addErrorNotification(errorTKey[data.key]);
                }
            });
        }
    }

    /**
   * Handler for form control change
   * @param {Event} e
   */
    handleFormChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
   * #region render calls for Group members table and it's associated popups
   * @return {JSX} Modal template
   */
    renderInviteModal() {
        const { inviteeEmail, showInviteConfirm } = this.state;
        const { t } = this.props;
        const showInvite = this.state.showInvite;
        const closeModal = () =>
            this.setState({
                showInvite: false,
                showLoader: 'hidden',
                showMultipleEmailsError: false,
                inviteeEmail: null,
                inviteErrorMessage: null,
                showInviteConfirm: false
            });
        const sendInvite = async () => {
            this.setState({ showLoader: 'visible' });
            this.setState({ inviteErrorMessage: '' });
            let invalidEmail = true;

            if (this.state.inviteeEmail === null || this.state.inviteeEmail === undefined || this.state.inviteeEmail === '') {
                this.setState({
                    inviteErrorMessage: 'add-leaderboard__error--invalid-email' });
                invalidEmail = true;
            } else {
                invalidEmail = false;
            }

            let repeatFlag = false;
            const groupMembers = this.state.groupMembers.data;

            repeatFlag = _.some(groupMembers, (member) => {
                return member.UserEmail === this.state.inviteeEmail;
            });
            console.log('Repeat val: ', repeatFlag);

            // Check if invitation input contains multiple email ids.
            // Multiple email id are not allowed for now, so show an error, BUG #30769.
            let showMultipleEmailsError = false;
            const emailIdCount = getEmailIdCount(this.state.inviteeEmail);
            showMultipleEmailsError = emailIdCount > 1 ? true: false;
            this.setState({ showMultipleEmailsError });

            if (repeatFlag) {
                this.setState({ inviteErrorMessage: 'add-leaderboard__error--invited-already' });
            }
            if (invalidEmail) {
                this.setState({ inviteErrorMessage: 'add-leaderboard__error--invalid-email' });
            }

            if (!invalidEmail && !repeatFlag && !showMultipleEmailsError) {
                this.props.setInviteDetails({
                    UserEmail: this.state.inviteeEmail,
                    GroupUID: this.state.GroupUID,
                    InvitedBy_UserUID: this.state.uuid,
                    CompanyUID: this.state.companyDetails.companyUId
                });

                await this.props.inviteLBGroupUser((res) => {
                    console.log('Invite: ', res);

                    if (res.status) {
                        dismissNotification();
                        addSuccessNotification('add-leaderboard__success--invite');
                        this.props.getLBGroupUsers(this.state.GroupUID, (data) => {
                            if (data.status) {
                                this.setState({
                                    showLoader: 'hidden',
                                    groupMembers: data
                                });
                            } else {
                                console.log('Request failed.');
                            }
                        });
                        this.setState({
                            successMessage: t(successTKey[res.key]),
                            errorMessage: '',
                            showLoader: 'hidden',
                            showInvite: false,
                            inviteeEmail: null
                        });
                    } else {
                        console.log('Error: ', t(errorTKey[res.key]));

                        this.setState({
                            showCommonLoader: false,
                            inviteErrorMessage: errorTKey[res.key],
                            successMessage: ''
                        });
                    }
                });
            }
            this.setState({ showInviteConfirm: false });
        };

        return (
            <Modal show={showInvite} title={t('add-leaderboard__invite-participant')} onHide={closeModal} dialogClassName="invite confirm-popup">
                <div className={'render-invite-success ' + (this.state.GroupNotSelectedError ? 'hidden' : '')}>
                    <span className="invite-text">{t('add-leaderboard__enter-participant')}</span>
                    <ErrorAlert className="mt-4 invite-error-msg" errorMessage={this.state.inviteErrorMessage} />
                    <InputComponent
                        className={`mt-4 invite-input-email ${this.state.inviteErrorMessage ? 'border-red' : ''}`}
                        placeholder={t('add-leaderboard__invite-email-placeholder')}
                        value={inviteeEmail}
                        onChange={this.handleChangeInviteeEmail}
                    />
                    <div className="mt-4">
                        <Button className={'ml-2 me-2'} onClick={closeModal}>
                            {t('common__cancel')}
                        </Button>

                        <Button variants={['submit']} disabled={!showInviteConfirm} onClick={sendInvite}>
                            {t('add-leaderboard__send-invite')}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    /**
   * Set invite modal shown
   */
    toggleInviteModal() {
        this.setState({ showInvite: true });
    }

    /**
   * Get users assigned to leaderboard group
   */
    getUsers() {
        if (this.state.isEdit) {
            this.props.getLBGroupUsers(this.state.GroupUID, (data) => {
                if (data.status) {
                    this.setState({ groupMembers: data });
                    this.setState({ showLoader: false });
                } else {
                }
            });
        }
    }

    handleChangeGroupName(event) {
        const groupName = event;
        this.setState({
            groupName,
            formChanged: true,
            stepOneCompleted: groupName && groupName.length > 2 });
    }

    handleChangeGroupDesc(event) {
        this.setState({
            groupDesc: event.target.value,
            formChanged: true
        });
    }

    handleChangeInviteeEmail(event) {
        const inviteeEmail = event.trim();
        // email reg-ex validation check
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
        this.setState({
            inviteeEmail,
            showInviteConfirm: regex.test(inviteeEmail)
        });
    }

    renderSectionOne = () => {
        const { t } = this.props;
        const { isNitroAccount, isEdit, isAdmin, groupName, groupDesc } = this.state;
        const sectionOneHeaderForCreate = isNitroAccount ? `1. ${t('add-leaderboard__first-section__title')}` : t('add-leaderboard__first-section__title');
        return (
            <div className="">
                <p className="leaderboard-section-header mb-5">{isEdit ? t('common__general') : sectionOneHeaderForCreate}</p>

                <section className={(this.state.saveSuccess || this.state.deleteSuccess ? 'hide' : 'show')}>

                    <div className="">
                        <div className="">
                            <label htmlFor="groupName" className="leaderboard-input-label">
                                <b className="text-uppercase">{t('common__lb__name')}</b>
                            </label>
                        </div>
                        <div className={isEdit && isAdmin != 'true' ? 'hide' : 'show'}>
                            <InputComponent
                                value={groupName}
                                onChange={this.handleChangeGroupName}
                                isRequired
                                maxLength={LEADERBOARD_NAME_MAX_LENGTH}
                                showRemaining={true}
                            />
                        </div>
                        <div className={isAdmin == 'false'? 'show': 'hide'}>
                            <span className="leaderboard-info-text">
                                {groupName}
                            </span>
                        </div>
                    </div>
                    <div className="inputs-wrapper mt-4">
                        <div className="">
                            <label htmlFor="groupName" className="leaderboard-input-label">
                                <b className="text-uppercase">{t('common__lb__description')}</b>
                            </label>
                        </div>
                        <div className={isEdit && isAdmin != 'true' ? 'hide' : 'show '}>
                            <textarea
                                rows="4"
                                defaultValue={groupDesc}
                                onChange={this.handleChangeGroupDesc}
                                cols="53"
                                className="leaderboard-desc-textbox hme-input-component"
                                maxLength="200"
                                disabled={isEdit && isAdmin != 'true' ? 'disabled' : ''}
                            />
                        </div>
                        <div className={isAdmin == 'false'? 'show': 'hide'}>
                            <span className="leaderboard-info-text">
                                {groupDesc && groupDesc != '' ? groupDesc : `None`}
                            </span>
                        </div>
                    </div>
                    {this.renderSummarySection()}
                </section>
            </div>
        );
    };

    onChangeTemplate(e) {
        console.log(`change: `, e);
        this.setState({ lbSettingTemplateID: e,
            formChanged: true,
            stepTwoCompleted: e && e > 0 ? true : false });
    }

    getSectionTwoHeader(stepOneCompleted, isEdit, isNitroAccount) {
        const { t } = this.props;

        if (isNitroAccount) {
            return (<p className={'leaderboard-section-header mb-5'+ (stepOneCompleted || isEdit ? '':' text-muted')}>{isEdit ? t('common__template') : `2. ${t('add-leaderboard__second-section__title')}`}</p>);
        } else {
            return (<p className={'leaderboard-section-header mb-5'+ (isEdit ? '':' text-muted')}>{isEdit ? t('common__settings') : t('add-leaderboard__second-section__title')}</p>);
        }
    }


    getSectionThreeHeader(stepOneCompleted, stepTwoCompleted, isEdit, isNitroAccount) {
        const { t } = this.props;

        if (isNitroAccount) {
            return (
                <p className={`leaderboard-section-header mb-4 ${isEdit ? '' : 'padding-bottom-3'} ${(stepTwoCompleted || isEdit) ? '': 'text-muted'}`}>
                    {isEdit ? t('common__stores') : `3. ${t('add-leaderboard__third-section__title')}`}
                </p>
            );
        } else {
            return (<p className={`leaderboard-section-header mb-4 ${(!isEdit ? 'padding-bottom-3':'')} ${((stepOneCompleted && stepTwoCompleted) || isEdit ? '':'text-muted')}`}>{isEdit ? t('common__stores') : t('add-leaderboard__third-section__title')}</p>);
        }
    }


    renderSectionTwo = () => {
        const { t } = this.props;
        const { lbSettingTemplateName, stepOneCompleted, isEdit, isAdmin, lbSettingTemplateID, lbSettingTemplates, lbTemplatesData } = this.state;
        const lbSettingTemplate = lbTemplatesData.find( ({ LBSettingTemplateID }) => LBSettingTemplateID === lbSettingTemplateID );
        const lbSettingTemplateUID = lbSettingTemplate ? lbSettingTemplate.LBSettingTemplateUID : null;
        return (
            <div className="">
                {this.getSectionTwoHeader(stepOneCompleted, isEdit, this.state.isNitroAccount)}
                <section className={(stepOneCompleted || isEdit ? 'show' : 'hide' )}>

                    <div className="">
                        <label htmlFor="groupName" className="leaderboard-input-label text-uppercase">
                            <b>{t('add-leaderboard__template-label')}</b>
                        </label>
                    </div>
                    <div className={classNames('hme-create-edit-leaderboard-template-selection', isEdit && isAdmin != 'true' ? 'hide' : 'show')}>
                        <Select
                            isDisabled={!this.state.isNitroAccount}
                            isRequired={true}
                            value={lbSettingTemplateID}
                            placeholder={t('add-leaderboard__template-placeholder')}
                            onChange={(e) => this.onChangeTemplate(e)}
                            showTooltip
                        >
                            {lbSettingTemplates}
                        </Select>
                        {lbSettingTemplateUID && <div className={`preview-template-link mt-3 me-4 ${this.state.isNitroAccount ? '' : ' disable opacity-50'}`} onClick={() => {
                            this.setState({ lbTemplateUID: lbSettingTemplateUID, showTemplatePreview: true });
                        }}>
                            <IconPreview className="preview-icon me-2"/>
                            <a>{t('common__preview-template')}</a>
                        </div>}
                    </div>
                    <div className={isAdmin == 'false'? 'show': 'hide'}>
                        <span className="leaderboard-info-text">
                            {lbSettingTemplateName}
                        </span>
                    </div>
                    <div className={this.state.isNitroAccount ? 'hide':'show mt-5'}>
                        <div className="upgrade-to-nitro-message">
                            <Trans i18nKey="add-leaderboard__upgrade-to-nitro">
                                <div className="fleft icon-column me-3 bold">ⓘ</div>
                                <div className="">
                                    <p>Want to create your own templates?</p>
                                    <p>Call to HME at <b>1 800 848 4468</b> to upgrade</p>
                                </div>
                            </Trans>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    renderSectionThree = () => {
        const { t } = this.props;
        const { isAllLBGroupSelected, stepTwoCompleted, stepOneCompleted, showCommonLoader, isEdit, isAdmin } = this.state;
        const isHidden = this.state.isDefault === 1 && isAdmin === 'true' ? ' hidden' : '';
        return (
            <div className="">
                {this.getSectionThreeHeader(stepOneCompleted, stepTwoCompleted, isEdit, this.state.isNitroAccount)}
                <section className={((stepOneCompleted && stepTwoCompleted) || isEdit ? 'show' : 'hide' )}>
                    <div className={this.state.isInvite === 'true' ? '' : 'hidden'}>
                        <label className={'new-msg'}>{t('add-leaderboard__select-stores')}</label>
                    </div>

                    <div className="leaderboard-store-selection">
                        {/* GroupTable renders all the stores from the current user account in an tree structure  */}
                        <SelectStores
                            isHidden={isHidden}
                            data={this.state.treeList}
                            // TODO: Use css text-transform for uppercase
                            title={t('add-leaderboard__available-stores')}
                            checkedStores={this.state.checkedStores}
                            setCheckedStores={(checkedStores) => this.setState({ checkedStores })}
                            inputStyles={{ search: 'col-5 form-control leaderboard-search-stores text-uppercase' }}
                        />
                        <div className={`col-1 leaderboard-select-move-stores ${this.state.isDefault === 1 && isAdmin === 'true' ? ' hidden' : ''}`}>
                            <div className="moveToHierarchy pull-center">
                                <button className="btn btn-secondary stores-transfer-btn" onClick={this.add.bind(this)}>
                                    <b>&gt;</b>
                                </button>
                            </div>
                            <div className="removeFromToHierarchy pull-center">
                                <button className="btn btn-secondary stores-transfer-btn" onClick={this.remove.bind(this)}>
                                    <b>&lt;</b>
                                </button>
                            </div>
                        </div>
                        {/* LBGroupTable renders the stores only from the current Leaderboard  */}
                        {/* isDefault is an flag used for displaying stores in an default group  */}
                        <LBGroupTable
                            // TODO: Use css text-transform for uppercase
                            title={t('add-leaderboard__participating-stores')}
                            items={this.state.assigned}
                            selectAll={(e, items) => this.selectAll(e, items)}
                            onChange={this.onSelectedItemsChange}
                            type="assigned"
                            company={this.state.companyDetails}
                            isAllSelected={isAllLBGroupSelected}
                            isDefault={this.state.isDefault === 1}
                            isHidden={isHidden}
                        />
                    </div>
                    {/* {this.renderButtonGroups(assigned)} */}

                    <div className={showCommonLoader === false && isEdit ? 'ctable' : 'hidden'}>
                        <div className="memberTableHeader">
                            <LeaderboardMembers
                                isAdmin={isAdmin}
                                members={this.state.groupMembers.data}
                                assignedStores={this.state.assigned}
                                isDefault={this.state.isDefault}
                                renderLBGroupDetails={this.populateGroupDetails.bind(this)}
                                GroupUID={this.state.GroupUID}
                                toggleInviteModal={this.toggleInviteModal.bind(this)}
                                getMembers={this.getUsers.bind(this)}
                                CompanyUID={this.state.companyDetails.companyUId}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        {this.renderInviteModal()}
                    </div>
                </section>
            </div>
        );
    };

    /**
   * Mark store as changed and change a store
   * @param {*} fieldName
   * @return {JSX} value
   */
    renderSummarySection = () => {
        const { t } = this.props;
        const { groupDetails, isAdmin, isEdit } = this.state;
        return (
            <div className="mt-3 mb-2">
                <div className={(isEdit ? 'show' : 'hide' )}>
                    <div className="inputs-wrapper">
                        <label htmlFor="groupName" className="leaderboard-input-label">
                            {/* TODO: Use css text-transform for upper-case */}
                            <b className="text-uppercase">{t('common__summary')}</b>
                        </label>

                    </div>
                    <div className={'mt-1 mb-2'}>
                        <label className="participating-accounts-col text-uppercase">{t('common__role')} </label><br/>
                        <span className={`leaderboard-info-text`}>{isAdmin == 'true' ? t('common__owner') : t('add-leaderboard__participant')}</span>
                    </div>
                    {/* TODO: Use css text-transform for uppercase */}
                    <div className={`summary-info-container mt-1 mb-2 ${isAdmin == 'true' ? '' : 'hide'}`}><label className="participating-accounts-col text-uppercase">{t('add-leaderboard__created-by')}</label><br/>
                        <span className="leaderboard-info-text leaderboard-audit-info-text">
                            {groupDetails && groupDetails.CreatedBy}
                        </span>
                        <span className="leaderboard-info-text leaderboard-audit-info-text float-end">
                            {groupDetails ? format(FORMAT_TYPES.DATE_AND_TIME, groupDetails.CreatedAt) : ``}
                        </span>
                    </div>
                    {/* TODO: Use css text-transform for uppercase */}
                    <div className={`summary-info-container mt-1 mb-1 ${isAdmin == 'true' ? '' : 'hide'}`}><label className="participating-accounts-col text-uppercase">{t('add-leaderboard__updated-by')}</label><br/>
                        <span className="leaderboard-info-text leaderboard-audit-info-text">
                            {groupDetails && groupDetails.LastUpdatedBy}
                        </span>
                        <span className="leaderboard-info-text leaderboard-audit-info-text float-end">
                            {groupDetails ? format(FORMAT_TYPES.DATE_AND_TIME, groupDetails.LastUpdatedAt) : ``}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    onHide(fieldName) {
        this.setState({ [fieldName]: false });
    }
    /**
   * Main render function
   * @return {JSX} Component's template
   */
    render() {
        const { t } = this.props;
        const {
            isEdit,
            stepOneCompleted,
            noStoreSelectedPopup,
            editLBSavePopup,
            stepTwoCompleted,
            formChanged,
            assigned,
            showCommonLoader,
            isAdmin,
            lbTemplateUID,
            showTemplatePreview
        } = this.state;

        return (
            <div>
                <NotificationsList closeOnClick={false} draggable={false} />
                { showCommonLoader && <CenterLoader>{t('common__loading')}</CenterLoader>}
                {lbTemplateUID && <TemplatePreview
                    templateUID={lbTemplateUID}
                    show={showTemplatePreview}
                    onHide={this.onHide.bind(this, `showTemplatePreview`)}
                />}
                <section className={(showCommonLoader ? 'hide' : 'show LeaderBoardGroup groupDetailsPage')}>
                    <div className="">
                        <ErrorAlert errorMessage={this.state.errorMessage} />
                    </div>

                    <ConfirmPopupComponent
                        show={noStoreSelectedPopup}
                        message={<>
                            <div className="admin-create-apply-device-settings-task-filters-confirm-line">
                                {t('add-leaderboard__modal--no-stores__body')}
                            </div>
                            <div>{t('add-leaderboard__modal--no-stores__confirm')}</div>
                        </>}
                        onHide={this.onHide.bind(this, `noStoreSelectedPopup`)}
                        onConfirm={this.saveAssigned.bind(this, assigned)}
                        confirmationVerb={t('common__create')}
                    />
                    <ConfirmPopupComponent
                        title={t('add-leaderboard__modal--edit__title')}
                        show={isEdit && editLBSavePopup}
                        message={<>
                            <div className="admin-create-apply-device-settings-task-filters-confirm-line">
                                {t('add-leaderboard__modal--edit__body')}
                            </div>
                            <div>{t('add-leaderboard__modal--edit__confirm')}</div>
                        </>}
                        onHide={this.onHide.bind(this, `editLBSavePopup`)}
                        onConfirm={this.saveAssigned.bind(this, assigned)}
                        confirmationVerb={t('common__save')}
                    />
                    <div className="leaderboard-group-display ms-5 mt-4">
                        <div className={`${isEdit ? 'show' : 'hidden'}`}>
                            <h1 className="leaderboard-page-title">
                                {
                                  this.state.isDefault === 1 && isAdmin === 'true' ?
                                  t('add-leaderboard__title--edit-default') :
                                  t('add-leaderboard__title--edit')
                                }
                            </h1>
                        </div>
                        <div className={!isEdit ? 'show' : 'hidden'}>
                            <h1 className="leaderboard-page-title">{t('leaderboard__create-lb')}</h1>
                        </div>


                        <div className="leaderboard-group-columns mt-5">
                            <div className="leaderboard-group-column-1 me-3 padding-left-0">
                                { this.renderSectionOne() }
                            </div>
                            <div className={'leaderboard-group-column-2 ms-4 me-3 super-tall'}>
                                { this.renderSectionTwo() }
                            </div>
                            <div className={'leaderboard-group-column-3 ms-4 super-tall'}>
                                { this.renderSectionThree() }
                            </div>

                        </div>
                    </div>
                    <Footer
                        isSubmitEnabled={isEdit ? formChanged : stepTwoCompleted && stepOneCompleted}
                        onCancel={`/${PUBLIC_ROUTES.lbGroups}`}
                        onApply={this.checkStoreSelection.bind(this, assigned)}
                    />
                </section>

            </div>


        );
    }
}

LeaderBoardGroup.propTypes = {
    getLBGroupUsers: PropTypes.func,
    getCompanyDetail: PropTypes.func,
    get: PropTypes.func,
    save: PropTypes.func,
    setInviteDetails: PropTypes.func,
    inviteLBGroupUser: PropTypes.func,
    navigate: PropTypes.func,
    queryParams: PropTypes.object
};

/**
 * Map redux state to props
 * @param {*} state
 * @return {object} props with new fields from Redux state
 */
function mapStateToProps(state) {
    return {};
}

/**
 * Match dispatch actions to prop
 * @param {*} dispatch
 * @return {object} redux actions with wrapper dispatcher
 */
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                getCompanyDetail: getCompanyDetail,
                save: save,
                remove: remove,
                get: get,
                getLBGroupUsers,
                setInviteDetails,
                inviteLBGroupUser,
                deleteLBGroupUser
            },
            dispatch
    );
}
export default compose(
        connect(
                mapStateToProps,
                matchDispatchToProps
        ),
        withTranslation(),
        withReactRouter
)(LeaderBoardGroup);
