import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

// Actions
import * as actions from '../../actions/dynamicHomeScreen';

// Tiles
import { WelcomeTile } from '../Tiles/WelcomeTile';
import { DefaultQuickLinksTile, PartnerQuickLinksTile } from '../Tiles/QuickLinksTile';
import StoreRankingTile from '../Tiles/StoreRankingTile';
import { GetTraining } from '../Tiles/GetTraining';
import { AnnouncementTile } from '../Tiles/AnnouncementTile/AnnouncementTile';
import { getProfile, isPartner, isAdmin } from 'services/Auth';

// Permisions
import NavigationServices from '../Common/NavigationServices';

import './DynamicHomeScreen.css';

/**
 * @param {String} tile
 * @param {int} index
 * @return {JSX}
 */
class DynamicHomeScreen extends Component {
    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.navigation = new NavigationServices();

        this.state = {
            containerWidth: document.documentElement.clientWidth
        };
        const { SL: subscriptionType } = getProfile();
        this.isPartner = isPartner();
        this.isAdmin = isAdmin();
        this.subscriptionType = subscriptionType;
    }
    /**
     *
     */
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                containerWidth: this.gridContainer.clientWidth
            });
        },
        false
        );
    }

    /* eslint-disable */
    renderGridItem = (tile, index) => {
        /* eslint-enable */
        if (tile === 'Welcome') {
            return (
                <div key={index}>
                    <WelcomeTile />
                </div>);
        } else if (tile === 'GetTraning' && !this.isPartner) {
            return (
                <div key={index}>
                    <GetTraining />
                </div>
            );
        } else if (tile === 'QuickLinks') {
            return (
                <div key={index}>
                    {this.isPartner ? <PartnerQuickLinksTile /> : <DefaultQuickLinksTile />}
                </div>
            );
        } else if (tile === 'StorePerformance') {
            return <div key={index} />;
        } else if (tile === 'StoreRanking' && !this.isAdmin && (this.subscriptionType === 2 || this.subscriptionType === 6)) {
            return (
                <div key={index}>
                    <StoreRankingTile />
                </div>
            );
        } else if (tile === 'AnnouncementTile' && !this.isAdmin && !this.isPartner) {
            return (
                <div key={index}>
                    <AnnouncementTile />
                </div>
            );
        }
        return null;
    };

    /* eslint react/prop-types: 0 */
    /**
     *
     * @return {JSX}
     */
    render() {
        const { tiles } = this.props;
        const { containerWidth } = this.state;
        const tilesOrder = containerWidth > 620 ? tiles.desktop : tiles.mobile;

        return (
            <div
                className={classNames(
                    'dynamic-home__columns-layout',
                    {
                        'dynamic-home__columns-layout--stretched': this.isPartner
                    }
                )}
                ref={(element) => {
                    this.gridContainer = element;
                }}
            >
                {tilesOrder.map((tile, index) => {
                    return this.renderGridItem(tile, index);
                })}
            </div>
        );
    }
}
/**
 *
 * @param {*} state
 * @return {*}
 */
function mapStateToProps(state) {
    return {
        tiles: state.dynamicHomeScreen.tiles
    };
}

/**
 *
 * @param {*} dispatch
 * @return {*}
 */
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                getDefaultSetup: actions.getDefaultSetup
            },
            dispatch
    );
}

export default connect(
        mapStateToProps,
        matchDispatchToProps
)(DynamicHomeScreen);
