export const USERS_LIST_HEADERS = [
    {
        property: 'User_EmailAddress',
        text: 'common__user__username',
        sortable: true,
        flex: 1.75,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        property: 'User_FirstName',
        text: 'common__user__first-name',
        sortable: true,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        property: 'User_LastName',
        text: 'common__user__last-name',
        sortable: true,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        property: 'Company_Name',
        text: 'common__company__name',
        sortable: true,
    },
    {
        property: 'RoleText',
        text: 'common__role',
        sortable: true,
    },
    {
        property: 'Account_Email',
        text: 'common__account__email',
        sortable: true,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        property: 'Actions',
        text: 'common__actions',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-users--distributor-account--actions-cell',
    },
];
