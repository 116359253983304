import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

import './SendUpgradeConfirmPopup.scss';

const IB7000Key = NEXEO_PERIPHERAL_TYPES.IB7000.modelName;
const SM7000Key = NEXEO_PERIPHERAL_TYPES.SM7000.modelName;

export const SendUpgradeConfirmPopup = ({ onHide, onConfirm, upgradeTaskData, ...props }) => {
    let {
        deviceType = '',
        version = '',
    } = upgradeTaskData;

    // join SM7000 and IB7000 device types
    if (deviceType === IB7000Key) {
        deviceType = `${IB7000Key}/${SM7000Key}`;
    }

    const { t } = useTranslation();

    return (
        <ConfirmPopupComponent
            {...props}
            onHide={onHide}
            title={t('common__double-checking')}
            message={t('admin-system-status__action__send-upgrade--confirm-message', {
                deviceType,
                version,
                interpolation: { escapeValue: false },
            })}
            dialogClassName="hme-admin-system-status-upgrade-confirm"
            actions={[
                {
                    children: t('common__no-go-back'),
                    onClick: onHide,
                },
                {
                    children: t('common__continue'),
                    variants: ['submit'],
                    onClick: () => {
                        onConfirm && onConfirm();
                        onHide && onHide();
                    },
                },
            ]}
        />
    );
};