import * as React from 'react';
import { Tr, Td, Table } from 'react-super-responsive-table';
import { useTranslation } from 'react-i18next';

import LockDeviceSettingsTableHeader from './LockDeviceSettingsTableHeader';
import LockDeviceSettingsTableBody from './LockDeviceSettingsTableBody';

export const LockDeviceSettingsTable = (prop) => {
    const { settings, onSelect, onSort, ascending, sortBy, sortType, selectedItems } = prop;
    const { t } = useTranslation();

    const allDevices = settings.map((store) => store.Device_Details).flat();
    const totalDevices = allDevices.length;
    const isSelectedAll = selectedItems.length === totalDevices;
    const isSelected = selectedItems.length > 0;
    const toggleSelect = () => {
        onSelect(allDevices, !isSelectedAll);
    };

    return (
        <section className="stores">
            <div className="settings_list">
                <div className="storesTable ctable">
                    <div className="hme-table-container">
                        <Table>
                            <LockDeviceSettingsTableHeader
                                onSort={onSort}
                                ascending={ascending}
                                sortBy={sortBy}
                                sortType={sortType}
                                isSelectedAll={isSelectedAll}
                                isSelected={isSelected}
                                toggleSelect={toggleSelect}
                            />
                            {settings.length > 0 ? (
                                <LockDeviceSettingsTableBody
                                    collection={settings}
                                    onChange={onSelect}
                                    selectedItems={selectedItems}
                                />
                            ) : (
                                <Tr className="tdata clear">
                                    <Td colSpan="7" className="settings_table_noStores">
                                        {' '}
                                        <h3>{t('lock-device-settings__settings-lock-limit')}</h3>{' '}
                                    </Td>
                                </Tr>
                            )}
                        </Table>
                    </div>
                </div>
            </div>
        </section>
    );
};
