import React, { Component } from 'react'
import './Stores.css'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next';
import { bindActionCreators } from 'redux'
import { closePopup } from '../../actions/modalAction'
import { getCountryList } from '../../actions/country'
import { Config } from '../../Config'
import { CommonConstants } from '../../Constants'
import Api from '../../Api'
import AuthenticationService from '../Security/AuthenticationService'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Draggable from 'react-draggable'
import { checkAccess, getPermission } from '../../actions/permissions'
import { CountryOptions } from '../Common/Country'
import TimeZoneDropdown from './TimeZoneDropdown';
import PermissionSpecification from '../../helpers/PermissionSpecification';
import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';
import { filterAXNumberValue } from 'helpers/filterInputNumberValues';
import { validateAXAccountNumber } from 'helpers/Store/validateAXAccountNumber';

const R = require('ramda')
const { XmlUtilities } = require('@hme-cloud/utility-common');

const saveBtnStyle = {
  margin: '15px 0px 0px 0px'
}

const inputProps = {
  type: 'text',
  maxLength: '100',
  disabled: 'disabled'
};

const arrayOfInputNumbers = ['Store_AX_Customer_Account', 'Store_AX_Invoice_Account'];

// This componenet is used for the store detail popup and store detail page.
class StoreDetailsComponent extends Component {
  constructor (props) {
    super(props);

    this.state = {
      showStores: this.props.showStores,
      show: false,
      Store_Country_ID: 1,
      timeZones: [],
      Store_TZ: '',
      errorMessage: '',
      successMessage: '',
      Store_Name: '',
      isViewDetailsPage: false,
      suid: null,
      Store_AX_Customer_Account: '',
      Store_AX_Invoice_Account: '',
      Store_IsEVDSubscription: false,
      Store_IsNEXEOSubscription: false,
      hasRegisteredZoom: false,
    }

    this.api = new Api()
    this.authService = new AuthenticationService(Config.authBaseUrl)
    this.permissionSpecification = new PermissionSpecification(this.authService);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkUserUUID = this.checkUserUUID.bind(this)
    this.handleRemoveStore = this.handleRemoveStore.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    props.getPermission()
  }

  // Getting the country list from the service.
  componentDidMount () {
    this.props.getCountryList()
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.getAllTimeZones
    this.api.getData(url, result => {
      if (result.status) {
        this.setState({ timeZones: result.data })
      }
    })
  }

  // Reload the page.
  pageRefresh (){
    window.location.reload();
  }

  // Storing the props values in the state.
  UNSAFE_componentWillReceiveProps (newProps) {
    const { Device_Details: devices = [] } = newProps;
    const {
      Store_Name: storeName,
      Timezone: timeZone,
      Store_UID: suid,
      Store_AX_Customer_Account: AXCustomerAccount,
      Store_AX_Invoice_Account: AXInvoiceAccount,
      Store_IsEVDSubscription: IsEVDSubscription,
      Store_IsNEXEOSubscription: IsNEXEOSubscription,
    } = newProps.storesDetails;

    const {
      Store_Name: currStoreName,
      Store_TZ: currTimeZone,
      suid: currSuid,
      Store_AX_Customer_Account: currAXCustomerAccount,
      Store_AX_Invoice_Account: currAXInvoiceAccount,
      Store_IsEVDSubscription: currIsEVDSubscription,
      Store_IsNEXEOSubscription: currIsNEXEOSubscription,
      isViewDetailsPage,
      hasRegisteredZoom: currRegisteredZoom,
    } = this.state;

    const hasRegisteredZoom = this.checkRegisteredDevice('ZOOM', devices);
    const hasChanges =
      storeName !== currStoreName ||
      timeZone !== currTimeZone ||
      suid !== currSuid ||
      AXCustomerAccount !==  currAXCustomerAccount ||
      AXInvoiceAccount !== currAXInvoiceAccount ||
      IsEVDSubscription !== currIsEVDSubscription ||
      IsNEXEOSubscription !== currIsNEXEOSubscription ||
      hasRegisteredZoom !== currRegisteredZoom;

    if (hasChanges) {
      this.setState({
        Store_Name: storeName,
        Store_TZ: timeZone,
        suid,
        Store_AX_Customer_Account: AXCustomerAccount,
        Store_AX_Invoice_Account: AXInvoiceAccount,
        Store_IsEVDSubscription: IsEVDSubscription,
        Store_IsNEXEOSubscription: IsNEXEOSubscription,
        hasRegisteredZoom,
      })
    }

    if (this.props.location.pathname === CommonConstants.StoreSettingsPage && !isViewDetailsPage) {
      this.setState({ isViewDetailsPage: true })
    }
  }

  regExpStringFilter = (str, regExp, condition = true) => {
    const proceed = condition && regExp && typeof str === 'string';
    if (!proceed) {
      return str;
    };
    return str.replace(regExp, '');
  }

  checkRegisteredDevice (deviceName, devices) {
    return devices && devices
      .some(device =>
        device['Device_Name'].includes(deviceName)
        && String(device['Device_IsPreconfigured']) === '0'
      );
  }

  // Updating the state values on input change.
  handleInputChange (event) {
    const {
      target: {
        name: targetName,
        value: targetValue,
      } = {}
    } = event || {};
  let newValue = targetValue;
    if(arrayOfInputNumbers.includes(targetName)) {
      newValue = filterAXNumberValue(targetValue);
    }
    this.setState({
      [targetName]: newValue
    })
  }

  handleCheckboxChange(event) {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  // Validates the edit store basic fields.
  handleValidation () {
    const failNameError = () => {
      this.props.closePopup();
      this.props.navigate('/error', { state: ['stores__error--invalid-store-name'] });
    };

    const {
      hasRegisteredZoom,
    } = this.state;
    const validateTZ =
      this.authService.isAdmin() === true
      && this.checkUserUUID() === false
      && hasRegisteredZoom;

    if (!validateAXAccountNumber(this.state.Store_AX_Customer_Account)) {
      this.setState({ errorMessage: 'stores__error--invalid-ax-account-number', successMessage: '' });
      return false;
    }

    if (!XmlUtilities.isXMLCompatible(this.state.Store_Name)) {
      this.setState({ errorMessage: 'stores__error--invalid-store-name', successMessage: ''});
      return false;
    }

    if (validateTZ) {
      const storeTZ = this.state.Store_TZ;
      if (storeTZ === '' || storeTZ === null) {
        this.setState({ errorMessage: 'stores__error--empty-timezone', successMessage: '' });
        return false;
      }
      this.setState({ errorMessage: '' });
      return true;
    } else {
      const storeName = this.state.Store_Name;
      if (storeName) {
        if (storeName.length === 1) {
          failNameError();
          return false;
        }
        const input = new RegExp(storeName, 'gi');
        const res = CommonConstants.blackListWords.match(input);
        if (res) {
          failNameError();
          return false;
        } else {
          return true;
        }
      }
      return true;
    }
  }

  // Making a service call on submitting the form.
  async handleSubmit () {
    if (!this.handleValidation()) {
      return;
    }
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.saveStore
    let data = {}
    // For Public user - request data.
    if (!this.authService.isAdmin()) {
      data = {
        isAdmin: 0,
        suid: this.props.storesDetails.Store_UID,
        StoreNumber: this.props.storesDetails.Store_Number,
        storeID: this.props.storesDetails.Store_ID,
        StoreName: this.state.Store_Name,
        Company_ID: this.props.storesDetails.Store_Company_ID
      }
    } else {
      // For Admin - request data
      data = {
        isAdmin: 1,
        storeID: this.props.storesDetails.Store_ID,
        timeZone: this.state.Store_TZ,
        StoreName: this.state.Store_Name,
        AXCustomerAccount: this.state.Store_AX_Customer_Account || null,
        AXInvoiceAccount: Number(this.state.Store_AX_Invoice_Account) || null,
        IsEVDSubscription: this.state.Store_IsEVDSubscription,
        IsNEXEOSubscription: this.state.Store_IsNEXEOSubscription,
      }
    }

    // Making a service call
    const result = await this.api.postData(url, data, data => data, error => error) || {};
    if (!result.status || !result.updatedRecords) {
      const isForbidden = result.body && result.body.message === 'Forbidden';
      const errorMessage = isForbidden ? 'common__error--permissions' : 'common__error--internal-server';
      return this.setState({ errorMessage, successMessage: '' });
    }
    const isAdmin = !this.checkUserUUID() && this.authService.isAdmin() === true;
    if (isAdmin) {
      return this.setState({ errorMessage: '', successMessage: 'stores__success--details-saved' })
    }
    const messageKey = 'stores__success--store-saved';
    this.props.closePopup();
    this.props.navigate('/message', { state: messageKey });
  }

  // Admin - This function checks for the UUID presence on the url, if the Admin is viewing the user details.
  checkUserUUID () {
    if (window.location) {
      const params = new URLSearchParams(window.location.search)
      if (params) {
        const uuid = params.get('uuid') ? params.get('uuid') : null
        if (uuid === 'null' || uuid === null) {
          return false
        } else {
          return true
        }
      }
      return false
    }
    return false
  }

  // Checking the condition for showing the remove system on the store popup
  isRemoveStore (deviceDetails) {
    if (deviceDetails) {
      let device = deviceDetails[0]
      if ((device.Device_Name !== null && device.Device_Name !== undefined) && (device.Device_MainVersion !== null && device.Device_MainVersion !== undefined)) {
        if (device.Device_IsActive === 1 || device.Device_IsActive === 0) {
          return true
        }
        return false
      }
      return false
    }
  }

  // Confirming the remove store action by making a service call.
  confirmRemoveStore () {
    const { t } = this.props;
    const { suid } = this.state;
    const errors = [];
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.removeStore}?suid=${suid}`;

    this.api.getData(url, result => {
      if (result.status && result.isStoreRemoved) {
        if (this.checkUserUUID() || this.authService.isAdmin() === false) {
          this.props.closePopup();
          this.props.navigate('/message', { state: 'stores__success--remove' });
        } else {
          this.storeRemovePopupSuccess();
        }
      } else {
        errors.push(t('common__error--unknown'));
        this.storeRemovePopupError();
      }
    })
  }

  // Showing the confirmation popup for removing the store.
  handleRemoveStore () {
    const { t } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Draggable>
            <div className='remove-store-dialog'>
              <div className='custom-ui'>
                <span className='custom-ui-header'><h1>{t('common__confirmation-required')} </h1></span>
                <div className='remove-store-dialog-content'>
                  <p>{t('stores__modal--confirmation__remove')}</p>
                  {/* warning should be modified with css text-transform */}
                  <p><span className='warning-store-dialog-content'><span className='Required'><strong>{t('common__warning')}</strong></span>: {t('common__warning--undone')}</span></p>
                </div>
                <div className='store-merge-footer'>
                  <div className='merge-footer-buttonset'>
                    <button type='button' className='rounded-corners' onClick={() => {
                      this.confirmRemoveStore()
                      onClose()
                    }}>{t('common__confirm')}</button>
                    <button type='button' className='rounded-corners' onClick={onClose}>{t('common__close')}</button>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        )
      }
    })
  }

  storeRemovePopupSuccess(){
    const { t } = this.props;

    confirmAlert({
      customUI:({title,message,onClose})=>{
        return(
          <Draggable>
          <div className='popup modal_dialog'>
            <p className='popup_Title'>Confirmation Required</p>
            <p className='popup_message successMsg'>Success! The requested store has been removed.</p>
            <div className='popup_buttons'>
              <div className='buttonSet' />
              <div className='popup_button'>
                <button className='popup_buttons buttons' onClick={()=>{
                  this.pageRefresh();
                  onClose();
                }}>Close</button>
              </div>
            </div>
          </div>
          </Draggable>
        )
      }
    })
  }

    // Store Remove Error popup
    storeRemovePopupError (error) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Draggable>
            <div className='popup'>
              <p className='popup_Title'>Confirmation Required</p>
              <p className='popup_message warning'> Error! Something went wrong, please try your request again.</p>
              <p className='popup_message'>{error}</p>
              <div className='popup_buttons'>
                <div className='buttonSet' />
                <div className='popup-button'>
                  <button className='popup_buttons buttons' onClick={() => {
                    onClose()
                  }}>Close</button>
                </div>
              </div>
            </div>
            </Draggable>
          )
        }
      })
    }

  render () {
    const isAdmin = this.authService.isAdmin();
    const { t, Device_Details: devices = [] } = this.props;
    const showGroupField = devices && devices
      .some(device => device.Device_Name.includes('ZOOM') && !device.Device_IsPreconfigured);
    const withoutEditBasicPerm = !this.permissionSpecification.hasEditStoreBasicPermission;
    const { Store_Name: storeName, isViewDetailsPage, errorMessage, successMessage, hasRegisteredZoom } = this.state;
    const { storedetails, storeList, storesDetails, storeModelPopupIsAdmin, adminStoreList } = this.props;
    const { Store_UID: currentSuid, Brand_Name: brandName, Store_Number: storeNumber } = storesDetails;
    //We use store from list coz 'usp_GetAllStoresUsers' only returns the groups
    // let storeFromList = {};
    // if(isAdmin) {
    //   [storeFromList = {}] = adminStoreList.filter(({Store_UID: storeUid}) => storeUid === currentSuid);
    // } else {
    //   [storeFromList = {}] = storeList.filter(({Store_UID: storeUid}) => storeUid === currentSuid);
    // }

    const hasNEXEODevice = devices && devices.some(d => d.Device_DeviceType_ID == CommonConstants.deviceType.nexeo.id);
    const hasEVDDevice = devices && devices.some(d => d.Device_DeviceType_ID == CommonConstants.deviceType.evd.id);

    const hideIfStoreDetailsClass = storedetails ? 'hide-table-row' : 'show-table-row';
    const hideIfNotStoreDetailsClass = storedetails ? 'show-table-row' : 'hide-table-row';
    const storeNameDisabled = isAdmin || !checkAccess(CommonConstants.userPermissions.EditStoreBasic);

    return (
      <div>
        <div className={'storePopupForm ' + (storedetails ? 'stores_Details' : '')}>
          <h4 className={'cib_header ' + (storedetails ? 'show' : 'hide')}> {t('stores__modal__tab--details__title')} </h4>
          <div className={'saveStoreDetailsErrors ' + (errorMessage ? 'show' : 'hide')} >
            <span>{t(errorMessage)}</span>
          </div>
          <div className={'saveStoreDetailsSuccess ' + (successMessage ? 'show' : 'hide')} >
            <span>{t(successMessage)}</span>
          </div>
        </div>
        <table className='user_form'>
          <tbody>
            <tr>
              <th><label htmlFor='Store_Brand_ID'>{t('common__brand')}:{(isAdmin || isViewDetailsPage) ? <span className='Required'>*</span> : ''}
              </label></th>
              <td className={storedetails ? 'hide' : ''}>
                <select name='Store_Brand_ID' id='Store_Brand_ID' disabled='disabled' defaultValue="Checker's">
                  <option value="Checker's">{brandName}</option>
                </select>
              </td>
              <td className={storedetails ? '' : 'hide'}>
                <table className='storedetails'>
                  <tbody>
                    <tr>
                      <td>
                        <select name='Store_Brand_ID' id='Store_Brand_ID' disabled='disabled'>
                          <option value="Checker's">{brandName}</option>
                        </select>
                      </td>
                      <th>
                        <label htmlFor='Store_Number'>{t('common__store__number--text')} #:<span className='Required'>*</span></label>
                      </th>
                      <td><input {...inputProps} name='Store_Number' id='Store_Number' value={storeNumber} /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className={hideIfStoreDetailsClass}>
              <th><label htmlFor='Store_Name'> {t('common__store__name')}:</label></th>
              <td><input type='text' maxLength='25' name='Store_Name' value={storeName} disabled={storeNameDisabled} onChange={this.handleInputChange} />
                <input type='hidden' name='Store_ID' id='Store_ID' value={storesDetails.Store_ID} />
              </td>
            </tr>
            <tr className={hideIfStoreDetailsClass}>
              <th ><label htmlFor='Store_Number'>{t('common__store__number--text')}
              : {isAdmin ? <span className='Required'>*</span> : isViewDetailsPage ? <span className='Required'>*</span> : ''}
              </label></th>
              <td><input {...inputProps} name='Store_Number' id='Store_Number' value={storeNumber} /></td>
            </tr>
            {showGroupField &&
              <tr className={storeModelPopupIsAdmin ? '' : hideIfStoreDetailsClass}>
                <th><label htmlFor='Group_Name'>{t('common__group')}:</label></th>
                <td><input {...inputProps} name='Group_Name' id='Group_Name' value={storesDetails.Group_Name} /></td>
              </tr>
            }
            <tr className={storeModelPopupIsAdmin ? '' : hideIfStoreDetailsClass}>
              <th><label htmlFor='Report_Group'>{t('stores__list-header__report-group')}:</label></th>
              <td><input {...inputProps} name='Report_Group' id='Report_Group' value={storesDetails.GroupName || ''} /></td>
            </tr>
            <tr>
              <th><label htmlFor='Store_AddressLine1'>{t('common__address-line--1')}:{isAdmin ? <span className='Required'>*</span> : isViewDetailsPage ? <span style={{ padding: '2px' }} className='Required'>*</span> : ''}</label></th>
              <td><input {...inputProps} name='Store_AddressLine1' id='Store_AddressLine1' value={storesDetails.Store_AddressLine1} /></td>
            </tr>
            <tr>
              <th><label className='storedetails_Addressline' htmlFor='Store_AddressLine2'>{t('common__address-line--2')}:</label></th>
              <td><input {...inputProps} name='Store_AddressLine2' id='Store_AddressLine2' value={storesDetails.Store_AddressLine2} /></td>
            </tr>
            <tr>
              <th><label className='storedetails_Addressline' htmlFor='Store_AddressLine3'>{t('common__address-line--3')}:</label></th>
              <td><input {...inputProps} name='Store_AddressLine3' id='Store_AddressLine3' value={storesDetails.Store_AddressLine3} /></td>
            </tr>
            <tr>
              <th><label className='storedetails_Addressline' htmlFor='Store_AddressLine4'>{t('common__address-line--4')}:</label></th>
              <td><input {...inputProps} name='Store_AddressLine4' id='Store_AddressLine4' value={storesDetails.Store_AddressLine4} /></td>
            </tr>
            <tr className={hideIfNotStoreDetailsClass}>
              <th><label htmlFor='Group_Name'>{t('common__group')}:</label></th>
              <td><input {...inputProps} name='Group_Name' id='Group_Name' value={storesDetails.Group_Name} /></td>
            </tr>
            <tr className={hideIfStoreDetailsClass} >
              <th><label htmlFor='Store_Locality'>{t('common__city')}:{isAdmin ? <span className='Required'>*</span> : ''}</label></th>
              <td><input {...inputProps} name='Store_Locality' id='Store_Locality' value={storesDetails.Store_Locality} /></td>
            </tr>
            <tr className={hideIfNotStoreDetailsClass} >
              <th><label htmlFor='Store_Locality'>{t('common__city')}:{isAdmin ? <span className='Required'>*</span> : isViewDetailsPage ? <span style={{ padding: '3px' }} className='Required'>*</span> : ''}</label></th>
              <td>
                <table className='storedetails'>
                  <tbody>
                    <tr>
                      <td>
                        <input {...inputProps} name='Store_Locality' id='Store_Locality' value={storesDetails.Store_Locality} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className={hideIfStoreDetailsClass}>
              <th><label htmlFor='Store_Region'> {t('common__region')}:<span className='Required'>*</span></label></th>
              <td><input {...inputProps} name='Store_Region' id='Store_Region' value={storesDetails.Store_Region} /></td>
            </tr>
            <tr className={hideIfStoreDetailsClass}>
              <th><label htmlFor='Store_PostCode'>{t('common__zip')}:{(isAdmin || isViewDetailsPage) ? <span className='Required'>*</span> : ''}</label></th>

              <td><input {...inputProps} name='Store_PostCode' id='Store_PostCode' value={storesDetails.Store_PostCode} /></td>
            </tr>
            <tr className={hideIfNotStoreDetailsClass} >
              <th><label htmlFor='Store_Region'>{storesDetails.Country_ID === 1 ? t('common__state') : t('common__region')}:<span className='Required'>*</span></label></th>
              <td>
                <table className='storedetails'>
                  <tbody>
                    <tr>
                      <td>
                        <input {...inputProps} name='Store_Region' id='Store_Region' value={storesDetails.Store_Region} />
                      </td>
                      <th><label htmlFor='Store_PostCode' className='zip'>{t('common__zip')}:{(isAdmin || isViewDetailsPage) ? <span className='Required'>*</span> : ''}</label></th>
                      <td><input {...inputProps} name='Store_PostCode' id='Store_PostCode' value={storesDetails.Store_PostCode} /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th><label htmlFor='Store_Country'>{t('common__country')}:{(isAdmin || isViewDetailsPage) ? <span className='Required'>*</span> : ''}</label></th>
              <td>
                <select name='Store_Country_ID' id='Store_Country_ID' disabled='disabled'>
                  <CountryOptions countryList={this.props.countries} selectedCountryId={storesDetails.Country_ID} />
                </select>
              </td>
            </tr>
            <tr className={isAdmin ? 'show-table-row' : 'hide-table-row'}>
              <th><label htmlFor='Store_TZ'>{t('common__timezone')}:{isAdmin ? <span className='Required'>*</span> : ''}</label></th>
              <td>
                <TimeZoneDropdown
                  timezones={this.state.timeZones}
                  selectedZone={this.state.Store_TZ}
                  isDisabled={withoutEditBasicPerm || !hasRegisteredZoom}
                  defaultOptionTitle={t('stores__modal__select_timezone')}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <th><label htmlFor='Store_PhoneNumber'>{t('common__phone')}:{(isAdmin || isViewDetailsPage) ? <span className='Required'>*</span> : ''}</label></th>
              <td><input {...inputProps} name='Store_PhoneNumber' id='Store_PhoneNumber' value={storesDetails.Store_PhoneNumber} /></td>
            </tr>
            <tr>
              <th><label htmlFor='Store_FaxNumber'>{t('common__fax')}:</label></th>
              <td><input {...inputProps} name='Store_FaxNumber' id='Store_FaxNumber' value={storesDetails.Store_FaxNumber} /></td>
            </tr>
            <tr className={!isAdmin ? 'hide-table-row' : ' show-table-row'}>
              <th><label htmlFor='Store_AX_Customer_Account'>{t('common__ax-account--customer')}:</label></th>
              <td><input disabled={!this.props.editable} type='text' maxLength='10' name='Store_AX_Customer_Account' id='Store_AX_Customer_Account' value={this.state.Store_AX_Customer_Account} onChange={this.handleInputChange}/></td>
            </tr>
            <tr className={!isAdmin ? 'hide-table-row' : ' show-table-row'}>
              <th><label htmlFor='Store_AX_Invoice_Account'>{t('common__ax-account--invoice')}:</label></th>
              <td><input disabled={!this.props.editable} type='text' maxLength='10' name='Store_AX_Invoice_Account' id='Store_AX_Invoice_Account' value={this.state.Store_AX_Invoice_Account} onChange={this.handleInputChange}/></td>
            </tr>
            <tr className={!isAdmin && !hasEVDDevice ? 'hide-table-row' : ' show-table-row'}>
              <th><label htmlFor='Store_IsEVDSubscription'>{t('common__subscription--evd')}:</label></th>
              <td>
                <input disabled={true} type='checkbox' readonly={true} name='Store_IsEVDSubscription' id='Store_IsEVDSubscription' checked={hasEVDDevice} />
              </td>
            </tr>
            <tr className={!isAdmin && !hasNEXEODevice ? 'hide-table-row' : ' show-table-row'}>
              <th><label htmlFor='Store_IsNEXEOSubscription'>{t('common__subscription--nexeo')}:</label></th>
              <td>
                <input disabled={true} type='checkbox' readonly={true} name='Store_IsNEXEOSubscription' id='Store_IsNEXEOSubscription' checked={hasNEXEODevice} />
              </td>
            </tr>
            <tr />
          </tbody>
        </table>

        <div className={(storeModelPopupIsAdmin ? 'hide' : 'show') + ' store-details-save Store_savebtn_bottom'}>
          <div className={hideIfStoreDetailsClass}>
            {withoutEditBasicPerm ? '' : <input type='submit' value={t('common__save')} className='submit-public' onClick={this.handleSubmit} translate='' key='save' />}
            {withoutEditBasicPerm ? '' : '\u00A0 | \u00A0'}
            <a className='cancel-public' translate='' onClick={this.props.close} key='settingsStoresCancel'>{t('common__cancel')}</a>
          </div>
        </div>

        {/* Showing the remove store button for Admin and stores which are unavailable */}
        <div className={'remove-store-dialog ' + (isAdmin && checkAccess(CommonConstants.adminPermissions.RemoveStore) ? 'show' : 'hide')}>
          <input className={this.isRemoveStore(this.props.Device_Details) ? 'hide' : 'show' + ' store-remove-button'} type='submit' value={t('stores__modal__remove')} onClick={this.handleRemoveStore} />
          <input className={(storeModelPopupIsAdmin ? 'show' : 'hide') + ' store-info-save'} style={saveBtnStyle} type='submit' value={t('common__save')} onClick={this.handleSubmit} />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const countries = R.pathOr(null, ['country','countries'], state);
  const storesDetails = R.pathOr(null, ['StorePopupDetails','storePopupDetails','storeDetails'], state);
  const Device_Details = R.pathOr(null, ['StorePopupDetails','storePopupDetails','Device_Details'], state);
  const storeModelPopup = R.pathOr(null, ['StorePopupDetails'], state);
  const storeModelPopupIsAdmin = R.pathOr(null, ['StorePopupDetails','storePopUpDetailisAdmin'], state);
  const permissions = R.pathOr(null, ['permissions'], state);
  const storeList = R.pathOr(null, ['storeDetails','storeDetails','storeList'], state);
  const adminStoreList = R.pathOr(null, ['storeDetails','adminStoreDetails','storeList'], state);

  return {
    countries,
    storesDetails,
    Device_Details,
    storeModelPopup,
    storeModelPopupIsAdmin,
    permissions,
    storeList,
    adminStoreList
  }
}

function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      getCountryList: getCountryList,
      closePopup: closePopup,
      getPermission: getPermission
    }, dispatch
  )
}

StoreDetailsComponent.propTypes = {
    navigate: PropTypes.func,
    location: PropTypes.object
};

export default R.compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter
)(StoreDetailsComponent);
