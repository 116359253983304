import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import { Expand } from 'library/Icons/Expand';
import { GridCell } from '../../GridCell';

import './ExpandCell.scss';

export const ExpandCell = cond([
    [({ expandable }) => expandable, ({ expanded, onExpandChange, hidden }) => (
        <GridCell className={classNames('hme-grid-cell-expand-icon', {
            'hme-grid-cell-expand-icon-hidden': hidden,
        })}>
            <Expand
                expanded={expanded}
                onExpandChange={onExpandChange && (() => onExpandChange(!expanded))} />
        </GridCell>
    )],    
    [T, () => null]
]);