import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'library/Label';

import { TreeItemsProp } from './types';
import { TreeItem } from './TreeItem';

import './Tree.scss';

const defaultVariants = [];

export const TREE_VARIANTS = {
    BORDERED: 'bordered'
};

/**
 * @param {object} hierarchical items
 * @param {boolean} isGroupSelectable
 *
 * @returns {object} key-value pairs of disabled items
 */
const getDisabledItems = (items, isGroupSelectable) => {
    const getDisabledItemMap = (item, isGroupSelectable) => {
        let disabledMap = {};

        if (isGroupSelectable && item.item.Type === 'group') {
            disabledMap[item.key] = item.disabled;
        }

        if (!isGroupSelectable && item.children?.length) {
            item.children.forEach((childItem) => {
                if (childItem.value) {
                    disabledMap[childItem.value] = childItem.disabled;
                }

                const childItemDisabledMap = getDisabledItemMap(childItem);

                disabledMap = { ...disabledMap, ...childItemDisabledMap };
            });
        }

        return disabledMap;
    };

    return items.reduce(
        (acc, item) => ({
            ...acc,
            ...getDisabledItemMap(item, isGroupSelectable),
        }),
        {},
    );
};

export const Tree = ({
    tree,
    className,
    variants = defaultVariants,
    selectable = false,
    selection,
    hideEmpty = false,
    isAllExpanded = false,
    isExpandedDefault = false,
    emptyTreeMessage,
    onSelectionChange,
    isGroupSelectable
}) => {
    const { t } = useTranslation();

    const disabledItems = useMemo(() => getDisabledItems(tree, isGroupSelectable), [tree, isGroupSelectable]);

    return (
        <div className={classNames('hme-tree', className, variants.map((variant) => `hme-tree--${variant}`))}>
            {
                !tree.length && emptyTreeMessage ?
                    <Label className="hme-tree__empty-message">{t(emptyTreeMessage)}</Label> :
                    tree.map((item, index) => (
                        <TreeItem
                            isGroupSelectable={isGroupSelectable}
                            key={typeof item.key === 'undefined' ? index : item.key}
                            item={item}
                            selectable={selectable}
                            selection={selection}
                            onSelectionChange={onSelectionChange}
                            hideEmpty={hideEmpty}
                            isAllExpanded={isAllExpanded}
                            isExpandedDefault={isExpandedDefault}
                            disabledItems={disabledItems}
                        />
                    ))
            }
        </div>
    );
};

Tree.propTypes = {
    tree: TreeItemsProp,
    selectable: PropTypes.bool,
    selection: PropTypes.arrayOf(PropTypes.any),
    onSelectionChange: PropTypes.func,
    isGroupSelectable: PropTypes.bool
};
