import { CommonConstants } from 'Constants';
import { permissionTypes } from 'constants/permissionTypes'

export const permissionSections = [{
    type: permissionTypes.BASIC,
    title: 'add-role__group--basic',
}, {
    type: permissionTypes.ADMIN,
    title: 'add-role__group--advanced'
}, {
    type: permissionTypes.EXTERNAL,
    title: 'add-role__group--external'
}];

export const subPermission = [CommonConstants.userPermissions.CustomizeSmackTalk]; 

export const dependencies = {
    [CommonConstants.userPermissions.CustomizeSmackTalk]: [CommonConstants.userPermissions.SmackTalk],
    [CommonConstants.userPermissions.EditStoreAdvanced]: [
        CommonConstants.userPermissions.ViewAllStores,
        CommonConstants.userPermissions.EditStoreBasic
    ],
    [CommonConstants.externalPermissions.ManageDevicesAdvanced]: [CommonConstants.externalPermissions.ManageDevicesBasic]
};
