export const getStoreIds = (stores) => {
    return stores.reduce((ids, store) => {
        if (store.Stores) {
            return [...ids, ...getStoreIds(store.Stores)];
        }

        ids.push(store.StoreUID);
        return ids;
    }, []);
};
