/* eslint-disable react/prop-types */
import './ReceiverSection.scss';
import React, { useEffect, useState } from 'react';
import { Section } from 'library/Section';
import { Label } from 'library/Label';
import { ReactComponent as SearchIcon } from '../../../../images/IconSearchField.svg';
import { MultiSelectInput } from 'components/Inputs/MultiSelectInput';
import { BadgePill } from 'library/BadgePill';
import { MultiPopup } from 'library/MultiPopup';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';
import { useTranslation } from 'react-i18next';

const BAGES_HEIGHT_BEFORE_WRAPPING = 200;
export const ReceiverSection = ({
    col,
    number,
    disabled = false,
    title,
    className,
    smacktalkData,
    selections,
    setSelections,
    ...rest
}) => {
    const [stores, setStores] = useState([]);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [isPopupShown, setIsPopupShown] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (smacktalkData && smacktalkData.groups) {
            const groups = smacktalkData.groups.map(({ GroupName, Stores }) => ({
                name: GroupName,
                children: (Stores || []).map(({ StoreName, StoreNumber, StoreUID }) => ({
                    text: StoreName ? `${StoreNumber}-${StoreName}` : StoreNumber,
                    value: StoreUID
                }))
            }));
            setItems(groups);
            setFilteredItems(groups);
            setStores(smacktalkData.stores || []);
        }
    }, [smacktalkData]);

    const deleteBadge = (el) => {
        setSelections(selections.filter((f) => f !== el));
    };

    const badgePillwrapper = (storeUid, i) => {
        const currentStore = stores.find((el) => el.value === storeUid) || {};
        return (
            <BadgePill key={`${i}-selections`}>
                <span className="bage-pill-label">{currentStore.label || storeUid}</span>
                <div
                    className="close-cross"
                    onClick={() => deleteBadge(storeUid)}
                ></div>
            </BadgePill>
        );
    };

    const onSearchIconClick = () => {
        setIsPopupShown(isPopupShown);
    };
    const onStoreChange = (selection) => {
        setSelections(selection);
    };
    const onInputChange = (e) => {
        if (!isPopupShown) {
            setIsPopupShown(true);
        }
        setInputValue(e);
        setFilteredItems(
                items
                        .map((p) => {
                            const childrenFiltered = p && p.children.filter(({ text })=> {
                                const  result = text && text.toLowerCase().includes(e.toLowerCase());
                                return result;
                            });
                            const isMatched =
                                (childrenFiltered && childrenFiltered.length) ||
                                    (String(p.name).toLowerCase().includes(e.toLowerCase()));
                            // eslint-disable-next-line no-void
                            return isMatched ? { ...p, children: childrenFiltered } : void(0);
                        })
                        .filter((el) => el)
        );
    };

    return (
        <Section
            col={col}
            number={number}
            disabled={disabled}
            title = {title}
            className = {className}
            {...rest}
        >
            <div className="d-flex mb-2">
                <Label>{t('common__to').toUpperCase()}</Label>
                <Label className="ms-auto">
                    <span className="text-primary">
                        {selections.length}
                    </span> {t('common__stores').toUpperCase()}
                </Label>
            </div>

            <MultiSelectInput
                selections={selections.map(badgePillwrapper)}
                variants={['search']}
                onChange={onInputChange}
                onClick={()=>{
                    if (!isPopupShown) {
                        setIsPopupShown(true);
                    }
                }}
                placeholder={
                    items && items.length ?
                        (selections && selections.length ? '' : t('smack-talk__tab__receiver-section--store-num-name-lb')) :
                        t('common__loading')
                }
                value={inputValue}
                showMoreHeight={BAGES_HEIGHT_BEFORE_WRAPPING}
                showMoreLabel = {<>…{t('smack-talk__tab__receiver-section--show-more')} <DropdownIcon /></>}
                showLessLabel = {<>…{t('smack-talk__tab__receiver-section--show-less')} <DropdownIcon /></>}
            >
                <SearchIcon className="hme-multi-select-input-search-icon" onClick={onSearchIconClick}/>
            </MultiSelectInput>
            <MultiPopup
                show={isPopupShown}
                items={items}
                filteredItems={filteredItems}
                selection={selections}
                onlyText={t('common__only').toLowerCase()}
                onChange={onStoreChange}
                onHide={()=>{
                    if (isPopupShown) {
                        setIsPopupShown(false);
                    }
                }}
            />
        </Section>
    );
};
