export const ACCOUNTS_HEADERS = [{
    property: [{
        text: 'common__account__email',
        property: 'User_EmailAddress',
        sortable: true,
        flex: 1.25
    }, {
        text: 'common__company__name',
        property: 'Company_Name',
        sortable: true
    }, {
        text: 'admin__accounts__grid__header--company-type',
        property: 'Company_Type',
        sortable: true,
        flex: 0.75
    }, {
        text: 'common__country',
        property: 'Country_Name',
        sortable: true,
        flex: 0.75
    }, {
        text: 'admin__distributors__grid__header--subscription',
        property: 'Subscription_Name',
        sortable: true,
        flex: 1
    }, {
        text: 'admin__accounts__grid__header--account-status',
        property: 'isActive',
        sortable: true,
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell',
        flex: 0.75
    }, {
        text: 'common__actions',
        property: 'Actions',
        flex: 0.5,
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell',
    }]
}];
