const ACTIONS = {
    CHANGE_IS_ENABLED: 'CHANGE_IS_ENABLED',
    CHANGE_TYPE: 'CHANGE_TYPE',
    CHANGE_TEXT: 'CHANGE_TEXT',
    ADD_NEW_LANGUAGE: 'ADD_NEW_LANGUAGE',
    REMOVE_LANGUAGE: 'REMOVE_LANGUAGE',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE'
};

export const formReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.CHANGE_IS_ENABLED: {
            const { isEnabled } = action.payload;

            return {
                ...state,
                isEnabled
            };
        }
        case ACTIONS.CHANGE_TYPE: {
            const { newType: type } = action.payload;

            return {
                ...state,
                type
            };
        }
        case ACTIONS.CHANGE_TEXT: {
            const { idx, text } = action.payload;
            const { texts } = state;

            const currentText = { ...texts[idx] };
            currentText.value = text;

            return {
                ...state,
                texts: [
                    ...texts.slice(0, idx),
                    currentText,
                    ...texts.slice(idx + 1)
                ]
            };
        }
        case ACTIONS.ADD_NEW_LANGUAGE: {
            const { langKey } = action.payload;

            const { texts } = state;

            return {
                ...state,
                texts: [
                    ...texts,
                    {
                        langKey,
                        value: ''
                    }
                ]
            };
        }
        case ACTIONS.REMOVE_LANGUAGE: {
            const { idx } = action.payload;

            const { texts, initialNotification } = state;

            const languageToRemove = texts[idx];

            const newInitialTexts = { ...initialNotification.texts };
            delete newInitialTexts[languageToRemove.langKey];

            return {
                ...state,
                texts: [
                    ...texts.slice(0, idx),
                    ...texts.slice(idx + 1)
                ],
                initialNotification: {
                    ...initialNotification,
                    texts: newInitialTexts
                }
            };
        }
        case ACTIONS.CHANGE_LANGUAGE: {
            const { idx, langKey: newLangKey } = action.payload;

            const { texts } = state;
            const currentText = { ...texts[idx] };
            currentText.langKey = newLangKey;

            return {
                ...state,
                texts: [
                    ...texts.slice(0, idx),
                    currentText,
                    ...texts.slice(idx + 1)
                ]
            };
        }
        default:
            return state;
    }
};

const changeIsEnabled = ({ isEnabled }) => ({ type: ACTIONS.CHANGE_IS_ENABLED, payload: { isEnabled } });
const changeType = ({ newType }) => ({ type: ACTIONS.CHANGE_TYPE, payload: { newType } });

const changeText = ({ idx, text }) => ({ type: ACTIONS.CHANGE_TEXT, payload: { idx, text } });
const addNewLanguage = ({ langKey }) => ({ type: ACTIONS.ADD_NEW_LANGUAGE, payload: { langKey } });
const removelanguage = ({ idx }) => ({ type: ACTIONS.REMOVE_LANGUAGE, payload: { idx } });
const changeLanguage = ({ idx, langKey }) => ({ type: ACTIONS.CHANGE_LANGUAGE, payload: { idx, langKey } });

export const actionTypes = {
    changeIsEnabled,
    changeType,
    changeText,
    addNewLanguage,
    removelanguage,
    changeLanguage
};
