import React, { Component } from "react";
import { closePopup } from '../actions/modalAction'

import "../components/Stores/Stores.css";

import { useParams } from 'react-router-dom';

const wrapper = WrappedComponent => {
  return class extends Component {

    viewDevice = (did) => {
      this.props.dispatch(closePopup());
      const { uuid = null } = useParams();

      let url = `/settings/stores/viewDetail?duid=${did}`;
      if (uuid) {
        url = `${url}&uuid=${uuid}`
      }
      window.location.href = url;
    }

    render() {
      return <WrappedComponent {...this.props} viewDevice={this.viewDevice} />;
    }
  };
};

export default wrapper;
