import { deviceConfig } from 'constants/device';
import { snapshotConfig } from 'constants/manageDeviceSettings';
import { CommonConstants } from 'Constants';
import { getDevices, applySettingsToMany } from '../../ApplySettingsService';
import { filterSnapshots, getDetailedSnapshotsList } from 'services/Snapshots';

const { deviceType } = CommonConstants;

export const loadDevices = async ({ setDevices, setIsDevicesLoading }) => {
    setIsDevicesLoading(true);

    try {
        const [zoomNitroDevices, nexeoDevices] = await Promise.all([
            getDevices({
                deviceMinMainVersion: deviceConfig.zoomNitro.applySettingsMinVersion,
                showIsBusyDevices: 1,
            }),
            getDevices({
                deviceTypeId: deviceType.nexeo.id,
                showIsBusyDevices: 1,
            }),
        ]);

        setDevices([...zoomNitroDevices, ...nexeoDevices]);
    } catch (err) {
        setDevices([]);
    }
    
    setIsDevicesLoading(false);
};

export const loadSnapshots = async ({ setSnapshots, setIsSnapshotsLoading }) => {
    setIsSnapshotsLoading(true);

    try {
        const snapshotsRaw = await getDetailedSnapshotsList({});
        const snapshots = {
            nexeo: filterSnapshots(snapshotsRaw, {
                type: deviceType.nexeo.displayName,
                status: snapshotConfig.statuses.CREATED,
            }),
            zoomNitro: filterSnapshots(snapshotsRaw, {
                type: deviceType.zoomNitro.displayName,
                status: snapshotConfig.statuses.CREATED,
            }),
        };

        setSnapshots(snapshots);
    } catch (err) {
        setSnapshots({ nexeo: [] });
    }

    setIsSnapshotsLoading(false);
};

const formatPayload = ({
    sourceType,
    sourceDeviceUID,
    settingTemplateID,
    sourceDeviceSettingGroups,
    targetDeviceUIDs,
}) => {
    const payload = {
        targetDeviceUIDs,
        selectedDeviceType: sourceType,
    };

    if (!!sourceDeviceSettingGroups) {
        // send selected groups for snapshots ADS tasks too
        payload.sourceDeviceSettingGroups = sourceDeviceSettingGroups;
    }

    if (settingTemplateID) {
        return {
            ...payload,
            settingTemplateID,
        };
    }

    if (sourceDeviceUID) {
        return {
            ...payload,
            sourceDeviceUID,
            sourceDeviceSettingGroups,
        };
    }
};

export const applySettings = async ({
    params,
    sourceType,
    selectedDevices,
    setIsApplyingSettings,
    setSettingSuccess,
    setPageError,
    redirectToStatusPage,
    t,
}) => {
    setIsApplyingSettings(true);

    const { sourceDevice, settingTemplateID, sourceDeviceSettingGroups } = params;
    const sourceDeviceUID = sourceDevice?.Device_UID;
    const targetDeviceUIDs = selectedDevices.map((d) => d.Device_UID);

    // format payload either from device source or snapshot settings
    let payload = formatPayload({
        sourceType,
        sourceDeviceUID,
        settingTemplateID,
        sourceDeviceSettingGroups,
        targetDeviceUIDs,
    });

    if (!payload) {
        setPageError({
            status: true,
            message: t('apply-device-settings-new__failed'),
        });
        setIsApplyingSettings(false);
        return redirectToStatusPage();
    }

    const result = await applySettingsToMany(payload);

    result.status
        ? setSettingSuccess({
              status: true,
              message: t('apply-device-settings-new__success'),
          })
        : setPageError({
              status: true,
              message: t('apply-device-settings-new__failed'),
          });
   
    setIsApplyingSettings(false);
    redirectToStatusPage();
};
