import React, { useState, useEffect, useCallback } from 'react';
const { Constant: { DeviceType } } = require('@hme-cloud/utility-common');

import { useTranslation } from 'react-i18next';

import { SuccessInput } from 'library/SuccessInput';
import { SelectSnapshotModal } from './SelectSnapshotModal';
import { SourceDeviceSettings } from '../../SourceDeviceSettings';

import './SelectSnapshot.scss';

export const SelectSnapshot = ({
    snapshots,
    value,
    deviceType,
    isSnapshotsLoading = false,
    onParamsChange,
    params,
}) => {
    const {
        sourceSettingsList: settingsList,
    } = params;

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [snapshot, setSnapshot] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setSnapshot(snapshots.find(({ SettingTemplateID }) => SettingTemplateID == value) || null);
    }, [snapshots, value]);

    const setSettingsList = useCallback(settingsList => {
        onParamsChange && onParamsChange({
            ...params,
            sourceSettingsList: settingsList,
        })
    }, [onParamsChange, params]);

    return (
        <>
            <SuccessInput
                variants={['snapshot']}
                label={t('common__snapshot')}
                value={snapshot ? snapshot.SettingName : ''}
                success={value}
                onFocus={e => e.target.blur()}
                onClick={() => setIsModalOpened(true)}
                readOnly
            >
                <span className='hme-select-snapshot'>{t('common__select-a-snapshot')}</span>
            </SuccessInput>
            <SelectSnapshotModal
                snapshots={snapshots}
                deviceType={deviceType}
                show={isModalOpened}
                onSnapshotSelect={
                    onParamsChange &&
                    ((selectedSnapshot) => {
                        setSnapshot(selectedSnapshot);
                        onParamsChange({
                            settingTemplateID: selectedSnapshot.SettingTemplateID,
                            isIOT: selectedSnapshot.IsIOT,
                        });
                        setIsModalOpened(false);
                    })
                }
                onHide={() => setIsModalOpened(false)}
                isSnapshotsLoading={isSnapshotsLoading}
            />
            {
                params.settingTemplateID
                && params.isIOT
                && params.selectedDeviceType === DeviceType.ZOOMNITRO.camelCase
                ? <div>
                    <SourceDeviceSettings
                        values={settingsList}
                        onChange={setSettingsList}
                        isEditable
                    />
                </div> 
                : null
            }
        </>
    );
};