import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { debounce } from 'lodash';
import { CommonConstants } from 'Constants';
import { useAccess } from 'hooks/useAccess';

import { adminUsersSelectSearchOptions as searchSelectOptions } from '../../constants';
import { usersToRows, formatSortParams } from '../helpers';
import { useLoadUsers } from './useLoadUsers';
import { useDeleteUser } from './useDeleteUser';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

const { userPermissions } = CommonConstants;

const INIT_SEARCH_PARAMS = { selectValue: '', searchValue: '' };
const INIT_PAGINATION_VALUES = { recordsPerPage: 10, page: 0 };
const INITIAL_SORT_SELECTION = { User_EmailAddress: 1 };
const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;
const SEARCH_DEBOUNCE_TIMEOUT = 1000;

export const useUsersList = () => {
    const [rows, setRows] = useState([]);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);
    const [sortSelection, setSortSelection] = useState(INITIAL_SORT_SELECTION);
    const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [uuidUserToRemove, setUuidUserToRemove] = useState('');

    const searchRef = useRef(null);

    const { users, isUsersLoading, usersLoadingError, loadUsers } = useLoadUsers({
        ...paginationData,
        ...formatSortParams(sortSelection),
        ...searchParams,
    });
    const { isDeleteUserLoading, deleteUserLoadingError, deleteUser } = useDeleteUser();

    const loadUsersDebounced = useMemo(() => debounce(loadUsers, SEARCH_DEBOUNCE_TIMEOUT), [debounce, loadUsers]);
    const hasManageUserAccess = useAccess(userPermissions.ManageUser);

    const isLoading = isUsersLoading || isDeleteUserLoading;

    const focusSearch = useCallback(() => {
        if (searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef]);

    const onSearchDone = useCallback(() => {
        focusSearch();
        setPaginationData((currentPaginationData) => ({
            ...currentPaginationData,
            page: INIT_PAGINATION_VALUES.page,
        }));
    }, [focusSearch, setPaginationData]);

    const onSortChange = useCallback(
        (newSorting) => {
            if (isLoading) {
                return;
            }

            setSortSelection(newSorting);
            loadUsers({ ...paginationData, ...formatSortParams(newSorting), ...searchParams });
        },
        [isLoading, paginationData, searchParams, formatSortParams, loadUsers],
    );

    const onPaginateChange = useCallback(
        (newPaginationData) => {
            if (isLoading) {
                return;
            }

            setPaginationData(newPaginationData);
            loadUsers({ ...newPaginationData, ...formatSortParams(sortSelection), ...searchParams });
        },
        [isLoading, sortSelection, searchParams, formatSortParams, loadUsers],
    );

    const onSearch = useCallback(
        (newSearchParams) => {
            if (isLoading) {
                return;
            }

            loadUsersDebounced.cancel();
            loadUsersDebounced({
                ...paginationData,
                ...formatSortParams(sortSelection),
                ...newSearchParams,
                onDone: onSearchDone,
            });
        },
        [isLoading, sortSelection, paginationData, loadUsersDebounced],
    );

    const onSearchParamsChange = useCallback(
        (newSearchParams) => {
            setSearchParams(newSearchParams);

            if (!newSearchParams.searchValue) {
                focusSearch();
            }

            if (newSearchParams.searchValue.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH) {
                loadUsersDebounced.cancel();
                return;
            }

            loadUsersDebounced({
                ...paginationData,
                ...formatSortParams(sortSelection),
                ...newSearchParams,
                onDone: onSearchDone,
            });
        },
        [paginationData, sortSelection, loadUsersDebounced, onSearchDone],
    );

    const onConfirmModalOpen = (userUid) => {
        setIsConfirmModalOpen(true);
        setUuidUserToRemove(userUid);
    };

    const onCancelRemoveUser = useCallback(() => {
        setUuidUserToRemove('');
        setIsConfirmModalOpen(false);
    }, []);

    const onConfirmRemoveUser = useCallback(() => {
        onCancelRemoveUser();

        deleteUser({
            userUid: uuidUserToRemove,
            onDone: () => {
                loadUsers({ ...paginationData, ...formatSortParams(sortSelection), ...searchParams });
                addSuccessNotification('common__success--user-deleted');
            },
        });
    }, [
        uuidUserToRemove,
        paginationData,
        sortSelection,
        searchParams,
        deleteUser,
        formatSortParams,
        onCancelRemoveUser,
    ]);

    useEffect(() => {
        if (deleteUserLoadingError || usersLoadingError) {
            addErrorNotification('common__error--internal-server');
        }
    }, [deleteUserLoadingError, usersLoadingError]);

    useEffect(() => {
        if (!users) return;

        setRows(usersToRows(users, onConfirmModalOpen, hasManageUserAccess));
    }, [users]);

    const searchOptions = {
        searchParams,
        searchSelectOptions,
        searchRef,
        onSearchParamsChange,
        onSearch,
    };

    return {
        users,
        rows,
        sortSelection,
        paginationData,
        isLoading,
        searchOptions,
        isConfirmModalOpen,
        onSortChange,
        onPaginateChange,
        onCancelRemoveUser,
        onConfirmRemoveUser,
    };
};
