import React from 'react';

const GroupOfStoresText = ({ store }) => {
    return <span>{store.GroupName}</span>;
};

const StoreText = ({ store }) => {
    return <span>{store.StoreNumber} - {store.StoreName}</span>;
};

export const STORE_TYPE = {
    STORE: 'store',
    GROUP: 'group'
};

const STORE_TYPE_COMPONENTS = {
    [STORE_TYPE.GROUP]: GroupOfStoresText,
    [STORE_TYPE.STORE]: StoreText
};

export const StoreHierarchyText = ({ store, hierarchyType }) => {
    const Component = STORE_TYPE_COMPONENTS[hierarchyType];

    return <Component store={store} />;
};