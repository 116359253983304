import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'library/Button';
import {
    ADMIN_STORES_EDIT_PAGE,
    STORES_EDIT_PAGE
} from '../../constants';

export const ViewButton = ({ suid, uuid, isAdminUser }) => {
    const { t } = useTranslation();
    const editPageURL = isAdminUser
                    ? `${ADMIN_STORES_EDIT_PAGE}?suid=${suid}&uuid=${uuid}`
                    : `${STORES_EDIT_PAGE}?suid=${suid}`;

    return <Button as={Link} to={editPageURL} variants={['transparent']}>{t('common__view-edit')}</Button>
};

ViewButton.propTypes = {
    suid: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};
