/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import './withCharCounter.css';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default (WrappedComponent, CustomContent) => {
    class withCharCounter extends Component {
        constructor(props) {
            super(props);

            this.state = {
                charsLeft: this.props.maxLength,
                charsEntered: 0,
                isDisplayed: CustomContent ? true : false
            };

            this.symbolsCounter = this.symbolsCounter.bind(this);
        }

        componentDidMount() {
            this.symbolsCounter(this.props.value);
        }

        symbolsCounter(e) {
            const { maxLength, onChange } = this.props;
            const { target: { value = e } = {} } = e || {};
            const charsEntered = value.length;
            let { charsLeft, isDisplayed } = this.state;

            if (maxLength) {
                charsLeft = maxLength - charsEntered;
            }

            if (!isDisplayed && charsEntered) {
                isDisplayed = true;
            }

            this.setState({ charsLeft, charsEntered, isDisplayed });
            onChange(value);
        }

        render() {
            const { maxLength, t } = this.props;
            const { charsLeft, charsEntered, isDisplayed } = this.state;
            const props = { ...this.props, onChange: this.symbolsCounter };
            const defaultContent = (<>
                <span>{`${charsEntered} ${t('common__characters-entered')}`}</span>
                {maxLength && <span>{`${charsLeft} ${t('common__characters-remaining')}`}</span>}
            </>);
            const customContent = <CustomContent { ...this.props } { ...this.state }/>;

            // get rid of translation props for the wrapped component
            const { t: tKey, tReady, i18n, ...wrappedComponentProps } = props;

            return (
                <Fragment>
                    <WrappedComponent {...wrappedComponentProps} />
                    <small className={classNames(
                            'hme-input__char-counter',
                            { 'hme-input__char-counter--hidden': !isDisplayed }
                    )}>
                        {CustomContent ? customContent : defaultContent}
                    </small>
                </Fragment>
            );
        }
    }

    withCharCounter.displayName = `withCharCounter(${getDisplayName(WrappedComponent)})`;

    return withTranslation()(withCharCounter);
};
