import React from 'react';

import { ViewEditAction } from '../../Common/List';

const getEditUserUrl = (user, queryString) => {
    const baseUrl = '/accounts/users/edit-user';

    return `${baseUrl}${queryString}&uuid=${user.User_UID}`;
};

export const usersToRows = (users, queryString, onConfirmModalOpen, hasManageUserAccess) => 
    users.map((user) => ({
        ...user,
        RoleText: user.IsOwner ? 'Account Owner' : user.Role_Name,
        Actions: user.IsOwner ? null : <ViewEditAction
            url={getEditUserUrl(user, queryString)}
            onConfirmModalOpen={onConfirmModalOpen}
            uuid={user.User_UID}
            hasManageUserAccess={hasManageUserAccess}
        />
    }
));
