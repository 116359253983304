import React, { useState, useEffect, useCallback } from 'react';
import { Section } from 'library/Section';
import { Paginate } from 'library/Paginate/Paginate';
import { useTranslation, Trans } from 'react-i18next';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { DevicesSearch } from './DevicesSearch';
import { DevicesList } from './DevicesList';
import { applySearchValue } from 'components/Common/Grid';
import { applyPagination } from '../../helpers';

import './DeviceListSection.css';

const INIT_PAGINATION_VALUES = { pageSize: 10, pageNumber: 0 };
const pageSizes = [10, 20, 50, 100];

const searchProperties = [
    'AccountOwnerEmailAddress',
    'BrandName',
    'CompanyName',
    'StoreNumber',
    'StoreName',
    'DeviceSerialNumber',
    'Status',
];

export const DeviceListSection = ({ sectionNumber, devices, deviceType, upgradeStatus, onCancelDevicesUpgrade }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [filteredDevices, setFilteredDevices] = useState(devices);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [cancelDeviceUpgradeIDs, setCancelDeviceUpgradeIDs] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);

    const isShowPaginate = !!filteredDevices.length;

    const onPaginateChange = useCallback(
        ({ page, recordsPerPage }) => {
            const devicesFilteredBySearch = applySearchValue(devices, searchProperties, search, {
                minLength: 1,
            });

            const newPaginationData = { pageSize: recordsPerPage, pageNumber: page };
            setFilteredDevices(applyPagination(devicesFilteredBySearch, newPaginationData));
            setRecordsTotal(devicesFilteredBySearch.length);
            setPaginationData(newPaginationData);
        },
        [applySearchValue],
    );

    const onCancelUpgrade = useCallback((deviceUpgradeIDs = []) => {
        if (deviceUpgradeIDs.length) {
            setCancelDeviceUpgradeIDs(deviceUpgradeIDs.map(Number));
            setIsConfirmPopupShown(true);
        }
    }, []);

    const onHide = useCallback(() => {
        setIsConfirmPopupShown(false);
    }, []);

    const onConfirm = useCallback(() => {
        onCancelDevicesUpgrade(cancelDeviceUpgradeIDs);
    }, [cancelDeviceUpgradeIDs, onCancelDevicesUpgrade]);

    useEffect(() => {
        const devicesFilteredBySearch = applySearchValue(devices, searchProperties, search, {
            minLength: 1,
        });

        setFilteredDevices(applyPagination(devicesFilteredBySearch, INIT_PAGINATION_VALUES));
        setRecordsTotal(devicesFilteredBySearch.length);
        setPaginationData(INIT_PAGINATION_VALUES);
    }, [devices, search, applySearchValue, searchProperties]);

    return (
        <Section section={sectionNumber} className="view-device-upgrade-device-list">
            <DevicesSearch
                devices={devices}
                upgradeStatus={upgradeStatus}
                selectedDevices={selectedDevices}
                search={search}
                onSearchChange={setSearch}
                onCancel={onCancelUpgrade}
            />
            <DevicesList
                isLoading={false}
                devices={filteredDevices}
                deviceType={deviceType}
                upgradeStatus={upgradeStatus}
                selectedDevices={selectedDevices}
                onDeviceSelection={setSelectedDevices}
                onCancelUpgrade={onCancelUpgrade}
            />
            {isShowPaginate && (
                <Paginate
                    total={recordsTotal}
                    page={paginationData.pageNumber}
                    onChange={onPaginateChange}
                    recordsPerPage={paginationData.pageSize}
                    pageSizes={pageSizes}
                    className="hme-stores-status--admin__paginate"
                />
            )}
            <ConfirmPopupComponent
                show={isConfirmPopupShown}
                message={
                    <>
                        <Trans i18nKey={cancelDeviceUpgradeIDs.length > 1
                            ? 'device-upgrades__warning__many-cancel-device-upgrade'
                            : 'device-upgrades__warning__single-cancel-device-upgrade'}
                        >
                            <div className="view-device-upgrade-device-list-confirm-line">
                                These devices will be removed from the batch upgrade.
                            </div>
                            <div>
                                Are you sure you want to cancel these device upgrades?
                            </div>
                        </Trans>
                    </>
                }
                onHide={onHide}
                onConfirm={onConfirm}
                cancelVerb={t('common__no-go-back')}
                confirmationVerb={t('common__yes-cancel-now')}
            />
        </Section>
    );
};
