import React, { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useOnTrigger } from 'hooks/useTrigger';

import { defaultMinValueLength } from 'library/AutoCompleteInput';
import { StoreInputView } from './StoreInputView';

const debounceTimeout = 700;

const loadStores = async (mask, getStores, setLoading, setStores) => {
    setLoading(true);

    try {
        const stores = getStores ? await getStores(mask) : [];
        setStores(stores);
    } catch (err) {
        setStores([]);
    }

    setLoading(false);
};

const loadStoresDebounced = debounce(loadStores, debounceTimeout);

export const StoreInput = ({ minValueLength = defaultMinValueLength, getStores, resetFormTrigger, onStoreChange, onChange, ...inputProps }) => {
    const [value, setValue] = useState('');
    const [stores, setStores] = useState([]);
    const [areStoresLoading, setAreStoresLoading] = useState(false);

    const onValueChange = useCallback((newValue) => {
        setValue(newValue);
        onChange && onChange(newValue);

        if (newValue.length >= minValueLength) {
            setAreStoresLoading(true);
            loadStoresDebounced(newValue, getStores, setAreStoresLoading, setStores);
        }
    }, [minValueLength, getStores, setValue, setAreStoresLoading, setStores]);

    const onSuggest = useCallback((newStore) => {
        onStoreChange && onStoreChange(newStore);

        if (!newStore) {
            setValue('');

            return;
        }

        setValue(newStore.Store_Number);
    }, [onStoreChange, setValue]);

    const onReset = useCallback(() => {
        setValue('');
        setStores([]);
    }, []);

    useOnTrigger(resetFormTrigger, onReset);

    return (
        <StoreInputView
            onStoreChange={onSuggest}
            onChange={onValueChange}
            value={value}
            stores={stores}
            isLoading={areStoresLoading}
            minValueLength={minValueLength}
            {...inputProps}
        />
    );
}
