import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { pluck } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useOnTrigger } from 'hooks/useTrigger';
import { Grid, resetFilters, getFiltersCount } from 'components/Common/Grid';
import compareVersions from 'helpers/sortComparators/version';
import compareStatuses from 'helpers/sortComparators/status';
import compareDates from 'helpers/sortComparators/date';
import {
    filterAccountTypeColumns,
    filterMobileAccountTypeColumns,
    validateGridAccountTypeObject,
} from 'helpers/filterAccountTypeColumns';
import { snapshotConfig } from 'constants/manageDeviceSettings';
import { accountTypes } from 'constants/accountTypes';
import { TileList } from 'library/TileList';
import { Actions } from '../Actions';
import { StatusCell } from './StatusCell';
import {
    MOBILE_SNAPSHOTS_HEADERS,
    MOBILE_SORT_OPTIONS,
    DEVICE_SETTINGS_GRID_OPTIONS,
    DEVICE_SETTINGS_FILTER_OPTIONS,
} from '../constants';
import { DateLib } from '@hme-cloud/utility-common';
import { FORMAT_TYPES } from 'helpers/DateLib/constants';

import './SnapshotsList.scss';

const { statusKeys, statuses } = snapshotConfig;

const statusTexts = {
    DRAFT: 'In Progress',
    IN_PROGRESS: 'In Progress',
    CREATED: 'Created',
    FAILED: 'Failed',
    SET_TO_EXPIRE: 'Set to Expire',
};

// TODO fix sorting functionality inside filters
const getSortComparators = (sortSelection = {}) => ({
    SettingsVersion: (a, b) => compareVersions(a.SettingsVersion, b.SettingsVersion, sortSelection.SettingsVersion),
    SourceSettingsVersion: (a, b) =>
        compareVersions(a.SourceSettingsVersion, b.SourceSettingsVersion, sortSelection.SourceSettingsVersion),
    SourceSoftwareVersion: (a, b) =>
        compareVersions(a.SourceSoftwareVersion, b.SourceSoftwareVersion, sortSelection.SourceSoftwareVersion),
    Status: (a, b) =>
        compareStatuses(a.Status, b.Status, sortSelection.Status, statusKeys, statusTexts),
    AppliedDateRaw: (a, b) => compareDates(a.AppliedDateRaw, b.AppliedDateRaw, sortSelection.AppliedDate),
    AppliedDate: (a, b) => compareDates(a.AppliedDate, b.AppliedDate, sortSelection.AppliedDate),
});

const defaultSortComparators = getSortComparators();

const ALL_CREATORS_FILTER_TEXT = 'common__all-users';
const ALL_VERSIONS_FILTER_TEXT = 'common__all-versions';
const ALL_BRANDS_FILTER_TEXT = 'common__all-brands';
const ALL_SOURCE_TYPES_FILTER_TEXT = 'settings_snapshots__grid__filter-all-source-types';
const ALL_SOURCE_SOFTWARE_VERSIONS_FILTER_TEXT = 'settings_snapshots__grid__filter-all-source-versions';
const ALL_LANE_TYPES_FILTER_TEXT = 'common__all-lane-types';
const ALL_STATUSES_FILTER_TEXT = 'common__all-statuses';

const filterOptions = {
    CreatedBy: {
        allText: ALL_CREATORS_FILTER_TEXT,
    },
    SettingsVersion: {
        allText: ALL_VERSIONS_FILTER_TEXT,
        sortComparator: defaultSortComparators.SettingsVersion,
    },
    SourceBrand: {
        allText: ALL_BRANDS_FILTER_TEXT,
    },
    SourceDeviceType: {
        allText: ALL_SOURCE_TYPES_FILTER_TEXT,
        accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR],
    },
    SourceSoftwareVersion: {
        allText: ALL_SOURCE_SOFTWARE_VERSIONS_FILTER_TEXT,
        sortComparator: defaultSortComparators.SourceSoftwareVersion,
    },
    SourceLaneConfig: {
        allText: ALL_LANE_TYPES_FILTER_TEXT,
    },
    Status: {
        allText: ALL_STATUSES_FILTER_TEXT,
    },
};

const getSnapshotRowClassName = ({ property, isActionsShown }) =>
    !isActionsShown && property === 'actions' ? 'hme-grid-empty-cell' : '';

const snapshotsToRows = ({ snapshots, permissionType, onSnapshotDelete, t }) => {
    const isAdminPage = permissionType === accountTypes.ADMIN;
    const isDistributorPage = permissionType === accountTypes.DISTRIBUTOR;

    return snapshots.map((snapshot) => {
        const isActionsShown = isAdminPage || (snapshot.CreatedBy_AccountID > 0);

        return {
            ...snapshot,
            IsBrandApproved: snapshot.IsBrandApproved ? t('common__yes') : t('common__no'),
            IsTestSnapshot: snapshot.IsTestSnapshot ? t('common__yes') : t('common__no'),
            AppliedDateRaw: snapshot.AppliedDate || snapshot.CreatedDate || '',
            AppliedDate: new DateLib(snapshot.AppliedDate || snapshot.CreatedDate).format(FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME),
            Status: <StatusCell status={snapshot.Status} />,
            Actions: isActionsShown ? <Actions snapshot={snapshot} onDelete={onSnapshotDelete} /> : null,
            hideViewButton: !isActionsShown,
            getCellClassName: (property, _) => getSnapshotRowClassName({ property, isActionsShown }),
            rowClassName: snapshot.Status === statuses.SET_TO_EXPIRE ? 'hme-grid-row--set-to-expire' : '',
            tileItemClassName: snapshot.Status === statuses.SET_TO_EXPIRE ? 'hme-tile-list-item--set-to-expire' : '',
        }
    });
}

const formatAvailableFilters = (filters, accountType) =>
    Object.keys(filters)
        .filter((property) => validateGridAccountTypeObject(filterOptions[property], accountType))
        .filter((property) => filterOptions[property])
        .map((property) => ({
            allText: filterOptions[property].allText,
            items: filters[property],
            property,
        }));

const formatGridFilters = (availableFilterList) =>
    availableFilterList.reduce((filtersObject, { property, items }) => {
        filtersObject[property] = pluck('value', items);

        return filtersObject;
    }, {});

export const SnapshotsList = ({
    permissionType,
    profile,
    snapshots,
    filters,
    sortSelection,
    onSnapshotDelete,
    onSortChange,
    onSortReset,
    isLoading,
    filtersCount,
    resetFiltersTrigger,
    onAppliedFiltersReset,
    onFiltersAndSortChange,
    onFiltersCountChange,
    onFiltersChange,
}) => {
    const [rows, setRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});
    const [isRendering, setIsRendering] = useState(false);

    const { t } = useTranslation();

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    const rowsMemo = useMemo(
        () =>
            snapshotsToRows({
                snapshots,
                permissionType,
                profile,
                onSnapshotDelete,
                t,
            }),
        [snapshots, onSnapshotDelete],
    );

    useEffect(() => {
        setIsRendering(true);
        setRows(rowsMemo);
    }, [rowsMemo]);

    useEffect(() => {
        if (filters) {
            const availableFilterList = formatAvailableFilters(filters, permissionType);

            setAvailableFilters(availableFilterList);
            setGridFilters(formatGridFilters(availableFilterList));
        }
    }, [filters]);

    const onFiltersChangeHandler = useCallback(
        (gridFilters) => {
            onFiltersChange(gridFilters);
            setGridFilters(gridFilters);
        },
        [setGridFilters, onFiltersChange],
    );

    const onFiltersAndSortChangeHandler = useCallback(
        ({ selectedFilters, selectedSortValue }) => {
            onFiltersAndSortChange({ selectedFilters, selectedSortValue });
            setGridFilters(selectedFilters);
        },
        [setGridFilters, onFiltersAndSortChange],
    );

    useEffect(() => {
        setIsRendering(false);
    }, [rows]);

    const onFiltersAndSortReset = useCallback(() => {
        onSortReset();
        onAppliedFiltersReset();
    }, [onSortReset, onAppliedFiltersReset]);

    return (
        <div className="device-settings-snapshots">
            <Grid
                headers={filterAccountTypeColumns({
                    columns: DEVICE_SETTINGS_GRID_OPTIONS,
                    accountType: permissionType,
                })}
                rows={rows}
                className="hme-l-size"
                isLoading={isLoading || isRendering}
                rowKey="SettingTemplateID"
                noRecordsMessage={t('settings_snapshots__no-snapshots--found')}
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={onFiltersChangeHandler}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
            />
            <TileList
                headers={filterMobileAccountTypeColumns({
                    columns: MOBILE_SNAPSHOTS_HEADERS,
                    accountType: permissionType,
                })}
                rows={rows}
                isLoading={isLoading || isRendering}
                noRecordsMessage="settings_snapshots__no-snapshots--found"
                rowKey="SettingTemplateID"
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersReset={onAppliedFiltersReset}
                filtersOptions={DEVICE_SETTINGS_FILTER_OPTIONS}
                filtersCount={filtersCount}
                sortSelection={sortSelection}
                sortOptions={MOBILE_SORT_OPTIONS}
                onFiltersAndSortChange={onFiltersAndSortChangeHandler}
                onFiltersAndSortReset={onFiltersAndSortReset}
                isSortingDropdown
            />
        </div>
    );
};
