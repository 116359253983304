import { useMemo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { BUTTON_VARIANTS } from 'library/Button';

import { useNotificationsCenterContext } from '../../Context';

const confirmButtonVariants = [BUTTON_VARIANTS.SUBMIT];

export const LeaderboardInvite = () => {
    const { t } = useTranslation();

    const {
        leaderboardInvite
    } = useNotificationsCenterContext();

    const {
        isAcceptModalShown,
        isDeclineModalShown,
        onAcceptConfirm,
        onDeclineConfirm,
        onAcceptHide,
        onDeclineHide
    } = leaderboardInvite;

    const onAcceptConfirmClick = useCallback(() => {
        onAcceptConfirm();
        onAcceptHide();
    }, [onAcceptConfirm, onAcceptHide]);

    const acceptActions = useMemo(() => [{
        children: t('common__popup--cancel-action'),
        onClick: onAcceptHide
    }, {
        children: t('common__popup--confirm-action'),
        variants: confirmButtonVariants,
        onClick: onAcceptConfirmClick
    }], [onAcceptHide, onAcceptConfirmClick, t]);

    const onDeclineConfirmClick = useCallback(() => {
        onDeclineConfirm();
        onDeclineHide();
    }, [onDeclineConfirm, onDeclineHide]);

    const declineActions = useMemo(() => [{
        children: t('common__popup--cancel-action'),
        onClick: onDeclineHide
    }, {
        children: t('common__popup--confirm-action'),
        variants: confirmButtonVariants,
        onClick: onDeclineConfirmClick
    }], [onDeclineHide, onDeclineConfirmClick, t]);

    return (
        <>
            <ConfirmPopupComponentGeneric
                show={isAcceptModalShown}
                title={t('merge_devices__popup__title')}
                message={<Trans i18nKey='notifications-center__modals--leaderboard-invite__accept-message' components={{
                    bold: <b />
                }} />}
                className="notifications-center"
                onHide={onAcceptHide}
                actions={acceptActions}
            />

            <ConfirmPopupComponentGeneric
                show={isDeclineModalShown}
                title={t('merge_devices__popup__title')}
                message={<Trans i18nKey='notifications-center__modals--leaderboard-invite__decline-message' components={{
                    bold: <b />
                }} />}
                className="notifications-center"
                onHide={onDeclineHide}
                actions={declineActions}
            />
        </>
    );
};
