import React, {useCallback, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationsList } from 'library/NotificationsList';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'library/Loader';
import { useLoadBrands } from 'hooks/useLoadBrands';
import { useLoadFeeds } from '../hooks/useLoadFeeds';
import { processDataFeedCreation } from '../Controller';
import { useLoadCloudAccounts } from '../hooks/useLoadCloudAccounts';
import { Header } from './Header';
import { DXSFeedsList } from './DXSFeedList';
import { FeedFormModal } from '../FeedFormModal';
import { FeedCreationForm } from '../FeedCreationForm';
import { formDataValidator } from '../helpers';

import './DXSFeeds.scss';

export const DXSFeeds = ({ permissionType }) => {
    const [feedSearchValue, setFeedSearchValue] = useState('');
    const [accountSearchValue, setAccountSearchValue] = useState('');
    const [isCreateFeedModalShown, setIsCreateFeedModalShown] = useState(false);
    const [newFeed, setNewFeed] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { feeds, isFeedsLoading, loadFeeds, feedsTotal } = useLoadFeeds();
    const { cloudAccounts, isCloudAccountsLoading } = useLoadCloudAccounts();
    const { brands, isBrandsLoading } = useLoadBrands();

    const onFeedCreationModalShow = () => {
        setIsCreateFeedModalShown(true);
    };

    const onFeedCreationModalHide = () => {
        setIsCreateFeedModalShown(false);
    };

    const onAccountSelected = ({ cloudAccountID }) => navigate(`accounts/${cloudAccountID}`);

    const onFeedCreation = useCallback(async () => {
        const errors = formDataValidator(newFeed);

        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});

        onFeedCreationModalHide();

        await processDataFeedCreation({ payload: newFeed, setIsLoading, t });
        loadFeeds();
    }, [newFeed, formErrors]);

    return (
        <div className="hme-components dxs-feeds-wrapper">
            <NotificationsList />
            <div className="dxs-feeds">
                {(isCloudAccountsLoading && isBrandsLoading) || isLoading ? (
                    <Loader />
                ) : (
                    <div className="dxs-feeds-content">
                        <Header
                            cloudAccounts={cloudAccounts}
                            feedSearchValue={feedSearchValue}
                            onFeedSearch={setFeedSearchValue}
                            accountSearchValue={accountSearchValue}
                            onAccountSearch={setAccountSearchValue}
                            onAccountSelected={onAccountSelected}
                            onFeedCreate={onFeedCreationModalShow}
                        />
                        <DXSFeedsList feeds={feeds} isLoading={isFeedsLoading} searchValue={feedSearchValue} />
                    </div>
                )}
            </div>
            <FeedFormModal
                title={t('dxs-feeds__header__create-new-feed')}
                show={isCreateFeedModalShown}
                onHide={onFeedCreationModalHide}
                actions={[
                    {
                        children: t('common__cancel'),
                        onClick: onFeedCreationModalHide,
                    },
                    {
                        children: t('common__submit'),
                        variants: ['submit'],
                        onClick: onFeedCreation,
                    },
                ]}
            >
                <FeedCreationForm
                    brands={brands}
                    formErrors={formErrors}
                    onFormChange={setNewFeed}
                    withAccountSearch
                />
            </FeedFormModal>
        </div>
    );
};
