/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import './SettingsSection.scss';
import { InputColorPicker } from 'components/Inputs/InputColorPicker/InputColorPicker';
import { Toggle } from 'library/Toggle';
import { Tip } from 'library/Tip';
import { Label } from 'library/Label';
import { Select } from 'library/Select';

const goalTypeValues = ['Master', 'Daypart'];
const daypartGoalType = ['Current Daypart', 'Previous Daypart', 'Current Hour', 'Previous Hour' ];

const toggles =[
    {
        buttons: [
            {
                text: 'display-settings__goal--show',
                value: false
            }, {
                text: 'display-settings__goal--hide',
                value: true
            }
        ],
        label: 'display-settings__title--goal',
        tooltipText: 'leaderboard__template__goal-display-tooltip',
        key: 'HideGoal'
    },
    {
        buttons: [
            {
                text: 'common__master',
                value: goalTypeValues[0]
            }, {
                text: 'common__daypart',
                value: goalTypeValues[1]
            }
        ],
        label: 'display-settings__goal-type',
        tooltipText: 'display-settings__tip--goal-type',
        key: 'GoalType'
    },
    {
        buttons: [
            {
                text: 'common__minute-seconds',
                value: 'minutes:seconds'
            }, {
                text: 'common__seconds',
                value: 'seconds'
            }
        ],
        label: 'common__time-format',
        tooltipText: null,
        key: 'TimeFormat'
    },
    {
        buttons: [
            {
                text: 'common__include',
                value: true
            }, {
                text: 'common__exclude',
                value: false
            }
        ],
        label: 'common__pull-ins',
        tooltipText: 'leaderboard__template__pull-ins-tooltip',
        key: 'IncludePullIns'
    },
    {
        buttons: [
            {
                text: 'common__lane-total',
                value: 1
            }, {
                text: 'common__lane-total-2',
                value: 2
            }
        ],
        label: 'common__metrics',
        tooltipText: null,
        key: 'Metric'
    }
];


export const SettingsSection = ({
    template: {
        DisplayColumnOneType: displayColumnOneType,
        DisplayColumnTwoType: displayColumnTwoType,
        HideGoal,
        TimeFormat,
        LanguageID,
        IncludePullIns,
        Metric,
        GoalAColor: goalAColor,
        GoalBColor: goalBColor,
        GoalCColor: goalCColor,
        GoalType
    } = {},
    onChange,
    col,
    number,
    disabled = false,
    title,
    className,
    isNitro,
    languages,
    isPreview,
    ...rest
}) => {
    const toggleValues = {
        HideGoal,
        TimeFormat,
        IncludePullIns,
        Metric,
        GoalType
    };
    const [displayGoalType, setDisplayGoalType] = useState(true);

    const onToggleChange = ( key ) => ( value ) => {
        onChange({ [key]: value });
    };
    const onInputChange = ( key ) => ( value ) => {
        onChange({ [key]: value || null });
    };
    const { t } = useTranslation();
    useEffect(() => {
        !isNitro && onChange({ 'GoalType': goalTypeValues[0] });
    }, []);
    useEffect(() => {
        const display =
            [displayColumnOneType, displayColumnTwoType]
                    .some((el) => el && daypartGoalType.includes(el));
        setDisplayGoalType(display);
        !display && onChange({ 'GoalType': goalTypeValues[0] });
    }, [displayColumnOneType, displayColumnTwoType]);

    const getToggleParams = ({ label, tooltipText, key }) => ({
        value: toggleValues[key],
        onChange: onToggleChange(key),
        isDisabled: isPreview,
        isPreview: isPreview,
        label:
            (<>
                {t(label)}&nbsp;
                {tooltipText &&
                    <Tip className={'leaderboard-templates-rest-settings-toggle-tip z-index-300'}>
                        {t(tooltipText)}
                    </Tip>
                }
            </>)
    });

    const onLngChange = useCallback((newLng) => {
        onChange({ LanguageID: newLng });
    }, [onChange]);

    return (
        <Section
            col={col}
            number={number}
            disabled={disabled}
            title = {t(title)}
            className = {className}
            {...rest}
        >
            <div className="hme-leaderboard-subsection-wrapper">
                <div className="hme-leaderboard-subsection-1">
                {toggles.slice(0, 2).map((params, i) => (<Toggle
                        key={i}
                        className={!isNitro && 'hidden'}
                        {...getToggleParams(params)}
                        isDisabled={isPreview || !displayGoalType && params.key === 'GoalType'}
                    >
                        {params.buttons}
                    </Toggle>))}

                    {!HideGoal && <>
                        <Label>{t('leaderboard__template__primary-goal-colors')}</Label>&nbsp;

                        <InputColorPicker
                            disabled={isPreview}
                            value={goalAColor}
                            label={`< ${t('common__goal--a')}`}
                            onChange={onInputChange('GoalAColor')}
                            className={isPreview ? 'disabled-color-picker' : ''}
                        >
                        </InputColorPicker>

                        <InputColorPicker
                            disabled={isPreview}
                            value={goalBColor}
                            label={`< ${t('common__goal--b')}`}
                            onChange={onInputChange('GoalBColor')}
                            className={isPreview ? 'disabled-color-picker' : ''}
                        >
                        </InputColorPicker>

                        <InputColorPicker
                            disabled={isPreview}
                            value={goalCColor}
                            label={`< ${t('common__goal--c')}`}
                            onChange={onInputChange('GoalCColor')}
                            className={isPreview ? 'disabled-color-picker' : ''}
                        >
                        </InputColorPicker>
                    </>}
                </div>
                <div className="hme-leaderboard-subsection-2">
                {toggles.slice(2, 3).map(({ buttons, label, tooltipText, value, key }, i) => (<Toggle
                        key={i}
                        value={toggleValues[key]}
                        onChange={onToggleChange(key)}
                        isDisabled={isPreview}
                        isPreview={isPreview}
                        label={
                            (<>
                                {t(label)}&nbsp;
                                {(key==='HideGoal' || key==='IncludePullIns') ?
                                (<Tip className={'leaderboard-templates-rest-settings-toggle-tip z-index-300'}>{t(tooltipText)}</Tip>)
                                :
                                <></>}
                            </>)
                        }
                    >
                        {buttons}
                    </Toggle>))}
                    <>
                        <Label>{t('language-select__label')}</Label>
                        <Select
                            variants={['no-label']}
                            value={LanguageID}
                            onChange={onLngChange}
                            isDisabled={isPreview}
                        >
                            {languages}
                        </Select>
                    </>
                    {toggles.slice(3).map(({ buttons, label, tooltipText, value, key }, i) => (<Toggle
                        key={i}
                        value={toggleValues[key]}
                        onChange={onToggleChange(key)}
                        isDisabled={isPreview}
                        isPreview={isPreview}
                        label={
                            (<>
                                {t(label)}&nbsp;
                                {(key==='HideGoal' || key==='IncludePullIns') ?
                                (<Tip className={'leaderboard-templates-rest-settings-toggle-tip z-index-300'}>{t(tooltipText)}</Tip>)
                                :
                                <></>}
                            </>)
                        }
                    >
                        {buttons}
                    </Toggle>))}
                </div>
            </div>
        </Section>

    );
};
