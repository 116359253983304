import { CommonConstants } from 'Constants';

const { deviceType } = CommonConstants;

export const deviceTypes = {
    ZOOM: 'zoom',
    ZOOM_NITRO: 'zoomNitro',
    NEXEO: 'nexeo'
};

export const deviceTypesTexts = {
    [deviceTypes.ZOOM]: 'common__device__type--zoom',
    [deviceTypes.ZOOM_NITRO]: 'common__device__type--zoom-nitro',
    [deviceTypes.NEXEO]: 'common__device__type--nexeo'
};

export const deviceTypesTranslationsMap = Object.keys(deviceType).reduce((acc, key) => {
    if (deviceTypesTexts[key]) {
        acc[deviceType[key].name] = deviceTypesTexts[key];
    }

    return acc;
}, {});

export const deviceTypeOptions = Object.keys(deviceTypesTexts).map((deviceType) => ({
    text: deviceTypesTexts[deviceType],
    value: deviceType
}));

export const deviceServerTypes = {
    IOT: 'IOT',
    DTDS: 'DTDS'
};