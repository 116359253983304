import { useMemo } from 'react';

import { useLeaderboardInvite } from './LeaderboardInvite/useLeaderboardInvite';

export const useProviderData = ({ loadNotifications, onAfterRead, isLoaded, notifications }) => {
    const leaderboardInvite = useLeaderboardInvite({ loadNotifications });

    const removeNotification = useMemo(() => ({
        onAfterRemove: loadNotifications,
    }), [loadNotifications]);

    const readNotification = useMemo(() => ({
        onAfterRead,
    }), [onAfterRead]);

    const active = useMemo(() => ({
        isLoaded,
        notifications,
    }), [isLoaded, notifications]);

    return useMemo(() => ({
        leaderboardInvite,
        isLoading: leaderboardInvite.isLoading || removeNotification.isRemoving,
        removeNotification,
        readNotification,
        active,
    }), [leaderboardInvite, removeNotification, readNotification, active]);
};
