import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'library/Modal';

import { checkMoveAllowed } from '../helpers/checkMoveAllowed';
import { Selected } from './Selected';
import { To } from './To';

import './MoveModal.scss';


export const MoveModal = ({ show, title = 'stores-hierarchy__btn--move', selectedItems, tree, onMove, onHide, ...props }) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [isNoGroup, setIsNoGroup] = useState(false);
    const [showMoveNotAllowedError, setShowMoveNotAllowedError] = useState(false);

    const isMoveAllowed = useMemo(()=> checkMoveAllowed(tree, selectedItem, selectedItems), [selectedItem, selectedItems]);

    const onMoveClick = useCallback(() => {
        setShowMoveNotAllowedError(!isMoveAllowed);
        if (isMoveAllowed) {
            onMove && onMove(isNoGroup ? null : selectedItem, selectedItems);
            onHide && onHide();
        }
    }, [isNoGroup, selectedItem, selectedItems, onMove, isMoveAllowed]);

    const onHideModal = useCallback(() => {
        setShowMoveNotAllowedError(false);
        onHide && onHide();
    }, [onHide]);

    const actions = useMemo(() => ([{
        children: t('common__cancel'),
        onClick: onHideModal
    }, {
        children: t('stores-hierarchy__btn--move'),
        disabled: !isNoGroup && !selectedItem,
        variants: ['submit'],
        onClick: onMoveClick
    }]), [t, isNoGroup, selectedItem, onMoveClick, onHideModal]);

    useEffect(() => {
        if (!show) {
            setSelectedItem(null);
            setIsNoGroup(false);
        }
    }, [show]);

    return (
        <Modal
            show={show}
            title={t(title)}
            dialogClassName="hme-manage-hierarchy__move-popup"
            onHide={onHideModal}
            actions={actions}
            {...props}
        >
            <Selected selectedItems={selectedItems} />
            <To
                selectedItems={selectedItems}
                tree={tree}
                selectedItem={selectedItem}
                isNoGroup={isNoGroup}
                onSelectItemChange={setSelectedItem}
                onIsNoGroupChange={setIsNoGroup}
                showMoveNotAllowedError={showMoveNotAllowedError}
            />
        </Modal>
    );
};
