import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { CommonConstants } from 'Constants';

import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { addErrorNotification } from 'services/Notifications';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

import { useLoadBrandDeviceUpgradeVersions } from './hooks/useLoadBrandDeviceUpgradeVersions';
import { useLoadBrand } from './hooks/useLoadBrand';
import { Header } from './Header';
import { Footer } from './Footer';
import { DeviceTypeSection } from './DeviceTypeSection';
import { ConfirmDetails } from './ConfirmDetails';
import {
    checkVersionsForChanges,
    formatAddAndRemoveVersionRows,
    mapAllVersionsByDeviceType,
    collectMissingUpgradeVersionIds,
} from './helpers';
import { saveBrandDeviceUpgradeVersions } from '../../Controller';
import { FIRMWARE_MANAGEMENT_PAGE_URL } from './constants';

const { deviceType } = CommonConstants;

import './ViewEdit.scss';

export const ViewEdit = ({ permissionType }) => {
    const { t } = useTranslation();
    const { brandID } = useParams();
    const navigate = useNavigate();
    const [sortedBrandDeviceUpgradeVersions, setSortedBrandDeviceUpgradeVersions] = useState([]);
    const [resultBrandDeviceUpgradeVersions, setResultBrandDeviceUpgradeVersions] = useState([]);
    const [isSaveConfirmShown, setIsSaveConfirmShown] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [addRemoveUpgradeVersionRows, setAddRemoveUpgradeVersionRows] = useState({});

    const { brand, isBrandLoading, brandLoadingError } = useLoadBrand(brandID);
    const {
        brandDeviceUpgradeVersions,
        isBrandDeviceUpgradeVersionsLoading,
        brandDeviceUpgradeVersionsLoadingError,
        loadBrandDeviceUpgradeVersions,
    } = useLoadBrandDeviceUpgradeVersions(brandID);

    const isLoading = isBrandDeviceUpgradeVersionsLoading || isBrandLoading || isSaving;

    const initialAllVersionsApprovedMap = useMemo(
        () => mapAllVersionsByDeviceType(brandDeviceUpgradeVersions),
        [brandDeviceUpgradeVersions],
    );

    const allVersionsApprovedMap = useMemo(
        () => mapAllVersionsByDeviceType(sortedBrandDeviceUpgradeVersions),
        [sortedBrandDeviceUpgradeVersions],
    );

    const onSave = useCallback(() => {
        setAddRemoveUpgradeVersionRows(
            formatAddAndRemoveVersionRows(brandDeviceUpgradeVersions, sortedBrandDeviceUpgradeVersions),
        );

        const resultUpgradeVersions = sortedBrandDeviceUpgradeVersions.map(({ deviceTypeID, versions, allVersionsApproved }) => ({
            deviceTypeID,
            versionIDs: versions.filter(({ selected }) => selected).map(({ id }) => id),
            allVersionsApproved,
        }));

        setResultBrandDeviceUpgradeVersions(resultUpgradeVersions);
        setIsSaveConfirmShown(true);
    }, [brandDeviceUpgradeVersions, sortedBrandDeviceUpgradeVersions, formatAddAndRemoveVersionRows]);

    const onCancel = useCallback(() => navigate(FIRMWARE_MANAGEMENT_PAGE_URL), [navigate]);

    const onSaveCancel = useCallback(() => {
        setIsSaveConfirmShown(false);
        setResultBrandDeviceUpgradeVersions([]);
    }, []);

    const onSaveConfirm = useCallback(async () => {
        setIsSaveConfirmShown(false);
        const payload = collectMissingUpgradeVersionIds(
            resultBrandDeviceUpgradeVersions,
            sortedBrandDeviceUpgradeVersions,
        );

        await saveBrandDeviceUpgradeVersions({
            brandID,
            payload,
            setIsLoading: setIsSaving,
            navigate,
            t,
        });
    }, [brandID, resultBrandDeviceUpgradeVersions, loadBrandDeviceUpgradeVersions, t, saveBrandDeviceUpgradeVersions, navigate]);

    // walk throught the device type upgrade versions and toggle the selected one
    const onToggleUpgradeVersion = useCallback((dtID, versionID, selected) => {
        setSortedBrandDeviceUpgradeVersions((currentSortedBrandDeviceUpgradeVersions) =>
            currentSortedBrandDeviceUpgradeVersions.map((deviceUpgradeVersion) =>
                deviceUpgradeVersion.deviceTypeID === dtID
                    ? {
                          ...deviceUpgradeVersion,
                          versions: deviceUpgradeVersion.versions.map((v) =>
                              v.id === versionID ? { ...v, selected } : v,
                          ),
                      }
                    : deviceUpgradeVersion,
            ),
        );
        setIsSaveEnabled(true);
    }, []);

    const onToggleAllVersionsApproved = useCallback((allVersionsApproved, deviceTypeID) => {
        setSortedBrandDeviceUpgradeVersions((prevDeviceUpgradeVersions) =>
            prevDeviceUpgradeVersions.map((typeUpgradeVersions) =>
                typeUpgradeVersions.deviceTypeID === deviceTypeID
                    ? {
                          ...typeUpgradeVersions,
                          allVersionsApproved,
                      }
                    : typeUpgradeVersions,
            ),
        );
    }, []);

    useEffect(() => {
        if (!brandDeviceUpgradeVersions?.length || !sortedBrandDeviceUpgradeVersions?.length) {
            return;
        }

        const hasVersionsChanged = checkVersionsForChanges(
            brandDeviceUpgradeVersions,
            sortedBrandDeviceUpgradeVersions,
        );

        setIsSaveEnabled(hasVersionsChanged);
    }, [sortedBrandDeviceUpgradeVersions]);

    useEffect(() => {
        if (brandLoadingError) {
            addErrorNotification(brandLoadingError.message);
            return;
        }

        if (brandDeviceUpgradeVersionsLoadingError) {
            addErrorNotification(brandDeviceUpgradeVersionsLoadingError.message);
            return;
        }
    }, [brandLoadingError, brandDeviceUpgradeVersionsLoadingError]);

    useEffect(() => {
        if (!brandDeviceUpgradeVersions?.length) {
            setSortedBrandDeviceUpgradeVersions([]);
            return;
        }

        const sortOrder = [deviceType.nexeo.id, NEXEO_PERIPHERAL_TYPES.IB7000.deviceTypeID, deviceType.zoomNitro.id];

        // sort sections by device types
        const sortedDeviceUpgradeVersions = [...brandDeviceUpgradeVersions]
            .map((item) => ({
                ...item,
                versions: item.versions,
                allVersionsApproved: item.allVersionsApproved,
            }))
            .sort((a, b) => sortOrder.indexOf(a.deviceTypeID) - sortOrder.indexOf(b.deviceTypeID));

        setSortedBrandDeviceUpgradeVersions(sortedDeviceUpgradeVersions);
    }, [brandDeviceUpgradeVersions]);

    if (isLoading) {
        return (
            <div className="hme-components firmware-management-view-wrapper loading">
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className="hme-components firmware-management-view-wrapper">
            <NotificationsList />
            <div className="firmware-management-view">
                <div className="firmware-management-view__content">
                    <Header brandName={brand?.Brand_Name} />
                    <div className="firmware-management-view__sections">
                        {sortedBrandDeviceUpgradeVersions.map(({ deviceTypeID, versions, allVersionsApproved }) => (
                            <DeviceTypeSection
                                key={deviceTypeID}
                                deviceTypeID={deviceTypeID}
                                versions={versions}
                                allVersionsApproved={allVersionsApproved}
                                onToggleVersion={onToggleUpgradeVersion}
                                onToggleAllVersionsApproved={onToggleAllVersionsApproved}
                            />
                        ))}
                    </div>
                </div>
                <Footer isSubmitEnabled={isSaveEnabled} onSave={onSave} onCancel={onCancel} />
                <ConfirmPopupComponentGeneric
                    className="firmware-management-view__confirm-popup"
                    show={isSaveConfirmShown}
                    title={t('common__double-checking')}
                    message={
                        <ConfirmDetails
                            addRemoveUpgradeVersionRows={addRemoveUpgradeVersionRows}
                            initialAllVersionsApprovedMap={initialAllVersionsApprovedMap}
                            allVersionsApprovedMap={allVersionsApprovedMap}
                            brandName={brand?.Brand_Name}
                        />
                    }
                    actions={[
                        {
                            children: t('common__popup--cancel-action'),
                            onClick: onSaveCancel,
                        },
                        {
                            children: t('common__popup--confirm-action'),
                            variants: ['submit'],
                            onClick: onSaveConfirm,
                        },
                    ]}
                />
            </div>
        </div>
    );
};
