import React from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { checkAccess } from 'actions/permissions';
import { isAdmin } from 'services/Auth';
import { CommonConstants } from 'Constants';

import { ViewEdit } from '../../Common';
import { useCreate } from './hooks/useCreate';

export const CreateRoleComponent = () => {
    const state = useCreate();

    return (
        <ViewEdit {...state} />
    );
};

const hasAccess = !isAdmin() && checkAccess(CommonConstants.userPermissions.ManageRole);

export const PublicCreateRole = compose(
        withHMELayout(),
        authenticate,
        withAccess({
            checkAccess: () => hasAccess
        })
)(CreateRoleComponent);
