/* eslint-disable react/prop-types */
import './Receivers.scss';
import React, { useCallback, useRef, useState } from 'react';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { useOutsideScroll } from 'hooks/useOutsideScroll';
import { Tooltip } from 'library/Tooltip';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const Receivers = ({
    el,
    scrollableRef,
    ...props
}) => {
    const { t } = useTranslation();
    const tipRef = useRef(null);
    const [isShown, setIsShown] = useState(false);

    const outClickHandler = useCallback(() => setIsShown(false), [setIsShown]);
    const outsideScrollHandler = () => {
        if (isShown) {
            setIsShown(false);
        }
    };

    useClickOutSide(tipRef, outClickHandler);
    useOutsideScroll(scrollableRef, outsideScrollHandler, [isShown]);

    const toggler = ({ target, type }) => {
        const shouldShow = type === 'mouseenter';
        const getWidth = (rects) => rects && Math.max(...[...rects].map((rect) => rect.right - rect.left));
        const rects = target && target.getClientRects();
        const targetWidth = getWidth(rects);
        const parentRects = tipRef.current && tipRef.current.getClientRects();
        const parentWidth = getWidth(parentRects);
        if (targetWidth < parentWidth && shouldShow) {
            return;
        }
        setIsShown(shouldShow);
    };
    return (
        <div
            className={classNames('hme-history-receivers', {
                'hme-tip-opened': isShown
            })}
            ref={tipRef}
        >
            <span
                className="history-to-column"
                onMouseEnter={toggler}
                onMouseLeave={toggler}
            >
                {el['to'].join(', ')}
                <Tooltip
                    {...props}
                    target={tipRef}
                    show={isShown}
                    placement={'right'}
                    className="hme-scrollable-tooltip"
                >
                    {
                        [t('common__to').toUpperCase()].concat(el['to']).map((rrs, i) =>
                            (<div key={`${rrs}-${i}`}>
                                {rrs}<br/>
                            </div>))
                    }
                </Tooltip>
            </span>
        </div>
    );
};
