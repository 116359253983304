import * as storeDetails from '../actionTypes/StoreDetails/storeDetails'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'

// Making a redux call to update the store.
function getStoreSuccess (stores) {
  return {
    type: storeDetails.INIT_STORES_VIEW_DETAILS,
    storeViewDetails: stores
  }
}

// Making a redux call to update the store.
function getUpgradeDateSuccess (updateDate) {
  return {
    type: storeDetails.GET_DEVICEUPGRADE,
    storeViewDetails: updateDate
  }
}

// Fetching the store details.
export const initViewStore = (duid, callback) => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getSettingsDevices + '?duid=' + duid
  return (dispatch) => {
    const api = new Api()
    api.getData(url, data => {
      if (data.status === true) {
        dispatch(getStoreSuccess(data))
        callback()
      }
    })
  }
}

// Making a redux action call.
export const getStoreDetails = (duid, callback) => {
  return dispatch => Promise.resolve(dispatch(initViewStore(duid, callback)))
}

export const getUpgradeDate = (deviceUID) => {
  let url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDeviceUpgradeDate}?deviceUID=${deviceUID}`
  return (dispatch, getData) => {
    const api = new Api()
    api.getData(url, data => {
      if (data.status) {
        dispatch(getUpgradeDateSuccess(data))
      }
    })
  }
}
