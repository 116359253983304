import React from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Header } from './Header';
import { useLoadDevice } from './hooks/useLoadDevices';
import { withHMELayout } from 'HOCs/withHMELayout';
import { formatDisplayData } from './helpers';
import { useLocation } from 'react-router-dom';
import CommonLoader from 'components/Alerts/CommonLoader';
import { CenterErrorMessage } from 'components/Common/CenterMessages';
import DeviceDetails from './DeviceDetails/DeviceDetails';

import './SystemStatus.scss';

const SystemStatus = () => {
    const { t } = useTranslation();
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const duid = query.get('duid');
    const { device, isDeviceLoading, deviceLoadingError } = useLoadDevice(duid);

    const formattedDisplay = formatDisplayData(device);

    if (deviceLoadingError) {
        return <CenterErrorMessage>{t('system-status__message__loading-device-error')}</CenterErrorMessage>;
    }

    return (
        <>
            {isDeviceLoading ?
                <CommonLoader showLoader={true} message='common__loading' /> :
                <>
                    <Header device={device} />
                    <div className='hme-public-system-status-linkwear-section-wrapper'>
                        <DeviceDetails formattedDisplay={formattedDisplay} device={device} />
                    </div>
                </>
            }
        </>
    );
};

export const PublicSystemStatus = compose(
    withHMELayout({
        contentClasses: ['public-system-status-linkwear-page'],
    }),
)(SystemStatus);
