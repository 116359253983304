import { useEffect } from 'react';

export const useSetFileNames = ({ multiple = false, value, setFileNames }) => {
    useEffect(() => {
        if (!value) {
            return;
        }

        const files = multiple ? value : [value];
        const names = files.filter(file => file).map(file => file.name);

        setFileNames(names.length ? names : ['']);
    }, [multiple, value, setFileNames]);
};
