import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'library/MultiSelect';

import './BrandSelect.css';

export const BrandSelect = ({ label, brands, value, deviceTypeID, onChange }) => {
    const { t } = useTranslation();
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [brandsFiltered, setBrandsFiltered] = useState(brands);
    const [searchValue, setSearchValue] = useState('');

    const onBrandSelect = (selection) => {
        onChange(selection);
        setSelectedBrands(selection);
    };

    const onBrandSearchChange = useCallback((value) => {
        setSearchValue(value);
        const valueLC = value.toLowerCase();
        setBrandsFiltered(brands.filter((brand) => brand.Name.toLowerCase().includes(valueLC)));
    }, [value]);

    const brandOptions = useMemo(
        () =>
            brandsFiltered.map(({ Id, Name }) => ({
                text: Name,
                value: Id,
            })),
        [brandsFiltered],
    );

    return (
        <div className="hme-device-upgrade-brand-select">
            <MultiSelect
                searchValue={searchValue}
                searchMinSymbols={0}
                items={brandOptions}
                selections={selectedBrands}
                onSelectionChange={onBrandSelect}
                onSearchValueChange={onBrandSearchChange}
                variants={['search']}
                title={'common__brand'}
                labelTotal={'common__brand-s'}
                placeholder="common__select-item"
                noRecordsMessage="common__no-brands--found"
                shouldClearSearchOnFocus
                withShowMore={false}
                withSelectAll
            />
        </div>
    );
};
