import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Grid } from 'components/Common/Grid';

import './AccountBrandGrid.css';

const gridHeaders = [
    {
        text: 'device-upgrades__grid__header--brand',
        property: 'brandName',
    },
    {
        text: 'device-upgrades__grid__header--number-of-devices',
        property: 'quantity',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell',
    },
];

export const AccountBrandGrid = ({ accountStores = [], selectedAccountStores, onAccountStoreSelection }) => {
    const brandRows = useMemo(() => {
        // group store devices by brands
        const brandDevicesMap = accountStores.reduce((acc, store) => {
            const accountBrandKey = `${store.Brand_Name}|${store.User_Company_ID}`;

            // Check if this brand-combo exists already
            if (!acc[accountBrandKey]) {
                acc[accountBrandKey] = {
                    brandName: store.Brand_Name,
                    quantity: 0,
                    deviceIDs: [],
                };
            }

            // Increment the quantity for this brand-combo
            acc[accountBrandKey].quantity += 1;

            // Append the Device_ID to the deviceIDs for this brand-combo
            acc[accountBrandKey].deviceIDs.push(store.Device_ID);

            return acc;
        }, {});

        return Object.keys(brandDevicesMap).map((accountBrandKey) => {
            // provide the same store brand entity in case it is already selected (for marking the selection as checked)
            const alreadySelectedStoreBrand = selectedAccountStores.find((sas) => sas.accountBrandKey === accountBrandKey);

            return (
                alreadySelectedStoreBrand || {
                    accountBrandKey,
                    brandName: brandDevicesMap[accountBrandKey].brandName,
                    quantity: brandDevicesMap[accountBrandKey].quantity,
                    deviceIDs: brandDevicesMap[accountBrandKey].deviceIDs,
                }
            );
        });
    }, [accountStores]);

    const deviceCount = useMemo(() => brandRows.reduce((acc, brand) => acc + brand.quantity, 0), [brandRows]);

    return (
        <div className="hme-create-device-upgrade-account-brand">
            <div className="hme-create-device-upgrade-account-brand-info">
                <Trans
                    i18nKey={'device-upgrades__section__filter__account__count-devices'}
                    deviceCount={deviceCount}
                    brandCount={brandRows.length}
                >
                    There are <span className="hme-create-device-upgrade-account-brand-count">{{ deviceCount }}</span>{' '}
                    devices across <span className="hme-account-brand-count">{{ brandCount: brandRows.length }}</span>{' '}
                    brands under this account.
                </Trans>
            </div>

            <Grid
                rows={brandRows}
                headers={gridHeaders}
                rowKey="brandName"
                isLoading={false}
                selectable
                selection={selectedAccountStores}
                onSelectionChange={onAccountStoreSelection}
            />
        </div>
    );
};
