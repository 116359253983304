import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { Label } from 'library/Label';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { Checkbox } from 'library/Checkbox';
import { SearchInput } from 'components/Inputs/SearchInput';
import { toItemsArray } from 'helpers/tree/toItemsArray';
import { applySearchValue } from 'components/Common/Grid';

import { getAllInnerItems } from '../../helpers/getAllInnerItems';
import { HierarchyItem } from '../../HierarchyBlock/HierarchyItem';

import './To.scss';

const suggestionHeaders = [{
    text: 'common__group__name',
    property: 'text',
}];

export const To = ({ tree, selectedItems, selectedItem, isNoGroup, onSelectItemChange, onIsNoGroupChange, showMoveNotAllowedError }) => {
    const { t } = useTranslation();

    const [searchString, setSearchString] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const availableItems = useMemo(() => {
        const items = toItemsArray(tree).filter(({ Type }) => Type === storeHierarchyNodeTypes.GROUP);
        const disabledItems = getAllInnerItems(selectedItems).filter(({ Type }) => Type === storeHierarchyNodeTypes.GROUP);

        return items
            .filter((item) => !disabledItems.includes(item))
            .map((item) => ({
                item,
                Name: item.Name,
                text: <HierarchyItem item={item} />
            }));
    }, [tree, selectedItems]);

    const onSuggested = useCallback((item) => {
        onSelectItemChange(item.item);
        setSearchString(item.item.Name);
    }, []);

    const onSearchBlur = useCallback(() => {
        if (selectedItem) {
            setSearchString(selectedItem.Name);
        }
    }, [selectedItem]);

    const onKeyPress = useCallback((e) => {
        if (e.key === 'Enter') {
            setSearchValue(searchString);
        }
    }, [searchString]);

    const onChange = useCallback((value) => {
        setSearchString(value);

        if (value.length < 3) {
            setSearchValue('');

            return;
        }

        setSearchValue(value);
    }, []);

    useEffect(() => {
        if (searchValue.length === 0) {
            setSuggestions([]);

            return;
        }

        const newSuggestions = applySearchValue(availableItems, ['Name'], searchValue, {
            minLength: 1,
        }).slice(0, 3);

        setSuggestions(newSuggestions);
    }, [searchValue]);

    return (
        <div className='hme-manage-hierarchy__move-popup__to'>
            <Label className='hme-manage-hierarchy__move-popup__label'>{t('common__to')}</Label>
            <AutoCompleteInput
                value={searchString}
                isDisabled={isNoGroup}
                minValueLength={searchValue.length < 3 && searchValue.length !== 0 ? searchValue.length : 3}
                onChange={onChange}
                Component={SearchInput}
                headers={suggestionHeaders}
                suggestions={suggestions}
                noSuggestionText={t('stores-hierarchy__move-popup__no-groups')}
                onSuggested={onSuggested}
                onBlur={onSearchBlur}
                onKeyPress={onKeyPress}
            />
            {
                showMoveNotAllowedError &&
                <Label className="hme-manage-hierarchy__move-popup__to__error">
                    { t('stores-hierarchy__move__level-error')}
                </Label>
            }
            {
                selectedItem && !isNoGroup &&
                    <>
                        <Label className='hme-manage-hierarchy__move-popup__to__review'>
                            {t('stores-hierarchy__move-popup__review')}
                        </Label>
                        <HierarchyItem item={selectedItem} className='hme-manage-hierarchy__move-popup__to__selected' />
                    </>
            }
            <Checkbox label={t('stores-hierarchy__move-popup__no-group')} checked={isNoGroup} onChange={onIsNoGroupChange} />
        </div>
    );
};
