import React from 'react';
import { ContactInfo } from './ContactInfo';
import { HmeDetails } from './HmeDetails';

import './CompanyInfoView.scss';

export const CompanyInfoView = ({ infoData, countries, regions, distributors, shouldShowDistributors }) => {
    const hasCompanyDistributor = !!infoData.distributorId;

    return (
        <>
            <ContactInfo infoData={infoData} countries={countries} regions={regions} />
            {shouldShowDistributors && hasCompanyDistributor && (
                <HmeDetails infoData={infoData} distributors={distributors} />
            )}
        </>
    );
};
