import React from 'react';
import classNames from 'classnames';

import { PureButton } from 'library/PureButton';

export const ToolbarButton = ({ active = false, onClick, className = '', ...props }) => {
    return (
        <PureButton
            onClick={onClick}
            className={classNames('toolbar-item', { active }, className)}
            {...props}
        />
    );
};
