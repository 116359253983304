import React from 'react';

import { Button } from 'library/Button';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

import './DownloadLink.scss';

export const DownloadLink = ({ children, ...props }) => (
    <Button variants={['transparent', 'download-link']} {...props}>
        <DownloadIcon className='hme-download-link-icon' />
        {children}
    </Button>
);
