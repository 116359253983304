import { Constant } from '@hme-cloud/utility-common';
import React, { useState } from 'react';
import { SnapshotForm } from '../SnapshotForm';
import { SourceDeviceSettings } from '../SourceDeviceSettings';

const { DeviceType } = Constant;
const snapshotsDefault = { nexeo: [] };

export const ZoomNitroSnapshotForm = ({
    params,
    devices,
    onParamsChange,
    isSnapshotsLoading,
    snapshots = snapshotsDefault,
}) => {
    const { settingTemplateID: sourceSettingTemplateID, allStores, sourceDeviceSettingGroups } = params;
    const [targetDevices, setTargetDevices] = useState([]);
    const [settingTemplateID, setSettingTemplateID] = useState(null);
    const onSnapshotChange = (newParams) => {
        onParamsChange({
            ...newParams,
            targetDevices: devices
        });

        setSettingTemplateID(newParams.settingTemplateID);
        setTargetDevices(devices);
    }

    const onSourceDeviceSettingGroupChanged = (settingGroupsToApply) => {
        onParamsChange({
            ...params,
            sourceDeviceSettingGroups: settingGroupsToApply,
            targetDevices: devices,
        });
    };

    return (
        <>
            <SnapshotForm
                deviceType={DeviceType.ZOOMNITRO.camelCase}
                params={params}
                onParamsChange={onSnapshotChange}
                snapshots={snapshots.zoomNitro}
                isSnapshotsLoading={isSnapshotsLoading}
            />
            {
                settingTemplateID
                ? <div>
                    <SourceDeviceSettings
                        devices={allStores}
                        values={sourceDeviceSettingGroups}
                        onChange={onSourceDeviceSettingGroupChanged}
                    />
                </div> 
                : null
            }
        </>
    );
};
