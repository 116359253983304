import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Label } from 'library/Label';

import { UserEmails } from '../../../../Common/UserEmails';

import './AccountLink.scss';

export const AccountLink = ({ store, className }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-store-details-account-link', className)}>
            <Label>{t('common__account__email')}</Label>
            <UserEmails store={store} />
        </div>
    );
};
