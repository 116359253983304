/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'library/Toggle';
import { SINGLE_SELECT_VARIANTS, SingleSelect } from 'library/SingleSelect';

import { FieldsetTitle } from '../FieldsetTitle';

const defaultSelectVariants = [SINGLE_SELECT_VARIANTS.MOBILE_LABEL_INSIDE];

export const SectionTwo = ({
    timezoneID,
    timezones,
    recurrences,
    recurrenceID,
    RECURRENCE_VALUES,
    reportTypes,
    timeFormats,
    timeFormat,
    pullInsList,
    pullIns,
    timeMeasures,
    weekID,
    monthID,
    weekDays,
    monthDays,
    formValues,
    setFormValues
}) => {
    const { t } = useTranslation();

    const {
        reportType = 'single',
        timeMeasureID
    } = formValues;

    const toggleValues = {
        timeFormat,
        pullIns
    };

    const handleTimeMeasureIDChange = (newTimeMeasureID) => {
        setFormValues({
            timeMeasureID: Number(newTimeMeasureID)
        });
    };

    const handleReportTypeChange = (newReportType) => {
        const isSelectDefault = t('common__select') === newReportType;
        setFormValues({
            reportType: !isSelectDefault ? newReportType : ''
        });
    };

    const handlePullInsChange = (key) => (value) => {
        setFormValues({
            [key]: value
        });
    };

    const handleChangeTimezone = (event) => {
        const { text, value } = timezones[event];
        const code = text.substring(4, 7);
        setFormValues({
            timezoneID: value,
            timezone_code: code
        });
    };
    const handleChangeRecurrence = (event) => {
        const { value } = recurrences.find((recurrence)=> recurrence.value === Number(event));
        setFormValues({
            recurrenceID: value,
            monthID: null,
            weekID: null
        });
    };

    const handleChangeComplement = (day, recurrence) => {
        const source = recurrence === RECURRENCE_VALUES.MONTHLY ? monthDays : weekDays;
        const { value } = source[day];
        if (recurrence === RECURRENCE_VALUES.MONTHLY) {
            setFormValues({
                monthID: value,
                weekID: null
            });
        } else if (recurrence === RECURRENCE_VALUES.WEEKLY) {
            setFormValues({
                weekID: value,
                monthID: null
            });
        }
    };

    const isMultiStoreReport = reportType === 'multi';
    const isSingleStoreReport = reportType === 'single';

    return (
        <>
            <FieldsetTitle>{t('scheduler__create-header-two')}</FieldsetTitle>

            <div className="create-edit-scheduler__fields-wrapper">
                <SingleSelect
                    isRequired={true}
                    value={reportType}
                    placeholder="common__select"
                    onChange={handleReportTypeChange}
                    label={t('common__type')}
                    items={reportTypes}
                    variants={defaultSelectVariants}
                />

                <Toggle
                    value={toggleValues[pullInsList.key]}
                    onChange={handlePullInsChange(pullInsList.key)}
                    label={t(pullInsList.label)}
                >
                    {pullInsList.buttons}
                </Toggle>

                <Toggle
                    value={toggleValues[timeFormats.key]}
                    onChange={handlePullInsChange(timeFormats.key)}
                    label={t(timeFormats.label)}
                >
                    {timeFormats.buttons}
                </Toggle>

                {(isMultiStoreReport || isSingleStoreReport) && (
                    <SingleSelect
                        isRequired={true}
                        value={timeMeasureID}
                        placeholder="common__select"
                        onChange={handleTimeMeasureIDChange}
                        label={t('reports__time-measure')}
                        items={timeMeasures}
                        variants={defaultSelectVariants}
                    />
                )}

                <SingleSelect
                    isRequired={true}
                    value={timezoneID}
                    placeholder="common__select"
                    onChange={(event) => handleChangeTimezone(event)}
                    label={t('common__timezone')}
                    items={timezones}
                    variants={defaultSelectVariants}
                />

                <SingleSelect
                    isRequired={true}
                    value={recurrenceID}
                    placeholder="common__select"
                    onChange={(event) => handleChangeRecurrence(event)}
                    label={t('scheduler__recurrence')}
                    items={recurrences}
                    variants={defaultSelectVariants}
                />

                {recurrenceID === RECURRENCE_VALUES.WEEKLY && (
                    <SingleSelect
                        isRequired={true}
                        value={weekID}
                        placeholder="common__select"
                        onChange={(event) => handleChangeComplement(event, recurrenceID)}
                        label={t('scheduler__day-of-week')}
                        items={weekDays}
                        variants={defaultSelectVariants}
                    />
                )}

                {recurrenceID === RECURRENCE_VALUES.MONTHLY && (
                    <SingleSelect
                        isRequired={true}
                        value={monthID}
                        placeholder="common__select"
                        onChange={(event) => handleChangeComplement(event, recurrenceID)}
                        label={t('scheduler__day-of-month')}
                        items={monthDays}
                        variants={defaultSelectVariants}
                    />
                )}
            </div>
        </>
    );
};
