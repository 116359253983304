import React, { Component } from 'react'
import './Stores.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import Unregistersystem from '../Actions/Unregistersystem'
import CommonFunctions from '../Common/Functions'
import { checkAccess } from '../../actions/permissions'
import { CommonConstants } from '../../Constants'
import AuthFactory from '../../helpers/AuthFactory'
import { DeviceStatus } from './DeviceStatus'

const { ion: {name: ionName}, eos: {name: eosName}, linkWear: {name: linkWearName} } = CommonConstants.deviceType;
const remoteConnectDeviceTypes = [ionName, eosName];
const allowedDeviceTypes = [
  { type: ionName , deviceTypeTKey: 'common__device__type--ion' },
  { type: eosName, deviceTypeTKey: 'common__device__type--eos' },
  { type: linkWearName, deviceTypeTKey: 'common__device__type--link-wear' },
];

class Device extends Component {
  constructor (props) {
    super(props)

    this.state = {
      disableRemove: true,
      deviceIDs: [],
    };

    this.handleViewDevice = this.handleViewDevice.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.enableRemoveBtn = this.enableRemoveBtn.bind(this);
    this.commonFunctions = new CommonFunctions();
    this.authService = AuthFactory.AuthService;
  }

  handleViewDevice (id) {
    this.props.viewDevice(id)
  }

  enableRemoveBtn (e, deviceId, active) {
    const disableRemove = this.commonFunctions.enableRemoveBtn(e, deviceId, active)
    const deviceIDs = this.state.deviceIDs
    const input = {
      isChecked: e.currentTarget.checked,
      device_ID: deviceId,
      isActive: active
    }
    deviceIDs.push(input)
    this.setState({
      disableRemove,
      deviceIDs,
    })
  }

  // Renders the data.
  renderRows () {
    const isAdmin = this.authService.isAdmin()
    const { t } = this.props;

    const devices = this.props.stores.Device_Details.filter((el) => {
      if (this.props.device) {
        return allowedDeviceTypes.map(item => item.type).includes(el.Device_Name) &&
         this.props.device === el.Device_Name;
      }
    });
    
    let rows = devices.map((data, index) => {
      const {
        Device_ID: deviceID,
        Device_IsActive: deviceIsActive,
        Device_Name: deviceName
      } = data;

      const isRemoteConnectAllowed = remoteConnectDeviceTypes.includes(deviceName);

      return (
        <tr className='device-row-data' key={data.Device_SerialNumber}>
          <td className='device-checkbox'>
          <td className='device-checkbox'>
            <input type='checkbox' name='checkbox' id='idname' className={'device-check-input ' + (isAdmin ? 'show' : 'hide')}
              value={deviceID} onChange={e => this.enableRemoveBtn(e, deviceID, deviceIsActive)} />
          </td>
          </td>
          <td >{data.Device_SettingVersion}</td>
          <td >{data.Device_SerialNumber}</td>
          <DeviceStatus device={data} allowRemoteConnect={isRemoteConnectAllowed} />
          <td onClick={() => this.handleViewDevice(data.Device_UID)}><a className='device-view-link'> {t('stores__modal__view-details')} </a></td>
        </tr>
      )
    })
    return rows
  }

  // Main render function.
  render () {
    const {
      disableRemove,
      deviceIDs,
    } = this.state;
    const {
      stores: {
        storeDetails: {
          User_EmailAddress: userEmailAddress,
          Store_UID: storeUID,
        } = {},
        Device_Details: deviceDetails
      } = {},
      t,
    } = this.props;

    const isAdmin = this.authService.isAdmin();
    const deviceList = this.commonFunctions.removeDuplicateDevices(deviceIDs);
    const { deviceTypeTKey = '' } = allowedDeviceTypes.find(item => item.type === this.props.device) || {};

    return (
      <div>
        <h4 className='header'> { t('common__device--registered', { deviceType: t(deviceTypeTKey) }) }</h4>
        <table className='device-list-table'>
          <tbody>
            <tr className='theader'>
              <th />
              <th>{t('common__system__version')}</th>
              <th>{t('common__device__serial-number--text')}</th>
              <th>{t('common__system__status')}</th>
              <th className='device-view' />
            </tr>
            {this.renderRows()}
          </tbody>
        </table>
        <div className={'remove-sys ' + (isAdmin && checkAccess(CommonConstants.adminPermissions.RemoveDevice) ? 'show' : 'hide')}>
          <Unregistersystem
            userEmail={userEmailAddress}
            system={deviceDetails}
            deviceList={deviceList}
            display={disableRemove}
            deviceName={this.props.device}
            deviceStoreUID={storeUID} />
        </div>
      </div>
    )
  }

}/**/
// Setting the state from redux store.
function mapStateToProps (state) {
  return {
    stores: state.StorePopupDetails.storePopupDetails
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(Device)
