import React, { Component } from 'react';
import { StoresSelector } from '../Inputs';
class SelectStores extends Component {
   render() {
    const { data: tree, checkedStores, setCheckedStores, title, isHidden, disabled, inputStyles } = this.props;
    return (
      <div className={isHidden ? `${isHidden}` : 'storeAssignment'}>
            <StoresSelector
              title={title}
              disabled={disabled}
              value={checkedStores}
              tree={tree}
              filters={['search']}
              actions={['selectAll']}
              inputStyles={inputStyles}
              onChange={selectedStoreUIDs => setCheckedStores(selectedStoreUIDs)}
            />
      </div>
    );
  }
}

export default SelectStores;
