import React, { useState, useEffect, useCallback } from 'react';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { Toggle } from 'library/Toggle';
import { ErrorLabel } from 'library/ErrorLabel';
import { Title } from 'library/Title';
import { TextareaInput } from 'library/TextareaInput';
import { SSOConfigLabels, SSOType } from '../../helpers/constants';
import './AuthenticationSection.scss';
import { InputComponent } from 'components/Inputs';
import { maskPublicKey } from 'helpers/maskPublicKey';
import { getSSODetails } from 'pages/SSOConfiguration/services';
import { validateDomain } from '../../helpers/formDataValidator';

const SSO_TEST_PASS_STATUS = 'VERIFIED';

export const AuthenticationSection = ({ formErrors, onChange, accountID, setIsLoading, setIsTested }) => {
    const { t } = useTranslation();

    const [SSOID, setSSOID] = useState('');
    const [domain, setDomain] = useState('');
    const [authMethod, setAuthMethod] = useState(SSOType[0].value);
    const [ssoTargetUrl, setSsoTargetUrl] = useState('');
    const [publicCertificateField, setPublicCertificateField] = useState('');// this field is going to be masked
    const [publicCertificatePayload, setPublicCertificatePayload] = useState('');// field contains actual value when certFieldChanged = true
    const [certFieldChanged, setCertFieldChanged] = useState(false);
    const [certKey, setCertKey] = useState('');
    const [logoutRedirect, setLogoutRedirect] = useState('');

    const mask = (e) => setPublicCertificateField(maskPublicKey(e.target.value) || '');

    const onCertFieldChange = useCallback((e) => {
        setPublicCertificateField(e);
        setPublicCertificatePayload(e);
        setCertFieldChanged(true);
    }, []);

    useEffect(() => {
        onChange({
            SSOID,
            domain,
            authMethod,
            ssoTargetUrl,
            certKey,
            logoutRedirect,
            certFieldChanged
        });
    }, [domain, authMethod, ssoTargetUrl, logoutRedirect, certKey, certFieldChanged]);

    useEffect(() => { // will run when onCertFieldChange runs
        onChange({
            publicCertificatePayload
        });
    }, [publicCertificatePayload]);

    useEffect(() => {
        // eslint-disable-next-line require-jsdoc
        async function getDetails() {
            try {
                setIsLoading(true);
                if (accountID) {
                    const ssoDetails = await getSSODetails(accountID);
                    if (!ssoDetails.status) {
                        setSSOID(ssoDetails.SSOID || '');
                        setDomain(ssoDetails.DomainName || '');
                        // setAuthMethod(ssoDetails.SSOMethod || ''); // to be used later
                        setSsoTargetUrl(ssoDetails.TargetURL || '');
                        setPublicCertificatePayload(ssoDetails.X509Key || '');
                        mask({
                            target: {
                                value: ssoDetails.X509Key || ''
                            }
                        });
                        setCertKey(ssoDetails.X509CertKeyName || '');
                        setLogoutRedirect(ssoDetails.LogoutURL || '');
                        setIsTested(ssoDetails.SSOConfigStatus === SSO_TEST_PASS_STATUS);
                    }
                }
            } catch (e) {
                console.error('Could not fetch SSO details', e);
            }
        }
        getDetails();
        setIsLoading(false);
    }, [accountID]);

    return (
        <Section className="hme-section-sm">
            <div className="hme-section-separation">
                <Title>{t('settings_sso_configuration_authentication_information')}</Title>
                <div className="sso-form-input">
                    <Label>{t('settings_sso_configuration_domain_label')}</Label>
                    <InputComponent
                        value={domain}
                        isRequired={true}
                        readOnly={true}
                        onChange={(e) => validateDomain(e, setDomain)}
                        id="domain"
                    />
                    <div>
                        {formErrors.domain && <ErrorLabel>{t(formErrors.domain)}</ErrorLabel>}
                    </div>
                </div>

                <div className="sso-form-input">
                    <Label>{t('settings_sso_configuration_authentication_type_label')}</Label>
                    <Toggle
                        value={authMethod}
                        // onChange={setAuthMethod} // to be used later
                        id="authMethod"
                    >
                        {SSOType}
                    </Toggle>
                </div>

                <div className="sso-form-input">
                    <Label>{SSOConfigLabels['X509']}</Label>
                    <TextareaInput
                        value={publicCertificateField}
                        required={true}
                        rows={10}
                        onChange={onCertFieldChange}
                        onBlur={mask}
                        id="publicCertificate"
                    />
                    <div>
                        {formErrors.publicCertificateField && <ErrorLabel>{t(formErrors.publicCertificateField)}</ErrorLabel>}
                    </div>
                </div>

                <div className="sso-form-input">
                    <Label>{SSOConfigLabels['SSO_TARGET_URL']}</Label>
                    <InputComponent
                        value={ssoTargetUrl}
                        isRequired={true}
                        onChange={setSsoTargetUrl}
                        id="ssoTargetUrl"
                    />
                    <div>
                        {formErrors.ssoTargetUrl && <ErrorLabel>{t(formErrors.ssoTargetUrl)}</ErrorLabel>}
                    </div>
                </div>

                <div className="sso-form-input">
                    <Label>{SSOConfigLabels['LOGOUT_REDIRECT']}</Label>
                    <InputComponent
                        value={logoutRedirect}
                        isRequired={true}
                        onChange={setLogoutRedirect}
                        id="logoutRedirect"
                    />
                    <div>
                        {formErrors.logoutRedirect && <ErrorLabel>{t(formErrors.logoutRedirect)}</ErrorLabel>}
                    </div>
                </div>
            </div>
        </Section>
    );
};
