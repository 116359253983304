/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from 'react';
import { cond, T } from 'ramda';
import * as R from 'ramda';

import { useClickOutSide } from '../../../../../../hooks/useClickOutSide';
import { Checkbox, CHECKBOX_VALUES } from '../../../../../../library/Checkbox';

import './MultiPopup.scss';
import { useTranslation } from 'react-i18next';

const defaultItems = [];
const defaultSelection = [];

const getAllSelectedValue = (items, selection) => {
    const isAllChecked = items.length && items.every((value) => selection.includes(value));
    if (isAllChecked) {
        return CHECKBOX_VALUES.CHECKED;
    }
    if (items.some((value) => selection.includes(value))) {
        return CHECKBOX_VALUES.PARTIAL;
    }
    return CHECKBOX_VALUES.UNCHECKED;
};

const MultiPopupComponent = ({
    items = defaultItems,
    selection = defaultSelection,
    HeaderComponent,
    onHide,
    onChange
}) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const outSideClickHandler = useCallback(() => {
        onHide && onHide();
    }, [onHide]);

    useClickOutSide(ref, outSideClickHandler);

    const Section = ({ parent, elements }) => {
        return (
            <div className="hme-filter-multi-popup-body">
                <div className="hme-filter-multi-popup-row">
                    <Checkbox
                        label={parent}
                        checked={getAllSelectedValue(R.pluck('value', elements), selection)}
                        onChange={onChange && ((checked) => {
                            const values = R.pluck('value', elements);
                            const clearedSelection = R.without(values, selection);
                            const newSelection =
                                checked ?
                                    R.concat(clearedSelection, values) : clearedSelection;
                            onChange(newSelection);
                        })}
                    />
                </div>
                {
                    elements.map(({ text, value }, i) => (
                        <div
                            className={`
                            hme-filter-multi-popup-row
                            hme-filter-multi-popup-item
                        `}
                            key={value + i}
                        >
                            <Checkbox
                                label={text}
                                checked={selection.includes(value)}
                                onChange={onChange && ((checked, e) => {
                                    e.stopPropagation();
                                    const newSelection = checked ?
                                    [...selection, value] :
                                    selection.filter((selectedItem) => selectedItem !== value);
                                    onChange(newSelection);
                                })}
                            />
                            <span className="hme-filter-multi-popup-only-label">
                                <span onClick={onChange && ((e) => {
                                    e.stopPropagation();
                                    onChange([value]);
                                })}>{t('common__only').toLowerCase()}</span>
                            </span>
                        </div>
                    ))
                }
            </div>
        );
    };

    return (
        <div
            className="hme-filter-multi-popup"
            ref={ref}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <div className="hme-filter-multi-popup-header">
                <span>{t('common__show-me')}</span>
                <span className="hme-filter-multi-popup-close" onClick={onHide}>×</span>
            </div>
            <HeaderComponent/>
            {items.map(({ name, children }) => (
                <Section
                    key={name}
                    parent={name}
                    elements={children}
                />
            ))}
        </div>
    );
};

export const MultiPopup = cond([
    [({ show }) => show, MultiPopupComponent],
    [T, () => <div></div>]
]);
