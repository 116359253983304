// compare status texts for grid columns sorting
export default (a, b, order = 1) => {
  const textA = (a || '').toLowerCase();
  const textB = (b || '').toLowerCase();

  if (!textA) return 1;
  if (!textB) return -1;

  return textA > textB ? order : (textA < textB ? -order : 0);
};
