export const formatAppliedFilters = (filters, appliedFilters) => {
    // define filters without checked "Show all items" option
    const appliedFields = Object.keys(filters).filter(
        (field) => appliedFilters[field] && filters[field].length !== appliedFilters[field].length,
    );

    return appliedFields.reduce(
        (acc, field) => ({
            ...acc,
            [field]: appliedFilters[field],
        }),
        {},
    );
};
