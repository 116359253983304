/* eslint-disable react/prop-types */
import React from 'react';
import i18next from 'i18next';
import { cond, T } from 'ramda';

import { GridRow } from '../GridRow';

const NoRows = ({ noRecordsMessage }) => (
    <GridRow
        rowKey={0}
        headers={[{ property: 'message' }]}
        data={{ message: noRecordsMessage }}
        className="hme-grid-row-message"
    />
);

const Loading = ({ loadingText = i18next.t('common__loading') }) => (
    <GridRow
        rowKey={0}
        headers={[{ property: 'message' }]}
        data={{ message: loadingText }}
        className="hme-grid-row-message"
    />
);

// Component that shows all rows in body
const Body = ({
    rows,
    headers,
    ExpandComponent,
    selectable,
    rowKey = null,
    selection = [],
    onItemSelectionChange,
    expandable,
    isAllExpanded,
    onRowClick,
    onExpandChange,
    showOverflow,
    checkSelected,
    checkDisabled,
}) => (
    <>
        {
            rows.map((row, index) => (
                <GridRow
                    key={rowKey ? row[rowKey] : index}
                    rowKey={rowKey ? row[rowKey] : index}
                    headers={headers}
                    ExpandComponent={ExpandComponent}
                    data={row}
                    selectable={selectable}
                    selected={checkSelected && checkSelected(row) || selection.includes(row)}
                    onSelectionChange={onItemSelectionChange && ((checked) => {
                        onItemSelectionChange(row, checked);
                    })}
                    expandable={expandable}
                    isAllExpanded={isAllExpanded}
                    onExpandChange={onExpandChange}
                    odd={index % 2 === 0}
                    onClick={onRowClick}
                    showOverflow={showOverflow}
                    checkboxProps={row.checkboxProps || {}}
                    isDisabled={checkDisabled && checkDisabled(row)}
                />
            ))
        }
    </>
);

export const GridBody = cond([[
    ({ isLoading }) => isLoading,
    Loading
], [
    ({ rows }) => rows.length === 0,
    NoRows
], [
    T,
    Body
]]);
