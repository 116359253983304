export const ACCOUNTS_SEARCH_OPTIONS = [
    {
        text: 'common__account__email',
        value: 'User_EmailAddress',
        placeholder: 'admin__stores__search-placeholder--account-email'
    }, {
        text: 'common__company__name',
        value: 'Company_Name',
        placeholder: 'distributors__accounts__search-placeholder--company__name'
    }, {
        text: 'common__all',
        value: '',
        placeholder: 'stores__search-placeholder--all'
    }
];
