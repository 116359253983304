import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components/Common/Grid';
import { Paginate } from 'library/Paginate';
import { TilesList } from "./TilesList";
import { PAGE_DATA } from 'constants/paginationDefault';
import { getProfile } from 'services/Auth';
import { filterAndSort, storeToRow } from '../helpers';
import { headers} from '../constants';

import './List.scss';

const sortDirections = [1, -1];

export const List = ({ stores, searchValue, sorting, isLoading, onRenderingChange, onSorting }) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [pagedRows, setPagedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const profile = useMemo(() => getProfile(), []);

    const { t } = useTranslation();

    useEffect(() => {
        const newRows = stores.map((store) => storeToRow(store));
        setRows(newRows);
    }, [stores]);

    useEffect(() => {
        filterAndSort(searchValue, rows, sorting, setFilteredRows, setPage, onRenderingChange);
    }, [rows, searchValue, sorting, onRenderingChange]);

    useEffect(() => {
        if (rowsPerPage === -1) {
            setPagedRows(filteredRows);
        } else {
            const newRows = filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
            setPagedRows(newRows);
        }
    }, [filteredRows, page, rowsPerPage]);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPage(page);
        setRowsPerPage(recordsPerPage === -1 ? recordsPerPage : Number.parseInt(recordsPerPage));
    }, []);

    const onSortingChange = useCallback((newSorting) => {
        if (isLoading) {
            return;
        }

        onSorting(newSorting);
    }, [isLoading, onSorting]);

    return (
        <>
            <Grid
                headers={headers}
                rows={pagedRows}
                rowKey='Store_UID'
                className='hme-stores-status--partner__grid hme-l-size'
                noRecordsMessage={t('partner__no-stores--found')}
                isLoading={isLoading}
                loadingText={t('common__loading')}
                onSortChange={onSortingChange}
                sortSelection={sorting}
                sortDirections={sortDirections}
            />
            <TilesList
                stores={pagedRows}
                isLoading={isLoading}
            />
            { !isLoading &&
                    <Paginate
                        className='hme-stores-status--partner__paginate'
                        page={page}
                        recordsPerPage={rowsPerPage}
                        pageSizes={ profile.admin ? PAGE_DATA.PAGE_SIZES_ADMIN : PAGE_DATA.PAGE_SIZES_PUBLIC}
                        total={filteredRows.length}
                        onChange={onPaginationChange}
                    />
            }
        </>
    );
};
