import { Config } from '../Config';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { DateLib } from '@hme-cloud/utility-common';
import AuthenticationService from '../components/Security/AuthenticationService';
import { getSearchString, getExportFile } from 'Api';
import { CommonConstants } from 'Constants';

const authService = new AuthenticationService(Config.authBaseUrl);

const base = 'scheduledUpgrade';

const axiosConfig = {
    baseURL: `${Config.apiBaseUrl}api/`,
    headers: {
        'Content-Type': 'application/json'
    }
};

const axiosInstance = axios.create(axiosConfig);

const api = {
    request(options) {
        options.headers = Object.assign(options.headers || {}, {
            Authorization: `Bearer ${authService.getToken()}`
        });
        return axiosInstance(options)
                .then((res) => res.data);
    }
};

export const scheduledUpgradesApi = {
    async getScheduledUpgradesByID(data) {
        try {
            const {
                scheduledUpgradeId
            } = data || {};
            const scheduledUpgradesResult = await api.request({
                url: `${base}/${scheduledUpgradeId}`,
                method: 'GET'
            });

            return scheduledUpgradesResult ? scheduledUpgradesResult : {};
        } catch (error) {
            return null;
        }
    },
    async cancelDeviceUpgrade(data) {
        try {
            const {
                deviceIds
            } = data || {};
            const deviceUpgradeResult = await api.request({
                url: `${base}/deviceupgrade/cancel`,
                method: 'POST',
                data: {deviceIds}
            });
            const result = deviceUpgradeResult ? deviceUpgradeResult : {};
            return result;
        } catch (error) {
            return null;
        }
    },

    async updateDeviceUpgrade(data) {
        try {
            const deviceUpgradeResult = await api.request({
                url: `${base}/update`,
                method: 'POST',
                data
            });
            const result = deviceUpgradeResult ? deviceUpgradeResult : {};
            return result;
        } catch (error) {
            return null;
        }
    },

    async cancelScheduledUpgrade(upgradeId = null) {
        try {
            const upgradeResult = await api.request({
                url: `${base}/${upgradeId}`,
                method: 'DELETE'
            });
            const result = upgradeResult ? upgradeResult : {};
            return result;
        } catch (error) {
            return null;
        }
    },

    async approveScheduledUpgrade(upgradeId = null) {
        if (!upgradeId) return;

        try {
            const approveResult = await api.request({
                url: `${base}/${upgradeId}/approve`,
                method: 'POST',
                
            });
            return approveResult || {};
        } catch (error) {
            return null;
        }
    },

    async declineScheduledUpgrade(upgradeId = null, message = '') {
        if (!upgradeId) return;

        try {
            const declineResult = await api.request({
                url: `${base}/${upgradeId}/decline`,
                method: 'POST',
                data: { message }
            });
            return declineResult || {};
        } catch (error) {
            return null;
        }
    },

    async getLatestScheduledUpgradeByDeviceUID(data) {
        try {
            const {
                deviceUID
            } = data || {};
            const scheduledUpgradeResult = await api.request({
                url: `${base}/device/${deviceUID}`,
                method: 'GET'
            });
            const scheduledUpgrade = scheduledUpgradeResult ? scheduledUpgradeResult : {};
            return scheduledUpgrade;
        } catch (error) {
            return null;
        }
    },
    getBrands() {
        return api.request({
            url: `${base}/brands/getAll`,
            method: 'GET'
        });
    },
    async getDevicesByBrand(data) {
        try {
            const defaultDeviceType = CommonConstants.deviceType.zoom.name.toLowerCase(); // for backward compatibility
            const {
                brandIDs,
                targetDeviceVersions,
                deviceType,
                countryID,
                timezoneIDs,
                search,
                pagenumber = 1,
                perpage = 10,
                excludeDeviceIDs = []
            } = data || {};

            const searchParams = {
                targetDeviceVersions,
                deviceType: deviceType || defaultDeviceType,
                countryID,
                timezoneIDs,
                search,
                pagenumber,
                perpage,
                excludeDeviceIDs,
                brandIDs: brandIDs.join(','),
            };

            if (data.peripheralDeviceType) {
                searchParams.peripheralDeviceType = data.peripheralDeviceType;
            }

            const devicesResult = await api.request({
                url: `${base}/device/brand?${getSearchString(searchParams)}`,
                method: 'GET',
            });
            const devices = devicesResult ? devicesResult : {};
            return devices;
        } catch (error) {
            return null;
        }
    },
    async countBrandDevicesByCountryTimezone(data) {
        try {
            const defaultDeviceType = CommonConstants.deviceType.zoom.name.toLowerCase(); // for backward compatibility
            const {
                brandIDs,
                targetDeviceVersions,
                deviceType = defaultDeviceType,
                peripheralDeviceType,
            } = data || {};

            const searchParams = getSearchString({
                targetDeviceVersions,
                deviceType,
                brandIDs,
                ...(peripheralDeviceType && { peripheralDeviceType }),
            });

            return await api.request({
                url: `${base}/device/count?${searchParams}`,
                method: 'GET',
            });
        } catch (error) {
            return null;
        }
    },
    async getStores(data) {
        try {
            const defaultDeviceType = CommonConstants.deviceType.zoom.name.toLowerCase(); // for backward compatibility

            const { criteria, deviceType, targetDeviceVersions, peripheralDeviceType = null } = data || {};

            const searchParams = {
                criteria,
                deviceType: deviceType || defaultDeviceType,
                targetDeviceVersions: targetDeviceVersions.join(','),
            };

            if (peripheralDeviceType) {
                searchParams.peripheralDeviceType = peripheralDeviceType;
            }

            const devicesResult = await api.request({
                url: `${base}/stores/search?${getSearchString(searchParams)}`,
                method: 'GET',
            });
            // TODO return empty results as array
            const devices = devicesResult ? devicesResult : {};
            return devices;
        } catch (error) {
            return null;
        }
    },
    async downloadDevicesUpgrades(scheduledUpgrade) {
        const { DeviceScheduledUpgradeID: id, ScheduledUpgradeName: name } = scheduledUpgrade;

        const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const exportType = 'csv';
        const response = await getExportFile({
            url: `${Config.apiBaseUrl}api/${base}/${id}/downloadDevicesUpgrades?tz=${currentTimeZone}`,
            exportType,
        });

        const filename = `Upgrade Details_${name}_${new DateLib().format(DateLib.FORMAT_TYPES.DATE_UNDERSCORED)}.${exportType}`;

        saveAs(response, filename);
    },
};
