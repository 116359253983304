import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { getDataAsync, getSearchString, postDataAsync } from 'Api';

const handleResponseError = (error) => {
    if (error.response.status === 401) {
        // TODO - update with translation key
        throw new Error('Your session has expired. Please log in again.');
    }

    throw new Error('common__error--internal-server');
};

export const fetchStores = async (params) => {
    const search = getSearchString(params);
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getTextConnectStores}?${search}`;

    try {
        const response = await getDataAsync(url);

        if (!response.status) {
            throw new Error('common__error--internal-server');
        }

        return response.data;
    } catch (error) {
        handleResponseError(error);
    }
};

export const fetchGroupedStores = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getTextConnectGroupedStores}`;

    try {
        const response = await getDataAsync(url);

        if (!response.status) {
            throw new Error('common__error--internal-server');
        }

        return response.data;
    } catch (error) {
        handleResponseError(error);
    }
};

export const sendMessage = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.sendTextConnectMessage}`;

    try {
        const response = await postDataAsync(url, payload);

        if (!response.status) {
            throw new Error('common__error--internal-server');
        }

        return response.data;
    } catch (error) {
        handleResponseError(error);
    }
};

export const fetchMessages = async ({ page = 0, sortColumn, sortingType, recordsPerPage }) => {
    const params = {
        pagenumber: page + 1,
        sortcolumn: sortColumn,
        sorttype: sortingType,
        perpage: recordsPerPage,
    };

    const queryParams = new URLSearchParams(params).toString();

    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getTextConnectMessages}?${queryParams}`;

    try {
        const response = await getDataAsync(url);

        if (!response.status) {
            throw new Error('common__error--internal-server');
        }

        return response.data;
    } catch (error) {
        handleResponseError(error);
    }
};
