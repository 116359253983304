import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StoreStatusesMobile } from '../StoreStatusesMobile';
import { DetailsButton } from '../DetailsButton';
import { isDistributor } from '../../helpers/companyTypeDeterminator';

import { ReactComponent as Pencil } from 'assets/icons/IconEditBlue.svg';
import { Button } from 'library/Button';

export const TileListItem = ({
    storeNumber,
    leaderboard,
    reportGroup,
    devices,
    brandName,
    storeName,
    storeAddress,
    cityAndState,
    editPageURL,
    companyType
}) => {
    const { t } = useTranslation();

    const [isDetailsVisible, setDetailsVisible] = useState(false);

    const onDetailsButtonClick = useCallback(() => {
        setDetailsVisible(!isDetailsVisible);
    }, [isDetailsVisible, setDetailsVisible]);

    return (
       <>
           <div className="hme-stores-mobile--public__tile__main__info">
               <span className="hme-stores-mobile--public__tile__title">{t('stores__list-header__store-information')}</span>
               <div className="hme-stores-mobile--public__store__info__item">
                   <span className="hme-stores-mobile--public__tile__sub__title">{t('common__store__number')}</span>
                   <span className="hme-stores-mobile--public__tile__sub__value">{storeNumber}</span>
               </div>
               <div className="hme-stores-mobile--public__store__info__item">
                   <span className="hme-stores-mobile--public__tile__sub__title">{t('common__leaderboard')}</span>
                   <span className="hme-stores-mobile--public__tile__sub__value">{leaderboard}</span>
               </div>
               <div className="hme-stores-mobile--public__store__info__item">
                   <span className="hme-stores-mobile--public__tile__sub__title">{t('stores__list-header__report-group')}</span>
                   <span className="hme-stores-mobile--public__tile__sub__value">{reportGroup}</span>
               </div>
               {isDetailsVisible &&
                   <>
                       <div className="hme-stores-mobile--public__store__info__item">
                           <span className="hme-stores-mobile--public__tile__sub__title">{t('common__brand')}</span>
                           <span className="hme-stores-mobile--public__tile__sub__value">{brandName}</span>
                       </div>
                       <div className="hme-stores-mobile--public__store__info__item">
                           <span className="hme-stores-mobile--public__tile__sub__title">{t('common__store__name')}</span>
                           <span className="hme-stores-mobile--public__tile__sub__value">{storeName}</span>
                       </div>
                       <div className="hme-stores-mobile--public__store__info__item">
                           <span className="hme-stores-mobile--public__tile__sub__title">{t(isDistributor(companyType) ? 'common__address' : 'common__store__address')}</span>
                           <span className="hme-stores-mobile--public__tile__sub__value">{storeAddress}</span>
                       </div>
                       <div className="hme-stores-mobile--public__store__info__item">
                           <span className="hme-stores-mobile--public__tile__sub__title">{t('stores__list-header__city-state')}</span>
                           <span className="hme-stores-mobile--public__tile__sub__value">{cityAndState}</span>
                       </div>
                   </>
               }
               <span className="hme-stores-mobile--public__tile__title">{t('stores__list-header__device-information')}</span>
               <StoreStatusesMobile devices={devices} isDetailsVisible={isDetailsVisible} />
               <div  className="hme-stores-mobile--public__details__button__block">
                   <DetailsButton isDetailsVisible={isDetailsVisible} onDetailsButtonClick={onDetailsButtonClick} />
               </div>
           </div>
           <Button
               variants={['transparent']}
               className="hme-stores-mobile--public__edit__store__link"
               as={Link}
               to={editPageURL}
           >
               <Pencil />
               {t('common__view-edit')}
           </Button>
       </>
    )
};

TileListItem.prototype ={
    storeNumber: PropTypes.string.isRequired,
    leaderboard: PropTypes.string.isRequired,
    reportGroup: PropTypes.string.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    brandName: PropTypes.string.isRequired,
    storeName: PropTypes.string.isRequired,
    storeAddress: PropTypes.string.isRequired,
    cityAndState: PropTypes.string.isRequired,
    storeUUID: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    companyType: PropTypes.string.isRequired
};
