import * as React from 'react';

import { TreeSelectInput } from '../../Inputs';
import { useParticipatingStoreTreeData } from './hooks';

export const ParticipatingStoresTree = (props) => {
    const { onChange } = props;
    const { value, storesTree, disabled } = useParticipatingStoreTreeData(props);

    return <TreeSelectInput
                className="stores-selector__wrap__stores"
                tree={storesTree}
                value={value}
                onChange={onChange}
                disabled={disabled}
                omitRootNodes
                strongRootNodes
                rangeSelection
           />
}