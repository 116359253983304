import React from 'react';
import { useTranslation } from 'react-i18next';

import './DownloadFailedStatus.scss';

export const DownloadFailedStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-download-failed-cell">
            {t('common__task__status--download-failed')}
        </span>
    );
};
