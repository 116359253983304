import * as country from '../actionTypes/Country/Country'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'

function getCountrySuccess (countries) {
  return {
    type: country.GET_COUNTRY,
    countries: countries
  }
}

export const getCountryList = () => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getCountry
  return (dispatch, getState) => {
    const api = new Api()
    api.getData(url, data => {
      let countryList = data.data
      dispatch(getCountrySuccess(countryList))
    })
  }
}
