import * as React from 'react';

export const ParticipatingStoreItem = ({
      store, 
      isViewMyStoresOnlyChecked,
      isDefault,
      company,
      toggle,
      disabled,
      type,
      key}) => {

    const isOtherLBStore = company && company.companyUId !== store.CompanyUID;
    const title = store.StoreName ? `${store.StoreNumber}-${store.StoreName}`: store.StoreNumber;
    const isHidden = isViewMyStoresOnlyChecked && isOtherLBStore;
    const isDisabled = isDefault || isOtherLBStore || disabled;
    const isSelectable = (isDefault || isOtherLBStore || disabled);

    const onChange = () => toggle(store);

    return (
        <tr className={"tdata clear " + (isOtherLBStore ?  " greyed-row" : "") + (isHidden ? ' hidden': '')} key={key}>
          <td className={"cdet store_checkbox"}>
            <label className={"participant-stores-checkbox " + (isSelectable ? "hidden" : "")}>
              <input
                type="checkbox"
                checked={store.selected}
                disabled={isDisabled}
                name="availableItem"
                id={type}
                onChange={onChange}
              />
              <span></span>
            </label>
          </td>
          <td title={title}>{title}</td>
        </tr>
      );
};