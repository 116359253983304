import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { T, compose, cond } from 'ramda';
import classNames from 'classnames';

import { Paginate } from 'library/Paginate';
import { Title } from 'library/Title';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { Button } from 'library/Button';
import { SEPARATOR_VARIANTS, Separator } from 'library/Separator';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { LoadingModal } from 'library/LoadingModal';
import { NotificationsList } from 'library/NotificationsList';
import { applyPagination } from 'helpers';
import { Grid, getFiltersCount, useAvailableFilters } from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { CenterLoader } from 'components/Common/CenterLoader';
import { PAGE_DATA, PAGE_DEFAULT } from 'constants/paginationDefault';
import { PUBLIC_ROUTES } from 'constants/routes';
import { useScreenType } from 'globalState/GlobalStateProvider';

import { getSortedRows } from '../../Common/utils';
import { withGamificaionMarketingLayout } from '../../Common/HOCs';
import { Certificate } from '../Certificate';
import {
    useContests,
    CONTESTS_FILTER_OPTIONS,
    LIST_HEADERS,
    MOBILE_LIST_HEADERS,
    MOBILE_FILTER_OPTIONS,
    MOBILE_SORT_OPTIONS,
    DEFAULT_SORT_SELECTIONS
} from './hooks';

import './StatusPage.scss';

const separatorVariants = [SEPARATOR_VARIANTS.PURE];

const StatusPage = ({
    contests,
    setContests,
    contestUIDToCancel,
    setContestUIDToCancel,
    cancelContest,
    isLoadingModalShown
}) => {
    const contestListRef = useRef(null);
    const { t } = useTranslation();
    const screenType = useScreenType();

    const [showedContests, setShowedContests] = useState(contests);
    const [filters, setFilters] = useState({});
    const [availableFilters, setAvailableFilters] = useState([]);
    const [pageData, setPageData] = useState(PAGE_DEFAULT);
    const [filtersCount, setFiltersCount] = useState(0);

    const [sortSelection, setSortSelection] = useState(DEFAULT_SORT_SELECTIONS);

    const handleFiltersChange = useCallback((newFilters) => {
        setShowedContests(contests.filter(({ ContestStatus }) => {
            return newFilters.ContestStatusComponent.includes(ContestStatus);
        }));
        setFilters(newFilters);
    }, [contests]);

    const resetFilters = useCallback(() => {
        setFiltersCount(0);
        setContests((currentContests) => [...currentContests]);
        setSortSelection(DEFAULT_SORT_SELECTIONS);
        setShowedContests(contests);
    }, []);

    useAvailableFilters(CONTESTS_FILTER_OPTIONS, contests, handleFiltersChange, setAvailableFilters);

    const handleSortChange = useCallback((newSorting) => {
        if (newSorting === DEFAULT_SORT_SELECTIONS) {
            setSortSelection(newSorting);

            return;
        }
        setSortSelection(newSorting);
        setShowedContests((currentlyShowedContests) => getSortedRows(currentlyShowedContests, newSorting));
    }, []);

    const handlePaginationChange = ({ page, recordsPerPage }) => {
        if (contestListRef.current) {
            contestListRef.current.scrollTop = 0;
        }
        setPageData({ page, recordsPerPage });
    };

    const rowsToShow = useMemo(() => applyPagination(showedContests, pageData), [showedContests, pageData]);

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, filters));
    }, [availableFilters, filters]);

    useEffect(() => {
        setShowedContests(contests);
    }, [contests]);

    return (
        <div className={
            classNames('hme-contests__content', { 'hme-contests__content--reset-filter-shown': sortSelection !== DEFAULT_SORT_SELECTIONS })
        }>
            <div className="hme-contests__header">
                <Title as="h2" className="hme-contests__header-title">{t('common__contests')}</Title>

                {screenType.isDesktop && (
                    <div className="hme-contests__filters-count-wrapper">
                        <Separator variants={separatorVariants} className="hme-contests__header-separator" />

                        <GridFiltersCount
                            count={filtersCount}
                            onFiltersReset={resetFilters}
                            className="hme-contests__filters-count"
                        />
                    </div>
                )}

                <Button as={Link} to={PUBLIC_ROUTES.newContest} className="hme-contests__add-contest">
                    {t('contest__add-contest')}
                </Button>
            </div>

            {screenType.isDesktop ? (
                <Grid
                    rows={rowsToShow}
                    headers={LIST_HEADERS}
                    filters={filters}
                    availableFilters={availableFilters}
                    onFiltersChange={handleFiltersChange}
                    rowKey="ContestUID"
                    noRecordsMessage={t('common__error--no-records-found')}
                    className="hme-contests__list"
                    sortSelection={sortSelection}
                    onSortChange={handleSortChange}
                />
            ) : (
                <TileList
                    rows={rowsToShow}
                    headers={MOBILE_LIST_HEADERS}
                    rowKey="ContestUID"
                    noRecordsMessage={t('common__error--no-records-found')}
                    availableFilters={availableFilters}
                    filtersCount={filtersCount}
                    onFiltersChange={handleFiltersChange}
                    onFiltersReset={resetFilters}
                    filters={filters}
                    sortOptions={MOBILE_SORT_OPTIONS}
                    sortSelection={sortSelection}
                    onSortChange={handleSortChange}
                    filtersOptions={MOBILE_FILTER_OPTIONS}
                    className="hme-contests__list"
                    ref={contestListRef}
                />
            )}
            <Paginate
                page={pageData.page}
                recordsPerPage={pageData.recordsPerPage}
                total={showedContests.length}
                pageSizes={PAGE_DATA.PAGE_SIZES_PUBLIC}
                onChange={handlePaginationChange}
            />

            <ConfirmPopupComponentGeneric
                show={Boolean(contestUIDToCancel)}
                title={t('common__double-checking')}
                message={t('contest__warning__cancel-contest')}
                actions={[
                    {
                        children: t('common__popup--cancel-action'),
                        onClick: () => setContestUIDToCancel('')
                    },
                    {
                        children: t('common__popup--confirm-action'),
                        variants: ['submit'],
                        onClick: cancelContest
                    }
                ]}
            />

            <LoadingModal show={isLoadingModalShown} />
        </div>
    );
};

const StatusListUI = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <StatusPage {...props} />]
]);

const StatusListLoader = () => {
    const {
        isLoading,
        contests,
        setContests,
        contestUIDToCancel,
        setContestUIDToCancel,
        cancelContest,
        isLoadingModalShown,
        hideCertificateModal,
        certificateToShow
    } = useContests();

    return (
        <>
            <NotificationsList />
            <StatusListUI
                isLoading={isLoading}
                contests={contests}
                setContests={setContests}
                contestUIDToCancel={contestUIDToCancel}
                setContestUIDToCancel={setContestUIDToCancel}
                cancelContest={cancelContest}
                isLoadingModalShown={isLoadingModalShown}
            />

            {certificateToShow && <Certificate
                contestUID={certificateToShow}
                onHide={hideCertificateModal}
            />}
        </>
    );
};

export const StatusListPage = compose(
    withGamificaionMarketingLayout({
        className: ['hme-contests']
    })(StatusListLoader)
);
