import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { Grid } from 'components/Common/Grid';
import { Button } from 'library/Button';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { SearchInput } from 'components/Inputs';

import { Label } from 'library/Label';
import { useLoadAccounts } from 'pages/DXSFeeds/hooks/useLoadAccounts';
import './AccountsSection.scss';

const accountsResultHeaders = [
    {
        text: '',
        property: 'User_EmailAddress'
    }
];

const gridOptions = [
    {
        text: 'common__account',
        property: 'accountEmail',
        flex: 40,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'common__status',
        property: 'status',
        flex: 30,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'common__subscription',
        property: 'subscription',
        flex: 40,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'leaderboard__count-of-stores',
        property: 'storeCount',
        flex: 20,
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 40,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell'
    }
];

const accountsToRows = ({ accounts, onAccountRemoval, t }) =>
    accounts.map((account) => {
        const {
            Subscription_Name: subscription,
            User_EmailAddress: accountEmail,
            Account_IsActive: isActive,
            User_ID: userID,
            Store_Count: storeCount = 0
        } = account || {};
        return {
            ...account,
            accountEmail,
            subscription,
            storeCount: storeCount ? storeCount : 0,
            status: isActive ? t('common__active') : t('common__inactive'),
            Actions: (
                <Button onClick={() => onAccountRemoval(userID)} variants={['transparent']}>
                    {t('common__remove')}
                </Button>
            )
        };
    });

const filterAccountSuggestions = (suggestions = [], cloudAccounts = []) => {
    const userIDMap = cloudAccounts.reduce((acc, { User_ID: accountID } = {}) => ({ ...acc, [accountID]: true }), {});
    return suggestions.filter(({ User_ID: userID } = {}) => !userIDMap[userID]);
};

export const AccountsSection = ({ isLoading, onAccountRemoval = () => {}, formValues = {}, onAccountSelection = () => {} } = {}) => {
    const { apiType = 'DXS', cloudAccounts = [] } = formValues || {};
    const [accountSearchValue, setAccountSearchValue] = useState('');
    const { t } = useTranslation();

    const { accounts, isAccountsLoading } = useLoadAccounts({ search: accountSearchValue });

    const setSuggestion = (suggestion = {}) => {
        onAccountSelection(suggestion);
    };

    return (
        <Section
            className="api-management-form-details"
            number={3}
            col={6}
            showNumber={false}
            title={t(`api-token-management__section__title--${apiType}--detail`)}
        >
            <label className="hme-form-value-required">*</label>
            <Label className="vaio-provider-form-label">{t('api-token-management__form--select-DXS-provider')}</Label>
            <div className="dxs-accounts-section">
                <AutoCompleteInput
                    isRequired
                    success={false}
                    value={accountSearchValue}
                    suggestions={filterAccountSuggestions(accounts, cloudAccounts)}
                    headers={accountsResultHeaders}
                    rowKey="Account_ID"
                    placeholder={t('api-token-management__form--token--DXS--account-email')}
                    Component={SearchInput}
                    isLoading={isAccountsLoading}
                    noSuggestionText={t('accounts__no-accounts')}
                    onChange={setAccountSearchValue}
                    onSuggested={setSuggestion}
                />
            </div>
            <Label className="dxs--feed-accounts-selected">
                {t('api-token-management__form--token--DXS--accounts-selected', { count: cloudAccounts.length })}</Label>
            <div className="dxs-feed-accounts-section">
                <Grid
                    headers={gridOptions}
                    rows={accountsToRows({ accounts: cloudAccounts, onAccountRemoval, t })}
                    isLoading={isLoading}
                    rowKey="cloudAccountID"
                    noRecordsMessage={t('accounts__no-accounts')}
                />
            </div>
        </Section>
    );
};
