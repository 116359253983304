import { useCallback } from 'react';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { fetchUserTokenByAdmin } from 'services/Auth';

export const useMasquerade = (userEmail) => {
    const {
        run,
        isLoading
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const masquerade = useCallback(() => run(fetchUserTokenByAdmin({ userEmail, isPartnerUser: true })), [userEmail]);

    return {
        masquerade,
        isMasquerading: isLoading
    };
};
