import React, { useCallback, useState } from 'react';
import { NotificationsList } from 'library/NotificationsList';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from 'library/Loader';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { useLoadBrands } from 'hooks/useLoadBrands';
import { useLoadCloudAccount } from '../hooks/useLoadCloudAccount';
import { useLoadFeeds } from '../hooks/useLoadFeeds';
import { processDataFeedCreation, processCloudAccountLink, processCloudAccountUnlink } from '../Controller';
import { formDataValidator } from '../helpers';
import { FeedFormModal } from '../FeedFormModal';
import { LinkFeedForm } from '../LinkFeedForm';
import { FeedCreationForm } from '../FeedCreationForm';
import { Header } from './Header';
import { InfoSection } from './InfoSection';
import { FeedsSection } from './FeedsSection';

import './DXSAccount.scss';

export const DXSAccount = () => {
    const [accountSearchValue, setAccountSearchValue] = useState('');
    const [isFeedLinkModalShown, setIsFeedLinkModalShown] = useState(false);
    const [isFeedCreateModalShown, setIsFeedCreateModalShown] = useState(false);
    const [isConfirmUnlinkModalShown, setIsConfirmUnlinkModalShown] = useState(false);
    const [unlinkFeedtID, setUnlinkFeedID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [linkedFeed, setLinkedFeed] = useState(null);
    const [newFeed, setNewFeed] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const { accountId } = useParams();
    const { t } = useTranslation();

    const { account, isAccountLoading, loadAccount } = useLoadCloudAccount(accountId);
    const { feeds, isFeedsLoading } = useLoadFeeds();
    const { brands, isBrandsLoading } = useLoadBrands();

    const onFeedLinkModalShow = () => {
        setIsFeedLinkModalShown(true);
    };

    const onFeedLinkModalHide = () => {
        setIsFeedLinkModalShown(false);
        setLinkedFeed(null);
    };

    const onFeedCreateModalShow = () => {
        setIsFeedCreateModalShown(true);
    };

    const onFeedCreateModalHide = () => {
        setIsFeedCreateModalShown(false);
    };

    const onFeedUnlink = (serviceAccountID) => {
        setIsConfirmUnlinkModalShown(true);
        setUnlinkFeedID(serviceAccountID);
    };

    const onConfirmUnlinkModalHide = () => {
        setIsConfirmUnlinkModalShown(false);
        setUnlinkFeedID(null);
    };

    const onFeedLink = useCallback(async () => {
        onFeedLinkModalHide();
        await processCloudAccountLink({
            payload: {
                serviceAccountID: +linkedFeed.serviceAccountID,
                cloudAccountID: +accountId,
            },
            setIsLoading,
            t,
        });

        loadAccount(accountId);
    }, [linkedFeed, accountId]);

    const onFeedUnlinkConfirm = useCallback(async () => {
        await processCloudAccountUnlink({
            payload: {
                serviceAccountID: +unlinkFeedtID,
                cloudAccountID: +accountId,
            },
            setIsLoading,
            t,
        });

        loadAccount(accountId);
    }, [linkedFeed, accountId, unlinkFeedtID]);

    const onFeedCreation = useCallback(async () => {
        const errors = formDataValidator(newFeed);

        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});

        onFeedCreateModalHide();

        await processDataFeedCreation({ payload: newFeed, setIsLoading, t });
        loadAccount(accountId);
    }, [accountId, newFeed, formErrors]);

    const onFeedCreationFormChange = useCallback(
        ({ serviceAccountName, brandID }) => {
            setNewFeed({
                serviceAccountName,
                brandID,
                cloudAccountID: +accountId,
            });
        },
        [accountId],
    );

    return (
        <div className="hme-components dxs-account-wrapper">
            <NotificationsList />
            <div className="dxs-account">
                {account && !isFeedsLoading && !isBrandsLoading ? (
                    <div className="dxs-account-content">
                        <Header
                            accountSearchValue={accountSearchValue}
                            onAccountSearch={setAccountSearchValue}
                            onFeedLink={onFeedLinkModalShow}
                            onFeedCreate={onFeedCreateModalShow}
                        />
                        <div className="dxs-account-sections">
                            <InfoSection accountEmail={account.cloudAccountEmail} />
                            <FeedsSection feeds={account.serviceAccounts} isLoading={isAccountLoading || isLoading} onFeedUnlink={onFeedUnlink} />
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
            <FeedFormModal
                title={t('dxs-feeds__header__add-new-feed')}
                show={isFeedLinkModalShown}
                onHide={onFeedLinkModalHide}
                actions={[
                    {
                        children: t('common__cancel'),
                        onClick: onFeedLinkModalHide,
                    },
                    {
                        children: t('common__submit'),
                        variants: ['submit'],
                        onClick: onFeedLink,
                    },
                ]}
            >
                <LinkFeedForm feeds={feeds} onFormChange={setLinkedFeed} />
            </FeedFormModal>

            <FeedFormModal
                title={t('dxs-feeds__header__create-new-feed')}
                show={isFeedCreateModalShown}
                onHide={onFeedCreateModalHide}
                actions={[
                    {
                        children: t('common__cancel'),
                        onClick: onFeedCreateModalHide,
                    },
                    {
                        children: t('common__submit'),
                        variants: ['submit'],
                        onClick: onFeedCreation,
                    },
                ]}
            >
                <FeedCreationForm brands={brands} formErrors={{}} onFormChange={onFeedCreationFormChange} />
            </FeedFormModal>

            <ConfirmPopupComponent
                show={isConfirmUnlinkModalShown}
                onConfirm={onFeedUnlinkConfirm}
                onHide={onConfirmUnlinkModalHide}
                title={t('common__popup--title-confirm')}
                message={t('dxs-feeds__popup__unlink-account')}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />
        </div>
    );
};
