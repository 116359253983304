import React from 'react';
import { compose, T, cond } from 'ramda';
import { Trans } from 'react-i18next';

import { NotificationsList } from 'library/NotificationsList';
import { withHMELayout } from 'HOCs/withHMELayout';
import { CenterLoader } from 'components/Common/CenterLoader';

import { useTokensList } from '../hooks';
import { Header } from './Header';
import { List } from './List';
import { Paginate } from 'library/Paginate';
import { PAGE_DATA } from 'constants/paginationDefault';

import './StatusPage.scss';

const StatusLayout = ({
      tokens,
      sortSelection,
      onSortChange,
      onRemove,
      pageNumber,
      itemsPerPage,
      totalRows,
      onPaginationChange,
      paginatedRows
}) => {
    return (
        <div className="hme-token-management__wrapper">
            <div className="hme-token-management__content">
                <Header />
                <List tokens={paginatedRows} sortSelection={sortSelection} onSortChange={onSortChange} onRemoveToken={onRemove} />
                <Paginate
                    className="hme-token-management__paginate"
                    page={pageNumber}
                    recordsPerPage={itemsPerPage}
                    pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                    total={totalRows}
                    onChange={onPaginationChange}
                    hideSinglePage
                />
            </div>
            <NotificationsList />
        </div>
    );
};

const StatusComponent = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <StatusLayout {...props} />]
]);

const StatusComponentLoader = () => {
    const state = useTokensList();

    return <StatusComponent {...state} />;
};

export const StatusPage = compose(
        withHMELayout({
            contentClasses: ['hme-token-management']
        })
)(StatusComponentLoader);
