import { useCallback, useReducer } from 'react';

import { useSafeDispatch } from 'hooks/useSafeDispatch';

const tokensReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_TOKENS': {
            return action.payload;
        }
        default:
            return state;
    }
};

export const useTokensState = (initialState = []) => {
    const [tokens, unsafeDispatch] = useReducer(tokensReducer, initialState);

    const dispatch = useSafeDispatch(unsafeDispatch);

    const setTokens = useCallback((newTokens) => {
        dispatch({ type: 'SET_TOKENS', payload: newTokens });
    }, [dispatch]);

    return {
        tokens,
        setTokens
    };
};
