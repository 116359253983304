import React from 'react';
import { useTranslation } from 'react-i18next';

import './CompletedStatus.scss';

export const CompletedStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-completed-cell">
            {t('common__task__status--completed')}
        </span>
    );
};
