import React from 'react';

import { Tip } from 'library/Tip';
import { Label } from 'library/Label';

export const InputLabelWithTip = ({ title, tipText, container, ...props }) => {
    return (
        <Label>
            {title}{' '}
            {tipText && (
                <Tip
                    className="admin-create-apply-device-settings-task-filters-tip"
                    container={container}
                    placement="bottom"
                    {...props}
                >
                    {tipText}
                </Tip>
            )}
        </Label>
    );
};
