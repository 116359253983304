import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import ActionLoader from '../Alerts/ActionLoader';
import Error from '../Actions/Error';
import { ServiceError } from './Reboot/ServiceError';
import {
  reboot,
  BAD_DEVICE_ID_ERROR,
  DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR,
  ZOOM_DEVICE_UNREACHABLE_ERROR
} from '../../services/Device';

import '../Footer/Footer.css';

import { useNavigate, useSearchParams } from 'react-router-dom';

const REDIRECT_TIME = 5 * 60 * 1000; // 5 minutes

const errorTKeys = {
  [BAD_DEVICE_ID_ERROR]: 'device-connect__error--bad-id',
  [DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR]: 'device-connect__error--zomm-unreachable',
  [ZOOM_DEVICE_UNREACHABLE_ERROR]: 'common__error--zoom-unreachable',
};

const isDeviceErrorState = error => error.message === BAD_DEVICE_ID_ERROR;

const Reboot = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [showCommonLoader, setShowCommonLoader] = useState(true);
  const [isDeviceError, setIsDeviceError] = useState(false);
  const [duid, setDuid] = useState(null);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const queryUUID = queryParams.get('duid');
  useEffect(() => {
    setDuid(queryUUID);
  }, []);

    const startRedirectTimeout = () => {

        setTimeout(() => navigate(-1), REDIRECT_TIME);
    };

  const deviceReboot = async () => {
    try {
      await reboot(duid);
    } catch (err) {
      const errorTKey = errorTKeys[err.message] || 'error';
      setErrorMessage(errorTKey);
      setIsDeviceError(isDeviceErrorState(err));
      setShowCommonLoader(false);
    }

    startRedirectTimeout();
  }

  useEffect(() => {
    if (!duid) {
      return;
    }

    deviceReboot();
  }, [duid]);

  const checkDeviceError = () => isDeviceError;
  const checkServiceError = () => !!errorMessage;

  const getComponent = R.cond([
    [checkDeviceError, () => <Error error={isDeviceError} />],
    [checkServiceError, () => <ServiceError errorMessage={errorMessage} />],
    [R.T, () => <ActionLoader showLoader={showCommonLoader} message='device-reboot__loading' />]
  ]);

  return (
    <section>
      {getComponent()}
    </section>
  );
};

export default Reboot;
