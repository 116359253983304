import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { cond, T } from 'ramda';

import { Label } from 'library/Label';

import './OnOff.scss';

const defaultVariants = [];

const OnOffInput = ({ className, checked, variants = defaultVariants, disabled, onChange }) => {
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        if (disabled) {
            return;
        }

        onChange && onChange(!checked)
    }, [disabled, checked, onChange]);

    return (
        <div className={classNames(
                'hme-on-off',
                {
                    'hme-on-off--checked': checked,
                    'hme-on-off--disabled': disabled
                },
                className,
                variants.map((variant) => `hme-on-off--${variant}`)
        )} onClick={onClick}>
            <div className="hme-on-off__slider"></div>
            <Label className="hme-on-off__label">{
                checked ? t('common__checkbox--on') : t('common__checkbox--off')
            }</Label>
        </div>
    );
};


const OnOffReadOnly = ({checked}) => {
    const { t } = useTranslation();
    return (
        <span className="hme-on-off__label">{
            checked ? t('common__checkbox--on') : t('common__checkbox--off')
        }</span>
    );
};


export const OnOff = cond([
    [({ isReadOnly }) => isReadOnly, OnOffReadOnly],
    [T, OnOffInput]
]);
