import { BrandSharing } from 'pages/BrandSharing';
import { useFranchise } from './hooks/useFranchise';

export const Franchise = () => {
    const state = useFranchise();

    return (
        <BrandSharing {...state} />
    );
};
