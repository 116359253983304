import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '../Common/Input';

import { Section } from '../Common/Section';

const INPUT_VARIANTS = ['label-inside-mobile-only'];

export const UserInfo = ({ account, accountDataErrors, onAccountChange }) => {
    const { t } = useTranslation();

    const handlePasswordFieldsChange = useCallback((key, value) => {
        onAccountChange((currentFields) => ({
            ...currentFields,
            [key]: value
        }));
    }, [onAccountChange]);

    const handleSpecificFieldChange = useCallback(
            ({ target }) => {
                handlePasswordFieldsChange(target.name, target.value);
            },
            [handlePasswordFieldsChange]
    );

    return (
        <Section title={t('my-account__account-info__user-info')} className="hme-user-info">
            <div className="hme-user-info__fields">
                <Input
                    name="firstName"
                    onChange={handleSpecificFieldChange}
                    value={account.firstName || ''}
                    error={accountDataErrors.firstName}
                    isRequired
                    universalOnChange
                    label="common__user__first-name"
                    autoComplete="given-name"
                    variants={INPUT_VARIANTS}
                />
                <Input
                    name="lastName"
                    onChange={handleSpecificFieldChange}
                    value={account.lastName || ''}
                    error={accountDataErrors.lastName}
                    isRequired
                    universalOnChange
                    label="common__user__last-name"
                    autoComplete="family-name"
                    variants={INPUT_VARIANTS}
                />
                <Input
                    name="jobTitle"
                    onChange={handleSpecificFieldChange}
                    value={account.jobTitle || ''}
                    label="common__job-title"
                    universalOnChange
                    variants={INPUT_VARIANTS}
                />
                <Input
                    name="officeNumber"
                    onChange={handleSpecificFieldChange}
                    value={account.officeNumber || ''}
                    label="common__office-number"
                    autoComplete="tel"
                    universalOnChange
                    variants={INPUT_VARIANTS}
                />
                <Input
                    name="mobilePhone"
                    onChange={handleSpecificFieldChange}
                    value={account.mobilePhone || ''}
                    universalOnChange
                    label="common__mobile-phone"
                    autoComplete="tel"
                    variants={INPUT_VARIANTS}
                />
                <Input
                    name="fax"
                    onChange={handleSpecificFieldChange}
                    value={account.fax || ''}
                    universalOnChange
                    label="common__fax"
                    variants={INPUT_VARIANTS}
                />
            </div>
        </Section>
    );
};
