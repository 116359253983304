import React from 'react';
import { Modal as BModal } from 'react-bootstrap';
import classNames from 'classnames';

import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Modal.scss';

const {
    Header: BHeader,
    Body: BBody,
    Footer: BFooter,
} = BModal;

// Notes: In the future we will play with styles
export const Modal = ({ show, title, actions = [], dialogClassName, children, ...modalProps }) => (
    <BModal
        show={show}
        size="lg"
        centered
        dialogClassName={classNames('hme-modal-component', dialogClassName)}
        {...modalProps}
    >
        <BHeader closeButton>
            <Title>{title}</Title>
        </BHeader>
        <BBody>
            { children }
        </BBody>
        {
            actions.length ?
                <BFooter>
                    { actions.map((action, index) => (
                        <Button {...action} key={index} />
                    )) }
                </BFooter>:
            null
        }
    </BModal>
);
