import React from 'react';
import { useTranslation } from 'react-i18next';

import './DownloadInProgressStatus.scss';

export const DownloadInProgressStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-download-in-progress-cell">
            {t('common__task__status--download-in-progress')}
        </span>
    );
};
