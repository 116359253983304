/* eslint-disable react/prop-types */
import './TilesContainer.scss';
import React from 'react';
import { cond, T } from 'ramda';
import { Loader } from '../../../library/Loader';

const NoTiles = ({ noRecordsMessage }) => (
    <span
        className="hme-tiles-container no-records-message"
    >{noRecordsMessage}</span>
);
const TilesContainerComponent = ({ tiles = [], TileComponent, tileKey='' }) => {
    return (
        <div className="hme-tiles-container">
            {tiles.map((tile, i)=>{
                return (
                    <TileComponent key={`${i}-${tile[tileKey]}`} tile={tile} />
                );
            })}
        </div>
    );
};

export const TilesContainer = cond([[
    ({ isLoading }) => isLoading,
    Loader
], [
    ({ tiles }) => tiles.length === 0,
    NoTiles
], [
    T,
    TilesContainerComponent
]]);
