import { createComparator } from 'helpers';

import { announcementItems, errorsTranslations } from '../constants';

const PRESENTATION_FREQUENCY_OPTIONS = [announcementItems.AWARDPAGE, announcementItems.CONTEST_WINNER];

export const validateFrequencies = (values) => {
    const notificationsFrequency = values.find((item) => announcementItems.NOTIFICATION_FREQUENCY === item.SettingType) || {};
    const frequencySettings = values.filter((item) => PRESENTATION_FREQUENCY_OPTIONS.includes(item.SettingType));
    const compare = createComparator(['DisplayDuration', 'DisplayDurationUnit.value', 'DisplayInterval', 'DisplayIntervalUnit.value']);

    for (let i = 0; i < frequencySettings.length; i++) {
        const setting = frequencySettings[i];
        const areEqual = compare(notificationsFrequency, setting);

        if (areEqual) {
            return errorsTranslations.get('frequency-awards-equal');
        }
    }

    return undefined;
};
