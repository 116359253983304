import React, { useCallback } from 'react';

import { AccountInput } from './AccountInput';

const isAccountsDifferent = (account1, account2) => {
    const email1 = account1 ? account1.User_EmailAddress : '';
    const email2 = account2 ? account2.User_EmailAddress : '';

    return email1 !== email2;
};

export const AccountForm = ({ filters, onFiltersChange }) => {
    const onAccountChange = (account) => {
        if (isAccountsDifferent(account, filters.account)) {
            onFiltersChange({
                ...filters,
                account,
            });
        }
    };

    return (
        <AccountInput account={filters.account} onAccountChange={onAccountChange} />
    );
};
