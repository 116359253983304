import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components/Inputs';
import { Select } from 'library/Select';
import { Button } from 'library/Button';

import './SearchOptionable.scss';

const SearchFieldsComponent = ({
    value,
    selectLabel = 'search-optionable__label',
    buttonLabel = 'admin__store-merge__search',
    onChange,
    onSearch,
    searchSelectOptions,
    isSearchButtonDisabled = false,
    isDisabled,
    searchRef = null,
}) => {
    const { t } = useTranslation();

    const onSelectValueChange = useCallback((newSelectValue) => {
        onChange({ selectValue: newSelectValue, searchValue: '' });
    }, [onChange]);

    const onSearchValueChange = useCallback((newSearchValue) => {
        onChange({ ...value, searchValue: newSearchValue });
    }, [value, onChange]);

    const onSearchButtonClick = useCallback(() =>{
        onSearch(value);
    }, [value, onSearch]);

    const onSearchKeyPressed = useCallback((event) => {
        if (event.key === 'Enter' && !isDisabled && !isSearchButtonDisabled) {
            onSearch(value);
        }
    }, [value, onSearch]);

    const searchPlaceholder = useMemo(() => {
        const currentOption = searchSelectOptions.find(({ value: itemValue }) => itemValue === value.selectValue);
        const tKey = currentOption && currentOption.placeholder;

        if (!tKey) {
            return '';
        }

        return t(tKey);
    }, [t, value.selectValue, searchSelectOptions]);

    return <div className="hme-search-optionable">
        <Select
            value={value.selectValue}
            onChange={onSelectValueChange}
            label={t(selectLabel)}
            isDisabled={isDisabled}
        >
            {searchSelectOptions}
        </Select>
        <SearchInput
            value={value.searchValue}
            onChange={onSearchValueChange}
            onKeyPress={onSearchKeyPressed}
            showClearIcon
            isDisabled={isDisabled}
            inputRef={searchRef}
            placeholder={searchPlaceholder}
        />
        <Button
            variants={['submit']}
            onClick={onSearchButtonClick}
            disabled={isDisabled || isSearchButtonDisabled}
        >
            {t(buttonLabel)}
        </Button>
    </div>;
};

SearchFieldsComponent.propTypes = {
    value: PropTypes.shape({
        selectValue: PropTypes.string,
        searchValue: PropTypes.string
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    searchSelectOptions: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
    })).isRequired,
    selectLabel: PropTypes.string,
    buttonLabel: PropTypes.string,
    isSearchButtonDisabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    searchRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    placeholder: PropTypes.string
};

export const SearchOptionable = SearchFieldsComponent;
