/* eslint-disable react/prop-types */
import React from 'react';
import { allMonths } from 'containers/Awards/AwardsResettable/config';
import './FilterInfo.scss';
import { useTranslation } from 'react-i18next';

export const FilterInfo = ({
    year = '',
    month = '0',
    selection = [],
    allStores = [],
    groupedStores = [],
    onClear,
    startYear
}) => {
    const { t } = useTranslation();
    const isStoresUnchanged = allStores.every((el) => selection.includes(el));
    const isYearUnchanged = String(year) === String(startYear);
    const isMonthSelected = Number(month) > 0;
    const isFiltersClear = isStoresUnchanged && isYearUnchanged && !isMonthSelected;
    const flatStores = groupedStores.reduce((acc, el) => {
        if (!el || !el['Stores']) {
            return acc;
        }
        return acc.concat(el['Stores']);
    }, []);
    const storesNumbers = isStoresUnchanged ?
        t('common__all-stores') :
        `${t('common__store')} ${flatStores.map((el) => {
            if (selection.includes(el['StoreUID'])) {
                return ` ${el['StoreNumber']}`;
            }
        }).filter((el) => Boolean(el))}`;

    const allAwards = (t('common__all') + ' ' + t('common__awards')).toLowerCase();

    return (
        <div
            className="hme-enhanced-filter-info"
        >
            {t('common__showing')} {isFiltersClear ? allAwards : storesNumbers} {t('common__in')}&nbsp;
            {isMonthSelected ? t(allMonths[month]) + ' ' : ''}
            {isYearUnchanged ? t('awards__all-filters-clear') : year }&nbsp;
            {isFiltersClear ? '' : (<>
                &nbsp;&nbsp;&nbsp;&nbsp;[<span onClick={onClear}>{t('common__clear-filters')}</span>]
            </>)}
        </div>
    );
};
