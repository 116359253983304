import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { compose } from 'ramda';
import classNames from 'classnames';

import { useTrigger } from 'hooks/useTrigger';
import { Paginate } from 'library/Paginate';
import { NotificationsList } from 'library/NotificationsList';
import { PAGE_DATA } from 'constants/paginationDefault';
import { withHMELayout } from 'HOCs/withHMELayout';
import { getAdminAccounts, getFilters } from 'services/Account';
import { addErrorNotification } from 'services/Notifications';
import { getProfile, getAccountPermissions } from 'services/Auth';
import { Header } from './Header';
import { Footer } from './Footer';
import { AccountsList } from './AccountsList';

import './Accounts.css';

const INIT_SEARCH_PARAMS = { selectValue: 'User_EmailAddress', searchValue: '' };
const INIT_SORT_SELETION = { User_EmailAddress: 1 };
const INIT_PAGE_NUMBER = 0;
const INIT_ITEMS_PER_PAGE = 10;
const SEARCH_DEBOUNCE_TIMEOUT = 1000;
const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;

const loadAccounts = async ({
    setIsAccountsLoading,
    setAccounts,
    pageNumber = 0,
    itemsPerPage,
    sortSelection,
    appliedFilters = {},
    searchParams = {},
    onDone,
}) => {
    try {
        setIsAccountsLoading(true);

        const { selectValue = '', searchValue = '' } = searchParams;
        const [sortColumn] = Object.keys(sortSelection);
        let sortingType = sortSelection && sortSelection[sortColumn] === -1 ? 0 : 1;

        // Account_IsActive is a boolean db field
        // but should be sorted alphabetically (Active, Inactive)
        if (sortColumn === 'Account_IsActive') {
            sortingType = sortingType ? 0 : 1;
        }

        const accounts = await getAdminAccounts({
            pageNumber: pageNumber + 1,
            itemsPerPage,
            sortColumn,
            sortingType,
            searchField: selectValue,
            search: searchValue?.trim(),
            appliedFilters,
        });

        setAccounts(accounts);
    } catch (e) {
        setAccounts([]);
    }

    setIsAccountsLoading(false);

    // trigger callback for debounced executions
    onDone && onDone();
};

const loadAccountsDebounced = debounce(loadAccounts, SEARCH_DEBOUNCE_TIMEOUT);

const loadFilters = async ({ setIsFiltersLoading, setFilters }) => {
    try {
        setIsFiltersLoading(true);

        const filters = await getFilters();

        setFilters(filters);
    } catch (e) {
        addErrorNotification('common__error--internal-server');
        setFilters([]);
    }

    setIsFiltersLoading(false);
};

const AccountsComponent = () => {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [filters, setFilters] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [isAccountsLoading, setIsAccountsLoading] = useState(false);
    const [isFiltersLoading, setIsFiltersLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(INIT_PAGE_NUMBER);
    const [itemsPerPage, setItemsPerPage] = useState(INIT_ITEMS_PER_PAGE);
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELETION);
    const [searchParams, setSearchParams] = useState(INIT_SEARCH_PARAMS);
    const [filtersCount, setFiltersCount] = useState(0);
    const profile = useMemo(() => getProfile(), []);
    const accountPermissions = useMemo(() => getAccountPermissions(profile.admin), [profile]);
    const searchRef = useRef(null);
    const resetFiltersTrigger = useTrigger();

    const onPaginationChange = useCallback(
        ({ page = 0, recordsPerPage = 10 } = {}) => {
            setPageNumber(page);
            setItemsPerPage(recordsPerPage);

            loadAccounts({
                setIsAccountsLoading,
                setAccounts,
                pageNumber: page,
                itemsPerPage: recordsPerPage,
                sortSelection,
                searchParams,
                appliedFilters,
            });
        },
        [
            setPageNumber,
            setItemsPerPage,
            setIsAccountsLoading,
            setAccounts,
            sortSelection,
            searchParams,
            appliedFilters,
        ],
    );

    const onSortChange = useCallback(
        (newSortData) => {
            setSortSelection(newSortData);
            loadAccounts({
                setIsAccountsLoading,
                setAccounts,
                itemsPerPage,
                sortSelection: newSortData,
                searchParams,
                appliedFilters,
            });
        },
        [setSortSelection, setIsAccountsLoading, setAccounts, pageNumber, itemsPerPage, appliedFilters, searchParams],
    );

    const focusSearch = useCallback(() => {
        if (searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef]);

    const onSearchDone = useCallback(() => {
        focusSearch();
        setPageNumber(INIT_PAGE_NUMBER);
    }, [focusSearch, setPageNumber]);

    const onSearch = useCallback(
        (newSearchParams) => {
            loadAccountsDebounced.cancel();
            loadAccounts({
                setIsAccountsLoading,
                setAccounts,
                itemsPerPage,
                sortSelection,
                searchParams: newSearchParams,
                appliedFilters,
                onDone: onSearchDone,
            });
        },
        [setIsAccountsLoading, setAccounts, itemsPerPage, sortSelection, appliedFilters],
    );

    const onSearchParamsChange = useCallback(
        (newSearchParams) => {
            setSearchParams(newSearchParams);

            if (!newSearchParams.searchValue) {
                focusSearch();
            }

            if (newSearchParams.searchValue.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH) {
                loadAccountsDebounced.cancel();
                return;
            }

            loadAccountsDebounced({
                setIsAccountsLoading,
                setAccounts,
                itemsPerPage,
                sortSelection,
                searchParams: newSearchParams,
                appliedFilters,
                onDone: onSearchDone,
            });
        },
        [
            setIsAccountsLoading,
            setAccounts,
            itemsPerPage,
            sortSelection,
            appliedFilters,
        ],
    );

    const onFiltersChange = useCallback(
        ({ Subscription_Name, Company_Type, Account_IsActive, Country_Name }) => {
            const newAppliedFilters = {
                subscriptionIds: Subscription_Name.join(','),
                companyTypes: Company_Type.join(','),
                accountStatuses: Account_IsActive.join(','),
                countryIds: Country_Name.join(','),
            };

            setAppliedFilters(newAppliedFilters);

            loadAccounts({
                setIsAccountsLoading,
                setAccounts,
                itemsPerPage,
                sortSelection,
                searchParams,
                appliedFilters: newAppliedFilters,
            });

            setPageNumber(INIT_PAGE_NUMBER);
        },
        [setIsAccountsLoading, setAccounts, itemsPerPage, sortSelection, searchParams],
    );

    useEffect(() => {
        loadFilters({ setIsFiltersLoading, setFilters });
    }, []);

    useEffect(() => {
        loadAccounts({
            setIsAccountsLoading,
            setAccounts,
            pageNumber,
            itemsPerPage,
            sortSelection,
            searchParams,
            appliedFilters,
        });
    }, []);

    const itemsTotal = useMemo(() => (accounts && accounts[0] ? accounts[0].Total : 0), [accounts]);
    const pageSizes = [10, 20, 50, -1];

    const shouldFooterBeShown = useMemo(
        () => !!(accounts.length && itemsTotal > pageSizes[0]),
        [accounts, itemsTotal, pageSizes],
    );

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
        setAppliedFilters({});

        loadAccounts({
            setIsAccountsLoading,
            setAccounts,
            pageNumber,
            itemsPerPage,
            sortSelection,
            searchParams,
        });
    }, [
        accounts,
        setAccounts,
        setIsAccountsLoading,
        setAccounts,
        setAppliedFilters,
        pageNumber,
        itemsPerPage,
        sortSelection,
        searchParams,
        resetFiltersTrigger
    ]);

    return (
        <div className={classNames('hme-page-component', 'hme-accounts-wrapper')}>
            <div className="hme-components hme-accounts">
                <NotificationsList />
                <Header
                    searchParams={searchParams}
                    onSearchParamsChange={onSearchParamsChange}
                    onSearch={onSearch}
                    accountPermissions={accountPermissions}
                    searchRef={searchRef}
                    filtersCount={filtersCount}
                    onFiltersReset={onFiltersReset}
                />
                <AccountsList
                    accounts={accounts}
                    isLoading={isAccountsLoading || isFiltersLoading}
                    onSortChange={onSortChange}
                    sortSelection={sortSelection}
                    filters={filters}
                    resetFiltersTrigger={resetFiltersTrigger}
                    onFiltersChange={onFiltersChange}
                    onFiltersCountChange={setFiltersCount}
                />
                {shouldFooterBeShown && (
                    <Footer>
                        <Paginate
                            className="hme-accounts__paginate"
                            page={pageNumber}
                            recordsPerPage={itemsPerPage}
                            pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                            total={itemsTotal}
                            onChange={onPaginationChange}
                            hideSinglePage
                        />
                    </Footer>
                )}
            </div>
        </div>
    );
};

export const Accounts = compose(
    withHMELayout({
        contentClasses: ['accounts-page'],
    }),
)(AccountsComponent);
