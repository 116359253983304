import {isAdmin, loginUser, getProfile, dfaAuth, isPartner, verifyUsername as authVerifyUsername } from 'services/Auth';
import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { DEFAULT_IP, defaultNotificationOptions } from 'constants/login'
import { CommonConstants } from 'Constants';
import { PUBLIC_ROUTES } from 'constants/routes';
import { Config } from 'Config';

const getTimeoutKey = () => {
    return isAdmin() ? 'adminloginAttemptsDate' : 'loginAttemptsDate';
};

const getLoginAttemptsKey = () => {
    return isAdmin() ? 'adminloginAttempts' : isPartner() ? 'partnerloginAttempts' : 'loginAttempts';
};

export const login = async (formData, redirectUrl, ip, ssoTokenData) => {
    try {
        const loginResult = await loginUser({
            ...formData,
            ip: ip && ip.clientIp || DEFAULT_IP,
            portalBaseUrl: window.location.href,
            ssoTokenData
        });

        if ('location' in loginResult) {
            return {
                success: true,
                location: loginResult.location
            };
        }

        window.localStorage.removeItem(getTimeoutKey());
        window.localStorage.removeItem(getLoginAttemptsKey());
        sessionStorage.removeItem('ssoUserId');

        const profile = getProfile();

        if (profile.admin) {
            // TODO: Use History from react-router-dom when we will be ready for SPA
            window.location = redirectUrl;

            return {
                success: true,
            };
        }

        if (profile.Account_DFA_IsDFA) {
            const dfaResult = await dfaAuth();

            if (dfaResult.status && dfaResult.isDFA === 1) {
                return {
                    success: true,
                    redirectTo: '/validateDfaAuth',
                };
            }
        }

        const nextPageUrl = profile.Account_TOS_Agree || ssoTokenData ? redirectUrl : `/${PUBLIC_ROUTES.newAccount}`;

        // TODO: Use History from react-router-dom when we will be ready for SPA
        window.location = nextPageUrl;

        return {
            success: true,
        };
    } catch (err) {
        const isExceeded = err.loginResult && err.loginResult.loginAttempts === err.loginResult.maxAttempts;

        window.localStorage.setItem(getLoginAttemptsKey(), err.loginResult.loginAttempts);

        if (isExceeded) {
            window.localStorage.setItem(getTimeoutKey(), err.loginResult.timeout);
        }

        addNotification({
            type: NOTIFICATION_TYPES.ERROR,
            message: [err.message, 'common__error--internal-server'],
        }, defaultNotificationOptions);

        return {
            success: false,
            loginAttempts: err.loginResult.loginAttempts,
            maxAttempts: err.loginResult.maxAttempts,
            isExceeded,
        };
    }
};

export const verifyUsername = () => {
    window.localStorage.removeItem(getTimeoutKey());
    window.localStorage.removeItem(getLoginAttemptsKey());
};

export const checkTimeout = () => {
    const timeoutKey = getTimeoutKey();

    const timeout = window.localStorage.getItem(timeoutKey);

    if (!timeout) {
        return true;
    }

    const now = new Date();
    const timeoutDate = new Date(timeout);

    const diff = now - timeoutDate;

    const minutes = diff / (60 * 1000);

    if (minutes < CommonConstants.loginAttempts.checkAttemptsTimeout) {
        return false;
    }

    window.localStorage.removeItem(timeoutKey);
    window.localStorage.removeItem(getLoginAttemptsKey());

    return true;
};

export const getLoginAttempts = () => {
    return window.localStorage.getItem(getLoginAttemptsKey()) || 0;
};
