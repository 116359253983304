import { Link } from 'react-router-dom';

import { CommonConstants } from 'Constants';

import { useGlobalState } from 'globalState';
import { NotificationsIcon } from './NotificationsIcon';

export const NotificationsCenterLink = () => {
    const { unreadNotifications } = useGlobalState();

    return (
        <Link to={CommonConstants.NotificationsCenterPage}>
            <NotificationsIcon
                hasUnread={unreadNotifications.hasUnreadNotifications}
            />
        </Link>
    );
};
