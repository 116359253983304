import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GridActions } from 'library/GridActions';
import { BUTTON_VARIANTS, Button } from 'library/Button';
import { PUBLIC_ROUTES } from 'constants/routes';

import { CONTEST_STATUS_NAMES } from '../../../Common/Constants';

import './Actions.scss';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

const ViewLink = ({ uid, children }) => {
    return (
        <Button
            as={Link}
            to={PUBLIC_ROUTES.contestDetails(uid)}
            variants={buttonVariants}
            className="hme-contest-actions__view-link"
        >
            {children}
        </Button>
    );
};

const ScheduledContestActions = ({ uid, onCancel }) => {
    const { t } = useTranslation();

    const handleCancel = () => onCancel(uid);

    return (
        <GridActions className="hme-contest-actions">
            <ViewLink uid={uid}>{t('common__view-edit')}</ViewLink>
            <Button variants={buttonVariants} onClick={handleCancel} className="hme-contest-actions__cancel">
                {t('common__cancel')}
            </Button>
        </GridActions>
    );
};

const InProgressContestActions = ({ uid, onCancel }) => {
    const { t } = useTranslation();

    const handleCancel = () => onCancel(uid);

    return (
        <GridActions className="hme-contest-actions">
            <ViewLink uid={uid}>{t('common__view')}</ViewLink>
            <Button variants={buttonVariants} onClick={handleCancel} className="hme-contest-actions__cancel">
                {t('common__cancel')}
            </Button>
        </GridActions>
    );
};

const CompletedContestActions = ({ uid, showCertificate }) => {
    const { t } = useTranslation();

    return (
        <GridActions className="hme-contest-actions">
            <ViewLink uid={uid}>{t('common__view')}</ViewLink>
            <Button
                variants={buttonVariants}
                onClick={showCertificate}
                className="hme-contest-actions__view-certificate"
            >
                {t('contest__certificate')}
            </Button>
        </GridActions>
    );
};

const CONTEST_STATUSE_CELL_ACTIONS = {
    [CONTEST_STATUS_NAMES.SCHEDULED]: ScheduledContestActions,
    [CONTEST_STATUS_NAMES.IN_PROGRESS]: InProgressContestActions,
    [CONTEST_STATUS_NAMES.COMPLETED]: CompletedContestActions,
};

export const Actions = ({ statusType, uid, ...props }) => {
    const Component = CONTEST_STATUSE_CELL_ACTIONS[statusType];

    return <Component uid={uid} {...props} />;
};
