export const HISTORY_MOBILE_GRID_OPTIONS = [
    {
        column: [
            { text: 'text-n-connect__history__grid-header--from', property: 'createdBy' },
            { text: 'text-n-connect__history__grid-header--to', property: 'stores' },
        ],
    },
    {
        column: [{ text: 'text-n-connect__history__grid-header--sent-datetime', property: 'createdAt' }],
    },
];
