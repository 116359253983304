import { trimValues } from 'helpers';
import { hasAllRequired } from 'helpers/object/hasAllRequired';

const requiredFields = ['name', 'permissions'];

export const trimmedKeys = ['name', 'description'];

export const isRoleValid = (role) => {
    const trimmedRole = trimValues(role, trimmedKeys)

    if (!hasAllRequired(trimmedRole, requiredFields)) {
        return false;
    }

    return trimmedRole.name.length > 2 && trimmedRole.name.toLowerCase() !== 'account owner';
};

export const areRolesEqual = (role1, role2) => {
    if (!role1 || !role2) {
        return false;
    }

    if (role1.name !== role2.name) {
        return false;
    }

    if (role1.description !== role2.description) {
        return false;
    }

    if (role1.permissions.length !== role2.permissions.length) {
        return false;
    }

    return role1.permissions.every((permission) => role2.permissions.includes(permission));
};
