/* eslint-disable complexity */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Label } from 'library/Label';
import { SingleSelect as Select } from 'library/SingleSelect';
import { DateSelector } from 'library/DateSelector';
import { Checkbox } from 'library/Checkbox';
import { ErrorLabel } from 'library/ErrorLabel';
import { DateLib } from '@hme-cloud/utility-common';

import { upgradeStatuses } from '../../constants/upgradeStatuses';
import { validateRunDate } from '../../helpers/validateRunDate';
import { weekDays } from '../../constants';
import { getScheduleTime } from '../../helpers';

import { ReactComponent as ClockIcon } from 'images/icon-clock.svg';

const MONTHS_TO_SHOW = 1;

const disableSameTime = (time, scheduledTimes) => {
    return scheduledTimes.map((st) => ({
        ...st,
        disabled: st.value === time,
    }));
};

export const UpgradeScheduleDate = ({
    title,
    scheduledUpgrade,
    upgradeStatus,
    formErrors,
    minDate,
    maxDate,
    deviceUpgradeDateTime,
    onDeviceUpgradeDateTimeChange,
}) => {
    const { t } = useTranslation();

    const [isAllDaysUpgrade, setIsAllDaysUpgrade] = useState(false);
    const [deviceUpgradeDateErrors, setDeviceUpgradeDateErrors] = useState({});

    const isUpgradeFailed = upgradeStatus === upgradeStatuses.FAILED;
    const isUpgradeInProgress = upgradeStatus === upgradeStatuses.IN_PROGRESS;
    const isUpgradeCancelled = upgradeStatus === upgradeStatuses.CANCELLED;

    const { scheduleTime, initialStartTime, initialEndTime } = useMemo(() => getScheduleTime(deviceUpgradeDateTime), []);

    const [scheduleTimeFrom, setScheduleTimeFrom] = useState(initialStartTime);
    const [scheduleTimeTo, setScheduleTimeTo] = useState(initialEndTime);
    const initialUpgradeDate = useMemo(() => deviceUpgradeDateTime.deviceUpgradeDate, []);

    const onDeviceUpgradeDateChange = useCallback(
        (deviceUpgradeDate) => {
            onDeviceUpgradeDateTimeChange({
                ...deviceUpgradeDateTime,
                deviceUpgradeDate,
                upgradeScheduleDays: !(scheduledUpgrade && scheduledUpgrade.DaysofWeek)
                    ? [new DateLib(deviceUpgradeDate).dayOfWeek]
                    : deviceUpgradeDateTime.upgradeScheduleDays,
            });
            setIsAllDaysUpgrade(false);
            setDeviceUpgradeDateErrors({});
        },
        [deviceUpgradeDateTime, onDeviceUpgradeDateTimeChange],
    );

    const onScheduleDayChange = useCallback(
        (checked, value) => {
            const newScheduleDays = checked
                ? [...deviceUpgradeDateTime.upgradeScheduleDays, value]
                : deviceUpgradeDateTime.upgradeScheduleDays.filter((sd) => sd !== value);

            setIsAllDaysUpgrade(newScheduleDays.length === weekDays.length);
            onDeviceUpgradeDateTimeChange({
                ...deviceUpgradeDateTime,
                upgradeScheduleDays: newScheduleDays,
            });
        },
        [deviceUpgradeDateTime, weekDays],
    );

    const onAllScheduleDaysChange = useCallback(
        (checked) => {
            const newScheduleDays = checked ? weekDays.map((wd) => wd.value) : [];
            onDeviceUpgradeDateTimeChange({
                ...deviceUpgradeDateTime,
                upgradeScheduleDays: newScheduleDays,
            });
            setIsAllDaysUpgrade(checked);
        },
        [deviceUpgradeDateTime, weekDays],
    );

    const onTimeFromChange = useCallback(
        (upgradeTime) => {
            const newDeviceUpgradeDateTime = {
                ...deviceUpgradeDateTime,
                deviceUpgradeTimeFrom: upgradeTime,
            };

            setScheduleTimeTo(disableSameTime(upgradeTime, scheduleTime));
            onDeviceUpgradeDateTimeChange(newDeviceUpgradeDateTime);
        },
        [scheduleTimeFrom, scheduleTimeTo, deviceUpgradeDateTime],
    );

    const onTimeToChange = useCallback(
        (upgradeTime) => {
            const newDeviceUpgradeDateTime = {
                ...deviceUpgradeDateTime,
                deviceUpgradeTimeTo: upgradeTime,
            };

            setScheduleTimeFrom(disableSameTime(upgradeTime, scheduleTime));
            onDeviceUpgradeDateTimeChange(newDeviceUpgradeDateTime);
        },
        [scheduleTimeFrom, scheduleTimeTo, deviceUpgradeDateTime],
    );

    const onValidateRunDate = useCallback(
        (value) => {
            setDeviceUpgradeDateErrors({
                runDate: validateRunDate(value, { initialUpgradeDate, minDate, maxDate }),
            });
        },
        [validateRunDate, minDate, maxDate],
    );

    const validationMessageRunDate = deviceUpgradeDateErrors.runDate || formErrors.runDate;

    return (
        <div className="hme-create-device-upgrade-schedule-date">
            <div
                className={classNames([
                    'hme-create-device-upgrade-form-section',
                    validationMessageRunDate ? 'hme-validation-error' : '',
                ])}
            >
                <Label>{t(title)}</Label>
                <DateSelector
                    key={deviceUpgradeDateTime.deviceUpgradeDate} // the key is needed to remount the date picker when the date is changed from the parent component
                    isDisabled={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    className="hme-create-device-upgrade-date-picker"
                    label={t('device-upgrades__section__form--starting-on')}
                    value={deviceUpgradeDateTime.deviceUpgradeDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={onDeviceUpgradeDateChange}
                    months={MONTHS_TO_SHOW}
                    onValidate={onValidateRunDate}
                />
                {validationMessageRunDate && <ErrorLabel>{t(validationMessageRunDate)}</ErrorLabel>}
            </div>
            <div
                className={classNames([
                    'hme-create-device-upgrade-form-section',
                    'hme-create-device-upgrade-form-all-days-upgrade',
                    formErrors.scheduleDays ? 'hme-validation-error' : '',
                ])}
            >
                <Checkbox
                    disabled={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    readOnly={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    label={t('device-upgrades__section__form--all-days-of-the-week')}
                    checked={isAllDaysUpgrade}
                    onChange={onAllScheduleDaysChange}
                />
                <div className="schedule-days">
                    {weekDays.map(({ text, value }) => (
                        <Checkbox
                            disabled={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                            key={value}
                            label={t(text)}
                            checked={deviceUpgradeDateTime.upgradeScheduleDays.includes(value)}
                            onChange={(checked) => onScheduleDayChange(checked, value)}
                        />
                    ))}
                </div>
                {formErrors.scheduleDays && <ErrorLabel>{t(formErrors.scheduleDays)}</ErrorLabel>}
            </div>
            <div className="hme-create-device-upgrade-form-section">
                <Select
                    isDisabled={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    items={scheduleTimeFrom}
                    Icon={<ClockIcon className="input-dateselector-icon" />}
                    variants={['label-inside']}
                    label={t('device-upgrades__section__form--from')}
                    value={deviceUpgradeDateTime.deviceUpgradeTimeFrom}
                    onChange={onTimeFromChange}
                />
            </div>
            <div className="hme-create-device-upgrade-form-section">
                <Select
                    isDisabled={isUpgradeFailed || isUpgradeInProgress || isUpgradeCancelled}
                    items={scheduleTimeTo}
                    Icon={<ClockIcon className="input-dateselector-icon" />}
                    variants={['label-inside']}
                    label={t('device-upgrades__section__form--to')}
                    value={deviceUpgradeDateTime.deviceUpgradeTimeTo}
                    onChange={onTimeToChange}
                />
            </div>
        </div>
    );
};

// add props validation
UpgradeScheduleDate.propTypes = {
    title: PropTypes.string.isRequired,
    scheduledUpgrade: PropTypes.object,
    upgradeStatus: PropTypes.string,
    formErrors: PropTypes.object,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    deviceUpgradeDateTime: PropTypes.object,
    onDeviceUpgradeDateTimeChange: PropTypes.func
};
