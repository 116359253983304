/* eslint-disable react/prop-types */
import './LandingTiles.scss';
import React, { useEffect, useState } from 'react';
import { TilesContainer } from 'components/Common/TilesContainer';
import { ManageMessagesTile } from './ManageMessagesTile';
import { Modal } from 'react-bootstrap';
import { JoinedFilters } from 'library/JoinedFilters';
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter.svg';
import classNames from 'classnames';
import { cond, empty, T } from 'ramda';
import { FilterFooter } from '../../../../../library/JoinedFilters/FilterFooter';
import { Button } from '../../../../../library/Button';
import { Loader } from '../../../../../library/Loader';
import { gridFiltersCounter } from '../../../../../helpers/filters';
import { useTranslation } from 'react-i18next';

const LandingTilesComponent = ({
    filters,
    onFiltersChange = empty,
    loader,
    availableFilters,
    tiles = [],
    sectionLables,
    filtersCounter,
    resetFilters,
    noRecordsMessage,
    createMessage
}) => {
    const [isApplyEnabled, setIsApplyEnabled] = useState(false);
    const [newSelection, setNewSelection] = useState({});
    const [isPopupShown, setIsPopupShown] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setNewSelection(filters);
    }, [filters]);
    const onFilterChange = (newFilter) => {
        const filtered = { ...newSelection, ...newFilter };
        const counter = gridFiltersCounter(availableFilters, filters, filtered);
        setIsApplyEnabled(counter > 0);
        setNewSelection(filtered);
    };

    const popupToggle = () => {
        setIsPopupShown(!isPopupShown);
    };

    const onHide = () => {
        if (isPopupShown) {
            setIsPopupShown(false);
        }
        onFilterChange(filters);
    };

    const onApply = () => {
        onFiltersChange(newSelection);
        onHide();
    };

    const FooterComponent = (
        <FilterFooter
            isApplyEnabled={isApplyEnabled}
            onApply={onApply}
            onHide={onHide}
            okLabel='common__apply-filters'
        />
    );
    return (
        <div className={classNames('hme-messages-landing-container-s', { 'disabled': loader })}>
            <Modal
                show={isPopupShown}
                dialogClassName={classNames('hme-messages-landing-popup-wrapper', { opened: isPopupShown })}
                className="hme-messages-landing-popup-modal"
                backdropClassName="hme-messages-landing-popup-backdrop"
                onHide={onHide}
            >
                <JoinedFilters
                    show={isPopupShown}
                    availableFilters={availableFilters}
                    selection={newSelection}
                    onChange={onFilterChange}
                    onHide={onHide}
                    sectionLables={sectionLables}
                    footer={FooterComponent}
                />
            </Modal>
            <div className="hme-create-message-button">
                <Button className="hme-mobile-button" onClick={() => createMessage()}>
                    {t('smack-talk__tab__messages-landing--create-custom-message')}
                </Button>
            </div>
            <div className="hme-messages-landing-header-container">
                {Boolean(filtersCounter) && <span className="hme-messages-landing-header-reset" onClick={resetFilters}>{t('common__reset')}</span>}
                <Button
                    className={classNames({ 'hme-submit-button-component': filtersCounter })}
                    variants={['tile-rounded']}
                    onClick={popupToggle}
                >
                    {Boolean(filtersCounter) && filtersCounter}&nbsp;
                    <span>{t('common__filter').toUpperCase()}</span>&nbsp;
                    <FilterIcon
                        className="hme-enhanced-filter-icon"
                    />
                </Button>
            </div>
            <TilesContainer
                tiles={tiles}
                TileComponent={ManageMessagesTile}
                tileKey="messageId"
                noRecordsMessage={noRecordsMessage}
            />
        </div>
    );
};

export const LandingTiles = cond([[
    ({ isLoading }) => isLoading,
    Loader
], [
    T,
    LandingTilesComponent
]]);
