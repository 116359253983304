import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Paginate } from 'library/Paginate';
import { PAGE_DEFAULT } from 'constants/paginationDefault';

import { Notification } from './Notification';
import { useNotificationsCenterContext } from '../Context';

import './List.scss';

export const List = ({
    notifications,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(PAGE_DEFAULT.page);
    const [recordsPerPage, setRecordsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);
    const { active } = useNotificationsCenterContext();

    const showedNotifications = useMemo(
        () => notifications.slice(page * recordsPerPage, (page + 1) * recordsPerPage),
        [notifications, page, recordsPerPage]
    );

    const onPaginationChange = useCallback(({ page: newPage, recordsPerPage: newRecordsPerPage }) => {
        setPage(newPage);
        setRecordsPerPage(newRecordsPerPage);
    }, []);

    useEffect(() => {
        if (active.startPage === null) {
            return;
        }

        setPage(active.startPage);
    }, [active.startPage]);

    return (
        <div className='hme-notifications-center__list'>
            {
                showedNotifications.length ?
                showedNotifications.map((notification) => (
                    <Notification
                        notification={notification}
                        key={notification.id}
                    />
                )) :
                <div className='hme-notifications-center__list__empty'>
                    {t('notifications-center__list__empty')}
                </div>
            }
            {
                <Paginate
                    total={notifications.length}
                    page={page}
                    recordsPerPage={recordsPerPage}
                    onChange={onPaginationChange}
                />
            }
        </div>
    );
};
