import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { DateLib } from '@hme-cloud/utility-common';

import { getDayKey } from '../../utils';

import './Day.scss';

export const Day = ({
    date,
    monthDate,
    range,
    minDate,
    maxDate,
    isSelectionMode,
    onClick,
    onBlur
}) => {
    const [isToday, setIsToday] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onClickHandler = useCallback((e) => {
        e.stopPropagation();

        if (isDisabled) {
            return;
        }

        onClick && onClick(date);
    }, [isDisabled, onClick, getDayKey(date)]);

    const onBlurHandler = useCallback(() => {
        if (isDisabled) {
            return;
        }

        onBlur && onBlur(date);
    }, [isDisabled, onBlur, getDayKey(date)]);

    useEffect(() => {
        const now = new DateLib();

        setIsToday(now.isSameDay(date));
    }, [date]);

    useEffect(() => {
        let disabled = minDate && date.isBefore(minDate, 'day');
        disabled = disabled || (maxDate && date.isAfter(maxDate, 'day'));

        setIsDisabled(disabled);
    }, [minDate, maxDate, date]);

    return (
        <span
            className={classNames('hme-calendar-selector-day', {
                'hme-calendar-selector-day--other-month': date.format(DateLib.FORMAT_TYPES.SINGLE_MONTH_ONLY) !== monthDate.format(DateLib.FORMAT_TYPES.SINGLE_MONTH_ONLY),
                'hme-calendar-selector-day--today': isToday,
                'hme-calendar-selector-day--in-selection-mode': isSelectionMode,
                'hme-calendar-selector-day--start-date': !isDisabled && range && range.startDate && range.startDate.isSame(date, 'day'),
                'hme-calendar-selector-day--end-date': !isDisabled && range && range.endDate && range.endDate.isSame(date, 'day'),
                'hme-calendar-selector-day--in-range': !isDisabled && range && date.isBetween(range.startDate, range.endDate, 'day', '[]'),
                'hme-calendar-selector-day--disabled': isDisabled,
            })}
            onClick={onClickHandler}
            onMouseEnter={onBlurHandler}
        >{date.format(DateLib.FORMAT_TYPES.SINGLE_DAY_ONLY)}</span>
    );
};
