import { getFilteredAccounts } from './getFilteredAccounts';
import { getAccountWithPagination } from './getAccountWithPagination';
import { getSortedAccountsList } from './getSortedAccountsList';

export const getShowedRows = (
    rows,
    {
        searchParamsBy,
        filters,
        sortSelection,
        paginationData,
        filtersOptions,
        searchProperties = []
    }
) => {
    const filteredRows = getFilteredAccounts(rows, filters, searchParamsBy, filtersOptions, searchProperties);
    const total = filteredRows.length;
    const sortedRows = getSortedAccountsList(filteredRows, sortSelection);
    const paginatedRows = getAccountWithPagination(sortedRows, paginationData);

    return {
        total,
        newShowedRows: paginatedRows,
    };
};