import React from 'react';
import { useTranslation } from 'react-i18next';

import './InProgressStatus.scss';

export const InProgressStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-progress-cell">
            {t('common__task__status--in-progress')}
        </span>
    );
};
