import React from 'react';
import { ContactInfo } from './ContactInfo';
import { HmeDetails } from './HmeDetails';

import './CompanyInfoForm.scss';

export const CompanyInfoForm = ({
    formData,
    formErrors,
    countries,
    regions,
    distributors,
    shouldShowDistributors,
    onContactInfoChange,
    onHmeDetailsChange,
}) => {
    return (
        <>
            <ContactInfo
                initialValues={formData}
                formErrors={formErrors}
                countries={countries}
                regions={regions}
                distributors={distributors}
                onChange={onContactInfoChange}
            />

            {shouldShowDistributors && (
                <HmeDetails initialValues={formData} distributors={distributors} onChange={onHmeDetailsChange} />
            )}
        </>
    );
};
