import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';

import './Footer.scss';

const submitButtonVariants = ['submit'];

export const Footer = ({ isSaveButtonDisabled, onCancel, onSave }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-view-user__footer'>
            <Button className='hme-view-user__footer__cancel' onClick={onCancel}>{t('common__cancel')}</Button>
            <Button
                className='hme-view-user__footer__save'
                variants={submitButtonVariants}
                disabled={isSaveButtonDisabled}
                onClick={onSave}
            >{t('common__save')}</Button>
        </div>
    )
};

Footer.propTypes = {
    isSaveButtonDisabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
};
