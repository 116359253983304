import { useState, useEffect, useCallback } from 'react';
import { getDeviceSettings } from '../Controller';

export const useLoadDeviceSettings = (deviceUID) => {
    const [deviceSettings, setDeviceSettings] = useState(null);
    const [isDeviceSettingsLoading, setIsDeviceSettingsLoading] = useState(true);
    const [deviceSettingsLoadingError, setDeviceSettingsLoadingError] = useState(null);

    const loadDeviceSettings = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceSettingsLoading(true);
            setDeviceSettingsLoadingError(null);
            const deviceSettings = (await getDeviceSettings(duid)) || null;

            setDeviceSettings(deviceSettings);
        } catch (e) {
            console.error('Error while loading device settings', e);
            setDeviceSettings(null);
            setDeviceSettingsLoadingError(e);
        }

        setIsDeviceSettingsLoading(false);
    }, []);

    useEffect(() => { loadDeviceSettings(deviceUID); }, [deviceUID]);

    return { deviceSettings, isDeviceSettingsLoading, deviceSettingsLoadingError, loadDeviceSettings };
};
