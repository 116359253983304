import React, { Component } from 'react'
import { Loader } from 'library/Loader';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { Config } from '../../../Config'
import { CommonConstants } from '../../../Constants'
import Api from '../../../Api'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { addStoreFieldsList } from './generateFieldsList';
import { customValidate, validate } from './helpers/storeValidation';
import {
  handleInputChange,
  handleCheckboxChange,
  generateTableBody
} from './helpers/generateTableBody';
import { postData } from './helpers/postData'
import { getUserAccountDetails } from 'services/Account';
import { getVaioProviders } from 'services/StoreFeatures';
import { addErrorNotification } from 'services/Notifications';

import '../Stores.css'

const errorTKey = {
  'storealreadyexists': 'stores__error--already-exists',
};

// This componenet is used for the store detail popup and store detail page.
class AddStoreComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showStores: this.props.showStores,
      isValid: true,
      requestErrors: [],
      errors: [],
      isSuccess: false,
      storeBrandID: 0,
      storeName: '',
      storeNumber: '',
      storeCountryID: 1,
      storeAXCustomerAccount: '',
      vaioProviderId: 0,
      fieldsList: [],
      isPending: false,
      isDataLoading: true
    }

    this.api = new Api();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleCheckboxChange = handleCheckboxChange.bind(this);
    this.generateTableBody = generateTableBody.bind(this);
    this.postData = postData.bind(this);
  }

  async componentDidMount() {
    try {
      const providers = await getVaioProviders(false)
      const fieldsList = addStoreFieldsList({ ...this.props, vaioProviders: providers });
      this.setState({ fieldsList, isDataLoading: false })
    } catch (e) {
      addErrorNotification(this.props.t('vaio__error-retrieving-providers'))
    }
  }

  async handleSubmit() {
    const { uuid = '', brands = [] } = this.props;
    const {
      fieldsList,
      storeBrandID,
      storeName,
      storeNumber,
      storeCountryID,
      storeAXCustomerAccount,
      vaioProviderId
    } = this.state;

    const stateData = {
      storeBrandID,
      storeName,
      storeNumber,
      storeCountryID,
      storeAXCustomerAccount,
      vaioProviderId
    };
    const { isValid, errors } = validate(fieldsList, stateData);
    const { isCustomValid, customErrors } = customValidate(stateData);

    if (!isValid || !isCustomValid) {
      return this.setState({
        errors: [...errors, ...customErrors],
        isValid: isValid && isCustomValid
      });
    }
    this.setState({ isDataLoading: true });
    try {
      const { User_EmailAddress: accountEmail = '' } = await getUserAccountDetails(uuid, true);

      const selectedBrand = brands.find((brandData) => brandData.Id === Number(storeBrandID));
      const brandLetter = selectedBrand ? selectedBrand.Letter : '';

      const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createPreInstallStore}`;
      const data = { ...stateData, uuid, accountEmail, brandLetter };
      // Making a service call
      await this.postData(url, data);
    } catch (error) {
      console.log('Add new Store error:', error);
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  render() {
    const { t } = this.props;
    const { isDataLoading } = this.state;

    return (
      <div>
        <div className={'storePopupForm'}>
          <div className={`saveStoreErrors ${this.state.isValid ? 'hide' : 'show'}`} >
            {this.state.errors.map(errorMessage => (<div>{errorMessage}</div>))}
            {this.state.requestErrors.map(errorMessage => (<div>{t(errorTKey[errorMessage], 'common__error--internal-server')}</div>))}
          </div>
        </div>
        {isDataLoading
          ?
          <div className="storePopupForm-Loader">
            <Loader variants={['invert']} />
          </div>
          :
          <>
            <table className='user_form'>
              <tbody>
                {this.generateTableBody()}
              </tbody>
            </table>
            <div className={'add-store-save'}>
              <div
                className='submit-public'
                translate=''
                onClick={this.props.close}
                key='settingsStoresCancel'
              >
                {t('common__cancel')}
              </div>
              <input
                type='submit'
                value='Add Store'
                className='submit-public'
                onClick={this.handleSubmit}
                translate=''
                key='save'
                disabled={this.state.isPending ? 'disabled' : ''}
              />
            </div>
          </>
        }
      </div >
    )
  }
}

export default compose(
  withTranslation()
)(AddStoreComponent);
