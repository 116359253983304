import { CommonConstants } from '../../Constants';
const {
    deviceType: {
        nexeo,
    },
    subscription: {
        nexeoCustomerOnly: nexeoCustomerOnlySubscriptionID
    }
} = CommonConstants;

const filterSubscriptionAllowedDevices = (devices, subscritptionID) => {
    if (subscritptionID == nexeoCustomerOnlySubscriptionID) {
        return devices.filter(d => d.Device_Name === nexeo.name);
    }
    return devices;
};

export default filterSubscriptionAllowedDevices;
