import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Separator, SEPARATOR_VARIANTS } from 'library/Separator';

import { Certificate } from './Certiicate';
import { Results } from './Results';

import './Header.scss';

const separatorVariants = [SEPARATOR_VARIANTS.TITLE];

export const Header = ({ showDownloads = false, contestName }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-view-edit-contest--results__header'>
            <Title>{t('contest__form__title--edit')}</Title>
            {
                showDownloads &&
                <>
                    <Separator variants={separatorVariants} />
                    <Certificate />
                </>
            }
            {
                showDownloads &&
                <>
                    <Separator variants={separatorVariants} />
                    <Results contestName={contestName} />
                </>
            }
        </div>
    );
};
