import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getProfile } from 'services/Auth';
import { useRebootLinkedDevice } from 'pages/SystemStatus/Common/hooks/useRebootLinkedDevice';
import { useReconnectLinkedDevice } from 'pages/SystemStatus/Common/hooks/useReconnectLinkedDevice';

import { notificationActions, showNotification, getStoreCountryName } from '../../../Common/helpers';
import { useLoadDevice } from '../../../Common/hooks/useLoadDevice';
import { useLoadDeviceScheduledUpgrade } from '../../../Common/hooks/useLoadDeviceScheduledUpgrade';
import { useLoadDeviceSettings } from '../../../Common/hooks/useLoadDeviceSettings';
import { useLoadDeviceUpgradeVersions } from '../../../Common/hooks/useLoadDeviceUpgradeVersions';
import { useLoadCountries } from '../../../Common/hooks/useLoadCountries';
import { useReconnectDevice } from '../../../Common/hooks/useReconnectDevice';
import { useRebootDevice } from '../../../Common/hooks/useRebootDevice';

import { getDeviceStatusInfoFields } from '../helpers';

export const useSystemStatus = () => {
    const profile = getProfile();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const deviceUID = searchParams.get('duid');

    const { countries, isCountriesLoading } = useLoadCountries();
    const { device, isDeviceLoading, deviceLoadingError, loadDevice } = useLoadDevice(deviceUID);

    const {
        deviceSettings,
        isDeviceSettingsLoading,
        deviceSettingsLoadingError,
        loadDeviceSettings
    } = useLoadDeviceSettings(deviceUID);

    const {
        deviceUpgradeVersions,
        isDeviceUpgradeVersionsLoading,
        deviceUpgradeVersionsLoadingError
    } = useLoadDeviceUpgradeVersions(deviceUID);

    const { deviceScheduledUpgrade } = useLoadDeviceScheduledUpgrade(deviceUID);

    const { isDeviceReconnecting, reconnectDevice } = useReconnectDevice(loadDevice, loadDeviceSettings);

    const { isDeviceRebooting, rebootDevice } = useRebootDevice(loadDevice, loadDeviceSettings);

    const { isLinkedDeviceRebooting, rebootLinkedDevice } = useRebootLinkedDevice(loadDevice, loadDeviceSettings);

    const { isLinkedDeviceReconnecting, reconnectLinkedDevice } = useReconnectLinkedDevice(loadDevice, loadDeviceSettings);

    const onDeviceReboot = useCallback(async (deviceToReboot) => {
        const status = await rebootDevice(deviceToReboot.Device_UID);
        showNotification({ action: notificationActions.REBOOT_DEVICE, status, t });
    }, []);

    const onDeviceReconnect = useCallback(async (deviceToReconnect) => {
        const status = await reconnectDevice(deviceToReconnect.Device_UID);
        showNotification({ action: notificationActions.RECONNECT_DEVICE, status, t });
    }, []);

    const onLinkedDeviceReboot = useCallback(async (deviceToReboot, deviceToLoad) => {
        const status = await rebootLinkedDevice(deviceToReboot.Device_UID, deviceToLoad.Device_UID);
        showNotification({ action: notificationActions.REBOOT_DEVICE, status, t });
    }, [t]);

    const onLinkedDeviceReconnect = useCallback(async (deviceToReconnect, deviceToLoad) => {
        const status = await reconnectLinkedDevice(deviceToReconnect.Device_UID, deviceToLoad.Device_UID);
        showNotification({ action: notificationActions.RECONNECT_DEVICE, status, t });
    }, [t]);

    const isDataLoading =
      isDeviceLoading ||
      isDeviceSettingsLoading ||
      isDeviceUpgradeVersionsLoading ||
      isDeviceReconnecting ||
      isDeviceRebooting ||
      isLinkedDeviceRebooting ||
      isLinkedDeviceReconnecting ||
      isCountriesLoading;

    const error =
      deviceLoadingError ||
      deviceUpgradeVersionsLoadingError ||
      deviceSettingsLoadingError;

    const storeCountry = useMemo(() => getStoreCountryName(device, countries), [device, countries]);

    const StatusInfoFields = useMemo(
            () => getDeviceStatusInfoFields({ profile, device, deviceSettings, storeCountry }),
            [profile, device, deviceSettings, storeCountry]
    );

    return {
        device,
        deviceSettings,
        deviceUpgradeVersions,
        deviceScheduledUpgrade,
        isDataLoading,
        error,
        StatusInfoFields,
        onDeviceReboot,
        onDeviceReconnect,
        onLinkedDeviceReboot,
        onLinkedDeviceReconnect
    };
};
