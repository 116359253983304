import React from 'react';
import PropTypes from 'prop-types';

export const DeviceProductIDs = ({ devices }) => (
    <div className="hme-stores-status__list__device-product-id">
        {
            devices.map((device) => (
                <div className="hme-stores-status__list__inner-cell" key={device.Device_ID}>
                    {device.Device_Product_ID || ' '}
                </div>
            ))
        }
    </div>
);

DeviceProductIDs.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object).isRequired
};
