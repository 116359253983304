import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { getProfile, isMasquerade, clearUUID, setUserEmail, getIdToken, saveContextToken } from 'services/Auth';

const MasqueradeHeaderFunction = () => {
    const { i18n } = useTranslation();

    const user = getProfile();
    const isCurrentlyMasquerade = isMasquerade();
    const userName = user.name ?? `${user.User_FirstName} ${user.User_LastName}`;

    const logout = () => {
        /**
         * function to return back to admin portal.
        */
        const { User_UID: publicUserUID, partnerUID, partner } = user;
        const userUID = partner ? partnerUID : publicUserUID;

        i18n.changeLanguage('en-US');
        clearUUID();
        setUserEmail(null);
        window.localStorage.removeItem('user_perm');
        window.localStorage.removeItem('ShowBellInviteIcon');
        window.localStorage.removeItem('isAccountSSO');

        const adminIdToken = getIdToken();
        saveContextToken(adminIdToken);

        // Please don't forget to reset Unread Notifications timeout if that part of code will be rewritten with navigate function
        // In this case you just need to call unreadNotifications.resetUnread() from globalState context after calling navigation function
        window.location.href = partner ? `/admin/partners/${userUID}` : `/admin/settings/users/user?uuid=${userUID}`;
    };

    if (isCurrentlyMasquerade) {
        return (
            <div className="view-as">
                <button type="button" onClick={logout}>
                    <span> X </span>
                </button>
                <span>Currently Viewing As {userName}</span>
            </div>
        );
    }

    return <span />;
};

export const MasqueradeHeader = memo(MasqueradeHeaderFunction);
