import {Config} from '../Config';
import axios from 'axios';
import AuthenticationService from '../components/Security/AuthenticationService';
const authService = new AuthenticationService(Config.authBaseUrl);

const base = 'manageSetting';

const axiosConfig = {
    baseURL: `${Config.apiBaseUrl}api/`,
    headers: {
        'Content-Type': 'application/json'
    }
};

const axiosInstance = axios.create(axiosConfig);

const api = {
    request(options) {
        options.headers = Object.assign(options.headers || {}, {
            Authorization: `Bearer ${authService.getToken()}`
        });
        return axiosInstance(options)
                .then((res) => res.data);
    }
};

export const settingApi = {
    get() {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/getDeviceSetting`,
            method: 'GET'
        });
    },
    update(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/saveSetting`,
            method: 'POST',
            data
        });
    },
    saveDistributorSetting(data) {
        return api.request({
            url: `${base}/saveDistributorSetting`,
            method: 'POST',
            data
        });
    },
    savePublicSetting(data) {
        return api.request({
            url: `${base}/savePublicSetting`,
            method: 'POST',
            data
        });
    },
    findSnapshots(data) {
        return api.request({
            url: `${base}/findSnapshots`,
            method: 'POST',
            data
        });
    },
    validateAddSnapshot(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/validateAddSnapshot`,
            method: 'POST',
            data
        });
    },
    validateApplySnapshot(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/validateApplySnapshot`,
            method: 'POST',
            data
        });
    },
    deletePublicSnapshot(id) {
        // TODO: Change error hannnddler after BE will throw them
        return api.request({
            url: `${base}/deletePublicSetting/${id}`,
            method: 'DELETE'
        }).catch((err) => {
            const error = err.response ? new Error(err.response.data.message) : new Error('Unexpected error. Try again later');

            return Promise.reject(error);
        });
    },
    deleteDistributorSnapshot(id) {
        return api.request({
            url: `${base}/deleteDistributorSetting/${id}`,
            method: 'DELETE'
        }).catch((err) => {
            const error = err.response ? new Error(err.response.data.message) : new Error('Unexpected error. Try again later');

            return Promise.reject(error);
        });
    },
    deleteAdminSnapshot(id) {
        return api.request({
            url: `${base}/deleteSetting/${id}`,
            method: 'DELETE'
        }).catch((err) => {
            const error = err.response ? new Error(err.response.data.message) : new Error('Unexpected error. Try again later');

            return Promise.reject(error);
        });
    },
    getDeviceSettingsName(query) {
        return api.request({
            url: `${base}/getDeviceSettingsName?${query}`,
            method: 'GET'
        });
    },
    findDevices(query) {
        return api.request({
            url: `${base}/findDevices?${query}`,
            method: 'GET'
        });
    },
    // Get list of unique settings to use as filters
    // If deviceType is not provided, all filter settings will be returned
    getFilters(deviceType) {
        return api.request({
            url: `${base}/getFilters?filterByDeviceType=${deviceType}`,
            method: 'GET'
        });
    },
    apply(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/applySetting`,
            method: 'POST',
            timeout: 0,
            data
        });
    },
    getAccountStores(data) {
        // TODO: shold be deprecated when renderApplySettings.js getStores method is replaced with: await settingApi.getAccountStoresByUserUID({ userUID })
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/getAccountStores/${data.account}`,
            method: 'GET'
        });
    },
    getAccountStoresByUserUID(data) {
        // TODO: Add error hannnddler after BE will throw them
        const {
            userUID,
            selectedDeviceType,
            isDeviceUpgrade
        } = data || {};
        return api.request({
            url: `${base}/getAccountStoresByUserUID/${userUID}`,
            method: 'GET',
            params: {
                selectedDeviceType, isDeviceUpgrade
            }
        });
    },
    getDeviceTasks(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/getDeviceTasks/${data.serialNumber}`,
            method: 'GET'
        });
    },
    getServerTZOffset() {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/serverTZOffset`,
            method: 'GET'
        });
    },
    getDeviceInfo(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/getDeviceInfo/${data.serialNumber}`,
            method: 'GET'
        });
    },
    applyToMany(data) {
        // TODO: Add error hannnddler after BE will throw them
        return api.request({
            url: `${base}/applySettingMultipleDevices`,
            method: 'POST',
            timeout: 0,
            data
        });
    },
    //@todo DEPRECATED to getTasks
    getAccountTasks() {
        return api.request({
            url: `tasks/getAccountTasks`,
            method: 'GET'
        });
    },
    getTasks(filters) {
        return api.request({
            url: `tasks/getTasks`,
            method: 'GET',
            params: filters,
        });
    },
};
