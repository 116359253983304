import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';
import { Title } from 'library/Title';

import './Header.scss';
import { GridFiltersCount } from 'library/GridFiltersCount';

export const Header = ({filtersCount, onFiltersReset}) => {
    const { t } = useTranslation();
    const url = `/admin/createVaioProvider`;

    return (
        <div className="vaio-provider-page__header">
            <Title>{t('common__providers')}</Title>
            <div className="vaio-provider-page__filters-count">
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>

            <Link
                type="button"
                to={url}
                className="vaio-provider-page__header__create-provider"
            >
                <Button>{t('vaio_button-create-new-provider')}</Button>
            </Link>
        </div>
    );
};

Header.propTypes = {
    filtersCount: PropTypes.number,
    onFiltersReset: PropTypes.func
};
