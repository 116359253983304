import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { getDataAsync, getSearchString } from 'Api';

const filtersRequestMap = {
    Brand_Name: 'storeBrandIds',
    LaneConfig_Name: 'deviceLaneConfigIds',
    Device_Type: 'deviceTypes',
    Status: 'statuses',
};

const buildSystemsUrlParams = ({
    sortColumn = null,
    sortingType = null,
    itemsPerPage,
    pageNumber,
    searchField,
    search,
    appliedFilters,
    withPagination = true,
    template,
}) => {
    const filters = withPagination
        ? {
              perpage: itemsPerPage ?? 10,
              pagenumber: pageNumber ?? 1,
          }
        : {
              perpage: null,
              pagenumber: 1,
          };

    if (search) {
        filters.criteria = search;
        filters.filter = searchField;
    }

    switch (sortColumn) {
        case 'status':
            filters.sortcolumn = 'Device_IsActive';
            break;
        default:
            filters.sortcolumn = sortColumn;
            break;
    }
    if (sortColumn && sortingType == 1) {
        filters.sorttype = 'ASC';
    } else if (sortColumn && sortingType == -1) {
        filters.sorttype = 'DESC';
    } else if (sortColumn) {
        filters.sorttype = '';
    }

    // map filter fields with request payload fields
    const filterQuery = Object.keys(appliedFilters).reduce((acc, key) => {
        const appliedFilterField = filtersRequestMap[key] || key;

        return {
            ...acc,
            [appliedFilterField]: appliedFilters[key]
        }
    }, {});

    return getSearchString({
        ...filters,
        ...filterQuery,
        template,
        allowUnlinked: 1,
        allowPreconfigured: 1
    });
}

export const getSystems = async (params) => {
    const urlParams = buildSystemsUrlParams(params);

    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAdminDevices}`;
    const response = await getDataAsync(`${url}?${urlParams}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const sendAdminDevicesReport = async (params) => {
    const urlParams = buildSystemsUrlParams({
        ...params,
        withPagination: false
    });
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.sendAdminDevicesReport}`;
    const response = await getDataAsync(`${url}?${urlParams}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
}
 