import { createContext } from 'react';

const empty = () => {};

export const HierarchyItemContext = createContext({
    selectedItems: [],
    searchString: '',
    onItemSelect: empty,
    onItemAdd: empty,
    onItemAddCancel: empty,
    onItemEdit: empty,
    onItemEditCancel: empty,
});