import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ onCancel, onSave, isSaveBtnDisabled }) => {
    const { t } = useTranslation();
    return <div className="hme-view-edit-store__footer">
        <Button
            onClick={onCancel}
            className="hme-view-edit-store__footer__button"
        >
            {t('common__cancel')}
        </Button>
        <Button
            onClick={onSave}
            variants={['submit']}
            disabled={isSaveBtnDisabled}
            className="hme-view-edit-store__footer__button"
        >
            {t('common__save')}
        </Button>
    </div>;
};

Footer.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isSaveBtnDisabled: PropTypes.bool.isRequired
};
