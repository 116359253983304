import { findDepth } from 'helpers/tree/findDepth';
import { getMaxDepth } from './maxDepth';

const MAXIMUM_DEPTH_TO_CREATE_GROUP = 3;

export const checkMoveAllowed = (tree, parent, groups) => {
    if (!parent) {
        return true;
    }
    const parentDepth = findDepth(tree, parent);
    const acceptableDepth = MAXIMUM_DEPTH_TO_CREATE_GROUP - parentDepth + 1;

    const maxChildDepth = getMaxDepth(groups);
    return maxChildDepth <= acceptableDepth;
};
