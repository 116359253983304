import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { getDataAsync, postDataAsync, putDataAsync } from 'Api';

export const getSSODetails = async (accountID) => {
    const searchQuery = `accountID=${accountID}`;
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getSSOConfigDetails}?${searchQuery}`;

    const { status, data = [] } = await getDataAsync(url);

    if (!status) {
        throw new Error('Request failed');
    }

    return data;
};

export const updateSSOInfo = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.editSSOConfigDetails}`;

    const { status, data = [] } = await putDataAsync(url, payload);

    if (!status) {
        throw new Error('Request failed');
    }

    return data;
};

export const logSSOAudit = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.logSSOAudit}`;

    const { status, data = [] } = await postDataAsync(url, payload);

    if (!status) {
        console.error('Failed to save SSO audit log');
    }

    return data;
}