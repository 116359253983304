import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

import { CommonConstants } from '../../Constants';
const { deviceType } = CommonConstants;
const { zoomNitro } = deviceType;
const deviceTypesArr = Object.values(deviceType);
const semver = require('semver');

/**
 * @example getDeviceByName('ZOOM Nitro/EVD') => ({
    id: 14,
    name: 'ZOOM Nitro/EVD',
    displayName: 'ZOOM Nitro/NVD',
    hardwareName: 'NVD VPU'
  })
 */
export const getDeviceByName = (deviceName) => {
  return Object.values(deviceType)
    .find((device) => device.name === deviceName) || {};
};

export const getDeviceTypeByName = (name) => {
  const foundDevice = Object.keys(deviceType)
    .map((key) => deviceType[key])
    .find((info) => info.name === name);

  if (!foundDevice) {
    return 0;
  }

  return foundDevice.id;
};

export default (device = {}) => {
  let {
    Device_DeviceType_ID: deviceTypeID,
    Device_MainVersion: deviceMainVersion = '',
  } = device;

  if (deviceMainVersion === null || !BuildVersions.formatVersion(deviceMainVersion)) {
    deviceMainVersion = '';
  }
  const parsedVersion = +deviceMainVersion.split('.').slice(0,2).join('.');
  const isZoomNitro = deviceTypeID === zoomNitro.id && BuildVersions.semverGte(parsedVersion, zoomNitro.minMainVersion);
  if (isZoomNitro) { return zoomNitro; }
  const [resultDevice = {}] = deviceTypesArr.filter(el => el.id === deviceTypeID);
  return resultDevice;
};
