import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Section } from 'library/Section';
import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';
import { Grid } from 'components/Common/Grid';
import { DeviceDropdown } from '../DeviceDropdown';
import getDeviceType from 'helpers/Device/getDeviceType'
import { ModelName } from '../../../Common/ModelName';
import { gridOptions } from './constants';

import './DevicesSection.scss';

const prepareDevices = ({ device }) => {
    const deviceType = getDeviceType(device);

    const actionsComponent = '';

    const preparedDevice = {
        modelName: <ModelName device={device} modelName={deviceType?.hardwareName} />,
        serialNumber: device.Device_SerialNumber,
        macAddress: device.Device_MacAddress,
        mainVersion: device.Device_MainVersion,
        latestFirmwareAvailable: '',
        actionsComponent,
    };

    return [preparedDevice];
};

export const DevicesSection = ({ device }) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const preparedDevices = prepareDevices({
            device,
            t,
        });
        setRows(preparedDevices);
    }, [device, setRows]);

    return (
        <Section className="hme-admin-evd-system-status-devices">
            <div className="hme-admin-evd-system-status-devices-headline">
                <Title>{t('system-status__title__system-devices')}</Title>
            </div>
            <Grid
                rows={rows}
                rowKey="serialNumber"
                isLoading={false}
                headers={gridOptions}
                noRecordsMessage={t('system-status__title__system-devices__no-devices--found')}
                expandable={true}
            />
        </Section>
    );
};
