import React from 'react';
import { Section } from 'library/Section';
import { TextareaInput } from 'library/TextareaInput';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { Title } from 'library/Title';
import { ReactComponent as ClipboardIcon } from 'assets/icons/clipboard.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { SSOConfigLabels, HME_SETTINGS } from '../../helpers/constants';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import './HMECompanySettings.scss';

export const HMECompanySettings = () => {
    const { t } = useTranslation();
    const assertionUrl = HME_SETTINGS.assertionUrl;
    const entityID = HME_SETTINGS.entityID;
    const [{ text: copiedAssertionValue }, setCopyAssertion] = useCopyToClipboard();
    const [{ text: copiedEntityValue }, setCopyEntity] = useCopyToClipboard();

    const handleCopyToken = async (token, type) => {
        try {
            switch (type) {
                case 'Assertion':
                    await setCopyAssertion(token);
                    break;
                case 'Entity':
                    await setCopyEntity(token);
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error('There was an error copying the token', err);
        }
    };

    return (
        <Section className="hme-section-sm">
            <Title>{t('settings_sso_configuration_hme_settings')}</Title>
            <div className="sso-form-input hme-settings">
                <Label>{SSOConfigLabels['ASSERTION_URL']}</Label>
                <div
                    className="copy-btn"
                    onClick={() => handleCopyToken(assertionUrl, 'Assertion')}
                >
                    {
                        !copiedAssertionValue ?
                        <ClipboardIcon className="hme-api-management-copy-token-modal__clipboard-icon" /> :
                        <CheckIcon className="hme-api-management-copy-token-modal__clipboard-icon" />
                    }
                </div>
                <TextareaInput
                    value={assertionUrl}
                    id="assertionUrl"
                    isDisabled={true}
                />
            </div>

            <div className="sso-form-input hme-settings">
                <Label>{SSOConfigLabels['ENTITY_ID']}</Label>
                <div
                    className="copy-btn"
                    onClick={() => handleCopyToken(entityID, 'Entity')}
                >
                    {
                        !copiedEntityValue ?
                        <ClipboardIcon className="hme-api-management-copy-token-modal__clipboard-icon" /> :
                        <CheckIcon className="hme-api-management-copy-token-modal__clipboard-icon" />
                    }
                </div>
                <TextareaInput
                    value={entityID}
                    id="entityID"
                    isDisabled={true}
                />
            </div>
        </Section>
    );
};
