/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Label } from 'library/Label';
import { Section } from 'library/Section';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent } from 'components/Inputs';
import { Toggle } from 'library/Toggle';
import { usePartnerEmails } from 'library/PartnerEmailDropdown/hooks/usePartnerEmails';
import { PartnerEmailDropdown } from 'library/PartnerEmailDropdown/PartnerEmailDropdown';

import {
    DEFAULT_TOKEN_MANAGER,
    FORM_DEFAULTS,
    TOKEN_USER_TYPES,
    VAIO_DEFAULTS,
    tokenUsers
} from '../helpers/constants';

import './EmailSection.scss';
import { isApiTypeDXS, isPartnerDXSType, partnerHasDXSAssociated } from '../helpers';
import { addErrorNotification } from 'services/Notifications';
import { isApiTypeVAIO, isPartnerVAIOType } from '../helpers/isPartnerVAIO';

export const EmailSection = ({ formValues, setFormValues, onChange, formErrors }) => {
    const { t } = useTranslation();
    const [tokenUser, setTokenUser] = useState(formValues.tokenUser);
    const { apiType, email2, email3 } = formValues;

    const {
        email: partnerEmail,
        matchedEmails: matchedPartnerEmails,
        handleEmailChange: fetchPartnerEmails,
        setEmail: setPartnerEmail,
        isLoading: isPartnerLoading
    } = usePartnerEmails(FORM_DEFAULTS[apiType].tokenManager.email);

    const handleTokenManagerSelect = useCallback((newTokenManager = {}) => {
        const { email = '', partnerCategoryIds = [], tokens = [] } = newTokenManager;
        if (isApiTypeDXS(apiType)) {
            if (!isPartnerDXSType(partnerCategoryIds)) {
                addErrorNotification(
                        'api-token-management__error--dxs-unsupported-partner', { autoClose: 5000 }
                );
                return;
            }
            if (partnerHasDXSAssociated(tokens)) {
                addErrorNotification(
                        'api-token-management__error--dxs-partner-already-associated', { autoClose: 5000 }
                );
                return;
            }
        } else if (isApiTypeVAIO(apiType)) {
            if (!isPartnerVAIOType(partnerCategoryIds)) {
                addErrorNotification(
                        'partner__form__error--not-allowed-partner-type', { autoClose: 5000 }
                );
                return;
            }
        }
        setPartnerEmail(email);
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            tokenManager: newTokenManager
        }));
    }, [apiType]);

    const handlePartnerEmailChange = useCallback(async (newEmail) => {
        try {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                tokenManager: { ...DEFAULT_TOKEN_MANAGER, email: newEmail },
                vaioProvider: VAIO_DEFAULTS.vaioProvider
            }));
            await fetchPartnerEmails(newEmail);
        } catch (_err) {
            // do nothing when request is programmatically canceled
        }
    }, [apiType]);

    useEffect(() => {
        setFormValues({
            ...formValues,
            tokenUser
        });
    }, [tokenUser]);

    useEffect(() => {
        setFormValues(({ tokenName = '' } = {}) => ({
            ...FORM_DEFAULTS[apiType],
            tokenName
        }));
        setPartnerEmail(FORM_DEFAULTS[apiType].tokenManager.email);
    }, [apiType]);

    return (
        <Section
            className="api-management-form-email"
            number={2}
            col={3}
            showNumber={false}
            title={t('api-token-management__section__title--email')}
        >
            <div className="vaio-provider-form-input-first">
                <Label className="vaio-provider-form-label">{t('api-token-management__form--token-user')}</Label>
                <Toggle value={tokenUsers.partner} onChange={setTokenUser} disabled>
                    {TOKEN_USER_TYPES}
                </Toggle>
            </div>
            <div className="vaio-provider-form-input">
                <PartnerEmailDropdown
                    email={partnerEmail}
                    partnerEmails={matchedPartnerEmails}
                    onSelect={handleTokenManagerSelect}
                    onChange={handlePartnerEmailChange}
                    isLoading={isPartnerLoading}
                />
                {formErrors.tokenManager && <ErrorLabel>{t(formErrors.tokenManager)}</ErrorLabel>}
            </div>
            <div className="vaio-provider-form-input">
                <InputComponent
                    label={t('api-token-management__form--email2')}
                    name="email2"
                    value={email2}
                    onChange={onChange}
                    maxLength={50}
                    universalOnChange={true}
                />
                {formErrors.email2 && <ErrorLabel>{t(formErrors.email2)}</ErrorLabel>}
            </div>
            <div className="vaio-provider-form-input">
                <InputComponent
                    label={t('api-token-management__form--email3')}
                    name="email3"
                    value={email3}
                    onChange={onChange}
                    maxLength={50}
                    universalOnChange={true}
                />
                {formErrors.email3 && <ErrorLabel>{t(formErrors.email3)}</ErrorLabel>}
            </div>
        </Section>
    );
};

EmailSection.propTypes = {
    formValues: PropTypes.object.isRequired,
    setFormValues: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired
};
