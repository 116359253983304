import { Constant } from '@hme-cloud/utility-common';
import { NEXEO_BASE_STATION, NEXEO_PERIPHERAL_TYPES_IDKEY } from 'constants/NEXEOPeripheralTypes';
import { nexeoRestrictions } from 'constants/upgradeRestrictions';
import { nexeoTranslationKeysMap } from '../../../../Common/constants';
import { DeviceDropdown } from '../../DeviceDropdown';
import { DeviceUpgradeStatus } from '../../DeviceUpgradeStatus';
import { getUpgradeStatusCell } from '../statuses';
import { mergeSpeakerDevices, checkIsScheduledUpgrade, checkIsUpgradeVersionOptionsDisabled } from '../helpers';
import { Tip } from 'library/Tip';
import { PeripheralActiveStatus } from '../PeripheralActiveStatus';

const { BuildVersions } = Constant;

const filterUpgradeVersions = (deviceType, device) => {
    const mainDeviceVersions = (device.devices || []).map((d) => d.mainVersion);

    // check if main version is less than minimum version for tier upgrade
    if (
        deviceType === NEXEO_BASE_STATION.modelName &&
        mainDeviceVersions.some((version) => BuildVersions.semverLt(version, nexeoRestrictions.minTierUpgradeVersion))
    ) {
        return device.upgradeVersions.filter((v) => BuildVersions.semverLt(v, nexeoRestrictions.maxNonTierVersion));
    }

    return device.upgradeVersions;
};

export const preparePeripheralDevices = ({
    peripheralDevices,
    device,
    onDeviceVersionUpgrade,
    deviceScheduledUpgrade,
    onScheduledUpgradeChanged,
    t,
}) => {
    const preparedPeripheralDevices = {};
    if (typeof peripheralDevices !== 'object') {
        return [];
    }

    const isBaseStationActive = peripheralDevices[NEXEO_BASE_STATION.modelName]?.devices[0]?.isActive;

    Object.keys(peripheralDevices).forEach((deviceType) => {
        let peripheralDevice = peripheralDevices[deviceType];

        const { modelNameLangKey = '' } = NEXEO_PERIPHERAL_TYPES_IDKEY[peripheralDevice.deviceTypeID] || {};
        const filteredUpgradeVersions = filterUpgradeVersions(deviceType, peripheralDevice);

        const upgradeVersionOptions = (filteredUpgradeVersions || []).map((version) => ({
            name: `${t(modelNameLangKey)} v${version}`,
            deviceType,
            version,
            deviceUID: device.Device_UID,
        }));

        peripheralDevice.devices = peripheralDevice.devices.map((d) => ({
            ...d,
            modelName: peripheralDevice.modelName,
            upgradeTasks: peripheralDevice.upgradeTasks,
            maxUpgradeVersion: peripheralDevice.maxUpgradeVersion,
        }));

        const { currentUpgradeTask, lastUpgradeTask } = peripheralDevice.upgradeTasks || {};
        const getUpgradeStatusFor = currentUpgradeTask || lastUpgradeTask ? (currentUpgradeTask || lastUpgradeTask) : '';

        // ovveride preconfigured device with empty values
        if (device.Device_IsPreconfigured) {
            peripheralDevice.devices.forEach((d) => {
                d.macAddress = '';
                d.mainVersion = '';
                d.serialNumber = '';
                d.lane = '';
            });
        }

        peripheralDevice.device = {};

        const isBaseStation =
            peripheralDevices[NEXEO_BASE_STATION.modelName]?.deviceTypeID === peripheralDevice.deviceTypeID;

        if (!peripheralDevice.isMultiple) {
            peripheralDevice.device = peripheralDevice.devices[0];
            Object.keys(peripheralDevice.device).forEach((d) => {
                peripheralDevice[`device_${d}`] = peripheralDevice.device[d];
            });

            const isOnLatestVersion =
                !peripheralDevice.maxUpgradeVersion ||
                (peripheralDevice.minMainVersion &&
                    BuildVersions.semverGte(peripheralDevice.minMainVersion, peripheralDevice.maxUpgradeVersion));
            const deviceSerialNum = peripheralDevice.device.serialNumber

            peripheralDevice.onLatestVersion =
                !device.Device_IsPreconfigured && isOnLatestVersion ? t('common__yes') : t('common__no');

            peripheralDevice.latestFirmwareAvailable = peripheralDevice.maxUpgradeVersion;
            peripheralDevice.currentUpgrade = t('common__none');
            peripheralDevice = {
                ...peripheralDevice,
                lastUpgradeData: lastUpgradeTask ? (
                    <DeviceUpgradeStatus title="admin-system-status__upgrade-title--latest" task={lastUpgradeTask} serialNumber={peripheralDevice.device.serialNumber} />
                ) : null,
                upgradeStatus:
                    getUpgradeStatusFor
                        ? getUpgradeStatusCell(getUpgradeStatusFor,deviceSerialNum)
                        : '',
                currentUpgrade: currentUpgradeTask ? (
                    <DeviceUpgradeStatus
                        title="admin-system-status__upgrade-title--current"
                        task={currentUpgradeTask}
                        serialNumber={peripheralDevice.device.serialNumber}
                    />
                ) : null,
            };
        }

        const currentUpgradeTaskStatus = currentUpgradeTask ? currentUpgradeTask.status : null;

        const isScheduledUpgrade = checkIsScheduledUpgrade({ deviceScheduledUpgrade, currentUpgradeTaskStatus });

        if (peripheralDevice.deviceTypeID === device.Device_DeviceType_ID) {
            onScheduledUpgradeChanged(isScheduledUpgrade);
        }

        const isUpgradeVersionOptionsDisabled = checkIsUpgradeVersionOptionsDisabled({
            device,
            peripheralDevice,
            upgradeVersionOptions,
            isScheduledUpgrade,
            currentUpgradeTaskStatus,
        });
        const modelNameText = t(nexeoTranslationKeysMap[peripheralDevice.modelName] || peripheralDevice.modelName);

        // here we prepare data for grid component and merge it with device data from above
        preparedPeripheralDevices[deviceType] = {
            ...peripheralDevice,
            modelName: !peripheralDevice.isMultiple
                ? <div>
                    <PeripheralActiveStatus
                        isBaseStationActive={isBaseStationActive}
                        device={peripheralDevice.device}
                    />{modelNameText}
                  </div>
                : modelNameText,
            productId: isBaseStation ? device.Device_Product_ID : '',
            serialNumber: peripheralDevice.device.macAddress ? (
                <span className='hme-grid-serial-number'>
                  {peripheralDevice.device.serialNumber}
                  <Tip
                    className='hme-admin-system-status-tooltip__tip'
                    withCloseButton
                    title={t('system-status__mac-address').toUpperCase()}>
                    {peripheralDevice.device.macAddress}
                  </Tip>
                </span>
              ) : peripheralDevice.device.serialNumber,
            expandHidden: !peripheralDevice.isMultiple,
            actionsComponent: (
                <DeviceDropdown
                    onChange={(value) => onDeviceVersionUpgrade(value)}
                    placeholder="admin-system-status__action__send-upgrade"
                    options={upgradeVersionOptions}
                    isDisabled={isUpgradeVersionOptionsDisabled}
                />
            ),
            isBaseStationActive,
        };
    });

    // merge IB7000 and SM7000 devices
    const preparedPeripheralDevicesWithMergedSpeakerDevices = mergeSpeakerDevices({
        preparedPeripheralDevices,
        device,
        deviceScheduledUpgrade,
        onDeviceVersionUpgrade,
        t,
    });

    return Object.values(preparedPeripheralDevicesWithMergedSpeakerDevices);
};
