import { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getQueryString } from 'helpers/distributor';
import { getDistributorUsers } from 'services/Users';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { sortUsers } from '../../Common/helpers/sortUsers';
import { usersToRows } from '../helpers/usersToRows';
import { headers } from '../helpers/headers';
import { removeDistributorUser } from 'services/DistributorAccounts';
import { INITIAL_SORT_SELECTION } from '../../../../constants/initialSortSelection';

const hasCreateUserAccess = true;

const loadUsers = async (auuid, setIsLoading, setUsers) => {
    setIsLoading(true);

    try {
        const { usersList } = await getDistributorUsers({ auuid });
        setUsers(usersList);
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

const deleteUser = async (params, setIsLoading, setUsers) => {
    setIsLoading(true);
    try {
        await removeDistributorUser(params);
        addSuccessNotification('common__success--user-deleted');
        const { auuid } = params;
        const { usersList } = await getDistributorUsers({ auuid });
        setUsers(usersList);
    } catch (error) {
        addErrorNotification('common__error--internal-server');
    } finally {
        setIsLoading(false);
    }
};

export const useDistributorUsers = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [originRows, setOriginRows] = useState([]);
    const [rows, setRows] = useState([]);
    const { search } = useLocation();
    const [queryParams] = useSearchParams();
    const [sortSelection, setSortSelection] = useState(INITIAL_SORT_SELECTION);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [uuId, setUuid] = useState('');

    const queryString = useMemo(() => getQueryString(search), [search]);

    const onConfirmModalOpen = useCallback((userID) => {
        setUuid(userID);
        setConfirmModalOpen(true);
    }, [setUuid, setConfirmModalOpen]);

    const addUserUrl = useMemo(
        () => `/accounts/users/add-user${queryString}`,
        [queryString]
    );

    const onSortChange = useCallback((newSorting) => {
        if (isLoading) {
            return;
        }

        setSortSelection(newSorting);
    }, [isLoading, setSortSelection]);

    useEffect(() => {
        loadUsers(queryParams.get('auuid'), setIsLoading, setUsers);
    }, [queryParams, setIsLoading, setUsers]);

    useEffect(() => {
        const newRows = usersToRows(users, queryString, onConfirmModalOpen, hasCreateUserAccess);
        setOriginRows(newRows);
    }, [queryString, users, onConfirmModalOpen, setOriginRows]);

    useEffect(() => {
        const sortedUsers = sortUsers(originRows, sortSelection);

        setRows(sortedUsers);
    }, [originRows, sortSelection, setRows]);

    const onCancelRemoveUser = useCallback(() => {
        setUuid('');
        setConfirmModalOpen(false);
    }, [setConfirmModalOpen, setUuid]);

    const onConfirmRemoveUser = useCallback(() => {
        const auuid = queryParams.get('auuid');
        deleteUser({ auuid, uuId }, setIsLoading, setUsers);
        setConfirmModalOpen(false);
    }, [uuId, setConfirmModalOpen, queryParams, setIsLoading, setUsers]);

    const onFiltersAndSortReset = useCallback(() => setSortSelection(INITIAL_SORT_SELECTION), [setSortSelection]);

    return {
        addUserUrl,
        hasCreateUserAccess,
        isLoading,
        headers,
        rows,
        sortSelection,
        onSortChange,
        isConfirmModalOpen,
        onCancelRemoveUser,
        onConfirmRemoveUser,
        onFiltersAndSortReset
    };
};
