export const accountsSuggestionsHeaders = [
    {
        text: 'common__email',
        property: 'User_EmailAddress',
    },
];

export const storesSuggestionsHeaders = [
    {
        text: 'common__device-serial-number',
        property: 'Device_SerialNumber',
    },
    {
        text: 'common__store__number--text',
        property: 'Store_Number',
    },
];

export const devicesSuggestionsHeaders = [
    {
        text: 'common__store__number--text',
        property: 'storeNumber',
        flex: 0.6,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        flex: 0.8,
        text: 'common__store__name',
        property: 'storeName',
        className: 'hme-grid-cell-show-all-content',
    },
];

export const nexeoDevicesSuggestionsHeaders = [
    {
        text: 'common__device__serial-number',
        property: 'serialNumber',
        flex: 1,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__product-id',
        property: 'productId',
        flex: 1,
        className: 'hme-grid-cell-show-all-content',
    },
    ...devicesSuggestionsHeaders,
];

export const zoomNitroDevicesSuggestionsHeaders = [
    {
        text: 'common__device__serial-number',
        property: 'serialNumber',
        flex: 1,
        className: 'hme-grid-cell-show-all-content',
    },
    ...devicesSuggestionsHeaders,
];

export const mobileNexeoDevicesSuggestionsHeaders = [
    {
        column: [
            { text: 'common__device__serial-number', property: 'serialNumber' },
            { text: 'common__store__number--text', property: 'storeNumber' },
        ],
    },
    {
        column: [
            { text: 'common__device__product-id', property: 'productId' },
            { text: 'common__store__name', property: 'storeName' },
        ],
    },
];

export const mobileZoomNitroDevicesSuggestionsHeaders = [
    {
        column: [
            { text: 'common__device__serial-number', property: 'serialNumber' },
            { text: 'common__store__number--text', property: 'storeNumber' },
        ],
    },
    {
        column: [{ text: 'common__store__name', property: 'storeName' }],
    },
];
