import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useClickOutSide = (ref, handler) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = event => {
            let shouldHandle = true;

            // handle the case when outside click should be prevented
            // while clicking on multiple elements
            if (Array.isArray(ref)) {
                ref.forEach(r => {
                    shouldHandle = r.current && !r.current.contains(event.target) && shouldHandle;
                })
            } else {
                shouldHandle = ref.current && !ref.current.contains(event.target) && shouldHandle;
            }

            if (shouldHandle) {
                handler(event);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, handler]);
};
