export const NEXEO_BASE_STATION = {
    modelName: 'BS7000',
    modelNameLangKey: 'admin-system-status__device-model-name--bs7000',
    deviceTypeID: 5,
    isMultiple: false,
};

export const NEXEO_PERIPHERAL_TYPES = {
    'HS7000': {
        modelName: 'HS7000',
        modelNameLangKey: 'admin-system-status__device-model-name--hs7000',
        deviceTypeID: 7,
        isMultiple: true,
    },
    'RT7000': {
        modelName: 'RT7000',
        modelNameLangKey: 'admin-system-status__device-model-name--rt7000',
        deviceTypeID: 8,
        isMultiple: true,
    },
    'AC70': {
        modelName: 'AC70',
        modelNameLangKey: 'admin-system-status__device-model-name--ac70',
        deviceTypeID: 9,
        isMultiple: true,
    },
    'SM7000': {
        modelName: 'SM7000',
        modelNameLangKey: 'admin-system-status__device-model-name--sm7000',
        deviceTypeID: 10,
        isMultiple: true,
    },
    'IB7000': {
        modelName: 'IB7000',
        modelNameLangKey: 'admin-system-status__device-model-name--sm7000', // same as SM7000 due to merged devices
        deviceTypeID: 11,
        isMultiple: true,
    },
    'VOICEENGINE': {
        modelName: 'VOICEENGINE',
        modelNameLangKey: 'admin-system-status__device-model-name--voiceengine',
        deviceTypeID: 12,
        isMultiple: true,
    },
};

export const NEXEO_PERIPHERAL_TYPES_IDKEY = Object
    .values(Object.assign(
        {}, 
        { [NEXEO_BASE_STATION.modelName]: NEXEO_BASE_STATION }, 
        NEXEO_PERIPHERAL_TYPES
    ))
    .reduce((acc, val) => {
        acc[val.deviceTypeID] = val;
        return acc;
    }, {});
