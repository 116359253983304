import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';

const getGroupStoresObj = (tree, result = {}) => {
    tree.forEach((item) => {
        if (item.Type !== storeHierarchyNodeTypes.GROUP) {
            return;
        }

        result[item.Id] = [];

        if (!item.Children || !item.Children.length) {
            return;
        }

        getGroupStoresObj(item.Children, result);

        item.Children.forEach((child) => {
            switch (child.Type) {
                case storeHierarchyNodeTypes.STORE:
                    result[item.Id].push(child.StoreUid);

                    break;
                
                case storeHierarchyNodeTypes.GROUP:
                    result[item.Id].push(...result[child.Id]);

                    break;
            }
        });
    });

    return result;
};

export const getGroupStores = (tree) => {
    const groupStores = getGroupStoresObj(tree);

    const filledKeys = Object.keys(groupStores).filter((key) => !!groupStores[key].length);

    return filledKeys.reduce((val, key) => {
        val[key] = groupStores[key];

        return val;
    }, {});
}