import React from 'react';

import { DistributorUsers } from 'pages/Users/List/DistributorUsers';

export const UsersList = ({
    addUserRedirectionURL,
    editUserRedirectionURL,
    accountUuid
}) => {
    return (
        <>
            <DistributorUsers
                addUserRedirectionURL={addUserRedirectionURL}
                editUserRedirectionURL={editUserRedirectionURL}
                accountUuid={accountUuid}
            />
        </>
    );
};
