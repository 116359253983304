import { useCallback } from 'react';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { create } from 'services/Partner';

export const useSavePartner = () => {
    const {
        run: savePartner,
        isLoading: isSaving = false,
        data: result,
        error: saveError,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED,
    });

    const save = useCallback((partner) => {
        return savePartner(create(partner));
    }, []);

    return { save, isSaving, result, saveError };
};
