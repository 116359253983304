import React from 'react';
import { useTranslation } from 'react-i18next';

import './ApplyFailedStatus.scss';

export const ApplyFailedStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-apply-failed-cell">
            {t('common__task__status--apply-failed')}
        </span>
    );
};
