import { settingApi } from 'api/settingApi';

export const getApplySettingsTaskHistory = async filters => {
    let result = { status: false, data: [] };
    try {
        const requestParams = {};

        if (filters.dateRange) {
            requestParams.createdAtGt = filters.dateRange.startDate.clone()
                .setLocale('en-US')
                .startOfDay()
                .toISOString();

            requestParams.createdAtLte = filters.dateRange.endDate.clone()
                .setLocale('en-US')
                .endOfDay()
                .toISOString();
        }

        result = await settingApi.getTasks(requestParams);
    } catch (error) {
        result = { status: false, message: error.message };
    }
    return result;
};


