import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select } from 'library/Select';
import { Label } from 'library/Label';
import { ReactComponent as WorldIcon } from 'assets/icons/world.svg';
import { DateLib } from '@hme-cloud/utility-common';

import './LanguageSelect.scss';

// When new language is added please remember to update LB Template languages.
export const lngItems = [{
    text: 'English',
    value: 'en-US'
}, {
    text: 'Deutsch',
    value: 'de'
}, {
    text: 'Español',
    value: 'es'
}, {
    text: 'Español (Latinoamérica)',
    value: 'es-LA'
}, {
    text: 'Français',
    value: 'fr'
}, {
    text: 'Français (Canada)',
    value: 'fr-CA'
}, {
    text: 'Italiano',
    value: 'it'
}, {
    text: '日本語',
    value: 'ja'
}, {
    text: 'Polski',
    value: 'pl'
}, {
    text: 'Português (Brasil)',
    value: 'pt-BR'
}, {
    text: '中文（繁體）',
    value: 'zh-TW'
}];

export const LanguageSelect = ({ className }) => {
    const { i18n, t } = useTranslation();

    const onChange = useCallback(async (language) => {
        i18n.changeLanguage(language);
        DateLib.setLocale(language);
    }, [i18n]);

    // TODO: Make it as label inline when Select component will allow that
    return (
        <div className={classNames('hme-language-select', className)}>
            <Label>
                <WorldIcon className="hme-language-select__label__icon"/>
                <span className="hme-language-select__label__text">{t('language-select__label')}</span>
            </Label>
            <Select
                value={i18n.resolvedLanguage}
                label=""
                onChange={onChange}
            >{lngItems}</Select>
        </div>
    );
};
