import { cond, T } from 'ramda';

import { SEARCH_TYPES } from 'pages/ApplyStoreSettings/AdminCreateTask/SearchSection/FiltersForm';
import { Account } from './Account';
import { Brand } from './Brand';

export const SearchParams = cond([
    [({ task }) => task.AdditinionalParams.searchType === SEARCH_TYPES.ACCOUNT, Account],
    [T, Brand]
]);
