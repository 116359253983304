import React from 'react';

import { Title } from 'library/Title';

import './Header.scss';

export const Header = () => (
    <div className="admin-create-apply-store-settings-task-header">
        <Title>Create New Task</Title>
    </div>
);
