import React from 'react';
import { T, cond } from 'ramda';

import { useTranslation } from 'react-i18next';

import './DeviceActiveStatus.scss';

const StatusOnline = ({ device }) => {
    const { t } = useTranslation();

    return (<span className='hme-device-active-status'>
        <a href={`/connect?duid=${device.Device_UID}`}>{t('common__device__status--online')}</a>
        <span className='hme-status-circle hme-status-circle--online'></span>
    </span>);
};

const StatusOffline = () => {
    const { t } = useTranslation();
    
    return (<span className='hme-device-active-status'>
        {t('common__device__status--offline')}
        <span className='hme-status-circle hme-status-circle--offline'></span>
    </span>);
};

export const DeviceActiveStatus = cond([
    [({ device }) => device.Device_IsActive == 1, StatusOnline],
    [T, StatusOffline]
]);
