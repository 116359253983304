import api from './api';

const basePath = 'awards';
const paths = {
    awards: basePath,
    trophycase: `${basePath}/trophycase`,
    historyreport: `${basePath}/historyreport`,
    resetAwards: `${basePath}/reset`,
    awardsResetHistory: `${basePath}/resetHistory`
};

const awardApi = {
    createAward(data) {
        return api.request({
            url: paths.awards,
            method: 'POST',
            data
        });
    },

    getAwards() {
        return api.request({
            url: paths.awards,
            method: 'GET'
        });
    },

    getAward(awardID) {
        return api.request({
            url: `${paths.awards}/${awardID}`,
            method: 'GET'
        });
    },

    updateAward(awardID, data) {
        return api.request({
            url: `${paths.awards}/${awardID}`,
            method: 'PUT',
            data
        });
    },

    updateAwards(data) {
        return api.request({
            url: paths.awards,
            method: 'PUT',
            data
        });
    },

    deleteAward(awardID, masqueradeUser) {
        const params = { AwardID: awardID };
        if (masqueradeUser) params.AdminUser = masqueradeUser;

        return api.request({
            url: paths.awards,
            method: 'DELETE',
            params
        });
    },

    getTropyCase(data) {
        return api.request({
            url: paths.trophycase,
            method: 'POST',
            data
        });
    },

    resetAwards(data) {
        return api.request({
            url: paths.resetAwards,
            method: 'POST',
            data
        });
    },

    awardsResetHistory(params) {
        return api.request({
            url: paths.awardsResetHistory,
            method: 'GET',
            params
        });
    },

    getAwardHistory(params) {
        return api.request({
            url: paths.historyreport,
            method: 'GET',
            params,
        });
    }
};

export default awardApi;
