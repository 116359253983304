import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InputComponent as Input } from 'components/Inputs';
import { Button } from 'library/Button';
import { validEmailRegexp } from 'constants/email';

import './ForgetPasswordForm.scss';

export const ForgetPasswordForm = ({ onReset }) => {
    const [email, setEmail] = useState('');
    const isResetDisabled = useMemo(() => email.trim().match(validEmailRegexp) < 3, [email]);
    const { t } = useTranslation();

    const onSubmit = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        if (isResetDisabled) {
            return;
        }

        onReset && onReset({
            User_EmailAddress: email.trim(),
        });
    }, [isResetDisabled, email, onReset]);

    return (
        <form className='hme-forgot-password__form' onSubmit={onSubmit}>
            <Input
                variants={['label-inline']}
                label={t('common__email-address')}
                name='user_email_address'
                maxLength={100}
                value={email}
                onChange={setEmail}
            />
            <Button
                className='hme-forgot-password__form__login-button'
                variants={['submit']}
                disabled={isResetDisabled}
                type='submit'
            >
                {t('forgot-pass__reset-button')}
            </Button>
        </form>
    );
};
