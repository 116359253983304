import { useState, useEffect, useCallback } from 'react';
import { getDataFeed } from 'services/DataFeed';

export const useLoadFeed = (feedId) => {
    const [feed, setFeed] = useState(null);
    const [isFeedLoading, setIsFeedLoading] = useState(false);
    const [feedLoadingError, setFeedLoadingError] = useState(null);

    const loadFeed = useCallback(async (id) => {
        try {
            setIsFeedLoading(true);
            setFeedLoadingError(null);
            const dataFeed = (await getDataFeed(id)) || [];

            setFeed(dataFeed);
        } catch (e) {
            setFeed(null);
            setFeedLoadingError(e);
        }

        setIsFeedLoading(false);
    }, []);

    useEffect(() => { loadFeed(feedId); }, [feedId]);

    return { feed, isFeedLoading, feedLoadingError, loadFeed };
};
