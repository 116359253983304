import { sendReportsMeasures } from 'api/pbiReports';
import { reportEvents } from 'constants/pbiReports';
import { DateLib } from '@hme-cloud/utility-common';
import _ from 'underscore';

/**
 * Send the power bi event logs
 * @param {string} pbiEvent power bi event name
 * @param {object} errorResponse response object when the pbiEvent is an error
 */
const sendMetrics = ({
    pbiMetricsID, eventType: pbiEvent, type, pbiMetricsEmail: userEmail, eventData = {}, errorResponse = {}
}) => {
    const timeStamp = new DateLib().toUTC();
    const { error } = reportEvents;
    let requestData = {
        user: userEmail,
        pbiEvent: pbiEvent,
        reportType: type,
        timeStamp: timeStamp.format(),
        id: pbiMetricsID,
        eventData
    };
    if (pbiEvent === error && !_.isEmpty(errorResponse)) {
        requestData = {
            ...requestData,
            errorMessage: errorResponse.message,
            errorDetailedMessage: errorResponse.detailedMessage
        };
    }
    sendReportsMeasures(requestData);
};


export { sendMetrics };
