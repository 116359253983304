import React from 'react';
import { cond, T } from 'ramda';

import { CenterLoader } from 'components/Common/CenterLoader';
import { CenterErrorMessage } from 'components/Common/CenterMessages';
import { InfoSection } from './InfoSection';
import { DevicesSection } from './DevicesSection';

import './TaskInfo.scss';

const Info = ({ task, brands, countries, onDownloadLinkClick }) => (
    <div className='hme-view-store-settings-task-info'>
        <InfoSection task={task} brands={brands} countries={countries} />
        <DevicesSection task={task} countries={countries} onDownloadLinkClick={onDownloadLinkClick} />
    </div>
);

export const TaskInfo = cond([
    [({ isLoading }) => isLoading, () => (
        <div className='hme-view-store-settings-task-info'>
            <CenterLoader>Task is loading</CenterLoader>
        </div>
    )],
    [({ error }) => error !== null, () => (
        <div className='hme-view-store-settings-task-info'>
            <CenterErrorMessage>Error occurred while loading task. Please try again later.</CenterErrorMessage>
        </div>
    )],
    [T, Info]
]);
