import { useTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';

import { ContactHME } from 'library/ContactHME';
import { Title } from 'library/Title';
import { withHMELayout } from 'HOCs/withHMELayout';

import './GamificationMarketing.scss';

const subTitleVariants = ['section'];

const GamificationMarketingComponent = ({ hasNoSubscription, title = 'common__leaderboards' }) => {
    const { t } = useTranslation();

    const usPhone = t('gamification-marketing__subscribe__sales--us');
    const internationalPhone = t('gamification-marketing__subscribe__sales--international');

    return (
        <div className='hme-gamification-marketing'>
            <Title className='hme-gamification-marketing__title'>{t(title)}</Title>
            <div className='hme-gamification-marketing__info'>
                <Title variants={subTitleVariants}>{t('gamification-marketing__sub-title--content')}</Title>
                <p className='hme-gamification-marketing__paragraph'>{t('gamification-marketing__administration')}</p>
                <p className='hme-gamification-marketing__paragraph'>
                    { hasNoSubscription ?
                        <Trans
                            i18nKey='gamification-marketing__subscribe'
                        >
                            <span>You currently do not have a Leaderboard subscription. To subscribe,
                                please contact your HME Account Representative or call: U.S. Sales</span>
                            <a href={`tel:${usPhone}`}>
                                {{usPhone}}
                            </a>
                            <span>or International Sales:</span>
                            <a href={`tel:${internationalPhone}`}>
                                {{internationalPhone}}
                            </a>
                        </Trans> :
                        t('gamification-marketing__no-permissions')
                    }
                </p>
            </div>
            <div className='hme-gamification-marketing__contact'>
                <ContactHME />
            </div>
        </div>
    );
};

export const GamificationMarketing = compose(
    withHMELayout()
)(GamificationMarketingComponent);
