import { useState, useEffect, useCallback } from 'react';
import { getRegions } from '../services/Regions';

export const useLoadRegions = (countryId) => {
    const [regions, setRegions] = useState([]);
    const [isRegionsLoading, setIsRegionsLoading] = useState(false);
    const [regionsLoadingError, setRegionsLoadingError] = useState(null);

    const loadRegions = useCallback(async (countryId) => {
        try {
            setIsRegionsLoading(true);
            setRegionsLoadingError(null);
            const regionsList = countryId ? (await getRegions(countryId) || []) : [];

            setRegions(regionsList);
        } catch (e) {
            setRegions([]);
            setRegionsLoadingError(e);
        }

        setIsRegionsLoading(false);
    }, []);

    useEffect(() => { loadRegions(countryId); }, [countryId]);

    return { regions, isRegionsLoading, regionsLoadingError, loadRegions };
};
