/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button, BUTTON_VARIANTS } from 'library/Button';
import { Tip } from 'library/Tip';
import { DateLib } from '@hme-cloud/utility-common';

import { getDownloadedContestResult } from '../Controller';

import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

import './ContestDownloadButton.scss';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

const ContestDownloadButton = ({ className, formats, uid, contestName }) => {
    const { t } = useTranslation();
    const [showFormats, setShowFormats] = useState(false);
    const timePart = new DateLib().format(DateLib.FORMAT_TYPES.DATE_UNDERSCORED);

    const downloadContestResults = async (format) => {
        const fileName = `${t('contest__results__file-name')}_${contestName}_${timePart}.${format.toLowerCase()}`;
        try {
            await getDownloadedContestResult({
                contestUID: uid,
                fileName,
                format: format.toUpperCase()
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={classNames(className, 'contest-download-button')}>
            <Button
                variants={buttonVariants}
                onMouseDown={()=> setShowFormats(!showFormats)}
            >
                {t('contest__results')}
            </Button>
            <Tip
                placement="bottom"
                className="contest-download-button__tooltip"
                iconClassName="contest-download-button__dropdown-icon"
                Icon={DropdownIcon}
                showTip={showFormats}
                setShowTip={setShowFormats}
            >
                <ul className="contest-download-button__format-options">
                    {formats.map((format) => (
                        <li
                            onClick={() =>  {
                                setShowFormats(false);
                                downloadContestResults(format);
                            }}
                            className="contest-download-button__format-options__item"
                            key={format}
                        >
                            {format.toUpperCase()}
                        </li>
                    ))}
                </ul>
            </Tip>
        </div>
    );
};

export { ContestDownloadButton };
