import { T, cond } from 'ramda';
import './PeripheralActiveStatus.scss';

const StatusOnline = () => {
    return (<span className='hme-device-individual-active-status'>
        <span className='hme-status-circle hme-status-circle--online'></span>
    </span>);
};

const StatusOffline = () => {
    return (<span className='hme-device-individual-active-status'>
        <span className='hme-status-circle hme-status-circle--offline'></span>
    </span>);
};

export const PeripheralActiveStatus = cond([
    [({ isBaseStationActive, device }) => (isBaseStationActive == 1 && device.isActive == 1), StatusOnline],
    [T, StatusOffline]
]);