import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrigger } from 'hooks/useTrigger';
import { NotificationsList } from 'library/NotificationsList';
import { Paginate } from 'library/Paginate';
import { Translator } from 'library/Translator';
import { getProfile } from 'services/Auth';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';
import { saveSnapshot as saveAdminSnapshot, saveDistributorSnapshot, savePublicSnapshot } from 'services/Snapshots';
import { formDataValidator } from './helpers';
import { Header } from './Header';
import { deletePublicSnapshot, deleteAdminSnapshot, deleteDistributorSnapshot, getFilters } from './Controller';
import { accountTypes } from 'constants/accountTypes';
import { snapshotConfig } from 'constants/manageDeviceSettings';
import { accountTypePageSizes } from './constants';
import { SnapshotsList } from './SnapshotsList';
import { DeleteConfirmPopup } from './DeleteConfirmPopup';
import { CreateSnapshotModal } from './CreateSnapshotModal';
import { CreateSnapshotForm } from './CreateSnapshotForm';
import { Footer } from './Footer';

import './DeviceSettings.scss';

const { statuses } = snapshotConfig;
const INIT_PAGE_NUMBER = 0;
const INIT_ITEMS_PER_PAGE = 10;
const INIT_SORT_SELECTION = { AppliedDate: -1 };
const INIT_NEW_SNAPSHOT_PAYLOAD = {
    sourceDeviceSerialNumber: '',
    sourceDeviceProductId: '',
    settingName: '',
    description: '',
    settingsVersion: '',
    storeNumber: '',
    selectedDeviceType: '',
    isBrandApproved: false,
    isTestSnapshot: false,
};

const validate = (
    { sourceDeviceSerialNumber, sourceDeviceProductId, selectedDeviceType, storeNumber, settingName, settingsVersion },
    selectedAccount,
    accountSearch,
) =>
    formDataValidator(
        selectedAccount,
        sourceDeviceSerialNumber,
        sourceDeviceProductId,
        selectedDeviceType,
        storeNumber,
        settingName,
        accountSearch,
        settingsVersion,
    );

const statusFilters = [
    {
        value: [statuses.CREATED],
        text: <Translator tKey="common__created" />,
    },
    {
        value: [statuses.FAILED],
        text: <Translator tKey="common__task__status--failed" />,
    },
    {
        value: [statuses.DRAFT, statuses.IN_PROGRESS],
        text: <Translator tKey="common__task__status--in-progress" />,
    },
    {
        value: [statuses.SET_TO_EXPIRE],
        text: <Translator tKey="common__task__status--set-to-expire" />,
    },
];

const getAccountTypePageSizes = (accountType) => (accountTypePageSizes[accountType] ?? accountTypePageSizes.DEFAULT);

const loadFilters = async ({ setIsFiltersLoading, setFilters, t }) => {
    try {
        setIsFiltersLoading(true);

        const filters = await getFilters();
        filters.Status = statusFilters;

        setFilters(filters);
    } catch (e) {
        addErrorNotification('common__error--internal-server');
        setFilters([]);
    }

    setIsFiltersLoading(false);
};

const saveSnapshot = (permissionType, payload) => {
    const saveSnapshotMap =  {
        [accountTypes.ADMIN]: saveAdminSnapshot,
        [accountTypes.DISTRIBUTOR]: saveDistributorSnapshot,
        [accountTypes.PUBLIC]: savePublicSnapshot,
    }

    return saveSnapshotMap[permissionType](payload);    
}

export const DeviceSettings = ({ permissionType, loadSnapshots }) => {
    const [snapshots, setSnapshots] = useState([]);
    const [filters, setFilters] = useState({});
    const [snapshotsTotal, setSnapshotsTotal] = useState(0);
    const [isSnapshotsLoading, setIsSnapshotsLoading] = useState(false);
    const [isFiltersLoading, setIsFiltersLoading] = useState(false);
    const [snapshotForDelete, setSnapshotForDelete] = useState(null);
    const [isCreateShown, setIsCreateShown] = useState(false);
    const [isDeleteShown, setIsDeleteShown] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(INIT_PAGE_NUMBER);
    const [itemsPerPage, setItemsPerPage] = useState(INIT_ITEMS_PER_PAGE);
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELECTION);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [snapshotCreationPayload, setSnapshotCreationPayload] = useState(INIT_NEW_SNAPSHOT_PAYLOAD);
    const [snapshotCreationAccountSearch, setSnapshotCreationAccountSearch] = useState('');
    const [snapshotCreationSelectedAccount, setSnapshotCreationSelectedAccount] = useState(null);
    const [isSnapshotCreationLoading, setIsSnapshotCreationLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const resetFiltersTrigger = useTrigger();
    const profile = useMemo(() => getProfile(), []);

    const { t } = useTranslation();

    const onSnapshotDelete = useCallback(
        (snapshot) => {
            setSnapshotForDelete(snapshot);
            setIsDeleteShown(true);
        },
        [setSnapshotForDelete, setIsDeleteShown],
    );

    const onSnapshotDeleteHide = useCallback(() => {
        setIsDeleteShown(false);
    }, [setIsDeleteShown]);

    const onSnapshotCreateShow = useCallback(() => {
        setIsCreateShown(true);
    }, [setIsCreateShown]);

    const onSnapshotCreateHide = useCallback(() => {
        setIsCreateShown(false);
    }, [setIsCreateShown]);

    const onSnapshotCreated = useCallback(() => {
        loadSnapshots({
            setIsSnapshotsLoading,
            setSnapshots,
            setSnapshotsTotal,
            pageNumber,
            itemsPerPage,
            sortSelection,
            filters,
            appliedFilters,
        });
        setIsCreateShown(false);
        addSuccessNotification('settings_snapshots__create_snapshot__notification--snapshot-successfully-created');
    }, [
        setIsCreateShown,
        setSnapshots,
        setIsSnapshotsLoading,
        pageNumber,
        itemsPerPage,
        sortSelection,
        filters,
        appliedFilters,
    ]);

    const onSnapshotDeleteConfirm = useCallback(async () => {
        setIsDeleteShown(false);
        setIsSnapshotsLoading(true);

        try {
            if (permissionType === accountTypes.DISTRIBUTOR) {
                await deleteDistributorSnapshot(snapshotForDelete.SettingTemplateID);
            }

            if (permissionType === accountTypes.ADMIN) {
                await deleteAdminSnapshot(snapshotForDelete.SettingTemplateID);
            }

            if (permissionType === accountTypes.PUBLIC) {
                await deletePublicSnapshot(snapshotForDelete.SettingTemplateID);
            }
        } catch (err) {
            setSnapshotForDelete(null);
            setIsSnapshotsLoading(false);
            return;
        }

        const newSnapshots = snapshots.filter((s) => s.SettingTemplateID !== snapshotForDelete.SettingTemplateID);

        setSnapshots(newSnapshots);
        setSnapshotForDelete(null);
        setIsSnapshotsLoading(false);
    }, [snapshotForDelete, snapshots, setIsDeleteShown, setSnapshots, setSnapshotForDelete, setIsSnapshotsLoading]);

    const onPaginationChange = useCallback(
        ({ page = 0, recordsPerPage = 10 } = {}) => {
            setPageNumber(page);
            setItemsPerPage(recordsPerPage);

            loadSnapshots({
                setIsSnapshotsLoading,
                setSnapshots,
                setSnapshotsTotal,
                pageNumber: page,
                itemsPerPage: recordsPerPage,
                sortSelection,
                filters,
                appliedFilters
            });
        },
        [sortSelection, appliedFilters, filters],
    );

    const onSortChange = useCallback(
        (newSortData) => {
            setSortSelection(newSortData);
            loadSnapshots({
                setIsSnapshotsLoading,
                setSnapshots,
                setSnapshotsTotal,
                pageNumber,
                itemsPerPage,
                filters,
                appliedFilters,
                sortSelection: newSortData,
            });
        },
        [pageNumber, itemsPerPage, appliedFilters, filters],
    );

    const onFiltersChange = useCallback(
        ({
            SourceBrand,
            SettingsVersion,
            SourceLaneConfig,
            CreatedBy,
            SourceDeviceType,
            SourceSoftwareVersion,
            Status,
        }) => {
            const newAppliedFilters = {
                SourceBrand,
                SettingsVersion,
                SourceLaneConfig,
                CreatedBy,
                SourceDeviceType,
                SourceSoftwareVersion,
                Status,
            };

            setAppliedFilters(newAppliedFilters);

            loadSnapshots({
                setIsSnapshotsLoading,
                setSnapshots,
                setSnapshotsTotal,
                itemsPerPage,
                sortSelection,
                filters,
                appliedFilters: newAppliedFilters,
            });

            setPageNumber(INIT_PAGE_NUMBER);
        },
        [itemsPerPage, sortSelection, filters],
    );

    const onFiltersAndSortChange = useCallback(({ selectedFilters, selectedSortValue }) => {
        setAppliedFilters(selectedFilters);
        setSortSelection(selectedSortValue);

        loadSnapshots({
            setIsSnapshotsLoading,
            setSnapshots,
            setSnapshotsTotal,
            itemsPerPage,
            filters,
            sortSelection: selectedSortValue,
            appliedFilters: selectedFilters,
        });
    }, [itemsPerPage, filters]);

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
        setAppliedFilters({});

        loadSnapshots({
            setIsSnapshotsLoading,
            setSnapshots,
            setSnapshotsTotal,
            itemsPerPage,
            sortSelection,
        });
    }, [
        itemsPerPage,
        sortSelection,
        resetFiltersTrigger
    ]);

    useEffect(() => {
        loadFilters({ setIsFiltersLoading, setFilters, t });
        loadSnapshots({
            setIsSnapshotsLoading,
            setSnapshots,
            setSnapshotsTotal,
            pageNumber,
            itemsPerPage,
            sortSelection,
            filters,
            appliedFilters
        });
    }, []);

    const pageSizes = getAccountTypePageSizes(permissionType);

    const shouldFooterBeShown = useMemo(
        () => !!(snapshots.length && snapshotsTotal > pageSizes[0]),
        [snapshots, snapshotsTotal, pageSizes],
    );

    const onSortReset = () => {
        onSortChange(INIT_SORT_SELECTION);
    }

    const onSnapshotCreationChange = useCallback(({ snapshotPayload, selectedAccount, accountSearch }) => {
        setSnapshotCreationPayload(snapshotPayload);
        setSnapshotCreationSelectedAccount(selectedAccount);
        setSnapshotCreationAccountSearch(accountSearch);
        setFormErrors({});
    }, []);

    const onSnapshotCreationConfirm = useCallback(async () => {
        const errors = validate(
            snapshotCreationPayload,
            snapshotCreationSelectedAccount,
            snapshotCreationAccountSearch,
        );

        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});
        setIsSnapshotCreationLoading(true);

        try {
            const result = await saveSnapshot(permissionType, snapshotCreationPayload);

            if (!result.status) {
                const errorNotification = result?.data?.key?.tKey
                    ? t(result.data.key.tKey, result.data.key.params)
                    : t('settings_snapshots__create_snapshot__form__warning--failed-process-error-message');

                addErrorNotification(errorNotification);

                setIsSnapshotCreationLoading(false);

                return;
            }

            onSnapshotCreated();
        } catch (e) {
            addErrorNotification(t('settings_snapshots__create_snapshot__form__warning--failed-process-error-message'));
        }

        setIsSnapshotCreationLoading(false);
    }, [
        snapshotCreationPayload,
        snapshotCreationSelectedAccount,
        snapshotCreationAccountSearch,
        setFormErrors,
    ]);

    const createSnapshotActions = useMemo(
        () =>
            isSnapshotCreationLoading
                ? []
                : [
                      {
                          children: t('settings_snapshots__create_snapshot__form__cancel'),
                          onClick: onSnapshotCreateHide,
                      },
                      {
                          children: t('settings_snapshots__create_snapshot__form__create-snapshot'),
                          variants: ['submit'],
                          onClick: onSnapshotCreationConfirm,
                      },
                  ],
        [isSnapshotCreationLoading, onSnapshotCreateHide, onSnapshotCreationConfirm],
    );

    return (
        <div className="hme-page-component device-settings">
            <NotificationsList />
            <Header onCreateShow={onSnapshotCreateShow} onFiltersReset={onFiltersReset} filtersCount={filtersCount} />
            <SnapshotsList
                permissionType={permissionType}
                profile={profile}
                snapshots={snapshots}
                filters={filters}
                isLoading={isSnapshotsLoading || isFiltersLoading}
                onSnapshotDelete={onSnapshotDelete}
                resetFiltersTrigger={resetFiltersTrigger}
                filtersCount={filtersCount}
                onFiltersChange={onFiltersChange}
                onFiltersCountChange={setFiltersCount}
                onSortChange={onSortChange}
                onFiltersAndSortChange={onFiltersAndSortChange}
                onAppliedFiltersReset={onFiltersReset}
                onSortReset={onSortReset}
                sortSelection={sortSelection}
            />
            {shouldFooterBeShown && (
                <Footer>
                    <Paginate
                        className="hme-manage-device-settings__paginate"
                        page={pageNumber}
                        recordsPerPage={itemsPerPage}
                        pageSizes={pageSizes}
                        total={snapshotsTotal}
                        onChange={onPaginationChange}
                        hideSinglePage
                    />
                </Footer>
            )}
            <DeleteConfirmPopup
                show={isDeleteShown}
                name={snapshotForDelete && snapshotForDelete.SettingName}
                onHide={onSnapshotDeleteHide}
                onConfirm={onSnapshotDeleteConfirm}
            />
            <CreateSnapshotModal
                className={
                    isSnapshotCreationLoading
                        ? 'hme-modal-device-settings__loading'
                        : ''
                }
                show={isCreateShown}
                onHide={onSnapshotCreateHide}
                actions={createSnapshotActions}
            >
                <CreateSnapshotForm
                    permissionType={permissionType}
                    isSnapshotCreationLoading={isSnapshotCreationLoading}
                    onChange={onSnapshotCreationChange}
                    formErrors={formErrors}
                />
            </CreateSnapshotModal>
        </div>
    );
};
