import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputComponent } from 'components/Inputs';
import { Button } from 'library/Button';
import { Modal } from 'library/Modal';
import { ChangePasswordWidget } from 'library/Password/ChangePasswordWidget';

import './PasswordChangeField.scss';

const BUTTON_VARIANTS = ['transparent'];

export const PasswordChangeField = ({ fields, onFieldsChange, isPasswordChangeInProgress, onSubmit, onClose, className = '' }) => {
    const { t } = useTranslation();

    const [isPasswordFormShown, setIsPasswordFormShown] = useState(false);

    const handlePasswordFormVisibilityOpen = useCallback(() => {
        setIsPasswordFormShown(true);
    }, []);

    const handlePasswordFormVisibilityClose = useCallback(() => {
        if (isPasswordChangeInProgress) return;

        setIsPasswordFormShown(false);

        onClose?.();
    }, [isPasswordChangeInProgress, onClose]);

    return (
        <div className={className}>
            <div className="hme-password-change-field">
                <Button
                    className="hme-password-change-field__btn"
                    variants={BUTTON_VARIANTS}
                    onClick={handlePasswordFormVisibilityOpen}
                >
                    {t('common__change')}
                </Button>
                <InputComponent
                    className="hme-password-change-field__fake-unput"
                    label="common__user-password"
                    value="********"
                    valueClassName="hme-password-change-field__password-fill"
                    isReadOnly
                />
            </div>

            <Modal
                show={isPasswordFormShown}
                className="hme-password-change-field__modal"
                onHide={handlePasswordFormVisibilityClose}
                dialogClassName="hme-password-change-field__modal-dialog"
                restoreFocus
                backdrop
            >
                <ChangePasswordWidget
                    fields={fields}
                    isPasswordChangeInProgress={isPasswordChangeInProgress}
                    onFieldsChange={onFieldsChange}
                    onSubmit={onSubmit}
                    onCancel={handlePasswordFormVisibilityClose}
                />
            </Modal>
        </div>
    );
};
