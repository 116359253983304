import * as React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ message, className = null }) => {
    return (
        <section>
            <div>
                <div className='forms clear'>
                    <div className= {className ? className : 'appr'}>
                        <h4>{message}</h4>
                    </div>
                </div>
            </div>
        </section>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string
};

export default ErrorMessage;