import * as leaderboard from '../../actionTypes/Leaderboard/Leaderboard'

const initialState = {
  accountSubscriptionType: {
    'accountSubscriptionType': 3
  }
}

export default function companyLBVersion (state = initialState, action) {
  switch (action.type) {
    case leaderboard.GET_ACCOUNTSUBSCRIPTIONTYPE:
      state.accountSubscriptionType = action.accountSubscriptionType
      return {
        ...state
      }
    default:
      return state
  }
}
