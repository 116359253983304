import { checkAccess } from '../../../actions/permissions';
import { CommonConstants } from '../../../Constants';

const getAdminReportsSubheaderPermissions = {
    outliersReports: CommonConstants.adminPermissions.HMEReports,
    ghostCarsReport: CommonConstants.adminPermissions.HMEReports,
    speedOfService: CommonConstants.adminPermissions.ViewSpeedOfServiceReport,
    storeStatus: CommonConstants.adminPermissions.HMEReports
};

// We need to store the tabs order
const tabsOrder = [
    'outliersReports',
    'ghostCarsReport',
    'speedOfService',
    'storeStatus'
];

// Get available tabs for settings page by user permissions
export const getAdminReportsTab = () => {
    return tabsOrder.filter((tabName)=> {
        const permission = getAdminReportsSubheaderPermissions[tabName];
        return !permission || checkAccess(getAdminReportsSubheaderPermissions[tabName]);
    });
};
