import * as brand from '../../actionTypes/Brands/Brands'

const initialState = {
  brands: [
    {
      'Id': 0,
      'Uid': '',
      'Name': '',
      'Letter': ''
    }
  ]
}

export default function brandDetails (state = initialState, action) {
  switch (action.type) {
    case brand.GET_BRAND:
      state.brands = action.brands
      return {
        ...state
      }
    default:
      return state
  }
}
