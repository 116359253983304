import { useState, useEffect, useCallback } from 'react';
import { getDistributors } from '../services/Distributors';

export const useLoadDistributors = ({ shouldLoadDistributors = true } = {}) => {
    const [distributors, setDistributors] = useState([]);
    const [isDistributorsLoading, setIsDistributorsLoading] = useState(false);
    const [distributorsLoadingError, setDistributorsLoadingError] = useState(null);

    const loadDistributors = useCallback(async (shouldLoadDistributors) => {
        try {
            setIsDistributorsLoading(true);
            setDistributorsLoadingError(null);
            const distributorsList = (shouldLoadDistributors && await getDistributors()) || [];

            setDistributors(distributorsList);
        } catch (e) {
            setDistributors([]);
            setDistributorsLoadingError(e);
        }

        setIsDistributorsLoading(false);
    }, []);

    useEffect(() => { loadDistributors(shouldLoadDistributors); }, []);

    return { distributors, isDistributorsLoading, distributorsLoadingError, loadDistributors };
};
