import React from 'react';

import { formItemParts, announcementItems } from '../constants';
import { AnnouncementDurationSettings } from '../AnnouncementDurationSettings';

const settingsView = new Map([
    [
        announcementItems.SMACKTALK,
        {
            title: 'announcement-settings__title--smacktalk',
            fieldsSubtitle: 'announcement-settings__after-message-sent',
            parts: [formItemParts.PERIOD],
        },
    ],
    [
        announcementItems.WINNER_BANNER,
        {
            title: 'announcement-settings__title--winner-banner',
            fieldsSubtitle: 'announcement-settings__after-contest-ends',
            parts: [formItemParts.PERIOD],
        },
    ],
    [
        announcementItems.CONTEST_STARTING_BANNER,
        {
            title: 'announcement-settings__title--contest-starting-banner',
            description: 'announcement-settings__contest-starting-banner-description',
            fieldsSubtitle: 'announcement-settings__before-contest-starts',
            parts: [formItemParts.PERIOD],
        },
    ],
    [
        announcementItems.ROUND_STARTING_BANNER,
        {
            title: 'announcement-settings__title--round-starting-banner',
            description: 'announcement-settings__round-starting-banner-description',
            fieldsSubtitle: 'announcement-settings__before-round-starts',
            parts: [formItemParts.PERIOD],
        },
    ],
    [
        announcementItems.NOTIFICATION_FREQUENCY,
        {
            title: 'announcement-settings__title--notification-frequency',
            intervalDescription: 'announcement-settings__repeat-presentation',
            parts: [formItemParts.INTERVAL],
        },
    ],
    [
        announcementItems.CONTEXTUAL_FEEDBACK,
        {
            title: 'announcement-settings__title--contextual-feedback',
            description: 'announcement-settings__contextual-feedback-description',
            parts: [formItemParts.FEEDBACK_INTERVAL],
        },
    ],
    [
        announcementItems.AWARDPAGE,
        {
            title: 'announcement-settings__title--award-achievement',
            description: 'announcement-settings__award-duration-settings-description',
            intervalDescription: 'announcement-settings__repeat-presentation',
            fieldsSubtitle: 'announcement-settings__after-award-achieved',
            parts: [formItemParts.INTERVAL, formItemParts.PERIOD],
        },
    ],
    [
        announcementItems.CONTEST_WINNER,
        {
            title: 'announcement-settings__title--contest-winner',
            description: 'announcement-settings__contest-winner-settings-description',
            intervalDescription: 'announcement-settings__repeat-presentation',
            fieldsSubtitle: 'announcement-settings__after-contest-ends',
            parts: [formItemParts.INTERVAL, formItemParts.PERIOD],
        },
    ],
]);

export const SettingsFactory = ({ currentSettings, settingType, timeUnits }) => {
    const extendedProps = { ...settingsView.get(settingType), item: { ...currentSettings } };

    return (
        <AnnouncementDurationSettings
            {...extendedProps}
            settingType={settingType}
            timeUnits={timeUnits}
        />
    );
};
