import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CheckboxInput.css';

class CheckboxInput extends Component {
  constructor() {
    super();

    this.checkboxRef = createRef();
    this.onChange = this.onChange.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  onChange(e) {
    // check for focus then space click
    // ignored when mouse click - onMouseDown is triggered instead
    if (!e.nativeEvent.screenX) {
      this.props.onChange(e.target.checked);
    }
  }

  onMouseDown() {
    this.props.onChange(!this.checkboxRef.current.checked);
  }

  render() {
    const { className, label, name, value } = this.props;
    if (typeof value == 'string') {
      console.log(`here: ${value}; name: ${name}`)
    }

    const checkboxDisabled = (this.props.isDisabled === true || this.props.disabled === true) ? true : false;
    const inputStyling = (this.props.useUpgradesInput ? 'checkbox-input': 'hme-input checkbox-input')

    if(this.props.useUpgradesInput){
      return (
        <div className={inputStyling}>
          
          <div className={"round"+([...label].length < 2? " round-extra-padding-for-centering-single-chars":"")}>            
          <input ref={this.checkboxRef} 
            type="checkbox" 
            checked={value} 
            value={name} 
            onChange={this.onChange}
            onMouseDown={this.onMouseDown}
            disabled={checkboxDisabled}
          />
          <label onClick={checkboxDisabled?null:this.onMouseDown} htmlFor="checkbox">{label}</label>
          </div>
        </div>
      );
    }else{
      return (
        <div className={classNames(className, inputStyling)}>
          <input
            {...this.props}
            ref={this.checkboxRef}
            type="checkbox"
            checked={value ? value : false}
            value={name || ''}
            onChange={this.onChange}
            onMouseDown={this.onMouseDown}
            disabled={checkboxDisabled}
          />
          {label && (
            <label>{label}</label>
          )}
        </div>
      );

    }
  }
}

CheckboxInput.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.bool
};

export { CheckboxInput };
