import React, { Component } from 'react'
import CommonLoader from '../Alerts/CommonLoader'

class Device extends Component { // ensure you dont export component directly
  constructor (props) {
    super(props)
    this.state = {
      showStores: this.props.showStores
    }
  }
  render () {
    return (
      <section className='device'>
        <CommonLoader showLoader message='device-connect__loading' />
      </section>
    )
  }
}

export default Device
