import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';

import { TabPanel, Tab, TAB_PANEL_VARIANTS } from 'library/TabPanel';
import { Loader } from 'library/Loader';
import { Section } from 'library/Section';

import { Overall } from './Overall';
import { Round } from './Round';
import { NoResults } from './NoResults';

import './Results.scss';

const tabVariants = [TAB_PANEL_VARIANTS.TRANSPARENT];

const ResultTabs = ({ results, isResultsLoading }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('overall');

    return (
        <TabPanel activeTab={activeTab} onActiveTabChange={setActiveTab} variants={tabVariants} >
            <Tab text={t('contest__form__results__tab--overall')} value='overall'>
                <Overall overall={results.overall} isResultsLoading={isResultsLoading} />
            </Tab>
            <Tab text={t('contest__form__results__tab--round')} value='round'>
                <Round rounds={results.rounds} isResultsLoading={isResultsLoading} metric={results.overall && results.overall.metric} />
            </Tab>
        </TabPanel>
    );
};

const ResultsComponent = cond([
    [({ isResultsLoading }) => isResultsLoading, () => (<Loader />)],
    [({ results }) => !results.overall?.results?.length && !results.rounds?.length, () => (<NoResults />)],
    [T, (props) => (<ResultTabs {...props} />)]
]);

export const Results = (props) => {
    const { t } = useTranslation();

    return (
        <Section
            title={`${t('contest__form__results__title--section')}`}
            showNumber={false}
            className="hme-contest-view-edit-form__section hme-contest-view-edit-form__section--stores"
        >
            <div className='hme-contest-view-edit-form__results'>
                <ResultsComponent {...props} />
            </div>
        </Section>
    );
}
