export const TYPES = {
    LEADERBOARD_INVITED: 'Leaderboard Invited',
    LEADERBOARD_ACCEPTED: 'Leaderboard Accepted',
    LEADERBOARD_DECLINED: 'Leaderboard Declined'
};

export const LOCAL_STORAGE_KEYS = {
    LAST_TIME: 'unread__last-time',
    UNREAD_KEY: 'unread__unread',
};
