import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import './Alerts.css';

// CommonLoader - Component.

// get the loader Image
const LoaderImg = require('../../images/Loader.gif');

/**
 *
 */
class CommonLoader extends React.Component {
    // get the brower lanuage and set to intial state
    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {};
        this.state.isDashboard = this.props.isDashboard;
    }

    /**
     *
     * @return {JSX}
     */
    render() {
        const messageTKey = this.props.message;
        const { showLoader, t } = this.props;

        const message = t(messageTKey);

        // displaying the message based on brower selected lanuage
        return (
            <section className={'commonLoaderSection text-center ' + (showLoader ? 'show' : 'hidden')}>
                <h3 className="loaderTxt">{message}</h3>
                <div><img src={LoaderImg} alt="loader" /></div>
                {this.renderDashboardMessage()}
            </section>
        );
    }

    /**
     *
     * @return {JSX}
     */
    renderDashboardMessage() {
        const { t } = this.props;

        if (this.state.isDashboard) {
            return (<div className="dashboardMessage"> {t('dashboard__loading')}</div>);
        } else {
            return '';
        }
    }
}

export default compose(
        withTranslation()
)(CommonLoader);
