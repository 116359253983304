import * as storeRanking from '../../actionTypes/StoreRanking/StoreRanking'

const initialState = {
  gaugeDetails: {
    tables: {
      topStores: [
      ],
      bottomStores: [
      ],
      centerStores: [
      ]
    },
    dashboardData: {
    }
  },
  ranking: {
    tables: {
      topStores: [
      ],
      bottomStores: [
      ],
      centerStores: [
      ]
    },
    dashboardData: {
    }
  },
  dashboardParams: {
    ps: '5',
    report: 'Day',
    suid: '3D04C9A06DC44EF7968022D3B63F343D'
  }
}

export default function usersDetails (state = initialState, action) {
  switch (action.type) {
    case storeRanking.GET_STORERANKING:
      state.gaugeDetails = action.gaugeDetails
      return {
        ...state
      }
    case storeRanking.SET_DASHBOARDPARAMS:
      state.dashboardParams = action.dashboardParams
      return {
        ...state
      }
    case storeRanking.GET_DASHBOARDATA:
      state.ranking.dashboardData = action.dashboard.dashboard
      state.ranking.tables = action.dashboard.tables
      state.ranking.userPreferences = action.dashboard.userPreferences
      state.ranking.currentDayData = action.dashboard.currentDayData
      return {
        ...state
      }
    default:
      return state
  }
}
