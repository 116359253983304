import { useState, useCallback } from 'react';
import { createUpgradeTask } from '../Controller';

export const useUpgradeDevice = (loadDevice, loadDeviceSettings) => {
    const [deviceUpgradeTask, setDeviceUpgradeTask] = useState(null);
    const [isDeviceUpgrading, setIsDeviceUpgrading] = useState(false);
    const [deviceUpgradingError, setDeviceUpgradingError] = useState(null);

    const upgradeDevice = useCallback(async ({ deviceUID, deviceType, version }) => {
        if (!deviceUID) return;

        try {
            setIsDeviceUpgrading(true);
            setDeviceUpgradingError(null);

            const result = await createUpgradeTask({ deviceUID, deviceType, version });
            await Promise.all([loadDevice(deviceUID), loadDeviceSettings(deviceUID)]);

            setDeviceUpgradeTask(result);
            setIsDeviceUpgrading(false);

            return result;
        } catch (e) {
            setDeviceUpgradeTask(null);
            setIsDeviceUpgrading(false);
            setDeviceUpgradingError(e);
        }
    }, []);

    return { deviceUpgradeTask, isDeviceUpgrading, deviceUpgradingError, upgradeDevice };
};
