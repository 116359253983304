import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';
import { Modal } from 'library/Modal';

import { ChangePasswordForm } from '../ChangePasswordForm';
import { Section } from '../Common/Section';
import { Input } from '../Common/Input';

import './LoginDetails.scss';

const BUTTON_VARIANTS = ['transparent'];
const INPUT_VARIANTS = ['label-inside-mobile-only'];

export const LoginDetails = ({ account, accountDataErrors, isPasswordChangeInProgress, onAccountChange, onPasswordUpdate }) => {
    const { t } = useTranslation();
    const [isPasswordFormShown, setIsPasswordFormShown] = useState(false);

    const onUserNameChange = useCallback(
            (changeEmail) => {
                onAccountChange((accountData) => ({
                    ...accountData,
                    changeEmail
                }));
            },
            [account, onAccountChange]
    );

    const handlePasswordFormVisibilityOpen = useCallback(() => {
        setIsPasswordFormShown(true);
    }, []);

    const handlePasswordFormVisibilityClose = useCallback(() => {
        if (isPasswordChangeInProgress) return;

        onAccountChange((accountData) => {
            // eslint-disable-next-line no-unused-vars
            const { currentPassword, newPassword, confirmNewPassword, ...accountDataWithoutPassword } = accountData;

            return accountDataWithoutPassword;
        });
        setIsPasswordFormShown(false);
    }, [isPasswordChangeInProgress]);

    return (
        <Section title={t('profile__account__login-details__title')} className="hme-login-details">
            <Input
                label="common__user__username"
                value={account.changeEmail}
                error={accountDataErrors.changeEmail}
                isRequired
                onChange={onUserNameChange}
                autoComplete="username"
                variants={INPUT_VARIANTS}
            />

            <div className="hme-login-details__password-field">
                <Button
                    className="hme-login-details__password-btn"
                    variants={BUTTON_VARIANTS}
                    onClick={handlePasswordFormVisibilityOpen}
                >
                    {t('common__change')}
                </Button>
                <Input
                    className="hme-login-details__password"
                    label="common__user-password"
                    value="********"
                    valueClassName="hme-login-details__password-fill"
                    isReadOnly
                />
            </div>

            <Modal
                show={isPasswordFormShown}
                className="hme-login-details__password-modal"
                onHide={handlePasswordFormVisibilityClose}
                dialogClassName="hme-login-details__password-modal-dialog"
                restoreFocus
                backdrop
            >
                <ChangePasswordForm
                    account={account}
                    isPasswordChangeInProgress={isPasswordChangeInProgress}
                    onAccountChange={onAccountChange}
                    onSubmit={onPasswordUpdate}
                    onCancel={handlePasswordFormVisibilityClose}
                />
            </Modal>
        </Section>
    );
};
