import PropTypes from 'prop-types';

export const headerItemType = PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
    flex: PropTypes.number,
    property: PropTypes.string,
    headerClassName: PropTypes.string,
    sortable: PropTypes.bool,
});

export const headersType = PropTypes.arrayOf(headerItemType);
