import  { useEffect, useState } from 'react';

import { Title } from './Title';
import { Day } from './Day';
import { getMonthKey, getDayKey } from '../utils';

import './Month.scss';

const getDatesInRange = (startDate, endDate) => {
    const dates = [];

    let currentDate = startDate.clone();

    while (currentDate.isBetween(startDate, endDate, undefined, '[]')) {
        dates.push(currentDate);

        currentDate = currentDate.clone().addDays(1);
    }

    return dates;
};

export const Month = ({
    shownDate,
    range,
    minDate = null,
    maxDate = null,
    isSelectionMode,
    onDayClick,
    onDayBlur
}) => {
    const [firstDate, setFirstDate] = useState(shownDate);
    const [lastDate, setLastDate] = useState(shownDate);
    const [dates, setDates] = useState([]);

    useEffect(() => {
        setFirstDate(shownDate.clone().startOfMonth().startOfWeek());
        setLastDate(shownDate.clone().endOfMonth().endOfWeek())
    }, [getMonthKey(shownDate)]);

    useEffect(() => {
        const dates = getDatesInRange(firstDate, lastDate);

        setDates(dates);
    }, [getMonthKey(firstDate), getMonthKey(lastDate)]);

    return (
        <div className='hme-calendar-selector-month'>
            <Title shownDate={shownDate}/>
            {
                dates.map((date) => (
                    <Day
                        key={getDayKey(date)}
                        date={date}
                        monthDate={shownDate}
                        range={range}
                        minDate={minDate}
                        maxDate={maxDate}
                        isSelectionMode={isSelectionMode}
                        onClick={onDayClick}
                        onBlur={onDayBlur}
                    />
                ))
            }
        </div>
    );
};
