import React from 'react';
import { ViewEditAction } from '../ViewEditAction';

const getUrl = (user) => `/admin/settings/users/user?uuid=${user.User_UID}`;

export const usersToRows = (users, onConfirmModalOpen, hasManageUserAccess) =>
    users.map((user) => ({
        ...user,
        RoleText: user.IsOwner ? 'Account Owner' : user.Role_Name,
        Actions: (
            <ViewEditAction
                url={getUrl(user)}
                isOwner={!!user?.IsOwner}
                onConfirmModalOpen={onConfirmModalOpen}
                uuid={user.User_UID}
                hasManageUserAccess={hasManageUserAccess}
            />
        ),
    }));
