import React, { forwardRef, useEffect } from 'react';
import { Tooltip as BTooltip } from 'react-bootstrap';
import classNames from 'classnames';

import './Tooltip.scss';

export const TooltipView = forwardRef(
    ({ className, tooltipContentRef, children, ...overlayProps }, ref) => {
        const { popper, show } = overlayProps;

        useEffect(() => {
            if (show) {
                // recalculate tooltip position in case scroll is appeared
                setTimeout(popper.scheduleUpdate, 100);
            }
        }, [popper, show]);

        return (
            <BTooltip {...overlayProps} ref={ref} className={classNames('hme-tooltip', className)}>
                <div className="hme-tooltip-content" ref={tooltipContentRef}>
                    {children}
                </div>
            </BTooltip>
        );
    },
);
