import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useQuery } from 'hooks/useQuery';
import { getOwnerAccount } from 'actions/userAccounts';
import { Label } from 'library/Label';

import './AccountEmailHeader.scss';

const defaultVariants = [];

export const AccountEmailHeader = ({ variants = defaultVariants }) => {
    const params = useQuery();
    const uuidInQuery = params.get('uuid');
    const auuidInQuery = params.get('auuid');
    const userUuid = useMemo(() => uuidInQuery || auuidInQuery, [uuidInQuery, auuidInQuery]);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (userUuid) {
            getOwnerAccount(userUuid)(dispatch);
        }
    }, [userUuid]);

    const ownerEmail = useSelector(({userAccounts}) => userAccounts.ownerAccount.User_EmailAddress, shallowEqual);

    return (
        <>
            {ownerEmail && <div className={classNames(
                'hme-account-email-header',
                variants.map((variant) => `hme-account-email-header-${variant}-variant`)
            )}>
                <Label>{t('common__account__email')}</Label>
                <Label className='hme-account-email-header__email'>{ownerEmail}</Label>
            </div>}
        </>
    );
};
