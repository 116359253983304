import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Label } from 'library/Label';
import { useClickOutSide } from 'hooks/useClickOutSide';

import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

import './Select.scss';

const defaultPlaceholder = 'common__select-item';
const defaultVariants = [];

const mobileOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    return false;
};

export const Select = ({
    value,
    variants = defaultVariants,
    label,
    placeholder,
    onChange,
    children,
    isDisabled,
    isRequired = false,
    showTooltip = false,
    className = ''
}) => {
    const selectRef = useRef(null);
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState(null);

    const translatedChildren = useMemo(() => children.map((option) => ({
        text: t(option.text),
        value: option.value
    })), [children, t]);

    useEffect(() => {
        const selectedItem = translatedChildren.find((item) => item.value === value) || null;
        const text = selectedItem ? selectedItem.text : t(placeholder || defaultPlaceholder);

        setInputText(text);
    }, [value, placeholder, translatedChildren]);

    const outSideClickHandler = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onMobileChange = useCallback((e) => {
        onChange && onChange(e.target.value);
    }, [onChange]);

    useClickOutSide(selectRef, outSideClickHandler);

    return (
        <div
            className={classNames(
                    'hme-select',
                    {
                        'hme-select-opened': isOpen,
                        'hme-select--required': isRequired
                    },
                isDisabled ? 'hme-select--disabled' : '',
                variants.map((variant) => `hme-select-${variant}-variant`),
                className
            )}
            onClick={() => isDisabled ? '' : setIsOpen(!isOpen)}
            ref={selectRef}
        >
            {!isRequired && <>
                <Label>{label}</Label>
                <div
                    className="hme-select-text"
                    title={showTooltip && inputText ? inputText : ''}
                >
                    {inputText}
                </div>
            </>}
            {isRequired && <>
                <div
                    className="hme-select-without-label"
                    title={showTooltip && inputText ? inputText : ''}
                >
                    <span className={isDisabled ? 'hidden' : 'hme-select-required'}>*</span>
                    {inputText}
                </div>
            </>}
            <DropdownIcon className="hme-select-icon" />
            <ul disabled={isDisabled} className="hme-select-dropdown">
                {
                    translatedChildren.map(({ value: optionValue, text }) => (
                        <li
                            key={optionValue}
                            className="hme-select-dropdown-item"
                            onClick={() => {
                                setIsOpen(false);
                                onChange && onChange(optionValue);
                            }}
                            title={showTooltip && text ? text : ''}

                        >
                            {text}
                        </li>
                    ))
                }
            </ul>
            <select
                className="hme-select__mobile"
                value={value}
                onClick={mobileOnClick}
                onChange={onMobileChange}
                disabled={isDisabled}
            >
                {
                    translatedChildren.map(({ value: optionValue, text }) => (
                        <option key={optionValue} value={optionValue}>{t(text)}</option>
                    ))
                }
            </select>
        </div>
    );
};

const valueType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

const textType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element
]);

Select.propTypes = {
    value: valueType,
    label: PropTypes.string,
    placeholder: textType,
    onChange: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.shape({
        value: valueType.isRequired,
        text: textType.isRequired
    })),
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    showTooltip: PropTypes.bool,
    variants: PropTypes.arrayOf(PropTypes.string)
};
