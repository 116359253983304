import { useCallback } from 'react';
import { removeUser } from 'services/Users';
import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';

export const useDeleteUser = () => {
    const { run: runDeleteUser, status, data: deleteUserStatus, error: deleteUserLoadingError } = useAsync();

    const deleteUser = useCallback(({ userUid, onDone }) => {
        runDeleteUser(removeUser({ userUid }).then(onDone));
    }, []);

    return {
        deleteUserStatus,
        isDeleteUserLoading: status === ASYNC_STATUS.PENDING,
        deleteUserLoadingError,
        deleteUser,
    };
};
