export const ACTIVITY_LOG_HEADERS = [
    {
        property: 'lastLoginDateTime',
        text: 'common__datetime',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.2,
        sortable: true,
    },
    {
        property: 'page',
        text: 'common__page',
        className: 'hme-grid-cell-show-all-content',
        flex: 2,
        sortable: true,
    },
    {
        property: 'action',
        text: 'common__action',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.2,
        sortable: true,
    },
    {
        property: 'record',
        text: 'common__record',
        className: 'hme-grid-cell-show-all-content',
        flex: 0.8,
        sortable: true,
    },
];

export const MOBILE_ACTIVITY_LOG_HEADERS = [
    {
        column: [
            { text: 'common__datetime', property: 'lastLoginDateTime' },
            { text: 'common__page', property: 'page' },
        ],
    },
    {
        column: [
            { text: 'common__action', property: 'action' },
            { text: 'common__record', property: 'record' },
        ],
    },
];
