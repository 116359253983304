import * as userAccount from '../actionTypes/Accounts/userAccounts'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import {PortalServiceApiQuery} from '../helpers/ApiQuery'
import { isDistributor } from 'services/Auth'

function getAccountSuccess (userAccounts) {
  return {
    type: userAccount.GET_ADMIN_ACCOUNT,
    userAccounts: userAccounts
  }
}

function getOwnerAccountSuccess (ownerAccount) {
  return {
    type: userAccount.SET_OWNER_ACCOUNT,
    ownerAccount
  }
}

/**
 * Used to fetch account owner displayed on admin portal when viewing any user pages
 * @param {string} uuid
 */
export const getOwnerAccount = (uuid) => {
  const url = new PortalServiceApiQuery({
    endpoint: CommonConstants.apiUrls.getAdminAcount,
    parameters: {
      uuid,
      fetchAccountOwner: true
    }
  }).toString();

  return (dispatch) => {
    const api = new Api();

    api.getData(url, ({data}) => {
      const ownerData = (({ User_EmailAddress, User_UID, User_ID, Company_Type }) => ({ User_EmailAddress, User_UID, User_ID, Company_Type }))(data[0]);
      dispatch(getOwnerAccountSuccess(ownerData));
    })
  }
}

function getAccountListSuccess (userAccountsList) {
  return {
    type: userAccount.GET_ADMIN_ACCOUNT_LIST,
    userAccountsList: userAccountsList
  }
}

export const getUserAccountDetails = (uuid, callBack, fetchAccountOwner) => {
  const parameters = {
    fetchAccountOwner: Boolean(fetchAccountOwner)
  };

  if (isDistributor()) {
    parameters.auuid = uuid;
  } else {
    parameters.uuid = uuid;
  }
  const url = new PortalServiceApiQuery({
    endpoint: isDistributor() ? CommonConstants.apiUrls.getDistributorAccount : CommonConstants.apiUrls.getAdminAcount,
    parameters
  }).toString();

  return (dispatch, getState) => {
    const api = new Api()
    api.getData(url, data => {
      let accountData = data.data
      dispatch(getAccountSuccess(accountData))
      callBack(accountData)
    })
  }
}
// Search accounts
export const setAccountSearchParams = (searchParams) => {
  return {
    type: userAccount.SET_SEARCH_PARAMS_ACCOUNTS,
    searchParams: searchParams
  }
}

export const getUserAccountList = (data, callBack) => {
  let page = (data.page !== 0) ? data.page : 1
  let pagenumber = '?PageNumber=' + page
  let sortcolumn = '&sortcolumn=' + data.sc
  let SortingType = '&SortingType=' + data.st
  let RecordPerPage = '&RecordPerPage=' + data.perPage
  return (dispatch, getState) => {
    const api = new Api()
    const state = getState()
    let query = pagenumber + '&criteria=' + escape(encodeURIComponent(state.userAccounts.searchParams.criteria)) + '&filter=' + state.userAccounts.searchParams.filter + sortcolumn + SortingType + RecordPerPage
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.getAdminAccounts + query
    api.getData(url, data => {
      let accountData = data.data
      dispatch(getAccountListSuccess(accountData))
      callBack(accountData)
    })
  }
}
