import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { getPermissionsList } from 'services/Permissions';
import { createRole } from 'services/Roles';
import { getPermissionItems } from '../../../Common/helpers/getPermissionItems';
import { getCheckedByDefault } from '../../../Common/helpers/getCheckedByDefault';
import { isRoleValid, trimmedKeys } from '../../../Common/validators/role';
import { trimValues } from 'helpers';

const load = async (setRole, setPermissions, setIsLoading) => {
    setIsLoading(true);

    try {
        const permissions = await getPermissionsList();
        setPermissions(getPermissionItems(permissions));
        setRole({
            name: '',
            description: '',
            permissions: getCheckedByDefault(permissions),
        });
    } catch (err) {
        addErrorNotification(err.message);
    } finally {
        setIsLoading(false);
    }
};

const save = async (role, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await createRole({ role: trimValues(role, trimmedKeys) });

        navigate('/settings/roles');
        setTimeout(() => {
            addSuccessNotification('add-role__success--created');
        }, 1000);
    } catch (err) {
        addErrorNotification(err.message);
    } finally {
        setIsLoading(false);
    }
};

export const useCreate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [role, setRole] = useState({
        name: '',
        description: '',
        permissions: []
    });
    const [permissions, setPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isSaveButtonDisabled = useMemo(() => !isRoleValid(role), [role]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onSave = useCallback(() => {
        save(role, navigate, setIsLoading);
    }, [role, navigate, setIsLoading]);

    useEffect(() => {
        load(setRole, setPermissions, setIsLoading);
    }, [setPermissions, setIsLoading]);

    return {
        title: t('roles__create-role-btn'),
        role,
        permissions,
        isLoading,
        isSaveButtonDisabled,
        hasRemove: false,
        onRoleChange: setRole,
        onCancel,
        onSave
    };
};
