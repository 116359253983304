import React from 'react';
import { Thead, Th, Tr } from 'react-super-responsive-table';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { HMECheckbox } from '../Common/HMECheckbox';

class LockDeviceSettingsTableHeader extends React.Component {
    constructor(props) {
        super(props);

        this.sort = this.sort.bind(this);
    }

    sort(event) {
        const sortParams = {
            sortBy: event.target.id,
            sortType: this.props.ascending ? 'DESC' : 'ASC',
        };

        this.props.onSort(sortParams);
    }

    render() {
        const { t, sortBy, sortType, isSelected, isSelectedAll, toggleSelect } = this.props;
        const sortIconClass = sortType === 'ASC' ? 'actcold' : 'actcol';
        return (
            <Thead>
                <Tr className="theader clear storestableHeader">
                    <Th className="tableHeaders">
                        <HMECheckbox
                            onChange={toggleSelect}
                            value={isSelectedAll}
                            isSelectedPartially={isSelected && !isSelectedAll}
                        />
                    </Th>
                    <Th className={`tableHeaders ${sortBy === 'Store_Name' ? sortIconClass : ''}`}>
                        <span id="Store_Name" onClick={this.sort}>
                            {t('common__store')}
                        </span>
                    </Th>
                    <Th className={`tableHeaders ${sortBy === 'Store_Number' ? sortIconClass : ''}`}>
                        <span id="Store_Number" onClick={this.sort}>
                            {t('common__store__number')}
                        </span>
                    </Th>
                    <Th className={`tableHeaders ${sortBy === 'Store_AddressLine1' ? sortIconClass : ''}`}>
                        <span id="Store_AddressLine1" onClick={this.sort}>
                            {t('common__address')}
                        </span>
                    </Th>
                    <Th className={`tableHeaders ${sortBy === 'Device_IsSettingLocked' ? sortIconClass : ''}`}>
                        <span id="Device_IsSettingLocked" onClick={this.sort}>
                            {t('common__settings__status')}
                        </span>
                    </Th>
                    <Th className={`tableHeaders ${sortBy === 'Device_MainVersion' ? sortIconClass : ''}`}>
                        <span id="Device_MainVersion" onClick={this.sort}>
                            {t('common__system__version')}
                        </span>
                    </Th>
                    <Th className={`tableHeaders ${sortBy === 'Device_IsActive' ? sortIconClass : ''}`}>
                        <span id="Device_IsActive" onClick={this.sort}>
                            {t('common__system__status')}
                        </span>
                    </Th>
                </Tr>
            </Thead>
        );
    }
}

export default compose(withTranslation())(LockDeviceSettingsTableHeader);
