import { cond, T } from 'ramda';

import { Loader } from 'library/Loader';

import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';

import './NotificationIcon.scss';

const Icon = ({ hasUnread }) => {
    return (
        <div className='hme-notifications-icon'>
            { hasUnread ?
                <BellIcon /> :
                <div className='dropdown-icon-badge'>
                    <span className="badge bg-primary"></span>
                    <i className="icon icon-alert" />
                </div> }
        </div>
    );
};

export const NotificationsIcon = cond([
    [({ isLoading }) => isLoading, () => (
        <div className='hme-notifications-icon'>
            <Loader className='hme-notifications-icon__loader' />
        </div>)],
    [T, ({ ...props }) => <Icon {...props} />]
]);
