import * as users from '../../actionTypes/Users/Users'

const initialState = {
  usersDetails: [
  ],
  pageDetails: [{'RecordCount': null}, {'TotalPages': null}],
  status: true,
  sortParams: {sortBy: 'User_EmailAddress', sortType: 'ASC'},
  searchParams: {filter: null, criteria: null},
  paginationParams: {pageSize: 10, pageNumber: 1}
}

export default function usersDetails (state = initialState, action) {
  switch (action.type) {
    case users.GET_USERS:
      state.usersDetails = action.usersDetails.usersList
      state.pageDetails = action.usersDetails.pageDetails
      return {
        ...state
      }
    case users.SET_SORT_PARAMS_USERS:
      state.sortParams = action.sortParams
      return {
        ...state
      }
    case users.SET_SORT_PARAMS_ACTIVITY:
      state.sortParamsActivity = action.sortParams
      return {
        ...state
      }
    case users.GET_ACTIVITY:
      state.activities = action.activity
      return {
        ...state
      }

    case users.SET_SEARCH_PARAMS_USERS:
      state.searchParams = action.searchParams
      return {
        ...state
      }

    case users.SET_USERS_UUID:
      state.uuid = action.uuid
      return {
        ...state
      }

    default:
      return state
  }
}
