import React, { useState, useCallback } from 'react';

import { Header } from './Header';
import { useTrigger } from 'hooks/useTrigger';
import { DevicesList } from './DevicesList';

import './UploadedDevices.scss';

export const UploadedDevices = ({ task, countries }) => {
    const [devices, setDevices] = useState(task.devices);
    const [searchValue, setSearchValue] = useState('');
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger();
    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
    }, [resetFiltersTrigger]);

    return (
        <div className='hme-view-store-settings-task-uploaded-devices'>
            <Header
                count={devices.length}
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                onFiltersReset={onFiltersReset}
                filtersCount={filtersCount}
            />
            <DevicesList
                devices={devices}
                searchValue={searchValue}
                countries={countries}
                resetFiltersTrigger={resetFiltersTrigger}
                filtersCount={filtersCount}
                onFiltersCountChange={setFiltersCount}
            />
        </div>
    );
};
