import React from 'react';
import { ReactComponent as CircleCrossIcon } from 'assets/icons/circle-cross.svg';
import { DeviceUpgradeStatuses } from 'constants/NEXEOUpgradeStatuses';

import './DeviceUpgradeStatusMessage.scss';

export const formatFailedUpgradeMessage = (task, serialNumber) => {
    const failedStatus =
        task.statuses?.[serialNumber]?.[DeviceUpgradeStatuses.UPDATE_FAILED] ||
        task.statuses?.[DeviceUpgradeStatuses.UPDATE_FAILED];

    const { statusCode, message } = failedStatus || {};

    return {
        isFailedMessageAvailable: !!(failedStatus && statusCode && message),
        statusCode,
        message,
    };
};

export const DeviceUpgradeStatusMessage = ({ task, serialNumber }) => {
    // check if download or install failed
    const isUpgradeFailed =
        task.status === DeviceUpgradeStatuses.UPDATE_FAILED &&
        (!task.statuses[DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED] ||
            !task.statuses[DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED]);

    if (!isUpgradeFailed) {
        return null;
    }

    const { isFailedMessageAvailable, statusCode, message } = formatFailedUpgradeMessage(task, serialNumber);

    if (!isFailedMessageAvailable) {
        return null;
    }

    const errorMessage = `${statusCode}: ${message}`;

    return (
        <div className="device-upgrade-status-message">
            <div className="device-upgrade-status-message__icon">
                <CircleCrossIcon />
            </div>
            <div className="device-upgrade-status-message__text">{errorMessage}</div>
        </div>
    );
};
