import { Separator } from 'library/Separator';

import { Export } from './Export';
import { Import } from './Import';

import './FileManageBlock.scss';

export const FileManageBlock = ({
    exportFormat,
    isExportDisabled,
    onExportFormatChange,
    onExport,
    importedFile,
    onImportChange,
    onImport
}) => {
    return (
        <div className='hme-manage-hierarchy__file-manage'>
            <Export
                exportFormat={exportFormat}
                isExportDisabled={isExportDisabled}
                onExportFormatChange={onExportFormatChange}
                onExport={onExport}
            />
            <Separator className='hme-s-size'/>
            <Import value={importedFile} onChange={onImportChange} onImport={onImport} />
        </div>
    );
};
