import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTrigger } from 'hooks/useTrigger';
import { useStatusQueryParams } from 'hooks/useStatusQueryParams';
import { NotificationsList } from 'library/NotificationsList';
import { addErrorNotification } from 'services/Notifications';
import { Loader } from 'library/Loader';
import { Paginate } from 'library/Paginate';
import { useLoadBrandsDeviceUpgradeVersions } from './hooks';
import { formatBrandsDeviceUpgradeVersions } from './helpers';
import { INIT_PAGINATION_VALUES, PAGE_SIZES, INIT_SEARCH_PARAMS, INIT_SORT_SELECTION } from './constants';
import { Header } from './Header';
import { Footer } from './Footer';
import { BrandsList } from './BrandsList';

import './List.scss';

export const List = () => {
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [filtersCount, setFiltersCount] = useState(0);
    const [availableFilters, setAvailableFilters] = useState([]);

    const {
        page,
        perPage: recordsPerPage,
        searchParams,
        appliedFilters,
        sortSelection,
        setPagination,
        setSearchParams,
        setSortSelection,
        setAppliedFilters,
    } = useStatusQueryParams({
        page: INIT_PAGINATION_VALUES.page,
        perPage: INIT_PAGINATION_VALUES.recordsPerPage,
        searchParams: INIT_SEARCH_PARAMS,
        sortSelection: INIT_SORT_SELECTION,
        appliedFilters: {},
    });

    const resetFiltersTrigger = useTrigger();

    const {
        brandsDeviceUpgradeVersions,
        isBrandsDeviceUpgradeVersionsLoading,
        brandsDeviceUpgradeVersionsLoadingError,
    } = useLoadBrandsDeviceUpgradeVersions();

    const formattedBrandsDeviceUpgradeVersions = useMemo(
        () => formatBrandsDeviceUpgradeVersions(brandsDeviceUpgradeVersions),
        [brandsDeviceUpgradeVersions],
    );

    const shouldFooterBeShown = !!(brandsDeviceUpgradeVersions?.length && recordsTotal > PAGE_SIZES[0]);

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
        setAppliedFilters({}, availableFilters);
    }, [resetFiltersTrigger]);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPagination({ page, perPage: recordsPerPage });
    }, []);

    const onSearch = useCallback((searchValue) => {
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            searchValue,
        }));
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: INIT_PAGINATION_VALUES.page,
        }));
    }, []);

    const onFiltersChange = useCallback((filters, availableFilters) => {
        const formattedAvailableFilters = availableFilters.reduce(
            (acc, { property, items }) => ({
                ...acc,
                [property]: items,
            }),
            {},
        );

        setAppliedFilters(filters, formattedAvailableFilters);
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: INIT_PAGINATION_VALUES.page,
        }));
    }, []);

    const paginationData = {
        page,
        recordsPerPage,
    };

    useEffect(() => {
        if (brandsDeviceUpgradeVersionsLoadingError) {
            addErrorNotification(brandsDeviceUpgradeVersionsLoadingError.message);
            return;
        }
    }, [brandsDeviceUpgradeVersionsLoadingError]);

    if (isBrandsDeviceUpgradeVersionsLoading) {
        return (
            <div className="hme-page-component hme-components firmware-management-wrapper loading">
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className="hme-page-component hme-components firmware-management-wrapper">
            <NotificationsList />
            <div className="firmware-management">
                <Header
                    searchValue={searchParams.searchValue}
                    filtersCount={filtersCount}
                    onSearch={onSearch}
                    onFiltersReset={onFiltersReset}
                />
                <BrandsList
                    brands={formattedBrandsDeviceUpgradeVersions}
                    searchValue={searchParams.searchValue}
                    sortSelection={sortSelection}
                    isLoading={isBrandsDeviceUpgradeVersionsLoading}
                    paginationData={paginationData}
                    appliedFilters={appliedFilters}
                    availableFilters={availableFilters}
                    resetFiltersTrigger={resetFiltersTrigger}
                    onFiltersChange={onFiltersChange}
                    onAvailableFiltersChange={setAvailableFilters}
                    onFiltersCountChange={setFiltersCount}
                    onRecordsTotalChange={setRecordsTotal}
                    onPaginationChange={onPaginationChange}
                    onSortChange={setSortSelection}
                />
                {!isBrandsDeviceUpgradeVersionsLoading && shouldFooterBeShown && (
                    <Footer>
                        <Paginate
                            className="firmware-management__list__paginate"
                            page={page}
                            recordsPerPage={recordsPerPage}
                            pageSizes={PAGE_SIZES}
                            total={recordsTotal}
                            onChange={onPaginationChange}
                            hideSinglePage
                        />
                    </Footer>
                )}
            </div>
        </div>
    );
};
