import semver from 'semver';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

// Need initial checks to handle settings versions, which can have invalid formats
export const checkVersionsEquality = (v1, v2) => {
    const version1 = BuildVersions.normalizeVersionString(v1);
    const version2 = BuildVersions.normalizeVersionString(v2);

    if (!semver.valid(version1) && !semver.valid(version2)) {
        return version1 === version2;
    }

    if (!semver.valid(version1) && semver.valid(version2)) {
        return false;
    }

    if (semver.valid(version1) && !semver.valid(version2)) {
        return false;
    }

    return BuildVersions.sameVersion(version1, version2);
};
