import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import './Title.scss';

const defaultVariants = [];

export const TITLE_VARIANTS = {
    BLOCK: 'block',
    SECTION: 'section'
};

export const Title = cond([
    [({ children }) => children, ({ children, variants = defaultVariants, className, as: Tag = 'div' }) => <Tag
        className={classNames(
                'hme-title',
                variants.map((variant) => `hme-title-${variant}`),
                className
        )}
    >{ children }</Tag>],
    [T, () => null]
]);
