import * as distributor from '../../actionTypes/Distributor/Distributor'
// set the distributor redux initialState
const initialState = {
  distributors: [ {
    Username: null,
    Distributor: null,
    Avante: null,
    Subscription: null,
    StartDate: null,
    Enddate: null,
    Company_Name: null,
    Company_ID: 0
  }],
  'pageDetails': [{'RecordCount': null}, {'TotalPages': null}],
  'status': true,
  sortParams: {sortBy: 'User_EmailAddress', sortType: 'ASC'},
  searchParams: {filter: null, criteria: null},
  paginationParams: {pageSize: 10, pageNumber: 1},
  'state': true
}

// call distributorList redux action & change the distributorList  state
export default function distributorList (state = initialState, action) {
  switch (action.type) {
    case distributor.GET_DISTRIBUTOR:
      state.distributors = action.distributors
      state.pageDetails = action.distributors.pageDetails
      return {
        ...state
      }
    case distributor.GET_DISTRIBUTORS:
      state.distributors = action.distributors
      return {
        ...state
        // storeDetails: action.storeDetails
      }
    case distributor.SET_SORT_PARAMS_USERS:
      state.sortParams = action.sortParams
      return {
        ...state
        // storeDetails: action.storeDetails
      }
    case distributor.SET_PAGINATION_PARAMS_USERS:
      state.paginationParams = action.paginationParams
      return {
        ...state
        // storeDetails: action.storeDetails
      }

    case distributor.SET_SEARCH_PARAMS_USERS:
      state.searchParams = action.searchParams
      return {
        ...state
        // storeDetails: action.storeDetails
      }
    default:
      return state
  }
}
