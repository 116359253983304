import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoCompleteInput } from 'library/AutoCompleteInput';

import './LinkFeedForm.scss';

export const feedsResultHeaders = [
    {
        text: '',
        property: 'serviceAccountName',
    },
];

export const LinkFeedForm = ({ feeds, onFormChange }) => {
    const [suggestedFeed, setSuggestedFeed] = useState(null);
    const [feedSearchValue, setFeedSearchValue] = useState('');
    const [filteredFeeds, setFilteredFeeds] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setFilteredFeeds(
            feeds.filter((feed) => feed.serviceAccountName.toLowerCase().includes(feedSearchValue.toLowerCase())),
        );

        // remove the suggested account if the search field has been cleared
        if (!feedSearchValue) {
            setSuggestedFeed(null);
        }
    }, [feedSearchValue]);

    useEffect(() => {
        if (suggestedFeed) {
            setFeedSearchValue(suggestedFeed.serviceAccountName);
            onFormChange({
                serviceAccountID: +suggestedFeed.serviceAccountID,
            });
        }
    }, [suggestedFeed]);

    return (
        <div>
            <div className="hme-feed-form-section">
                <AutoCompleteInput
                    isRequired
                    success={!!suggestedFeed}
                    value={feedSearchValue}
                    suggestions={filteredFeeds}
                    headers={feedsResultHeaders}
                    rowKey="serviceAccountID"
                    className="hme-account-input"
                    placeholder={t('common__service-account-name')}
                    isLoading={false}
                    noSuggestionText={t('dxs_feeds__no-feeds-found')}
                    onChange={setFeedSearchValue}
                    onSuggested={setSuggestedFeed}
                />
            </div>
        </div>
    );
};
