import React from 'react';
import { compose } from 'ramda';

import { useDistributorsAccounts } from './hooks/useDistributorsAccounts';
import { Header } from './Header/Header';
import { List } from './List';
import { Paginate } from 'library/Paginate';
import { withAccess } from 'HOCs/withAccess';
import { withHMELayout } from 'HOCs/withHMELayout';
import authenticate from 'components/Security/Authentication';
import { TileList } from 'library/TileList';
import { checkBaseAccessForAccounts } from 'helpers/distributor';
import { MOBILE_ACCOUNTS_HEADERS } from './constants/mobileAccountsHeders';
import { ACCOUNTS_FILTER_OPTIONS } from './constants/accountsFilterOptions';
import { MOBILE_SORT_OPTIONS } from './constants/mobileSortOptions';

import './DistributorsAccounts.scss';

const PAGE_SIZES = [10, 20, 50, 100];

const DistributorsAccountsComponent = () => {
    const {
        onSearchHandler,
        isDisabled,
        sortSelection,
        onSortChange,
        rows,
        isAccountsLoading,
        availableFilters,
        filters,
        onFiltersChange,
        searchParams,
        onSearchChangeHandler,
        recordsTotal,
        paginationData,
        onPaginateChange,
        filtersCount,
        onFiltersReset,
        setSortModalOpen,
        onFiltersAndSortReset
    } = useDistributorsAccounts();

    return <div className="hme-distributors-accounts">
        <Header
            onSearch={onSearchHandler}
            isDisabled={isDisabled}
            searchParams={searchParams}
            onSearchParamsChange={onSearchChangeHandler}
            filtersCount={filtersCount}
            onFiltersReset={onFiltersReset}
            setSortModalOpen={setSortModalOpen}
        />
        <List
            rows={rows}
            sortSelection={sortSelection}
            onSortChange={onSortChange}
            isLoading={isAccountsLoading}
            availableFilters={availableFilters}
            filters={filters}
            onFiltersChange={onFiltersChange}
        />
        <TileList
            headers={MOBILE_ACCOUNTS_HEADERS}
            rows={rows}
            isLoading={isAccountsLoading}
            noRecordsMessage="accounts__no-accounts"
            rowKey="Account_ID"
            availableFilters={availableFilters}
            filters={filters}
            onFiltersChange={onFiltersChange}
            onFiltersReset={onFiltersReset}
            filtersOptions={ACCOUNTS_FILTER_OPTIONS}
            filtersCount={filtersCount}
            sortSelection={sortSelection}
            onSortChange={onSortChange}
            sortOptions={MOBILE_SORT_OPTIONS}
            onFiltersAndSortReset={onFiltersAndSortReset}
        />
        <Paginate
            total={recordsTotal}
            page={paginationData.pageNumber}
            onChange={onPaginateChange}
            recordsPerPage={paginationData.pageSize}
            pageSizes={PAGE_SIZES}
            className="hme-distributors-accounts--paginate"
        />
    </div>;
};

const hasAccess = checkBaseAccessForAccounts();

export const DistributorsAccounts = compose(
    withHMELayout(),
    authenticate,
    withAccess({
        checkAccess: () => hasAccess
    })
)(DistributorsAccountsComponent);
