import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select } from 'library/Select';

import { updateSettingUnitActionCreator, updateSettingValueActionCreator, useNitroAnnouncementSettings } from '../../state';

const selectVariants = ['no-label'];

const CustomFormField = ({
    description,
    fieldId = '',
    timeUnits,
    label = 'announcement-settings__display-for',
    fieldsSubtitle = '',
    onTimeChange,
    onTimeUnitChange,
    timeValue,
    timeUnitValue,
    isInputBordered = false,
    error
}) => {
    const { t } = useTranslation();

    return (
        <div className="nitro-announcement-settings__field">
            {description && <div className="nitro-announcement-settings__fieldset-description">{t(description)}</div>}

            <label htmlFor={fieldId} className="nitro-announcement-settings__field-label">
                {t(label)}
            </label>

            <div className="nitro-announcement-settings__field-inputs">
                <input
                    type="text"
                    value={timeValue}
                    onChange={(evt) => onTimeChange(evt.target.value)}
                    className={classNames(
                        'nitro-announcement-settings__field-input',
                        {
                            'nitro-announcement-settings__field-input--bordered': isInputBordered
                        }
                    )}
                />

                <Select
                    value={timeUnitValue}
                    onChange={onTimeUnitChange}
                    variants={selectVariants}
                    className="nitro-announcement-settings__field-select"
                >
                    {timeUnits}
                </Select>

                {fieldsSubtitle && <span className="nitro-announcement-settings__field-sublabel">{t(fieldsSubtitle)}</span>}
            </div>

            {error && <div className="nitro-announcement-settings__field-error">{error}</div>}
        </div>
    );
};

export const PeriofFormFieldset = ({ item, fieldsSubtitle, settingType, timeUnits, description, displayPeriodValidator }) => {
    const timeKey = 'DisplayPeriod';
    const timeUnitKey = 'DisplayPeriodUnit';

    const { highlightedErrorFields, dispatchSetSettingsState } = useNitroAnnouncementSettings();

    const handleTimeChange = useCallback((newValue) => {
        const error = displayPeriodValidator(newValue);

        dispatchSetSettingsState(updateSettingValueActionCreator({
            currentSettingType: settingType,
            newValue,
            valueKey: timeKey,
            error
        }));
    }, [displayPeriodValidator, settingType, timeKey]);

    const handleTimeUnitChange = useCallback((newTimeUnit) => {
        const error = displayPeriodValidator(item[timeKey]);

        dispatchSetSettingsState(updateSettingUnitActionCreator({
            currentSettingType: settingType,
            newUnit: newTimeUnit,
            unitKey: timeUnitKey,
            error
        }));
    }, [displayPeriodValidator, settingType, item, timeUnitKey, timeKey]);

    return (
        <CustomFormField
            fieldsSubtitle={fieldsSubtitle}
            label="announcement-settings__display-for"
            error={item.error}
            isInputBordered={Boolean(highlightedErrorFields[settingType])}
            timeUnits={timeUnits}
            description={description}
            timeValue={item[timeKey]}
            timeUnitValue={item[timeUnitKey].value}
            onTimeChange={handleTimeChange}
            onTimeUnitChange={handleTimeUnitChange}
        />
    );
};

export const IntervalFormFieldset = ({ item, settingType, timeUnits, description, displayDurationValidator, displayIntervalValidator }) => {
    const displayForTimeKey = 'DisplayDuration';
    const displayForTimeUnitKey = 'DisplayDurationUnit';
    const hideForTimeKey = 'DisplayInterval';
    const hideForTimeUnitKey = 'DisplayIntervalUnit';

    const { highlightedErrorFields, dispatchSetSettingsState } = useNitroAnnouncementSettings();

    const handleDisplayForTimeChange = useCallback((newValue) => {
        const error = displayDurationValidator(newValue);

        dispatchSetSettingsState(updateSettingValueActionCreator({
            currentSettingType: settingType,
            newValue,
            valueKey: displayForTimeKey,
            error
        }));
    }, [displayDurationValidator, settingType, displayForTimeKey]);

    const handleDisplayForTimeUnitChange = useCallback((newTimeUnit) => {
        const error = displayDurationValidator(item[displayForTimeKey]);

        dispatchSetSettingsState(updateSettingUnitActionCreator({
            currentSettingType: settingType,
            newUnit: newTimeUnit,
            unitKey: displayForTimeUnitKey,
            error
        }));
    }, [displayDurationValidator, settingType, item, displayForTimeKey, displayForTimeUnitKey]);

    const handleHideForTimeChange = useCallback((newValue) => {
        const error = displayIntervalValidator(newValue);

        dispatchSetSettingsState(updateSettingValueActionCreator({
            currentSettingType: settingType,
            newValue,
            valueKey: hideForTimeKey,
            error
        }));
    }, [displayIntervalValidator, settingType, hideForTimeKey]);

    const handleHideForTimeUnitChange = useCallback((newTimeUnit) => {
        const error = displayIntervalValidator(item[hideForTimeKey]);

        dispatchSetSettingsState(updateSettingUnitActionCreator({
            currentSettingType: settingType,
            newUnit: newTimeUnit,
            unitKey: hideForTimeUnitKey,
            error
        }));
    }, [displayIntervalValidator, settingType, item, hideForTimeKey, hideForTimeUnitKey]);

    return (
        <>
            <CustomFormField
                label="announcement-settings__display-for"
                fieldsSubtitle="announcement-settings__and-then"
                isInputBordered={Boolean(highlightedErrorFields[settingType])}
                error={item.error}
                timeUnits={timeUnits}
                description={description}
                timeValue={item[displayForTimeKey]}
                timeUnitValue={item[displayForTimeUnitKey].value}
                onTimeChange={handleDisplayForTimeChange}
                onTimeUnitChange={handleDisplayForTimeUnitChange}
            />

            <CustomFormField
                label="announcement-settings__hide-for"
                error={item.error}
                isInputBordered={Boolean(highlightedErrorFields[settingType])}
                timeUnits={timeUnits}
                timeValue={item[hideForTimeKey]}
                timeUnitValue={item[hideForTimeUnitKey].value}
                onTimeChange={handleHideForTimeChange}
                onTimeUnitChange={handleHideForTimeUnitChange}
            />
        </>
    );
};

export const FeedbackIntervalFormFieldset = ({ item, settingType, timeUnits, description, displayDurationValidator }) => {
    const timeKey = 'DisplayDuration';
    const timeUnitKey = 'DisplayDurationUnit';

    const { highlightedErrorFields, dispatchSetSettingsState } = useNitroAnnouncementSettings();

    const handleTimeChange = useCallback((newValue) => {
        const error = displayDurationValidator(newValue);

        dispatchSetSettingsState(updateSettingValueActionCreator({
            currentSettingType: settingType,
            newValue,
            valueKey: timeKey,
            error
        }));
    }, [displayDurationValidator, settingType, timeKey]);

    const handleTimeUnitChange = useCallback((newTimeUnit) => {
        const error = displayDurationValidator(item[timeKey]);

        dispatchSetSettingsState(updateSettingUnitActionCreator({
            currentSettingType: settingType,
            newUnit: newTimeUnit,
            unitKey: timeUnitKey,
            error
        }));
    }, [displayDurationValidator, settingType, item, timeUnitKey, timeKey]);

    return (
        <CustomFormField
            label="announcement-settings__display-for"
            error={item.error}
            isInputBordered={Boolean(highlightedErrorFields[settingType])}
            timeUnits={timeUnits}
            description={description}
            timeValue={item[timeKey]}
            timeUnitValue={item[timeUnitKey].value}
            onTimeChange={handleTimeChange}
            onTimeUnitChange={handleTimeUnitChange}
        />
    );
};
