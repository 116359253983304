const getArray = (tree) => {
    return tree.reduce((result, child) => {
        result.push(child.item);

        if (!child.children || child.children.length === 0) {
            return result;
        }

        const childItems = getArray(child.children);

        return [...result, ...childItems];
    }, []);
};

export const toItemsArray = (tree) => {
    if (!tree || tree.length === 0) {
        return [];
    }

    return getArray(tree);
};
