import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { useLoadFeed } from '../hooks/useLoadFeed';
import { processCloudAccountLink, processCloudAccountUnlink, processFeedDeletion } from '../Controller';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { Header } from './Header';
import { Footer } from './Footer';
import { InfoSection } from './InfoSection';
import { AccountsSection } from './AccountsSection';
import { FeedFormModal } from '../FeedFormModal';
import { LinkAccountForm } from '../LinkAccountForm';

import './DXSFeed.scss';

const FEEDS_PAGE_URL = '/admin/dxsFeeds';

export const DXSFeed = () => {
    const [accountSearchValue, setAccountSearchValue] = useState('');
    const [linkedAccount, setLinkedAccount] = useState(null);
    const [isFeedModalShown, setIsFeedModalShown] = useState(false);
    const [isConfirmUnlinkModalShown, setIsConfirmUnlinkModalShown] = useState(false);
    const [isConfirmFeedDeleteModalShown, setIsConfirmFeedDeleteModalShown] = useState(false);
    const [unlinkAccountID, setUnlinkAccountID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { feedId } = useParams();
    const { t } = useTranslation();

    const { feed, isFeedLoading, loadFeed } = useLoadFeed(feedId);

    const onFeedModalShow = () => {
        setIsFeedModalShown(true);
    };

    const onFeedModalHide = () => {
        setIsFeedModalShown(false);
        setLinkedAccount(null);
    };

    const onAccountUnlink = (cloudAccountID) => {
        setIsConfirmUnlinkModalShown(true);
        setUnlinkAccountID(cloudAccountID);
    };

    const onConfirmUnlinkModalHide = () => {
        setIsConfirmUnlinkModalShown(false);
        setUnlinkAccountID(null);
    };

    const onConfirmFeedDeleteModalShow = () => {
        setIsConfirmFeedDeleteModalShown(true);
    };

    const onConfirmFeedDeleteModalHide = () => {
        setIsConfirmFeedDeleteModalShown(false);
    };

    const onCancel = () => navigate(FEEDS_PAGE_URL);

    const onAccountLink = useCallback(async () => {
        onFeedModalHide();
        await processCloudAccountLink({
            payload: {
                serviceAccountID: +feedId,
                cloudAccountID: linkedAccount.cloudAccountID,
            },
            setIsLoading,
            t,
        });

        loadFeed(feedId);
    }, [linkedAccount, feedId]);

    const onAccountUnlinkConfirm = useCallback(async () => {
        if (unlinkAccountID) {
            await processCloudAccountUnlink({
                payload: {
                    serviceAccountID: +feedId,
                    cloudAccountID: unlinkAccountID,
                },
                setIsLoading,
                t,
            });

            loadFeed(feedId);
        }
    }, [linkedAccount, feedId, unlinkAccountID]);

    const onFeedDeleteConfirm = useCallback(async () => {
        if (feedId) {
            await processFeedDeletion({
                payload: {
                    serviceAccountID: +feedId,
                },
                setIsLoading,
                navigate,
                t,
            });
        }
    }, [feedId]);

    return (
        <div className="hme-components dxs-feed-wrapper">
            <NotificationsList />
            <div className="dxs-feed">
                {feed ? (
                    <>
                        <div className="dxs-feed-content">
                            <Header
                                accountSearchValue={accountSearchValue}
                                onAccountSearch={setAccountSearchValue}
                                isAccountSearchDisabled={false}
                                onAccountLink={onFeedModalShow}
                            />
                            <div className="dxs-feed-sections">
                                <InfoSection feed={feed} />
                                <AccountsSection
                                    accounts={feed.cloudAccounts}
                                    searchValue={accountSearchValue}
                                    isLoading={isFeedLoading || isLoading}
                                    onAccountUnlink={onAccountUnlink}
                                />
                            </div>
                        </div>
                        <Footer onCancel={onCancel} onDelete={onConfirmFeedDeleteModalShow} isSubmitEnabled={!feed.cloudAccounts.length} />
                    </>
                ) : (
                    <Loader />
                )}
            </div>

            <FeedFormModal
                title={t('dxs-feeds__header__add-new-account-email')}
                show={isFeedModalShown}
                onHide={onFeedModalHide}
                actions={[
                    {
                        children: t('common__cancel'),
                        onClick: onFeedModalHide,
                    },
                    {
                        children: t('common__submit'),
                        variants: ['submit'],
                        disabled: !linkedAccount,
                        onClick: onAccountLink,
                    },
                ]}
            >
                <LinkAccountForm onFormChange={setLinkedAccount} />
            </FeedFormModal>

            <ConfirmPopupComponent
                show={isConfirmUnlinkModalShown}
                onConfirm={onAccountUnlinkConfirm}
                onHide={onConfirmUnlinkModalHide}
                title={t('common__popup--title-confirm')}
                message={t('dxs-feeds__popup__unlink-account')}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />

            <ConfirmPopupComponent
                show={isConfirmFeedDeleteModalShown}
                onConfirm={onFeedDeleteConfirm}
                onHide={onConfirmFeedDeleteModalHide}
                title={t('common__popup--title-confirm')}
                message={t('dxs-feeds__popup__delete-datafeed')}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />
        </div>
    );
};
