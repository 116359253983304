import PropTypes from 'prop-types';

export const historyField = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isChanged: PropTypes.bool,
    isFullWidth: PropTypes.bool
});

export const historyData = PropTypes.shape({
    fields: PropTypes.arrayOf(historyField),
    title: PropTypes.string,
    className: PropTypes.string
});

export const history = PropTypes.arrayOf(
    PropTypes.shape({
        data: PropTypes.arrayOf(historyData),
        changedAt: PropTypes.string
    })
);
