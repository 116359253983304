import { getAccounts } from 'services/Account';
import { taskConfig } from 'constants/applyStoreSettingsTask';

export const loadAccounts = ({
    mask,
    limit,
}) => {
    return getAccounts({
        searchUserEmail: mask,
        limit,
        deviceIsActive: 1,
        deviceTypeId: 1,
        deviceMaxMainVersion: taskConfig.zoom.applySettingsMaxVersion,
        deviceMinMainVersion: taskConfig.zoom.applySettingsMinVersion,
    });
};
