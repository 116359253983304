import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { useSafeDispatch } from 'hooks/useSafeDispatch';
import { useAsync } from 'hooks/useAsync';

import { getAnnouncementSettings, saveAnnouncementSettings } from '../Controller';
import {
    announcementSettingsReducer,
    initialState,
    setHightlightedErrorFieldsActionCreator,
    setSettingActionCreator,
    updateFormModifiedStateActionCreator
} from './reducer';
import { validateFrequencies } from '../helpers';
import { announcementItems } from '../constants';

const NitroAnnouncementSettingsContext = createContext();
NitroAnnouncementSettingsContext.displayName = 'NitroAnnouncementSettingsContext';

export const NitroAnnouncementSettingsContextProvider = ({ children }) => {
    const [{ settings, durationUnits, errors, highlightedErrorFields, isFormModified }, unsafeDispatch] = useReducer(
        announcementSettingsReducer,
        initialState
    );

    const dispatchSetSettingsState = useSafeDispatch(unsafeDispatch);

    const hasErrors = useMemo(() => Object.values(errors).some(Boolean), [errors]);

    const { isLoading: isSettingsLoading, run: fetchNitroSettingsData } = useAsync({ data: initialState });

    const frequenciesError = useMemo(() => validateFrequencies(Object.values(settings)), [settings]);

    useEffect(() => {
        dispatchSetSettingsState(
            setHightlightedErrorFieldsActionCreator({
                errors: {
                    [announcementItems.NOTIFICATION_FREQUENCY]: frequenciesError,
                    [announcementItems.AWARDPAGE]: frequenciesError
                }
            })
        );
    }, [frequenciesError]);

    useEffect(() => {
        fetchNitroSettingsData(
            getAnnouncementSettings()
                .then((data) => {
                    dispatchSetSettingsState(setSettingActionCreator(data));
                })
                .catch((err) => {
                    addErrorNotification(err.message, { autoClose: 5000 });
                })
        );
    }, [fetchNitroSettingsData, dispatchSetSettingsState]);

    const saveSettings = useCallback(
        async (newSettings = []) => {
            try {
                if (frequenciesError) {
                    throw new Error(frequenciesError);
                }

                await fetchNitroSettingsData(
                    saveAnnouncementSettings({ settings: newSettings })
                );

                dispatchSetSettingsState(updateFormModifiedStateActionCreator({
                    modifiedState : false
                }));

                addSuccessNotification('announcement-settings__save--success', { autoClose: 5000 });
            } catch (err) {
                addErrorNotification(err.message, { autoClose: 5000 });
            }
        },
        [frequenciesError, saveAnnouncementSettings]
    );

    const value = useMemo(
        () => ({
            settings,
            durationUnits,
            hasErrors,
            highlightedErrorFields,
            isLoading: isSettingsLoading,
            saveSettings,
            dispatchSetSettingsState,
            isFormModified
        }),
        [
            settings,
            durationUnits,
            hasErrors,
            highlightedErrorFields,
            isSettingsLoading,
            saveSettings,
            dispatchSetSettingsState,
            isFormModified
        ]
    );

    return <NitroAnnouncementSettingsContext.Provider value={value}>{children}</NitroAnnouncementSettingsContext.Provider>;
};

export const useNitroAnnouncementSettings = () => useContext(NitroAnnouncementSettingsContext);
