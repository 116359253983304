import { cond, T } from 'ramda';
import { ZoomNitroForm } from '../ZoomNitroForm';
import { NexeoForm } from '../NexeoForm';

export const deviceTypes = {
    ZOOM_NITRO: 'zoomNitro',
    NEXEO: 'nexeo',
};

const isType = type => ({ deviceType }) => type === deviceType;
const isZoomNitroForm = isType(deviceTypes.ZOOM_NITRO);
const isNexeoForm = isType(deviceTypes.NEXEO);

export const DeviceForm = cond([
    [isZoomNitroForm, ZoomNitroForm],
    [isNexeoForm, NexeoForm],
    [T, () => null]
]);