import React, { useCallback } from 'react';

import { Button } from 'library/Button';

export const Upload = ({ task, onUpload }) => {
    const handler = useCallback(() => onUpload(task), [task, onUpload]);

    return (
        <Button variants={['transparent']} onClick={handler}>Upload</Button>
    );
};
