import React, { Component } from 'react'
import 'url-search-params-polyfill'
import {Tab, Tabs, Modal} from 'react-bootstrap'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { compose } from 'ramda';
import { closePopup } from '../actions/modalAction'
import AuthenticationService from '../../src/components/Security/AuthenticationService'
import { Config } from '../Config'

import withRedirectToDevice from '../HOCs/withRedirectToDevice'
import { getModalStructure, StorePopupType } from './PreInstallModalContainer/modals';
import { components } from './PreInstallModalContainer/constants'
import { checkAccess } from '../actions/permissions'
import { CommonConstants } from '../Constants'

const initialState = {
  show: false,
  key: 1,
  activetab: 'Store Details',
  tabData: [],
}

// ModalContainer- Component
class ModalContainer extends Component {
  constructor (props, context) {
    super(props);
    this.state = {...initialState};

    this.isAdmin = new AuthenticationService(Config.authBaseUrl).isAdmin();

    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  // Select the device
  handleSelect (key) {
    this.setState({ key })
  }

  // Popup close function
  handleClose () {
    this.setState(initialState);
    this.props.dispatch(closePopup());
  }

  // Displaying Store details
  storeTabs (modalStructure) {
    const { t } = this.props;
    const isRemoteConnectionPermission = checkAccess(CommonConstants.userPermissions.RemoteConnect);
    const { viewDevice, stores, stores: {storePopupDetails}} = this.props;
    const existedDevices = storePopupDetails && storePopupDetails['Device_Details'] || [{}];

    return modalStructure.map((tab, index) => {
      const { component, caption, device } = tab;
      if (!tab || !component || !caption) return '';
      const title = t(caption) || caption;
      const Component = components[component];

      return (
        <Tab eventKey={index + 1} title={title} key={index + 1} >
          <Component
            viewDevice={viewDevice}
            isRemoteConnection={isRemoteConnectionPermission}
            isAdmin={stores.storePopUpDetailisAdmin}
            close={this.handleClose}
            device={device}
            existedDevices={existedDevices}
            close={this.handleClose}
          />
        </Tab>
      )
    })
  }

  // Main render function
  render () {
    const {
      storePopUpClient: show,
      storePopupDetails,
      storePopUpDetailisAdmin
    } = this.props.stores;
    const devices = storePopupDetails && storePopupDetails['Device_Details'] || [{}];
    const modalStructure = getModalStructure(StorePopupType.viewStore, devices, storePopUpDetailisAdmin);
    const dialogClassName = `store-modal-popup ${this.isAdmin ? 'adminPopUp' : 'clientPopUp'}`;

    return (
      <Modal show={show} dialogClassName={dialogClassName} onHide={this.handleClose} >
        <Modal.Body className='store-modal-content'>
          <div id='store-tabs'>
            <Tabs
              activeKey={this.state.key}
              onSelect={this.handleSelect}
              className={'custom-margin ' + (storePopUpDetailisAdmin ? 'admin' : 'public')}
            >
              {this.storeTabs(modalStructure)}
            </Tabs>
          </div>
          <div className='merger-popup-close' onClick={this.handleClose}>X</div>
        </Modal.Body>
      </Modal>
    )
  }
}

// get the state details from redux action.
function mapStateToProps (state) {
  return {
    stores: state.StorePopupDetails
  }
}

export default compose(
  connect(mapStateToProps),
  withRedirectToDevice,
  withTranslation()
)(ModalContainer)
