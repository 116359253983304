import { CommonConstants } from 'Constants';

const externalPermissions = CommonConstants.externalPermissions;

export const externalSortingIndexes = [
    externalPermissions.ManageDevicesBasic,
    externalPermissions.ManageDevicesAdvanced,
    externalPermissions.ManageRoles,
    externalPermissions.ManageStoresBasic,
    externalPermissions.ManageStoresAdvanced,
    externalPermissions.ManageUsers,
];
