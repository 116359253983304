import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';
import { Title } from 'library/Title';
import { mobileHeadersType } from '../types';
import { ReactComponent as Icon } from 'assets/icons/down.svg';

import './TileListItem.scss';

// Separate property string by . to get full data path
const getPath = (property) => property.split('.');

export const TileListItem = ({
    item,
    headers,
    onRowClick,
    radioColumn,
    isSelected = false,
    isDisabled = false,
    className = ''
}) => {
    const { t } = useTranslation();
    const { hideActionsLink, Actions } = item;
    const [toggled, setToggled] = useState({});

    const onClickHandler = useCallback(() => {
        if (!isDisabled) {
            onRowClick && onRowClick(item);
        }
    }, [onRowClick, item]);

    const onToggleItem = useCallback((index) => {
        setToggled((prevToggled) => ({
            ...prevToggled,
            [index]: !prevToggled[index],
        }));
    }, []);

    const isColumnShown = (toggleable, index) => !toggleable || (toggleable && toggled[index]);

    return (
        <div
            className={classNames(
                'hme-tile-list-item__wrapper',
                isSelected ? 'hme-tile-item-selected' : '',
                isDisabled ? 'hme-tile-item-disabled' : '',
                className,
                item.tileItemClassName || '',
            )}
        >
            {(radioColumn && item[radioColumn]) ?? item[radioColumn]}
            {!hideActionsLink && (
                // TODO: Research that we can remove this button
                <Button variants={['transparent']} className="hme-tile-list-item__actions-link">
                    {Actions}
                </Button>
            )}
            <div className="hme-tile-list-item__rows__wrapper" onMouseDown={onClickHandler}>
                {headers.map(
                    (
                        { title, toggleable, column, subcolumn, subcolumnField, buttonShowText, buttonHideText },
                        index,
                    ) => (
                        <div className="hme-tile-list-item__rows__column" key={index}>
                            {toggleable && (
                                <div className="hme-tile-list-item__toggle-head">
                                    <Button
                                        variants={['transparent']}
                                        className="hme-tile-list-item__toggle-link"
                                        onClick={() => onToggleItem(index)}
                                    >
                                        {toggled[index]
                                            ? t(buttonHideText || 'common__hide-details')
                                            : t(buttonShowText || 'common__show-details')}
                                        <Icon
                                            className={classNames('hme-tile-list--expand-icon', { up: toggled[index] })}
                                        />
                                    </Button>
                                </div>
                            )}
                            {isColumnShown(toggleable, index) && (
                                <>
                                    {title ? <Title>{t(title)}</Title> : ''}
                                    {(column || []).map(({ text, property }) => (
                                        <div className="hme-tile-list__row" key={property}>
                                            <span className="hme-tile-list__row__name">{t(text)}</span>
                                            <span className="hme-tile-list__row__value">
                                                {path(getPath(property), item) || ' '}
                                            </span>
                                        </div>
                                    ))}

                                    {/* list of subitems */}
                                    {item[subcolumnField] && (
                                        <div className="hme-tile-list__row__subcolumns">
                                            {(item[subcolumnField] || []).map((subitem, index) => (
                                                <div className="hme-tile-list__row__subcolumn" key={index}>
                                                    {(subcolumn || []).map(({ text, property }) => (
                                                        <div className="hme-tile-list__row" key={property}>
                                                            <span className="hme-tile-list__row__name">{t(text)}</span>
                                                            <span className="hme-tile-list__row__value">
                                                                {path(getPath(property), subitem) || ' '}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ),
                )}
            </div>
            {item.tileItemBottomSection && (
                <div className="hme-tile-list__bottom-section">{item.tileItemBottomSection}</div>
            )}
        </div>
    );
};

TileListItem.propTypes = {
    item: PropTypes.shape({
        hideActionsLink: PropTypes.bool,
        Actions: PropTypes.shape({}),
    }).isRequired,
    headers: mobileHeadersType.isRequired,
};
