import { SSO_CERT_LENGTH } from './constants';

const errorCheckForCertificateField = (certFieldChanged, publicCertificatePayload) => {
    if (!publicCertificatePayload) {
        return {
            condition: true,
            message: 'settings_sso_configuration_X509_error_message'
        };
    }

    if (!certFieldChanged) {
        return {
            condition: false
        };
    }

    return {
        condition: publicCertificatePayload.length < SSO_CERT_LENGTH,
        message: 'settings_sso_configuration_X509_length_error_message'
    };
};

export const formDataValidator = ({
    authenticationInfo,
    HMESettings
}) => {
    const { domain, ssoTargetUrl, publicCertificatePayload, logoutRedirect, certFieldChanged } = authenticationInfo;

    const formErrorsConditions = [
        {
            key: 'domain',
            condition: !domain,
            message: 'settings_sso_configuration_domain_error_message'
        },
        {
            key: 'publicCertificateField',
            ...errorCheckForCertificateField(certFieldChanged, publicCertificatePayload)
        },
        {
            key: 'ssoTargetUrl',
            condition: !ssoTargetUrl,
            message: 'settings_sso_configuration_sso_target_url_message'
        },
        {
            key: 'logoutRedirect',
            condition: !logoutRedirect,
            message: 'settings_sso_configuration_logout_redirect_error_message'
        }
    ];

    const formError = formErrorsConditions.reduce(
            (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
            { status: false }
    );

    return formError;
};

export const validateDomain = (e, setDomain) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (!regex.test(e))
        setDomain(e.substring(0, e.length - 1));
    else
        setDomain(e);
};
