import React from 'react';

import { SelectSnapshot } from './SelectSnapshot';

export const SnapshotForm = ({ snapshots, deviceType, params, onParamsChange, isSnapshotsLoading = false }) => (
    <SelectSnapshot
        snapshots={snapshots}
        deviceType={deviceType}
        value={params.settingTemplateID}
        onChange={onParamsChange && (value => onParamsChange({
            ...params,
            settingTemplateID: value,
        }))}
        isSnapshotsLoading={isSnapshotsLoading}
    />
);
