import { Constant } from '@hme-cloud/utility-common';

const { SystemTiers } = Constant;
const { tiers } = SystemTiers;

export const MIN_TEXT_CONNECT_NEXEO_VERSION = '3.3.0';
export const MIN_TEXT_CONNECT_VOICE_ENGINE_VERSION = '3.1';
export const VALID_TEXT_CONNECT_SYSTEM_TIERS = [
    tiers.NEXEO_SINGLE,
    tiers.NEXEO_DUAL,
    tiers.NEXEO_PRO_SINGLE,
    tiers.NEXEO_PRO_DUAL,
];
