import { useEffect, useCallback } from 'react';
import { getMessages } from '../Controller';
import { useAsync } from 'hooks/useAsync';

export const useLoadTextConnectMessages = (params) => {
    const {
        run: runLoadTextConnectMessages,
        data,
        error: textConnectMessagesLoadingError,
        isLoading: isTextConnectMessagesLoading,
    } = useAsync();

    const loadTextConnectMessages = useCallback(
        (params) => {
            runLoadTextConnectMessages(getMessages(params));
        },
        [params],
    );

    useEffect(() => {
        loadTextConnectMessages(params);
    }, [params]);

    return {
        messages: data?.messages || [],
        total: data?.total || 0,
        isTextConnectMessagesLoading,
        textConnectMessagesLoadingError,
        loadTextConnectMessages,
    };
};
