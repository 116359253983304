import { debounce } from 'lodash';
import { getUsers } from 'services/Account';
import { getProfile } from 'services/Auth';
import { accountTypes } from 'constants/accountTypes';
import { getStores, filterStoresByDeviceType, getDeviceSettingsName, findDevices } from 'services/Snapshots';
import { SEARCH_DEVICES_DEBOUNCE_TIMEOUT, INITIAL_DEVICE_SETTINGS_NAME } from './constants';

export const searchAccountsByEmail = async ({ searchValue, setAccounts, setIsAccountsLoading, onDone }) => {
    let formattedAccounts = [];

    try {
        setIsAccountsLoading(true);
        const users = await getUsers({
            searchValue,
            selectValue: 'User_EmailAddress',
        });

        formattedAccounts = users.map(({ User_UID, User_EmailAddress }) => ({
            User_UID,
            User_EmailAddress,
        }));

        // Adding distributor account owner user to the account users list
        const profile = getProfile();

        if (
            profile.IsAccountOwner &&
            profile.Company_Type &&
            profile.Company_Type.toUpperCase() === accountTypes.DISTRIBUTOR
        ) {
            const { User_UID, User_EmailAddress } = profile;

            // add distributor account owner user only if the email address corresponds to the search value
            if (User_EmailAddress.includes(searchValue)) {
                formattedAccounts.push({
                    User_UID,
                    User_EmailAddress,
                });
            }
        }

        setAccounts(formattedAccounts);
    } catch (e) {
        setAccounts([]);
    }

    setIsAccountsLoading(false);
    onDone && onDone(formattedAccounts);
};

export const loadAccountStores = async ({
    account,
    selectedDeviceType,
    setAccountStores,
    setSelectedAccount,
    setIsAccountLoading,
}) => {
    try {
        setIsAccountLoading(true);

        const stores = await getStores(account['User_UID'], selectedDeviceType);
        const accStores = filterStoresByDeviceType(stores, selectedDeviceType);
        setAccountStores(accStores);
        setSelectedAccount(account);
    } catch (e) {
        setAccountStores([]);
        setSelectedAccount(null);
    }

    setIsAccountLoading(false);
};

export const loadDeviceSettingsName = async ({ deviceSerialNumber, setDeviceSettingsName }) => {
    try {
        const generatedDeviceSettingsName = await getDeviceSettingsName({ deviceSerialNumber });
        setDeviceSettingsName(generatedDeviceSettingsName);
    } catch (e) {
        setDeviceSettingsName(INITIAL_DEVICE_SETTINGS_NAME);
    }
};

export const searchDevices = async ({
    deviceIdentifier,
    deviceType,
    setDevices,
    setIsDevicesLoading,
}) => {
    if (!deviceIdentifier) {
        setDevices([]);

        return;
    }

    try {
        setIsDevicesLoading(true);
        const devices = await findDevices({ deviceType, deviceIdentifier });
        setDevices(devices);
        setIsDevicesLoading(false);
    } catch (err) {
        setIsDevicesLoading(false);
        setDevices([]);
    }
};

export const searchDevicesDebounced = debounce(searchDevices, SEARCH_DEVICES_DEBOUNCE_TIMEOUT);
