const { updateSSOInfo } = require('pages/SSOManagement/services/ssoManagementService');
const { SSO_DOMAIN_STATUS_ENUMS } = require('../components/Actions/helpers');

const updateSsoStatus = async (id, email, ssoDomainStatus) => {
    try {
        const response =
        await updateSSOInfo({ id, email, ssoDomainStatus: ssoDomainStatus
            ? SSO_DOMAIN_STATUS_ENUMS.ENABLED : SSO_DOMAIN_STATUS_ENUMS.DISABLED });
        return response;
    } catch (err) {
        console.error('Could not update SSO status', err);
        return err;
    }
};

module.exports = {
    updateSsoStatus
};
