import React from 'react';
import { Section } from 'library/Section';
import { SettingsInfoForm } from './SettingsInfoForm/SettingsInfoForm';
import { accountTypes } from 'constants/accountTypes';

import './SettingsInfoSection.scss';

export const SettingsInfoSection = ({
    permissionType,
    deviceSettingsName,
    deviceSettingsVersionName,
    sectionNumber,
    col,
    settingsInfo,
    formErrors,
    onSettingsInfoChange,
}) => {
    return (
        <Section
            className="create-snapshot-info"
            number={sectionNumber}
            col={col}
            title="settings_snapshots__create_snapshot__title--settings-information"
        >
            <SettingsInfoForm
                permissionType={permissionType}
                deviceSettingsName={deviceSettingsName}
                deviceSettingsVersionName={deviceSettingsVersionName}
                settingsInfo={settingsInfo}
                formErrors={formErrors}
                onSettingsInfoChange={onSettingsInfoChange}
            />
        </Section>
    );
};
