import React, {Component} from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { Chart } from 'react-google-charts'

import { translationKeysMap } from './translationsMap';
import { DateLib } from '@hme-cloud/utility-common';

import mobileLane from '../../images/mobileLane.png'

class Gauge extends Component {
  constructor (props) {
    super(props)
    this.state = {
      title: this.props.title,
      gaugeData: this.props.gaugeData ? this.props.gaugeData : null
    }
    let colors = this.props.colors[0].ColourCode.split('|')
    this.state.userGoalA = colors[0]
    this.state.userGoalB = colors[1]
    this.state.userGoalC = colors[2]
    this.state.timeFormat = this.props.timeFormat
  }
  convertTime (seconds) {
    return new DateLib(seconds * 1000).toUTC().format(DateLib.FORMAT_TYPES.MINUTES_SECONDS);
  }
  isIE (userAgent) {
    userAgent = userAgent || navigator.userAgent
    return (userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident') > -1)
  }
  render () {
    const { t } = this.props;
    if (this.state.gaugeData && this.state.gaugeData.Value !== undefined) {
      return (
        <section className={'customizedGauges dialGauge ' + (this.isIE() ? 'dualLaneIE' : '')}>
          <div className='dialValue'><span>{ this.props.timeFormat === 'ss' ? this.state.gaugeData.Value : this.convertTime(this.state.gaugeData.Value)}</span> </div>
          <Chart
            className='svgs'
            chartType='Gauge'
            data={[
              ['Label', 'Value'],
              ['', this.state.gaugeData.Value]
            ]}
            options={{
              width: 600,
              height: 250,
              greenFrom: 0,
              greenTo: this.state.gaugeData.GoalA,
              yellowFrom: this.state.gaugeData.GoalA,
              yellowTo: this.state.gaugeData.GoalB,
              redFrom: this.state.gaugeData.GoalB,
              redTo: this.state.gaugeData.GoalD,
              max: this.state.gaugeData.GoalD,
              greenColor: this.state.userGoalA,
              yellowColor: this.state.userGoalB,
              redColor: this.state.userGoalC,
              minorTicks: 5
            }}
            graph_id={this.state.title}
            width='100%'
            height='100px'
            legend_toggle
          />
          <div className='dialTitle'> {t(translationKeysMap[this.state.gaugeData.EventType_Name] || this.state.gaugeData.EventType_Name)} </div>
        </section>
      )
    } else {
      return (<div className='mobileLaneSection lane-cont-bot'><img className='mobileLane' src={mobileLane} alt='mobileLane' /></div>)
    }
  }
}

export default compose(
  withTranslation()
)(Gauge);