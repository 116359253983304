import React from 'react'
import { navigationService } from '../../Common/NavigationServices'
import { getDeviceUpgradesSubHeaders } from './getDeviceUpgradesSubHeaders';
import { Link } from 'react-router-dom';

const DeviceUpgradesSubHeader = (props) => {


    if (!props.isAdmin)
        return '';
        
    let getSelectedMenu = navigationService.getSelectedMenu()
    const menus = getDeviceUpgradesSubHeaders();
    const captions = {
        stores: 'stores',
        deviceUpgrades: 'Device Upgrades'
    };
    const active = (menu) => getSelectedMenu === menu ? 'active_tab_sub' : ''
    return (menus.includes(getSelectedMenu) &&
        <div>
            <div className='subMenu menuBar '>
                <div className='subHeaderSection'>
                    <ul className='subHeaders'>
                        {menus.map(menu => (
                            <li key={menu}>
                                <Link
                                    className={'headerMenu ' + active(menu)}
                                    to={navigationService.getSubMenuUrl(menu)}
                                >{captions[menu]}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default DeviceUpgradesSubHeader;
