import { Config } from '../../Config'
import AuthenticationService, { baseAuthService } from '../Security/AuthenticationService'

export default class SecurityService {
  constructor (authService) {
    // TODO: Start use baseAuthService as auth service.
    this.authService = authService || new AuthenticationService(Config.authBaseUrl)
  }
  /// change function to callback to update change permission immediately
  hasAccess (permission, cb) {
    let access = false
      if (this.authService.getProfile().IsAccountOwner === 1) {
        access = true
        if (cb !== undefined) cb(access)
      } else {
        // Getting the permissions from the service and checking.
        // For Non account owner - checking the permissions which stored in local.
        const isAdminPermissions = !this.authService.isMasquerade() && this.authService.isAdmin();
        const permissions = isAdminPermissions ? this.authService.getAdminPermission() : this.authService.getPermission()
        if (permissions && permissions.length > 0) {
          if (permissions.indexOf(permission) > -1) {
            access = true
          } else {
            access = false
          }
          if (cb !== undefined) cb(access)
        }
      }

    return access
  }

  checkUserUUID () {
    if (window.location) {
      const params = new URLSearchParams(window.location.search)
      if (params) {
        const uuid = params.get('uuid') ? params.get('uuid') : null
        if (uuid === 'null' || uuid === null) {
          return false
        } else {
          return true
        }
      }
      return false
    }
    return false
  }
}

export const baseSecurityService = new SecurityService(baseAuthService);
