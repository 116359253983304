import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'library/Button';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { InputComponent } from 'components/Inputs';
import { useOnTrigger } from 'hooks/useTrigger';
import { Calendar } from './Calendar';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { DateLib } from '@hme-cloud/utility-common';

const dateSeparator = ' - ';
const defaultSinglePlaceholder = DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT;
const defaultMultiplePlaceholder = `${defaultSinglePlaceholder}${dateSeparator}${defaultSinglePlaceholder}`;

import './DatePicker.scss';

const getPlacehodler = (placeholder, multiple) => {
    if (typeof placeholder !== 'undefined') {
        return placeholder;
    }

    return multiple ? defaultMultiplePlaceholder : defaultSinglePlaceholder;
};

const getInputValue = (range, dateFormat) => {
    const getDateString = (date) => date.format(dateFormat);
    if (!range) {
        return "";
    }

    if (!range.startDate) {
        const formatted = getDateString(range);
        return formatted;
    }

    return !range.endDate ?
        getDateString(range.startDate) :
        `${getDateString(range.startDate)}${dateSeparator}${getDateString(range.endDate)}`;
}

export const DatePicker = ({
    value = null,
    daysLimit,
    minDate,
    maxDate,
    label,
    months = 2,
    multiple,
    placeholder,
    onChange,
    onApply,
    isDisabled,
    isRequired = false,
    withResetButton = false,
    resetDatePickerTrigger,
    dateFormat = DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT,
    isTypingDisabled = false,
    onValidate,
    ...inputProps
}) => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const [isCalendarShown, setIsCalendarShown] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputPlaceholder, setInputPlaceholder] = useState('');

    // Get date on initial render if it exists
    useEffect(() => {
        if (value) setInputValue(getInputValue(value, dateFormat));
    }, []);

    const onClickHandler = useCallback(() => {
        if (isDisabled) {
            return;
        }

        setIsCalendarShown(true);
    }, [isDisabled, setIsCalendarShown]);

    const onInputChange = useCallback((val) => {
        if (val.length <= dateFormat.length) {
            const date = new DateLib(val);
            date.isValid() ? onChange(date) : onChange('');
            setInputValue(val);
        }

        if (val.length === dateFormat.length) {
            onValidate && onValidate(val);
        }
    }, [onChange, setInputValue]);

    const onOutsideClickHandler = useCallback(() => {
        setIsCalendarShown(false);
        const valForInput = getInputValue(value, dateFormat);
        setInputValue(valForInput);
        if (valForInput) {
            onValidate && onValidate(valForInput);
        }
    }, [value, inputValue, onValidate]);

    const onDateSelect = useCallback((range) => {
        const valForInput = getInputValue(range, dateFormat);
        setInputValue(valForInput);
        onValidate && onValidate(valForInput);
    }, [setInputValue]);

    useEffect(() => {
        if (!multiple) {
            setIsCalendarShown(false);
        }
    }, [inputValue]);

    const onReset = useCallback(() => {
        setInputValue('');
        setIsCalendarShown(false);
        setInputPlaceholder(getPlacehodler(placeholder, multiple));
    }, [setInputValue, setIsCalendarShown, setInputPlaceholder]);

    useOnTrigger(resetDatePickerTrigger, onReset);

    const onResetButtonClick = useCallback(() => {
        onDateSelect && onDateSelect(null);
        onChange && onChange(null);
        onReset();
    }, [onDateSelect, onChange, onReset]);

    const onApplyHandler = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsCalendarShown(false);
        onApply && onApply();
    }, [onApply, setIsCalendarShown]);

    useEffect(() => {
        setInputPlaceholder(getPlacehodler(placeholder, multiple));
    }, [placeholder, multiple]);

    useEffect(() => {
        if (isDisabled) {
            setIsCalendarShown(false);
        }
    }, [isDisabled, setIsCalendarShown]);

    useClickOutSide(ref, onOutsideClickHandler);

    return (
        <div ref={ref} className={classNames('hme-datepicker', withResetButton ? 'hme-datepicker--with-reset' : '')}>
            <InputComponent
                label={label || 'Date Range'}
                variants={['label-inside', 'date-picker']}
                value={inputValue}
                placeholder={inputPlaceholder}
                onClick={onClickHandler}
                isDisabled={isDisabled}
                onChange={onInputChange}
                isRequired={isRequired}
                fromDatePicker={!inputProps.isReadOnly}
                isTypingDisabled={isTypingDisabled || multiple}
                {...inputProps}
            >
                {
                    !inputProps.isReadOnly && <CalendarIcon className="input-datepicker-icon" />
                }
                {
                    isCalendarShown &&
                    <Calendar
                        value={value}
                        daysLimit={daysLimit}
                        months={months}
                        maxDate={maxDate}
                        minDate={minDate}
                        multiple={multiple}
                        onDateSelect={onDateSelect}
                        onChange={onChange}
                        onApply={onApply ? onApplyHandler : null}
                        resetCalendarTrigger={resetDatePickerTrigger}
                    />
                }
            </InputComponent>
            {withResetButton && <Button variants={['transparent']} onClick={onResetButtonClick}>{t('common__clear')}</Button>}
        </div>
    );
};
