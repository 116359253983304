import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import AuthenticationService from '../../Security/AuthenticationService';
import { Config } from '../../../Config';
import { CommonConstants } from '../../../Constants';
import Unregistersystem from '../../Actions/Unregistersystem';
import NavigationService from '../../Common/NavigationServices';
import CommonFunctions from '../../Common/Functions';
import CommonStoreMethods from '../CommonStoreMethod';
import { checkAccess } from '../../../actions/permissions';
import DeviceStatusImage from '../DeviceStatusImage';
import { createSystemPageURI } from '../../../helpers/createSystemPageURI';

import '../Stores.css';
import '../ZoomComponent/ZoomComponent.css';

const {
  deviceType: {
    evd,
  } = {},
} = CommonConstants;

class EVDComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      disableRemove: true,
      deviceIDs: [],
      deviceName: 'ZOOM Nitro/EVD',
    };
    this.navigate = new NavigationService();
    this.commonFunctions = new CommonFunctions();
    this.CommonStoreMethods = new CommonStoreMethods();
    this.enableRemoveBtn = this.enableRemoveBtn.bind(this);
    this.handleViewDevice = this.handleViewDevice.bind(this);
    this.renderSystemStatus = this.renderSystemStatus.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderViewDetails = this.renderViewDetails.bind(this);
    this.authService = new AuthenticationService(Config.authBaseUrl);
    this.state.url = this.authService.getColdFusionAppUrl(this.authService.isAdmin());
    this.state.token = this.authService.getToken();
  }
  handleViewDevice (id) {
    this.props.viewDevice(id);
  }

  // Checks for the enabling the remove system button.
  enableRemoveBtn (e, deviceId, active) {
    const disableRemove = this.commonFunctions.enableRemoveBtn(e, deviceId, active);
    const deviceIDs = this.state.deviceIDs;
    const input = {
      isChecked: e.currentTarget.checked,
      device_ID: deviceId,
      isActive: active
    };
    deviceIDs.push(input);
    this.setState({
      disableRemove,
      deviceIDs,
    });
  }
  renderCheckbox({isAdmin, deviceID, deviceIsActive}) {
    const checkboxAttrs = {
      type: 'checkbox',
      name: 'checkbox',
      id: 'idname',
      className: 'device-check-input',
      value: deviceID,
      onChange: e => this.enableRemoveBtn(e, deviceID, deviceIsActive)
    }
    return isAdmin ? (<td className='device-checkbox'><input {...checkboxAttrs}/></td>) : '';
  }

  renderSystemStatus (device = {}) {
    const { t } = this.props;
    const {
      Device_IsActive: deviceIsActive,
      Device_IsPreconfigured: deviceIsPreconfigured,
    } = device;

    return deviceIsPreconfigured
      ? (<td>
          <span className='device-status deviceStatusText'>{t('common__device__status--pre-configured')}</span>
        </td>)
      : (<td className='device-status deviceStatusText'>
          <DeviceStatusImage isActive={deviceIsActive === 1} />
          <span>
            {this.CommonStoreMethods.renderStatus(device, false)}
          </span>
        </td>);
  }

  renderViewDetails({ deviceUID, deviceTypeID, deviceMainVersion }) {
    const { t } = this.props;

    return (<td className='device-view'>
            <a href={createSystemPageURI({ deviceUID, deviceTypeID, deviceMainVersion })} className='device-view-link'>{t('stores__modal__view-details')}</a>
          </td>)
  }
  renderRows ({ isPreconfigured, isAdmin, evdDevices }) {
    const rows = evdDevices.map(device => {
      const {
        Device_UID: deviceUID,
        Device_ID: deviceID,
        Device_IsActive: deviceIsActive,
        Device_MainVersion: deviceMainVersion,
        Device_SerialNumber: deviceSerialNumber,
        Device_SettingVersion: deviceSettingVersion,
        Device_DeviceType_ID: deviceTypeID,
      } = device;
      return (
        <tr key={deviceUID} className='device-row-data'>
          {this.renderCheckbox({isAdmin, deviceID, deviceIsActive})}
          <td className='device-main-version'>{isPreconfigured ? '' : deviceMainVersion}</td>
          <td className='device-serial-number'>{isPreconfigured ? '' : deviceSerialNumber}</td>
          {this.renderSystemStatus(device)}
          {this.renderViewDetails({ deviceUID, deviceTypeID, deviceMainVersion })}
        </tr>
      );
    });
    return rows;
  }
  getHeaderCaption({ isPreconfigured }) {
    const { t } = this.props;
    const tKey = isPreconfigured ? 'common__device--pre-configured' : 'common__device--registered';

    return t(tKey, { deviceType: t('common__device__type--nitro-evd') });
  }

  // Main render function. and get the Zoom devices value
  render () {
    const {
      deviceIDs,
      disableRemove,
      deviceName,
    } = this.state;
    const {
      existedDevices: deviceDetails = [],
      stores:
        {
          storeDetails: {
            User_EmailAddress: userEmailAddress,
            Store_UID: storeUID,
          } = {},
        } = {}
    } = this.props;

    const deviceList = this.commonFunctions.removeDuplicateDevices(deviceIDs);
    const isAdmin = this.authService.isAdmin();
    const evdDevices = deviceDetails.filter(({ Device_DeviceType_ID: deviceType } = {}) => deviceType === evd.id);

    const devicesOnline = evdDevices.filter(d => d.Device_IsActive);
    const devicesPreconfigured = evdDevices.filter(d => d.Device_IsPreconfigured);

    let isPreconfigured = false;
    if (devicesPreconfigured.length > 0 && devicesOnline.length === 0) {
      isPreconfigured = true;
    }

    const headerCaption = this.getHeaderCaption(({ isPreconfigured }));
    const deviceListHeaders = this.CommonStoreMethods.renderDeviceListHeaders(false);
    const deviceListRows = this.renderRows({ isPreconfigured, isAdmin, evdDevices });
    return (
      <div className='device-settings-zoom'>
        <h4 className='header'>{headerCaption}</h4>
        <table className='device-list-table'>
          <tbody>
            {deviceListHeaders}
            {deviceListRows}
          </tbody>
        </table>
        <div className={'remove-sys ' + (isAdmin && checkAccess(CommonConstants.adminPermissions.RemoveDevice) ? 'show' : 'hide')}>
          <Unregistersystem
            userEmail={userEmailAddress}
            system={deviceDetails}
            deviceList={deviceList}
            display={disableRemove}
            deviceName={deviceName}
            deviceStoreUID={storeUID} />
        </div>
      </div>
    );
  }
}

// Setting the state from redux store.
function mapStateToProps (state) {
  return {
    stores: state.StorePopupDetails.storePopupDetails
  };
}

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(EVDComponent);
