import React from 'react';
import { cond, T } from 'ramda';

import { useTranslation } from 'react-i18next';

import { CenterLoader } from 'components/Common/CenterLoader';
import { CenterErrorMessage } from 'components/Common/CenterMessages';
import { InfoSection } from './InfoSection';
import { DevicesSection } from './DevicesSection';
import { Header } from '../Header';

import './DeviceStatus.scss';

export const Status = ({ device, deviceSettings, deviceScheduledUpgrade, peripheralDevices, storeCountry, onDeviceReboot, onDeviceReconnect }) => {
    return (<div className='hme-admin-evd-system-status hme-page-component'>
        <Header
            device={device}
            deviceSettings={deviceSettings}
            peripheralDevices={peripheralDevices}
            onDeviceReboot={onDeviceReboot}
            onDeviceReconnect={onDeviceReconnect}
        />
        <div className='hme-admin-evd-system-status-device-status'>
            <InfoSection
                device={device}
                storeCountry={storeCountry}
                deviceSettings={deviceSettings}
                deviceScheduledUpgrade={deviceScheduledUpgrade}
            />
            <DevicesSection device={device} />
        </div>
    </div>);
};

export const DeviceStatus = cond([
    [({ isLoading }) => isLoading, () => {
        const { t } = useTranslation();

        return <div className='hme-admin-system-status'>
            <CenterLoader>{t('system-status__message__loading-device')}</CenterLoader>
        </div>
    }],
    [({ error }) => error !== null, () => {
        const { t } = useTranslation();

        return <div className='hme-admin-system-status'>
            <CenterErrorMessage>{t('system-status__message__loading-device-error')}</CenterErrorMessage>
        </div>
    }],
    [T, (props) => <Status {...props} />]
]);
