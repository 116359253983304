import React, { useState, useEffect, useCallback } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';

import { Toggle } from 'library/Toggle';
import { DateSelector } from 'library/DateSelector';
import { Label } from 'library/Label';
import { DESKTOP_RESOLUTION, MOBILE_RESOLUTION } from 'constants/screenResolutions';
import { DateLib } from '@hme-cloud/utility-common';

import './DateFilter.scss';

const DEFAULT_DATE_RANGE_VALUE = 30;
const DAYS_IN_DATE_RANGE = 90;
const MONTHS_TO_SHOW = 2;
const MOBILE_MONTHS_TO_SHOW = 1;

const defaultItems = [{
    text: t('common__last-30-days'),
    value: 30,
}, {
    text: t('common__last-7-days'),
    value: 7,
}, {
    text: t('common__date-range'),
    value: -1,
}];

export const DateFilter = ({
    label,
    defaultToggleValue = DEFAULT_DATE_RANGE_VALUE,
    items = defaultItems,
    isDisabled = false,
    className,
    onDateRangeChange,
    datePickerProps,
}) => {
    const [toggleValue, setToggleValue] = useState(defaultToggleValue);
    const [dates, setDates] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    const onDateApply = useCallback(() => {
        onDateRangeChange(dates);
    }, [dates, onDateRangeChange]);

    const onScreenSizeChange = useCallback(() => {
        setIsTablet(window.innerWidth >= MOBILE_RESOLUTION && window.innerWidth < DESKTOP_RESOLUTION);
        setIsMobile(window.innerWidth < MOBILE_RESOLUTION);
    }, []);

    useEffect(() => {
        setDates(null);

        if (toggleValue === -1) {
            return;
        }

        onDateRangeChange({
            startDate: new DateLib().addDays(-toggleValue),
            endDate: new DateLib(),
        });
    }, [toggleValue, setDates, onDateRangeChange]);

    useEffect(() => {
        window.addEventListener('resize', onScreenSizeChange);
        return () => {
            window.removeEventListener('resize', onScreenSizeChange);
        };
    }, [onScreenSizeChange]);

    useEffect(() => { onScreenSizeChange();}, [window.innerWidth]);

    return (
        <div className={classNames('hme-admin-header-date-filter', className)}>
            {
                label && <Label className='hme-admin-header-date-filter-label'>{label}</Label>
            }
            <div className='hme-admin-header-date-filter-content'>
                <Toggle value={toggleValue} onChange={setToggleValue} isDisabled={isDisabled}>{items}</Toggle>
                {
                    toggleValue === -1 &&
                    <DateSelector
                        multiple={true}
                        months={isMobile ? MOBILE_MONTHS_TO_SHOW : MONTHS_TO_SHOW}
                        daysLimit={DAYS_IN_DATE_RANGE}
                        value={dates}
                        isDisabled={isDisabled}
                        maxDate={new Date()}
                        withResetButton={isMobile || isTablet}
                        {...datePickerProps}
                        onChange={setDates}
                        onApply={onDateApply}
                    />
                }
            </div>
        </div>
    );
};
