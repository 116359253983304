import { useState, useCallback, useMemo } from 'react';

import { useAsync } from 'hooks/useAsync';
import { getAccountData } from 'services/Account';
import { isPartner } from 'services/Auth';

const DEFAULT_ACCOUNT_DATA = {
    userEmail: '',
    changeEmail: '',
    roleName: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    officeNumber: '',
    mobilePhone: '',
    fax: '',
    store: {
        stores: ''
    }
};

export const useAccount = (initialState = DEFAULT_ACCOUNT_DATA) => {
    const { data: fetchedAccountData, error, isLoading, run } = useAsync(initialState);
    const [accountData, setAccountData] = useState(initialState);

    const isPartnerUser = useMemo(isPartner, []);

    const loadAccount = useCallback(() => run(getAccountData(isPartnerUser).then((data) => {
        setAccountData({
            ...data,
            changeEmail: data.userEmail
        });
        return data;
    })), [run]);

    return { fetchedAccountData, accountData, error, isLoading, loadAccount, setAccountData };
};
