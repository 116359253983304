import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button } from 'library/Button';
import { InputComponent } from 'components/Inputs/InputComponent';

import { useExtensions } from './hooks/useExtensions';
import { useOnFileChange } from './hooks/useOnFileChange';
import { useSetFileNames } from './hooks/useSetFileNames';

import './FilePlainInput.scss';

const defaultVariants = [];

export const FilePlainInput = ({
    label,
    value,
    variants = defaultVariants,
    inputVariants,
    className,
    extensions = '*',
    onChange
}) => {
    const { t } = useTranslation();
    const fileRef = useRef();
    const [names, setNames] = useState(['']);

    const {
        accept,
    } = useExtensions(extensions);

    const { onFileChange } = useOnFileChange({ fileRef, onChange });

    useSetFileNames({ value, setFileNames: setNames });

    return (
        <div className={classNames(
            'hme-file-plain-input',
            className,
            variants.map((variant) => `hme-file-plain-input--${variant}`)
        )}>
            <InputComponent
                className='hme-file-plain-input__text-input'
                label={label}
                value={names[0]}
                variants={inputVariants}
                readOnly={true}
            >
                <input
                    ref={fileRef}
                    type='file'
                    className='hme-file-plain-input__file'
                    accept={accept}
                    // Need to hide default title for input
                    title=' '
                    onChange={onFileChange}
                />
                <Button>{t('file-select__choose-file')}</Button>
            </InputComponent>
        </div>
    );
};
