import { DateLib } from '@hme-cloud/utility-common';

export const getScheduleTime = (deviceUpgradeDateTime) => {
    const dateLib = new DateLib();
    const startOfDay = dateLib.startOfDay();
    const initialStartTime = [];
    const initialEndTime = [];
    const scheduleTime = [];

    for (let i = 0; i < 24; i++) {
        const text = startOfDay.format(DateLib.FORMAT_TYPES.FULL_HOURS_MINUTES); // '11:00 PM'
        const value = startOfDay.format(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD); // '23 00 PM'


        scheduleTime.push({ text, value });
        initialEndTime.push({ text, value, disabled: value === deviceUpgradeDateTime?.deviceUpgradeTimeFrom });
        initialStartTime.push({ text, value, disabled: value === deviceUpgradeDateTime?.deviceUpgradeTimeTo });
        startOfDay.addHours(1);
    }

    return { scheduleTime, initialStartTime, initialEndTime };
};
