import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { Grid } from 'components/Common/Grid';
import { Actions } from '../Actions';
import { DateLib } from '@hme-cloud/utility-common';
import { FORMAT_TYPES } from 'helpers/DateLib/constants';

export const headers = [
    {
        text: 'settings_sso_configuration_admin__sso-management__domain-name',
        property: 'domain'
    },
    {
        text: 'common__created-date-n-time',
        property: 'createdAt'
    },
    {
        text: 'common__created-by',
        property: 'createdBy'
    },
    {
        text: 'common__updated-date-n-time',
        property: 'updatedAt'
    },
    {
        text: 'common__actions',
        property: 'actions',
        flex: 1.2,
        headerClassName: 'hme-grid-centered-cell'
    }
];

const getFormattedDate = (date, dateFormat = FORMAT_TYPES.DEFAULT) => date ? new DateLib(date).formatInTimeZone(dateFormat) : '';

export const List = ({ ssoPaginatedList, handleConfirmRemoveClick, setDelCertKey, ...props }) => {
    const [rows, setRows] = useState([]);
    const [isLoadingModalShown, setIsLoadingModalShown] = useState(false);

    const openModal = useCallback(() => {
        setIsLoadingModalShown(true);
    }, [ssoPaginatedList]);

    const onRemoveCancel = useCallback(() => {
        setIsLoadingModalShown(false);
    }, [ssoPaginatedList]);

    const generateRow = useCallback(() => {
        const tempRows = [];
        ssoPaginatedList.forEach((sso, i) => {
            tempRows.push({
                id: `sso-${i}`,
                domain: sso.domain,
                createdAt: getFormattedDate(sso.createdAt, FORMAT_TYPES.DATE_AND_TIME),
                createdBy: sso.createdBy,
                updatedAt: getFormattedDate(sso.updatedAt, FORMAT_TYPES.DATE_AND_TIME),
                actions: <Actions
                    openModal={openModal}
                    setDelCertKey={setDelCertKey}
                    email={sso.email}
                    domain={sso.domain}
                    id={sso.id}
                    ssoConfigStatus={sso.ssoConfigStatus}
                    ssoDomainStatus={sso.ssoDomainStatus}
                    certKeyName={sso.certKeyName}
                />
            });
        });
        return tempRows;
    }, [ssoPaginatedList, openModal, setDelCertKey]);

    useEffect(() => {
        if (ssoPaginatedList) {
            setRows(generateRow());
        }
    }, [ssoPaginatedList, generateRow]);

    const { t } = useTranslation();

    return (
        <>
            <Grid
                rows={rows}
                headers={headers}
                noRecordsMessage={t('admin__sso-management__not-found')}
                rowKey="id"
                className="hme-sso-management-page__list"
                {...props}
            />
            <ConfirmPopupComponent
                show={isLoadingModalShown}
                message={t('settings_sso_configuration_admin__sso-management__list__confirm--remove')}
                onConfirm={handleConfirmRemoveClick}
                onHide={onRemoveCancel}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />
        </>
    );
};
