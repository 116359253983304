/* eslint-disable require-jsdoc */
import React, { useCallback } from 'react';
import { T, compose, cond } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { BUTTON_VARIANTS, Button } from 'library/Button';
import { NotificationsList } from 'library/NotificationsList';
import { SEPARATOR_VARIANTS, Separator } from 'library/Separator';
import { withHMELayout } from 'HOCs/withHMELayout';
import { CenterLoader } from 'components/Common/CenterLoader';

import { FORM_SECTIONS, announcementItems } from './constants';
import { SettingsFactory } from './SettingsFactory';
import { NitroAnnouncementSettingsContextProvider, useNitroAnnouncementSettings } from './state/NitroAnnouncementSettingsContext';

import './NitroAnnouncementSettings.scss';

const seperatorVariants = [SEPARATOR_VARIANTS.PURE];

function Section({ part, settings, durationUnits }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="nitro-announcement-settings__section">
                <Title as="h3" className="nitro-announcement-settings__section-title">{t(part.title)}</Title>

                {Object.keys(settings).map((settingType) => {
                    const currentSettings = settings[settingType];

                    // filter out item not required for current form part (Notifications/Presentation)
                    const required = part.items.includes(settingType);

                    let timeUnits = null;

                    // add check to remove seconds from all other dropdown except feedback
                    if (settingType !== announcementItems.CONTEXTUAL_FEEDBACK) {
                        timeUnits = durationUnits.filter((x) => x.value !== 'SECOND');
                    } else {
                        timeUnits = durationUnits.filter((x) => x.value !== 'HOUR');
                    }

                    return required ? (
                        <SettingsFactory
                            key={settingType}
                            timeUnits={timeUnits}
                            currentSettings={currentSettings}
                            settingType={settingType}
                        />
                    ) : null;
                })}
            </div>

            <Separator className="nitro-announcement-settings__section-separator" variants={seperatorVariants} />
        </>
    );
}

const NitroAnnouncementSettingsUI = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings, durationUnits, saveSettings, hasErrors, isFormModified } = useNitroAnnouncementSettings();

    const handleSubmit = useCallback((evt) => {
        evt.preventDefault();

        if (hasErrors) {
            return;
        }

        saveSettings(Object.values(settings));
    }, [hasErrors, saveSettings, settings]);

    const handleCancelClick = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <div className="nitro-announcement-settings">
            <Title as="h2" className="nitro-announcement-settings__page-title">{t('common__announcement-settings')}</Title>

            <form
                onSubmit={handleSubmit}
                className="nitro-announcement-settings__form"
            >
                <div className="nitro-announcement-settings__fields-wrapper">
                    {FORM_SECTIONS.map((part, index) => (
                        <Section key={index} part={part} settings={settings} durationUnits={durationUnits} />
                    ))}
                </div>

                <div className="nitro-announcement-settings__footer">
                    <Button type="button" onClick={handleCancelClick}>{t('common__cancel')}</Button>
                    <Button type="submit" variants={[BUTTON_VARIANTS.SUBMIT]} disabled={hasErrors || !isFormModified}>{t('common__save')}</Button>
                </div>
            </form>
        </div>
    );
};

const NitroAnnouncementSettingsComponent = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <NitroAnnouncementSettingsUI {...props} />]
]);

const NitroAnnouncementSettingsState = () => {
    const { isLoading } = useNitroAnnouncementSettings();

    return (
        <>
            <NotificationsList />

            <NitroAnnouncementSettingsComponent
                isLoading={isLoading}
            />
        </>
    );
};

const NitroAnnouncementSettingsStateProvider = () => {
    return (
        <NitroAnnouncementSettingsContextProvider>
            <NitroAnnouncementSettingsState />
        </NitroAnnouncementSettingsContextProvider>
    );
};

export const NitroAnnouncementSettings = compose(
    withTranslation(),
    withHMELayout({
        contentClasses: ['nitro-announcement-settings-page']
    })
)(NitroAnnouncementSettingsStateProvider);
