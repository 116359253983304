import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { getLastUpgradeDate } from '../helpers';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

export const PERIPHERAL_GRID_OPTIONS = [
    {
        text: 'common__device__type--nexeo-device',
        property: 'modelName',
        flex: 1.3,
        className: 'hme-grid-model-name hme-grid-cell--borderless'
    },
    {
        text: 'common__device__serial-number',
        tabletScreenText: 'common__device__serial-number--text',
        flex: 1.4,
        property: 'serialNumber'
    },
    {
        text: 'system-status__mac-address',
        property: 'macAddress',
        flex: 1.5
    },
    {
        text: 'system-status__last-upgrade',
        property: 'lastUpgradeDate',
        flex: 0.9,
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        property: 'mainVersion',
        flex: 0.9,
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__latest-firmware-available',
        property: 'latestFirmwareAvailable',
        flex: 0.9,
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        property: 'onLatestVersion',
        flex: 0.9,
        className: 'hme-grid-centered-cell hme-grid-cell--uppercase',
        headerClassName: 'hme-grid-centered-cell'
    }
];

const deviceToRow = ({ data, device }) => {
    const { lastUpgradeTask } = data.upgradeTasks;
    const onLatestVersion =
        !data.maxUpgradeVersion ||
        (device.mainVersion && BuildVersions.semverGte(device.mainVersion, data.maxUpgradeVersion)) ? (
            <Trans i18nKey="common__yes" />
        ) : (
            <Trans i18nKey="common__no" />
        );

    return {
        serialNumber: device.serialNumber,
        macAddress: device.macAddress,
        lastUpgradeDate: getLastUpgradeDate(lastUpgradeTask),
        mainVersion: device.mainVersion,
        latestFirmwareAvailable: data.maxUpgradeVersion,
        onLatestVersion
    };
};

export const usePeripheralDevices = ({ data }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(data.devices?.map((device) => deviceToRow({ data, device })) || []);
    }, [data]);

    return { rows, headers: PERIPHERAL_GRID_OPTIONS };
};
