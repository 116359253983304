import React from 'react';
import { useTranslation } from 'react-i18next';

import { getTokenNameTranslation } from 'constants/token';

export const APISCell = ({ apis = [] }) => {
    const { t } = useTranslation();
    if (Array.isArray(apis)) {
        return apis.map((api) => <div key={api}>{t(getTokenNameTranslation(api))}</div>);
    }

    return <div>{t(getTokenNameTranslation(apis))}</div>;
};
