import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { isValidStatus, isInvalidStatus } from '../rules';

import { ReactComponent as SuccessIcon } from 'assets/icons/right.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/wrong.svg';

import './Rule.scss';

export const Rule = ({ tKey, rule, password, confirm }) => {
    const status = useMemo(() => rule(password, confirm), [rule, password, confirm]);
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-reset-password__rule', {
            'hme-reset-password__rule--valid': isValidStatus(status),
            'hme-reset-password__rule--invalid': isInvalidStatus(status),
        })}>
            <div className='hme-reset-password__rule__icon--neutral'></div>
            <SuccessIcon className='hme-reset-password__rule__icon--valid' />
            <CrossIcon className='hme-reset-password__rule__icon--invalid' />
            <span className='hme-reset-password__rule__label'>{t(tKey)}</span>
        </div>
    );
};
