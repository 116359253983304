import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { equals } from 'ramda';

import { trimValues } from 'helpers';
import { getDistributorUser, editDistributorUser, removeDistributorUser } from 'services/Users';
import { getDistributorRoles } from 'services/Roles';
import { getDistributorGroups } from 'services/Groups';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { getQueryString } from 'helpers/distributor';
import { getStoresTree } from '../../Common/helpers/getStoresTree';
import { getGroupStores } from '../../Common/helpers/getGroupStores';
import { findGroups } from '../../Common/helpers/findGroups';
import { isUserValid, trimmedKeys, validateUserData } from '../../Common/validators/user';

const load = async (accountUuid, userUid, setUser, setRoles, setStoresTree, setGroupStores, setIsLoading) => {
    setIsLoading(true);
    try {
        const userPromise = getDistributorUser({
            accountUuid,
            userUid,
        });

        const rolesPromise = getDistributorRoles({ accountUuid });
        const groupsPromise = getDistributorGroups({ accountUuid });

        const [user, roles, groups] = await Promise.all([userPromise, rolesPromise, groupsPromise]);

        setUser({
            uid: user.uuId,
            userName: user.userEmail,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.userRole,
            stores: user.storeIds,
            isActive: user.isActive,
        });

        setRoles(roles.map(({ Role_UID, Role_Name }) => ({
            value: Role_UID,
            text: Role_Name
        })));

        if (groups) {
            setStoresTree(getStoresTree(groups));
            setGroupStores(getGroupStores(groups));
        } else {
            setStoresTree([]);
            setGroupStores({});
        }
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

const saveUser = async (accountUuid, user, groupStores, queryParams, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await editDistributorUser({
            user: {
                ...trimValues(user, trimmedKeys),
                groups: findGroups(user.stores, groupStores),
            },
            accountUuid,
        });

        const search = getQueryString(queryParams);

        navigate(`/accounts/users${search}`);
        setTimeout(() => {
            addSuccessNotification('add-user__success--updated');
        }, 1000);
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

const removeUser = async (accountUuid, userUid, queryParams, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await removeDistributorUser({ accountUuid, userUid });
        const search = getQueryString(queryParams);

        navigate(`/accounts/users${search}`);
        setTimeout(() => {
            addSuccessNotification('common__success--user-deleted');
        }, 1000);
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

export const useEditUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [storesTree, setStoresTree] = useState([]);
    const [user, setUser] = useState({
        uid: null,
        userName: '',
        firstName: '',
        lastName: '',
        role: '',
        stores: [],
        isActive: 1,
    });
    const [originUser, setOriginUser] = useState(null);
    const [groupStores, setGroupStores] = useState({});
    const [validationErrors, setValidationError] = useState({});

    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const auuid = useMemo(() => queryParams.get('auuid'), [queryParams]);
    const uuid = useMemo(() => queryParams.get('uuid'), [queryParams]);
    const { t } = useTranslation();

    const title = useMemo(() => { return originUser ? `${t('add-user__title--edit', {
        firstName: originUser.firstName,
        lastName: originUser.lastName,
    })}` : t('users__header__add-user') }, [originUser, t]);

    const isSaveButtonDisabled = useMemo(() => !isUserValid(user) ||
        equals(
            trimValues(originUser, trimmedKeys),
            trimValues(user, trimmedKeys)
        ), [user, originUser]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onSave = useCallback(() => {
        const errorData = validateUserData(user);
        if (errorData) {
            return setValidationError(errorData);
        }
        setValidationError({});
        return saveUser(auuid, user, groupStores, queryParams, navigate, setIsLoading, setValidationError);
    }, [auuid, user, groupStores, queryParams, navigate, setIsLoading]);

    const onRemove = useCallback(() => {
        removeUser(auuid, user.uid, queryParams, navigate, setIsLoading);
    }, [auuid, user.uid, queryParams, navigate, setIsLoading]);

    useEffect(() => {
        load(auuid, uuid, setOriginUser, setRoles, setStoresTree, setGroupStores, setIsLoading);
    }, [auuid, uuid, setOriginUser, setIsLoading]);

    useEffect(() => {
        setUser({
            ...originUser
        });
    }, [originUser, setUser])

    return {
        title,
        hasRemove: true,
        isLoading,
        user,
        roles,
        storesTree,
        isSaveButtonDisabled,
        showStatus: true,
        onUserChange: setUser,
        onCancel,
        onSave,
        onRemove,
        validationErrors
    };
};
