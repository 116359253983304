import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAsync } from 'hooks/useAsync';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { useLoadRegions } from 'hooks/useLoadRegions';
import { loadPartner } from 'services/Partner';
import { addErrorNotification } from 'services/Notifications';

import { useLoadPartnersType } from '../../../../Common/hooks/useLoadPartnersType';

export const useLoadPartner = ({ countryId }) => {
    const {
        isLoading,
        run,
        data: partner,
        error: partnerError,
    } = useAsync();

    const {
        countries,
        isCountriesLoading,
        countriesLoadingError
    } = useLoadCountries();

    const {
        regions,
        isRegionsLoading,
        regionsLoadingError,
    } = useLoadRegions(countryId);

    const {
        isLoading: isTypesLoading,
        types,
        error: typesError
    } = useLoadPartnersType();

    const { partnerUID } = useParams();

    const hasNetworkError = !!countriesLoadingError || !!regionsLoadingError || !!partnerError || typesError;

    useEffect(() => {
        if (!partnerUID) {
            return;
        }

        run(loadPartner(partnerUID).then((partner) => {
            partner.types = partner.types.map(({ id }) => id);

            return partner;
        }));
    }, [partnerUID]);

    useEffect(() => {
        if (hasNetworkError) {
            addErrorNotification('common__error--unknown');
        }
    }, [hasNetworkError]);

    return {
        isLoading: isLoading || isCountriesLoading || isTypesLoading,
        isRegionsLoading: isRegionsLoading,
        types,
        countries,
        regions,
        partner,
    };
};
