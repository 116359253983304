import { useEffect, useCallback } from 'react';
import { useAsync } from './useAsync';
import { getVaioProviders } from 'services/StoreFeatures';

export const useLoadVaioProviders = () => {
    const {
        run: runLoadVaioProviders,
        isLoading: isVaioProvidersLoading = false,
        data: vaioProviders,
        error: vaioProvidersLoadingError,
    } = useAsync();

    const loadVaioProviders = useCallback(async () => {
        runLoadVaioProviders(getVaioProviders());
    }, []);

    useEffect(() => { loadVaioProviders(); }, []);

    return { vaioProviders, isVaioProvidersLoading, vaioProvidersLoadingError, loadVaioProviders };
};
