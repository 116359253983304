import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';
import StoreDetailsComponent from '../components/Stores/StoreDetailsComponent'
import { connect } from 'react-redux'
import AuthenticationService from '../components/Security/AuthenticationService'
import { Config } from '../Config'
import { initStoreDetail } from '../actions/modalAction'
import { bindActionCreators } from 'redux'
import { CommonConstants } from '../Constants'
import 'url-search-params-polyfill'
import ZOOMIcon from '../images/icon_ZOOM.png'
import CIBIcon from '../images/icon_CIB.png'
import LinkWearIcon from '../images/icon_LinkWear.svg'
import { Row, Col, Table } from 'reactstrap'
import './ViewDetails.css'
import { checkAccess, getPermission } from '../actions/permissions'
import CommonLoader from '../components/Alerts/CommonLoader'
import NavigationService from '../components/Common/NavigationServices'
import DeviceStatusImage from '../components/Stores/DeviceStatusImage'
import getFilteredStoreDevices from '../helpers/Store/getFilteredStoreDevices';
import ErrorMessage from '../components/ErrorBoundary/ErrorMessage';
import { Link } from 'react-router-dom';
import { createSystemPageURI } from 'helpers/createSystemPageURI';
import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';

const { deviceType } = CommonConstants;

class StoreContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isRemoteConnectionPermission: false,
      showCommonLoader: true,
      noAccessError: false
    }
    this.authService = new AuthenticationService(Config.authBaseUrl)
    this.navigate = new NavigationService()
    this.handleClick = this.handleClick.bind(this)
    this.renderSystemImage = this.renderSystemImage.bind(this)
    this.checkSystemStatus = this.checkSystemStatus.bind(this)
    this.state.url = this.authService.getColdFusionAppUrl(this.authService.isAdmin())
    this.state.token = this.authService.getToken()
    props.getPermission()
  }

  handleClick (did) {
      const { queryParams } = this.props;
      const uuid = queryParams.get('uuid');
    if (uuid) {
      window.location.href = '/settings/stores/viewDetail?duid=' + did + '&uuid=' + uuid
    } else {
      window.location.href = '/settings/stores/viewDetail?duid=' + did
    }
  }

  UNSAFE_componentWillMount () {
      const { queryParams } = this.props;
      const contextToken = queryParams.get('suid');
    this.props.initStoreDetail(contextToken, false, (data) => {
      this.setState({ showCommonLoader: false })
      if ( data && data.key == 'noAccess') {
        this.setState({ noAccessError: true });
      }
    })
  }

  renderHTTP (url) {
    return url.replace('https', 'http')
  }

  checkSystemStatus (data, isRemoteConnectionPermitted) {
    const {
      Device_IsPreconfigured: isPreconfigured,
      Device_IsActive: isActive,
    } = data

    return (
      <td>
        { !isPreconfigured && <DeviceStatusImage isActive={isActive === 1} secondaryClassName='img_padding' /> }
        <span className='cstat'>
          {this.renderStatus(data, isRemoteConnectionPermitted)}
        </span>
      </td>
    )
  }

  renderStatus (device, isRemoteConnectionPermitted) {
    const { t } = this.props;

    const {
      Device_IsActive: isActive,
      Device_UID: deviceUID,
      Device_Name: deviceName,
      Device_IsPreconfigured: isPreconfigured,
      Device_MainVersion: deviceMainVersion
    } = device
    const isAdmin = this.authService.isAdmin()

    const statusLabelKey = isPreconfigured ?
    'common__device__status--pre-configured'
    : isActive ? 'common__device__status--online' : 'common__device__status--offline'

    const spanStatus = <span className={'systemStatusBlack'}>{ t(statusLabelKey) }</span>

    // If admin portal allow remoteConnect to CIB, on public portal do not allow it
    // Remote connect to LW not allowed
    const isLinkWear = deviceName === deviceType.linkWear.name;
    const isCIB = deviceName === deviceType.cib.name;
    const isEVD = deviceName === deviceType.evd.name;
    if (isPreconfigured || !isActive || isLinkWear || isEVD || (!isAdmin && isCIB)) return spanStatus;

    if (isRemoteConnectionPermitted) {
      return <a className='systemStatus_online_normal' href={this.navigate.getMenu('connect', deviceUID, deviceMainVersion, null, deviceName)}>{t(statusLabelKey)}</a>
    }

    return spanStatus;
  }

  renderSystemImage (deviceName) {
    switch (deviceName) {
      case deviceType.zoom.name: {
        return (<img src={ZOOMIcon} alt='Zoom' />)
      }
      case deviceType.cib.name: {
        return (<img src={CIBIcon} alt='CIB' />)
      }
      case deviceType.linkWear.name: {
        return (<img src={LinkWearIcon} width='95px' alt='LinkWear' />)
      }
      default: {
        return (deviceName)
      }
    }
  }

  renderRows (isRemoteConnectionPermission) {
    const {
      stores: {
        Device_Details: devices
      } = {},
      t
    } = this.props
    const {
      deviceType: {
        zoomNitro: {
          name: zoomNitroName,
          minMainVersion,
        } = {},
      } = {},
    } = CommonConstants

    return getFilteredStoreDevices(devices).map(data => {
      const {
        Device_MainVersion: deviceMainVersion,
        Device_SerialNumber: deviceSerialNumber,
        Device_IsPreconfigured: isPreconfigured,
        Device_UID: deviceUID,
        Device_DeviceType_ID: deviceTypeID,
      } = data
      let Device_Name = data.Device_Name
      if (Device_Name === 'ZOOM' && +deviceMainVersion.split('.')[0] >= minMainVersion) {
        Device_Name = zoomNitroName
      }

      return (
        <tr className='tdata' key={deviceSerialNumber}>
          <td >{this.renderSystemImage(Device_Name)}</td>
          <td >{isPreconfigured ? '' : deviceMainVersion}</td>
          <td >{isPreconfigured ? '' : deviceSerialNumber}</td>
          { this.checkSystemStatus(data, isRemoteConnectionPermission) }
          <td>
            <a href={createSystemPageURI({ deviceUID, deviceTypeID, deviceMainVersion })} className='viewDetials'>{t('stores__modal__view-details')}</a>
          </td>
        </tr>
      )
    })
  }

  render () {
    const { t } = this.props;
    let isRemoteConnectionPermission = checkAccess(CommonConstants.userPermissions.RemoteConnect)
    return (<section>
      <CommonLoader showLoader={this.state.showCommonLoader} message='common__loading' />
      {!this.state.showCommonLoader && this.state.noAccessError && <ErrorMessage message={
        <Trans i18nKey='store-details__error--no-access'>
          No Access to
          <Link to='/settings/stores' className="no-access-error-link">
            Stores
          </Link>
          Yet
        </Trans>
      } className="no-access-error"/>}

      <div className={'store-details ' + (this.state.showCommonLoader || this.state.noAccessError ? 'hidden' : '')}>
        <Row>
          <Col sm='12' lg='6' md='12' >
            <StoreDetailsComponent storedetails editable={false} />
          </Col>
          <Col lg='6' sm='12' md='12'>
            <div className='device_details deviceDetails_table'>
              <h4 className='cib_header'> {t('store-details__associated-systems')} </h4>
              <Table className='sys_table_widget sys_table systemstatus_table'>
                <tbody>
                  <tr className='theader RegisterSystemTableHeader'>
                    <th>{t('common__system__name')} </th>
                    <th> {t('common__system__version')}</th>
                    <th> {t('common__device__serial-number--text')}</th>
                    <th className='systemStatusHeader'> {t('common__system__status')}</th>
                    <th className='viewDetailsHeader' />
                  </tr>
                  {this.renderRows(isRemoteConnectionPermission)}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </section>
    )
  }

}

function mapStateToProps (state) {
  return {
    stores: state.StorePopupDetails.storePopupDetails,
    roles: state.roles
  }
}
function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      initStoreDetail: initStoreDetail,
      getPermission: getPermission
    }, dispatch
  )
}

StoreContainer.propTypes = {
    queryParams: PropTypes.object
};

export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter
)(StoreContainer);
