import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import './ResultCount.css';

export const ResultCount = ({ count }) => {
    const tKey = useMemo(
        () => (count === 1 ? 'merge_devices__count-results--single' : 'merge_devices__count-results--multiple'),
        [count],
    );

    return (
        <div className="hme-result-count">
            <span className="hme-result-count-count">
                <Trans i18nKey={tKey} count={count}>
                    <span className="hme-result-count-label">{{ count }}</span> devices found
                </Trans>
            </span>
        </div>
    );
};
