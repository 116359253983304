import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DeviceStatus } from 'library/DeviceStatus';
import { SerialNumberLink } from '../../../../StatusPage/Common/List/StoreSerialNumbers';

export const StoreStatusesMobile = ({ devices, isDetailsVisible }) => {
    const { t } = useTranslation();
    return (
        <div className={classNames({'hme-stores-mobile--public__store__underline__block': !isDetailsVisible})}>
            {
                devices.map((device) => {
                    const {
                        Device_UID: deviceUUID = '',
                        Device_IsActive: online = 0,
                        Device_IsPreconfigured: isPreconfigured = 0,
                        Device_Name: name = '',
                        Device_MainVersion: version = '',
                        Device_Product_ID: productID = '',
                    } = device;
                    return (
                        <div
                            key={deviceUUID}
                            className={classNames({'hme-stores-mobile--public__store__underline__block': isDetailsVisible})}
                        >
                            <div className="hme-stores-mobile--public__store__info__item" key={deviceUUID}>
                                <span className="hme-stores-mobile--public__tile__sub__title">{t('common__status')}</span>
                                <div className='hme-stores-mobile--public__tile__sub__value'>
                                    <DeviceStatus
                                        online={online}
                                        isPreconfigured={isPreconfigured}
                                        name={name}
                                        version={version}
                                        deviceUid={deviceUUID}
                                    />
                                </div>
                            </div>
                            {isDetailsVisible &&
                            <>
                                <div className="hme-stores-mobile--public__store__info__item">
                                    <span className="hme-stores-mobile--public__tile__sub__title">{t('common__device__serial-number--text')}</span>
                                    <span className="hme-stores-mobile--public__tile__sub__value">
                                        <SerialNumberLink device={device} />
                                    </span>
                                </div>
                                <div className="hme-stores-mobile--public__store__info__item">
                                    <span className="hme-stores-mobile--public__tile__sub__title">{t('common__device__product-id')}</span>
                                    <span className="hme-stores-mobile--public__tile__sub__value">{productID}</span>
                                </div>
                                <div className="hme-stores-mobile--public__store__info__item">
                                    <span className="hme-stores-mobile--public__tile__sub__title">{t('common__device__version')}</span>
                                    <span className="hme-stores-mobile--public__tile__sub__value">{version}</span>
                                </div>
                            </>
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

StoreStatusesMobile.prototype = {
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isDetailsVisible: PropTypes.bool.isRequired,
}
