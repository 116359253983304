import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';

import { DeviceStatus } from './DeviceStatus/DeviceStatus';
import { SendUpgradeConfirmPopup } from './SendUpgradeConfirmPopup';
import { notificationActions, showNotification, getStoreCountryName } from './helpers';

import { useLoadDevice } from './hooks/useLoadDevices';
import { useLoadDeviceSettings } from './hooks/useLoadDeviceSettings';
import { useReconnectDevice } from './hooks/useReconnectDevice';
import { useRebootDevice } from './hooks/useRebootDevice';
import { useUpgradeDevice } from './hooks/useUpgradeDevice';
import { useLoadCountries } from './hooks/useLoadCountries';

import './SystemStatus.scss';

const SystemStatus = () => {
    const { t } = useTranslation();
    const { deviceUID } = useParams();
    const [upgradeTaskData, setUpgradeTaskData] = useState({});
    const [isSendUpgradeConfirmShown, setIsSendUpgradeConfirmShown] = useState(false);

    const { countries, isCountriesLoading } = useLoadCountries();
    const { device, isDeviceLoading, deviceLoadingError, loadDevice } = useLoadDevice(deviceUID);
    const peripheralDevices = [];

    const { deviceSettings, isDeviceSettingsLoading, deviceSettingsLoadingError, loadDeviceSettings } =
        useLoadDeviceSettings(deviceUID);

    const { isDeviceReconnecting, reconnectDevice } = useReconnectDevice(
        loadDevice,
        loadDeviceSettings,
    );

    const { isDeviceRebooting, rebootDevice } = useRebootDevice(
        loadDevice,
        loadDeviceSettings,
    );

    const { isDeviceUpgrading, upgradeDevice } = useUpgradeDevice(
        loadDevice,
        loadDeviceSettings,
    );

    const onSendUpgrade = useCallback(
        (upgradeTask) => {
            setUpgradeTaskData(upgradeTask);
            setIsSendUpgradeConfirmShown(true);
        },
        [setUpgradeTaskData, setIsSendUpgradeConfirmShown],
    );

    const onSendUpgradeConfirmHide = useCallback(() => {
        setIsSendUpgradeConfirmShown(false);
    }, [setIsSendUpgradeConfirmShown]);

    const onSendUpgradeConfirm = useCallback(async () => {
        const status = await upgradeDevice(upgradeTaskData);
        showNotification({ action: notificationActions.UPGRADE_DEVICE, status, t });
    }, [upgradeTaskData, deviceUID]);

    const onDeviceReboot = useCallback(async (device) => {
        const status = await rebootDevice(device.Device_UID);
        showNotification({ action: notificationActions.REBOOT_DEVICE, status, t });
    }, []);

    const onDeviceReconnect = useCallback(async (device) => {
        const status = await reconnectDevice(device.Device_UID);
        showNotification({ action: notificationActions.RECONNECT_DEVICE, status, t });
    }, []);

    const isDataLoading =
        isDeviceLoading ||
        isDeviceSettingsLoading ||
        // isDeviceUpgradeVersionsLoading ||
        isDeviceReconnecting ||
        isDeviceRebooting ||
        isDeviceUpgrading ||
        isCountriesLoading;

    const error =
        deviceLoadingError ||
        // deviceUpgradeVersionsLoadingError ||
        deviceSettingsLoadingError;

    const storeCountry = useMemo(() => getStoreCountryName(device, countries), [device, countries]);

    return (
        <>
            <NotificationsList />
            <DeviceStatus
                device={device}
                peripheralDevices={peripheralDevices}
                deviceSettings={deviceSettings}
                storeCountry={storeCountry}
                isLoading={isDataLoading}
                error={error}
                onDeviceVersionUpgrade={onSendUpgrade}
                onDeviceReboot={onDeviceReboot}
                onDeviceReconnect={onDeviceReconnect}
            />
            <SendUpgradeConfirmPopup
                show={isSendUpgradeConfirmShown}
                onHide={onSendUpgradeConfirmHide}
                onConfirm={onSendUpgradeConfirm}
                upgradeTaskData={upgradeTaskData}
            />
        </>
    );
};

export const AdminEVDSystemStatus = compose(
    withHMELayout({
        contentClasses: ['admin-evd-system-status-page'],
    }),
)(SystemStatus);
