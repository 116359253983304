/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import awardApi from 'api/awardApi';
import { Grid } from 'components/Common/Grid';
import './ResetHistory.scss';
import { ResetDetails } from './ResetDetails';
import { DateLib } from '@hme-cloud/utility-common';

const defaultHistory = [];

const gridHeaders = [{
    text: 'common__reset-by',
    property: 'ResetBy'
}, {
    text: 'common__reset-date-time',
    property: 'ResetDateFormatted'
}];
export const ResetHistory = ({ allStores }) => {
    const [history, setHistory] = useState(defaultHistory);
    const [loader, setLoader] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        const awardsResetHistory = async () => {
            setLoader(true);
            try {
                const received = await awardApi.awardsResetHistory();
                if (!received) {
                    throw Error();
                }
                if (Array.isArray(received)) {
                    const timeFormatted = received.map((el) => {
                        const resetDate = el['ResetDate'];
                        el['ResetDateFormatted'] = resetDate ? new DateLib(resetDate).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME) : '';

                        return el;
                    });
                    setHistory(timeFormatted);
                }
            } catch (e) {
                console.error(e);
            }
            setLoader(false);
        };
        awardsResetHistory();
    }, [allStores]);

    const ExpandComponent = (rest) => {
        return (
            <ResetDetails
                resetHistory={history}
                allStores={allStores}
                {...rest}
            />
        );
    };
    return (
        <div className="hme-awards-reset-history-tab">
            <Grid
                rows={history || []}
                headers={gridHeaders}
                rowKey="AwardResetID"
                isLoading={loader}
                expandable
                ExpandComponent={ExpandComponent}
                noRecordsMessage={t('common__error--no-data-found')}
                loadingText={t('common__loading')}
            />
        </div>
    );
};
