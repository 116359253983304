import React, { useMemo } from 'react';
import { Button } from 'library/Button';
import { SearchInput } from 'components/Inputs';
import { useTranslation, Trans } from 'react-i18next';

import './DevicesSearch.css';

export const DevicesSearch = ({ devices, brandDevicesTotal, search, onSearchChange, onRemoveAll }) => {
    const { t } = useTranslation();
    const devicesTotal = useMemo(() => brandDevicesTotal || devices.length, [devices, brandDevicesTotal]);
    const tKey = useMemo(
        () =>
            devicesTotal === 1
                ? 'device-upgrades__section__search__count-results--single'
                : 'device-upgrades__section__search__count-results--multiple',
        [devices],
    );

    return (
        <div className="create-device-upgrade-target-filters-row">
            <div className="create-device-upgrade-target-filters-panel">
                <span className="create-device-upgrade-target-count">
                    <Trans i18nKey={tKey} count={devicesTotal}>
                        <span className="create-device-upgrade-target-count-label">{{ count: devicesTotal }}</span>{' '}
                        devices found
                    </Trans>
                </span>

                <Button variants={['transparent']} onClick={onRemoveAll}>
                    {t('device-upgrades__section__search--remove-all')}
                </Button>
            </div>
            <SearchInput showClearIcon value={search} placeholder="Search for Device" onChange={onSearchChange} />
        </div>
    );
};
