import { formatStoreGroups, groupStores } from '../../../../helpers/Store/storeGroups';

export const useParticipatingStoreTreeData = ({items, company, isViewMyStoresOnlyChecked, disabled}) => {
    let filteredItems = items;

    if (isViewMyStoresOnlyChecked) {
        filteredItems = items.filter(store => store.CompanyUID == company.companyUId);
    }

    const storesTree = formatStoreGroups(groupStores(filteredItems));
    const presentStores = storesTree.reduce((stores, group) => {
      const storeUIDs = group.children.map(store => store.id);
      if (storeUIDs && storeUIDs.length) {
        return stores.concat(storeUIDs);
      }
      return stores;
    }, []);
    const checkedStores = filteredItems.filter(item => item.selected).map(item => item.StoreUID) || [];
    const value = checkedStores && checkedStores.filter(store => presentStores.includes(store)) || [];

    return {
        value,
        storesTree,
        disabled
    }
};