import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ isSubmitEnabled, onCancel, onApply }) => {
    const { t } = useTranslation();
    return (
        <div className='admin-create-apply-device-settings-task-footer'>
            <Button onClick={onCancel}>{t('common__cancel')}</Button>
            <Button
                variants={['submit']}
                disabled={!isSubmitEnabled}
                onClick={onApply}
            >
                {t('common__apply-settings')}
            </Button>
        </div>
    );
}
