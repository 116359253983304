import api from './api';

const base = 'smacktalkMessage';

const paths = {
    getData: `${base}/getSmacktalkMessages`,
    updateStatus: `${base}/updateSmacktalkStatus`,
    createCustom: `${base}/createCustomSmacktalk`,
    updateCustom: `${base}/updateSmacktalkMessage`,
    sendMessage: `${base}/sendSmacktalkMessage`,
    messageHistory: `${base}/messageHistory`,
    deleteCustom: `${base}/deleteSmacktalkMessage`
};

const smacktalkApi = {
    getData() {
        return api.request({
            url: paths.getData,
            method: 'GET'
        });
    },
    updateStatus(data) {
        return api.request({
            url: paths.updateStatus,
            method: 'POST',
            data
        });
    },
    createCustom(data) {
        return api.request({
            url: paths.createCustom,
            method: 'POST',
            data
        });
    },
    updateCustom(data) {
        return api.request({
            url: paths.updateCustom,
            method: 'POST',
            data
        });
    },
    deleteCustom(data) {
        return api.request({
            url: paths.deleteCustom,
            method: 'POST',
            data
        });
    },
    sendMessage(data) {
        return api.request({
            url: paths.sendMessage,
            method: 'POST',
            data
        });
    },
    messageHistory(data) {
        return api.request({
            url: paths.messageHistory,
            method: 'GET',
            data
        });
    }
};

export default smacktalkApi;
