import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';

import './LockDeviceSettingsPage.scss';

import { LockDeviceSettingsTable } from '../../components/LockDeviceSettingsTable';
import CommonLoader from '../../components/Alerts/CommonLoader';
import { sortStores, initStoresDetails, adminStoresDetails, paginationAdminStores } from '../../actions/stores';
import { Button } from '../../components/Common/Button/Button';
import { lockDeviceSettingsApi } from '../../api/lockDeviceSettingsApi';
import { confirmAlert } from '../../components/Common/ConfirmAlert/ConfirmAlert';
import { translateFormat } from '../../components/LeaderBoard/helpers/formatting';

import { selectStoreDetails, selectRecordCount, selectTotalPages, selectSortParams, selectAutocompleteItems, selectAdminStoreDetails } from './selectors';
import TypeAheadInput from '../../components/Common/TypeAheadInput/TypeAheadInput';
import { Config } from '../../Config';
import AuthenticationService from '../../components/Security/AuthenticationService';

let isAdmin = false;

class LockDeviceSettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: [],
      selectedItems: [],
      isLoading: false,
      isAscending: false,
      searchValue: ''
    };

    this.onSelect = this.onSelect.bind(this);
    this.getStores = this.getStores.bind(this);
    this.onSort = this.onSort.bind(this);
    this.cancel = this.cancel.bind(this);
    this.lockDeviceSettings = this.lockDeviceSettings.bind(this);
    this.unlockDeviceSettings = this.unlockDeviceSettings.bind(this);
    this.setLockDeviceSettings = this.setLockDeviceSettings.bind(this);
    this.showInfo = this.showInfo.bind(this);

    this.authService = new AuthenticationService(Config.authBaseUrl);
    isAdmin = this.authService.isAdmin();
  }

  getStores() {
    this.setState({ isLoading: true });
    isAdmin ?
      this.props.adminStoresDetails(() => this.setState({ isLoading: false }), true) :
      this.props.initStoresDetails(() => this.setState({ isLoading: false }), true);
  }

  UNSAFE_componentWillMount() {
    this.props.sortStores({
      sortBy: 'Store_Name',
      sortType: 'ASC'
    });

    // this is temporary only
    this.props.setAdminPagination({
        pageSize: 10000,
        pageNumber: 1
    });

    this.getStores();
  }

  componentWillUnmount() {
    this.props.setAdminPagination({});
  }

  onSort(sortParams) {
    this.setState({ isAscending: !this.state.isAscending });
    this.props.sortStores(sortParams);
    this.getStores();
  }

  onSelect(devices, isSelected) {
    const { selectedItems } = this.state;

    for (let i = 0; i < devices.length; i++) {
      const deviceId = devices[i].Device_ID;

      if (isSelected) {
        if (!selectedItems.includes(deviceId)) {
          selectedItems.push(deviceId);
        }
      } else {
        const index = selectedItems.indexOf(deviceId);
        if (index >= 0) {
          selectedItems.splice(index, 1);
        }
      }
    }

    this.setState((state) => ({ ...state, selectedItems }));
  }

  lockDeviceSettings() {
    this.setLockDeviceSettings(true);
  }

  unlockDeviceSettings() {
    this.setLockDeviceSettings(false);
  }

  confirmLockStateChange(callback) {
    const { t } = this.props;
    return (isLocked) => {
      confirmAlert({
        message: translateFormat(t('lock-device-settings__settings-action--question'), { action: isLocked ? t('lock-device-settings__settings-action--lock'): t('lock-device-settings__settings-action--unlock') }),
        buttons: [
          {
            label: t('common__yes'),
            onClick: () => {
              callback();
            }
          },
          {
            label: t('common__no'),
            onClick: () => {}
          }
        ]
      });
    };
  }

  showInfo() {
    const { t } = this.props;
    confirmAlert({
      message: <div style={{ width: '425px' }}>
        <span className='icon-info'></span>
        <span className='text'>
          <p>{t('lock-device-settings__lock-action-description')}</p>
          <p>{t('lock-device-settings__settings-changes-description')}</p>
          <p>{t('lock-device-settings__settings-lock-description')}</p>
        </span>
      </div>
    });
  }

  async setLockDeviceSettings(isLocked) {
    this.setState({ isLoading: true });
    if (isLocked) {
      await lockDeviceSettingsApi.lockDeviceSettings(this.state.selectedItems);
    } else {
      await lockDeviceSettingsApi.unlockDeviceSettings(this.state.selectedItems);
    }
    this.cancel();
    this.getStores();
  }

  cancel() {
    this.setState({ selectedItems: [] });
  }

  render() {
    const isDisabled = this.state.selectedItems.length === 0;
    const { t, sortParams, collection, searchOptions } = this.props;
    const { selectedItems, isAscending, searchValue, filteredCollection } = this.state;

    const setSearchValue = (searchValue) => {
      this.setState({ searchValue });
      if(!searchValue) {
        this.setState({ filteredCollection: this.props.collection });
      }
    }

    const setSelection = (selectionValue) => {
      const { value } = selectionValue;
      const newCollection = this.props.collection
          .filter(item => item.Store_Number && item.Store_Number.includes(value) ||
              item.Store_Name && item.Store_Name.includes(value)
              );
      this.setState({ filteredCollection: newCollection })
    }

    return (
      <section>
        <div className='title-with-icon'>
          <h3>{t('sub-header--settings__lock-device-settings')}</h3>
          <div className='icon-info' onClick={this.showInfo}></div>
        </div>
        <div>
          <div className='btn-panel'>
            <Button circular={true} color='gray' onClick={this.cancel} disabled={isDisabled} label={t('common__cancel')} />
            <Button
              circular={true}
              color='dark-blue'
              onClick={() => this.confirmLockStateChange(this.lockDeviceSettings)(true)}
              disabled={isDisabled}
              label={t('lock-device-settings__lock-settings')} />
            <Button
              circular={true}
              color='red'
              onClick={() => this.confirmLockStateChange(this.unlockDeviceSettings)(false)}
              disabled={isDisabled}
              label={t('lock-device-settings__unlock-settings')} />
            <div className='autocomplete-box'>
              <TypeAheadInput
                data={searchOptions}
                displayKeys={['', 'value']}
                value={searchValue}
                customMessage={t('common__no-devices--found')}
                setSelection={setSelection}
                onChange={setSearchValue}
                placeholder={t('common__search--dots')}
              />
              <label className='search-bar-description'>{t('lock-device-settings__search-by-store-description')}</label>
            </div>
          </div>
          <CommonLoader showLoader={this.state.isLoading} />
          {!this.state.isLoading &&
            <LockDeviceSettingsTable
              ascending={isAscending}
              settings={filteredCollection || collection}
              onSort={this.onSort}
              onSelect={this.onSelect}
              sortBy={sortParams.sortBy}
              selectedItems={selectedItems}
              sortType={sortParams.sortType} />
          }
        </div>
      </section>
    );
  }
}

const selectorCreator = (isAdmin) => isAdmin ? selectAdminStoreDetails : selectStoreDetails;

// eslint-disable-next-line require-jsdoc
function mapStateToProps(state) {
  return {
    collection: selectorCreator(isAdmin)(state), //selectStoreDetails(state),
    sortParams: selectSortParams(state),
    recordCount: selectRecordCount(state),
    totalPages: selectTotalPages(state),
    searchOptions: selectAutocompleteItems(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    initStoresDetails: initStoresDetails,
    adminStoresDetails: adminStoresDetails,
    sortStores: sortStores,
    setAdminPagination: paginationAdminStores
  }, dispatch);
}

const LockDeviceSettingsPageWithTranslation =  compose(withTranslation())(LockDeviceSettingsPage);

export default connect(mapStateToProps, mapDispatchToProps)(LockDeviceSettingsPageWithTranslation);
