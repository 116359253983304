import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import '../Stores.css';
import './AddDeviceComponent.css';
import { Config } from '../../../Config';
import { CommonConstants } from '../../../Constants';
import Api from '../../../Api';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { addDeviceFieldsList } from './generateFieldsList';
import { validate } from './helpers/storeValidation';
import {CoffeeTable} from '../../Common/CoffeeTable/CoffeeTable';
import { settingApi } from '../../../api/settingApi';
import CommonLoader from '../../Alerts/CommonLoader';
import { tableHeaders, devicesToAdd } from './constants';
import { generateTableBody } from './helpers/generateTableBody';
import { postData } from './helpers/postData';
import NoDevicesComponent from './NoDevicesComponent/NoDevicesComponent';
import getDeviceType from '../../../helpers/Device/getDeviceType';
import compareDeviceTypes from '../../../helpers/Device/compareDeviceTypes';
import getAvailableDevices from '../../../helpers/Store/getAvailableDevices';
import filterSubscriptionAllowedDevices from '../../../helpers/Store/filterSubscriptionAllowedDevices';

const deviceTypesArr = Object.values(CommonConstants.deviceType);
const {
  deviceType: {
      zoom,
      zoomNitro,
      nexeo,
  },
} = CommonConstants;

// This componenet is used for the store detail popup and store detail page.
class AddDeviceComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showStores: this.props.showStores,
      isValid: true,
      requestErrors: [],
      errors: [],
      fieldsList: [],
      isPending: false,
      showCommonLoader: false,
      showNoDevicesMessage: false,
      showUpgradeDevicesMessage: false,
      settingTemplateID: '',
      settings: [],
      currentDeviceName: '',
      storeID: 0,
      zoomNitroExists: false,
      zoomExists: false,
      nexeoExists: false,
      store: {},
    };
    this.api = new Api();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputRadio = this.handleInputRadio.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.generateTableBody = generateTableBody.bind(this);
    this.postData = postData.bind(this);
  }

  async componentDidMount() {
    this.setState({ showCommonLoader: true });
    const latestSettings = await settingApi.get();

    this.setState({
      settings: latestSettings.status ? latestSettings.data : [],
      showCommonLoader: false,
    });
    const {
      existedDevices = [],
      storeDetails = {},
      setNoDevicesMessage,
    } = this.props;

    const nexeoExists = existedDevices.some(device => compareDeviceTypes(device, nexeo));
    const zoomExists = existedDevices.some(device => compareDeviceTypes(device, zoom));
    const zoomNitroExists = existedDevices.some(device => compareDeviceTypes(device, zoomNitro));
    const zoomOnlineExists = existedDevices.some(device =>
      device['Device_DeviceType_ID'] === zoom.id && !device['Device_IsPreconfigured'] && device['Device_IsActive']
    );
    let availableDevices = getAvailableDevices(existedDevices);
    availableDevices = filterSubscriptionAllowedDevices(availableDevices, storeDetails['Account_Subscription_ID']);

    this.setState({
      currentDeviceName: availableDevices[0] && availableDevices[0]['Device_Name'] || ''
    })
    const fieldsList = addDeviceFieldsList({ devices: availableDevices });
    const showNoDevicesMessage = availableDevices.length === 0;

    const showUpgradeDevicesMessage = !zoomNitroExists && zoomOnlineExists;
    setNoDevicesMessage && setNoDevicesMessage(showNoDevicesMessage);
    this.setState({
      fieldsList,
      storeID: storeDetails['Store_ID'],
      showNoDevicesMessage,
      showUpgradeDevicesMessage,
      nexeoExists,
      zoomExists,
      zoomNitroExists,
      store: storeDetails,
    });
  }

  handleInputChange (e) {
    if (e.target.name === 'currentDeviceName') {
      const { Device_Name: newDeviceName = '' } =
        devicesToAdd.find(el => el['Device_Name'] === e.target.value) || {};
      this.props.onChangeTitle && this.props.onChangeTitle({ deviceName: newDeviceName });
    }
    this.setState({
      [e.target.name]: e.target.value,
      errors: [],
    });
  }

  handleSubmit () {
    const fieldsList = this.state.fieldsList || [];
    const {
      storeID,
      currentDeviceName,
      settingTemplateID
    } = this.state;
    const stateValues = {
      currentDeviceName,
      settingTemplateID,
    };
    const extendedFieldsList =
      fieldsList
        .concat({
          name: 'settingTemplateID',
          isRequired: true,
          caption: 'Snapshot Device Setting'
        });
    const {
      isValid,
      errors,
    } = validate(extendedFieldsList, stateValues);
    if (!isValid) {
      return this.setState({ errors, isValid });
    }

    const url = Config.apiBaseUrl + CommonConstants.apiUrls.createPreconfiguredDevice;
    const [{id: deviceTypeID} = {}] =
      deviceTypesArr.filter(el => el.name === currentDeviceName);
    const data = {
      storeID,
      deviceTypeID,
      settingTemplateID,
    };
    this.postData(url, data, this.props.onClose);
  }

  handleInputRadio(option) {
    this.setState({
      settingTemplateID: option,
      errors: [],
    });
  }

  noSnapshots(currentRows) {
    const {settings = [], currentDeviceName} = this.state;
    return currentDeviceName ? currentRows.length === 0 : settings.length === 0;
  }

  renderSettingsTemplate(){
    const {settings = [], settingTemplateID, currentDeviceName} = this.state;
    const currentRows = settings.reduce((filtered, row) => {
      const [{ id: deviceTypeID } = {}] =
        deviceTypesArr.filter(el => el.name === row.SourceDeviceType);
      const currentDevice = {
        Device_DeviceType_ID: deviceTypeID,
        Device_MainVersion: row.SourceSoftwareVersion,
      };
      const foundDevice = getDeviceType(currentDevice);
      if (deviceTypeID && foundDevice.name && foundDevice.name === currentDeviceName) {
        row.SourceDeviceType = foundDevice.name; // change Zoom to Zoom Nitro in Coffee Table
        filtered.push(row);
      }
      return filtered;
    }, []);
    return (
      <div className="settings_list" style={{height: '340px'}}>
        <div className='storesTable ctable'>
          {
            this.noSnapshots(currentRows) ?
              <h3 className="snapshots-no-records">Snapshots are not found</h3> :
              <CoffeeTable
                tableHeaders={tableHeaders}
                tableRows={currentRows}
                sortable={{presort: {columnId: 'CreatedDate', ascending: true}}}
                radioButton={{
                  replaceColumnId: 'SettingTemplateID',
                  selectionHandler: this.handleInputRadio,
                  selectionId: settingTemplateID
                }}
              />
          }
        </div>
      </div>
    );
  }

  render () {
    const {
      showCommonLoader,
      showNoDevicesMessage,
      showUpgradeDevicesMessage,
      zoomExists,
      zoomNitroExists,
    } = this.state;
    const { t } = this.props;
    const existedZOOMDevice = zoomNitroExists ? 'ZOOM Nitro' : 'ZOOM';
    const generalNoDeviceMessage = 'There are no other device types available to pre-configure.';
    const noZoomDeviceMessage = (zoomExists || zoomNitroExists)
      ? `You have already pre-configured a ${existedZOOMDevice} device.`
      : '';
    const noDeviceMessage = `${noZoomDeviceMessage}
    ${generalNoDeviceMessage}`;
    const upgradeDevicesMessage = `There are currently no other device types available to
      pre-configure. However, the ZOOM device associated with
      this Store is eligible for a software upgrade to ZOOM Nitro.`;
    const message = showUpgradeDevicesMessage ? upgradeDevicesMessage : noDeviceMessage;

    return showNoDevicesMessage ? (<NoDevicesComponent message={message}/>) : (
      <div>
        <div className={'storePopupForm'}>
          <div className={`saveStoreErrors ${this.state.isValid ? 'hide' : 'show'}`} >
            {this.state.errors.map(errorMessage => (<div key={errorMessage}>{t(errorMessage)}</div>))}
            {this.state.requestErrors.map(errorMessage => (<div key={errorMessage}>{t([errorMessage, 'common__error--internal-server'])}</div>))}
          </div>
        </div>
        <div className='add-device-container'>
          <div>
            <div className='add-device-title'>1. Select Device Type</div>
            <table className='user_form'>
              <tbody>
                {this.generateTableBody()}
              </tbody>
            </table>
          </div>
          <div className='snapshot-table'>
            <div className='add-device-title'>2. Select Snapshot Device Setting</div>
            {showCommonLoader
              ? <CommonLoader showLoader={showCommonLoader} message='common__loading' />
              : this.renderSettingsTemplate()}
          </div>
        </div>
        <div className={'add-store-save'}>
          <div
            className='submit-public'
            translate=''
            onClick={() => this.props.onClose(false)}
            key='settingsStoresCancel'
          >{t('common__cancel')}</div>
          <input
            type='submit'
            value='Pre-configure Device'
            className='submit-public'
            onClick={this.handleSubmit}
            translate=''
            key='save'
            disabled={this.state.isPending ? 'disabled' : '' }
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation()
)(AddDeviceComponent);
