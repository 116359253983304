import React from 'react';
import { Tbody, Tr, Td } from 'react-super-responsive-table';
import { useTranslation } from 'react-i18next';
import { HMECheckbox } from '../Common/HMECheckbox';

const LockDeviceSettingsTableBody = ({ collection, onChange, selectedItems = [] }) => {
    const { t } = useTranslation();

    const onChangeHandler = (item) => (isChecked) => {
        onChange(item.Device_Details, isChecked);
    };

    const isSelected = (devices) => {
        return devices.every((item) => selectedItems.includes(item.Device_ID));
    };

    const renderRows = (store, index) => {
        const devices = store.Device_Details;

        const deviceColumn = (device, getText) =>
            device.Device_IsPreconfigured ? (
                ''
            ) : (
                <div className="storesColumns" key={device.Device_ID} width={90}>
                    {getText()}
                </div>
            );

        const getSettingStatus = (device) => {
            const getText = () =>
                t(
                    device.Device_IsSettingLocked
                        ? 'common__device__settings--locked'
                        : 'common__device__settings--unlocked',
                );
            return deviceColumn(device, getText);
        };

        const getSystemVersion = (device) => deviceColumn(device, () => device.Device_MainVersion);

        const getSystemStatus = (device) =>
            deviceColumn(device, () =>
                t(
                    device.Device_IsActive
                        ? 'common__device__status--online'
                        : 'common__device__status--offline',
                ),
            );

        return (
            <Tr className="tdata clear" key={index}>
                <Td>
                    <HMECheckbox onChange={onChangeHandler(store)} value={isSelected(store.Device_Details)} />
                </Td>
                <Td>
                    <div className="storesColumns">{store.Store_Name}</div>
                </Td>
                <Td>
                    <div className="storesColumns">{store.Store_Number}</div>
                </Td>
                <Td>
                    <div className="storesColumns">{store.Store_AddressLine1}</div>
                </Td>
                <Td>
                    <div className="storesColumns">{devices.map(getSettingStatus)}</div>
                </Td>
                <Td>
                    <div className="storesColumns">{devices.map(getSystemVersion)}</div>
                </Td>
                <Td>
                    <div className="storesColumns">{devices.map(getSystemStatus)}</div>
                </Td>
            </Tr>
        );
    };

    return <Tbody>{collection.map(renderRows)}</Tbody>;
};

export default LockDeviceSettingsTableBody;
