/**
 * we move device status property into separate
 * constant because for desktop and mobile grids we need to have it in the different places
 */
const STATUS_HEADER = {
    text: 'common__status',
    property: 'status',
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell',
    flex: 1.7
};

export const headers = [{
    text: 'common__device__serial-number--text',
    property: 'Device_SerialNumber',
    flex: 1.2
}, {
    text: 'common__device__product-id',
    property: 'Device_Product_ID',
    flex: 1,
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell'
}, {
    text: 'common__device__version',
    property: 'Device_MainVersion',
    flex: 1,
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell'
},
STATUS_HEADER,
{
    text: 'common__settings__version',
    property: 'Device_SettingVersion',
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell',
    flex: 1
}, {
    text: 'common__actions',
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell',
    property: 'Actions',
    flex: 1
}];

const MOBILE_HEADER_PROPERTIES = [
    'Device_SerialNumber',
    'Device_MainVersion',
    'Device_Product_ID',
];

export const MOBILE_HEADERS = [
    {
        column: [
            STATUS_HEADER,
            ...headers.filter(({ property }) =>
                MOBILE_HEADER_PROPERTIES.includes(property)
            )
        ]
    }
];
