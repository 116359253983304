import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'constants/routes';
import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { ParticipantService } from 'services/Participant';
import { addErrorNotification } from 'services/Notifications';

export const useLeaderboardInvite = ({ loadNotifications }) => {
    const navigate = useNavigate();

    const {
        run: runAccept,
        isLoading: isAccepting,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const {
        run: runDecline,
        isLoading: isDeclining,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const onAccept = useCallback((participantId) => {
        runAccept(ParticipantService.accept(participantId).then((responseData) => {
            navigate(`/${PUBLIC_ROUTES.lbGroup}?GroupUID=${responseData.groupUid}&IsDefault=0&ia=false&invite=true`);
        }).catch((err) => addErrorNotification(err.message)));
    }, [runAccept]);

    const onDecline = useCallback((participantId) => {
        runDecline(ParticipantService.decline(participantId)
            .then(() => { loadNotifications() })
            .catch((err) => addErrorNotification(err.message)));
    }, [runAccept]);

    const isLoading = isAccepting || isDeclining;

    return useMemo(() => ({
        onAccept,
        onDecline,
        isLoading,
    }), [
        onAccept,
        onDecline,
        isLoading
    ]);
};
