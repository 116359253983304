/* eslint-disable max-len */
import Api from '../Api';
import { Config } from '../Config';
import { CommonConstants } from '../Constants';

export const checkDevice = (duid, callback) => {
    return (dispatch, getState) => {
        const api = new Api();
        const url = Config.apiBaseUrl + CommonConstants.apiUrls.checkDevice + '?duid=' + duid;
        api.getData(url, (data) => {
            callback(data);
        });
    };
};

export const unBind = (duid, callback) => {
    return (dispatch, getState) => {
        const api = new Api();
        api.getPublicIp().then((ip) => {
            const clientIp = ip ? ip.clientIp : null;
            const absoluteUrl = Config.apiBaseUrl + CommonConstants.apiUrls.UnBindRemoteDeviceWeb + '?oDeviceID=' + duid + '&sRequestorHost=' + clientIp;
            api.getData(absoluteUrl, (data) => {
                callback(data);
            });
        });
    };
};

export const unBindEmail = (duid, callback) => {
    return (dispatch, getState) => {
        const api = new Api();
        const url = Config.apiBaseUrl + CommonConstants.apiUrls.unBindMail;
        const emailObject = {
            duid: duid
        };
        api.postData(url, emailObject, (data) => {
            callback(data);
        }, (error) => {
            console.log(error);
        });
    };
};

export const reboot = (duid, callback) => {
    return (dispatch, getState) => {
        const api = new Api();
        const absoluteUrl = Config.apiBaseUrl + CommonConstants.apiUrls.RestartDevice + '?oDeviceID=' + duid;
        api.getData(absoluteUrl, (data) => {
            callback(data);
        });
    };
};

export const reConnect = (duid, callback) => {
    return (dispatch, getState) => {
        const api = new Api();
        const absoluteUrl = Config.apiBaseUrl + CommonConstants.apiUrls.ForceReconnect + '?oDeviceID=' + duid;
        api.getData(absoluteUrl, (data) => {
            callback(data);
        });
    };
};

/**
 * Call Portal Service DownloadNewImage API with data.
 * The Portal Service will then forward this data in a call to the corresponding Device Manager DownloadNewImage API.
 * @param {*} duid
 * @param {*} callback
 * @return {null}
 */
export const upgrade = (duid, callback) => {
    const queryParams = new URLSearchParams(window.location.search);
    const modelName = queryParams.get('model');
    const vz = queryParams.get('vz');
    const version = vz ? vz : null;
    return (/* dispatch, getState */) => {
        // NOTE: sUpgradeType=patch|full will be defined on server by device version and upgrade version
        const api = new Api();
        const absoluteUrl = Config.apiBaseUrl + CommonConstants.apiUrls.DownloadNewImage
            + `?oDeviceID=${duid}&client=${modelName}&sVersion=${version}&sSettingsVersion=GNRC&sRunTime=`;
        api.getData(absoluteUrl, (data) => callback(data));
    };
};

export const connectRemote = (duid, callback) => {
    return (dispatch, getState) => {
        const api = new Api();
        api.getPublicIp().then((ip) => {
            const clientIp = ip ? ip.clientIp : null;
            const absoluteUrl = Config.apiBaseUrl + CommonConstants.apiUrls.BindRemoteDeviceWeb + '?oDeviceID=' + duid + '&sRequestorHost=' + clientIp;
            api.getData(absoluteUrl, (data) => {
                callback(data);
            });
        });
    };
};
