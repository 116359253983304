import { deviceTypes, deviceTypeOptions } from 'constants/device';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';
import { DateLib } from '@hme-cloud/utility-common';
import { findMaxVersion } from './findMaxVersion'

import { DEFAULT_UPGRADE_TIME_FROM, DEFAULT_UPGRADE_TIME_TO } from '../constants';

export const initUpgradeForm = (scheduledUpgrade) => {
    const {
        ScheduledUpgradeName,
        DeviceTypeSource,
        PeripheralDeviceTypeSource = '',
        UpgradeFromVersions,
        UpgradeToVersion,
        DaysofWeek,
        UpgradeStartDate,
        StartTime,
        EndTime,
        InstallSchedule,
    } = scheduledUpgrade || {};

    let foundDeviceTypeSource = DeviceTypeSource;
    let foundPeripheralDeviceTypeSource = PeripheralDeviceTypeSource;

    if (DeviceTypeSource === NEXEO_PERIPHERAL_TYPES.IB7000.modelName) {
        foundPeripheralDeviceTypeSource = NEXEO_PERIPHERAL_TYPES.IB7000.modelName;
        foundDeviceTypeSource = deviceTypes.NEXEO;
    } else {
        foundDeviceTypeSource = (deviceTypeOptions.find((option) => option.value === DeviceTypeSource) || {}).value;
    }

    return scheduledUpgrade
        ? {
              ScheduledUpgradeName,
              DeviceTypeSource: foundDeviceTypeSource,
              PeripheralDeviceTypeSource: foundPeripheralDeviceTypeSource,
              UpgradeFromVersions,
              UpgradeToVersion,
              UpgradeDateTime: {
                  DaysofWeek: DaysofWeek.split(',').map(Number),
                  UpgradeStartDate: new DateLib(UpgradeStartDate).toUTC(),
                  StartTime: new DateLib(StartTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD),
                  EndTime: new DateLib(EndTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD),
              },
              InstallDateTime: {
                  DaysofWeek: InstallSchedule?.weekDays?.split(',').map(Number) || [],
                  InstallStartDate: InstallSchedule?.startDate ? new DateLib(InstallSchedule.startDate).toUTC() : null,
                  StartTime: InstallSchedule?.startTime
                      ? new DateLib(InstallSchedule.startTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD)
                      : DEFAULT_UPGRADE_TIME_FROM,
                  EndTime: InstallSchedule?.endTime
                      ? new DateLib(InstallSchedule.endTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD)
                      : DEFAULT_UPGRADE_TIME_TO,
              },
              InitialMaxTargetDeviceVersion: findMaxVersion(UpgradeFromVersions),
          }
        : {
              ScheduledUpgradeName: '',
              DeviceTypeSource: '',
              PeripheralDeviceTypeSource: '',
              UpgradeFromVersions: [],
              UpgradeToVersion: '',
              UpgradeDateTime: {
                  DaysofWeek: [],
                  UpgradeStartDate: '',
                  StartTime: DEFAULT_UPGRADE_TIME_FROM,
                  EndTime: DEFAULT_UPGRADE_TIME_TO,
              },
              InstallDateTime: {
                  DaysofWeek: [],
                  InstallStartDate: null,
                  StartTime: DEFAULT_UPGRADE_TIME_FROM,
                  EndTime: DEFAULT_UPGRADE_TIME_TO,
              },
              InitialMaxTargetDeviceVersion: '',
          };
};
