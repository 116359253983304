export const sortBySerialNumber = (rows, direction) => (
    rows.map((row) => ({
        ...row,
        Device_Details: row.Device_Details
            .map((device) => ({
                ...device,
                lowerSerialNumber: device.Device_SerialNumber ? device.Device_SerialNumber.toLowerCase() : '',
            }))
            .sort((a, b) => {
                const firstValue = a.lowerSerialNumber;
                const secondValue = b.lowerSerialNumber;

                if (firstValue === secondValue) {
                    return 0;
                }

                return firstValue < secondValue ? direction : -direction;
            }),
    }))
    .sort((a, b) => {
        const firstValue = a.Device_Details.length ? a.Device_Details[0].lowerSerialNumber : '';
        const secondValue = b.Device_Details.length ? b.Device_Details[0].lowerSerialNumber : '';

        if (firstValue === secondValue) {
            return 0;
        }

        return firstValue < secondValue ? direction : -direction;
    })
);
