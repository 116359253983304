import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { Title } from 'library/Title';
import { SearchOptionable } from 'library/SearchOptionable';
import { Button } from 'library/Button';
import { SEARCH_DEBOUNCE_TIME } from 'hooks/useSearchWithDebounce'

import './Header.scss';

const searchOptions = [{
    text: 'Partner Name',
    value: 'PartnerName',
    placeholder: 'Type a name'
}, {
    text: 'Partner Type',
    value: 'search_type',
    placeholder: 'Type a partner type'
}, {
    text: 'Partner Owner Name',
    value: 'owner',
    placeholder: 'Type an owner name'
}, {
    text: 'Email Address',
    value: 'EmailAddress',
    placeholder: 'Type an email'
}, {
    text: 'common__all',
    value: 'search_all',
    placeholder: 'Type a name, partner type, owner or email'
}];

export const Header = ({ onSearch }) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState({
        selectValue: 'search_all',
        searchValue: ''
    });

    const debouncedOnSearch = useDebouncedCallback(onSearch, SEARCH_DEBOUNCE_TIME);

    const handleSearch = useCallback((value) => {
        setSearchValue(value);
        debouncedOnSearch(value);
    }, [debouncedOnSearch]);

    return (
        <div className='hme-partners-status__header'>
            <Title>{t('admin__partner__status__title')}</Title>
            <SearchOptionable
                value={searchValue}
                searchSelectOptions={searchOptions}
                onChange={handleSearch}
                onSearch={handleSearch}
            />
            <Button as={Link} to='create'>{t('admin__partner__form__title--create')}</Button>
        </div>
    );
};
