import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { saveCompany } from 'services/Account';

export const saveCompanyInfo = async ({ formData, companyInfo, regions, setIsSaving, t }) => {
    const { regionId, regionName, ...formPayload } = formData;
    const companyRegion = (regions || []).find((region) => region.Id === regionId);
    const state = companyRegion?.Abbreviation || regionName;

    try {
        setIsSaving(true);

        await saveCompany({
            ...companyInfo,
            ...formPayload,
            state,
        });

        addNotification({
            message: t('my-account__notification--company-successfully-updated'),
            type: NOTIFICATION_TYPES.SUCCESS,
        });
    } catch (err) {
        addNotification({
            message: t('my-account__notification--company-update-error'),
            type: NOTIFICATION_TYPES.ERROR,
        });
    }

    setIsSaving(false);
};
