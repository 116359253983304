import { get, has } from "lodash";

export const getErrorMessage = (customErrorMessage, serverResponse) => {

    let message = customErrorMessage;

    const isNetworkError = !has(serverResponse, "response.status");
    if(isNetworkError) {
      message = 'common__error--network-error';
    }

    return message;
}