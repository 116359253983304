/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './TrophyCase.css';
import CommonLoader from '../../../components/Alerts/CommonLoader';
import { Tabs } from '../../../components/Common/Tabs/Tabs';
import { Header } from '../../../components/Common/Header/Header';
import { IMAGES } from '../../../appConfig';
import { awardKeys } from '../AwardsResettable/config';
import { withTranslation } from 'react-i18next';
import { DateLib } from '@hme-cloud/utility-common';

class TrophyCaseComponent extends Component {
    renderSummaryCaseResults({ contests, awards, t }) {
        return (
            <div className="trophy-case__store-case__results">
                <div className="trophy-case__store-case__results__result trophy-case__store-case__results__result--summary">
                    <img src={IMAGES.contest_tophy_icon} />
                    <Header type="large">{contests.length}</Header>
                    <div className="contests-substrate"><span>{t('common__contests').toUpperCase()}</span></div>
                </div>
                <div className="trophy-case__store-case__results__result trophy-case__store-case__results__result--summary">
                    <img src={IMAGES.award_tophy_icon} />
                    <Header type="large">{awards.length}</Header>
                    <div className="awards-substrate"><span>{t('common__awards').toUpperCase()}</span></div>
                </div>
            </div>
        );
    }

    renderDetailedCaseResults({ contests, awards, t }) {
        const getAwardName = (award) => award.AwardType == 'custom' ? award.AwardName: t(awardKeys['AwardName'][award.AwardName]);
        return (
            <div className="trophy-case__store-case__results">
                {contests.map((contest, index) => (
                    <div
                        key={index}
                        className="trophy-case__store-case__results__result trophy-case__store-case__results__result--detailed"
                    >
                        <img src={IMAGES.contest_tophy_icon} />
                        <div>
                            <Header type="tiny" bold centered>{contest.ContestName}</Header>
                            <Header type="tiny" bold centered>{new DateLib(contest.EndDate).format(DateLib.FORMAT_TYPES.MONTH_YEAR)}</Header>
                        </div>
                    </div>
                ))}
                {awards.map((award, index) => (
                    <div
                        key={index}
                        className="trophy-case__store-case__results__result trophy-case__store-case__results__result--detailed"
                    >
                        <img src={IMAGES.award_tophy_icon} />
                        <div className="trophy-case__store-case__results__result__info">
                            <Header type="tiny" bold centered>{getAwardName(award)}</Header>
                            <Header type="tiny" bold centered>{new DateLib(award.CreatedDate).toUTC().format(DateLib.FORMAT_TYPES.MONTH_YEAR)}</Header>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { typeOfView, views, onViewChange, trophyCase, showDetails, loading, t, ...rest } = this.props;

        return (
            <div className="trophy-case">
                <Tabs activeTab={typeOfView} onTabChange={onViewChange} tabs={views} {...rest}>
                    {(tab) => {
                        const TabComponent = (views.find((el) => el.value === tab) || {}).Component;
                        return (
                            <div className={classNames('trophy-case__store-cases', `trophy-case__store-cases--${tab}`)}>
                                {loading
                                ? <CommonLoader showLoader message='common__loading' />
                                : TabComponent ? <TabComponent name={tab} {...rest}/> :
                                <>
                                    {!trophyCase.length && <Header type="normal">{t('awards__trophy-case--no-records-found')}</Header>}
                                    {trophyCase.map(({ Store, contests, awards }) => (
                                        <div
                                            className={classNames(
                                                    'trophy-case__store-case',
                                                    { 'trophy-case__store-case--clickable': typeOfView === 'summary' }
                                            )}
                                            onClick={() => (typeOfView === 'summary') && showDetails(Store.StoreUID)}
                                            key={Store.StoreUID}
                                        >
                                            <Header
                                                type="normal"
                                            >
                                                {t('common__store--colon')} {R.compose(
                                                        R.join('-'),
                                                        R.filter(R.identity))([Store.StoreNumber, Store.StoreName])
                                                }
                                            </Header>
                                            {tab === 'summary' && this.renderSummaryCaseResults({ contests, awards, t })}
                                            {tab === 'detailed' && this.renderDetailedCaseResults({ contests, awards, t })}
                                        </div>
                                    ))}
                                </>
                                }
                            </div>
                        );
                    }}
                </Tabs>
            </div>
        );
    }
}

TrophyCaseComponent.propTypes = {
    onViewChange: PropTypes.func,
    trophyCase: PropTypes.array.isRequired,
    loading: PropTypes.bool
};

const TrophyCase = withTranslation()(TrophyCaseComponent);

export { TrophyCase };
