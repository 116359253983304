import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import './BadgePill.scss';

export const BadgePill = cond([
    [
        ({ children }) => children,
        ({ className, children }) => (
            <div className={classNames('hme-badge-pill', className)}>{children}</div>
        )
    ],
    [T, () => null]
]);
