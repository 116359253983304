import { Rank } from '../../Rank';

export const overallToRow = (overall) => {
    const className = overall.Rank === 1 ? 'hme-contest-view-edit-form__results__row--top' : '';

    return {
        ...overall,
        metricValueString: String(overall.metricValue),
        rank: <Rank rank={overall.Rank} />,
        rowClassName: className,
        tileItemClassName: className
    };
};
