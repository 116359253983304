import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './GridCell.scss';

// Component that renders Grid cell
export const GridCell = ({
    className = '',
    lastInBlock = false,
    flex,
    children,
    ...props
}) => {
    return (
        <div
            {...props}
            className={classNames('hme-grid-cell', className, {
                'hme-grid-cell-last-in-block': lastInBlock,
            })}
            style={typeof flex !== 'undefined' ? { flex } : {}}
        >
            <div className={'hme-grid-cell-content'}>
                { (children || children === 0)  ? children : ' '} 
            </div>
        </div>
    );
};

GridCell.propTypes = {
    className: PropTypes.string,
    lastInBlock: PropTypes.bool,
    flex: PropTypes.number,
    children: PropTypes.node,
};
