import { equals } from 'ramda';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ADMIN_ROUTES } from 'constants/routes';
import {
    getCustomerNotification,
    editCustomerNotification
} from 'services/CustomerNotification';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { validateNotification } from '../../Form/validators/customerNotification';

const DEFAULT_NOTIFICATION = {
    texts: {
        'en-us': ''
    },
    isEnabled: false
};

export const useEdit = () => {
    const { notificationId } = useParams();
    const navigate = useNavigate();
    const [notification, setNotification] = useState(DEFAULT_NOTIFICATION);
    const [isSubmitable, setIsSubmitable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const initialNotificationRef = useRef();

    const onCancel = useCallback(() => {
        navigate(ADMIN_ROUTES.customerNotification);
    }, [navigate]);

    const getNotificationById = useCallback(async (id) => {
        try {
            setIsLoading(true);
            const notificationFromServer = await getCustomerNotification(id);

            initialNotificationRef.current = notificationFromServer;
            setNotification(notificationFromServer);
        } catch (err) {
            addErrorNotification(err.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const editNotification = useCallback(async () => {
        try {
            setIsLoading(true);

            await editCustomerNotification({ notificationId, ...notification });
            initialNotificationRef.current = notification;

            onCancel();
        } catch (err) {
            addErrorNotification(err.message);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                addSuccessNotification('admin__customer-notification__edit__notification__toast--success');
            }, 1000);
        }
    }, [notification, notificationId]);

    useEffect(() => {
        if (!notificationId) return;

        getNotificationById(notificationId);
    }, [notificationId, getNotificationById]);

    useEffect(() => {
        const isNotificaitonValid = validateNotification(notification);

        setIsSubmitable(
                isNotificaitonValid &&
                !equals(notification, initialNotificationRef.current)
        );
    }, [notification]);

    return {
        notification,
        isSubmitable,
        isLoading,
        title: 'admin__customer-notification__edit__title',
        onSave: editNotification,
        onCancel,
        onNotificationChange: setNotification
    };
};
