import React from 'react';

import { Title } from 'library/Title';

import './Header.scss';

export const Header = () => (
    <div className="hme-view-store-settings-task-header">
        <Title>View Task</Title>
    </div>
);
