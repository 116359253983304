import { CommonConstants } from 'Constants';
import { Config } from 'Config';
import { getDataAsync, postDataAsync, getSearchString } from 'Api';
import { isAdmin, getSelectedUUID } from './Auth';

const errorKeys = {
    HAS_USER: 'rolehasuser',
    ROLE_EXISTS: 'roleAlreadyExist',
};

const handleCreateRoleError = (response) => {
    if (!response.status) {
        switch (response.key) {
            case errorKeys.ROLE_EXISTS:
                throw new Error('add-role__error--role-exists');

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const getDistributorRolesList = async ({ accountUuid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccountRolesList}`;

    const params = {
        isAdmin: 0,
        auuid: accountUuid
    };

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const removeDistributorRole = async ({ accountUuid, role }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteDistributorAccountRole}`;

    const params = {
        auuid: accountUuid
    };

    const search = getSearchString(params);

    const data = {
        roleuid: role.uid
    };

    let response;

    try {
        response = await postDataAsync(`${url}?${search}`, data);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        switch (response.key) {
            case errorKeys.HAS_USER:
                const error = new Error('add-role__error--role-assigned');
                error.tOptions = {
                    roleName: role.Role_Name,
                    usersCount: response.usersCount
                };

                throw error;

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const getDistributorRoles = async ({ accountUuid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccountRoles}`;

    const params = {
        auuid: accountUuid
    };

    const search = getSearchString(params);

    const { data, status } = await getDataAsync(`${url}?${search}`);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return data;
};

export const getRoles = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getRolesList}`;
    const params = {
        isAdmin: isAdmin() ? 1 : 0,
        uuid: getSelectedUUID()
    };

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const getUserRoles = async (uuid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getUserRoles}`;
    const params = {
        uuid,
    };

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const getDistributorRole = async ({ accountUuid, roleUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccountRole}`;

    const params = {
        auuid: accountUuid,
        roleUid
    };

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const createDistributorRole = async ({ accountUuid, role }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createDistributorAccountRole}`;

    const params = {
        auuid: accountUuid
    };

    const search = getSearchString(params);

    let response;

    const payload = {
        permissionUid: role.permissions,
        roleName: role.name,
        roleDesc: role.description
    };

    try {
        response = await postDataAsync(`${url}?${search}`, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    handleCreateRoleError(response);
};

export const updateDistributorRole = async ({ accountUuid, role }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateDistributorAccountRole}`;

    const params = {
        auuid: accountUuid
    };

    const search = getSearchString(params);

    let response;

    const payload = {
        roleuid: role.uid,
        permissionUid: role.permissions,
        roleName: role.name,
        roleDesc: role.description
    };

    try {
        response = await postDataAsync(`${url}?${search}`, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    handleCreateRoleError(response);
};

export const removeRole = async ({ role }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteRole}`;
    const data = {
        roleuid: role.uid // remove distrib check
    };

    let response;

    try {
        response = await postDataAsync(`${url}`, data);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        switch (response.key) {
            case errorKeys.HAS_USER:
                const error = new Error('add-role__error--role-assigned');
                error.tOptions = {
                    roleName: role.Role_Name,
                    usersCount: response.usersCount
                };

                throw error;

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const createRole = async ({ role }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createRole}`;

    let response;

    const payload = {
        permissionUid: role.permissions,
        roleName: role.name,
        roleDesc: role.description,
    };

    try {
        response = await postDataAsync(`${url}`, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    handleCreateRoleError(response);

    return response;
};

export const getRole = async (roleUid) => {
    const params = { roleUid };
    const search = getSearchString(params);

    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getRoleById}?${search}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const updateRole = async ({ role, userUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateRole}`;

    let response;

    const payload = {
        roleuid: role.uid,
        permissionUid: role.permissions,
        roleName: role.name,
        roleDesc: role.description,
        userUid,
    };

    try {
        response = await postDataAsync(url, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    handleCreateRoleError(response);

    return response;
};
