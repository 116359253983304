import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Chart } from 'react-google-charts'

class LaneTransactions extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showStores: this.props.showStores,
      Ascending: true,
      criteria: null,
      pageSize: 10,
      offset: 0,
      data: [],
      recordCount: 5000,
      storeUid: this.props.storeUid,
      options: {
        isStacked: true,
        vAxis: { title: props.t('dashboard__transactions'), titleTextStyle: { bold: false, italic: false, color: '#a6a6a6' }, viewWindow: { min: 0 } },
        legend: 'none',
        focusTarget: 'category',
        tooltip: { isHtml: true },
        pointSize: 28,
        width: 900,
        height: 270,
        chartArea: {
          left: 70,
          top: 30,
          width: '900px'
        },
        orientation: 'horizontal',
        seriesType: 'bars',
        fontSize: 12,
        bar: { groupWidth: '75%' },
        colors: this.props.goalColors,
        annotations: {
          style: 'default',
          textStyle: {
            fontSize: 12,
            bold: true,
            color: 'white', // The color of the text.
            auraColor: 'none', // The color of the text outline.
            opacity: 1 // The transparency of the text.
          },
          boxStyle: {
            stroke: '#888', // Color of the box outline.
            strokeWidth: 1, // Thickness of the box outline.
            rx: 5, // x-radius of the corner curvature.
            ry: 5, // y-radius of the corner curvature.
            gradient: { // Attributes for linear gradient fill.
              color1: '#7fce00', // Start color for gradient.
              color2: '#7fce00', // Finish color for gradient.
              x1: '0%',
              y1: '0%', // Where on the boundary to start and end the
              x2: '100%',
              y2: '100%', // color1/color2 gradient, relative to the
              useObjectBoundingBoxUnits: true // If true, the boundary for x1, y1,
            }
          }
        }
      }
    }
    this.state.rows = this.props.rows
    this.state.columns = this.props.columns
    this.state.colors = this.props.goalColors
  }

  render () {
    const { t } = this.props;

    return (
      <div className='chartSection'>
        <Chart
          chartType='ColumnChart'
          data={[this.props.columns, ...this.props.rows]}
          options={{
            ...this.state.options,
            vAxis: {
              ...this.state.options.vAxis,
              title: t('dashboard__transactions')
            }
          }}
          graph_id='LaneTransactions'
          width={'900px'}
          height={'270px'}
          legend_toggle={false}
        />
        <h6 className='dp_label'>{t('common__daypart')}</h6>
        <div className='cbar clear'>
          {this.renderLegends()}
        </div>
      </div>
    )
  }

  renderLegends () {
    const { t } = this.props;

    return (
      <div className='cmiddle tableColors'>
        <div className='blabel'>
          <span><div className='dash_circle' style={{ backgroundColor: this.state.colors[0] }} /></span>
          <span className='dash_goal_pad'>&lt;<span>{t('common__goal')}</span> A</span>
        </div>
        <div className='blabel'><span><div className='dash_circle' style={{ backgroundColor: this.state.colors[1] }} /></span><span className='dash_goal_pad'>&lt; <span>{t('common__goal')}</span> B</span></div>
        <div className='blabel'><span><div className='dash_circle' style={{ backgroundColor: this.state.colors[2] }} /></span><span className='dash_goal_pad'>&gt;= <span>{t('common__goal')}</span> B</span></div>
      </div>)
  }
}

function mapStateToProps () {
  return {
  }
}
function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
    }, dispatch
  )
}

const LaneTransactionsWithTranslation = compose(
  withTranslation()
)(LaneTransactions);

export default connect(mapStateToProps, matchDispatchToProps)(LaneTransactionsWithTranslation)
