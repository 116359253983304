import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './StoreAccess.scss';

export const StoreAccess = ({ children, className = '' }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-store-access', className)}>
            <div className="hme-label">{t('add-user__label--store-access')}</div>

            <div className="hme-store-access__stores">{children}</div>
        </div>
    );
};
