/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import './TitledRow.scss';
import { RowTitle } from 'library/RowTitle';
import { RowText } from 'library/RowText';

export const TitledRow = ({ title, text1, text, className, children = [] }) => (
    <div className={classNames('hme-titled-row', className)}>
        <RowTitle>{title}</RowTitle>
        <RowText
            key={text1}
        >{text1}</RowText>
        <RowText
            key={text}
        >{text}</RowText>
        <div className="hme-titled-row-group">
            {children}
        </div>
    </div>
);
