import * as userAccount from '../../actionTypes/Accounts/userAccounts'

const initialState = {
  userAccounts: [
    {
      'User_ID': 0,
      'User_UID': '',
      'User_EmailAddress': '',
      'User_LastName': '',
      'User_FirstName': '',
      'Company_Name': '',
      'Company_Country_ID': 1,
      'Company_AvanteCust_ID': '',
      'Company_Region': 'AZ',
      'Company_ID': 144,
      'Company_UID': '',
      'Account_Subscription_ID': 0,
      'Account_StartDate': '',
      'Account_EndDate': '',
      'Account_IsTest': true,
      'Account_DFA_IsDFA': null,
      'Account_ID': 0,
      'Account_UID': '',
      'Company_Type': 'Franchise',
      'Brand_ID': null,
      'Distributor_UID': '',
      'LeaderboardMode_ID': 0
    }
  ],
  searchParams: { filter: '', criteria: '' },
  ownerAccount: {
    'User_ID': 0,
    'User_UID': '',
    'User_EmailAddress': ''
  }
}

export default function userAccountDetails (state = initialState, action) {
  switch (action.type) {
    case userAccount.GET_ADMIN_ACCOUNT:
      state.userAccounts = action.userAccounts
      return {
        ...state
      }
    case userAccount.GET_ADMIN_ACCOUNT_LIST:
      state.userAccounts = action.userAccountsList
      return {
        ...state
      }
    case userAccount.SET_SEARCH_PARAMS_ACCOUNTS:
      state.searchParams = action.searchParams
      return {
        ...state
      }
    case userAccount.SET_OWNER_ACCOUNT:
      const { ownerAccount } = action;
      return {
        ...state,
        ownerAccount
      }
    default:
      return state
  }
}
