import { reportNames } from "components/LandingPage/ReportLandingPage/reportConstant";

export const reportType = {
    multiStore: 'multi',
    singleStore: 'single',
    paginated: 'paginated',
    scheduler: 'scheduler'
};

export const reportAccount = {
    singleAccount: 'globant-candrade@HME.com',
    multiAccount: 'globant-candrade@HME.com',
    paginatedAccount: 'suworow+altexsoft01@gmail.com'
};

export const reportFormat = {
    csv: 'CSV',
    pdf: 'PDF'
};

export const reportEvents = {
    loaded: 'loaded',
    rendered: 'rendered',
    error: 'error',
    firstRendered: 'firstRendered',
    unloaded: 'unloaded'
};

export const embedType = {
    report: 'report',
    dashboard: 'dashboard',
    tile: 'tile',
    visual: 'visual'
};

export const reportRoutes = {
    pbiDashboard: '/pbi-dashboard',
    pbiScheduler: '/pbi-scheduler',
    pbiReports: '/pbi-report/'
};
export const alertMessage = {
    success: 'Your report have been sent, please check your inbox',
    danger: 'There was a problem sending the report, please try again',
    loading: 'Loading ...',
    schedulerCreated: 'The Report will be sent in the next execution'
};

export const schedulerFrecuency = {
    day: 'Daily',
    month: 'Monthly',
    week: 'Weekly'
};

export const timezoneList = [
    {
        Name: 'GMT',
        Description: 'Greenwich Mean Time',
        RelativeGMT: 'GMT',
        Value: '0'
    },
    {
        Name: 'UTC',
        Description: 'Universal Coordinated Time',
        RelativeGMT: 'GMT',
        Value: '3'
    },
    {
        Name: 'ECT',
        Description: 'European Central Time',
        RelativeGMT: 'GMT+1:00',
        Value: '4'
    },
    {
        Name: 'EET',
        Description: 'Eastern European Time',
        RelativeGMT: 'GMT+2:00',
        Value: '5'
    },
    {
        Name: 'ART',
        Description: '(Arabic) Egypt Standard Time',
        RelativeGMT: 'GMT+2:00',
        Value: '6'
    },
    {
        Name: 'MET',
        Description: 'Middle East Time',
        RelativeGMT: 'GMT+3:30',
        Value: '7'
    }
];

export const dateTimeConfig = {
    locale: {
        en: 'en',
        fr: 'fr-ca'
    },
    format: 'MM/DD/YYYY'
};
export const pbiScheduler = {
    weekDays: [
        ('common__select'),
        ('common__sunday'),
        ('common__monday'),
        ('common__tuesday'),
        ('common__wednesday'),
        ('common__thursday'),
        ('common__friday'),
        ('common__saturday')
    ],
    monthDays: [
        ('common__select'),
        ('scheduler__monthly-01'),
        ('scheduler__monthly-02'),
        ('scheduler__monthly-03'),
        ('scheduler__monthly-04'),
        ('scheduler__monthly-05'),
        ('scheduler__monthly-06'),
        ('scheduler__monthly-07'),
        ('scheduler__monthly-08'),
        ('scheduler__monthly-09'),
        ('scheduler__monthly-10')
    ],
    RECURRENCE_VALUES: {
        MONTHLY: 0,
        WEEKLY: 1,
        DAILY: 2,
        SELECT: 4
    },
    recurrences: [
        {
            text: 'common__select',
            value: 4
        },
        {
            text: 'scheduler__recurrence-daily',
            value: 2
        },
        {
            text: 'scheduler__recurrence-weekly',
            value: 1
        },
        {
            text: 'scheduler__recurrence-monthly',
            value: 0
        }
    ],
    reportTypes: [
        {
            text: 'common__select',
            value: null
        },
        {
            text: ('reports__summary__single-store'),
            value: 'single'
        },
        {
            text: ('reports__summary__multi-store'),
            value: 'multi'
        },
        {
            text: ('reports__trends'),
            value: 'trends'
        }
    ],
    hierarchyLevels: [
        {
            text: ('reports__stores-hierarchy-level-store'),
            value: 0
        },
        {
            text: ('reports__stores-hierarchy-level-1'),
            value: 1
        },
        {
            text: ('reports__stores-hierarchy-level-2'),
            value: 2
        },
        {
            text: ('reports__stores-hierarchy-level-3'),
            value: 3
        },
        {
            text: ('reports__stores-hierarchy-level-4'),
            value: 4
        }
    ],
    timeMeasures: [
        {
            text: ('common__none'),
            value: 0
        },
        {
            text: ('common__daypart'),
            value: 1
        },
        {
            text: ('common__shift'),
            value: 2
        },
        {
            text: ('common__hour'),
            value: 3
        }
    ],
    formats: ['XLSX', 'PDF'],


    timeFormats: {
        buttons: [
            {
                text: 'common__minute-seconds',
                value: 'min:sec'
            }, {
                text: 'common__seconds',
                value: 'sec'
            }
        ],
        label: 'common__time-format',
        tooltipText: null,
        key: 'timeFormat'
    },

    pullInsList: {
        buttons: [
            {
                text: 'common__include',
                value: 1
            }, {
                text: 'common__exclude',
                value: 0
            }
        ],
        label: 'common__pull-ins',
        tooltipText: null,
        key: 'pullIns'
    },
    daypartIDsList: [
        {
            value: 1,
            selected: false
        },
        {
            value: 2,
            selected: false
        },
        {
            value: 3,
            selected: false
        },
        {
            value: 4,
            selected: false
        },
        {
            value: 5,
            selected: false
        },
        {
            value: 6,
            selected: false
        },
        {
            value: 7,
            selected: false
        },
        {
            value: 8,
            selected: false
        },
        {
            value: 9,
            selected: false
        },
        {
            value: 10,
            selected: false

        },
        {
            value: 11,
            selected: false

        },
        {
            value: 12,
            selected: false

        },
        {
            value: 13,
            selected: false

        },
        {
            value: 14,
            selected: false

        },
        {
            value: 15,
            selected: false

        },
        {
            value: 16,
            selected: false

        },
        {
            value: 17,
            selected: false

        },
        {
            value: 18,
            selected: false

        },
        {
            value: 19,
            selected: false

        },
        {
            value: 20,
            selected: false

        },
        {
            value: 21,
            selected: false

        },
        {
            value: 22,
            selected: false

        },
        {
            value: 23,
            selected: false

        }
    ]
};
export const pbiErrorMessage = {
    TOKEN_EXPIRED: 'TokenExpired'
};

export const scheduleSectionsValidator = {
    requiredFields: [
        'reportName',
        'reportSubject',
        'mails',
        'format',
        'reportType',
        'pullIns',
        'timeFormat',
        'timeMeasureID',
        'timeZoneID',
        'timezone_code',
        'recurrenceID'
    ]
};

export const downloadableReports = [
    reportNames.multi,
    reportNames.single,
    reportNames.trends
];

export const reportsWithoutTemplates = [
    reportNames.rcd
];

export const aasFilterTableName = {
    'self-service': 'canvas_Date_Filter',
    'overview': 'KPI Metrics'
};
