export const headers = [{
    text: 'common__name',
    property: 'PartnerName',
    sortable: true,
}, {
    text: 'admin__partner__form__label--types',
    property: 'types'
}, {
    text: 'common__country',
    property: 'Country_Name',
    flex: 0.75
}, {
    text: 'admin__partner__status__grid__header--owner-name',
    property: 'owner'
}, {
    text: 'common__status',
    property: 'status',
    flex: 0.5,
}, {
    text: 'common__email',
    property: 'EmailAddress',
    flex: 2,
}, {
    text: 'common__actions',
    property: 'actions',
    className: 'hme-grid-action-cell',
    headerClassName: 'hme-grid-centered-cell',
    flex: 0.75
}];

export const INIT_SORTING = {
    PartnerName: 1,
};

export const INIT_SEARCH = {
    selectValue: '',
    searchValue: '',
};
