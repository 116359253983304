import React from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import { DeviceStatus } from './DeviceStatus/DeviceStatus';
import { useSystemStatus, useDevicesGrid } from './hooks';

import './SystemStatus.scss';

const PublicSystemStatusComponent = ({ isNitro = false }) => {
    const {
        device,
        deviceSettings,
        deviceUpgradeVersions,
        deviceScheduledUpgrade,
        isDataLoading,
        StatusInfoFields,
        error,
        onDeviceReboot,
        onDeviceReconnect,
        onLinkedDeviceReboot,
        onLinkedDeviceReconnect
    } = useSystemStatus();

    const {
        isConfirmShown,
        confirmWindowDetails,
        onConfirmHide,
        ...deviceGridProps
    } = useDevicesGrid({
        isNitro,
        device,
        deviceScheduledUpgrade,
        deviceSettings,
        deviceUpgradeVersions,
        onLinkedDeviceReconnect,
        onLinkedDeviceReboot
    });

    return (
        <>
            <NotificationsList />
            <DeviceStatus
                device={device}
                deviceSettings={deviceSettings}
                StatusInfoFields={StatusInfoFields}
                deviceGridProps={deviceGridProps}
                isLoading={isDataLoading}
                error={error}
                onDeviceReboot={onDeviceReboot}
                onDeviceReconnect={onDeviceReconnect}
            />
            <ConfirmPopupComponent
                show={isConfirmShown}
                message={confirmWindowDetails.message}
                onHide={onConfirmHide}
                onConfirm={() => confirmWindowDetails.handler()}
            />
        </>
    );
};

export const PublicSystemStatus = compose(
        withHMELayout({
            contentClasses: ['public-zoom-nitro-system-status-page']
        })
)(PublicSystemStatusComponent);
