import { invertObj } from 'ramda';

export const NOTIFICATION_TYPE_OPTIONS = [
    {
        id: 'banner',
        name: 'notification-type',
        value: 1,
        text: 'admin__customer-notification__type--banner'
    },
    {
        id: 'announcement',
        name: 'notification-type',
        value: 2,
        text: 'admin__customer-notification__type--announcement'
    }
];

export const CUSTOMER_NOTIFICATION_TYPES = NOTIFICATION_TYPE_OPTIONS.reduce((notificationObject, { value, id }) => {
    notificationObject[value] = id;

    return notificationObject;
}, {});

export const CUSTOMER_NOTIFICATION_TYPE_IDS = invertObj(CUSTOMER_NOTIFICATION_TYPES);

export const LOCAL_STORAGE_KEYS = {
    CUSTOMER_NOTIFICATION: 'CUSTOMER_NOTIFICATION'
};

export const DEFAULT_TEXT_LANGUAGE = 'en-us';
