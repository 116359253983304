import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getRoles } from 'services/Roles';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { getGroups } from 'services/Groups';
import { createUser } from 'services/Users';
import { trimValues } from 'helpers';
import { getStoresTree } from '../../Common/helpers/getStoresTree';
import { getGroupStores } from '../../Common/helpers/getGroupStores';
import { findGroups } from '../../Common/helpers/findGroups';
import { isUserValid, trimmedKeys, validateUserData } from '../../Common/validators/user';

const load = async (setRoles, setStoresTree, setGroupStores, setIsLoading) => {
    setIsLoading(true);

    const rolesPromise = getRoles();
    const groupsPromise = getGroups();

    try {
        const [roles, groups] = await Promise.all([rolesPromise, groupsPromise]);

        setRoles(roles.map(({ Role_UID, Role_Name }) => ({
            value: Role_UID,
            text: Role_Name
        })));

        if (groups) {
            setStoresTree(getStoresTree(groups));
            setGroupStores(getGroupStores(groups));
        } else {
            setStoresTree([]);
            setGroupStores({});
        }
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

const saveUser = async (
        user,
        groupStores,
        navigate,
        setIsLoading
) => {
    setIsLoading(true);

    try {
        await createUser({
            user: {
                ...trimValues(user, trimmedKeys),
                groups: findGroups(user.stores, groupStores)
            }
        });

        navigate(`/settings/users`);
        setTimeout(() => {
            addSuccessNotification('add-user__success--created');
        }, 1000);
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

export const useCreateUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [storesTree, setStoresTree] = useState([]);
    const [groupStores, setGroupStores] = useState({});
    const [user, setUser] = useState({
        userName: '',
        firstName: '',
        lastName: '',
        role: '',
        isActive: 1,
        stores: []
    });
    const [validationErrors, setValidationError] = useState({});

    const isSaveButtonDisabled = useMemo(() => !isUserValid(user), [user]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onSave = useCallback(() => {
        const errorData = validateUserData(user);
        if (errorData) {
            return setValidationError(errorData);
        }
        setValidationError({});
        return saveUser(user, groupStores, navigate, setIsLoading);
    }, [user, groupStores, navigate, setIsLoading, setValidationError]);

    useEffect(() => {
        load(setRoles, setStoresTree, setGroupStores, setIsLoading);
    }, [setRoles, setIsLoading]);

    return {
        title: t('users__header__add-user'),
        hasRemove: false,
        isLoading,
        user,
        storesTree,
        roles,
        isSaveButtonDisabled,
        showStatus: false,
        onUserChange: setUser,
        onCancel,
        onSave,
        validationErrors
    };
};
