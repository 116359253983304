import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'library/Modal';
import { LanguageSelect } from 'library/LanguageSelect';
import { DateLib } from '@hme-cloud/utility-common';

import './LanguageModal.scss';

export const LanguageModal = ({ show, onHide, onChange }) => {
    const [actions, setActions] = useState([]);
    const { t, i18n } = useTranslation();
    const [startLanguage, setStartLanguage] = useState(i18n.resolvedLanguage);
    const isLanguageChanged = useMemo(() => startLanguage !== i18n.resolvedLanguage, [startLanguage, i18n.resolvedLanguage]);

    const onCancel = useCallback(async () => {
        i18n.changeLanguage(startLanguage);
        DateLib.setLocale(startLanguage);
        onHide && onHide();
    }, [startLanguage, i18n, onHide]);

    useEffect(() => {
        setStartLanguage(i18n.resolvedLanguage);
    }, [show]);

    useEffect(() => {
        setActions([{
            children: t('common__cancel'),
            onClick: onCancel
        }, {
            children: t('language-select--modal__set_language'),
            disabled: !isLanguageChanged,
            variants: ['submit'],
            onClick: onChange,
        }]);
    }, [startLanguage, i18n.resolvedLanguage, t, onCancel, onChange, setActions]);

    return (
        <Modal dialogClassName='hme-change-language-modal' show={show} actions={actions}>
            <LanguageSelect />
        </Modal>
    );
};
