import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Loader } from 'library/Loader';
import { Tip } from 'library/Tip';
import { AutoCompleteInput } from 'library/AutoCompleteInput';

import { AccountBrandGrid } from './AccountBrandGrid';
import { useOnTrigger } from 'hooks/useTrigger';
import { SearchInput } from 'components/Inputs/SearchInput';
import { loadAccountDevices, loadAccountPeripheralDevices, loadAccounts } from './Controller';
import { CommonConstants } from 'Constants';
import { NEXEO_BASE_STATION, NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

const { deviceType } = CommonConstants;

import './AccountForm.css';

const suggestionsHeaders = [
    {
        text: 'device-upgrades__grid__header--email',
        property: 'User_EmailAddress',
    },
];

const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;
const SEARCH_ACCOUNT_DEBOUNCE_TIMEOUT = 500;
const searchAccountsByEmailDebounced = debounce(loadAccounts, SEARCH_ACCOUNT_DEBOUNCE_TIMEOUT);

const filterAccountDevices = (devices, filters) => {
    if (!filters.deviceTypeId) {
        return devices;
    }
    if (filters.peripheralDeviceTypeName) {
        return devices.filter(
            (d) => {
                return Array.isArray(d.peripheralDevices)
                    && d.peripheralDevices.some((pd) => 
                        filters.peripheralDeviceTypeName.includes(pd.deviceType)
                        && filters.targetDeviceVersions.includes(pd.deviceVersion));
            }
        );
    } else {
        return devices.filter(
            (d) => {
                return d.Device_DeviceType_ID === filters.deviceTypeId
                    && filters.targetDeviceVersions.includes(d.Device_MainVersion);
            }
        );
    }
};

export const AccountForm = ({
    devices,
    targetDeviceVersions,
    onSelectStoresByBrand,
    selectedDeviceType,
    selectedPeripheralDeviceType,
    removeDevicesTrigger,
    changeUpgradeInfoTrigger,
}) => {
    const [account, setAccount] = useState(null);
    const [isAccountStoresLoading, setIsAccountStoresLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [accountSearch, setAccountSearch] = useState('');
    const [isAccountsLoading, setIsAccountsLoading] = useState(false);
    const [accountStores, setAccountStores] = useState([]);
    const [selectedAccountStores, setSelectedAccountStores] = useState([]);
    const [selectedBrandDeviceIDsMap, setSelectedBrandDeviceIDsMap] = useState({});
    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (!account) {
            return;
        }

        const filters = {
            targetDeviceVersions,
            deviceTypeId: selectedDeviceTypeId
        }

        const params = {
            storeAccountId: account.Account_ID,
            deviceTypeId: selectedDeviceTypeId,
            setIsAccountStoresLoading,
            setAccountStores,
            filters,
            filterDevices: filterAccountDevices,
        };

        if (selectedDeviceTypeId === NEXEO_BASE_STATION.deviceTypeID && selectedPeripheralDeviceType === NEXEO_PERIPHERAL_TYPES.IB7000.modelName) {
            filters.peripheralDeviceTypeName = [NEXEO_PERIPHERAL_TYPES.IB7000.modelName, NEXEO_PERIPHERAL_TYPES.SM7000.modelName];
            loadAccountPeripheralDevices(params)
        } else {
            loadAccountDevices(params);
        }
    }, [account]);

    useEffect(() => {
        if (!selectedDeviceType) {
            return;
        }

        setSelectedDeviceTypeId(deviceType[selectedDeviceType].id);
    }, [selectedDeviceType]);

    const onAccountSelected = useCallback((accountSelected) => {
        if (!accountSelected) {
            return;
        }

        setAccountSearch(accountSelected.User_EmailAddress);
        setAccount(accountSelected);
    }, []);

    const onAccountsFound = useCallback(
        (foundAccounts, value) => {
            // handle case when user types full email without selecting it from the list
            const foundAccount = foundAccounts.find((acc) => acc['User_EmailAddress'] === value);

            if (!foundAccount) {
                setAccountStores([]);
            }

            onAccountSelected(foundAccount);
        },
        [onAccountSelected],
    );

    const resetSearchResults = useCallback(() => {
        searchAccountsByEmailDebounced.cancel();

        setAccounts([]);
        setAccountStores([]);
        setAccount(null);
    }, []);

    const onAccountChange = useCallback(
        (searchValue) => {
            searchValue.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH
                ? resetSearchResults()
                : searchAccountsByEmailDebounced({
                      searchValue,
                      setAccounts,
                      setIsAccountsLoading,
                      onDone: (foundAccounts) => onAccountsFound(foundAccounts, searchValue),
                  });

            setAccountSearch(searchValue);
        },
        [onAccountsFound, resetSearchResults],
    );

    const onAccountStoreSelection = useCallback(
        (stores) => {
            // map device ids by brand names
            const brandDeviceIDsMap = stores.reduce(
                (acc, s) => ({
                    ...acc,
                    [s.accountBrandKey]: selectedBrandDeviceIDsMap[s.accountBrandKey] || s.deviceIDs,
                }),
                {},
            );

            const selectedStoresList = accountStores.filter((store) => {
                const accountBrandKey = `${store.Brand_Name}|${store.User_Company_ID}`;
                const activeDevice = brandDeviceIDsMap[accountBrandKey] && brandDeviceIDsMap[accountBrandKey].includes(store.Device_ID);
                return activeDevice && stores.some((s) => s.accountBrandKey === accountBrandKey);
            });

            const existingAccountDevices = devices.filter(
                (d) => !accountStores.some((accStore) => accStore.Device_ID === d.Device_ID),
            );

            setSelectedBrandDeviceIDsMap(brandDeviceIDsMap);
            setSelectedAccountStores(stores);
            onSelectStoresByBrand([...existingAccountDevices, ...selectedStoresList]);
        },
        [selectedBrandDeviceIDsMap, accountStores, devices],
    );

    const onDevicesRemove = (deviceIDs) => {
        const accountStoresToRemove = [];

        const remainedBrandDeviceIdsMap = Object.keys(selectedBrandDeviceIDsMap).reduce((acc, accountBrandKey) => {
            const remainedDeviceIDs = selectedBrandDeviceIDsMap[accountBrandKey].filter(
                (dID) => !deviceIDs.includes(dID),
            );

            if (!remainedDeviceIDs.length) {
                accountStoresToRemove.push(accountBrandKey);

                return acc;
            }

            return {
                ...acc,
                [accountBrandKey]: remainedDeviceIDs,
            };
        }, {});

        const remainedStores = selectedAccountStores.filter(
            (store) => !accountStoresToRemove.includes(store.accountBrandKey),
        );

        setSelectedBrandDeviceIDsMap(remainedBrandDeviceIdsMap);
        setSelectedAccountStores(remainedStores);
    };

    const onChangeUpgradeType = () => {
        setAccount(null);
        setAccountStores([]);
        setSelectedAccountStores([]);
        setSelectedBrandDeviceIDsMap({});

        setAccountSearch('');
        setAccounts([]);
    };

    useOnTrigger(removeDevicesTrigger, onDevicesRemove);
    useOnTrigger(changeUpgradeInfoTrigger, onChangeUpgradeType);

    return (
        <div className="hme-create-device-upgrade-account-form">
            <div className="hme-create-device-upgrade-form-section">
                <div className="hme-create-device-upgrade-account-form-input-row">
                    <AutoCompleteInput
                        value={accountSearch}
                        suggestions={accounts}
                        isLoading={isAccountsLoading}
                        headers={suggestionsHeaders}
                        rowKey="User_EmailAddress"
                        className="hme-account-input"
                        placeholder={t('device-upgrades__section__search__accounts__placeholder')}
                        noSuggestionText={t('device-upgrades__section__search__accounts__not-found')}
                        Component={SearchInput}
                        onChange={onAccountChange}
                        onSuggested={onAccountSelected}
                    />
                    <Tip className="hme-create-device-upgrade-account-form-tip">
                        {t('device-upgrades__section__warning__add-devices-multiple-accounts')}
                    </Tip>
                </div>
            </div>
            {isAccountStoresLoading ? (
                <Loader variants={['invert']} />
            ) : (
                <>
                    {account && !accountStores.length && (
                        <div className="hme-create-device-upgrade-devices-not-found">
                            {t('common__no-devices--found')}
                        </div>
                    )}
                    {accountStores.length > 0 && (
                        <AccountBrandGrid
                            accountStores={accountStores}
                            onAccountStoreSelection={onAccountStoreSelection}
                            selectedAccountStores={selectedAccountStores}
                        />
                    )}
                </>
            )}
        </div>
    );
};
