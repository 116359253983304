import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { scheduledUpgradesApi } from 'api/scheduledUpgradesApi';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';

const { downloadDevicesUpgrades } = scheduledUpgradesApi;

export const useLoadUpgradesCsv = () => {
    const { t } = useTranslation();
    const { run: runLoadUpgradesCsv, data: upgradesCsv, error: upgradesCsvLoadingError, status } = useAsync();

    const loadUpgradesCsv = useCallback((scheduledUpgrade) => {
        runLoadUpgradesCsv(downloadDevicesUpgrades(scheduledUpgrade))
            .then(() => {
                addSuccessNotification('device-upgrades__notification__upgrade-successfully-downloaded', {
                    autoClose: 3000,
                });
            })
            .catch(() => {
                addErrorNotification('common__error--internal-server', { autoClose: 3000 });
            });
    }, []);

    return {
        upgradesCsv: upgradesCsv || null,
        isUpgradesCsvLoading: status === ASYNC_STATUS.PENDING,
        upgradesCsvLoadingError,
        loadUpgradesCsv,
    };
};
