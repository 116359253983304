import compareTexts from 'helpers/sortComparators/text';
import compareDates from 'helpers/sortComparators/date';
import compareVersions from 'helpers/sortComparators/version';

export const getSortComparators = (sortSelection = {}) => ({
    brandName: (a, b) => compareTexts(a.brandName, b.brandName, sortSelection.brandName),
    IB7000Version: (a, b) => compareVersions(a.IB7000Version, b.IB7000Version, sortSelection.IB7000Version),
    nexeoVersion: (a, b) => compareVersions(a.nexeoVersion, b.nexeoVersion, sortSelection.nexeoVersion),
    zoomNitroVersion: (a, b) => compareVersions(a.zoomNitroVersion, b.zoomNitroVersion, sortSelection.zoomNitroVersion),
    updatedBy: (a, b) => compareTexts(a.updatedBy, b.updatedBy, sortSelection.updatedBy),
    updateDateTime: (a, b) => compareDates(a.updateDateTime, b.updateDateTime, sortSelection.updateDateTime),
});
