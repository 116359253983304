import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { removeRole, getRoles } from 'services/Roles';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { isAdmin } from 'services/Auth';
import { useAccess } from 'hooks/useAccess';
import { CommonConstants } from 'Constants';
import { accountOwner } from '../../Common/constants/accountOwner';
import { headers } from '../constants/headers';
import { mobileHeaders } from '../constants/mobileHeaders';
import { roleToRow } from '../../Common/helpers/roleToRow';
import {INIT_PAGINATION_VALUES} from "../../../../DistributorsAccounts/constants/initialPaginationValues";
import {getShowedRows} from "../../../../DistributorsAccounts/helpers/getShowedRows";
import {USERS_LIST_FILTER_OPTIONS} from "../../../../Users/List/constants";
import {usersToRows} from "../../../../Users/List/Public/helpers/usersToRows";

const load = async (setRoles, setIsLoading, setRecordsTotal) => {
    setIsLoading(true);

    try {
        const roles = await getRoles({ isAdmin: 0 });
        const allRroles = [
            {
                ...accountOwner,
                Company_Name: !!roles.length ? roles[0].Company_Name : '',
            },
            ...roles,
        ];
        setRoles(allRroles);
        setRecordsTotal(allRroles.length);
    } catch (err) {
        setRoles([{
            ...accountOwner,
            Company_Name: '',
        }]);
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

const remove = async (role, t) => {
    if (role.iosAccountOwner) {
        return false;
    }

    try {
        await removeRole({
            role,
        });

        addSuccessNotification('add-role__success--deleted');
    } catch (err) {
        addErrorNotification(t(err.message, err.tOptions));

        return false;
    }

    return true;
};
const addRoleUrl = '/settings/roles/add-role';
const baseViewLink = '/settings/roles/edit-role';

export const usePublic = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rows, setRows] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);
    const [originRows, setOriginRows] = useState([]);

    const isAdminUser = useMemo(() => isAdmin(), []);
    const hasManageRoleAccess = useAccess(CommonConstants.userPermissions.ManageRole);

    const onRemove = useCallback(async (role) => {
        setIsLoading(true);

        const isRemoved = await remove(role, t);

        if (!isRemoved) {
            setIsLoading(false);

            return;
        }

        load(setRoles, setIsLoading, setRecordsTotal);
    }, [t, setRoles, setIsLoading]);

    const getRolesPagination = useCallback((filteredAccounts, paginationData) => {
        const { pageNumber: page, pageSize: recordsPerPage } = paginationData;
        return (recordsPerPage > 0) ? filteredAccounts.slice(page * recordsPerPage, (page + 1) * recordsPerPage) : filteredAccounts ;
    }, []);

    useEffect(() => {
        if (originRows.length) {
            const  newShowedRows  = getRolesPagination(
                originRows,
                    paginationData,
            );
            setRows(newShowedRows);
        }
    }, [originRows, paginationData, setRows]);

    const onPaginateChange = useCallback(({ page, recordsPerPage }) => {
        const newPaginationData = { pageSize: recordsPerPage, pageNumber: page };
        setPaginationData(newPaginationData);
    }, [setPaginationData]);

    useEffect(() => {
        load(setRoles, setIsLoading, setRecordsTotal);
    }, [setRoles, setIsLoading, setRecordsTotal]);

    useEffect(() => {
        const newRows = roles.map((role) => roleToRow(role));
        setOriginRows(newRows);

    }, [roles, setOriginRows]);

    return {
        hasCreateAccess: !isAdminUser && hasManageRoleAccess,
        hasRemoveAccess: hasManageRoleAccess,
        hasViewAccess: hasManageRoleAccess,
        withAccountEmail: true,
        addRoleUrl,
        isLoading,
        rows,
        headers,
        baseViewLink,
        mobileHeaders,
        onRemove,
        recordsTotal,
        paginationData,
        onPaginateChange
    };
};
