import React, { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { CenterLoader } from 'components/Common/CenterLoader';
import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { forgotPassword, isPartner } from 'services/Auth';
import { Title } from 'library/Title';
import { ForgetPasswordForm } from './ForgetPasswordForm';
import { defaultNotificationOptions as loginNotificationOptions } from 'constants/login';

import './ForgotPassword.scss';

import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const onReset = useCallback(async (data) => {
        setIsLoading(true);

        await forgotPassword(data);

        navigate(isPartner() ? '/partner' : '/');
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: 'forgot-pass__request-sent',
        }, loginNotificationOptions);
    }, [location]);

    if (isLoading) {
        return (
            <CenterLoader>{t('common__loading')}</CenterLoader>
        );
    }

    return (
        <div className='hme-forgot-password'>
            <Title>{t('forgot-pass__reset-button')}</Title>
            <div className='hme-forgot-password__info'>
                <Trans i18nKey='hme-forgot-password__info'>
                    <p className='hme-forgot-password__info__line'>
                        Enter your account email address.
                    </p>
                    <p className='hme-forgot-password__info__line'>
                        We’ll send further instructions to this email address.
                    </p>
                </Trans>
            </div>
            <ForgetPasswordForm t={t} onReset={onReset} />
        </div>
    );
};

export const ForgotPassword = compose(
    withHMELayout(),
)(ForgotPasswordPage);
