import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { DateLib } from '@hme-cloud/utility-common';
import { useProfile } from 'components/Security/useProfile';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import * as contestsApiService from 'services/LeaderboardContests';

import {
    CONTEST_STATUSES,
    CONTEST_STATUSES_ROW_CLASS_NAMES,
    CONTEST_STATUS_TRANSLATION_KEYS,
    CONTEST_TYPES_AVAILABLE,
    CONTEST_TYPE_VALUES
} from '../../../Common/Constants';
import { StatusCell } from '../../../Common/StatusCell';
import { Actions } from '../Actions';

export const LIST_HEADERS = [
    {
        text: 'common__name',
        property: 'ContestName',
        flex: 0.8
    },
    {
        text: 'common__description',
        property: 'ContestDesc'
    },
    {
        text: 'common__start-date-and-time',
        property: 'FormattedStartDate',
        sortable: true,
        flex: 0.8
    },
    {
        text: 'common__end-date-and-time',
        property: 'FormattedEndDate',
        sortable: true,
        flex: 0.8
    },
    {
        text: 'common__status',
        property: 'ContestStatusComponent',
        flex: 0.5
    },
    {
        text: 'common__actions',
        property: 'Actions',
        headerClassName: 'hme-grid-centered-cell'
    }
];

export const MOBILE_LIST_HEADERS = [
    {
        column: [
            { text: 'common__name', property: 'ContestName' },
            { text: 'common__start-date-and-time', property: 'FormattedStartDate' },
            { text: 'common__status', property: 'ContestStatusComponent' }
        ]
    },
    {
        column: [
            { text: 'common__description', property: 'ContestDesc' },
            { text: 'common__end-date-and-time', property: 'FormattedEndDate' }
        ]
    }
];

export const MOBILE_FILTER_OPTIONS = {
    ContestStatusComponent: {
        title: 'status',
        allText: 'users-list__grid__filter__roles__types'
    }
};

export const MOBILE_SORT_OPTIONS = [
    {
        label: 'contest__stores-start-date-sorting--asc',
        value: { FormattedStartDate: 1 }
    },
    {
        label: 'contest__stores-start-date-sorting--desc',
        value: { FormattedStartDate: -1 }
    },
    {
        label: 'contest__stores-end-date-sorting--asc',
        value: { FormattedEndDate: 1 }
    },
    {
        label: 'contest__stores-end-date-sorting--desc',
        value: { FormattedEndDate: -1 }
    }
];

export const DEFAULT_SORT_SELECTIONS = { ContestStatus: 0 };

export const CONTESTS_FILTER_OPTIONS = {
    ContestStatusComponent: {
        title: 'common__role',
        items: [
            {
                text: <Trans i18nKey={CONTEST_STATUS_TRANSLATION_KEYS.SCHEDULED} />,
                value: CONTEST_TYPE_VALUES.SCHEDULED
            },
            {
                text: <Trans i18nKey={CONTEST_STATUS_TRANSLATION_KEYS.IN_PROGRESS} />,
                value: CONTEST_TYPE_VALUES.IN_PROGRESS
            },
            {
                text: <Trans i18nKey={CONTEST_STATUS_TRANSLATION_KEYS.COMPLETED} />,
                value: CONTEST_TYPE_VALUES.COMPLETED
            }
        ],
        valuePropName: 'ContestStatus'
    }
};

export const useContests = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { Company_UID: companyUID } = useProfile();

    const [contests, setContests] = useState();

    const [contestUIDToCancel, setContestUIDToCancel] = useState('');
    const [isLoadingModalShown, setIsLoadingModalShown] = useState(false);
    const [certificateToShow, setCertificateToShow] = useState(null);

    const fetchContests = useCallback(async () => {
        const fetchedContests = await contestsApiService.fetchContests({
            companyUID,
            sort: 'StartDate',
            status: CONTEST_TYPES_AVAILABLE,
            statusOrder: CONTEST_TYPES_AVAILABLE,
            order: 'DESC'
        });

        const preparedContests = fetchedContests.map((contest) => {
            const contestStatusType = CONTEST_STATUSES[contest.ContestStatus];
            const className = CONTEST_STATUSES_ROW_CLASS_NAMES[contestStatusType];

            return {
                ...contest,
                FormattedStartDate: new DateLib(contest.StartDate).format(DateLib.FORMAT_TYPES.DATE_AND_TIME),
                FormattedEndDate: new DateLib(contest.EndDate).format(DateLib.FORMAT_TYPES.DATE_AND_TIME),
                ContestStatusComponent: <StatusCell statusType={contestStatusType} />,
                Actions: (
                    <Actions
                        statusType={contestStatusType}
                        uid={contest.ContestUID}
                        onCancel={setContestUIDToCancel}
                        showCertificate={() => setCertificateToShow(contest.ContestUID)}
                    />
                ),
                rowClassName: className,
                tileItemClassName: className
            };
        });

        setContests(preparedContests);
    }, []);

    const handleCancelContest = useCallback(async () => {
        if (!contestUIDToCancel) {
            return;
        }

        try {
            setIsLoadingModalShown(true);
            setContestUIDToCancel('');
            await contestsApiService.cancelContest(contestUIDToCancel);
            addSuccessNotification('contest__removed-contest--success', { autoClose: 5000 });
            await fetchContests();
        } catch (error) {
            addErrorNotification(error.message, { autoClose: 5000 });
        } finally {
            setIsLoadingModalShown(false);
        }
    }, [contestUIDToCancel]);

    const hideCertificateModal = useCallback(() => setCertificateToShow(null), []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await fetchContests();
                setIsLoading(false);
            } catch (err) {
                console.log({ err });
            }
        })();
    }, [fetchContests]);

    return {
        isLoading,

        contests,
        setContests,

        contestUIDToCancel,
        setContestUIDToCancel,

        cancelContest: handleCancelContest,

        certificateToShow,
        hideCertificateModal,

        isLoadingModalShown
    };
};
