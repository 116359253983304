import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Trans } from 'react-i18next';

import { useTokensLoader } from '../useTokensLoader';
import { getSortedRows } from './utils';
import { useTokensState } from './useTokensState';
import {PAGE_DEFAULT} from "constants/paginationDefault";
import { getTokenNameTranslation } from 'constants/token';

const prepareFetchedTokens = (token) => ({
    ...token,
    apiName: <Trans i18nKey={getTokenNameTranslation(token.apiName)} />,
    originalExpiryDate: token.expiryDate
});

export const useTokensList = () => {
    const { tokens, setTokens } = useTokensState([]);
    const { fetchedTokens, isLoading } = useTokensLoader();

    const [sortSelection, setSortSelection] = useState({ expiryDate: 1 });

    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);
    const [totalRows, setTotalRows] = useState(0);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, []);

    const paginatedRows = useMemo(
        () => tokens.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage),
        [tokens, pageNumber, itemsPerPage]
    )

    const onSortChange = useCallback((newSorting) => {
        setSortSelection(newSorting);
        setTokens(getSortedRows(tokens, newSorting));
    }, [tokens, setTokens]);


    useEffect(() => {
        if (!fetchedTokens) return;

        setTokens(fetchedTokens.map(prepareFetchedTokens));
        setTotalRows(fetchedTokens.length)
    }, [fetchedTokens]);

    return {
        tokens,
        isLoading,
        sortSelection,
        onSortChange,
        pageNumber,
        itemsPerPage,
        totalRows,
        onPaginationChange,
        paginatedRows
    };
};
