/* eslint-disable complexity */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import AuthenticationService from './AuthenticationService';
import { Config } from '../../Config';
import { Navigate } from 'react-router-dom';
import { checkAccess } from '../../actions/permissions';
import { PUBLIC_ROUTES } from 'constants/routes';

import { CommonConstants } from 'Constants';

const hasAdminPermission = (permission) => Object.keys(CommonConstants.adminPermissions).includes(permission);
const isAdminPage = (permission) => hasAdminPermission(permission) || window.location.pathname.indexOf('/admin') > -1;

/**
 * The method is used to display GoalStatisticsData
 * @param  {JSX.Element} AuthenticationComponent
 * @param  {string} adminPermission
 * @return  {JSX} component's template
 * @public
 */
export default function authenticate(AuthenticationComponent, adminPermission) {
    return class AuthWrapped extends Component {
        /**
         * This constructor is usd to set initial state
         * @param  {input} props
         */
        constructor() {
            super();
            this.authService = new AuthenticationService(Config.authBaseUrl);
            this.state = {
                user: null,
                isLoggedIn: false,
                url: null,
                reirectTo: null
            };
        }

        /**
         * React lifescycle method before Component mount
         */
        UNSAFE_componentWillMount() {
            if (!this.authService.isLoggedIn()) {
                const isAdmin = adminPermission ? isAdminPage(adminPermission) : this.authService.isAdmin();
                this.setState({ url: window.location.pathname + window.location.search });
                this.setState({ isLoggedIn: false, redirectTo: isAdmin ? '/admin' : '/' });
            } else if (window.location.pathname.indexOf('/admin') > -1 && !this.authService.isAdmin()) {
                this.setState({ redirectTo: '/forbidden' });
            } else {
                const user = this.authService.getProfile();
                this.setState({user});
                if (window.location.pathname.indexOf('/admin') > -1 || window.location.pathname.indexOf('/systems') > -1) {
                    if (user.User_EmailAddress) {
                        this.setState({ redirectTo: '/welcome' });
                    }
                }

                if (adminPermission && this.authService.isAdmin() && !checkAccess(adminPermission)) {
                    this.setState({ redirectTo: '/forbidden' });
                    return;
                }

                if (!this.authService.isMasquerade()) {
                    const TOSAgree = this.authService.getProfile().Account_TOS_Agree;
                    const accountDFAIsDFA = this.authService.getProfile().Account_DFA_IsDFA;
                    const isDFAVerified = window.localStorage.getItem('isDFAVerified') || 0;
                    if (!isDFAVerified && accountDFAIsDFA !== null && accountDFAIsDFA === 1) {
                        if (window.location.pathname !== '/validateDfaAuth') {
                            window.location.href = '/validateDfaAuth';
                        }
                    } else if (TOSAgree === 0 || TOSAgree === null) {
                        if (window.location.pathname !== `/${PUBLIC_ROUTES.newAccount}` && window.location.pathname !== '/logout') {
                            window.location.href = `/${PUBLIC_ROUTES.newAccount}`;
                        }
                    }
                }
                // redirect to https in case it is http url irrespective of user authentication level.
                if (window.location.hostname !== 'localhost') {
                    if (window.location.pathname !== '/connect' && window.location.protocol === 'http:') {
                        window.location.href = window.location.href.replace('http', 'https');
                    }
                }

                this.setState({ isLoggedIn: true });
            }
        }
        /**
         * Main render function
         * @return {JSX} component's template
         */
        render() {
            const { isLoggedIn, redirectTo, url, user } = this.state;
            if (isLoggedIn) {
                return (
                    <AuthenticationComponent {...this.props} user={user} />
                );
            } else {
                return (<Navigate
                    to={{
                        pathname: redirectTo,
                        state: { referrer: url  }
                    }} />);
            }
        }
    };
}
