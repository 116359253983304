import { cond, T } from 'ramda';

import { snapshotConfig } from 'constants/manageDeviceSettings';
import { InProgress } from './InProgress';
import { Created } from './Created';
import { Failed } from './Failed';
import { SetToExpire } from './SetToExpire';

const { statuses } = snapshotConfig;

const isStatus = expected => ({ status }) => expected === status;

const isDraft = isStatus(statuses.DRAFT);
const isinProgress = isStatus(statuses.IN_PROGRESS);
const isCreated = isStatus(statuses.CREATED);
const isFailed = isStatus(statuses.FAILED);
const isSetToExpire = isStatus(statuses.SET_TO_EXPIRE);

export const StatusCell = cond([
    [isDraft, InProgress],
    [isinProgress, InProgress],
    [isCreated, Created],
    [isFailed, Failed],
    [isSetToExpire, SetToExpire],
    [T, () => null],
]);
