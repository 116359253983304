import { Children, useMemo } from 'react';
import classNames from 'classnames';

import { Separator } from 'library/Separator';

import './GridActions.scss';

const defaultVariants = [];

export const GridActions = ({ className, variants = defaultVariants, children }) => {
    const showChildren = useMemo(() => Children.toArray(children)
        .filter((child) => child)
        // Use Reduce instead of map because I don't want to wrap with Fragment
        .reduce((val, child, index) => {
            const isFirst = index === 0;

            if (!isFirst) {
                val.push(<Separator key={`separator__${index}`}/>);
            }

            val.push(child);

            return val;
        }, []), children);

    return (
        <div className={classNames(
            'hme-grid-actions',
            className,
            variants.map((variant) => `hme-grid-actions--${variant}`)
        )}>
            {
                showChildren
            }
        </div>
    );
};
