import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { useEffect, useMemo } from 'react';
import { getDataAsync } from 'Api';
import { getLoggedInUserUID, getProfile, getUUID, isAdmin } from 'services/Auth';

const API_BASE_URL = Config.apiBaseUrl;
const STORES_API_URL = CommonConstants.apiUrls.getStores;

const buildQueryString = (params) => {
    return Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
};

const uuid = isAdmin() ? getUUID() : getLoggedInUserUID();
/**
 *  Hook that handles stores and permission checking before executing the callback
 *  @param {callback} callback function to execute after stores and permissions are checked
 *  @param {function} setShowCommonLoader
 *  @param {function} setDetailsChecked
 *  @param {object} detailsChecked
 *  @param {object} dependencies array or variable with the name of the dependencies
 *  @param {object} initQueryParams parameters for getAllStores API call
 */
export const useCheckAccount = (
        callback,
        setShowCommonLoader,
        setDetailsChecked,
        detailsChecked,
        dependencies,
        initQueryParams = {
            uuid: uuid,
            timerDevicesAndNonNitroEssentialsOnly: true,
            includeDaypartIDs: false
        }
) => {
    const queryParams = useMemo(() => buildQueryString(initQueryParams), [initQueryParams]);
    useEffect(() => {
        const getUserPermissions = async () => {
            try {
                const profile = getProfile();
                if (profile && profile.Subscription_ID !== Number(CommonConstants.subscription.nitroEssentials)) {
                    setDetailsChecked({
                        ...detailsChecked,
                        noPermissions: false
                    });
                } else {
                    setDetailsChecked({
                        ...detailsChecked,
                        accountError: true,
                        noPermissions: true
                    });
                    setShowCommonLoader(false);
                    throw new Error('No permissions');
                }
            } catch (error) {
                console.error(error.message);
                throw error;
            }
        };

        const getUserStores = async () => {
            try {
                const url = `${API_BASE_URL}${STORES_API_URL}?${queryParams}`;
                const data = await getDataAsync(url);
                if (data.status) {
                    if (data.storeList.length === 0) {
                        // No stores
                        setDetailsChecked({
                            ...detailsChecked,
                            noStores: true,
                            accountError: true
                        });
                        setShowCommonLoader(false);
                        throw new Error('No stores');
                    } else {
                        setDetailsChecked({
                            ...detailsChecked,
                            noStores: false,
                            accountError: false
                        });
                        return data;
                    }
                } else {
                    // Status is false
                    setDetailsChecked({
                        ...detailsChecked,
                        noStores: true,
                        accountError: true
                    });
                    setShowCommonLoader(false);
                    throw new Error('Failed to fetch stores');
                }
            } catch (error) {
                console.error(error.message);
                throw error;
            }
        };

        const activate = async () => {
            try {
                await getUserPermissions();
                const stores = await getUserStores();
                callback(stores);
            } catch (error) {
                console.log(error.message);
            }
        };

        activate();

        return () => {
            // clean up
            setDetailsChecked({});
            setShowCommonLoader(true);
        };
    }, [dependencies]);
};
