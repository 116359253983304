import { CONTEST_STATUSES } from '../../../Common/Constants';
import { useLoadContest } from './useLoadContest';

export const useContestInfo = () => {
    const { contest, isLoading } = useLoadContest();

    return {
        isLoading,
        contest,
        contestType: CONTEST_STATUSES[contest.status]
    };
};
