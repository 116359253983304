import { flatten } from 'ramda';

import { EventEmitter } from 'helpers/eventEmitter';
import { getAllDevices } from 'services/Device';

export class LoaderByStore {
    constructor() {
        /*
            structure: {
                [storeId]: {
                    divice,
                    isLoading,
                    error,
                }
            }
        */
        this._stores = {};
        this._eventEmitter = new EventEmitter();
    }

    isLoading() {
        return Object
            .keys(this._stores)
            .some(storeId => this._stores[storeId].isLoading);
    }

    getDevices() {
        return flatten(Object.keys(this._stores).map((storeId) => this._stores[storeId].devices));
    }

    on(eventName, handler) {
        this._eventEmitter.on(eventName, handler);
    }

    off(eventName, handler) {
        this._eventEmitter.off(eventName, handler);
    }

    reset() {
        this._stores = {};
    }

    async update(filters) {
        const { storeIds } = filters;
        const ids = storeIds.map(storeId => storeId.toString());

        ids.map((storeId) => {
            if (!this._stores[storeId]) {
                this._load(storeId, filters);
            }
        });

        Object.keys(this._stores)
            .filter((storeId) => !ids.includes(storeId))
            .forEach((storeId) => this._remove(storeId));

        this._eventEmitter.trigger('changed');
    }

    removeDevice(device) {
        if (!device || !device.Device_UID) {
            return;
        }

        const deviceUid = device.Device_UID;
        const storeUid = Object.keys(this._stores).find((uid) => this._stores[uid].devices.some(({ Device_UID }) => Device_UID === deviceUid));

        if (!storeUid) {
            return;
        }

        delete this._stores[storeUid];
        this._eventEmitter.trigger('changed');
    }

    _onLoadSuccess(storeId, devices)  {
        if (!this._stores[storeId]) {
            return;
        }

        this._stores[storeId].devices = devices;
        this._stores[storeId].error = null;
        this._stores[storeId].isLoading = false;
    }

    _onLoadFailed(storeId, error) {
        if (!this._stores[storeId]) {
            return;
        }

        this._stores[storeId].devices = [];
        this._stores[storeId].error = error;
        this._stores[storeId].isLoading = false;
    }

    _getLoadDeviceParams(storeId, filters) {
        const result = {};

        Object.keys(filters)
            .filter(key => filters[key] !== '')
            .forEach(key => {
                result[key] = filters[key];
            });

        result.deviceStoreID = storeId;

        delete result.accountBrand;
        delete result.filterType;
        delete result.storeIds;

        return result;
    }

    _makeRequest(storeId, filters) {
        const params = this._getLoadDeviceParams(storeId, filters);

        return getAllDevices(params);
    }

    async _load(storeId, filters) {
        // // avoid double loading
        if (this._stores[storeId]) {
            return;
        }

        const request = this._makeRequest(storeId, filters);

        this._stores[storeId] = {
            isLoading: true,
            devices: [],
            error: false,
            request,
        };

        try {
            const devices = await request;
            this._onLoadSuccess(storeId, devices);
        } catch(err) {
            this._onLoadFailed(storeId, err);
        }

        this._eventEmitter.trigger('changed');
    }

    _remove(storeId) {
        if (!this._stores[storeId]) {
            return;
        }

        delete this._stores[storeId];
    }
}
