import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Header.scss';

const buttonVariants = ['transparent'];

export const Header = ({ title, isOwner, hasRemove, onRemoveClick }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-view-role__header'>
            <Title>{title}</Title>
            { hasRemove && !isOwner &&
                <Button
                    className='hme-view-role__header__remove-button'
                    variants={buttonVariants}
                    onClick={onRemoveClick}
                >{t('common__permission--remove-role')}</Button>
            }
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    isOwner: PropTypes.bool,
    hasRemove: PropTypes.bool,
    onRemoveClick: PropTypes.func,
};
