import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'library/Button';
import { ContactHME } from 'library/ContactHME';

import sadFace from 'assets/icons/sadFace.svg';
import './ErrorDisplay.scss';

const ErrorDisplay = ({ errorMessageKey, backButtonLabel }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateBack = useCallback(() => {
        navigate(-2);
    }, [navigate]);

    return (
        <div className="hme-error-display">
            <img className="hme-error-display__warning-icon" src={sadFace}/>
            <p className="hme-error-display__error-text">{t(errorMessageKey)}</p>
            <ContactHME />
            <Button
                className="hme-error-display__back-button"
                onClick={navigateBack}
            >
                {t(backButtonLabel)}
            </Button>
        </div>
    );
};

export default ErrorDisplay;
