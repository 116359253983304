import * as region from '../../actionTypes/Region/Region'
// set the country redux initialState
const initialState = {
  regions: [
    {
      'Id': 0,
      'Name': '',
      'Abbreviation': ''
    }
  ]
}

// call regionDetails redux action & change the regionDetails  state
export default function regionDetails (state = initialState, action) {
  switch (action.type) {
    case region.GET_REGION:
      state.regions = action.regions
      return {
        ...state
      }
    default:
      return state
  }
}
