import { useEffect, useCallback } from 'react';

import { useAsync } from 'hooks/useAsync';
import { load } from 'services/Partner';

export const useLoadPartners = () => {
    const {
        run,
        isLoading,
        error: loadError,
        data: loadData,
    } = useAsync();

    const loadPartners = useCallback(() => {
        run(load());
    }, []);

    useEffect(() => {
        loadPartners();
    }, []);

    return {
        loadPartners,
        isLoading,
        loadError,
        partners: loadData
    };
};
