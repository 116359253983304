import { cond, T } from 'ramda';

import { taskConfig } from 'constants/applyDeviceSettingsTask';
import { Failed } from './Failed';
import { InProgress } from './InProgress';
import { Completed } from './Completed';
import { Unchanged } from './Unchanged';

import './StatusCell.scss';

const { statuses } = taskConfig;

export const StatusCell = cond([
    [({ status }) => status === statuses.FAILED, Failed],
    [({ status }) => status === statuses.COMPLETED, Completed],
    [({ status }) => status === statuses.UNCHANGED, Unchanged],
    [T, InProgress]
]);