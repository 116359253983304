import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';

import authenticate from 'components/Security/Authentication';
import { withHMELayout } from 'HOCs/withHMELayout';
import { TabPanel, Tab } from 'library/TabPanel';

import { useProfile } from './hooks/useProfile';
import { Account } from '../AccountInfo';
import { CompanyInfo } from '../CompanyInfo';
import { BrandTab } from './BrandTab';

import './Profile.scss';

const tabPanelVariants = ['subheader'];

const ProfileComponent = () => {
    const { t } = useTranslation();

    const {
        activeTab,
        handleActiveTabChange,
        hasBrandsAccess,
        isDistributor,
        isAccountOwner
    } = useProfile();

    return (
        <TabPanel
            className="hme-profile"
            activeTab={activeTab}
            variants={tabPanelVariants}
            onActiveTabChange={handleActiveTabChange}
        >
            <Tab text={t('my-account__account-info')} value="account">
                <Account />
            </Tab>
            <Tab text={t('my-account__company-info')} value="company">
                <CompanyInfo isDistributor={isDistributor} isAccountOwner={isAccountOwner} />
            </Tab>
            <Tab text={t('profile__brand-sharing__title')} value="brand-sharing" hidden={!hasBrandsAccess}>
                <BrandTab />
            </Tab>
        </TabPanel>
    );
};

export const Profile = compose(withHMELayout(), authenticate)(ProfileComponent);
