import React from 'react';
import classNames from 'classnames';

import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import { AutoLinkPlugin, HtmlTransformerPlugin, ToolbarPlugin } from './plugins';

import { theme } from './theme';

import './Editor.scss';

const Placeholder = ({ className = '', children = 'Enter some rich text...' }) => {
    return <div className={classNames('editor-placeholder', className)}>{children}</div>;
};

const editorConfig = {
    // The editor theme
    theme,
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        ListNode,
        ListItemNode,
        AutoLinkNode,
        LinkNode
    ]
};

const DefaultPlugins = ({ initialText: initialHtml, onChange }) => {
    return (
        <>
            <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={<Placeholder />}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <HtmlTransformerPlugin
                onHtmlChanged={onChange}
                initialHtml={initialHtml}
            />
        </>
    );
};

const empty = () => {};

export const Editor = ({
    state = '',
    className = '',
    initialConfig = editorConfig,
    Plugins = DefaultPlugins,
    onChange = empty
}) => {
    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className={classNames('editor-container', className)}>
                <ToolbarPlugin />

                <div className="editor-inner">
                    <Plugins
                        initialText={state}
                        onChange={onChange}
                    />
                </div>
            </div>
        </LexicalComposer>
    );
};
