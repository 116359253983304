import { useCallback } from 'react';

import { debounce } from 'lodash';

export const SEARCH_DEBOUNCE_TIME = 1000;
const MIN_SEARCH_VALUE = 3;

const setDebounceSearchValue = (value, onSearch) => {
    if (value.searchValue.length < MIN_SEARCH_VALUE) {
        return;
    }
    onSearch(value);
};

const setSearchValueDebounce = debounce(setDebounceSearchValue, SEARCH_DEBOUNCE_TIME);

export const useSearchWithDebounce = (onSearch, setSearchValue) => {
    const onSearchChangeHandler = useCallback((newValue) => {
        setSearchValue(newValue);

        setSearchValueDebounce.cancel();
        setSearchValueDebounce(newValue, onSearch);
    }, [setSearchValue, onSearch]);

    const onSearchHandler = useCallback((newValue) => {
        setSearchValueDebounce.cancel();
        setSearchValue(newValue);
        onSearch(newValue);
    }, [setSearchValue, onSearch]);

    return {
        onSearchChangeHandler,
        onSearchHandler
    };
};
