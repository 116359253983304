import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { Title } from 'library/Title';
import { OnOff } from 'library/OnOff';
import { isFormFieldDisabled } from '../../helpers';

import './AccountSettings.css';


export const AccountSettings = ({ initialValues, onChange, isReadOnly }) => {
    const [isDualFactorAuthentication, setIsDualFactorAuthentication] = useState(
        initialValues.isDualFactorAuthentication || false,
    );
    const [isTestAccount, setIsTestAccount] = useState(initialValues.isTestAccount || false);

    const { t } = useTranslation();
    const isFieldDisabled = useMemo(() => isFormFieldDisabled(), []);

    const onDualFactorAuthenticationChange = useCallback(
            (checked) => {
                setIsDualFactorAuthentication(checked);
            },
            [setIsDualFactorAuthentication]
    );

    const onTestAccountChange = useCallback(
            (checked) => {
                setIsTestAccount(checked);
            },
            [setIsTestAccount]
    );

    useEffect(() => {
        onChange({
            isDualFactorAuthentication,
            isTestAccount,
        });
    }, [isDualFactorAuthentication, isTestAccount]);

    return (
        <div className="hme-account-section-wrapper hme-account-form-area hme-account-form-account-settings">
            <Title>{t('account__section__title--account-settings')}</Title>

            <div className="hme-account-form-section">
                <Label>{t('account__section__form--dual-factor-authentication')}</Label>
                <OnOff
                    checked={isDualFactorAuthentication}
                    onChange={onDualFactorAuthenticationChange}
                    disabled={isFieldDisabled}
                    isReadOnly={isReadOnly}
                />
            </div>
            <div className="hme-account-form-section">
                <Label>{t('account__section__form--test-account')}</Label>
                <OnOff
                    checked={isTestAccount}
                    onChange={onTestAccountChange}
                    disabled={isFieldDisabled}
                    isReadOnly={isReadOnly}
                />
            </div>
        </div>
    );
};
