import { useState, useEffect, useCallback } from 'react';
import { getCloudAccount } from 'services/DataFeed';

export const useLoadCloudAccount = (cloudAccountID) => {
    const [account, setAccount] = useState(null);
    const [isAccountLoading, setIsAccountLoading] = useState(false);
    const [accountLoadingError, setAccountLoadingError] = useState(null);

    const loadAccount = useCallback(async (id) => {
        try {
            setIsAccountLoading(true);
            setAccountLoadingError(null);
            const cloudAccount = (await getCloudAccount(id)) || null;

            setAccount(cloudAccount);
        } catch (e) {
            setAccount(null);
            setAccountLoadingError(e);
        }

        setIsAccountLoading(false);
    }, []);

    useEffect(() => { loadAccount(cloudAccountID); }, [cloudAccountID]);

    return { account, isAccountLoading, accountLoadingError, loadAccount };
};
