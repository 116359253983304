import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { LeaderBoardTooltip } from './LeaderBoardTooltip';
import { ConfirmPopupComponent } from '../../library/ConfirmPopup/ConfirmPopup.js';
import { NotificationsList } from 'library/NotificationsList';
import {
  addSuccessNotification,
  addErrorNotification,
  dismissNotification
} from 'services/Notifications';
import { Config } from '../../Config';
import Api from '../../Api';
import { getLeaderBoardList, sortLeaderBoard } from '../../actions/leaderBoardGroups';
import { withReactRouter } from 'HOCs/withReactRouter';
import AuthenticationService from '../Security/AuthenticationService';
import { CenterLoader } from 'components/Common/CenterLoader';
import { get, save, remove, getCompanyDetail } from '../../actions/leaderboardGroup';
import { CommonConstants } from '../../Constants';
import { Link } from '../Common/Link/Link';
import { Header } from '../Common/Header/Header';
import { Button } from '../Common/Button/Button';
import { Grid } from '../Common/Grid';
import { Paginate } from "library/Paginate";
import { PAGE_DATA, PAGE_DEFAULT } from "constants/paginationDefault";

import '../Users/Users.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './LBGroup.css';
import './leaderBoard.css';
import { applyPagination } from 'helpers';

const accessLevels = {
  modify: CommonConstants.userPermissions.EditLeaderboard,
  view: CommonConstants.userPermissions.ViewLeaderboard,
};

class LeaderBoardGroups extends Component {
  constructor(props) {
    super(props);
    const authService = new AuthenticationService(Config.authBaseUrl);

    this.state = {
      ascending: true,
      showCommonLoader: false,
      groups: [],
      companyDetails: {},
      pageNumber: PAGE_DEFAULT.page,
      itemsPerPage: PAGE_DEFAULT.recordsPerPage
    };
    this.api = new Api();
    const { IsNitro: isNitroAccount} = authService.getProfile();
    this.isAdmin = authService.isAdmin();
    this.isNitroAccount = isNitroAccount;

    this.unitTests = {
      initialized: null,
      componentLoaded: null,
      tableInitialized: null,
      tableHasData: null,
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  showLoadedGroups(data = {companyUId: this.state.companyDetails.companyUId}, showCancelSuccess=true) {
    this.props.getLeaderBoardList(() => {
      const { leaderBoardGroups: groups, t } = this.props;
      this.setState({ groups, showCommonLoader: false });
      this.setState({
        totalRows:      this.props.leaderBoardGroups.list.length,
        paginatedRows : this.props.leaderBoardGroups?.list?.length ? applyPagination(this.props.leaderBoardGroups.list, { page: this.state.pageNumber, recordsPerPage: this.state.itemsPerPage }) : []
    })
      if (showCancelSuccess){
        addSuccessNotification('leaderboard__success--cancel');
      }
    }, data.companyUId);
  }

  onPaginationChange( { page, recordsPerPage } )
  {
    this.setState({
      pageNumber: page,
      itemsPerPage: recordsPerPage,
      paginatedRows: applyPagination(this.props.leaderBoardGroups.list, { page, recordsPerPage })
    })
  }

  UNSAFE_componentWillMount() {
    this.setState({ showCommonLoader: true });
    const { queryParams } = this.props;
    const statusId = queryParams.get('statusId') ? Number(queryParams.get('statusId')) : null;
    const isEdit = queryParams.get('isEdit') ? queryParams.get('isEdit') === 'true' : null;
    dismissNotification();
    if (statusId) {
        if (statusId === 1 ) {
          addSuccessNotification('leaderboard__settings__success--save');
        } else if (statusId === 2 ) {
          addErrorNotification('leaderboard__settings__error--save');
        } else if (statusId === 3 ) {
          isEdit ? addSuccessNotification('leaderboard__success--edit') : addSuccessNotification('leaderboard__success--save');
      }
    }

    this.props.getCompanyDetail(data => {
      this.setState({ companyDetails: data });
      this.props.sortLeaderBoard({ sortBy: 'Group_Name', sortType: 'ASC' });

      this.showLoadedGroups(data, false);
    });

    this.unitTests.componentLoaded = true;
  }

  handleClick(did) {
    let url = `/settings/users/user/${did}`;
    if (this.isAdmin) {
        url = `/admin/settings/users/user?uuid=${did}`;
    }
    window.location.href = url;
  }

  renderPage() {
    const { companyDetails, paginatedRows } = this.state;
    const { t, accessLevel, navigate, remove } = this.props;
    const hasAccessModify = accessLevel.includes(accessLevels['modify']);
    const { list: leaderBoards } = this.props.leaderBoardGroups;
    const isNitroLB = this.props.isNitro === 1 && this.isNitroAccount === 1;
    const hasDefaultTemplateUID = leaderBoards.length && leaderBoards[0].IsDefault && leaderBoards[0].LBSettingTemplateUID;
    let defaultTemplateUID = '';
    this.unitTests.tableInitialized = true;

    // TODO: Use text and headerClassName instead of spans
    const headers = [ {
      text: <span>{t('common__name')}</span>,
      property: 'Group_Name',
      sortable: false
    }]


    if (isNitroLB){ // Hide Template column if not nitroLB subscription
      headers.push({
        text: (<span>{t('common__template')}</span>),
        property: 'Group_Template',
        sortable: false
      });
    }
    // Get default template's UID so that 3x LB's will be able to customize their LBs settings. Remember that 3x accounts can only use default templates.
    if (hasDefaultTemplateUID){
      defaultTemplateUID = leaderBoards[0].LBSettingTemplateUID;
    }

    headers.push({
      text: <span>{t('leaderboard__count-of-stores')}</span>,
      property: 'Group_StoreCount',
      sortable: false
    }, {
      text: <span>{t('common__role')}</span>,
      property: 'Group_RoleName',
      sortable: false
    }, {
      text: <span>{t('common__description')}</span>,
      property: 'Group_Desc',
      sortable: false
    }, {
      text: <span>{t('common__history')}</span>,
      property: 'Group_HistoryModal',
      headerClassName: 'hme-grid-centered-cell',
      className: 'hme-grid-centered-cell',
      sortable: false
    }, {
      text: <span className='lbactions-textAlign'>{t('common__actions')}</span>,
      property: 'Group_LBActions',
      sortable: false
    });

    if (!leaderBoards || leaderBoards.length <= 0) {
      this.unitTests.tableHasData = false;
      return <div />;
    }

    const tableRows = paginatedRows.map((LBGroup) => {

      const isNitroLeaderboard = this.props.isNitro === 1 && LBGroup.IsNitroLeaderboard === 1;
      const leaderboardUrl = isNitroLeaderboard ? Config.leaderboard4XBaseUrl : Config.leaderboard3XBaseUrl;

      const roleName = companyDetails.companyUId === LBGroup.GroupOwner ? t('common__owner').toUpperCase() : t('common__participant').toUpperCase();

      const isAbleToPreview = LBGroup.Group_StoreCount > 0 && LBGroup.ParticipatingStoreUID != null;
      const isAbleToDelete = !LBGroup.IsDefault && companyDetails.companyUId === LBGroup.GroupOwner && hasAccessModify;
      const isAccountsDefaultGroup = companyDetails.companyUId === LBGroup.GroupOwner && LBGroup.IsDefault
      const groupName = (
        <span className='hme-grid-cell-content'>
          {
            isAccountsDefaultGroup ?
              <Trans i18nKey='leaderboard__default-group' values={{groupName: LBGroup.Group_Name}} shouldUnescape={true}>
                Group Name <em>Default Group</em>
              </Trans> : LBGroup.Group_Name
          }
        </span>
      );

      const actions = (
        <div className="fleft lbactions-textAlign actions-leaderboard hme-grid-cell-content">
          {isAbleToPreview &&
            <Button
              onClick={() => window.open(`${leaderboardUrl}${LBGroup.ParticipatingStoreUID}`, "Leaderboard", "width=800,height=450")}
              label={t('common__preview')}
              className={`bigger-link-text${!isAbleToPreview ? ' hidden': ''}`}
            />
          }
          {isAbleToPreview && isAbleToDelete ? ' | ': ''}
          {isAbleToDelete &&
          <Button
            onClick={() => {
              this.setState({groupUIDToCancel: LBGroup.GroupUID, showCancelModal: true});
            }}
            disabled={!isAbleToDelete}
            label={t('common__remove')}
            className={`bigger-link-text ${!isAbleToDelete ? ' hidden': ''}`}
          />
          }
          {(isAbleToPreview || isAbleToDelete) && hasAccessModify ? ' | ' : '   '}
          <Link
            to={`/leaderBoards/LBGroups/lbGroup?GroupUID=${LBGroup.GroupUID}&IsDefault=${LBGroup.IsDefault}&ia=${companyDetails.companyUId === LBGroup.GroupOwner}`}
            label={t('common__edit')}
            disabled={!hasAccessModify}
            className={`bigger-link-text${!hasAccessModify ? ' hidden': ''}`}
          />

        </div>
      );

      let columnMappings = {
        'Group_Name': groupName
      }

      if (isNitroLB){ // Hide Template column if not nitroLB subscription
        columnMappings['Group_Template'] = LBGroup.TemplateName;
      }

      columnMappings['Group_StoreCount'] = LBGroup.Group_StoreCount || '0';
      columnMappings['Group_RoleName'] = roleName;
      columnMappings['Group_Desc'] = LBGroup.Group_Desc;
      columnMappings['Group_HistoryModal'] = <LeaderBoardTooltip t={t} LBGroup={LBGroup} />
      columnMappings['Group_LBActions'] = actions;

      return columnMappings;
    }
  );
  const cancelMessageWarning = (
    <div className='text-dark text-lg'>
      <Trans i18nKey='leaderboard__confirm--remove'>
        Removing this Leaderboard
        <br/><br/>
        Are you sure?
      </Trans>
    </div>
  );

    return (
      <div className="settings forms leaderboards-grid-new">
        <div className="settings_plug clear text-black">
          <Header>
            {t('common__leaderboards')}
            <span className={'customize-lb-link'+ (isNitroLB ? ' hidden': '')}>|
            <a
              href={`/leaderBoards/LBTemplatesList/${defaultTemplateUID}`}
              className={`hme-leaderboard__customize-link fright bigger-link-text hme-link${!hasAccessModify ? ' hidden': ''}`}
            >
              {t('common__customize-lb')}
            </a>
            </span>
          </Header>
          <NotificationsList />
          <ConfirmPopupComponent
            show={this.state.showCancelModal}
            message={cancelMessageWarning}
            onConfirm={() => {
              this.setState({ showCommonLoader: true });

              remove({ GroupUID: this.state.groupUIDToCancel, CompanyUID: this.state.companyDetails.companyUId }, data => {
                const {status, data: msg} = data;
                if (status === true && msg) {
                  // now server returns only 'status' key in response, but this redirect optionally left for a while
                  setTimeout(() => {
                    navigate('/leaderBoards/LBGroups');
                  }, 3000);
                }

                this.setState({groupUIDToCancel: null, showCancelModal: false});
                this.showLoadedGroups();
              });
            }}
            confirmationVerb={t('common__remove')}
            onHide={() => this.setState({groupUIDToCancel: null, showCancelModal: false})}
          />
          <Link
            to='/leaderBoards/LBGroups/lbGroup'
            className={`pull-right list-view-btn-grey button-shape pick-me-up mb-3${!hasAccessModify ? ' hidden': ''}`}
            label={t('leaderboard__create-lb')}
            primary
            button
            disabled={!hasAccessModify}
          />
        </div>
        <div className="settings_list lbgroups-grid-wrapper extra-padding-on-bottom leaderboards-new-improved">
          <Grid
              rows={tableRows}
              headers={headers}
              showOverflow={true}
          />
          <Paginate
              className="leaderboard-paginate"
              page={this.state.pageNumber}
              recordsPerPage={this.state.itemsPerPage}
              pageSizes={PAGE_DATA.PAGE_SIZES_PUBLIC}
              total={this.state.totalRows}
              onChange={this.onPaginationChange}
              hideSinglePage
          />
        </div>
      </div>
    );
  }

  // Renders the table container, its header columns, and the add group button
  render() {
    const { showCommonLoader } = this.state;
    const { t } = this.props;

    this.unitTests.initialized = true;

    return (
      <div>
        <section className="users leaderboards">
          { showCommonLoader && <CenterLoader>{t('common__loading')}</CenterLoader>}
          { !showCommonLoader && this.renderPage() }
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leaderBoardGroups: state.leaderboardGroups.leaderBoardGroups,
    permissions: state.permissions,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompanyDetail: getCompanyDetail,
      getLeaderBoardList,
      sortLeaderBoard,
      save,
      remove,
      get,
    },
    dispatch,
  );
}

LeaderBoardGroups.propTypes = {
    navigate: PropTypes.func,
    queryParams: PropTypes.object
};

export default compose(
        connect(
                mapStateToProps,
                matchDispatchToProps
        ),
        withTranslation(),
        withReactRouter,
)(LeaderBoardGroups);
