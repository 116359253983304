import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'library/Title';
import { useTranslation } from 'react-i18next';
import { sortOptionsType } from '../../types/sortOptionsType';
import { SortingItem } from './SortingItem';
import { SingleSelect } from 'library/SingleSelect';

import { ExpandableSortingItem } from './ExpandableSortingItem';

import './Sorting.scss';

export const Sorting = ({ sortSelection, isDropdown, onSortChange, sortOptions }) => {
    const { t } = useTranslation();

    const sortSelectItems = sortOptions.map(({ label, value }) => ({ value: JSON.stringify(value), text: label }));

    const onSortSelect = (value) => {
        if (value) {
            onSortChange(JSON.parse(value));
        }
    }

    return (
        <div className="hme-tile-list__sort-filter-modal__sorting">
            {isDropdown ? (
                <SingleSelect
                    value={JSON.stringify(sortSelection)}
                    label={t('common__sort')}
                    onChange={onSortSelect}
                    items={sortSelectItems}
                />
            ) : (
                <>
                    <Title>{t('common__sort')}</Title>
                    {sortOptions.map((option, idx) => {
                        const { label } = option;

                        if (option.options) {
                            const { options } = option;

                            return (
                                <ExpandableSortingItem
                                    key={idx}
                                    label={label}
                                    options={options}
                                    sortingItemProps={{
                                        sortSelection,
                                        onSortChange
                                    }}
                                >
                                </ExpandableSortingItem>
                            );
                        }

                        const { value } = option;

                        return (
                            <SortingItem
                                key={label}
                                sortSelection={sortSelection}
                                onSortChange={onSortChange}
                                label={label}
                                value={value}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
};

Sorting.propTypes = {
    sortSelection: PropTypes.shape({}),
    isDropdown: PropTypes.bool,
    onSortChange: PropTypes.func,
    sortOptions: sortOptionsType,
};
