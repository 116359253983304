const statuses = {
    UNCHANGED: null,
    SENT_TO_DEVICE: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    FAILED: 3,
    DOWNLOAD_IN_PROGRESS: 4,
    DOWNLOAD_COMPLETED: 5,
    DOWNLOAD_FAILED: 6,
    SNAPSHOT_OVERWRITEN: 7,
    APPLY_IN_PROGRESS: 8,
    APPLY_FAILED: 9,
};

export const taskConfig = {
    statuses,
    inProgressStatuses: [
        statuses.SENT_TO_DEVICE,
        statuses.IN_PROGRESS,
    ],
    taskInProgressStatuses: [
        statuses.SENT_TO_DEVICE,
        statuses.IN_PROGRESS,
        statuses.DOWNLOAD_IN_PROGRESS,
        statuses.DOWNLOAD_COMPLETED,
        statuses.APPLY_IN_PROGRESS
    ],
    deviceInProgressStatuses: [
        statuses.SENT_TO_DEVICE,
        statuses.IN_PROGRESS,
    ],
    failedStatuses: [
        statuses.FAILED,
        statuses.DOWNLOAD_FAILED,
        statuses.APPLY_FAILED,
    ],
    completedStatuses: [
        statuses.COMPLETED,
        statuses.SNAPSHOT_OVERWRITEN,
    ]
};

export const taskTypes = {
    MASTER_SETTINGS_TO_DEVICE: 2,
    DEVICE_TO_DEVICE: 3,
    STORE_SETTINGS: 4,
};