import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Config } from 'Config';
import { COMMON_ROUTES } from 'constants/routes';
import { Title } from 'library/Title';
import { HamburgerMenuButton } from 'library/HamburgerMenuButton';
import NavigationServices from 'components/Common/NavigationServices';
import classNames from 'classnames';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { reportNames, getReportTranslation, defaultReportUserGuideURL } from 'components/LandingPage/ReportLandingPage/reportConstant';
import { filterReportsBySubscription } from 'components/PbiReportScreen/helpers/FilterReportsBySubscription';

import './ReportsHeader.scss';

const additionalLinks = {
    'reports__scheduler': COMMON_ROUTES.scheduledReports,
    'reports__docs': defaultReportUserGuideURL
};

const url = Config.trainingPortalBaseDocURL;

const createClassName = ({ isActive } = {}) =>
    classNames('report-side-menu__links-link', {
        'report-side-menu__links-link--active': isActive
    });

export const ReportsHeader = ({ className = '', hideUserGuide = false, isAdmin = false }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const reportType = pathArray[pathArray.length - 1];
    const sidebarRef = useRef();
    // fix for the bug DW-1204
    const navigation = new NavigationServices();
    const [menuItems, setMenuItems] = useState({});

    const isOverviewPage = location.pathname === COMMON_ROUTES.overview;

    useEffect(()=>{
        // if not admin user then get reports by subscription
        if (!isAdmin) {
            const reportList = filterReportsBySubscription();
            let menu = {};
            reportList.forEach((report)=> {
                menu[report.title] = report.path;
            });
            menu = { ...menu, ...additionalLinks };
            setMenuItems(menu);
        }
    }, []);

    const getURL = useCallback(
            (menu) => {
                return navigation.getSubMenuUrl(menu);
            },
            [navigation]
    );

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = useCallback(() => {
        setIsOpen((c) => !c);
    }, []);

    const reportFullLink = useMemo(() => {
        return t(getReportTranslation(reportType), { url });
    }, [t, reportType]);

    useClickOutSide(sidebarRef, toggleMenu);

    return (
        <>
            <div className={classNames('report-header', className)}>
                <div className="report-header__title-wrapper">
                    { !isAdmin && <HamburgerMenuButton onClick={toggleMenu} className="report-header__menu-opener hme-l-size" /> }

                    <Title className={isAdmin && 'report-header__title-admin-view'}>{t(reportNames[reportType])}</Title>
                </div>

                <ul className="report-header__additional-links">
                    {Object.keys(additionalLinks).map((label) => {
                        return (
                            (isOverviewPage || label === 'reports__docs') && (
                                <li key={label}>
                                    {label !== 'reports__docs' ? (
                                        <NavLink to={getURL(label)} className="report-header__additional-link">
                                            {t(label)}
                                        </NavLink>
                                    ) : !hideUserGuide && (
                                        <a
                                            href={reportFullLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="report-header__additional-link"
                                        >
                                            {t(label)}
                                        </a>
                                    )}
                                </li>
                            )
                        );
                    })}
                </ul>
            </div>

            {isOpen &&
                createPortal(
                        <nav className="report-side-menu hme-l-size" ref={sidebarRef}>
                            <div className="report-side-menu__header">
                                <HamburgerMenuButton
                                    isOpen
                                    onClick={toggleMenu}
                                    className="report-side-menu__menu-btn"
                                    aria-label={t('common__close')}
                                />
                                <ul className="report-side-menu__links-list reset-list">
                                    <li className="report-side-menu__links-item">
                                        <NavLink className={createClassName} to={COMMON_ROUTES.overview}>
                                            {t('reports__overview')}
                                        </NavLink>
                                    </li>
                                    {Object.keys(menuItems).map((label) => (
                                        <li key={label} className="report-side-menu__links-item">
                                            {label !== 'reports__docs' ? (
                                                <NavLink to={getURL(label)} className={createClassName}>
                                                    {t(label)}
                                                </NavLink>
                                            ) : (
                                                <a
                                                    href={reportFullLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={createClassName()}
                                                >
                                                    {t(label)}
                                                </a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </nav>,
                        document.querySelector('.hme-page-content')
                )}
        </>
    );
};
