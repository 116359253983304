import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Constant } from '@hme-cloud/utility-common';

import { Title as LibTitle } from 'library/Title';
import { Button } from 'library/Button';
import { NEXEO_BASE_STATION } from 'constants/NEXEOPeripheralTypes';
import { nexeoTranslationKeysMap } from 'pages/SystemStatus/Common/constants';
import { PeripheralActiveStatus } from 'pages/SystemStatus/AdminNexeo/DeviceStatus/DevicesSection/PeripheralActiveStatus';
import { mergeSpeakerDevices } from 'pages/SystemStatus/AdminNexeo/DeviceStatus/DevicesSection/helpers';
import { PeripheralDevicesList } from '../PeripheralDevicesList';
import { TileDeviceList } from 'pages/SystemStatus/Public/Nexeo/TileDeviceList';

const { BuildVersions } = Constant;

const mapExpandedDevices = (devices, isExpanded) => {
    return devices.reduce((acc, device) =>
        device.isMultiple
            ? {
                ...acc,
                [device.deviceTypeID]: isExpanded
            }
            : acc,
    {});
};

const GRID_OPTIONS = [
    {
        text: 'common__device__type--nexeo-device',
        flex: 1.3,
        property: 'modelName',
        className: 'hme-grid-model-name',
        headerClassName: 'hme-grid-model-name'
    },
    {
        text: 'common__device__serial-number',
        tabletScreenText: 'common__device__serial-number--text',
        flex: 1,
        property: 'device.serialNumber',
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'common__device__product-id',
        flex: 1,
        property: 'productId',
        className: 'hme-grid-cell-show-all-content hme-grid-product-id-col',
        headerClassName: 'hme-grid-product-id-col'
    },
    {
        text: 'common__lane',
        flex: 1,
        property: '',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        flex: 1,
        property: 'device.mainVersion',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        flex: 1,
        property: 'onLatestVersion',
        className: 'hme-grid-centered-cell hme-grid-cell--uppercase',
        headerClassName: 'hme-grid-centered-cell'
    }
];

const MOBILE_GRID_OPTIONS = [
    {
        column: GRID_OPTIONS.filter(({ property }) => property !== 'modelName') // removes "model-name" field for non desktop tile-list
    }
];

const prepareDevices = ({ peripheralDevices, device, t }) => {
    const preparedPeripheralDevices = {};

    if (typeof peripheralDevices !== 'object') {
        return [];
    }

    const isBaseStationActive = peripheralDevices[NEXEO_BASE_STATION.modelName]?.devices[0]?.isActive;

    Object.keys(peripheralDevices).forEach((deviceType) => {
        const peripheralDevice = peripheralDevices[deviceType];

        peripheralDevice.devices = peripheralDevice.devices.map((d) => ({
            ...d,
            modelName: peripheralDevice.modelName
        }));

        // ovveride preconfigured device with empty values
        if (device.Device_IsPreconfigured) {
            peripheralDevice.devices.forEach((d) => {
                d.macAddress = '';
                d.mainVersion = '';
                d.serialNumber = '';
                d.lane = '';
            });
        }

        peripheralDevice.device = {};

        const isBaseStation =
            peripheralDevices[NEXEO_BASE_STATION.modelName]?.deviceTypeID === peripheralDevice.deviceTypeID;

        if (!peripheralDevice.isMultiple) {
            peripheralDevice.device = peripheralDevice.devices[0];
            Object.keys(peripheralDevice.device).forEach((d) => {
                peripheralDevice[`device_${d}`] = peripheralDevice.device[d];
            });

            const isOnLatestVersion =
                !peripheralDevice.maxUpgradeVersion ||
                (peripheralDevice.minMainVersion &&
                    BuildVersions.semverGte(peripheralDevice.minMainVersion, peripheralDevice.maxUpgradeVersion));

            peripheralDevice.onLatestVersion =
                !device.Device_IsPreconfigured && isOnLatestVersion ? t('common__yes') : t('common__no');

            peripheralDevice.latestFirmwareAvailable = peripheralDevice.maxUpgradeVersion;
            peripheralDevice.currentUpgrade = t('common__none');
        }

        const modelNameText = t(nexeoTranslationKeysMap[peripheralDevice.modelName] || peripheralDevice.modelName);

        preparedPeripheralDevices[deviceType] = {
            ...peripheralDevice,
            modelName: !peripheralDevice.isMultiple ? (
                <div>
                    <PeripheralActiveStatus
                        isBaseStationActive={isBaseStationActive}
                        device={peripheralDevice.device}
                    />
                    {modelNameText}
                </div>
            ) : (
                modelNameText
            ),
            productId: isBaseStation ? device.Device_Product_ID : '',
            serialNumber: peripheralDevice.device.serialNumber,
            expandHidden: !peripheralDevice.isMultiple,
            isBaseStationActive
        };
    });

    // merge IB7000 and SM7000 devices
    const peripheralsWithSpeakers = mergeSpeakerDevices({
        preparedPeripheralDevices,
        device,
        t
    });

    return Object.values(peripheralsWithSpeakers);
};

export const useDevicesGrid = ({
    device,
    peripheralDevices
}) => {
    const { t } = useTranslation();

    const [rows, setRows] = useState([]);
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [expandedDevices, setExpandedDevices] = useState({});

    useEffect(() => {
        if (!device || !peripheralDevices) return;

        const preparedDevices = prepareDevices({
            device,
            peripheralDevices,
            t
        });

        setRows(preparedDevices);
        setExpandedDevices(mapExpandedDevices(preparedDevices, false));
    }, [device, peripheralDevices, t]);

    const handleExpandAllChange = useCallback(() => {
        setIsAllExpanded(!isAllExpanded);
        setExpandedDevices(mapExpandedDevices(rows, !isAllExpanded));
    }, [isAllExpanded, rows]);

    const handleExpandChange = useCallback((deviceTypeID, isExpanded) => {
        setExpandedDevices((currentExpandedDevices) => ({
            ...currentExpandedDevices,
            [deviceTypeID]: isExpanded
        }));
    }, []);

    useEffect(() => {
        const expandedDeviceKeys = Object.keys(expandedDevices);

        const allExpanded = expandedDeviceKeys.every((k) => expandedDevices[k]);
        if (allExpanded) {
            setIsAllExpanded(true);
            return;
        }

        const allCollapsed = expandedDeviceKeys.every((k) => !expandedDevices[k]);
        if (allCollapsed) {
            setIsAllExpanded(false);
        }
    }, [expandedDevices]);

    const isExpandAllShown = useMemo(() => (
        !device?.Device_IsPreconfigured && rows.some((r) => r.isMultiple)
    ), [device, rows]);

    const Title = <div className="hme-system-status-devices-grid__headline">
        <LibTitle>{t('system-status__title__system-devices')}</LibTitle>
        {isExpandAllShown && (
            <Button
                variants={['transparent']}
                className="hme-system-status-devices-grid__headline-expand-all"
                onClick={handleExpandAllChange}
            >
                {isAllExpanded ? t('common__collapse-all') : t('common__expand-all')}
            </Button>
        )}
    </div>;

    return {
        Title,
        headers: GRID_OPTIONS,
        mobileHeaders: MOBILE_GRID_OPTIONS,
        rowKey: 'deviceTypeID',
        rows,
        gridProps: {
            isAllExpanded,
            ExpandComponent: PeripheralDevicesList,
            onExpandChange: handleExpandChange
        },
        tileProps: {
            ListItemComponent: TileDeviceList,
            listItemComponentProps: {
                isAllExpanded,
                expandedDevices,
                onExpandChange: handleExpandChange
            }
        }
    };
};
