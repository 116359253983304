import { useCallback } from 'react';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { fetchContestResult, fetchContestSessionResult } from 'services/LeaderboardContests';

const defaultResults = [];

const loadData = async (contestUid) => {
    const getResultPromise = fetchContestResult(contestUid);
    const getSessionResultPromise = fetchContestSessionResult(contestUid);

    const [results, sessionResults] = await Promise.all([getResultPromise, getSessionResultPromise]);

    return {
        overall: results,
        rounds: sessionResults
    };
};

export const useLoadResults = () => {
    const {
        run: getResults,
        isLoading: isResultsLoading,
        data: results,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const loadResults = useCallback((contestUid) => {
        return getResults(loadData(contestUid));
    }, []);

    return {
        loadResults,
        results: results || defaultResults,
        isResultsLoading
    };
};
