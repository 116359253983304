import React from 'react';
import warning from 'warning';
import classNames from 'classnames';

import { Title } from 'library/Title';
import { Section as LibrarySection } from 'library/Section';

import './PageContentLayout.scss';

const Layout = ({ className, children }) => {
    return (
        <div className={classNames('hme-page-layout', className)}>
            {children}
        </div>
    );
};

const Content = ({ className, children }) => {
    return (
        <div className="hme-page-layout__content">
            <main className={classNames('hme-page-layout__main-content', className)}>{children}</main>
        </div>
    );
};

const PageTitle = ({ className, title, children }) => {
    warning(Boolean(title), 'Please, provide title to this page');

    return (
        <div className={classNames('hme-page-layout__page-title', className)}>
            <Title as="h1">{title}</Title>

            {children}
        </div>
    );
};

const FieldsSection = ({ className, children, ...props }) => {
    return (
        <LibrarySection {...props} className={classNames('hme-page-layout__fields-section', className)}>
            <div className="hme-page-layout__field">
                {
                    React.Children.map(children, (child) => {
                        const { className = '' } = child.props;

                        return React.cloneElement(child, { className: classNames(className, 'hme-page-layout__field-content') });
                    })
                }
            </div>
        </LibrarySection>
    );
};

export const PageContentLayout = { Layout, Content, PageTitle, FieldsSection };
