import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'services/Date';

// TODO: Change language logic should be moved here from LanguageSelect
export const LoadGlobalLocale = () => {
    const { i18n } = useTranslation();

    useEffect(()=>{
        const loadLocale = () => {
            const { resolvedLanguage: language = 'en-US' } = i18n || {};
            setLocale(language);
        };
        loadLocale();
    }, []);

    return null;
};
