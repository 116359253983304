import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnTrigger } from 'hooks/useTrigger';
import { pluck } from 'ramda';
import { Grid, resetFilters, getFiltersCount } from 'components/Common/Grid';
import { DeviceStatus } from 'library/DeviceStatus';
import { Actions } from './Actions';
import { CommonConstants } from 'Constants';
import getDeviceType from '../../../../helpers/Device/getDeviceType';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { OverrideCountry } from '../OverrideCountry';

import './SystemsList.scss';

const { deviceType: deviceTypes } = CommonConstants;

const ALL_BRANDS_FILTER_TEXT = 'admin__systems__grid-filter__all-brands';
const ALL_SYSTEM_TYPES_FILTER_TEXT = 'admin__systems__grid-filter__all-system-types';
const ALL_STATUSES_FILTER_TEXT = 'admin__systems__grid-filter__all-statuses';
const ALL_LANE_TYPES_FILTER_TEXT = 'admin__systems__grid-filter__all-lane-types';

const filterOptions = {
    Brand_Name: {
        allText: ALL_BRANDS_FILTER_TEXT,
    },
    Device_Type: {
        allText: ALL_SYSTEM_TYPES_FILTER_TEXT,
    },
    LaneConfig_Name: {
        allText: ALL_LANE_TYPES_FILTER_TEXT,
    },
    Status: {
        allText: ALL_STATUSES_FILTER_TEXT,
    },
};


const gridHeaders = [
    {
        text: 'common__brand',
        property: 'Brand_Name',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__system',
        property: 'Device_Type',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device__serial-number--text',
        property: 'Device_SerialNumber',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device__product-id',
        property: 'Device_Product_ID',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__settings__version',
        property: 'Device_SettingVersion',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'admin__systems__grid__header--lane-config',
        property: 'LaneConfig_Name',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__status',
        property: 'Status',
        flex: 70,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__actions',
        property: 'actions',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    },
]

const formatAvailableFilters = (filters) =>
    Object.keys(filters)
        .filter((property) => filterOptions[property])
        .map((property) => ({
            allText: filterOptions[property].allText,
            items: filters[property],
            property,
        }));

const formatGridFilters = (availableFilterList, selectedFiltersList) => {
    return availableFilterList.reduce((filtersObject, { property, items }) => {
        filtersObject[property] = pluck('value', items);

        if (selectedFiltersList && selectedFiltersList[property]) {
            // selected filters will always be a string when available could be also numbers
            filtersObject[property] = filtersObject[property].filter(el => selectedFiltersList[property].indexOf(`${el}`) > -1);
        }

        return filtersObject;
    }, {})
};

const systemsToRow = ({ systems, onOverrideCountryAction }) =>
    systems.map((system) => {
        const deviceType = getDeviceType({
            Device_DeviceType_ID: system.Device_DeviceType_ID,
            Device_MainVersion: system.Device_MainVersion,
        })

        return {
            ...system,
            Device_Type: (deviceType || {}).displayName,
            actions: <Actions
                Device_UID={system.LinkedDeviceUID || system.Device_UID}
                Version={system.Version}
                DeviceType_ID={system.LinkedDeviceUID ? deviceTypes.zoomNitro.id : system.Device_DeviceType_ID}
                Store_Number={system.Store_Number}
                onOverrideCountry={onOverrideCountryAction}
            />,
            Status: <DeviceStatus
                online={system.Device_IsActive}
                isPreconfigured={system.Device_IsPreconfigured}
                deviceType={system.Device_DeviceType_ID}
                name={system.DeviceType_Name}
                version={system.Device_MainVersion}
                deviceUid={system.Device_UID}
                hideDeviceName={true}
            />,
        }
    });

export const SystemsList = ({
    systems,
    countries,
    filters,
    selectedFilters,
    isLoading,
    sortSelection,
    onSortChange,
    onFiltersChange,
    onFiltersCountChange,
    onOverrideCountry,
    resetFiltersTrigger,
}) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [isRendering, setIsRendering] = useState(false);
    const [gridFilters, setGridFilters] = useState({});
    const [showOverridePopup, setShowOverridePopup] = useState(false);
    const [deviceOverrideCountryUID, setDeviceOverrideCountryUID] = useState('');
    const [overrideCountryId, setOverrideCountryId] = useState('');

    const onOverrideCountryAction = useCallback((Device_UID) => {
        setShowOverridePopup(true);
        setDeviceOverrideCountryUID(Device_UID);
    }, []);

    const onCountryChange = useCallback((countryId) => {
        setOverrideCountryId(countryId);
    }, []);

    const onCancelOverrideCountry = useCallback(() => {
        setShowOverridePopup(false);
        setDeviceOverrideCountryUID('');
        setOverrideCountryId('');
    }, []);

    const onConfirmOverrideCountry = useCallback(
        () => {
            onOverrideCountry({
                countryId: overrideCountryId,
                deviceUID: deviceOverrideCountryUID,
            });
            onCancelOverrideCountry();
        },
        [deviceOverrideCountryUID, overrideCountryId, onCancelOverrideCountry, onOverrideCountry],
    );

    const rowsMemo = useMemo(() => systemsToRow({ systems, onOverrideCountryAction }), [systems, onOverrideCountryAction]);

    const countriesOptions = useMemo(
        () => [
            {
                text: t('common__none'),
                value: '',
            },
            ...countries.map((country) => ({
                text: country.Name,
                value: country.Id,
            })),
        ],
        [countries],
    );

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        setIsRendering(true);
        setRows(rowsMemo);
    }, [rowsMemo, setIsRendering, setRows]);

    useEffect(() => {
        if (filters) {
            const availableFilterList = formatAvailableFilters(filters);

            setAvailableFilters(availableFilterList);
            setGridFilters(formatGridFilters(availableFilterList, selectedFilters));
        }
    }, [filters]);

    const onFiltersChangeHandler = useCallback(
        (gridFilters) => {
            onFiltersChange(gridFilters);
            setGridFilters(gridFilters);
        },
        [setGridFilters, onFiltersChange],
    );

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useEffect(() => {
        setIsRendering(false);
    }, [rows]);

    return (
        <div className="systems-list">
            {!isLoading && countriesOptions.length > 0 ? (
                <ConfirmPopupComponentGeneric
                    show={showOverridePopup}
                    className="systems-list-override-country-modal"
                    title={t('admin__systems__popup__title__override-country')}
                    message={
                        <OverrideCountry
                            countriesOptions={countriesOptions}
                            chosenCountryId={overrideCountryId}
                            onCountryChange={onCountryChange}
                        />
                    }
                    onHide={onCancelOverrideCountry}
                    actions={[
                        {
                            children: t('common__cancel'),
                            onClick: onCancelOverrideCountry,
                        },
                        {
                            disabled: !overrideCountryId,
                            children: t('common__save'),
                            variants: ['submit'],
                            onClick: onConfirmOverrideCountry,
                        },
                    ]}
                />
            ) : null}

            <Grid
                headers={gridHeaders}
                rows={rows}
                isLoading={isLoading || isRendering}
                rowKey="Device_ID"
                noRecordsMessage={t('common__no-devices--found')}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={onFiltersChangeHandler}
            />
        </div>
    );
};
