import { useCallback } from 'react';

export const useOnFieldChange = (object, field, onChange) => {
    return useCallback((newValue) => {
        onChange && onChange({
            ...object,
            [field]: newValue,
        })
    }, [object, field, onChange]);
};
