import { Rest } from 'helpers/Rest';
import { CommonConstants } from 'Constants';
import { postDataAsync, getDataAsync } from 'Api';


class NotificationCenterRest extends Rest {
    getReadUrl(id) {
        return `${this.baseURL}/${id}/read`;
    }

    read(id) {
        return this.request(() => postDataAsync(this.getReadUrl(id)));
    }

    getHasUnreadUrl() {
        return `${this.baseURL}/hasUnread`;
    }

    hasUnread() {
        return this.request(() => getDataAsync(this.getHasUnreadUrl()));
    }
}

export const NotificationCenterService = new NotificationCenterRest(CommonConstants.apiUrls.notificationCenterBase);
