import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Label } from 'library/Label';
import { FilterSection } from 'library/JoinedFilters/FilterSection';
import { ReactComponent as Icon } from 'assets/icons/down.svg';
import { useFilterComponentSection } from '../../../hooks/useFilterComponentSection';
import { availableFilterItemType } from '../../../types';

import './Filter.scss';

export const Filter = ({
    property,
    items,
    filtersOptions,
    filters,
    defaultSearchValue,
    noItemsFoundText,
    SearchItemsComponent,
    onSearchItemsChange,
    onFiltersChange,
    isOpen = false,
}) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const [shownItems, setShownItems] = useState(items);

    const { isSectionOpen, changeSectionVisibility } = useFilterComponentSection();

    useEffect(() => {
        if (!onSearchItemsChange) {
            setShownItems(items);

            return;
        }

        const newItems = onSearchItemsChange(items, searchValue);
        setShownItems(newItems);
    }, [items, searchValue, onSearchItemsChange]);

    return <>
        <div className="hme-modal__filter-component__section-title">
            <Label>{t(filtersOptions[property]?.title)}</Label>
            <Icon
                onClick={changeSectionVisibility}
                className={classNames(
                        'hme-modal__filter-component__section__expand__icon',
                        { 'down': isSectionOpen })}
            />
        </div>
        {isSectionOpen && <FilterSection
            items={shownItems}
            selection={filters[property]}
            allText={filtersOptions[property]?.allText}
            searchValue={searchValue}
            noItemsFoundText={noItemsFoundText}
            onChange={onFiltersChange}
            property={property}
            SearchItemsComponent={SearchItemsComponent}
            onSearchItemsChange={setSearchValue}
        />}
    </>;
};

Filter.propTypes = {
    property: PropTypes.string.isRequired,
    items: availableFilterItemType.isRequired,
    filters: PropTypes.shape({}),
    filtersOptions: PropTypes.shape({}).isRequired,
    onFiltersChange: PropTypes.func,
    isOpen: PropTypes.bool
};
