import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPartnerTokenTypeTranslation } from 'constants/token';

export const Type = ({ types }) => {
    const { t } = useTranslation();

    return types.map(({ id }) => (
        <div key={id}>{t(getPartnerTokenTypeTranslation(Number(id)))}</div>
    ));
};
