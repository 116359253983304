import React from 'react';
import { cond, T } from 'ramda';
import PropTypes from 'prop-types';

import { DevicesGrid } from '../../../Common/DevicesGrid';
import { InfoSection } from '../../../Common/InfoSection';
import { Header } from '../../../Common/Header';
import { DeviceInfoLoading } from '../../../Common/DeviceInfoLoading';
import { DeviceInfoError } from '../../../Common/DeviceInfoError';

import { useDeviceActions } from '../hooks';

import './DeviceStatus.scss';

export const Status = ({
    device,
    deviceSettings,
    StatusInfoFields,
    onDeviceReboot,
    onDeviceReconnect,
    deviceGridProps
}) => {
    const { title, ActionButtons, ConfirmProps } = useDeviceActions({ device, deviceSettings, onDeviceReboot, onDeviceReconnect });

    return (
        <div className="hme-system-status">
            <Header
                title={title}
                ActionButtons={ActionButtons}
                ConfirmProps={ConfirmProps}
            />
            <div className="hme-system-status__content">
                <InfoSection
                    Fields={StatusInfoFields}
                    className="hme-system-status__section-item"
                />
                <DevicesGrid
                    {...deviceGridProps}
                    className="hme-system-status__section-item"
                />
            </div>
        </div>
    );
};

export const DeviceStatus = cond([
    [({ isLoading }) => isLoading, () => <DeviceInfoLoading />],
    [({ error }) => error !== null, () => <DeviceInfoError />],
    [T, (props) => <Status {...props} />]
]);

const propTypes = {
    device: PropTypes.object.isRequired,
    deviceSettings: PropTypes.object.isRequired,

    StatusInfoFields: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        className: PropTypes.string,
        children: PropTypes.elementType
    })).isRequired,

    onDeviceReboot: PropTypes.func.isRequired,
    onDeviceReconnect: PropTypes.func.isRequired,
    deviceGridProps: PropTypes.object
};

DeviceStatus.propTypes = propTypes;
Status.propTypes = propTypes;
