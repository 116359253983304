import { checkIsStoresAvailable } from 'services/LeaderboardContests';

import { prepareContestDTO } from '../utils/prepareContestDTO';

export const getAvailableStores = ({
    endDate,
    startDate,
    contestStores,
    timeZone,
    scheduledTimePeriod,
    recurrenceDays,
    contestUID = ''
}, requestOptions = {}) => {
    const {
        StartDate,
        EndDate,
        ZoneName,
        ContestScheduleDayTimePeriods,
        ContestScheduleDays,
        ContestStores
    } = prepareContestDTO({
        startDate,
        endDate,
        contestStores,
        timeZone,
        scheduledTimePeriod,
        recurrenceDays
    });

    return checkIsStoresAvailable({
        StartDate,
        EndDate,
        ZoneName,
        ContestScheduleDayTimePeriods,
        ContestScheduleDays,
        ContestStores,
        ContestUID: contestUID
    }, requestOptions);
};
