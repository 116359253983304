import compareVersions from 'helpers/sortComparators/version';
import compareDates from 'helpers/sortComparators/date';

export const getSortComparators = (sortSelection = {}) => ({
    UpgradeToVersion: (a, b) => compareVersions(a.UpgradeToVersion, b.UpgradeToVersion, sortSelection.UpgradeToVersion),

    UpgradeStartDateRaw: (a, b) =>
        compareDates(a.UpgradeStartDateRaw, b.UpgradeStartDateRaw, sortSelection.UpgradeStartDate),
    LastDeviceUpgradeCompletedDateRaw: (a, b) =>
        compareDates(
            a.LastDeviceUpgradeCompletedDateRaw,
            b.LastDeviceUpgradeCompletedDateRaw,
            sortSelection.LastDeviceUpgradeCompletedDate,
        ),
});

export const applyRawFieldsSorting = (value) => {
    const [sortCol = ''] = Object.keys(value) || [];

    switch (sortCol) {
        case 'UpgradeStartDate':
            return { UpgradeStartDateRaw: value[sortCol] };
        case 'LastDeviceUpgradeCompletedDate':
            return { LastDeviceUpgradeCompletedDateRaw: value[sortCol] };
        default:
            return value;
    }
};
