import { useCallback, useEffect, useRef, useState } from 'react';
import { empty } from 'ramda';

import { getStoreIds, searchTree } from '../utils';
import { getAvailableStores, getGroupedStores, prepareStoresTree } from '../Controller';

export const useStoresTree = ({ initialContestStoreIds = [] } = {}) => {
    const [storesTree, setStoresTree] = useState([]);
    const [storesSearchQuery, setStoresSearchQuery] = useState('');

    const [isOnlySelectedStoresShown, setIsOnlySelectedStoresShown] = useState(false);

    const [isCheckingAvailableStores, setIsCheckingAvailableStores] = useState(false);

    const [contestStoreIds, setContestStoreIds] = useState(initialContestStoreIds);

    const originalStoresTreeRef = useRef([]);
    const originalStoreIdsRef = useRef([]);
    const abortControllerRef = useRef(new AbortController());

    const handleStoresSearchQueryChange = useCallback(
        (searchVal) => {
            setStoresSearchQuery(searchVal);

            if (searchVal.length > 2) {
                setStoresTree(searchTree(originalStoresTreeRef.current || [], searchVal));
            } else {
                setStoresTree(originalStoresTreeRef.current);
            }
        },
        []
    );

    const checkIsStoresAvailable = ({
        startDate,
        endDate,
        timeZone,
        scheduledTimePeriod,
        recurrenceDays,
        contestUID = '',
        contestStoreIdsToCheck = originalStoreIdsRef.current
    }) => {
        if (storesTree.length === 0) {
            return;
        }

        const aborter = new AbortController();

        if (!abortControllerRef.current.signal.aborted) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = aborter;

        setIsCheckingAvailableStores(true);

        getAvailableStores({
            startDate,
            endDate,
            timeZone,
            scheduledTimePeriod,
            contestStores: contestStoreIdsToCheck,
            recurrenceDays,
            contestUID
        }, { signal: abortControllerRef.current.signal }).then((storesIds) => {
            setStoresTree(originalStoresTreeRef.current.map((store) => prepareStoresTree(store, storesIds)));

            setContestStoreIds((currentContestStoreIds) => {
                if (empty(storesIds) && !empty(initialContestStoreIds)) {
                    return initialContestStoreIds;
                }

                return currentContestStoreIds.filter((storeId) => !storesIds.includes(storeId));
            });

            setIsCheckingAvailableStores(false);
        }).catch(() => empty);
    };

    const handleSelectedStoresOnlyClick = useCallback((newIsOnlySelectedStoresShown) => {
        const filterSelectedStores = (tree) => {
            const reduced = tree.reduce((groupsTree, item) => {
                const filteredStores = item.children.filter((store) => contestStoreIds.includes(store.StoreUID));

                const newGroupsTree = [
                    ...groupsTree,
                    filteredStores.length > 0 ?
                    {
                        ...item,
                        children: filteredStores
                    } :
                    null
                ];

                return newGroupsTree;
            }, []).filter(Boolean);

            return reduced;
        };

        setIsOnlySelectedStoresShown(newIsOnlySelectedStoresShown);
        setStoresTree(
            newIsOnlySelectedStoresShown ? filterSelectedStores : originalStoresTreeRef.current
        );
    }, [contestStoreIds]);

    useEffect(() => {
        (async () => {
            const grouppedStores = await getGroupedStores();
            originalStoresTreeRef.current = grouppedStores;
            originalStoreIdsRef.current = getStoreIds(grouppedStores);

            setStoresTree(grouppedStores);
        })();
    }, []);

    return {
        storesTree,
        setStoresTree,

        contestStoreIds,
        setContestStoreIds,

        handleStoresSearchQueryChange,

        isOnlySelectedStoresShown,
        toggleSelectedStoresOnly: handleSelectedStoresOnlyClick,

        storesSearchQuery,
        setStoresSearchQuery,

        checkIsStoresAvailable,
        isCheckingAvailableStores,

        originalStoresTreeRef,
        originalStoreIdsRef
    };
};
