import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { t } from 'i18next';

import { TileList } from 'library/TileList';
import { useOnTrigger } from 'hooks/useTrigger';
import { Grid, useAvailableFilters, applyGridFilters, resetFilters, getFiltersCount } from 'components/Common/Grid';
import sortBySelection from 'helpers/sortBySelection';
import compareVersions from 'helpers/sortComparators/version';
import { DateLib } from '@hme-cloud/utility-common';
import compareDates from 'helpers/sortComparators/date';
import {
    DEVICE_SETTINGS_GRID_OPTIONS,
    DEVICE_SETTINGS_FILTER_OPTIONS,
    MOBILE_SORT_OPTIONS,
    MOBILE_SNAPSHOTS_HEADERS,
} from './constants';

const getSortComparators = (sortSelection = {}) => ({
    SettingsVersion: (a, b) =>
        compareVersions(a.SettingsVersion, b.SettingsVersion, sortSelection.SettingsVersion),
    SourceSoftwareVersion: (a, b) =>
        compareVersions(a.SourceSoftwareVersion, b.SourceSoftwareVersion, sortSelection.SourceSoftwareVersion),
    CreatedDateRaw: (a, b) =>
        compareDates(a.CreatedDateRaw, b.CreatedDateRaw, sortSelection.CreatedDate),
    AppliedDateRaw: (a, b) =>
        compareDates(a.AppliedDateRaw, b.AppliedDateRaw, sortSelection.AppliedDate)
});

const defaultSortComparators = getSortComparators();

const ALL_VERSIONS_FILTER_TEXT = 'common__all-versions';
const ALL_BRANDS_FILTER_TEXT = 'common__all-brands';
const ALL_SOURCE_SOFTWARE_VERSIONS_FILTER_TEXT = 'common__all-source-versions';

const filterOptions = {
    SettingsVersion: {
        allText: ALL_VERSIONS_FILTER_TEXT,
        sortComparator: defaultSortComparators.SettingsVersion,
    },
    SourceBrand: {
        allText: ALL_BRANDS_FILTER_TEXT,
    },
    SourceSoftwareVersion: {
        allText: ALL_SOURCE_SOFTWARE_VERSIONS_FILTER_TEXT,
        sortComparator: defaultSortComparators.SourceSoftwareVersion,
    },
};

const snapshotsToRows = (snapshots, onSelect) =>
    snapshots.map(snapshot => ({
        ...snapshot,
        CreatedDateRaw: snapshot.CreatedDateRaw || '',
        AppliedDateRaw: snapshot.AppliedDateRaw || '',
        CreatedDate: new DateLib(snapshot.CreatedDateRaw).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME),
        Actions: <span onClick={onSelect && (() => onSelect(snapshot))}>{t('common__select')}</span>,
    }));

const applyRawFieldsSorting = (value) => {
    const [sortCol = ''] = Object.keys(value) || [];

    switch (sortCol) {
        case 'CreatedDate':
            return { CreatedDateRaw: value[sortCol] };
        case 'AppliedDate':
            return { AppliedDateRaw: value[sortCol] };
        default:
            return value;
    }
};

const INIT_SORT_SELECTION = { CreatedDate: -1 };

export const SnapshotsList = ({
    snapshots,
    isSnapshotsLoading = false,
    filtersCount,
    onSnapshotSelect,
    resetFiltersTrigger,
    onFiltersCountChange,
}) => {
    const [rows, setRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELECTION);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    const rowsMemo = useMemo(() => snapshotsToRows(snapshots, onSnapshotSelect), [snapshots, onSnapshotSelect]);

    useEffect(() => {
        setRows(
            sortBySelection({
                list: applyGridFilters(filterOptions, rowsMemo, gridFilters),
                sortSelection: applyRawFieldsSorting(sortSelection),
                comparators: getSortComparators(sortSelection),
            }),
        );
    }, [rowsMemo, gridFilters, sortSelection]);

    useAvailableFilters(filterOptions, rowsMemo, setGridFilters, setAvailableFilters);

    const onFiltersAndSortReset = useCallback(() => {
        onFiltersReset();
        setSortSelection(INIT_SORT_SELECTION);
    }, [onFiltersReset]);

    const onFiltersAndSortChangeHandler = useCallback(({ selectedFilters, selectedSortValue }) => {
        setGridFilters(selectedFilters);
        setSortSelection(selectedSortValue);
    }, []);

    return (
        <div className="device-settings-snapshot-list">
            <Grid
                rows={rows}
                headers={DEVICE_SETTINGS_GRID_OPTIONS}
                availableFilters={availableFilters}
                filters={gridFilters}
                rowKey="SettingTemplateID"
                noRecordsMessage="No Snapshots"
                onFiltersChange={setGridFilters}
                onSortChange={setSortSelection}
                sortSelection={sortSelection}
                isLoading={isSnapshotsLoading}
            />
            <TileList
                headers={MOBILE_SNAPSHOTS_HEADERS}
                rows={rows}
                isLoading={isSnapshotsLoading}
                noRecordsMessage="settings_snapshots__no-snapshots--found"
                rowKey="SettingTemplateID"
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersReset={onFiltersReset}
                filtersOptions={DEVICE_SETTINGS_FILTER_OPTIONS}
                filtersCount={filtersCount}
                sortSelection={sortSelection}
                sortOptions={MOBILE_SORT_OPTIONS}
                onFiltersAndSortChange={onFiltersAndSortChangeHandler}
                onFiltersAndSortReset={onFiltersAndSortReset}
                isSortingDropdown
            />
        </div>
    );
};
