import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Button, BUTTON_VARIANTS } from 'library/Button';
import { Separator, SEPARATOR_VARIANTS } from 'library/Separator';

import './Header.scss';

const separatorVariants = [SEPARATOR_VARIANTS.TITLE];
const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

export const Header = ({ onCancelContest }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-view-edit-contest--results__header">
            <Title>{t('common__view-edit')} {t('common__contest')}</Title>

            <Separator variants={separatorVariants} />
            <Button variants={buttonVariants} onClick={onCancelContest}>{t('common__cancel')} {t('common__contest')}</Button>
        </div>
    );
};
