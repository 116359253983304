import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { InfoField } from 'library/InfoField';

import './ContactInfo.css';

const addressLineFields = ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4'];
const fieldsMap = [
    {
        field: 'companyName',
        label: 'common__company__name',
    },
    {
        field: 'phoneNumber',
        label: 'common__phone-number',
    },
    {
        field: 'faxNumber',
        label: 'common__fax-number',
    },
];

export const ContactInfo = ({ infoData, countries, regions }) => {
    const { city, regionId, countryId, regionName, zipcode } = infoData || {};

    const { t } = useTranslation();

    const region = regionName || regions.find((r) => r.Id === regionId)?.Abbreviation;
    const country = countries?.find((c) => c.Id === countryId)?.Name || '';

    return (
        <Section className="company-info-view-contact-info-section" title="my-account__contact-info">
            <div className="hme-company-info-view-sections">
                {fieldsMap.map(
                    ({ label, field }) =>
                        infoData[field] && (
                            <div className="hme-company-info-view-section" key={field}>
                                <InfoField label={t(label)}>{infoData[field]}</InfoField>
                            </div>
                        ),
                )}
                {/* address section */}
                <div className="hme-company-info-view-section">
                    <InfoField label={t('common__address')}>
                        {addressLineFields.map((field) => infoData[field] && <div key={field}>{infoData[field]}</div>)}
                        <div>
                            {city}, {region} {zipcode}
                        </div>
                        <div>{country}</div>
                    </InfoField>
                </div>
            </div>
        </Section>
    );
};
