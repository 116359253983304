import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './Button.css';

export const Button = ({ primary, secondary, onClick, className, label, submit, icon, disabled, bold, circular, color }) => {
    const { t } = useTranslation();
    return (<button
        className={classNames(className, 'hme-button', {
            'hme-button--circular': circular,
            'hme-button--primary': primary,
            'hme-button--disabled': disabled,
            'hme-button--secondary': secondary,
            'hme-button--bold': bold,
            'btn-primary': color === 'blue',
            'hme-button--dark-blue': color === 'dark-blue',
            'hme-button--gray': color === 'gray',
            'hme-button--red': color === 'red'
        })}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
        disabled={disabled}
    >
        {icon && <img className="hme-button__icon" src={icon} alt={t('common__image-alt__button-icon')} />}
        {label}
    </button>);
}
  ;
