export const findNodeByItem = (tree, item) => {
    return tree.reduce((found, node) => {
        if (found) {
            return found;
        }

        if (node.item === item) {
            return node;
        }

        if (!node.children || node.children.length === 0) {
            return null;
        }

        return findNodeByItem(node.children, item);
    }, null);
};

export const findNodesByItems = (tree, items) => {
    return items
        .map((item) => findNodeByItem(tree, item))
        .filter((item) => item);
};
