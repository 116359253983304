import React from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import '../Alerts/Alerts.css'
import { Config } from '../../Config'
import AuthenticationService from '../Security/AuthenticationService'
import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';

class EmailAlert extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      emailId: this.props.location.state
    }
    this.authService = new AuthenticationService(Config.authBaseUrl)
  }
  render () {
    const { t } = this.props;
    let user = this.authService.isAdmin() ? this.authService.getAdminProfile() : this.authService.getProfile()
    let email = user.User_EmailAddress ? user.User_EmailAddress : user.unique_name
    return (
      <section>
        <div id='Content'>
          <div className='forms clear'>
            <div className='appr'>
              <h4>{t('reports__please-check-email-report', { email })}</h4>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

EmailAlert.propTypes = {
    location: PropTypes.object
};

export default compose(
        withTranslation(),
        withReactRouter
)(EmailAlert);
