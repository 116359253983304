import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenWidthMatch } from 'globalState/slices';
import { Tip } from 'library/Tip';

import './StatusCell.scss';

export const SetToExpire = () => {
    const { t } = useTranslation();

    const { isDesktop } = useScreenWidthMatch();

    return (
        <span className="hme-snapshot-settings-status-black hme-snapshot-settings-status--alert">
            {t('common__task__status--set-to-expire')}
            <Tip
                iconClassName="icon-alert"
                className="hme-snapshot-settings__status__tip"
                placement="bottom"
                clickable={!isDesktop}
            >
                <span className="icon-alert"></span>
                {t('common__task__status--set-to-expire__tip')}
            </Tip>
        </span>
    );
};
