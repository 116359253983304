import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { SearchInput } from 'components/Inputs';
import { GridFiltersCount } from 'library/GridFiltersCount';

import './Header.scss';

export const Header = ({ searchValue, filtersCount, onSearch, onFiltersReset }) => {
    const { t } = useTranslation();

    return (
        <div className="firmware-management__header">
            <div className="firmware-management__header__title-wrapper">
                <Title>{t('common__firmware-management')}</Title>
                <SearchInput
                    value={searchValue}
                    onChange={onSearch}
                    showClearIcon
                    placeholder={t('firmware-management__grid__search__placeholder--brand')}
                />
                {onFiltersReset && (
                    <div className="firmware-management__header__filters-count">
                        <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
                    </div>
                )}
            </div>
        </div>
    );
};
