import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ReactComponent as ClipboardIcon } from 'assets/icons/clipboard.svg';

import { Modal } from '../Modal';

import './CopyTokenModal.scss';

export const CopyTokenModal = ({ isShown, tokenName = '', tokenValue = '', onClose, ...props }) => {
    const { t } = useTranslation();

    const [{ text: copiedTokenValue }, copyToken] = useCopyToClipboard();

    const handleCopyToken = useCallback(async () => {
        try {
            await copyToken(tokenValue);
        } catch (err) {
            // TODO: display copy error
        }
    }, [copyToken, tokenValue]);

    return (
        <Modal
            show={isShown}
            onHide={onClose}
            className="hme-api-management-copy-token-modal"
            {...props}
        >
            <div className="hme-api-management-copy-token-modal__content">
                <div className="hme-api-management-copy-token-modal__header">
                    <AlertIcon className="hme-api-management-copy-token-modal__alert-icon" />
                    <h2 className="hme-api-management-copy-token-modal__title">
                        {t('admin__token-management__token-copy')} - {tokenName}
                    </h2>
                    <p className="hme-api-management-copy-token-modal__subtitle">
                        {t('admin__token-management__token-copy-description')}
                    </p>
                </div>

                <Button
                    className="hme-submit-button-component hme-api-management-copy-token-modal__clipboard-btn"
                    onClick={handleCopyToken}
                >
                    {t(!copiedTokenValue ? 'admin__token-management__token-copy' : 'admin__token-management__token-copy--success')}
                    <ClipboardIcon className="hme-api-management-copy-token-modal__clipboard-icon" />
                </Button>

                <div className="hme-api-management-copy-token-modal__token-area">
                    {tokenValue}
                </div>

                <Button className="hme-api-management-copy-token-modal__done-btn" onClick={onClose}>
                    {t('common__done')}
                </Button>
            </div>
        </Modal>
    );
};
