import { useState, useEffect, useCallback } from 'react';

import { useOnTrigger } from 'hooks/useTrigger';
import { DateLib } from '@hme-cloud/utility-common';

import { CalendarView } from './CalendarView';
import { normalizeDateRange } from './utils';

const getMinDate = (date, minDate, daysLimit) => {
    if (!daysLimit) {
        return minDate;
    }

    const limitDay = date.clone().addDays(-daysLimit + 1);

    return minDate ? DateLib.max(minDate, limitDay) : limitDay;
};

const getMaxDate = (date, maxDate, daysLimit) => {
    if (!daysLimit) {
        return maxDate;
    }

    const limitDay = date.clone().addDays(daysLimit - 1);

    return maxDate ? DateLib.min(maxDate, limitDay) : limitDay;
};

export const MultipleCalendar = ({
    value,
    months,
    daysLimit,
    minDate = null,
    maxDate = null,
    onDateSelect,
    onChange,
    resetCalendarTrigger,
    ...calendarProps
}) => {
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [preSelectedRange, setPreSelectedRange] = useState({});
    const [shownRange, setShownRange] = useState(null);
    const [minDateValue, setMinDateValue] = useState(minDate);
    const [maxDateValue, setMaxDateValue] = useState(maxDate);

    const onDateClick = useCallback((date) => {
        let newMinDate = minDate;
        let newMaxDate = maxDate;

        if (isSelectionMode) {
            const newRange = normalizeDateRange({
                startDate: preSelectedRange.startDate,
                endDate: date,
            });

            onChange && onChange(newRange);
            onDateSelect && onDateSelect(newRange);
        } else {
            const newRange = {
                startDate: date,
                endDate: null,
            };

            newMinDate = getMinDate(date, minDate, daysLimit);
            newMaxDate = getMaxDate(date, maxDate, daysLimit);

            setPreSelectedRange({
                startDate: date,
                endDate: date,
            });
            onDateSelect && onDateSelect(newRange);
        }

        setIsSelectionMode(!isSelectionMode);
        setMinDateValue(newMinDate);
        setMaxDateValue(newMaxDate);
    }, [isSelectionMode, minDate, maxDate, onChange, onDateSelect]);

    const onDateBlur = useCallback((date) => {
        if (!isSelectionMode) {
            return;
        }

        setPreSelectedRange({
            startDate: preSelectedRange.startDate,
            endDate: date,
        });
    }, [isSelectionMode, preSelectedRange.startDate]);

    const onMouseLeave = useCallback(() => {
        if (!isSelectionMode) {
            return;
        }

        setPreSelectedRange({
            startDate: preSelectedRange.startDate,
            endDate: preSelectedRange.startDate,
        });
    }, [isSelectionMode, preSelectedRange]);

    const onReset = useCallback(() => {
        setIsSelectionMode(false);
        setPreSelectedRange({});
        setMinDateValue(minDate);
        setMaxDateValue(maxDate);
        onDateSelect && onDateSelect(null);
        onChange && onChange(null);
    }, [onDateSelect, onChange]);

    useOnTrigger(resetCalendarTrigger, onReset);

    useEffect(() => {
        const newShownRange = isSelectionMode ? normalizeDateRange(preSelectedRange) : value;

        setShownRange(newShownRange);
    }, [isSelectionMode, preSelectedRange, value]);

    return (
        <CalendarView
            range={shownRange}
            months={months}
            isSelectionMode={isSelectionMode}
            minDate={minDateValue}
            maxDate={maxDateValue}
            onReset={onReset}
            onDateClick={onDateClick}
            onDateBlur={onDateBlur}
            onMouseLeave={onMouseLeave}
            {...calendarProps}
        />
    );
};
