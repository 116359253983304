import React, { useEffect, useMemo } from 'react';

import { ViewEditForm } from '../../Common/ViewEdit';
import { CONTEST_STATUS_NAMES, CONTEST_TYPE_VALUES } from '../../Common/Constants';
import { useLoadResults } from '../ResultsPage/hooks/useLoadResults';
import { Header } from './Header';

const showDownloadStatuses = [
    CONTEST_TYPE_VALUES[CONTEST_STATUS_NAMES.COMPLETED]
];

const useViewContest = ({ contestUID, status }) => {
    const {
        loadResults,
        results: contestResult,
        isResultsLoading
    } = useLoadResults();

    const showDownloads = useMemo(() => showDownloadStatuses.includes(status), [status]);

    useEffect(() => {
        if (!contestUID) {
            return;
        }

        loadResults(contestUID);
    }, [contestUID]);

    return {
        showDownloads,

        contestResult,
        isResultsLoading
    };
};

export const ViewPage = ({ contest }) => {
    const {
        isResultsLoading,
        contestResult,
        showDownloads
    } = useViewContest({ contestUID: contest.contestUID, status: contest.status });

    return (
        <div className="hme-view-edit-contest--results">
            <Header showDownloads={showDownloads} contestName={contest.name} />

            <ViewEditForm
                isReadOnly
                showResults
                values={contest}
                results={contestResult}
                isResultsLoading={isResultsLoading}
            />
        </div>
    );
};
