import * as leaderboard from '../../actionTypes/Leaderboard/Leaderboard'
// set the leaderboard group redux initialState
const initialState = {
  leaderBoardGroups: {
    list: {},
    sortParams: {},
    groupUserDetails: {}
  },
  leaderBoardTemplates: {
    list: {},
    sortParams: {},
    groupUserDetails: {}
  },
  inviteParams: {
    UserEmail: null,
    GroupUID: null,
    InvitedBy_UserUID: null,
    status: false,
    Email_Language: null,
    CompanyUID: null
  }
}
//  Leaderboard redux action & change the leaderboard group  state
export default function leaderBoardGroups (state = initialState, action) {
  switch (action.type) {
    case leaderboard.GET_LEADERBOARDGROUPS:
      state.leaderBoardGroups.list = action.LeaderBoardList
      return {
        ...state
      }
    case leaderboard.GET_LEADERBOARDTEMPLATES:
      state.leaderBoardTemplates.list = action.TemplatesList
      return {
        ...state
      }
    case leaderboard.SET_SORT_PARAMS_LEADERBOARD:
      state.leaderBoardGroups.sortParams = action.sortParams
      return {
        ...state
      }
    case leaderboard.SET_INVITEDETAILS:
      state.inviteParams = action.inviteParams;
      return {
        ...state
      };
    case leaderboard.GET_LEADERBOARDGROUPUSERS:
      state.leaderBoardGroups.groupUserDetails = action.groupUserDetails
      return {
        ...state
    }
    default:
      return state
  }
}
