import React, { useEffect, useMemo } from 'react';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { SingleSelect } from 'library/SingleSelect';

import './DeviceTypeSection.scss';

export const DeviceTypeSection = ({ deviceType, availableDevices, onDeviceTypeChange }) => {
    const { t } = useTranslation();

    const selectOptions = useMemo(
        () =>
            (availableDevices || []).map(({ Device_Name }) => ({
                text: Device_Name,
                value: Device_Name,
            })),
        [availableDevices],
    );

    useEffect(() => {
        // define default device type
        if (!deviceType) {
            const [defaultOption] = selectOptions;
            defaultOption && onDeviceTypeChange(defaultOption.value);
        }
    }, []);

    return (
        <Section
            number={1}
            title={t('create_new_task__search--search_for_destination_devices')}
            className="add-store-device__device-type"
        >
            <SingleSelect
                value={deviceType}
                label={t('common__device-type')}
                onChange={onDeviceTypeChange}
                items={selectOptions}
                placeholder={`- ${t('common__select')} -`}
                variants={['label-inside']}
            />
        </Section>
    );
};
