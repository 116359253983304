import * as types from '../../actionTypes/DynamicHomeScreen'

const initialState = {
  tiles: {
    desktop: ['Welcome', 'QuickLinks', 'AnnouncementTile', 'GetTraning', 'StoreRanking'],
    mobile: ['QuickLinks', 'Welcome' , 'AnnouncementTile', 'GetTraning', 'StoreRanking'],
  },
  storeRanking: {
    topThree: [],
    bottomThree: []
  },
  userPreferences: {
    colors: ''
  }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEFAULT_SETUP:
      return state.tiles
    case types.GET_STORE_RANKING_SUCCESS:
      state.storeRanking = action.top3Bottom3
      state.userPreferences = action.userPreferences
      return {
        ...state
      }
    case types.GET_STORE_RANKING_FAILURE:
      return state
    default:
      return state
  }
};


