import React from 'react';

import { withHMELayout } from 'HOCs/withHMELayout';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { checkAccess } from 'actions/permissions';
import { CommonConstants } from 'Constants';

import { ViewEdit } from '../../Common';
import { useViewEdit } from './hooks/useViewEdit';
import { compose } from 'ramda';

export const EditRoleComponent = () => {
    const state = useViewEdit();

    return (
        <ViewEdit {...state} />
    );
};

const hasAccess = checkAccess(CommonConstants.userPermissions.ManageRole);

export const PublicEditRole = compose(
        withHMELayout(),
        authenticate,
        withAccess({
            checkAccess: () => hasAccess
        })
)(EditRoleComponent);
