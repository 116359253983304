/**
 * This hook is supposed to be used to save in query params state of grid variables
 */
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatAppliedFilters } from 'helpers/formatAppliedFilters';

const filterPattern = 'f.';

const extractPageParams = (initial, queryParams) => {
    return {
        page: queryParams.p && queryParams.p >=0 ? +queryParams.p : +initial.page,
        perPage: queryParams.pp  && queryParams.pp >=0 ? +queryParams.pp : +initial.perPage
    };
}

const extractSearchParams = (initial, queryParams) => {
    // search filter will be empty for "All" selection
    return (queryParams.sev)
        ? { selectValue: queryParams.sef ?? '', searchValue: queryParams.sev, }
        : { selectValue: initial.selectValue, searchValue: initial.searchValue, }
}

const extractSortSelectionParams = (initial, queryParams) => {
    return (queryParams.soc && queryParams.sod) 
        ? { [queryParams.soc]: +queryParams.sod }
        : initial
}

const extractFiltersParams = (queryParams) => {
    const extractedFilterParams = {};
    
    Object.keys(queryParams).forEach((key) => {
        if (key.startsWith(filterPattern)) {
            extractedFilterParams[key.replace(filterPattern, '')] = queryParams[key].split(',');
        }
    });

    return extractedFilterParams;
}

const createSearchParamsURL = ({
    page,
    perPage,
    selectValue,
    searchValue,
    sortSelection,
    appliedFilters = null
} = {}, initial = {}) => {

    const requestParams = {};

    if (page >= 0 && perPage) {
        requestParams.p = page;
        requestParams.pp = perPage;
    }

    // search filter will be empty for "All" selection
    if (searchValue) {
        requestParams.sef = selectValue;
        requestParams.sev = searchValue;
    }

    if (sortSelection && Object.keys(sortSelection).length === 1) {
        requestParams.soc = Object.keys(sortSelection)[0];
        requestParams.sod = sortSelection[requestParams.soc];
    }

    if (appliedFilters) {
        Object.keys(appliedFilters).forEach(key => (requestParams[`f.${key}`] = appliedFilters[key].join(',')));
    }

    return requestParams;
};

export const useStatusQueryParams = (initial) => {
    // @todo need to be adopted to handle other existed query params - uuid etc
    const [queryParams, setQueryParams] = useSearchParams();
    const queryParamsObject = useMemo(() => Object.fromEntries(queryParams), [queryParams]);

    const [pagination, setPagination] = useState(() => extractPageParams(initial, queryParamsObject));
    const [searchParams, setSearchQueryParams] = useState(() => extractSearchParams(initial.searchParams, queryParamsObject));
    const [sortSelection, setSortSelection] = useState(() => extractSortSelectionParams(initial.sortSelection, queryParamsObject));
    const [selectedFilters, setSelectedFilters] = useState(() => extractFiltersParams(queryParamsObject));

    useEffect(() => {
        const stateObject = {
            ...pagination,
            selectValue: searchParams.selectValue,
            searchValue: searchParams.searchValue,
            sortSelection,
            appliedFilters: selectedFilters
        };

        setQueryParams(createSearchParamsURL(stateObject, initial));
    }, [pagination, searchParams, sortSelection, selectedFilters]);

    const setAppliedFilters = useCallback((appliedFilters, availableFilters) => {
        setSelectedFilters(formatAppliedFilters(availableFilters, appliedFilters));
    }, []);

    return {
        ...pagination,
        searchParams,
        sortSelection,
        appliedFilters: selectedFilters,
        setPagination,
        setSearchParams: setSearchQueryParams,
        setSortSelection,
        setAppliedFilters,
    };
};
