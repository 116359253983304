import { useState } from 'react';
import { CommonConstants } from 'Constants';
import { getFilters } from 'services/Snapshots';
import { filterOptions } from '../AddDevice/constants/addDeviceGridOptions';

const {
    deviceType: { zoom, zoomNitro },
} = CommonConstants;

const formatFilters = (filters) => {
    const possibleFilters = [];
    const allGridFilters = {};

    for (const key of Object.keys(filterOptions)) {
        const propertyFilters = filters[key] || [];
        possibleFilters.push({ ...filterOptions[key], items: filters[key] });
        allGridFilters[key] = propertyFilters.map(({ value }) => value);
    }

    return { possibleFilters, allGridFilters };
};

export const useLoadFilters = () => {
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});
    const [isFiltersLoading, setIsFiltersLoading] = useState(false);

    const loadFilters = async (deviceType) => {
        try {
            setIsFiltersLoading(true);
            const sourceDeviceType = deviceType === zoomNitro.name ? zoom.name : deviceType;
            const deviceSpecificFilters = await getFilters(sourceDeviceType);

            const { possibleFilters, allGridFilters } = formatFilters(deviceSpecificFilters);

            setAvailableFilters(possibleFilters);
            setGridFilters(allGridFilters);
        } catch (e) {
            setAvailableFilters([]);
            setGridFilters({});
        }
        setIsFiltersLoading(false);
    };

    return { availableFilters, setAvailableFilters, gridFilters, setGridFilters, loadFilters, isFiltersLoading };
};
