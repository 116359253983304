import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'components/Inputs';
import { Button } from 'library/Button';

import './SearchForm.scss';

export const SearchForm = ({
    value,
    onChange,
    onSearch,
    isSearchButtonDisabled = false,
    isDisabled,
    searchRef = null,
    searchPlaceholder = '',
}) => {
    const { t } = useTranslation();

    const onSearchValueChange = useCallback(
        (newSearchValue) => {
            onChange({ ...value, searchValue: newSearchValue });
        },
        [value, onChange],
    );

    const onSearchKeyPressed = useCallback(
        (event) => {
            if (event.key === 'Enter' && !isDisabled && !isSearchButtonDisabled) {
                onSearch(value);
            }
        },
        [value, onSearch],
    );

    const onSearchButtonClick = useCallback(() => {
      onSearch(value);
    }, [onSearch]);

    return (
        <div className="hme-search">
            <SearchInput
                value={value.searchValue}
                onChange={onSearchValueChange}
                onKeyPress={onSearchKeyPressed}
                showClearIcon
                isDisabled={isDisabled}
                inputRef={searchRef}
                placeholder={searchPlaceholder}
            />
            <Button
                variants={['submit']}
                onClick={(onSearchButtonClick)}
                disabled={isDisabled || isSearchButtonDisabled}
            >
                {t('common__search')}
            </Button>
        </div>
    );
};
