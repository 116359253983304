import React from 'react';
import { SnapshotForm } from '../SnapshotForm';

const snapshotsDefault = { nexeo: [] };

export const NexeoForm = ({
    params,
    devices,
    onParamsChange,
    isSnapshotsLoading,
    snapshots = snapshotsDefault,
}) => {
    const onSnapshotChange = (newParams) => {
        onParamsChange({
            ...newParams,
            targetDevices: devices
        });
    }

    return (
        <SnapshotForm
            deviceType='NEXEO'
            params={params}
            onParamsChange={onSnapshotChange}
            snapshots={snapshots.nexeo}
            isSnapshotsLoading={isSnapshotsLoading}
        />
    );
};