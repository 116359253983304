import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { saveAs } from 'file-saver';
import { getDataAsync, getSearchString, postDataAsync, getExportFile } from 'Api';
import { AX_ERRORS } from 'constants/errors';
import { DateLib } from '@hme-cloud/utility-common';
import { FORMAT_TYPES } from 'helpers/DateLib/constants';

const getPaginationData = (paginationData) => ({
    psize: paginationData.pageSize,
    pno: paginationData.pageNumber + 1
});

const getSortParams = (sortSelection) => {
    const [[sortBy, sortValue]] = Object.entries(sortSelection);
    const sortType = sortValue === 1 ? 'ASC' : 'DESC';
    return {
        sortBy,
        sortType
    };
};

export const getAdminStoresFromApi = async (searchDetails) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getStores}`;

    const {
        filterParams: { selectValue, searchValue } = {},
        sortSelection,
        paginationData,
        appliedFilters,
        showOnlyAvailableForSettingLock = 0,
        signal
    } = searchDetails;
    const { sortBy, sortType } = getSortParams(sortSelection);
    const { psize, pno } = getPaginationData(paginationData);

    const searchString = getSearchString({
        Sortby: sortBy,
        sortType,
        criteria: searchValue?.trim(),
        psize,
        pno,
        filter: searchValue ? selectValue : '',
        showOnlyAvailableForSettingLock,
        ...appliedFilters,
        isAdmin: 1
    });

    const response = await getDataAsync(`${url}?${searchString}`, {}, signal);
    const { status, storeList, pageDetails } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return {
        storeList,
        pageDetails
    };
};

export const getPartnerStores = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartnerStores}`;

    const response = await getDataAsync(url);
    const { status, data } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return data

}

export const getPublicStores = async (uuid, isAdmin) => {
    const url = `${Config.apiBaseUrl}${isAdmin 
        ? CommonConstants.apiUrls.getAdminViewStores 
        : CommonConstants.apiUrls.getStores}` ;

    const defaultParams = {
        showOnlyAvailableForSettingLock: 0,
        Sortby: 'Brand_Name',
        sortType: 'ASC',
        criteria: '',
        filter: '',
        isAdmin
    };

    const userParams = uuid ? {
        uuid,
        pno: 0
    } : {};

    const searchString = getSearchString({
        ...defaultParams,
        ...userParams
    });

    const response = await getDataAsync(`${url}?${searchString}`);
    const { status, storeList, pageDetails, IsCorpUser } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return {
        storeList,
        pageDetails,
        IsCorpUser
    };
};

const addStoreTimeZone = (data) => {
    if (data.Device_Details && data.Device_Details[0].Timezone !== undefined) {
        const timezone = data.Device_Details[0].Timezone;
        data.storeDetails.Timezone = timezone;
    } else if ( data && data.key == 'noAccess') {
        throw new Error('No Access');
    } else {
        data.storeDetails.Timezone = null;
    }
};

export const getAdminStoreDataFromApi = async (suid) => {
    const searchString = getSearchString({ suid });
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getStore;
    const response = await getDataAsync(`${url}?${searchString}`);
    addStoreTimeZone(response);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response;
};

export const updateStore = async (storeData) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.saveStore;
    const response = await postDataAsync(url, storeData);

    const { status, updatedRecords } = response;
    if (!status || !updatedRecords) {
        throw new Error('Request failed');
    }

    return response;
};

export const createPreInstallStore = async (storeData) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createPreInstallStore}`;
    const response = await postDataAsync(url, storeData);

    if (!response.status && response.code && response.code != AX_ERRORS.INVALID_AX_ACC) {
        const errorMessage =
            response.message === 'storealreadyexists'
                ? 'stores__error--already-exists'
                : 'admin__stores__add-error__notification';

        throw new Error(errorMessage);
    }

    return response;
};


export const removeStore = async (suid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.removeStore}?suid=${suid}`;
    const response = await getDataAsync(url);

    const { status } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return response;
};

export const removeDeviceFromStore = async (params) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.removeStoreDevice}`;
    const response = await postDataAsync(url, params);

    const { status } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return response;
};

export const addPreconfiguredDeviceToStore = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createPreconfiguredDevice}`;

    const response = await postDataAsync(url, payload);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const downloadStoresFile = async ({
    exportType = 'csv',
    sorting = {},
    searchValue,
    uuid,
}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.downloadStores}`;

    const sortingFieldsMap = {
        brandText: 'Brand_Name',
        cityString: 'Store_Locality',
        SerialNumber: 'Device_SerialNumber',
        Version: 'Device_MainVersion',
        Status: 'Device_Name',
    }

    const [sortBy] = Object.keys(sorting);

    const params = {
        searchField: searchValue?.selectValue,
        searchValue: searchValue?.searchValue,
        Sortby: sortingFieldsMap[sortBy] || sortBy,
        sortType: sorting[sortBy] === 1 ? 'ASC' : 'DESC'
    };

    if (uuid) {
        params.uuid = uuid;
    }

    const response = await getExportFile({
        url,
        exportType,
        params,
    });

    if (!response) {
        throw new Error('common__error--internal-server');
    }

    const filename = `stores_${new DateLib().format(FORMAT_TYPES.DATE_UNDERSCORED)}.${exportType}`;

    saveAs(response, filename);
};
