import { useCallback } from 'react';

import { useReadNotification } from '../../hooks/useReadNotiication';

export const onReadDefault = {
    onRead: () => {},
    isReading: false
};

export const useOnRead = ({ onAfterRead }) => {
    const { readNotification, isReading } = useReadNotification()

    const onRead = useCallback(async (id) => {
        try {
            await readNotification(id);
            onAfterRead && onAfterRead(id);
        } catch (err) {}
    }, [onAfterRead]);

    return {
        onRead,
        isReading
    };
};
