import * as permissions from '../../actionTypes/Permissions/Permissions'

const initialState = {
  userPermissions: [],
  'state': true
}

export default function userPermissions (state = initialState, action) {
  switch (action.type) {
    case permissions.GET_USER_PERMISSIONS:
      state.userPermissions = action.userPermissions
      return {
        ...state
      }
    default:
      return state
  }
}
