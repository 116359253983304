import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { SearchOptionable } from 'library/SearchOptionable';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { ACCOUNTS_SEARCH_OPTIONS } from '../constants/accountSearchOptions';

import './Header.scss';

export const Header = ({
    onSearch,
    isDisabled,
    searchParams,
    onSearchParamsChange,
    filtersCount,
    onFiltersReset,
    setSortModalOpen
}) => {
    const { t } = useTranslation();
    return (
        <div className="hme-distributor-accounts__header__wrapper">
            <Title>{t('common__accounts')}</Title>
            <SearchOptionable
                value={searchParams}
                onChange={onSearchParamsChange}
                searchSelectOptions={ACCOUNTS_SEARCH_OPTIONS}
                onSearch={onSearch}
                isDisabled={isDisabled}
            />
            <div className="hme-distributors-accounts-filters-count">
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
        </div>
    );
};

Header.propTypes = {
    onSearch: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    searchParams: PropTypes.shape({
        selectValue: PropTypes.string,
        searchValue: PropTypes.string
    }).isRequired,
    onSearchParamsChange: PropTypes.func.isRequired,
    filtersCount: PropTypes.number.isRequired,
    onFiltersReset: PropTypes.func.isRequired,
    setSortModalOpen: PropTypes.func
};
