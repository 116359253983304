import { Component } from 'react'

export default class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, info) {
    window.localStorage.setItem('error', error)
    window.localStorage.setItem('erroInfo', JSON.stringify(info))
    if (window.location.pathname !== '/exception' && window.location.pathname !== '/error') {
      window.location.href = '/exception'
    } else {
      if (window.location.pathname !== '/error') {
        window.location.href = '/error'
      }
    }
  }

  render () {
    if (this.state.hasError) {
      return ''
    } else {
      return this.props.children
    }
  }
}
