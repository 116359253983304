import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { addErrorNotification } from 'services/Notifications';

import { useLoadPartners } from './useLoadPartners';
import { usePartnerRows } from './usePartnerRows';

export const useStatus = () => {
    const { t } = useTranslation();

    const {
        partners,
        loadError,
        isLoading
    } = useLoadPartners();

    const {
        rows,
        onSearch,
        pagination,
        onPaginationChange,
        sorting,
        onSortingChange,
    } = usePartnerRows({ partners });

    const noRowsMessage = useMemo(
        () => rows.length ? t('common__no-results') : t('admin__partner__status__no-partners'),
        [rows.length, t]
    );

    const hasError = Boolean(loadError);

    useEffect(() => {
        if (hasError) {
            addErrorNotification('common__error--unknown');
        }
    }, [hasError])

    return {
        rows,
        isLoading,
        noRowsMessage,
        onSearch,
        pagination,
        onPaginationChange,
        sorting,
        onSortingChange,
    };
};
