import * as leaderboard from '../../actionTypes/Leaderboard/Leaderboard'

const initialState = {
  companyLBVersion: {
    'LeaderboardMode_ID': 0
  }
}

export default function companyLBVersion (state = initialState, action) {
  switch (action.type) {
    case leaderboard.GET_COMPANYLBVERSION:
      state.companyLBVersion = action.companyLBVersion
      return {
        ...state
      }
    default:
      return state
  }
}
