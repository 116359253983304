import React from 'react';
import { confirmAlert as reactConfirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

export const confirmAlert = ({ header, message, buttons }) => {
    reactConfirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="modal-content modal-content__confirm-alert">
                    <div className="modal-header">
                        {header && <span className="modal-title d-inline-block h4">{header}</span>}
                        <button
                            className="close"
                            type="button"
                            onClick={() => onClose()}
                        >
                            {/* HEAVY MULTIPLICATION X */}
              &#10006;
                        </button>
                    </div>
                    <div className="modal-body">
                        { message }
                    </div>
                    <div className="modal-footer modal-footer__confirm-alert">
                        <div className="modal-footer__confirm-alert__buttons">
                            {buttons && buttons.map((btn, i) => {
                                // possible button types: [primary, secondary, success, danger, warning, info, light, dark, link]
                                const { type, label, onClick } = btn;
                                return <button
                                    key={i}
                                    type="button"
                                    className={classNames(
                                            btn.className,
                                            'btn',
                  `btn-${type || 'primary'}`
                                    )}
                                    onClick={() => {
                                        onClick && onClick();
                                        onClose();
                                    }}
                                >
                                    {label}
                                </button>;
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    });
};
