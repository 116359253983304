import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from 'components/Inputs';

export const VaioFilterSearch = ({ value, onChange, placeholder }) => {
    const onSearchTextChange = (searchText) => {
        return onChange && onChange({ searchText, searchBy: value.searchBy });
    };

    return (
        <div>
            <SearchInput value={value.searchText} onChange={onSearchTextChange} placeholder={placeholder} />
        </div>
    );
};

// set prop types
VaioFilterSearch.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};
