import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isPartner } from 'services/Auth';
import { partnerRules, publicRules, isValidStatus, isInvalidStatus } from 'hooks/useResetPassword';

import { RuleMessage } from '../../RuleMessage';

export const Rules = ({ newPassword, confirmNewPassword }) => {
    const { t } = useTranslation();

    const rules = useMemo(() => isPartner() ? partnerRules : publicRules, []);

    return rules.map(({ tKey, rule }) => {
        const status = rule(newPassword, confirmNewPassword);
        const isValid = isValidStatus(status);
        const isInvalid = isInvalidStatus(status);

        return (
            <RuleMessage
                key={tKey}
                message={t(tKey)}
                isInvalid={isInvalid}
                isValid={isValid}
                className="hme-change-password-form__rule"
            />
        );
    });
};
