/* eslint-disable camelcase */
import React, { useState, useCallback } from 'react';
import { DateLib } from '@hme-cloud/utility-common';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Label } from 'library/Label';
import { Section } from 'library/Section';
import { Slider } from 'library/Slider';
import { ErrorLabel } from 'library/ErrorLabel';
import { DateSelector } from 'library/DateSelector';
import { InputComponent } from 'components/Inputs';
import { Toggle } from 'library/Toggle';
import { vaioSettings } from 'pages/StoreFeatures/VaioProviders/helpers';
import { DEFAULT_TOKEN_MANAGER } from 'pages/APIManagement/APIManagementForm/helpers';
import { usePartnerEmails } from 'library/PartnerEmailDropdown/hooks/usePartnerEmails';
import { PartnerEmailDropdown } from 'library/PartnerEmailDropdown/PartnerEmailDropdown';
import { SingleSelect } from 'library/SingleSelect';

import { validateKeyExpirationDate } from '../helpers';

import './GeneralSection.scss';

const { locationTypes, audioModes, outboundAudioTypes } = vaioSettings;

const LOCATION_TYPES = [
    { text: 'vaio-provider_location--cloud-based', value: locationTypes.cloudBased },
    { text: 'vaio-provider_location--on-premise', value: locationTypes.onPremise },
];

const AUDIO_MODE_TYPES = [
    { text: 'vaio-provider_audio-mode--continuous', value: audioModes.continuous },
    { text: 'vaio-provider_audio-mode--vehicle-present', value: audioModes.vehiclePresent },
];

const OUTBOUND_AUDIO_TYPES = [
    { text: 'vaio-provider_outbound-audio--variable', value: outboundAudioTypes.variable },
    { text: 'vaio-provider_outbound-audio--fixed', value: outboundAudioTypes.fixed },
];

const MONTHS_TO_SHOW = 1;

// Expiration values are 1-24 hours, presented as a dropdown
const tokenExpirationValues = [{ text: '1 Hour', value: 1 }];
for (let i = 2; i <= 24; i++) {
    tokenExpirationValues.push({ text: `${i} Hours`, value: i });
};

export const GeneralSection = ({
    formValues,
    onChange,
    formErrors,
    requiredInputs,
    isKeyExpirationHidden,
    linkedPartner = DEFAULT_TOKEN_MANAGER,
    isPartnerMode = false
}) => {
    const { t } = useTranslation();
    const [providerDateErrors, setProviderDateErrors] = useState({});
    const { name, email = linkedPartner.email, keyExpiration, tokenExpiration, isTest, locationType, audioMode, outboundAudio } = formValues;
    const formattedKeyExp = keyExpiration ? new DateLib(keyExpiration) : keyExpiration;

    const {
        email: partnerEmail,
        matchedEmails: matchedPartnerEmails,
        handleEmailChange: fetchPartnerEmails,
        setEmail: setPartnerEmail,
        isLoading: isPartnerLoading
    } = usePartnerEmails(email);

    const onValidateKeyExpirationDate = useCallback((value) => {
        setProviderDateErrors({
            keyExpiration: validateKeyExpirationDate(value)
        });
    }, [validateKeyExpirationDate]);

    const onPartnerEmailSelect = useCallback((newPartner) => {
        if (!newPartner.email) {
            return;
        }

        setPartnerEmail(newPartner.email);
        onChange({ target: { name: 'email', value: newPartner.email } });
    }, [onChange]);

    const onPartnerEmailChange = useCallback(async (newEmail) => {
        try {
            onChange({ target: { name: 'email', value: newEmail } });
            await fetchPartnerEmails(newEmail);
        } catch (_err) {
            // do nothing when request is programmatically canceled
        }
    }, []);

    const validationMessageKeyExpiration = providerDateErrors.keyExpiration || formErrors.keyExpiration;

    return (
        <Section className="new-vaio-provider-general" number={1} col={3} showNumber={false} title="common__general">
            <InputComponent
                label={t('new-vaio-provider__form--provider-name')}
                name="name"
                value={name}
                onChange={onChange}
                isRequired={requiredInputs.name}
                maxLength={32}
                universalOnChange={true}
            />
            {formErrors.name && <ErrorLabel>{t(formErrors.name)}</ErrorLabel>}

            {!isPartnerMode && (
                <PartnerEmailDropdown
                    email={partnerEmail}
                    partnerEmails={matchedPartnerEmails}
                    onSelect={onPartnerEmailSelect}
                    onChange={onPartnerEmailChange}
                    isLoading={isPartnerLoading}
                    error={t(formErrors.email)}
                    label={t('new-vaio-provider__form--provider-email')}
                    className="vaio-partner-selector"
                />
            )}

            {!isKeyExpirationHidden && (
                <DateSelector
                    className="new-vaio-provider-date-picker"
                    label={t('new-vaio-provider__form--key-expiration-date')}
                    multiple={false}
                    value={formattedKeyExp}
                    isDisabled={false}
                    onChange={(value) => {
                        onChange({ target: { name: 'keyExpiration', value } });
                    }}
                    variants={['date-picker']}
                    months={MONTHS_TO_SHOW}
                    minDate={new DateLib()}
                    isRequired={requiredInputs.keyExpiration}
                    onValidate={onValidateKeyExpirationDate}
                    dateFormat={DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT}
                />
            )}
            {!isKeyExpirationHidden && validationMessageKeyExpiration && <ErrorLabel>{t(validationMessageKeyExpiration)}</ErrorLabel>}

            <SingleSelect
                value={tokenExpiration}
                onChange={(value) => {
                    onChange({ target: { name: 'tokenExpiration', value: parseInt(value) } });
                }}
                placeholder={`-${t('new-vaio-provider__form--token-expiration-placeholder')}-`}
                isDisabled={false}
                isRequired={requiredInputs.tokenExpiration}
                items={tokenExpirationValues}
                withFilter={false}
                label={t('new-vaio-provider__form--token-expiration')}
            />

            <Label className="vaio-provider-form-label">{t('vaio-provider--location')}</Label>
            <Toggle
                value={locationType}
                onChange={(value) => {
                    onChange({ target: { name: 'locationType', value } });
                }}
            >
                {LOCATION_TYPES}
            </Toggle>

            <Label className="vaio-provider-form-label">{t('vaio-provider--inbound-audio-mode')}</Label>
            <Toggle value={audioMode} onChange={(value) => {
                onChange({ target: { name: 'audioMode', value } });
            }}>
                {AUDIO_MODE_TYPES}
            </Toggle>

            <Label className="vaio-provider-form-label">{t('vaio-provider--outbound-audio-mode')}</Label>
            <Toggle value={outboundAudio} onChange={(value) => {
                onChange({ target: { name: 'outboundAudio', value } });
            }}>
                {OUTBOUND_AUDIO_TYPES}
            </Toggle>

            {!isPartnerMode && <>
                <Label className="vaio-provider-form-label">{t('vaio-provider--test-provider')}</Label>
                <Slider
                    value={isTest}
                    label={isTest ? t('common__checkbox--on') : t('common__checkbox--off')}
                    checked={isTest}
                    onSliderChange={(_, value) => {
                        onChange({ target: { name: 'isTest', value } });
                    }}
                />
            </>}
        </Section>
    );
};

GeneralSection.propTypes = {
    formValues: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    requiredInputs: PropTypes.object.isRequired,
    isPartnerMode: PropTypes.bool
};
