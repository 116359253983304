import getDeviceType from './getDeviceType';
import { CommonConstants } from '../../Constants';
import { isEqual } from 'underscore';
const { deviceType } = CommonConstants;
const deviceTypesArr = Object.values(deviceType);

export default (device = {}, sample = {}) => {
  const isSampleValid = deviceTypesArr.some(el => isEqual(el, sample));
  if (!device['Device_DeviceType_ID'] || !isSampleValid) return false;
  const deviceType = getDeviceType(device);
  return isEqual(deviceType, sample);
};