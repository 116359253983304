import { get } from 'lodash/fp';

export function selectStoreDetails(state) {
  const result = get(['storeDetails', 'storeDetails', 'storeList'], state);

  return result;
}

export function selectAdminStoreDetails(state) {
    const result = get(['storeDetails', 'adminStoreDetails', 'storeList'], state);

    return result;
  }