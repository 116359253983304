export const HISTORY_GRID_OPTIONS = [
    {
        text: 'text-n-connect__history__grid-header--from',
        property: 'createdBy',
        flex: 1,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'text-n-connect__history__grid-header--to',
        property: 'stores',
        flex: 1,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'text-n-connect__history__grid-header--message',
        property: 'message',
        flex: 2,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'text-n-connect__history__grid-header--sent-datetime',
        property: 'createdAt',
        flex: 1,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
];