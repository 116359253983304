import React from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Modal } from 'library/Modal';
import { withHMELayout } from 'HOCs/withHMELayout';
import { useViewEdit } from './hooks/useViewEdit';
import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { Header } from './Header';
import { SubscriptionsBlock } from '../Common/SubscriptionsBlock';
import { GeneralInformationBlock } from '../Common/GeneralInformationBlock';
import { AdminDevicesInformation } from './AdminDevicesInformation';
import { AddDevice } from './AddDevice';
import { Footer } from '../Common/Footer';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';

import './ViewEdit.scss';

const ViewEditComponent = () => {
    const { t } = useTranslation();

    const {
        snapshot,
        setSnapshot,
        deviceType,
        setDeviceType,
        isStoreDetailsLoading,
        storeDetails,
        devicesRows,
        AXCustomerNumber,
        setAXCustomerNumber,
        isJTechCustomerOnlySubscription,
        isNexeoCustomerOnlySubscription,
        isNitroSubscription,
        isNVDEnabled,
        onCancel,
        onSave,
        isSaveBtnDisabled,
        validationErrors,
        onAddDeviceClick,
        onAddDevice,
        isModalOpen,
        onModalClose,
        deviceDetails,
        availableDevices,
        isAddDeviceButtonDisabled,
        devicesHeaders,
        isConfirmPopupOpen,
        onConfirmPopupClose,
        isRemoveStoreButtonVisible,
        onStoreDeleteClick,
        confirmPopupContent,
        vaioSubscription,
        onVaioSubChange,
        onStoreSystemTierChange,
        onJTechTierChange,
        vaioSubscriptionList,
        nexeoSystemTierID,
        jTechTierID,
        storeSystemTiers,
        isStoreSystemTiersDisabled,
        isStoreSystemTiersLoading,
        initialVaioProvider,
        classificationId,
        setClassificationId,
        hasManageNitroSubscriptionPermission,
        nitroSubscriptionId,
        setNitroSubscriptionId,
        showNVDSubscriptionToggle
    } = useViewEdit();

    const isLoading = isStoreDetailsLoading || isStoreSystemTiersLoading;

    return <>
        <NotificationsList />
        {isLoading ?
            <div className="hme-view-edit-store__loader">
                <Loader/>
            </div> :
            <div className="hme-admin-store-details__wrapper">
                <div className="hme-admin-store-details">
                    <Header
                        storeDetails={storeDetails}
                        isRemoveStoreButtonVisible={isRemoveStoreButtonVisible}
                        onStoreDeleteClick={onStoreDeleteClick}
                    />
                    <div className="hme-admin-store-details__main">
                        <div className="hme-admin-store-details__general-info">
                            <GeneralInformationBlock
                                storeDetails={storeDetails}
                                showAccountEmail={true}
                                showAxNumber={true}
                                AXCustomerNumber={AXCustomerNumber}
                                setAXCustomerNumber={setAXCustomerNumber}
                                AXValidationErrors={validationErrors}
                                showClassifications={true}
                                classificationId={classificationId}
                                setClassificationId={setClassificationId}
                            />
                        </div>
                        <div className="hme-admin-store-details__central-block">
                            <SubscriptionsBlock
                                isJTechCustomerOnlySubscription={isJTechCustomerOnlySubscription}
                                isNexeoCustomerOnlySubscription={isNexeoCustomerOnlySubscription}
                                isNitroSubscription={isNitroSubscription}
                                isNVDEnabled={isNVDEnabled}
                                nitroSubscription={nitroSubscriptionId}
                                deviceDetails={deviceDetails}
                                vaioSubscription={vaioSubscription}
                                nexeoSystemTierID={nexeoSystemTierID}
                                jTechTierID={jTechTierID}
                                onVaioSubChange={onVaioSubChange}
                                onStoreSystemTierChange={onStoreSystemTierChange}
                                onJTechTierChange={onJTechTierChange}
                                subscriptionList={vaioSubscriptionList}
                                storeSystemTiers={storeSystemTiers}
                                isStoreSystemTiersDisabled={isStoreSystemTiersDisabled}
                                initialVaioProvider={initialVaioProvider}
                                hasManageNitroSubscriptionPermission={hasManageNitroSubscriptionPermission}
                                onNitroSubscriptionChange={setNitroSubscriptionId}
                                showNVDSubscriptionToggle={showNVDSubscriptionToggle}
                            />
                        </div>
                        <div className="hme-admin-store-details__device-info__block">
                            <AdminDevicesInformation
                                devicesRows={devicesRows}
                                onAddDeviceClick={onAddDeviceClick}
                                isAddDeviceButtonDisabled={isAddDeviceButtonDisabled}
                                devicesHeaders={devicesHeaders}
                            />
                        </div>
                    </div>
                </div>
                <Footer onCancel={onCancel} onSave={onSave} isSaveBtnDisabled={isSaveBtnDisabled} />
                <Modal
                    title={t('admin__stores__add-device__header')}
                    show={isModalOpen}
                    onHide={onModalClose}
                    dialogClassName="hme-view-edit-store__add-device-modal"
                    actions={[
                        {
                            onClick: () => onModalClose(),
                            children: t('common__close'),
                        },
                        {
                            onClick: () => onAddDevice(),
                            variants: ['submit'],
                            children: t('admin__add-device'),
                            disabled: !snapshot,
                        },
                    ]}
                    >
                    <AddDevice
                        deviceType={deviceType}
                        storeDetails={storeDetails}
                        onDeviceTypeSelected={setDeviceType}
                        onSnapshotSelected={setSnapshot}
                        availableDevices={availableDevices}
                    />
                </Modal>
            </div>
        }
        <ConfirmPopupComponentGeneric
            show={isConfirmPopupOpen}
            title={t('confirm-popup__default-title')}
            message={<>{t(confirmPopupContent.message)}</>}
            onHide={onConfirmPopupClose}
            actions={[{
                children: t('common__cancel'),
                onClick: onConfirmPopupClose
            }, {
                children: t('admin__stores__confirm-remove-devices__button'),
                variants: ['submit'],
                onClick: confirmPopupContent.onConfirm
            }]}
        />
    </>;
};

export const ViewEdit = compose(withHMELayout())(ViewEditComponent);
