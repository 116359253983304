import React from 'react';
import { NavDropdown  } from 'react-bootstrap';

const renderProfile = (profile) => (
    <span>
        <i className="icon icon-account"/>
        <span className="showOnMobile">{profile}</span>
    </span>
);

export const AccountDropdown = ({ profile, children }) => (
    <NavDropdown  title={renderProfile(profile)} className="account-nav-dropdown" id="account-dropdown">
        {children}
    </NavDropdown >
);
