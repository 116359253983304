import { useReducer, useCallback } from 'react';

import { useSafeDispatch } from 'hooks/useSafeDispatch';

export const ASYNC_STATUS = {
    IDLE: 'idle',
    PENDING: 'pending',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
};

const asyncReducer = (state, action) => {
    switch (action.type) {
        case 'pending':
            return {
                ...state,
                status: ASYNC_STATUS.PENDING,
                data: null,
                error: null
            };
        case 'resolved':
            return {
                ...state,
                status: ASYNC_STATUS.RESOLVED,
                data: action.data,
                error: null
            };
        case 'reject':
            return {
                ...state,
                status: ASYNC_STATUS.REJECTED,
                data: null,
                error: action.error
            };
        default:
            return state;
    }
};

export const useAsync = (initialState = {}) => {
    const [state, unsafeDispatch] = useReducer(asyncReducer, {
        status: ASYNC_STATUS.IDLE,
        data: null,
        error: null,
        ...initialState
    });

    const dispatch = useSafeDispatch(unsafeDispatch);

    // The "run" function takes promise and
    // handles setting state for pending, data, and error.
    const run = useCallback((promise) => {
        dispatch({ type: 'pending' });

        return promise.then((data) => {
            dispatch({ type: 'resolved', data });
            return data;
        }).catch((error) => {
            dispatch({ type: 'reject', error });
            throw error;
        });
    }, []);

    const idle = state.status === ASYNC_STATUS.IDLE;
    const pending = state.status === ASYNC_STATUS.PENDING;
    const isLoading = idle || pending;
    const isRejected = state.status === ASYNC_STATUS.REJECTED;

    return { ...state, isRejected, isLoading, run };
};
