import React from 'react';
import classNames from 'classnames';

import { Modal } from 'library/Modal';
import { ErrorMessage } from 'library/ErrorMessage';
import { Label } from 'library/Label';

import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

import './ErrorModal.scss';

const Title = () => (
    <>
        <ErrorIcon className='hme-error-modal-icon' />
        <Label>Error</Label>
    </>
);

export const ErrorModal = ({ show, message, errors, dialogClassName, onHide, children, ...modalProps }) => (
    <Modal
        actions={[{
            children: 'Close',
            onClick: onHide,
        }]}
        {...modalProps}
        show={show && errors.length !== 0}
        title={<Title />}
        dialogClassName={classNames('hme-error-modal', dialogClassName)}
    >
        {
            message &&
            <div className='hme-error-modal-message'>{message}</div>
        }
        <div className='hme-error-modal-list'>
            {
                children ||
                errors.map((error, index) => (
                    <ErrorMessage message={error} key={index} />
                ))
            }
        </div>
    </Modal>
);
