import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';
import { Title } from 'library/Title';
import { SearchInput } from 'components/Inputs/SearchInput';
import { ADMIN_CREATE_DEVICE_UPGRADE_URL } from '../../constants';

import './Header.scss';
import { GridFiltersCount } from 'library/GridFiltersCount';

export const Header = ({ onSearch, filtersCount, onFiltersReset }) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const onSearchChange = useCallback(
        (value) => {
            setSearchValue(value);
            onSearch(value);
        },
        [onSearch],
    );

    return (
        <div className="device-upgrade-list__header">
            <Title>{t('device-upgrades__header__device-upgrades')}</Title>
            <SearchInput
                value={searchValue}
                onChange={onSearchChange}
                placeholder={t('device-upgrades__search-placeholder--device-upgrade')}
            />
            <div className="device-upgrade-list__filters-count">
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
            <Link
                type="button"
                to={ADMIN_CREATE_DEVICE_UPGRADE_URL}
                className="device-upgrade-list__header__create-device-upgrade"
            >
                <Button>{t('device-upgrades__header__create-device-upgrade')}</Button>
            </Link>
        </div>
    );
};
