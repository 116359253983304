// TODO: Please add auto translation to Grid component after i18next is applied
export const translateGridOptions = (options, t) => {
    return options.map((option) => ({
        ...option,
        text: t(option.text) || option.text,
        property: Array.isArray(option.property) ?
            translateGridOptions(option.property, t) :
            option.property,
    }))
};
