import { useTranslation } from 'react-i18next';

import { Toggle, TOGGLE_VARIANTS } from 'library/Toggle';

export const TYPE_FILTERS = {
    ALL: 'all',
    UNREAD: 'unread',
    INVITES: 'invites'
};

const toggleItems = [{
    text: 'All Notifications',
    value: TYPE_FILTERS.ALL,
    key: TYPE_FILTERS.ALL,
    key: TYPE_FILTERS.INVITES
}];

const toggleVariants = [TOGGLE_VARIANTS.ROUNDED, TOGGLE_VARIANTS.WHITE, TOGGLE_VARIANTS.INLINE_LABEL];

export const TypeFilter = ({ value, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-notifications-center__type-filter'>
            <Toggle
                label={t('common__show-me')}
                value={value}
                variants={toggleVariants}
                onChange={onChange}
            >
                {toggleItems}
            </Toggle>
        </div>
    );
};
