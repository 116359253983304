import { loadAccounts as loadAccountsService } from 'services/Account';

export const loadAccounts = async filters => {
    try {
        // TODO: Gather params here and start use getAccounts from service
        return await loadAccountsService(filters);
    } catch (err) {
        return [];
    }
};
