import { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { DESKTOP_RESOLUTION, MOBILE_RESOLUTION } from 'constants/screenResolutions';
import AuthenticationService from '../components/Security/AuthenticationService';
import { Config } from '../Config';

const PAGINATION_FOOTER_BLOCK_EXTERNAL_PADDING = 105; // px
const PAGINATION_BLOCK_EXTERNAL_PADDING = 2 * 15; // px
const BUTTON_WIDTH = 62;
const BUTTON_MARGIN = 5;
const BUTTON_SPACE = BUTTON_WIDTH + BUTTON_MARGIN;
const MIN_PAGINATION_BUTTON_COUNT = 5;
const DEFAULT_PAGINATION_BUTTON_COUNT_WITH_TWO_RANGES = 7;
const MIN_MARGIN_PAGES = 1;
const MIN_PAGES_RANGE = 0;
const DEFAULT_MARGIN_PAGES = 2;
const DEFAULT_PAGES_RANGE = 5;

const MIN_PAGINATION_BLOCK_WIDTH = (MIN_PAGINATION_BUTTON_COUNT) * BUTTON_SPACE;
const MIN_PAGINATION_BLOCK_WIDTH_WITH_TWO_RANGES = (DEFAULT_PAGINATION_BUTTON_COUNT_WITH_TWO_RANGES) * BUTTON_SPACE;

const getCurrentPath = () => {
    const location = useLocation();
    const params  = useParams();

    return Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );
};

const getPaginationStorageKey = () => {
    const authService = new AuthenticationService(Config.authBaseUrl);
    const userDetails = authService.getProfile();
    const userUID = authService.isAdmin() ? userDetails.puid : userDetails.User_UID;

    return `PAGINATION_${userUID}`
};

const getStoredPaginationData = (storageKey, pageKey) => {
    const storedUserPaginationData = localStorage.getItem(storageKey);
    const parsedPaginationData = storedUserPaginationData ? JSON.parse(storedUserPaginationData) : null;

    return parsedPaginationData ? parsedPaginationData[pageKey] : null;
};

const storePaginationData = (storageKey, pageKey, value) => {
    const storedUserPaginationData = localStorage.getItem(storageKey);
    let parsedPaginationData = storedUserPaginationData ? JSON.parse(storedUserPaginationData) : {};
    parsedPaginationData[pageKey] = value;

    localStorage.setItem(storageKey, JSON.stringify(parsedPaginationData));
};

const getAmountOfDefaultButtons = (isFirstOrLastPage, amountOfPaginationButtonsNeedToShow, isAllButtonsInTheScreen) => {
    if (!isFirstOrLastPage && !isAllButtonsInTheScreen) {
        return 4;
    } else if ((amountOfPaginationButtonsNeedToShow < MIN_PAGINATION_BUTTON_COUNT) || isAllButtonsInTheScreen) {
        return 2;
    }
    return 3;
};

const getPaginationButtonsCalculation = (
        deviceScreenWidth,
        amountOfPaginationButtonsNeedToShow,
        amountOfDefaultButtons,
        availableButtonsWidth,
        amountOfAvailableButtons,
        isFirstOrLastPage,
        isAllButtonsInTheScreen,
        isLastPage
) => {
    const allButtonsWidth = ((amountOfPaginationButtonsNeedToShow + amountOfDefaultButtons) * BUTTON_SPACE) - BUTTON_MARGIN;
    const range = Math.floor((amountOfAvailableButtons - amountOfDefaultButtons) / 2);
    const marginPagesDisplayed = isAllButtonsInTheScreen ? range - 1 : range;

    const isNeedToCenteredPaginationBlock = availableButtonsWidth > (allButtonsWidth + BUTTON_WIDTH);

    if (allButtonsWidth >= deviceScreenWidth) {
        if (isFirstOrLastPage) {
            const pageMargin = isLastPage ? MIN_MARGIN_PAGES : marginPagesDisplayed;
            return {
                pageRangeDisplayed: range,
                marginPagesDisplayed: pageMargin
            };
        } else if (!isFirstOrLastPage && isAllButtonsInTheScreen) {
            const pageRangeDisplayed = Math.floor(range / 2);
            return {
                pageRangeDisplayed,
                marginPagesDisplayed: pageRangeDisplayed,
                isNeedToCenteredPaginationBlock: true
            };
        }

        return {
            pageRangeDisplayed: range - 1,
            marginPagesDisplayed,
            isNeedToCenteredPaginationBlock
        };
    }

    if (isAllButtonsInTheScreen) {
        return {
            pageRangeDisplayed: range - 1,
            marginPagesDisplayed: marginPagesDisplayed,
            isNeedToCenteredPaginationBlock
        };
    }

    return {
        pageRangeDisplayed: range,
        marginPagesDisplayed,
        isNeedToCenteredPaginationBlock
    };
};

export const usePagination = (total, defaultRecordsPerPage, page, sectionWidth, onChange) => {
    const [deviceScreenWidth, setDeviceScreenWidth] = useState(0);
    const [CURRENT_PAGE_KEY] = useState(() => {
        return getCurrentPath();
    });
    const [PAGINATION_STORAGE_KEY] = useState(() => {
        return getPaginationStorageKey();
    });


    const [recordsPerPage, setRecordsPerPage] = useState(() => {
        const storedItemsPerPage = getStoredPaginationData(PAGINATION_STORAGE_KEY, CURRENT_PAGE_KEY);

        if (storedItemsPerPage && onChange && (storedItemsPerPage !== defaultRecordsPerPage) ) {
            onChange(
                {
                    page: 0,
                    recordsPerPage: parseInt(storedItemsPerPage)
                }
            )
        }

        return storedItemsPerPage
            ? parseInt(storedItemsPerPage, 10)
            : defaultRecordsPerPage;
    });

    const onScreenSizeChange = useCallback(() => {
        let availableWidth = sectionWidth;

        if (!sectionWidth) {
            const externalPadding =
                window.innerWidth > MOBILE_RESOLUTION
                    ? PAGINATION_FOOTER_BLOCK_EXTERNAL_PADDING
                    : PAGINATION_BLOCK_EXTERNAL_PADDING;

            availableWidth = window.innerWidth - externalPadding;
        }

        setDeviceScreenWidth(availableWidth);
    }, [setDeviceScreenWidth]);

    useEffect(() => {
        window.addEventListener('resize', onScreenSizeChange);
        return () => {
            window.removeEventListener('resize', onScreenSizeChange);
        };
    }, [onScreenSizeChange]);

    useEffect(() => { onScreenSizeChange(); }, [window.innerWidth]);

    useEffect(() => {
        if(recordsPerPage && recordsPerPage !== defaultRecordsPerPage) {
            storePaginationData(PAGINATION_STORAGE_KEY, CURRENT_PAGE_KEY, recordsPerPage)
            onChange && onChange({
                page: 0,
                recordsPerPage: parseInt(recordsPerPage)
            })
        }
    }, [recordsPerPage]);

    const amountOfPaginationButtonsNeedToShow = useMemo(() => Math.ceil(total/recordsPerPage), [total, recordsPerPage]);
    const isFirstOrLastPage = useMemo(() => {
        return page === 0 || (total - (recordsPerPage * page)) / recordsPerPage < 1;
    }, [total, page, recordsPerPage]);

    const isLastPage = useMemo(() => (total - (recordsPerPage * page)) / recordsPerPage < 1, [total, page, recordsPerPage]);

    const amountOfAvailableButtons = useMemo(() => Math.floor(deviceScreenWidth / BUTTON_SPACE), [deviceScreenWidth]);
    const availableButtonsWidth = useMemo(() => (amountOfAvailableButtons * BUTTON_SPACE) - BUTTON_MARGIN, [amountOfAvailableButtons]);
    const isAllButtonsInTheScreen = deviceScreenWidth > availableButtonsWidth;
    const amountOfDefaultButtons =
        getAmountOfDefaultButtons(isFirstOrLastPage, amountOfPaginationButtonsNeedToShow, isAllButtonsInTheScreen);

    const isNeedToCenteredPaginationBlock = (amountOfPaginationButtonsNeedToShow + amountOfDefaultButtons) < MIN_PAGINATION_BUTTON_COUNT;

    if (deviceScreenWidth < DESKTOP_RESOLUTION) {
        if (deviceScreenWidth < MIN_PAGINATION_BLOCK_WIDTH) {
            return {
                marginPagesDisplayed: MIN_MARGIN_PAGES,
                pageRangeDisplayed: MIN_PAGES_RANGE,
                isNeedToCenteredPaginationBlock
            };
        } else if (!isFirstOrLastPage && deviceScreenWidth < MIN_PAGINATION_BLOCK_WIDTH_WITH_TWO_RANGES) {
            return {
                marginPagesDisplayed: MIN_PAGES_RANGE,
                pageRangeDisplayed: MIN_MARGIN_PAGES,
                isNeedToCenteredPaginationBlock: true
            };
        }

        const paginationButtonsCalculationResult = getPaginationButtonsCalculation(
            deviceScreenWidth,
            amountOfPaginationButtonsNeedToShow,
            amountOfDefaultButtons,
            availableButtonsWidth,
            amountOfAvailableButtons,
            isFirstOrLastPage,
            isAllButtonsInTheScreen,
            isLastPage
        );

        return {
            ...paginationButtonsCalculationResult,
            recordsPerPage,
            setRecordsPerPage
        };
    }

    return {
        marginPagesDisplayed: DEFAULT_MARGIN_PAGES,
        pageRangeDisplayed: DEFAULT_PAGES_RANGE,
        isNeedToCenteredPaginationBlock,
        recordsPerPage,
        setRecordsPerPage,
    };
};
