import { useCallback, useRef } from 'react';

export const useAbortController = () => {
    const abortControllerRef = useRef(new AbortController());

    // WARNING: if you add a dependency to this function, you must check functionality on the component that uses it
    const abort = useCallback(() => {
        const aborter = new AbortController();

        if (!abortControllerRef.current.signal.aborted) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = aborter;
    }, []);

    return { abortControllerRef, abort };
};
