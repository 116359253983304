import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup/ConfirmPopup';
import { LoadingModal } from 'library/LoadingModal';

import { ViewEditForm } from '../../Common/ViewEdit';
import { useEdit } from './hooks/useEdit';
import { Header } from './Header';

export const EditPage = ({ contest }) => {
    const { t } = useTranslation();
    const {
        handleCancelContest,
        setContestUIDToCancel,
        contestUIDToCancel,
        isLoadingModalShown,
        hideCancelModal,
        ...state
    } = useEdit({ contest });

    const cancelContestActions = useMemo(() => ([
        {
            children: t('common__popup--cancel-action'),
            onClick: hideCancelModal
        },
        {
            children: t('common__popup--confirm-action'),
            variants: ['submit'],
            onClick: handleCancelContest
        }
    ]), [hideCancelModal, handleCancelContest, t]);

    return (
        <div className="create-contest">
            <Header onCancelContest={() => setContestUIDToCancel(contest.contestUID)} />

            <ViewEditForm {...state} />

            <ConfirmPopupComponentGeneric
                show={Boolean(contestUIDToCancel)}
                title={t('common__double-checking')}
                message={t('contest__warning__cancel-contest')}
                actions={cancelContestActions}
                onHide={hideCancelModal}
            />

            <LoadingModal show={isLoadingModalShown} />
        </div>
    );
};
