import React from 'react';

import { Tip } from 'library/Tip';
import { format, FORMAT_TYPES } from 'services/Date';

import './LeaderBoardTooltip.scss';

export const LeaderBoardTooltip = ({ t, LBGroup }) => {
    return (
        <Tip
            placement="bottom"
            title={`${t('common__history')}:`}
            withCloseButton
            iconClassName="icon-clock"
            className="leaderboard-tooltip"
        >
            <div className="field-row">
                <div className="subheader">{t('common__created').toUpperCase()}</div>
                {LBGroup.CreatedAt ? format(FORMAT_TYPES.DATE_AND_TIME, LBGroup.CreatedAt) : t('common__na')}
            </div>
            <div className="field-row">
                <div className="subheader">{t('common__created-by').toUpperCase()}</div>
                {LBGroup.CreatedBy || 'HMEAdmin'}
            </div>
            <div className="field-row">
                <div className="subheader">{t('common__updated').toUpperCase()}</div>
                {LBGroup.LastUpdatedAt || LBGroup.LastUpdateAt ?
                format(FORMAT_TYPES.DATE_AND_TIME, LBGroup.LastUpdatedAt || LBGroup.LastUpdateAt) : t('common__na')}
            </div>
            <div className="field-row">
                <div className="subheader">{t('common__updated-by').toUpperCase()}</div>
                {LBGroup.LastUpdatedBy || 'HMEAdmin'}
            </div>
        </Tip>
    );
};
