import { companyTypes, pageViews } from './constants';

export const getPageMode = (queryParams) => {
    const type = queryParams.get('type');
    const uuid = queryParams.get('uuid') || queryParams.get('useruid');

    const auuid = queryParams.get('auuid');

    if (auuid) {
        return {
            pageViewType: pageViews.VIEW,
            pageAccountType: companyTypes.DISTRIBUTOR
        };
    }
    if (!type && !uuid) {
        return {
            pageViewType: pageViews.ADD,
            pageAccountType: companyTypes.FRANCHISE
        };
    }

    return {
        pageViewType: type && uuid ? pageViews.EDIT : pageViews.ADD,
        pageAccountType: companyTypes[type.toUpperCase()] || companyTypes.FRANCHISE
    };
};
