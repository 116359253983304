import { useState, useEffect, useCallback } from 'react';
import { getUserAccountDetails } from '../services/Account';

export const useLoadAccounDetails = ({ queryParams }) => {
    const [account, setAccount] = useState(null);
    const [isAccountLoading, setIsAccountLoading] = useState(false);
    const [accountLoadingError, setAccountLoadingError] = useState(null);

    const loadAccount = useCallback(async ({ queryParams }) => {
        try {
            const uuid = queryParams.get('uuid') || queryParams.get('useruid') || queryParams.get('auuid');
            if (!uuid) {
                return;
            }

            setIsAccountLoading(true);
            setAccountLoadingError(null);
            const accountDetails = (await getUserAccountDetails(uuid, true)) || null;

            setAccount(accountDetails);
        } catch (e) {
            setAccount(null);
            setAccountLoadingError(e);
        }

        setIsAccountLoading(false);
    }, []);

    useEffect(() => { loadAccount({ queryParams }); }, [queryParams]);

    return { account, isAccountLoading, accountLoadingError, loadAccount };
};
