import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { equals, intersection, isEmpty, difference } from 'ramda';

import { DateLib } from '@hme-cloud/utility-common';
import { PUBLIC_ROUTES } from 'constants/routes';
import { composeValidators, imageSize, minArrayItems, required } from 'components/Inputs/Validators';
import { commonErrorTranslations, INTERNAL_SERVER_ERROR_KEY } from 'constants/errors';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { updateLeaderboardContest, cancelContest } from '../../../Common/Controller';
import { getBase64FromImage } from '../../../Common/RewardImageInput';
import { REQUEST_SIZE_EXCEEDED_STATUS_CODE } from '../../../Common/Constants';
import { getNewRecurrenceDays, getStoreIds, prepareScheduledTimePeriod } from '../../../Common/utils';
import { useStoresTree } from '../../../Common/hooks';

const createDateTimeToCompare = ({ startDate, endDate, fromTime, toTime }) => ({
    startDate: new DateLib(startDate).format(DateLib.FORMAT_TYPES.DATE_ONLY),
    endDate: new DateLib(endDate).format(DateLib.FORMAT_TYPES.DATE_ONLY),
    from: fromTime,
    to: toTime
});

export const useEdit = ({ contest }) => {
    const { t, i18n: { resolvedLanguage } } = useTranslation();
    const navigate = useNavigate();

    const [isSavingInProgress, setIsSavingInProgress] = useState(false);

    const [values, setValues] = useState(contest);

    const [errors, setErrors] = useState({
        recurrenceDays: ''
    });

    const {
        storesTree,
        contestStoreIds,
        setContestStoreIds,
        storesSearchQuery,
        handleStoresSearchQueryChange,
        checkIsStoresAvailable,
        isCheckingAvailableStores,
        isOnlySelectedStoresShown,
        toggleSelectedStoresOnly,
        originalStoresTreeRef: { current: originalStoresTree },
        originalStoreIdsRef: { current: originalStoreIds }
    } = useStoresTree({ initialContestStoreIds: getStoreIds(contest.contestStores) });

    const [contestUIDToCancel, setContestUIDToCancel] = useState('');
    const [isLoadingModalShown, setIsLoadingModalShown] = useState(false);

    const navigateToContestGrid = useCallback(() => {
        navigate(PUBLIC_ROUTES.contestsList);
    }, [navigate]);

    const handleCancelContest = useCallback(async () => {
        if (!contestUIDToCancel) {
            return;
        }

        try {
            setIsLoadingModalShown(true);
            setContestUIDToCancel('');
            await cancelContest(contestUIDToCancel);

            navigateToContestGrid();

            setTimeout(() => {
                addSuccessNotification('contest__removed-contest--success', { autoClose: 5000 });
            }, 300);
        } catch (error) {
            addErrorNotification(error.message, { autoClose: 5000 });
        } finally {
            setIsLoadingModalShown(false);
        }
    }, [contestUIDToCancel, navigateToContestGrid]);

    const hideCancelModal = useCallback(() => {
        setContestUIDToCancel('');
    }, []);

    const handleValueChange = useCallback((fieldName) => (value) => {
        setValues((currentValues) => ({
            ...currentValues,
            [fieldName]: value
        }));
    }, []);

    const handleErrorChange = useCallback((errorObj = {}) => {
        setErrors((currentErrors) => ({
            ...currentErrors,
            ...errorObj
        }));
    }, []);

    const handleTimeZoneChange = useCallback((selectedTimeZone) => {
        handleValueChange('timeZone')(selectedTimeZone);
        handleErrorChange({ timeZone: '' });
    }, [handleValueChange, handleErrorChange]);

    const updateRecurrenceDays = useCallback(
        ({ startDate, endDate }) => {
            if (!startDate || !endDate) {
                return;
            }

            const diff = endDate.diff(startDate, 'days');
            const { dayOfWeek: startDateDayOfWeek } = startDate;

            if (diff < DateLib.AMOUNT_OF_WEEK_DAYS) {
                const newRecurrenceDays = getNewRecurrenceDays(startDateDayOfWeek, diff + 1);

                handleValueChange('recurrenceDays')(newRecurrenceDays);
            } else {
                const allRecurrenceDays = Array.from({ length: DateLib.AMOUNT_OF_WEEK_DAYS }, (_, idx) => idx);

                handleValueChange('recurrenceDays')(allRecurrenceDays);
            }
        },
        [handleValueChange]
    );

    const handleSchduledTimePeriodChange = useCallback(
        ({ from, to }) => {
            if (!from) {
                return;
            }

            const preparedPeriods = prepareScheduledTimePeriod({ from, to });

            handleValueChange('scheduledTimePeriod')({ from, to, periods: preparedPeriods });
        },
        [handleValueChange]
    );

    const handleImageDrop = useCallback(
        async (files) => {
            const uploadedImage = await getBase64FromImage(files[0]);

            const imageSizeErr = await imageSize()(uploadedImage);

            handleValueChange('rewardImg')(uploadedImage);

            if (imageSizeErr) {
                handleErrorChange({ rewardImg: t('common__error--image-size-new') });
            } else {
                handleErrorChange({ rewardImg: '' });
            }
        },
        [handleValueChange, handleErrorChange, t]
    );

    const handleImageRemove = useCallback(() => {
        handleValueChange('rewardImg')(null);
        handleErrorChange({ rewardImg: '' });
    }, [handleErrorChange, handleValueChange]);

    const handleContestStoresChange = useCallback(
        (stores) => {
            setContestStoreIds(stores);
            handleErrorChange({ contestStores: [] });
        },
        [setContestStoreIds, handleErrorChange]
    );

    const handleStartDateChange = useCallback(
        (newStartDate) => {
            const newEndDate = newStartDate > values.endDate ? newStartDate : values.endDate;

            handleValueChange('startDate')(newStartDate);
            handleValueChange('endDate')(newEndDate);

            updateRecurrenceDays({ startDate: newStartDate, endDate: newEndDate });
        },
        [values.endDate, handleValueChange, updateRecurrenceDays]
    );

    const handleEndDateChange = useCallback(
        (newEndDate) => {
            handleValueChange('endDate')(newEndDate);

            updateRecurrenceDays({ startDate: values.startDate, endDate: newEndDate });
        },
        [values.startDate, handleValueChange, updateRecurrenceDays]
    );

    const handleSubmit = useCallback(async () => {
        let hasValidationError = false;

        const timeZoneValidationResult = composeValidators(
            required(t('common__timezone'))
        )(values.timeZone.value);

        const storesValidationResult = composeValidators(
            required(t('common__stores')),
            minArrayItems(2)
        )(contestStoreIds);

        const recurrenceDaysValidationResult = composeValidators(
            required(t('contest__time-period--days'))
        )(values.recurrenceDays);

        if (storesValidationResult) {
            handleErrorChange({ contestStores: storesValidationResult });

            hasValidationError = true;
        }

        if (timeZoneValidationResult) {
            handleErrorChange({ timeZone: timeZoneValidationResult });

            hasValidationError = true;
        }

        if (recurrenceDaysValidationResult) {
            handleErrorChange({ recurrenceDays: recurrenceDaysValidationResult });

            hasValidationError = true;
        }

        if (hasValidationError) {
            return;
        }

        try {
            setIsSavingInProgress(true);

            await updateLeaderboardContest(contest.contestUID, {
                ...values,
                language: resolvedLanguage,
                contestStores: contestStoreIds
            });

            setTimeout(() => {
                addSuccessNotification('contest__update--success', { autoClose: 5000 });
            }, 300);

            navigateToContestGrid();
        } catch (err) {
            if (err?.response?.status === REQUEST_SIZE_EXCEEDED_STATUS_CODE) {
                handleErrorChange({ rewardImg: t('common__error--fize-size-exceeded') });
            }

            if (err?.response?.data?.data?.message === 'Validation error') {
                const { formErrors } = err.response.data.data;

                if (formErrors.ContestStores) {
                    handleErrorChange({
                        contestStores: t(formErrors.ContestStores.tKey, { stores: formErrors.ContestStores.options.stores })
                    });
                }
            }

            addErrorNotification(
                err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY,
                { autoClose: 5000 }
            );
        } finally {
            setIsSavingInProgress(false);
        }
    }, [values, contestStoreIds, handleErrorChange, navigateToContestGrid, t, resolvedLanguage]);

    useEffect(() => {
        setValues((currentValues) => Object.assign(currentValues, contest.values));
    }, [contest.values]);

    useEffect(() => {
        const { endDate, startDate, scheduledTimePeriod, recurrenceDays } = values;

        if (!(startDate || endDate)) {
            return;
        }

        const fullStartDate = new DateLib(startDate).setHours(parseInt(scheduledTimePeriod.from || 0, 10));
        const fullEndDate = new DateLib(endDate).setHours(parseInt(scheduledTimePeriod.to || 0, 10));

        const currentDate = new DateLib();

        const daysDiff = fullEndDate.diffDays(fullStartDate);
        const { dayOfWeek: startDateDayOfWeek } = fullStartDate;

        const isStartDateBeforeCurrent = fullStartDate.isBefore(currentDate);

        if (isStartDateBeforeCurrent) {
            handleErrorChange({ startDate: t('contest__error__start-date-time') });

            return;
        }

        if (daysDiff < DateLib.AMOUNT_OF_WEEK_DAYS) {
            const newRecurrenceDays = getNewRecurrenceDays(startDateDayOfWeek, daysDiff + 1);

            const intersectionLength = intersection(newRecurrenceDays, recurrenceDays).length;

            if (!intersectionLength || recurrenceDays.length > intersectionLength) {
                handleErrorChange({ recurrenceDays: t('contest__error__days-not-present-in-range') });

                return;
            }
        }

        handleErrorChange({ recurrenceDays: '', startDate: '' });
    }, [
        values.startDate,
        values.endDate,
        values.scheduledTimePeriod,
        values.recurrenceDays,
        values.timeZone,
        handleErrorChange,
        t
    ]);

    useEffect(() => {
        const { startDate, endDate, scheduledTimePeriod, recurrenceDays, timeZone } = values;
        const newDateTime = createDateTimeToCompare({
            startDate,
            endDate,
            fromTime: scheduledTimePeriod.from,
            toTime: scheduledTimePeriod.to
        });
        const initialDateTime = createDateTimeToCompare({
            startDate: contest.startDate,
            endDate: contest.endDate,
            fromTime: contest.scheduledTimePeriod.from,
            toTime: contest.scheduledTimePeriod.to
        });

        const isNewDateSelectedForSchedule = equals(initialDateTime, newDateTime);

        checkIsStoresAvailable({
            startDate,
            endDate,
            scheduledTimePeriod,
            recurrenceDays,
            timeZone,
            contestUID: contest.contestUID,
            contestStoreIdsToCheck: isNewDateSelectedForSchedule ?
                difference(originalStoreIds, getStoreIds(contest.contestStores)) :
                originalStoreIds
        });
    }, [
        contest,
        values.startDate,
        values.endDate,
        values.scheduledTimePeriod,
        values.recurrenceDays,
        values.timeZone,
        originalStoresTree,
        originalStoreIds
    ]);

    return {
        isSavingInProgress,
        isCheckingAvailableStores,
        isLoading: isEmpty(values) && isEmpty(storesTree),

        values: {
            ...values,
            contestStoresSearchQuery: storesSearchQuery,
            contestStores: contestStoreIds
        },
        errors,
        storesTree,

        onSubmit: handleSubmit,

        onValueChange: handleValueChange,
        onImageDrop: handleImageDrop,
        onImageRemove: handleImageRemove,
        onSchduledTimePeriodChange: handleSchduledTimePeriodChange,
        onStoresSearchQueryChange: handleStoresSearchQueryChange,
        onContestStoresChange: handleContestStoresChange,
        onStartDateChange: handleStartDateChange,
        onEndDateChange: handleEndDateChange,
        onTimeZoneChange: handleTimeZoneChange,

        isOnlySelectedStoresShown,
        toggleSelectedStoresOnly,

        handleCancelContest,
        setContestUIDToCancel,
        contestUIDToCancel,
        isLoadingModalShown,
        hideCancelModal
    };
};
