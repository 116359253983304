import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { getDataAsync, postDataAsync } from 'Api';

export const getServiceAccounts = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDataFeedServiceAccounts}`;
    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data.serviceAccounts;
};

export const getCloudAccounts = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDataFeedCloudAccounts}`;
    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data.accounts;
};

export const getCloudAccount = async (accountID) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getCloudAccount(accountID)}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const getDataFeedAccounts = async (datafeedId) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDataFeedList}`;
    const response = await postDataAsync(url, { datafeedId });

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const getAccountDataFeeds = async (accountEmail) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDataFeedList}`;
    const response = await postDataAsync(url, { accountEmail });

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const createDataFeed = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createServiceAccount}`;
    const response = await postDataAsync(url, payload);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const deleteDataFeed = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteServiceAccount}`;

    const response = await postDataAsync(url, payload);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};


export const linkCloudAccount = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.linkCloudAccount}`;
    const response = await postDataAsync(url, payload);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const unlinkCloudAccount = async (payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.unlinkCloudAccount}`;
    const response = await postDataAsync(url, payload);

    if (!response.status) {
        if (response.key === 'ALREADY_EXISTS') {

        }

        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const getDataFeed = async (dataFeedID) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getServiceAccount(dataFeedID)}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};
