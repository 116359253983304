import React from 'react';
import StoredetailsComponent from '../../components/Stores/StoreDetailsComponent'
import ZoomComponent from '../../components/Stores/ZoomComponent/ZoomComponent'
import NexeoComponent from '../../components/Stores/NexeoComponent/NexeoComponent'
import CIBComponent from '../../components/Stores/CIBComponent'
import Device from '../../components/Stores/Device'
import AddDeviceComponent from '../../components/Stores/PreInstallStoreComponents/AddDeviceComponent'
import EditStoreComponent from '../../components/Stores/PreInstallStoreComponents/EditStoreComponent'
import AddStoreComponent from '../../components/Stores/PreInstallStoreComponents/AddStoreComponent'
import EVDComponent from '../../components/Stores/EVDComponent/EVDComponent';


export const getTabs = () => {
  return {
    'viewStore': {
      caption: 'stores__modal__tab--details__title',
      component: 'StoredetailsComponent',
      device: '',
    },
    'addStore': {
      caption: 'stores__modal__tab--details__title',
      component: 'AddStoreComponent',
      device: '',
    },
    'editStore': {
      caption: 'stores__modal__tab--details__title',
      component: 'EditStoreComponent',
      device: '',
    },
    'CIB': {
      caption: 'common__device__type--cib',
      component: 'CIBComponent',
      device: 'CIB',
    },
    'EOS': {
      caption: 'common__device__type--eos',
      component: 'Device',
      device: 'EOS',
    },
    'ION': {
      caption: 'common__device__type--ion',
      component: 'Device',
      device: 'ION',
    },
    'NEXEO': {
      caption: 'common__device__type--nexeo',
      component: 'NexeoComponent',
      device: 'NEXEO',
    },
    'ZOOM': {
      caption: 'common__device__type--zoom',
      component: 'ZoomComponent',
      device: 'ZOOM',
    },
    'LinkWear': {
      caption: 'common__device__type--link-wear',
      component: 'Device',
      device: 'LinkWear'
    },
    'ZOOM Nitro': {
      caption: 'common__device__type--zoom-nitro',
      component: 'ZoomComponent',
      device: 'ZOOM Nitro',
    },
    'addDevice': {
      caption: 'addDevice',
      component: 'AddDeviceComponent',
      device: '',
    },
    'ZOOM Nitro/EVD': {
      caption: 'common__device__type--nitro-evd',
      component: 'EVDComponent',
      device: 'ZOOM Nitro/EVD',
    }
  }
};

export const components = {
  StoredetailsComponent: (props) => (<StoredetailsComponent {...props} editable= {true} />),
  AddStoreComponent,
  EditStoreComponent,
  ZoomComponent,
  NexeoComponent,
  CIBComponent,
  Device,
  AddDeviceComponent,
  EVDComponent,
}