import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { masquaradeUser } from 'services/Auth';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { COMMON_ROUTES } from 'constants/routes';

import { defaultPartner } from '../../../Common/constants/deaultPartner';
import { useLoadPartner } from './useLoadPartner';
import { useSavePartner } from './useSavePartner';
import { useResend } from './useResend';
import { useMasquerade } from './useMasquerade';

const errorTKeys = {
    'Bad Request': 'common__error--invalid-data',
    'userAlreadyExist': 'admin__partner__form__error--user-exists',
    'notAllowedToChangePartnerType': 'partner__form__error--not-allowed-to-change-partner-type',
};

export const useViewEidt = () => {
    const navigate = useNavigate();
    const [partner, setPartner] = useState(defaultPartner);

    const countryId = partner ? partner.countryId || 1 : 1;

    const {
        isLoading,
        countries,
        regions,
        types,
        isRegionsLoading,
        partner: originPartner,
    } = useLoadPartner({ countryId });

    const {
        isSaving,
        save
    } = useSavePartner();

    const {
        resend,
        isResending
    } = useResend();

    const {
        masquerade,
        isMasquerading
    } = useMasquerade(originPartner?.email);

    const onCancel = useCallback(() => {
        navigate('/admin/partners');
    }, [navigate]);

    const onSave = useCallback(async () => {
        try {
            await save(partner);

            navigate('/admin/partners');

            setTimeout(() => {
                addSuccessNotification('admin__partner__form__success--update');
            }, 500);
        } catch (err) {
            addErrorNotification(errorTKeys[err.message] || 'common__error--network-error');
        }
    }, [partner, navigate]);

    const onResend = useCallback(async () => {
        try {
            await resend();

            addSuccessNotification('admin__partner__form__success--reactivate');
        } catch (err) {
            addErrorNotification(errorTKeys[err.message] || 'common__error--network-error');
        }
    }, [resend]);

    const onMasquerade = useCallback(async () => {
        try {
            const result = await masquerade();

            if (!result?.accessToken) {
                throw new Error();
            }

            masquaradeUser(result.accessToken);
            window.location.pathname = `/${COMMON_ROUTES.welcome}`;
        } catch (err) {
            addErrorNotification('common__error--internal-server__p1');
        }
    }, [originPartner, navigate]);

    useEffect(() => {
        setPartner(originPartner || defaultPartner);
    }, [originPartner]);

    return {
        isLoading: isLoading || isSaving || isResending || isMasquerading,
        countries,
        regions,
        types,
        title: 'admin__partner__form__title--edit',
        isRegionsLoading,
        partner,
        originPartner: originPartner,
        onChange: setPartner,
        onCancel,
        onSave,
        onResend,
        onMasquerade
    };
};
