import { useMemo, useState, useEffect, useCallback } from 'react';

import { applySearchValue } from 'components/Common/Grid';
import sortBySelection from 'helpers/sortBySelection';

import { partnerToRow } from '../../Common/helpers/partnerToRow';
import { INIT_SORTING, INIT_SEARCH } from '../../Common/constants/headers';

const pageSizes = [10, 20, 50, 100];

const defaultRows = [];

export const usePartnerRows = ({ partners }) => {
    const rows = useMemo(() => partners ? partners.map((partner) => partnerToRow(partner)) : defaultRows, [partners]);

    const [searchValue, setSearchValue] = useState(INIT_SEARCH);

    const [filteredRows, setFilteredRows] = useState(defaultRows);
    const [showedRows, setShowedRows] = useState(defaultRows);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageSizes[0]);
    const [sorting, setSorting] = useState(INIT_SORTING);

    const onSearch = useCallback((newSearch) => {
        setSearchValue(newSearch);
    }, []);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPage(page);
        setRowsPerPage(recordsPerPage === -1 ? recordsPerPage : Number.parseInt(recordsPerPage));
    }, []);

    const pagination = useMemo(() => ({
        page: page,
        total: filteredRows.length,
        recordsPerPage: rowsPerPage,
        pageSizes
    }), [page, filteredRows.length, rowsPerPage]);

    const onSortingChange = useCallback((newSorting) => {
        setSorting(newSorting);
    }, []);

    useEffect(() => {
        let result = applySearchValue(rows, [searchValue.selectValue], searchValue.searchValue, {  minLength: 1 });
        result = sortBySelection({ list: result, sortSelection: sorting });

        setFilteredRows(result);
        setPage(0);
    }, [rows, searchValue, sorting]);

    useEffect(() => {
        if (rowsPerPage === -1) {
            return setPagedRows(filteredRows);
        }

        const newRows = filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        setShowedRows(newRows);
    }, [filteredRows, page, rowsPerPage]);

    return {
        rows: showedRows,
        pagination,
        sorting,
        onSortingChange,
        onSearch,
        onPaginationChange,
    }
};
