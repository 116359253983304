import { checkAccess } from "../actions/permissions";
import { CommonConstants } from "../Constants";
import AuthenticationService from "../components/Security/AuthenticationService";
import { isDistributor } from "services/Auth";
const isDistributorUser = isDistributor();


const { userPermissions, adminPermissions, externalPermissions } = CommonConstants;

export default class PermissionSpecification {
    constructor(authService) {
        this.isAccountOwner = Boolean(authService.getProfile().IsAccountOwner);
    }

    get hasEditStoreAdvancedPermission() {
        return this.isAccountOwner || checkAccess(userPermissions.EditStoreAdvanced);
    }

    get hasAddDevicePermission() {
        return checkAccess(userPermissions.AddDevice);
    }

    get hasManageRolePermission() {
        return checkAccess(userPermissions.ManageRole);
    }
    get hasManageUserPermission() {
        return checkAccess(userPermissions.ManageUser);
    }
    get hasEditDeviceSettingsPermission() {
        return checkAccess(userPermissions.EditDeviceSettings);
    }

    get hasEditLeaderboardPermission() {
        return checkAccess(userPermissions.EditLeaderboard);
    }

    get hasViewLeaderboardPermission() {
        return checkAccess(userPermissions.ViewLeaderboard);
    }

    get hasEditStoreBasicPermission() {
        return checkAccess(userPermissions.EditStoreBasic);
    }

    get hasPerformDeviceRestartPermission() {
        return checkAccess(userPermissions.PerformDeviceRestart);
    }

    get hasRemoteConnectPermission() {
        return checkAccess(userPermissions.RemoteConnect);
    }

    get hasViewAllStoresPermission() {
        return checkAccess(userPermissions.ViewAllStores);
    }

    get hasSmackTalkPermission() {
        return checkAccess(userPermissions.SmackTalk);
    }

    get hasEditStoreMergePermission() {
        return checkAccess(userPermissions.EditStoreMerge);
    }

    get hasAddStorePermission() {
        return checkAccess(userPermissions.AddStore);
    }

    get hasCreateCorpAccountsPermision() {
        return checkAccess(userPermissions.CreateCorpAccounts);
    }

    get hasCreateAccountsPermission() {
        return checkAccess(adminPermissions.CreateAccounts);
    }

    get hasEditAccountsPermission() {
        return checkAccess(adminPermissions.EditAccounts);
    }

    get hasMoveSubscriptionPermission() {
        return checkAccess(adminPermissions.MoveSubscription);
    }

    get hasEditDeviceAdvanced() {
        return checkAccess(adminPermissions.EditDeviceAdvanced);
    }

    get hasMasqueradePermission() {
        return checkAccess(adminPermissions.Masquerade);
    }

    get hasDistributorManageDeviceBasic() {
        return isDistributor() && checkAccess(CommonConstants.externalPermissions.ManageDevicesBasic);
    }
}
