import {useCallback, useEffect, useMemo, useState} from 'react';

import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { useTokensLoader } from '../useTokensLoader';
import { deleteTokenManagementList } from '../../Controller';
import { getSortedRows } from './utils';
import { useTokensState } from './useTokensState';
import {PAGE_DEFAULT} from "constants/paginationDefault";

const prepareFetchedTokens = (token) => ({
    ...token,
    originalExpiryDate: token.expiryDate
});

export const useTokensList = () => {
    const { tokens, setTokens, removeToken } = useTokensState([]);
    const { fetchedTokens, isLoading } = useTokensLoader();

    const [sortSelection, setSortSelection] = useState({ expiryDate: 1 });

    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);
    const [totalRows, setTotalRows] = useState(0);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, []);

    const paginatedRows = useMemo(
        () => tokens.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage),
        [tokens, pageNumber, itemsPerPage]
    )

    const onSortChange = useCallback((newSorting) => {
        setSortSelection(newSorting);
        setTokens(getSortedRows(tokens, newSorting));
    }, [tokens, setTokens]);

    const onRemove = useCallback(async (tokenId) => {
        try {
            await deleteTokenManagementList(tokenId);

            removeToken(tokenId);
            addSuccessNotification('admin__token-management__delete__notification__toast--success');
        } catch (err) {
            addErrorNotification(err.message);
        }
    }, [removeToken]);

    useEffect(() => {
        if (!fetchedTokens) return;

        setTokens(fetchedTokens.map(prepareFetchedTokens));
        setTotalRows(fetchedTokens.length)
    }, [fetchedTokens]);

    return {
        tokens,
        isLoading,

        sortSelection,
        onSortChange,

        onRemove,

        pageNumber,
        itemsPerPage,
        totalRows,
        onPaginationChange,
        paginatedRows
    };
};
