import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';
import { Loader } from 'library/Loader';

const BTN_VARIANTS = ['submit'];

export const Footer = ({ isSubmitDisabled, isPasswordChangeInProgress, onCancel }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-change-password-form__buttons">
            <Button onClick={onCancel} disabled={isPasswordChangeInProgress}>
                {t('common__cancel')}
            </Button>
            <Button
                variants={BTN_VARIANTS}
                disabled={isSubmitDisabled || isPasswordChangeInProgress}
                className="hme-change-password-form__submit-btn"
                type="submit"
            >
                {!isPasswordChangeInProgress ? (
                    t('profile__account__change-password')
                ) : (
                    <span className="hme-change-password-form__loader-wrapper">
                        <Loader className="hme-change-password-form__btn-loader" />
                    </span>
                )}
            </Button>
        </div>
    );
};
