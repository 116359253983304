import React, { useState, useRef, useCallback, forwardRef, useEffect } from 'react';
import classNames from 'classnames'

import { Tooltip } from 'library/Tooltip';
import { useClickOutSide } from 'hooks/useClickOutSide'

import './Tip.scss';

const DefaultIcon = forwardRef((props, ref) => {
    return <span {...props} ref={ref} />;
});

DefaultIcon.displayName = 'TipDefaultIcon';

export const Tip = ({
    placement = 'top',
    iconClassName = 'icon-info',
    title = '',
    withCloseButton = false,
    children,
    Icon = DefaultIcon,
    clickable = true,
    showTip = false,
    setShowTip,
    ...props
}) => {
    const tipRef = useRef(null);
    const tooltipRef = useRef(null);
    const [isShown, setIsShown] = useState(showTip);

    const outClickHandler = useCallback(() => {
        setIsShown(false);
        setShowTip && setShowTip(false);
    }, [setIsShown]);

    useClickOutSide([tipRef, tooltipRef], outClickHandler);
    useEffect(()=> {
        setIsShown(showTip);
    }, [showTip]);

    const headline = (
        <div className="tooltip-headline">
            {title ? (
                <div className="title">
                    <span className={classNames('icon', iconClassName)}></span>
                    <strong>{title}</strong>
                </div>
            ) : null}
            {withCloseButton ? (
                <span className="btn-close icon-close-alt-1" onClick={() => {
                    setIsShown(false);
                    setShowTip && setShowTip(false);
                }}></span>
            ) : null}
        </div>
    );


    return (
        <div className={classNames('hme-tip', {
            'hme-tip-opened': isShown,
        })}>
            <Icon
                className={classNames('icon', iconClassName)}
                ref={tipRef}
                onClick={() => {
                    if (clickable) {
                        setIsShown(!isShown);
                        setShowTip && setShowTip(!isShown);
                    }
                }}
                onMouseEnter={() => {
                    if (!clickable) {
                        setIsShown(true);
                        setShowTip && setShowTip(true);
                    }
                }}
                onMouseLeave={() => {
                    if (!clickable) {
                        setIsShown(false);
                        setShowTip && setShowTip(false);
                    }
                }}
            ></Icon>
            <Tooltip
                {...props}
                target={tipRef}
                show={isShown}
                placement={placement}
                tooltipContentRef={tooltipRef}
            >
                {title || withCloseButton ? headline : null}
                {children}
            </Tooltip>
        </div>
    );
};
