import { CommonConstants } from 'Constants';

const { subscription, subscriptionNames } = CommonConstants;
export const nitroSubscriptionTypeList = [
    {
        label: subscriptionNames.nitroEssentials,
        value: Number(subscription.nitroEssentials),
    },
    {
        label: subscriptionNames.nitroData,
        value: Number(subscription.nitroData),
    },
    {
        label: subscriptionNames.nitroLeaderboard,
        value: Number(subscription.nitroLeaderboard),
    },
    {
        label: subscriptionNames.nitroGamification,
        value: Number(subscription.nitroGamification),
    }
];
