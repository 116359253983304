/* eslint-disable react/prop-types */
import {
    deleteReportTemplate,
    getAllReportTemplates,
    saveReportTemplate,
    trackLastUsedReportTemplate,
    updateReportTemplate
} from 'api/pbiReports';
import { InputComponent } from 'components/Inputs';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { Tooltip } from 'library/Tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Button as FooterButton } from 'library/Button';
import { addErrorNotification, addSuccessNotification, dismissNotification } from 'services/Notifications';
import { useTranslation } from 'react-i18next';
import { Modal } from 'library/Modal';
import classNames from 'classnames';
import { useReportTemplateContext } from 'components/LandingPage/ReportLandingPage/context/ReportTemplateContext';

/**
 * Template component to save, apply, update and delete templates.
 * @param {String} reportType multi/single/trend report
 * @param {Object} TemplateData slicer information captured on report rendered
 * @return {JSX}
 */
export const PbiTemplate = ({ reportType, templateData }) => {
    const { selectedTemplate } = useReportTemplateContext();

    const report = window.report;
    const { t } = useTranslation();

    const [reportTemplates, setReportTemplates] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const getTemplateList = async () => {
        const params = {};
        params.reportType = reportType;
        const reportTemplateList = await getAllReportTemplates(params);
        setReportTemplates(reportTemplateList.recordset);
    };

    // Hooks and variables for save template popup
    const [showSaveTemplate, setShowSaveTemplate] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const saveTemplateRef = useRef(null);
    const saveTemplateButtonRef = useRef(null);
    const [templateInfo, setTemplateInfo] = useState({});
    const [showMaxLimitWarning, setShowMaxLimitWarning] = useState(false);

    const handleClose = () => {
        setTemplateName('');
        setShowSaveTemplate(false);
    };

    const outClickHandlerSaveTemplate = useCallback(() => {
        handleClose();
    }, [setShowSaveTemplate]);

    useClickOutSide([saveTemplateButtonRef, saveTemplateRef], outClickHandlerSaveTemplate);


    /**
     * function to save template
     * @param {template_info} data
     */
    const saveTemplate = async (data) => {
        dismissNotification();
        try {
            // console.log(templateInfo);
            const result = await saveReportTemplate(data);
            if (result.output.status) {
                addSuccessNotification(t('report-template__success-save'), { autoClose: 3000 });
                getTemplateList();
            } else {
                addErrorNotification(t('report-template__fail-save'), { autoClose: 3000 });
            }
        } catch (error) {
            console.log(error);
        }
        setTemplateName('');
        setTemplateInfo({});
    };

    /**
     * function to update template
     * @param {template_info} data
     */
    const updateTemplate = async () => {
        dismissNotification();
        try {
            const result = await updateReportTemplate(templateInfo);
            if (result.output.status) {
                addSuccessNotification(t('report-template__success-update'), { autoClose: 3000 });
                getTemplateList();
            } else {
                addErrorNotification(t('report-template__fail-update'), { autoClose: 3000 });
            }
        } catch (error) {
            console.log(error);
        }
        setTemplateName('');
        setTemplateInfo({});
    };

    /**
     * Function to handle save button action.
     * If new template then call saveTemplate function
     * Else show update confirmation modal
     */

    const handleSave = async () => {
        setShowSaveTemplate(false);
        try {
            const template = reportTemplates.find((reportTemplate) => reportTemplate.TemplateName === templateName);
            const capturedBookmark = await report.bookmarksManager.capture({ personalizeVisuals: true });
            const data = {
                templateName,
                reportType,
                bookmark: capturedBookmark.state,
                version: templateData.version,
                templateData
            };
            setTemplateInfo(data);
            if ( template ) {
                setTemplateInfo((prevInfo)=> ({ ...prevInfo, templateUID: template.TemplateUID }));
                setShowConfirmationModal(true);
            } else {
                saveTemplate(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * UI for save template
     */
    const saveNewTemplate = (
        <div className="modal-save-template">
            <label htmlFor="templateName" className="leaderboard-input-label">
                <b className="text-uppercase">{t('common__template-name')}</b>
            </label>
            <InputComponent
                value={templateName}
                onChange={setTemplateName}
                maxLength={50}
            />
            <div className="modal-save-template-buttons">
                <FooterButton onClick={handleClose}>
                    {t('common__cancel')}
                </FooterButton>
                <FooterButton
                    onClick={handleSave}
                    variants={['submit']}
                    disabled={!templateName || reportTemplates.length>9}
                >
                    {t('common__save')}
                </FooterButton>
            </div>
        </div>
    );

    // Hooks and variables for apply template
    const [showApplyTemplate, setShowApplyTemplate] = useState(false);
    const applyTemplateRef = useRef(null);
    const applyTemplateButtonRef = useRef(null);
    const [showVersionMismatchModal, setShowVersionMismatchModal] = useState(false);
    const [templateUIDtoDelete, setTemplateUIDtoDelete] = useState();

    const outClickHandlerApplyTemplate = useCallback(() => {
        setShowApplyTemplate(false);
    }, [setShowApplyTemplate]);

    useClickOutSide([applyTemplateButtonRef, applyTemplateRef], outClickHandlerApplyTemplate);

    useEffect(() => {
        getTemplateList();
    }, []);

    /**
     * function to apply the template to report
     * @param {object} template details
     */
    const handleApply = async (template) => {
        dismissNotification();
        setShowApplyTemplate(false);
        try {
            await report.bookmarksManager.applyState(template.Bookmark);
            addSuccessNotification(t('report-template__success-apply'), { autoClose: 3000 });
            trackLastUsedReportTemplate({
                templateUID: template.TemplateUID,
                reportType: template.ReportType
            });
        } catch (error) {
            console.log(error);
        }
    };
    /**
     * function to apply the template to report
     * @param {string} templateUID
     */
    const handleVersionMismatch = (templateUID) => {
        setShowVersionMismatchModal(true);
        setShowApplyTemplate(false);
        setTemplateUIDtoDelete(templateUID);
    };

    /**
     * function to delete the template
     * @param {uniqueidentifier} templateUID
     */
    const handleDelete = async (templateUID) => {
        dismissNotification();
        try {
            const params = { templateUID };
            const result = await deleteReportTemplate(params);
            if (result.output.status) {
                addSuccessNotification(t('report-template__success-delete'), { autoClose: 3000 });
            } else {
                addErrorNotification(t('report-template__fail-delete'), { autoClose: 3000 });
            }
            getTemplateList();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=> {
        if (selectedTemplate !==null) {
            if (reportType === selectedTemplate.ReportType && templateData.version) {
                const isValid = selectedTemplate.Version.slice(0, 1)
                === templateData.version.slice(0, 1);
                if (isValid) {
                    handleApply(selectedTemplate);
                } else {
                    handleVersionMismatch(selectedTemplate.TemplateUID);
                }
            }
        }
    }, [selectedTemplate, templateData.version]);

    /**
     * UI for apply template
     * @return {HTML}
     */
    const applyTemplate = (
        <div className="modal-save-template">
            <div className="report-apply-template-header">
                <label htmlFor="templateName" className="leaderboard-input-label">
                    {t('common__template-name')}
                </label>
                <i className="fas fa-close report-template-name" onClick={ ()=> setShowApplyTemplate(false) }> </i>
            </div>
            <div className="report-template-list">
                { reportTemplates.length > 0 && templateData.version ? reportTemplates.map((reportTemplate, idx) => {
                    const isValid = reportTemplate.Version.slice(0, 1) === templateData.version.slice(0, 1);
                    return (<div className="report-template-item" key={idx}>
                        <label
                            className={`report-template-name ${isValid ? '': 'report-template-invalid-name'}`}
                            onClick={
                                ()=> isValid ? handleApply(reportTemplate) : handleVersionMismatch(reportTemplate.TemplateUID)
                            }
                        >
                            { reportTemplate.TemplateName }
                        </label>
                        <i className="fas fa-trash-alt report-template-name" onClick={()=> handleDelete(reportTemplate.TemplateUID)}> </i>
                    </div>);
                }) : <p>{t('report-template__not-available')}</p> }
            </div>
        </div>
    );

    const onMouseEnter = () => {
        if (!showSaveTemplate && !showApplyTemplate) {
            setShowMaxLimitWarning(true);
        }
    };

    const onMouseLeave = () => {
        setShowMaxLimitWarning(false);
    };

    const onSaveTemplate = () => {
        setShowSaveTemplate(!showSaveTemplate);
        setShowMaxLimitWarning(false);
    };

    return (
        <>
            <div className="template-actions">
                <div
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    className="report_template-warning_container"
                >
                    {showMaxLimitWarning && (
                        <div className="report_template-warning_message">
                            <span>{t('reports__template-warning')}</span>
                        </div>
                    )}
                    <Button
                        className={classNames(
                                'ml-3', reportTemplates.length>9 ? 'custom-color-btn-disabled' : 'custom-color-btn'
                        )}
                        variant="primary"
                        onClick={onSaveTemplate}
                        ref={saveTemplateButtonRef}
                        disabled={reportTemplates.length>9}
                    >
                        <i className="fas fa-save"> </i> {t('reports__save-as-template')}
                    </Button>
                </div>
                <Button
                    className="ml-3 custom-color-btn" variant="primary"
                    onClick={()=> setShowApplyTemplate(!showApplyTemplate)}
                    ref={applyTemplateButtonRef}
                >
                    <i className="fas fa-filter"> </i> {t('common__apply')}
                </Button>
            </div>

            <Tooltip
                tooltipContentRef={saveTemplateRef}
                show={showSaveTemplate}
                placement={'bottom'}
                target={saveTemplateButtonRef}
            >
                {saveNewTemplate}
            </Tooltip>

            <Tooltip
                tooltipContentRef={applyTemplateRef}
                show={showApplyTemplate}
                placement={'bottom'}
                target={applyTemplateButtonRef}
            >
                {applyTemplate}
            </Tooltip>

            { /** Confirmation Modal for updating template */ }
            <Modal
                backdrop="static"
                show={showConfirmationModal}
                actions={[{
                    children: t('common__cancel'),
                    onClick: () => {
                        setShowConfirmationModal(false);
                        setTemplateInfo({});
                        setShowSaveTemplate(true);
                    }
                }, {
                    children: t('common__save'),
                    variants: ['submit'],
                    onClick: () => {
                        setShowConfirmationModal(false);
                        updateTemplate();
                    }
                }]}
                dialogClassName={classNames('confirm-popup')}
            >
                { t('report-template__confirm-update-template') }
            </Modal>
            { /** Confirmation Modal for delete temeplate when VERSION MISMATCH on saved template and current report */ }
            <Modal
                backdrop="static"
                show={showVersionMismatchModal}
                actions={[{
                    children: t('common__cancel'),
                    onClick: () => {
                        setShowVersionMismatchModal(false);
                        setTemplateUIDtoDelete();
                    }
                }, {
                    children: t('common__delete'),
                    variants: ['submit'],
                    onClick: () => {
                        setShowVersionMismatchModal(false);
                        if (templateUIDtoDelete) {
                            handleDelete(templateUIDtoDelete);
                            setTemplateUIDtoDelete();
                        } else {
                            console.log('nothing to delete');
                        }
                    }
                }]}
                dialogClassName={classNames('confirm-popup')}
            >
                { t('report-template__confirm-template-version') }
            </Modal>
        </>
    );
};
