import React from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsList } from 'library/NotificationsList';
import { Header } from '../Common/Header';
import { List } from '../Common/List';
import { useDistributorUsers } from './hooks/useDistributorUsers';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';

import { TileList } from 'library/TileList';
import { MOBILE_USERS_HEADERS } from '../../constants/mobileUsersHeders';
import { MOBILE_USERS_SORT_OPTIONS } from '../../constants/mobileUsersSortOptions';

import '../Common/UsersList.scss';
import './DistributorUsers.scss';

export const DistributorUsers = () => {
    const { t } = useTranslation();

    const state = useDistributorUsers();
    const { onCancelRemoveUser, onConfirmRemoveUser } = state;

    return (
        <div className="hme-users hme-users--distributor-account">
            <NotificationsList />
            <Header {...state} />
            <List {...state} />
            <TileList
                {...state}
                sortOptions={MOBILE_USERS_SORT_OPTIONS}
                noRecordsMessage="users__no-users-found"
                rowKey="User_UID"
                sortButtonName="common__sort"
                headers={MOBILE_USERS_HEADERS}
            />
            <ConfirmPopupComponentGeneric
                show={state.isConfirmModalOpen}
                title={t('common__double-checking')}
                message={t('add-user__confirm--remove')}
                actions={[{
                    children: t('common__popup--cancel-action'),
                    onClick: onCancelRemoveUser
                }, {
                    children: t('common__popup--confirm-action'),
                    variants: ['submit'],
                    className: 'hme-users--distributor-account--confirm-button',
                    onClick: onConfirmRemoveUser
                }]}
            />
        </div>
    );
};
