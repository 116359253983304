export const CONTEST_STATUS_NAMES = {
    SCHEDULED: 'SCHEDULED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED'
};

export const CONTEST_STATUSES = {
    0: CONTEST_STATUS_NAMES.SCHEDULED,
    1: CONTEST_STATUS_NAMES.IN_PROGRESS,
    2: CONTEST_STATUS_NAMES.COMPLETED,
    3: CONTEST_STATUS_NAMES.CANCELED
};

export const CONTEST_TYPE_VALUES = {
    [CONTEST_STATUS_NAMES.SCHEDULED]: 0,
    [CONTEST_STATUS_NAMES.IN_PROGRESS]: 1,
    [CONTEST_STATUS_NAMES.COMPLETED]: 2,
    [CONTEST_STATUS_NAMES.CANCELED]: 3
};

export const CONTEST_STATUS_TRANSLATION_KEYS = {
    [CONTEST_STATUS_NAMES.SCHEDULED]: 'contest__status--scheduled',
    [CONTEST_STATUS_NAMES.IN_PROGRESS]: 'common__task__status--in-progress',
    [CONTEST_STATUS_NAMES.COMPLETED]: 'contest__status--completed',
    [CONTEST_STATUS_NAMES.CANCELED]: 'contest__status--canceled'
};

export const CONTEST_STATUSES_ROW_CLASS_NAMES = {
    [CONTEST_STATUS_NAMES.SCHEDULED]: 'hme-contests__row',
    [CONTEST_STATUS_NAMES.IN_PROGRESS]: 'hme-contests__row hme-contests__row--in-progress',
    [CONTEST_STATUS_NAMES.COMPLETED]: 'hme-contests__row',
    [CONTEST_STATUS_NAMES.CANCELED]: 'hme-contests__row'
};

export const CONTEST_TYPES_AVAILABLE = [
    CONTEST_TYPE_VALUES.IN_PROGRESS,
    CONTEST_TYPE_VALUES.SCHEDULED,
    CONTEST_TYPE_VALUES.COMPLETED
];
