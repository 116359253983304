/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { compose } from 'ramda';
import classNames from "classnames";

import { CustomerNotificationBanner } from 'library/CustomerNotificationBanner';
import { CenterLoader } from 'components/Common/CenterLoader';

import "./WelcomeDynamic.css";
import { Config } from "../../Config";
import "react-confirm-alert/src/react-confirm-alert.css";
import "url-search-params-polyfill";
import AuthenticationService from "../Security/AuthenticationService";
import { setUserEmail } from "../../actions/headers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DynamicHomeScreen from "./DynamicHomeScreen";
import { CustomerNotificationProvider } from "library/CustomerNotificationBanner/context";

const accountImage = require("../../images/home_account.png");
const accountImageAdmin = require("../../images/home_accountAdmin.png");

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerMessages: {},
      bannerSubmessages: [],
      isLoading: true
    };
    this.authService = new AuthenticationService(Config.authBaseUrl);

    this.handleStartLoading = this.handleStartLoading.bind(this);
    this.handleEndLoading = this.handleEndLoading.bind(this);
  }

  UNSAFE_componentWillMount() {
    let contextUser = this.authService.getProfile();
    this.setState({ contextUser: contextUser });
  }

  handleStartLoading() {
    this.setState({ isLoading: true });
  }

  handleEndLoading() {
    this.setState({ isLoading: false });
  }

  render() {
      this.state.contextUser = this.authService.getProfile();

      return (
        <React.Fragment>
          <CustomerNotificationProvider
            onStartLoading={this.handleStartLoading}
            onEndLoading={this.handleEndLoading}
          >
            <div className=''>
            </div>

            {this.state.isLoading ? <CenterLoader /> : (
              <div className={classNames({
                'welcome--hidden': this.state.isLoading 
              })}>
                  <CustomerNotificationBanner />
                  <div>
                    <DynamicHomeScreen />
                  </div>
              </div>
            )}
        </CustomerNotificationProvider>
      </React.Fragment>
    );
  }
  renderAdminAccountsImage() {
    if (!this.authService.isAdmin()) {
      return <img className="homePageImages" src={accountImage} alt="Accounts" />;
    } else {
      return <img className="homePageImages" src={accountImageAdmin} alt="Admin Accounts" />;
    }
  }
}
function mapStateToProps(state) {
  return {
    headers: state.headers,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserEmail: setUserEmail,
    },
    dispatch,
  );
}
export default compose(
  connect(
    mapStateToProps,
    matchDispatchToProps,
  )
)(User);
