/* eslint-disable camelcase */
import React from 'react';
import { Section } from 'library/Section';
import { ProviderField, ProviderForm } from './ProviderSection/ProviderForm';
import { useTranslation } from 'react-i18next';

import './DetailsSection.scss';

export const DetailsSection = ({ formValues, onProviderChange }) => {
    const { t } = useTranslation();
    const { apiType } = formValues;
    return (
        <Section
            className="api-management-form-details"
            number={3}
            col={6}
            showNumber={false}
            title={t(`api-token-management__section__title--${apiType}--detail`)}
        >
            <ProviderField
                formValues={formValues}
                onProviderChange={onProviderChange}
            />
        </Section>
    );
};

DetailsSection.propTypes = ProviderForm.propTypes;
