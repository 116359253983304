import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { commonErrorTranslations } from 'constants/errors';
import { getDataAsync, postDataAsync } from 'Api';

const { apiUrls } = CommonConstants;

const ANNOUNCEMENT_SETTINGS_URL = `${Config.apiBaseUrl}${apiUrls.announcementSettings}`;

export const fetchAnnouncementSettings = async () => {
    try {
        const response = await getDataAsync(ANNOUNCEMENT_SETTINGS_URL);

        if (!response.success) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : 'common__error--internal-server'
        );
    }
};

export const postAnnouncementSettings = async (data) => {
    try {
        const response = await postDataAsync(ANNOUNCEMENT_SETTINGS_URL, data);

        if (!response.success) {
            throw new Error(response.key);
        }
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : 'common__error--internal-server'
        );
    }
};
