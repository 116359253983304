import React from 'react';

import { SearchInput } from 'components/Inputs';
import { GridFiltersCount } from 'library/GridFiltersCount';

import './Header.scss';

export const Header = ({
    count,
    searchValue,
    onSearchChange,
    filtersCount,
    onFiltersReset,
}) => (
    <div className='hme-view-store-settings-task-uploaded-devices-header'>
        <div className='hme-view-store-settings-task-uploaded-devices-header-count'>
            <span className='hme-view-store-settings-task-uploaded-devices-header-count-value'>
                {count}
            </span>
            {count === 1 ? ' device' : ' devices'} uploaded
        </div>
        <div className='hme-view-store-settings-task-uploaded-devices-reset-filters'>
            <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} variants={['single-line']} />
        </div>
        <div className='hme-view-store-settings-task-uploaded-devices-header-search'>
            <SearchInput
                value={searchValue}
                placeholder='Search for Device'
                onChange={onSearchChange}
            />
        </div>
    </div>
);
