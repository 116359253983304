import { TYPES } from 'constants/userNotifications';
import { Empty } from 'library/Empty';

import { LeaderboardInvite } from './LeaderboardInvite';
import { LeaderboardAccepted } from './LeaderboardAccepted';
import { LeaderboardDeclined } from './LeaderboardDeclined';

const components = {
    [TYPES.LEADERBOARD_INVITED]: LeaderboardInvite,
    [TYPES.LEADERBOARD_ACCEPTED]: LeaderboardAccepted,
    [TYPES.LEADERBOARD_DECLINED]: LeaderboardDeclined,
}

export const Notification = (props) => {
    const Component = components[props.notification.type] || Empty;

    return (
        <Component {...props} />
    );
};
