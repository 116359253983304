import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './EmailLink.scss';

export const EmailLink = ({ onReportSend, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-admin-systems-email-link">
            <strong>{t('admin__accounts__header__email-links--email-me')}</strong>
            <div>
                <Button disabled={isDisabled} variants={['transparent']} onClick={() => onReportSend()}>
                    {t('admin__systems__unregistered-systems-report')}
                </Button>
            </div>
        </div>
    );
};
