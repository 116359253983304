import { compose } from 'ramda';
import { withAccess } from 'HOCs/withAccess';
import { isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { CommonConstants } from 'Constants';
import { APIManagementForm } from '../APIManagementForm';


const hasAccess = isAdmin() && checkAccess(CommonConstants.adminPermissions.CreateVaioProvider);

export const AdminAPIManagementForm = compose(
        authenticate,
        withAccess({
            checkAccess: () => hasAccess,
        }),
)(APIManagementForm);
