import React, { useState, useEffect  } from 'react';
import classNames from 'classnames';

import { GridHeader } from './GridHeader';
import { GridBody } from './GridBody';
import { CHECKBOX_VALUES } from '../../../library/Checkbox';

// Detect that we need to show additional titles block
const hasTitles = headers => headers.some(({ property }) => Array.isArray(property))

// Prepare titles data
const getTitles = headers => hasTitles(headers) ?
    headers.map(({ text, property, flex }) => Array.isArray(property) ? {
            text,
            flex: property.reduce((sum, { flex = 1 }) => sum + flex, 0),
        } : {
            text: '',
            flex: flex || 1,
        }) :
    [];

// Find headers in case if we have titles
const getHeaders = headers => hasTitles(headers) ?
    headers.map(({ property }) => property)
        .map(cellInfo => !Array.isArray(cellInfo) ? [cellInfo] : cellInfo)
        .map(cellInfo => {
            cellInfo[cellInfo.length - 1].lastInBlock = true;
            return cellInfo;
        }).reduce((val, cellInfo) => {
            val.push(...cellInfo);
            return val;
        }, []) :
    headers;

const getHeaderCheckboxValue = (selection, rows, rowKey) => {
    const selectableRows = rows.filter(({ isSelectable = true }) => isSelectable);

    let selected = [];

    if (rowKey) {
        const selectionRowKeyValues = selection.map(s => s[rowKey]);
        selected = selectableRows.filter(row => selectionRowKeyValues.includes(row[rowKey]));
    } else {
        selected = selectableRows.filter(row => selection.includes(row));
    }

    if (selected.length === 0) {
        return CHECKBOX_VALUES.UNCHECKED;
    }

    if (selected.length === selectableRows.length) {
        return CHECKBOX_VALUES.CHECKED;
    }

    return CHECKBOX_VALUES.PARTIAL;
}

/**
 * Grid component
 * rows - original data that should be displayed. Additional properties for each row:
 *      rowClassName - class name that will be added to that row
 *      getCellClassName - function that will be called for each cell in the row.
 *          Function's arguments:
 *              property - property from header
 *              rowSection - current data for row. For expanded row we will have values for 
 *                  current row only
 *              rowData - full original row data
 *      isSelectable - bool. If true - checkbox is enabled and disabled in other case.
 *          By default - true.
 * 
 * headers - headers array info:
 *      text - Name of a header. If property is an array it will be used as name of title
 *      className - Name of class that will be added to body cell
 *      flex - Will be added as flex to cell. By default is 1
 *      property - property info.
 *          If property is a string - it is used as path to get data.
 *          If property is an array it is used as array of headers.
 *          Can be only 1 dimension array so please don't use nested array in property
 *      headerClassName: className that will be added to header cell
 *      sortable - column can be sortable
 * selectable - Each row can be selected
 * expandable - Each row with array data can be expandable
 * ExpandComponent - Component that can get current row data as `data` prop and
 *      renders expanded content.
 *      That content will be rendered in .hme-grid-expanded-row .hme-grid-expanded-content selector.
 * rowKey - key name that can be used for each row as key
 * selection - already selected rows
 * availableFilters - array of objects with properties:
 *      property - header property value
 *      allText - text for all items selected option
 *      items - array of objects with properties:
 *          text - displayed item text
 *          value - selected item value
 * filters - object of key/value pairs:
 *      key - header property
 *      value - array of selected values
 * onSelectionChange - Handler when a row is selected
 * isLoading - Show grid loading state
 * loadingText - Message that will be shown for searching
 * noRecordsMessage - Message that should be displayed if rows are empty
 * onRowClick - handler of click on row
 * onFiltersChange - handle filters changing
 * sortable - Rows can be sorted by the column
 * onSortChange - change sort handler
 * sortSelection - current sort selection, e.g.: { property: value },
 * sortDirections - sort change loop steps, if not specified: default = [0, 1 ,-1], where 0 -not sorted, 1 -asc sorted, -1 -desc sorted
 * 
 * Example of headers:
 * [{
 *      text: 'Title 1',
 *      property: [{
 *          text: 'Header 1',
 *          property: 'text1',
 *      }, {
 *          text: 'Header 2',
 *          property: 'someObject.nestedProperty',
 *      }]
 * }, {
 *      text: 'Title 2',
 *      property: [{
 *          text: 'Header 3',
 *          property: 'someArray.nestedProperty',
 *      }]
 * }]
 *
 * It will generate the next header:
 * Title 1                  | Tiltle 2
 * Header 1     Header 2    | Header 3
 *
 * Example of expandable grid:
 * expandable = true
 * ExpandableComponent = ({ data }) => (
 *      <span>Items length: {data.nestedArray.length}</span>
 * )
 *
 * Will render:
 * 1. Collapsed state:
 *   | Header 1 | Header 2 | Header 3
 * > | Val 1 1  | Val 2 1  | Val 3 1
 * > | Val 1 2  | Val 2 2  | Val 3 2
 * > | Val 1 3  | Val 2 3  | Val 3 3
 *
 * 2. Expanded state:
 *   | Header 1 | Header 2 | Header 3
 * v | Val 1 1  | Val 2 1  | Val 3 1
 *   | Items length: 3
 * > | Val 1 2  | Val 2 2  | Val 3 2
 * v | Val 1 3  | Val 2 3  | Val 3 3
 *   | Items length: 2
 *
 * Known issues:
 * 1. When Grid is selectable and expandable: Checkbox will be shown for each expandable row.
 *      We will fix it when there will be an example of how it should work.
*/

const defaultHeaders = [];

export const Grid = ({
    rows,
    headers = defaultHeaders,
    selectable = false,
    expandable = false,
    isAllExpanded = false,
    ExpandComponent,
    onSortChange,
    sortSelection,
    rowKey = null,
    selection = [],
    availableFilters,
    filters,
    className,
    onFiltersChange,
    onSelectionChange,
    noRecordsMessage = 'No data is found',
    isLoading = false,
    loadingText,
    onRowClick,
    onExpandChange,
    showOverflow,
    checkSelected,
    checkDisabled,
}) => {
    const [titles, setTitles] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        setTitles(getTitles(headers));
    }, [headers, setTitles]);

    useEffect(() => {
        // We can have titles so here we prepare headers list
        setTableHeaders(getHeaders(headers));
    }, [headers, setTableHeaders]);

    useEffect(() => {
        setIsAllSelected(getHeaderCheckboxValue(selection, rows, rowKey));
    }, [selection, rows, rowKey]);

    const onItemSelectionChange = onSelectionChange && ((selectedItem, selected) => {
        let newSelection;

        if (selected) {
            newSelection = [...selection];
            newSelection.push(selectedItem);
        } else {
            // choose only objects that are not selected
            newSelection = rowKey
                ? selection.filter((item) => item[rowKey] !== selectedItem[rowKey]) 
                : selection.filter((item) => item !== selectedItem)
        }

        onSelectionChange(newSelection);
    });

    const onAllSelectedChange = onSelectionChange && (selectedAll => {
        let newSelection;

        if (selectedAll) {
            newSelection = [...selection];

            let filterSelected;
            if (rowKey) {
                const selectionRowKeyValues = selection.map((s) => s[rowKey]);
                filterSelected = (row) => !selectionRowKeyValues.includes(row[rowKey]);
            } else {
                filterSelected = (row) => !selection.includes(row);
            }

            rows.filter(({ isSelectable = true }) => isSelectable)
                .filter(filterSelected)
                .forEach(row => newSelection.push(row));
        } else {
            // Here I don't want to use empty array
            // because selection can contain rows that are not displayed now
            if (rowKey) {
                const rowKeyValues = rows.map((row) => row[rowKey]);
                newSelection = selection.filter((selectedRow) => !rowKeyValues.includes(selectedRow[rowKey]));
            } else {
                newSelection = selection.filter(selectedRow => !rows.includes(selectedRow));
            }
        }

        onSelectionChange(newSelection);
    });

    return (
        <div className={classNames("hme-grid", className)}>
            <GridHeader
                headers={tableHeaders}
                titles={titles}
                selectable={selectable}
                expandable={expandable}
                availableFilters={availableFilters}
                filters={filters}
                onFiltersChange={onFiltersChange}
                isAllSelected={isAllSelected}
                onAllSelectedChange={onAllSelectedChange}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
            />
            <GridBody
                rows={rows}
                selectable={selectable}
                headers={tableHeaders}
                ExpandComponent={ExpandComponent}
                rowKey={rowKey}
                selection={selection}
                onItemSelectionChange={onItemSelectionChange}
                expandable={expandable}
                isAllExpanded={isAllExpanded}
                noRecordsMessage={noRecordsMessage}
                isLoading={isLoading}
                loadingText={loadingText}
                onRowClick={onRowClick}
                onExpandChange={onExpandChange}
                showOverflow={showOverflow}
                checkSelected={checkSelected}
                checkDisabled={checkDisabled}
            />
        </div>
    );
};
