import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import LaneTransactions from '../components/Dashboard/LaneTransactions'
import LaneTotalAvgTime from '../components/Dashboard/LaneTotalAvgTime'
import { translationKeysMap } from '../components/Dashboard/translationsMap';
import DialGauge from '../components/Dashboard/DialGauge'
import '../components/Dashboard/storeRanking.css'
import { Row, Col } from 'reactstrap'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'url-search-params-polyfill'
import { getStoreRanking, setDashBoardParams, getDashboardData } from '../actions/storeRanking'
import CommonLoader from '../components/Alerts/CommonLoader'
import arrowDesc from '../images/arrow_DESC.png'
import { Config } from '../Config'
import { SimpleToolTip } from '../components/Common/SimpleTooltip'
import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';
import { DateLib } from '@hme-cloud/utility-common';
const _ = require('underscore');

class StoreRankings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tabular: this.props.gaugeDetails.dashboardData,
      tableType: null,
      enableAutoRefresh: false,
      autoRefresh: false,
      showToolTip: false,
      showLoader: true,
      eventType: '2000'
    }
  }
  UNSAFE_componentWillMount () {
    const { queryParams, location } = this.props;

    const ps = queryParams.get('ps');
    const report = queryParams.get('report');
    const suid = queryParams.get('suid');
    const metrics = queryParams.get('metrics') ? queryParams.get('metrics') : this.state.eventType;
    // Getting the DashboardMetric from the localstorage.
    const DashboardMetric = window.localStorage.getItem('DashboardMetric');
    const eventType = DashboardMetric || metrics;

    this.setState({ eventType })
    let dashboardParams = { ps: ps,
      report: report,
      suid: suid,
      eventType: metrics }
    this.props.setDashBoardParams(dashboardParams)
    this.props.getStoreRanking(location.search, () => {
      self.setState({ showLoader: false })
    })
    let self = this
    this.props.getDashboardData(() => {
      // setTimeout(function () {
      //    self.setState({showLoader: false})
      // }, 100)
    })
  }
  // Maintaining the DashboardMetric in localstorage.
  setDashBoardMetric (metrics) {
    window.localStorage.setItem('DashboardMetric', metrics)
  }

  isIE (userAgent) {
    userAgent = userAgent || navigator.userAgent
    return (userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident') > -1)
  }
  enableAutoRefresh () {
    const { location } = this.props;
    let self = this
    let interval = setInterval(function () {
      self.setState({ showLoader: true })
      self.props.getStoreRanking(location.search, () => {
        self.setState({ showLoader: false })
      })
      self.props.getDashboardData(function () {
      })
    }, Config.autoRefreshTime)
    this.setState({ interval, autoRefresh: true })
  }

  disableAutoRefresh () {
    clearInterval(this.state.interval)
    this.setState({ autoRefresh: false })
  }

  handleOnChange (e) {
    const { location } = this.props;
    this.setState({
      [e.target.name]: e.target.value,
      showLoader: true
    })
    let dashboardParams = { ps: this.props.dashboardParams.ps,
      report: this.props.dashboardParams.report,
      suid: this.props.dashboardParams.suid,
      eventType: e.target.value }
    this.props.setDashBoardParams(dashboardParams)
    this.setDashBoardMetric(e.target.value)
    let self = this
    this.props.getStoreRanking(location.search, () => {
      self.setState({ showLoader: false })
    })
    this.props.getDashboardData(function () {
    })
  }

  renderStore () {
    if (this.props.ranking.dashboardData) {
      if (this.props.ranking.dashboardData.length > 0) {
        let data = this.props.ranking.dashboardData.length > 0 ? this.props.ranking.dashboardData[0].rows : []
        let store = _.where(data, { storeUID: this.props.dashboardParams.suid })
        return store[0].storeName ? store[0].storeName : store[0].storeNumber
      }
    }
  }
  render () {
    const { t, i18n } = this.props;
    const showCustom = this.props.userPreferences ? this.props.userPreferences.customHeaders ? true : false : false
    const currentStartDate = this.props.currentDayData && this.props.currentDayData.CurrentStartDate ? this.props.currentDayData.CurrentStartDate : '';
    const  currentEndDate = this.props.currentDayData && this.props.currentDayData.CurrentEndDate ? this.props.currentDayData.CurrentEndDate : '';
    let startDate = '';
    const endDate = currentEndDate ? new DateLib(currentEndDate).format(DateLib.FORMAT_TYPES.MONTH_DATE) : '';
      if (this.props.dashboardParams.report === 'week' || this.props.dashboardParams.report === 'month' || (this.props.dashboardParams.report === 'custom' && currentStartDate !== currentEndDate)) {
        startDate = currentStartDate ? new DateLib(currentStartDate).toUTC().format(DateLib.FORMAT_TYPES.MONTH_DATE) : '';
      } else {
        startDate = currentStartDate ? new DateLib(currentStartDate).format(DateLib.FORMAT_TYPES.MONTH_DATE) : '';
      }
    if (this.props.dashboardParams) {
      return (<section className='chart'>
        <div className={this.state.showLoader ? 'show' : 'hide'}>
          <CommonLoader showLoader={this.state.showLoader} message="dashboard__loading-please-wait" isDashboard />
        </div>
        <Row className={!this.state.showLoader ? 'show' : 'hide'}>
          <Col md='12' className='storeFlexBox'>
            <section>
              <h3><span>{t('common__store')}</span> {this.renderStore()}</h3>
              <div className='fright'>
                <form className='metrics-select pull-right'>
                  <label translate='' key='Metrics'>{t('common__metrics')}</label>
                  <select name='eventType' required='no' value={this.state.eventType} onChange={this.handleOnChange.bind(this)}>
                    <option value='2000' selected={this.state.eventType === '2000' ? 'selected' : ''}>{t('common__lane-total')}</option>
                    <option value='2001' selected={this.state.eventType === '2001' ? 'selected' : ''}>{t('common__lane-total-2')}</option>
                  </select>
                </form>
              </div>
              <div className='dbar clear'>
                <Row className='storeRankingHeaders'>
                  <Col lg='12' className='storeListingHeading'>
                    <div>
                      <ul className='rep_list'>
                        <li className={this.props.dashboardParams.report === 'daypart' ? 'SelectedTimeMeasure' : ''}>
                          <a href={'/dashboard/storeRanking?suid=' + this.props.dashboardParams.suid + '&report=daypart' + '&metrics=' + this.state.eventType} name='daypart'> <span>{t('common__daypart')}</span></a> | </li>
                        <li className={this.props.dashboardParams.report === 'day' ? 'SelectedTimeMeasure' : ''}>
                          <a href={'/dashboard/storeRanking?suid=' + this.props.dashboardParams.suid + '&report=day' + '&metrics=' + this.state.eventType} name='day'><span>{t('common__day')}</span></a> | </li>
                        <li className={this.props.dashboardParams.report === 'week' ? 'SelectedTimeMeasure' : ''}>
                          <a href={'/dashboard/storeRanking?suid=' + this.props.dashboardParams.suid + '&report=week' + '&metrics=' + this.state.eventType} name='week'><span>{t('common__week')}</span></a> | </li>
                        <li className={this.props.dashboardParams.report === 'month' ? 'SelectedTimeMeasure' : ''}>
                          <a href={'/dashboard/storeRanking?suid=' + this.props.dashboardParams.suid + '&report=month' + '&metrics=' + this.state.eventType} name='month'><span>{t('common__month')}</span></a> | </li>
                        <li className={(this.props.dashboardParams.report === 'custom' ? 'SelectedTimeMeasure ' : ' ') + (showCustom ? ' ' : 'hidden')}>
                          <a href={'/dashboard/storeRanking?suid=' + this.props.dashboardParams.suid + '&report=custom' + '&metrics=' + this.state.eventType} name='custom'><span>{t('common__custom')}</span></a> | </li>
                      </ul>
                    </div>
                    <div className='storeRankingDate'>   {startDate} - {endDate}</div>
                  </Col>
                  <Col lg='12' className='storeListingHeading'>
                    <div>
                      <ul className='rep_list'>
                        <li><a href={'/dashboard/customizedashboard'}>{t('dashboard__customize-dashboard')}</a></li>
                      </ul>
                    </div>
                    <span>
                      <a className={(this.state.autoRefresh ? 'hidden' : '')} onClick={this.enableAutoRefresh.bind(this)}>{t('dashboard__enable-auto-refresh')}</a>
                      <a className={(!this.state.autoRefresh ? 'hidden' : '')} onClick={this.disableAutoRefresh.bind(this)}>{t('dashboard__disable-auto-refresh')}</a>
                    </span>
                  </Col>
                </Row>
              </div>
              <div className='zeroMargin tabular'>
                <div lg='12' className='StoreRankingHeading'>
                  {t('dashboard__store-ranking-by-lane')}
                </div>
                {this.renderTopStores()}
                {this.renderCenterStores()}
                {this.renderBottomStores()}
              </div>
              {this.renderTableLegends()}
            </section>
            <section>
              <header className='chartHeading'> {t('dashboard__lane-transactions')} </header>
              {this.renderLaneTransactions()}
            </section>
            <section className='lanetotalavgtimeChart'>
              <header className='chartHeading'> {t('dashboard__lane-total-avg-time')} </header>
              {this.renderLaneTotalAvgTime()}
            </section>
          </Col>
          <Col lg='4' md='12' xl='4' className='gaugeSide'>
            <h3 className={(this.props.gaugeDetails.laneConfigId ? (this.props.gaugeDetails.laneConfigId[0].Device_LaneConfig_ID === 1 ? 'dailGaugeHeadingSingle' : 'dailGaugeHeadingDual') : '')} ><span translate=''>{t('common__average-current')}</span> <span>{t(`common__${this.props.dashboardParams.report}`)}</span> <span>{t('common__status')}</span></h3>
            {this.renderGauge()}
          </Col>
        </Row>
      </section>
      )
    } else {
      return (<section />
      )
    }
  }

  convertTime (seconds) {
    return new DateLib(seconds * 1000).toUTC().format(DateLib.FORMAT_TYPES.MINUTES_SECONDS);
  }
  renderTopStores () {
    if (this.props.ranking.tables) {
      if (this.props.ranking.tables.topStores.length > 0) {
        return (<div className='StoreRankingBody'>
          <Row className='zeroMargin'>
            {this.renderTable('top')}
          </Row>
        </div>)
      } else {
        return (<div />)
      }
    }
  }

  renderCenterStores () {
    if (this.props.ranking.tables) {
      if (this.props.ranking.tables.centerStores.length > 0) {
        return (<div className='StoreRankingBody'>
          <Row className='zeroMargin'>
            {this.renderTable('center')}
          </Row>
        </div>)
      } else {
        return (<div />)
      }
    }
  }

  renderBottomStores () {
    if (this.props.ranking.tables) {
      if (this.props.ranking.tables.bottomStores.length > 0) {
        return (<div className='StoreRankingBody'>
          <Row className='zeroMargin'>
            {this.renderTable('bottom')}
          </Row>
        </div>)
      } else {
        return (<div />)
      }
    }
  }

  renderTable (type) {
    const { t } = this.props;
    let tableType = type;
    if (this.props.dashboardData) {
      let tables = this.props.ranking.dashboardData
      let self = this
      let tableData = tables.map(function (table, index) {
        let tableIndex = index

        const storeTitle = self.renderStore();

        // Only for custom report.
        if ((table.title === 'Stores' || table.title.includes(storeTitle))  && self.props.dashboardParams.report === 'custom' && self.props.ranking.tables.topStores.length < 6 && self.props.ranking.tables.bottomStores.length === 0 && self.props.ranking.tables.centerStores.length === 0) {
          table.title = t('common__store') + ' ' + storeTitle;
        }

        return (
          <Col lg={tables.length > 3 ? '6' : (tables.length === 3 ? '4' : '6')} className='border zeroPadding'>
            <div className={'StoreRankingSubHeader zeroPadding rankingTableBorder ' + (type === 'top' && index === 1 && self.props.dashboardParams.report !== 'custom' ? 'highlight' : '')}>
              { type === 'top' && table.title === 'Stores' && table.rows.length > 5 ? t('dashboard__top-3-stores')
                : type === 'bottom' && table.title === 'Stores' ? t('dashboard__bottom-3-stores')
                  : type === 'center' && table.title === 'Stores' ? t('common__your-ranking')
                    : type === 'top' && table.title === 'Stores' ? t('common__stores')
                      : type === 'center' && table.title === 'Stores' ? t('common__store') + ' ' + self.renderStore()
                        : type === 'bottom' && table.title === 'Stores' ? t('dashboard__bottom-3-stores')
                        : t(translationKeysMap[table.title] || table.title)}
            </div>
            <table className='chartTable'>
              <thead>
                <tr className='highlight'>
                  {self.renderHeaders(index, tableType, tableIndex)}
                </tr>
              </thead>
              {self.renderStoreRankingRows(index, tableType, tableIndex)}
            </table>
          </Col>
        )
      })
      return tableData
    }
  }
  renderTableLegends () {
    const { t } = this.props;
    if (this.props.gaugeDetails.laneTransactions) {
      let colors = this.props.gaugeDetails.laneTransactions.goalColors
      return (
        <div className='cmiddle tableColors'>
          <div className='blabel'>
            <span><div className='dash_circle' style={{ backgroundColor: colors[0] }} /></span>
            <span className='dash_goal_pad'>&lt;<span> {t('common__goal')}</span> A</span>
          </div>
          <div className='blabel'><span><div className='dash_circle' style={{ backgroundColor: colors[1] }} /></span><span className='dash_goal_pad'>&lt; <span> {t('common__goal')}</span> B</span></div>
          <div className='blabel'><span><div className='dash_circle' style={{ backgroundColor: colors[2] }} /></span><span className='dash_goal_pad'>&gt;= <span> {t('common__goal')}</span> B</span></div>
        </div>)
    } else {
      return (<div />)
    }
  }

  formatTranslatedLaneInfo(tableData) {
      const { t, i18n } = this.props;
      const translatedRows = tableData.rows.map(row => (row.map(element => i18n.exists(element.toString()) ? t(element) : element)));

      // translate labels by translationMap
      const translatedColumns = tableData.columns.map(column => {
        const translationEl = tableData.translationMap && tableData.translationMap[column.label];

        return translationEl ? {
          ...column,
          label: i18n.exists(translationEl.tKey) ? t(translationEl.tKey, { value: translationEl.value}) : column.label
        } : {
          ...column,
          label: i18n.exists(column.label) ? t(column.label) : column.label
        };
      });

      return {
        translatedRows,
        translatedColumns
      }
  }

  renderLaneTransactions () {
    const { gaugeDetails } = this.props;

    if (gaugeDetails.laneTransactions) {
      const { translatedRows, translatedColumns } = this.formatTranslatedLaneInfo(gaugeDetails.laneTransactions);

      return (<LaneTransactions rows={translatedRows} columns={translatedColumns} goalColors={this.props.gaugeDetails.laneTransactions.goalColors} />)
    } else {
      return (<div />)
    }
  }
  renderLaneTotalAvgTime () {
    const { gaugeDetails } = this.props;

    if (gaugeDetails.laneTotalAvgTime) {
      const { translatedRows, translatedColumns } = this.formatTranslatedLaneInfo(gaugeDetails.laneTotalAvgTime);

      return (<LaneTotalAvgTime
        rows={translatedRows}
        columns={translatedColumns}
        goalColors={gaugeDetails.laneTotalAvgTime.goalColors}
        events={gaugeDetails.laneTotalAvgTime.events} />)
    } else {
      return (<div />)
    }
  }
  renderGauge () {
    let self = this
    let MultiLane = false
    let Count = 0
    let YLane = false
    let isLast = false
    // let isFirst = false
    if (this.props.gaugeDetails.gaugeDetails) {
      let storeRows = this.props.gaugeDetails.gaugeDetails.map(function (gauge, index) {
        let nextSingle = false
        // let previousDual = false
        if (index < (self.props.gaugeDetails.gaugeDetails.length - 1)) {
          if (self.props.gaugeDetails.gaugeDetails[index + 1].data.length === 1) {
            nextSingle = true
          }
        }
        if (index === (self.props.gaugeDetails.gaugeDetails.length - 1)) {
          isLast = true
          // self.setGauges()
        }
        if (gauge.data.length > 1) {
          MultiLane = true
          return (
            <section>
              <div className='dualGauge'>
                {self.renderDualGauge(gauge.data)}
              </div>
              <div className={nextSingle && !isLast ? 'hidden' : ''}>
                {self.renderYLaneSM()}
              </div>
            </section>
          )
        } else if (gauge.data.length === 1) {
          if (MultiLane && Count === 0) {
            YLane = true
            Count++
          } else {
            YLane = false
          }
          MultiLane = false
          return (
            <section>
              {self.renderYLaneFirstBottom(YLane)}
              <div className={'singleGauge ' + (self.props.gaugeDetails.laneConfigId[0].Device_LaneConfig_ID === 1 ? 'lane1' : 'dualGauge')}>
                {self.renderSingleGauge(gauge.data)}
              </div>
              <div className={isLast ? 'hidden' : ''}>
                {self.renderYLaneBottom()}
              </div>
            </section>
          )
        }
      })
      return storeRows
    } else {
      return (<div />)
    }
  }

  renderDualGauge (gauge) {
    let self = this.props
    let dailGauge = gauge.map(function (dail, index) {
      return (
        <DialGauge title={dail.title} gaugeData={dail} colors={self.gaugeDetails.colorCode} timeFormat={self.ranking.userPreferences ? self.ranking.userPreferences.timeFormat : 'ss'} />
      )
    })
    return dailGauge
  }
  renderYLaneFirstBottom (YLane) {
    if (!YLane) {
      return (<div />)
    } else {
      return (<div className='lane-cont-mid' />)
    }
  }
  renderYLaneSM () {
    return (
      <div className='lane-cont-top'>
        <div className='lane_top_sec1 lane_top_size' />
      </div>
    )
  }

  renderYLaneBottom () {
    return (
      <div className={(this.props.gaugeDetails.laneConfigId[0].Device_LaneConfig_ID === 1 ? 'lane-cont-bot-lane1 ' + (this.isIE() ? 'singleLaneIE' : '') : 'lane-cont-bot')}>
        <div className='lane_bot_sec2 lane_bot_size ' />
      </div>
    )
  }
  renderSingleGauge (gauge) {
    let self = this.props
    let dailGauge = gauge.map(function (dail, index) {
      return (
        <DialGauge title={dail.title} gaugeData={dail} colors={self.gaugeDetails.colorCode} timeFormat={self.ranking.userPreferences ? self.ranking.userPreferences.timeFormat : 'ss'} />
      )
    })
    return dailGauge
  }

  renderHeaders (index, tableType, tableIndex) {
    const { t } = this.props;
    if (this.props.ranking.dashboardData) {
      let data = this.props.ranking.dashboardData[index]
      let Headers = data.columns
      let self = this
      let dailGauge = Headers.map(function (item, index) {
        const header = item === 'percent' && tableIndex === 0 ? 'goal' : item;
        return (
          <th className={'StoreRankingSubHeading ' + ((item === 'storeName' && self.props.ranking.userPreferences.storeNameColumn === 'Exclude') ? 'hidden ' : '') + ((tableType === 'top' && self.props.ranking.tables.centerStores.length) === 0 && tableIndex === 1 ? 'grayedHeaders' : '') + ((Headers.length === 3 || (self.props.ranking.userPreferences.storeNameColumn === 'Exclude' && Headers.length === 4)) ? ' percent33' : '')}>
            {t(translationKeysMap[header] || header)} {self.renderIcon(index, tableType, tableIndex)}
          </th>
        )
      })
      return dailGauge
    }
  }
  renderStoreRankingRows (index, tableType, tableIndex) {
    if (this.props.ranking.dashboardData) {
      return (<tbody>
        {this.renderTableRows(index, tableType, tableIndex)}
      </tbody>)
    }
  }

  foramtText (text) {
    if (text) {
      if (text.length > 8) {
        let formattedText = text.substring(0, 8) + '...'
        return formattedText
      } else {
        return text
      }
    } else {
      return text
    }
  }

  renderIcon (index, tableType, tableIndex) {
    if (tableIndex === 1 && index === 1 && tableType === 'top' && this.props.dashboardParams.report !== 'custom' && this.props.ranking.tables.topStores.length < 6 && this.props.ranking.tables.bottomStores.length === 0 && this.props.ranking.tables.centerStores.length === 0) {
      return (<img src={arrowDesc} alt='descending' />)
    } else {
      return (<span />)
    }
  }
  renderRow (headers, index, row, tableIndex) {
    let item = row
    let goalA = {}
    let goalB = {}
    let goalGTB = {}
    let colors = this.props.gaugeDetails.laneTransactions ? this.props.gaugeDetails.laneTransactions.goalColors : []
    if (colors.length > 0) {
      goalA = { 'color': colors[0], 'font-weight': 'bold' }
      goalB = { 'color': colors[1], 'font-weight': 'bold' }
      goalGTB = { 'color': colors[2], 'font-weight': 'bold' }
    }
    let self = this;
    const storeRankingUrl = '/dashboard/storeRanking?suid=' + item.storeUID + '&report=' + self.props.dashboardParams.report;
    let tds = headers.map(function (header, index) {
      if (header === 'avg') {
        return (
          <td style={(item[header] < item.GoalA) ? goalA : (item[header] < item.GoalB) ? goalB : (item[header] >= item.GoalB) ? goalGTB : ''}>
            <span> { self.props.ranking.userPreferences ? (self.props.ranking.userPreferences.timeFormat === 'ss' ? item[header] : self.convertTime(item[header])) : item[header]}
            </span>
          </td>
        )
      } else if (header === 'storeNumber') {
        return (
          <td className='storeRankingNumber'>
            <SimpleToolTip className="ranking-tooltip" text={item[header]}>
              <a href={storeRankingUrl}>
                  <span>{item[header]}</span>
              </a>
            </SimpleToolTip>
          </td>
        )
      } else if (header === 'storeName') {
        return (
          <td className={'storeRankingName ' + (self.props.ranking.userPreferences.storeNameColumn === 'Exclude' ? 'hidden' : '')}>
            <SimpleToolTip className="ranking-tooltip" text={item[header]}>
              <a href={storeRankingUrl}>
                  <span>{self.foramtText(item[header])}</span>
              </a>
            </SimpleToolTip>
          </td>
        )
      } else if (header === 'percent' && tableIndex === 0) {
        return (
          <td className=''>
            <span> { self.props.ranking.userPreferences ? (self.props.ranking.userPreferences.timeFormat === 'ss' ? item[header] : self.convertTime(item[header])) : item[header]}
            </span>
          </td>
        )
      } else {
        return (
          <td className=''>
            <span> {item[header]} </span>
          </td>
        )
      }
    })
    return tds
  }
  renderTableRows (index, tableType, tableIndex) {
    if (this.props.ranking.dashboardData) {
      let data = this.props.ranking.dashboardData[index]
      let Headers = data.columns
      let Rows = data.rows
      let self = this
      if (tableType === 'top') {
        let rows = []
        this.props.ranking.tables.topStores.map(function (item, index) {
          let items = _.where(Rows, { storeRank: item.toString() })
          rows.push(items[0])
        })
        Rows = rows
      }
      if (tableType === 'center') {
        let rows = []
        if (this.props.ranking.tables.centerStores) {
          this.props.ranking.tables.centerStores.map(function (item, index) {
            let items = _.where(Rows, { storeRank: item.toString() })
            if (items[0]) { rows.push(items[0]) }
          })
          Rows = rows
        }
      }
      if (tableType === 'bottom') {
        let rows = []
        if (this.props.ranking.tables.bottomStores) {
          this.props.ranking.tables.bottomStores.map(function (item, index) {
            let items = _.where(Rows, { storeRank: item.toString() })
            rows.push(items[0])
          })
          Rows = rows
        }
      }
      let rows = Rows.map(function (rowItem, index) {
        if (rowItem) {
          return (
            <tr className={(self.props.dashboardParams.suid === (rowItem.storeUID ? rowItem.storeUID : null)) && ((tableType === 'center') ? (tableIndex === 0 ? 'selectedRankLeft' : (tableIndex === 1 ? 'selectedRankCenter' : (tableIndex === 2 ? 'selectedRankRight' : (tableIndex === 3 ? 'selectedRankLast' : '')))) : '' || (tableType === 'top' && self.props.ranking.tables.centerStores.length === 0) ? (tableIndex === 0 ? 'selectedRankLeft' : (tableIndex === 1 ? 'selectedRankCenter' : (tableIndex === 2 ? 'selectedRankRight' : (tableIndex === 3 ? 'selectedRankLast' : '')))) : '')} >
              {self.renderRow(Headers, index, rowItem, tableIndex)}
            </tr>
          )
        } else {
          return (
            <div />
          )
        }
      })
      return rows
    }
  }
}

StoreRankings.propTypes = {
    location: PropTypes.object,
    queryParams: PropTypes.object
};

function mapStateToProps (state) {
  return {
    gaugeDetails: state.storeRanking.gaugeDetails,
    dashboardData: state.storeRanking.ranking.dashboardData,
    dashboardParams: state.storeRanking.dashboardParams,
    userPreferences: state.storeRanking.ranking.userPreferences,
    currentDayData: state.storeRanking.ranking.currentDayData,
    ranking: state.storeRanking.ranking
  }
}
function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      getStoreRanking: getStoreRanking,
      setDashBoardParams: setDashBoardParams,
      getDashboardData: getDashboardData
    }, dispatch
  )
}

const StoreRankingsWithTranslation = compose(
        withTranslation(),
        withReactRouter
)(StoreRankings);

export default connect(mapStateToProps, matchDispatchToProps)(StoreRankingsWithTranslation)
