import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputComponent as Input } from 'components/Inputs';

const DEFAULT_INPUT_VARIANTS = ['label-inline', 'label-inside-mobile-only'];

export const Body = ({
    isAllValid,
    isSomeInvalid,
    currentPassword,
    newPassword,
    confirmNewPassword,
    onFieldsChange,
    isPasswordChangeInProgress
}) => {
    const { t } = useTranslation();

    const handleFieldUpdate = useCallback(
        ({ target }) => {
            if (isPasswordChangeInProgress) return;

            const { name, value } = target;

            onFieldsChange({ name, value });
        },
        [onFieldsChange, isPasswordChangeInProgress],
    );

    const newPasswordInputVariants = useMemo(() => {
        const variants = [...DEFAULT_INPUT_VARIANTS];

        if (isAllValid) {
            variants.push('valid');
        }

        if (isSomeInvalid) {
            variants.push('invalid');
        }

        return variants;
    }, [isAllValid, isSomeInvalid]);

    return (
        <>
            <Input
                value={currentPassword}
                onChange={handleFieldUpdate}
                universalOnChange
                variants={DEFAULT_INPUT_VARIANTS}
                label={t('my-account__account-info__current-password')}
                name="currentPassword"
                type="password"
                autoComplete="current-password"
                autoFocus
            />
            <Input
                value={newPassword}
                onChange={handleFieldUpdate}
                universalOnChange
                variants={newPasswordInputVariants}
                label={t('my-account__account-info__new-password')}
                name="newPassword"
                type="password"
                autoComplete="new-password"
            />
            <Input
                value={confirmNewPassword}
                onChange={handleFieldUpdate}
                universalOnChange
                variants={newPasswordInputVariants}
                label={t('reset-pass__label--confirm')}
                name="confirmNewPassword"
                type="password"
                autoComplete="off"
            />
        </>
    );
};
