import { createDataFeed, linkCloudAccount, unlinkCloudAccount, deleteDataFeed } from 'services/DataFeed';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';

const FEEDS_PAGE_URL = '/admin/dxsFeeds';

export const processDataFeedCreation = async ({ payload, setIsLoading, t }) => {
    setIsLoading(true);

    try {
        await createDataFeed(payload);
        addSuccessNotification(t('dxs-feeds__notification--datafeed-successfully-created'));
    } catch (err) {
        addErrorNotification(t('common__error--internal-server'));
    }

    setIsLoading(false);
};

export const processCloudAccountLink = async ({ payload, setIsLoading, t }) => {
    setIsLoading(true);

    try {
        await linkCloudAccount(payload);
        addSuccessNotification(t('dxs-feeds__notification--cloud-account-linked'));
    } catch (err) {
        addErrorNotification(t('common__error--internal-server'));
    }

    setIsLoading(false);
};

export const processCloudAccountUnlink = async ({ payload, setIsLoading, t }) => {
    setIsLoading(true);

    try {
        await unlinkCloudAccount(payload);
        addSuccessNotification(t('dxs-feeds__notification--cloud-account-unlinked'));
    } catch (err) {
        addErrorNotification(t('common__error--internal-server'));
    }

    setIsLoading(false);
};

export const processFeedDeletion = async ({ payload, setIsLoading, navigate, t }) => {
    setIsLoading(true);

    try {
        await deleteDataFeed(payload);

        navigate(FEEDS_PAGE_URL);
        setTimeout(() => {
            addSuccessNotification(t('dxs-feeds__notification--datafeed-successfully-deleted'));
        }, 1000);
    } catch (err) {
        addErrorNotification(t('common__error--internal-server'));
    }

    setIsLoading(false);
};
