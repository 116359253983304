import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';
import { Label } from 'library/Label';
import { useGeneratedId } from 'hooks/useGeneratedId';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

import './SingleSelect.scss';

const defaultVariants = [];
const defaultPlaceholder = 'common__select-item';

const mobileOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
};

export const SINGLE_SELECT_VARIANTS = {
    LABEL_INSIDE: 'label-inside',
    MOBILE_LABEL_INSIDE: 'mobile-label-inside',
    NO_LABEL: 'no-label',
    WITH_ICON: 'with-icon'
};

const SingleSelectItem = ({ item, withTitle, onChange, className }) => {
    const { text, disabled = false } = item;

    const onClick = useCallback((event) => {
        onChange && onChange(item, event);
    }, [item, onChange]);

    return (
        <li
            className={classNames(
                'hme-single-select__dropdown__item',
                className,
                { 'hme-single-select__dropdown__item--disabled': disabled },
            )}
            onClick={onClick}
            title={withTitle && text ? text : ''}
        >
            {text}
        </li>
    );
};

const SingleSelectInput = ({
    value,
    variants = defaultVariants,
    label,
    placeholder = defaultPlaceholder,
    onChange,
    items,
    isDisabled = false,
    isRequired = false,
    withTitle = false,
    withFilter = true,
    scrollOnOpen = false,
    className = '',
    Icon = null,
    noResultsText = 'common__no-results',
    ...props
}) => {
    const { t } = useTranslation();

    const id = useGeneratedId(props.id);

    const selectRef = useRef(null);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [lastSelected, setLastSelected] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);

    const translatedItems = useMemo(() => items.map((option) => ({
        text: t(option.text),
        value: option.value,
        disabled: option.disabled,
    })), [items, t]);

    useEffect(() => {
        const selectedItem = translatedItems.find((item) => item.value === value) || null;
        const text = selectedItem ? selectedItem.text : t(placeholder);
        setInputText(text);

        setLastSelected(text);
        setFilteredItems(translatedItems);
    }, [value, placeholder, translatedItems, t]);

    const onMobileChange = useCallback((e) => {
        onChange && onChange(e.target.value);
    }, [onChange]);

    const onClick = useCallback(() => {
        if (isDisabled) {
            inputRef.current && inputRef.current.blur();
            return;
        }

        if (withFilter) {
            if (isOpen) {
                setInputText(lastSelected);
                inputRef.current.blur();
            } else {
                setInputText('');
                inputRef.current.focus();
            }

            setFilteredItems(translatedItems);
        }

        if (!isOpen && scrollOnOpen) {
            requestAnimationFrame(() => {
                dropdownRef.current.scrollIntoView(false);
            });
        }

        setIsOpen(!isOpen);
    }, [isDisabled, isOpen, setIsOpen, setInputText, lastSelected, translatedItems, dropdownRef]);

    const onItemClick = useCallback((item, event) => {
        const { text, value: itemValue, disabled } = item;
        if (disabled) {
            event.stopPropagation();
            setIsOpen(true);
            return;
        }

        setIsOpen(false);
        onChange && onChange(itemValue);
        setLastSelected(text);
    }, [setIsOpen, onChange, setLastSelected]);

    const outSideClickHandler = useCallback(() => {
        if (isOpen) {
            setIsOpen(false);
            setInputText(lastSelected);
        }
    }, [setIsOpen, isOpen, lastSelected]);

    useClickOutSide(selectRef, outSideClickHandler);

    const handleFilterChange = useCallback((e) => {
        const { value: inputValue } = e.target;
        setInputText(inputValue);
        const updatedItems = translatedItems.filter((item) => item.text.toLowerCase().includes(inputValue.toLowerCase()));
        setFilteredItems(updatedItems);
    }, [setInputText, setFilteredItems, translatedItems]);

    return (
        <div
            className={classNames(
                'hme-single-select',
                className,
                {
                    'hme-single-select--opened': isOpen,
                    'hme-single-select--required': isRequired,
                    'hme-single-select--disabled': isDisabled,
                    'hme-single-select--with-icon-variant': Boolean(Icon),
                    'hme-single-select--label-inside-variant': Boolean(Icon)
                },
                variants.map((variant) => `hme-single-select--${variant}-variant`)
            )}
        >
            <Label as="label" htmlFor={id}>{label}</Label>
            <div
                className="hme-single-select__box"
                title={withTitle ? inputText : ''}
                onClick={onClick}
                ref={selectRef}
            >
                <span className="hme-single-select__box__star">*</span>
                {Icon && <span className="hme-single-select__icon-wrapper">{Icon}</span>}
                {withFilter ? (
                    <div className="hme-single-select__box__text hme-single-select__box__text--with-filter">
                        <input
                            type="text"
                            value={inputText}
                            ref={inputRef}
                            onChange={handleFilterChange}
                            className="hme-single-select__box__text-desktop"
                            readOnly={!isOpen}
                        />
                        <span className="hme-single-select__box__text-mobile">{inputText}</span>
                    </div>
                ) : (
                    <span className="hme-single-select__box__text">{inputText}</span>
                )}
                <DropdownIcon className="hme-single-select__box__icon" />

                <div className="hme-single-select__dropdown" ref={dropdownRef}>
                    {filteredItems.length ? (
                        <ul disabled={isDisabled} className="hme-single-select__dropdown__list">
                            {filteredItems.map((item) => (
                                <SingleSelectItem
                                    key={item.value}
                                    item={item}
                                    withTitle={withTitle}
                                    onChange={onItemClick}
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className="hme-single-select__no-results">{t(noResultsText)}</div>
                    )}
                </div>
                <select
                    className="hme-single-select__mobile"
                    value={value}
                    onClick={mobileOnClick}
                    onChange={onMobileChange}
                    disabled={isDisabled}
                    id={id}
                >
                    {translatedItems.map(({ value: optionValue, text }) => (
                        <option key={optionValue} value={optionValue}>
                            {text}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

// TODO: Add className as prop here
const SingleSelectReadOnly = ({ items, label, value }) => {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState('');

    const translatedItems = useMemo(() => items.map((option) => ({
        text: t(option.text),
        value: option.value
    })), [items, t]);

    useEffect(()=> {
        const selectedItem = translatedItems.find((item) => item.value === value) || null;

        const text = selectedItem && selectedItem.value !== '' ? selectedItem.text : '-';

        setInputText(text);
    }, [translatedItems, value, t]);

    // TODO: Use className hme-single-select--read-only instead of hme-single-select--required
    return (
        <div
            className={classNames(
                    'hme-single-select', 'hme-single-select--required'
            )}
        >
            <Label>{t(label)}</Label>
            <div>
                <span className="hme-single-select__read-only-label">{
                    inputText
                }</span>
            </div>
        </div>

    );
};

export const SingleSelect = cond([
    [({ isReadOnly }) => isReadOnly, (props) => <SingleSelectReadOnly {...props} />],
    [T, (props) => <SingleSelectInput {...props} />]
]);

const valueType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

const textType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element
]);

const availableVariants = ['label-inside', 'timer-icon', 'small-dropdown', 'no-label', 'mobile-label-inside'];

export const itemsProps = PropTypes.arrayOf(PropTypes.shape({
    value: valueType.isRequired,
    text: textType.isRequired
}));

SingleSelect.propTypes = {
    value: valueType.isRequired,
    label: PropTypes.string,
    placeholder: textType,
    onChange: PropTypes.func,
    items: itemsProps,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    withTitle: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    variants: PropTypes.arrayOf(PropTypes.oneOf(availableVariants))
};
