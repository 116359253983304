/* eslint-disable react/display-name */
import React from 'react';

// Provided complete crud for working with
// particular storage key "configKey"
// as with the plain object for saving isolated configuration.
const configServiceFactory = (configKey) => {
    if (!configKey) {
        console.error('No configKey provided.');
        return null;
    }
    const saveConfig = (values = {}) => {
        const serialized = JSON.stringify(values);
        localStorage.setItem(configKey, serialized);
        return serialized;
    };
    const getConfig = () => {
        try {
            return JSON.parse(localStorage.getItem(configKey)) || {};
        } catch (e) {
            console.error(`${configKey} key parsing error. ${e}`);
        }
        return {};
    };
    const removeConfig = () => {
        localStorage.removeItem(configKey);
        return true;
    };
    const updateConfig = (values = {}) => {
        const updated = JSON.stringify({ ...getConfig(), ...values });
        localStorage.setItem(configKey, updated);
        return updated;
    };
    return {
        getConfig,
        saveConfig,
        updateConfig,
        removeConfig
    };
};

export const LocalStorageContext = React.createContext();
export const withLocalStorageContext = (Component, configKey) => (props) => {
    return (
        <LocalStorageContext.Provider value={configServiceFactory(configKey)}>
            <Component {...props}/>
        </LocalStorageContext.Provider>
    );
};
