import { useTranslation } from 'react-i18next';

import { SingleSelect, SINGLE_SELECT_VARIANTS } from 'library/SingleSelect';
import { Button } from 'library/Button';

import './Export.scss';

const selectVariants = [SINGLE_SELECT_VARIANTS.MOBILE_LABEL_INSIDE];

export const EXPORT_TYPES = {
    CSV: 'csv',
    JSON: 'json',
};

const exportItems = [{
    text: 'common__export--csv',
    value: EXPORT_TYPES.CSV,
}, {
    text: 'common__export--json',
    value: EXPORT_TYPES.JSON,
}];

export const Export = ({ exportFormat, isExportDisabled, onExportFormatChange, onExport }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-manage-hierarchy__file-manage__export'>
            <SingleSelect
                variants={selectVariants}
                label={t('common__export')}
                value={exportFormat}
                onChange={onExportFormatChange}
                items={exportItems}
            />
            <Button disabled={isExportDisabled} onClick={onExport}>{t('common__export')}</Button>
        </div>
    );
};
