import { t } from 'i18next'; // Non-hook version

export const gridHeaderOptions = [
    {
        text: t('common__source-devices'),
        property: [
            {
                text: t('common__device__serial-number'),
                property: 'sourceDevice.Device_SerialNumber',
                className: 'hme-grid-cell-show-all-content apply-job-serial-number',
                flex: 1.5,
            },
            {
                text: t('common__device__product-id'),
                property: 'sourceDevice.Device_Product_ID',
                className: 'hme-grid-cell-show-all-content apply-job-serial-number',
                flex: 1.5,
            },
            {
                text: t('common__account'),
                property: 'accountEmail',
                className: 'hme-grid-cell-show-all-content',
                flex: 1.5,
            },
            {
                text: t('common__brand'),
                property: 'sourceBrandName',
            },
            {
                text: t('common__store__name'),
                property: 'sourceDevice.Store_Name',
            },
            {
                text: t('common__store__number'),
                property: 'sourceDevice.Store_Number',
            },
            {
                text: t('common__device__version'),
                property: 'sourceVersion',
            },
            {
                text: t('common__applied-settings-device'),
                className: 'hme-grid-cell-show-all-content apply-job-applied-settings',
                property: 'settingsGroupsText',
                flex: 1.5,
            },
        ],
    },
    {
        text: t('common__task-information'),
        property: [
            {
                text: t('common__number-of-devices'),
                property: 'devicesCount',
                className: 'apply-job-device-count',
            },
            {
                text: t('common__device-type'),
                property: 'deviceTypeName',
                flex: 1,
            },
            {
                text: t('common__start-date-and-time'),
                property: 'createdAtTime',
                flex: 1.25,
            },
            {
                text: t('common__applied-by'),
                property: 'appliedBy',
                flex: 1.25,
                headerClassName: 'apply-job-applied-by-header',
            },
            {
                text: t('common__task-status'),
                property: 'taskStatusCell',
                flex: 1,
                headerClassName: 'apply-job-status-header',
            },
        ],
    },
];

export const targetDevicesGridHeaderOptions = [
    {
        text: 'Target devices',
        property: [
            {
                text: 'Serial #',
                property: 'Device_SerialNumber',
                className: 'apply-job-serial-number',
                flex: 1.5,
            },
            {
                text: t('common__device__product-id'),
                property: 'Device_Product_ID',
                className: 'hme-grid-cell-show-all-content apply-job-serial-number',
                flex: 1.5,
            },
            {
                text: 'Account',
                property: 'User_EmailAddress',
                className: 'hme-grid-cell-show-all-content',
                flex: 1.5,
            },
            {
                text: 'Brand',
                property: 'Brand_Name',
            },
            {
                text: 'Store name',
                property: 'Store_Name',
                className: 'hme-grid-cell-show-all-content',
            },
            {
                text: 'Store #',
                property: 'Store_Number',
                className: 'hme-grid-cell-show-all-content apply-job-store-number',
            },
            {
                text: 'Version',
                property: 'Device_MainVersion',
            },
            {
                text: 'Device status',
                property: 'statusCell',
                flex: 1.0,
            },
            {
                text: '',
                property: 'nothing',
                className: 'hme-grid-empty-cell',
                flex: 0.5,
            },
        ],
    },
    {
        text: '',
        property: [
            {
                text: '',
                property: 'empty',
                flex: 5.5,
                className: 'hme-grid-empty-cell',
            },
        ],
    },
];

export const tileListHeaderOptions = [
    {
        title: 'common__source-devices',
        column: [
            { text: 'common__device__serial-number', property: 'sourceDevice.Device_SerialNumber' },
            { text: 'common__account', property: 'accountEmail' },
            { text: 'common__brand', property: 'sourceBrandName' },
            { text: 'common__store__name', property: 'sourceDevice.Store_Name' },
            { text: 'common__store__number', property: 'sourceDevice.Store_Number' },
            { text: 'common__device__version', property: 'sourceVersion' },
            { text: 'common__applied-settings-device', property: 'settingsGroupsText' },
        ],
    },
    {
        toggleable: true,
        title: 'common__target-devices',
        buttonShowText: 'apply-device-settings-status__show-target-devices',
        buttonHideText: 'apply-device-settings-status__hide-target-devices',
        subcolumnField: 'destinationDevices',
        subcolumn: [
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
            { text: 'common__account', property: 'User_EmailAddress' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__store__name', property: 'Store_Name' },
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__device__version', property: 'Device_MainVersion' },
            { text: 'common__device-status', property: 'deviceStatusCell' },
        ],
    },
    {
        title: 'common__task-information',
        column: [
            { text: 'common__number-of-devices', property: 'devicesCount' },
            { text: 'common__device-type', property: 'deviceTypeName' },
            { text: 'common__start-date-time', property: 'createdAtTime' },
            { text: 'common__applied-by', property: 'appliedBy' },
            { text: 'common__task-status', property: 'taskStatusCell' },
        ],
    },
];
