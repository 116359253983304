import React, { useMemo } from 'react';
import classNames from 'classnames';
import { cond, T } from 'ramda';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CommonConstants } from 'Constants';
import { useAccess } from 'hooks/useAccess';
import { Button } from 'library/Button';
import { StatusIcon } from 'library/StatusIcon';
import { navigationService } from 'components/Common/NavigationServices';
import { isDistributor } from 'services/Auth';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

import './DeviceStatus.scss';

const { deviceType, userPermissions } = CommonConstants;

const isDistributorUser = isDistributor();

const getDeviceName = (version, name) => {
    const normalizedVersion = BuildVersions.normalizeVersionString(version);
    return name === 'ZOOM' && BuildVersions.semverGte(normalizedVersion, deviceType.zoomNitro.entryVersion) ?
        deviceType.zoomNitro.displayName :
        name;
};

const unconnectableDevices = [deviceType.linkWear.displayName, deviceType.evd.displayName];

const UnavailableDevice = ()  => {
    const { t } = useTranslation();

    return (
        <div className='hme-device-status hme-device-status--unavailable'>
            {t('common__device__status--unavailable')}
        </div>
    );
};

const PreconfiguredDevice = ({ name, version, hideDeviceName = false }) => {
    const deviceName = useMemo(() => getDeviceName(version, name), [version, name]);
    const { t } = useTranslation();

    return (
        <div className='hme-device-status hme-device-status--preconfigured'>
            {!hideDeviceName && `${deviceName} - `}{t('common__device__status--pre-configured')}
        </div>
    );
};

const UnconnectableOnlineDevice = ({ name, version, hideDeviceName = false, className = '' }) => {
    const deviceName = useMemo(() => getDeviceName(version, name), [version, name]);
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-device-status', 'hme-device-status--online', className)}>
            <span>{!hideDeviceName && `${deviceName} - `}{t('common__device__status--online')}</span>
            <StatusIcon online={true} />
        </div>
    );
};

const ConnectLink = ({ to, children }) => {
    if (to.includes('http://') || to.includes('https://')) {
        return (
            <a href={to}>{children}</a>
        );
    }

    return (
        <Link to={to}>{children}</Link>
    );
};

const ConnectableOnlineDevice = ({ name, version, deviceUid, hideDeviceName = false, className = '' }) => {
    const deviceName = useMemo(() => getDeviceName(version, name), [version, name]);
    const { t } = useTranslation();
    const connectUrl = useMemo(() => {
        return navigationService.getMenu('connect', deviceUid, version, null, name);
    }, [deviceUid, version, name]);

    return (
        <div className={classNames('hme-device-status', 'hme-device-status--online', className)}>
            <ConnectLink to={connectUrl}>
                <Button variants={['transparent']}>
                    <span>{!hideDeviceName && `${deviceName} - `}{t('common__device__status--online')}</span>
                    <StatusIcon online={true} />
                </Button>
            </ConnectLink>
        </div>
    );
};

const OnlineDevice = ({ name, version, deviceUid, className = '', hideDeviceName = false  }) => {
    /*
     * NOTE: We moved this logic into "useConnectableDevice" hook
     * so when you will change logic below consider using "useConnectableDevice" hook
    */
    // START
    const hasDistributorManageDeviceBasic = isDistributorUser && useAccess(CommonConstants.externalPermissions.ManageDevicesBasic);
    const hasRemoteConnectAccess = useAccess(userPermissions.RemoteConnect);
    const connectable = useMemo(() => (hasRemoteConnectAccess || hasDistributorManageDeviceBasic) &&
        !unconnectableDevices.includes(name), [name, hasRemoteConnectAccess, hasDistributorManageDeviceBasic]);
    // END

    return (
        <>
            {
                connectable && <ConnectableOnlineDevice
                    name={name}
                    version={version}
                    deviceUid={deviceUid}
                    hideDeviceName={hideDeviceName}
                    className={className}
                />
            }
            {
                !connectable && <UnconnectableOnlineDevice
                    name={name}
                    version={version}
                    hideDeviceName={hideDeviceName}
                    className={className}
                />
            }
        </>
    );
};

const OfflineDevice = ({ name, version, hideDeviceName = false }) => {
    const deviceName = useMemo(() => getDeviceName(version, name), [version, name]);
    const { t } = useTranslation();

    return (
        <div className='hme-device-status hme-device-status--offline'>
            <span>{!hideDeviceName && `${deviceName} - `}{t('common__device__status--offline')}</span>
            <StatusIcon online={false} />
        </div>
    );
};

export const DeviceStatus = cond([
    [({ name, version }) => !name || !version, (props)=>{
        return <UnavailableDevice {...props}/>;
    }],
    [({ isPreconfigured }) => isPreconfigured, PreconfiguredDevice],
    [({ online }) => online, (props)=> {
        return <OnlineDevice {...props}/>;
    }],
    [T, OfflineDevice]
]);
