import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'components/Common/Grid';

import './TimezoneGrid.css';

const gridHeaders = [
    {
        text: 'Time zone',
        property: 'timezone',
        flex: 112,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'Number of devices',
        property: 'quantity',
        flex: 112,
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
];

export const TimezoneGrid = ({ countryTimezoneDevicesCount, selectedDeviceTimezones, onDeviceTimezoneSelection }) => {
    const { t } = useTranslation();

    const gridRows = useMemo(
        () =>
            countryTimezoneDevicesCount.map(({ WindowsTimeZone, TimezoneID, Total }) => ({
                timezone: WindowsTimeZone || '',
                timezoneID: TimezoneID || -1,
                quantity: Total,
            })),
        [countryTimezoneDevicesCount],
    );

    return (
        <div className="timezone-devices hme-components">
            <Grid
                selection={selectedDeviceTimezones}
                selectable
                onSelectionChange={onDeviceTimezoneSelection}
                headers={gridHeaders}
                rows={gridRows}
                isLoading={false}
                rowKey="timezoneID"
                noRecordsMessage={t('common__no-devices--found')}
            />
        </div>
    );
};
