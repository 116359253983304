import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { updatePartner } from 'services/Partner';

export const useSavePartner = () => {
    const { partnerUID } = useParams();

    const {
        run,
        isLoading,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED,
    });

    const save = useCallback((partner) => {
        return run(updatePartner(partnerUID, partner));
    }, [partnerUID]);

    return {
        isSaving: isLoading,
        save
    };
};
