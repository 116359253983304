import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Header.scss';

export const Header = () => {
    const { t } = useTranslation();

    return (
        <div className='hme-customer-notification-status__header'>
            <Title>{t('admin__customer-notification__list__title')}</Title>
            <Button as={Link} to='/admin/customerNotification/new'>{t('admin__customer-notification__list__create-btn')}</Button>
        </div>
    );
};
