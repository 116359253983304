import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ onCancel, onDecline, onApprove }) => {
    const { t } = useTranslation();

    return (
        <div className="view-device-upgrade-footer">
            <Button onClick={onCancel}>{t('common__close')}</Button>
            <Button variants={['decline']} onClick={onDecline}>
                {t('device-upgrades__button__decline-upgrade')}
            </Button>
            <Button variants={['approve']} onClick={onApprove}>
                {t('device-upgrades__button__approve-upgrade')}
            </Button>
        </div>
    );
};
