import React from 'react';
import classNames from 'classnames';

import './StatusIcon.scss';

export const StatusIcon = ({ online }) => (
    <span className={classNames('hme-status-icon', {
        'hme-status-icon--online': online,
        'hme-status-icon--offline': !online,
    })} />
);
