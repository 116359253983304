import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    compose,
    map,
    flatten,
    identity,
    path,
    pipe,
    pluck,
    sortBy,
    uniq,
    uniqBy,
    prop,
    toLower,
    sortWith,
} from 'ramda';

import { useOnTrigger } from 'hooks/useTrigger';
import compareVersions from 'helpers/sortComparators/version';
import { DateLib } from '@hme-cloud/utility-common';
import { Grid, resetFilters, getFiltersCount } from 'components/Common/Grid'
import { TileList } from 'library/TileList';
import {Paginate} from "library/Paginate";
import { taskConfig } from 'constants/applyDeviceSettingsTask';
import { PAGE_DATA } from "constants/paginationDefault";
import {PAGE_DEFAULT} from "constants/paginationDefault";

import { TargetDeviceList } from './TargetDevicesList';
import {
    getDeviceTypeName,
    getStatusCell,
    getRowDeviceTypes,
    getTaskStatusCell,
    getTaskStatusValue,
} from '../../../DeviceSettings/helpers';
import {
    gridHeaderOptions,
    tileListHeaderOptions,
    availableSettingsFilters,
    availableDeviceTypesFilters,
} from '../constants';

import './JobsList.scss';

const {
    statuses: deviceJobStatuses,
    inProgressStatuses,
} = taskConfig;

const ALL_BRANDS_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-brands';
const ALL_SETTINGS_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-settings';
const ALL_VERSIONS_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-versions';
const ALL_STATUSES_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-statuses';

// We can't use options from SourceDeviceSettings component
// because we can turn off/remove some options.
// But we need toLowerCase display that names in the future
const settingsGroupValues = {
    LANE_SETTINGS: 'apply-device-settings-status__setting-name--lane-settings',
    DAYPARTS_AND_SHIFTS: 'apply-device-settings-status__setting-name--dayparts-and-shifts',
    STORE_HOURS: 'apply-device-settings-status__setting-name--store-hours',
    DASHBOARD_SETTINGS: 'apply-device-settings-status__setting-name--dashboard-settings',
    ALL_SETTINGS: 'apply-device-settings-status__setting-name--all-settings',
    SPEED_GOALS: 'apply-device-settings-status__setting-name--speed-goals',
    TIME_FORMAT: 'common__time-format',
};

const getSettingsGroupsText = (groups, t) => Array.isArray(groups) ?
    groups.map(name => settingsGroupValues[name] ? t(settingsGroupValues[name]) : name).join(', ') :
    settingsGroupValues[groups] ? t(settingsGroupValues[groups]) : groups;

const getRowClassName = job => {
    const hasInProgress = job.destinationDevices.some(
        device => inProgressStatuses.includes(device.ApplySettingsStatus)
    );

    return hasInProgress ? '' : 'apply-job-device-row-completed';
}

const getJobRowClassName = (property, jobSection) => {
    let className = '';

    if (property.startsWith('destinationDevices')) {
        const status = path([
            'destinationDevices',
            'ApplySettingsStatus'
        ], jobSection);

        className = inProgressStatuses.includes(status) ? '' : 'apply-completed-job-cell';
    }

    return className;
};

const getValues = rows => {
    return rows.map(({
        destinationDevices = [],
        sourceDevice = {},
    }) => {
        const {
            Device_SerialNumber = '',
            Device_Product_ID = '',
            Store_Number = '',
            Store_Name = '',
        } = sourceDevice;

        const targetDeviceValues = destinationDevices.map(({
            Device_SerialNumber,
            Device_Product_ID,
            Store_Number,
            Store_Name
        }) => [
            Device_SerialNumber || '',
            Device_Product_ID || '',
            Store_Number || '',
            Store_Name || ''
        ].join(' '));

        return [
            Device_SerialNumber || '',
            Device_Product_ID || '',
            Store_Number || '',
            Store_Name || '',
            targetDeviceValues
        ].join(' ').toLowerCase();
    });
}

const filterRowsByWords = (rows, originWords) => {
    const words = originWords.map(word => word.toLowerCase());
    const values = getValues(rows);

    return rows.filter((_, index) => {
        const value = values[index];

        return words.every(word => value.includes(word));
    });
};

const getFilteredRows = (rows, filters) => {
    const {
        words,
    } = filters;

    const filteredByWords = filterRowsByWords(rows, words);

    return filteredByWords;
}

const jobToRow = (job, t) => ({
    ...job,
    settingsGroupsText: job.sourceSnapshot.SettingName ?
        `${t('apply-device-settings__grid__by-snapshot')} - ${job.sourceSnapshot.SettingName}` :
        (getSettingsGroupsText(job.settingsGroups, t) || t('apply-device-settings__grid__snapshot-is-removed')),
    devicesCount: job.destinationDevices.length,
    appliedBy: `${job.user.User_FirstName} ${job.user.User_LastName}`,
    rowClassName: getRowClassName(job),
    getCellClassName: getJobRowClassName,
    createdAtTime: new DateLib(job.CreatedAt).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME),
    taskStatusCell: getTaskStatusCell(job.destinationDevices),
    taskStatusValue: getTaskStatusValue(job.destinationDevices),
    deviceTypeName: getDeviceTypeName(job.sourceDevice),
    sourceBrandName: job.sourceDevice.Brand_Name || job.sourceSnapshot.SourceBrand,
    sourceVersion: job.sourceSnapshot.SourceSoftwareVersion || job.sourceDevice.Device_MainVersion,
    destinationDevices: job.destinationDevices.map(device => ({
        ...device,
        deviceStatusCell: getStatusCell(device.ApplySettingsStatus),
    }))
});

const getTaskStatuses = (t) => {
    return [{
        text: t('common__task__status--in-progress'),
        value: deviceJobStatuses.IN_PROGRESS
    }, {
        text: t('common__task__status--completed'),
        value: deviceJobStatuses.COMPLETED
    }, {
        text: <span className='tasks-table-public-status-filter-failed'>{t('common__task__status--failed')}</span>,
        value: deviceJobStatuses.FAILED
    }, {
        text: t('common__task__status--unknown'),
        value: -1
    }]
};

const getAllVersions = map(path(['sourceDevice', 'Device_MainVersion']));
const getUniqVersions = pipe(getAllVersions, uniq, sortWith([compareVersions]));
const getSettingGroups = pipe(
    pluck('settingsGroups'),
    flatten,
    uniq,
    sortBy(identity)
);

const getBrandData = ({ sourceDevice: { Brand_ID: value, Brand_Name: text } }) => ({
    text,
    value,
});
const getAllBrands = pipe(
    map(getBrandData),
    uniqBy(prop('value')),
    sortBy(compose(toLower, prop('text')))
);

const getStatuses = pipe(
    pluck('taskStatusValue'),
    uniq,
);

export const JOBS_FILTER_OPTIONS = {
    sourceBrandName: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    'sourceDevice.Device_MainVersion': {
        title: 'common__device__version',
        allText: 'common__all-versions',
    },
    settingsGroupsText: {
        title: 'common__applied-settings-device',
    },
    deviceTypeName: {
        title: 'common__device-type',
        allText: 'common__all-device-types',
    },
    taskStatusCell: {
        title: 'common__task-status',
        allText: 'common__all-statuses',
    },
};

export const JobsList = ({ jobs, isLoading, filters: filters, filtersCount, resetFiltersTrigger, onFiltersCountChange }) => {
    const { t } = useTranslation();
    const [noRecordsMessage, setNoRecordsMessage] = useState(t('apply-device-settings-status__no-tasks--load'));
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isRendering, setIsRendering] = useState(false);

    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);

    const paginatedPages = useMemo(()=> filteredRows
        .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage), [filteredRows, pageNumber, itemsPerPage])

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, []);

    const [gridFilters, setGridFilters] = useState({
        sourceBrandName: [],
        'sourceDevice.Device_MainVersion': [],
        settingsGroupsText: [],
        deviceTypeName: [],
        taskStatusCell: [],
    });
    const [availableFilters, setAvailableFilters] = useState([]);

    const onFiltersAndSortChange = useCallback(({ selectedFilters }) => setGridFilters(selectedFilters), []);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters]);

    useEffect(() => {
        setIsRendering(true);
        const rows = jobs.map(job => jobToRow(job, t));
        setRows(rows);
        const uniqVersions = getUniqVersions(jobs);
        const settingsGroups = getSettingGroups(jobs);
        const brands = getAllBrands(jobs);
        const allStatuses = getStatuses(rows);
        const statuses = getTaskStatuses(t).filter(({ value }) => allStatuses.includes(value));
        const deviceTypes = getRowDeviceTypes(rows);

        setGridFilters({
            sourceBrandName: pluck('value', brands),
            'sourceDevice.Device_MainVersion': uniqVersions,
            settingsGroupsText: settingsGroups,
            deviceTypeName: deviceTypes,
            taskStatusCell: pluck('value', statuses),
        });

        setAvailableFilters([
            {
                property: 'sourceBrandName',
                // TODO: Translation of All texts should be moved into the Grid component afet i18next is applied
                allText: ALL_BRANDS_FILTER_TEXT,
                items: brands,
            },
            {
                property: 'sourceDevice.Device_MainVersion',
                allText: ALL_VERSIONS_FILTER_TEXT,
                items: uniqVersions.map((version) => ({
                    text: version,
                    value: version,
                })),
            },
            {
                ...availableSettingsFilters,
                items: availableSettingsFilters
                    .items
                    .filter(item => settingsGroups.includes(item.value)),
            },
            {
                ...availableDeviceTypesFilters,
                items: availableDeviceTypesFilters.items.filter((item) => deviceTypes.includes(item.value)),
            },
            {
                property: 'taskStatusCell',
                allText: ALL_STATUSES_FILTER_TEXT,
                items: statuses,
            },
        ]);
    }, [jobs, t, setRows, setGridFilters, setAvailableFilters]);

    useEffect(() => {
        const newFilteredRows = rows
            .filter(({
                sourceDevice
            }) => gridFilters['sourceBrandName'].includes(sourceDevice.Brand_ID))
            .filter(({
                sourceDevice
            }) => gridFilters['sourceDevice.Device_MainVersion'].includes(sourceDevice.Device_MainVersion))
            .filter(({
                settingsGroups
            }) => gridFilters['settingsGroupsText'].some(f => settingsGroups.includes(f)))
            .filter(({
                deviceTypeName
            }) => gridFilters['deviceTypeName'].some(dt => deviceTypeName.includes(dt)))
            .filter(({
                taskStatusValue
            }) => {
                const selectedStatuses = flatten(gridFilters['taskStatusCell']);
                return selectedStatuses.includes(taskStatusValue);
            });
        setFilteredRows(getFilteredRows(newFilteredRows, filters));
        setIsRendering(false);
    }, [rows, filters, gridFilters]);

    useEffect(() => {
        setNoRecordsMessage(
            jobs.length === 0 ?
                t('apply-device-settings-status__no-tasks--load') :
                t('common__no-tasks-found')
        );
    }, [jobs, t]);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    return (
        <div className="tasks-table-public">
            <Grid
                rows={paginatedPages}
                headers={gridHeaderOptions}
                rowKey='taskUID'
                isLoading={isLoading || isRendering}
                ExpandComponent={TargetDeviceList}
                noRecordsMessage={noRecordsMessage}
                expandable
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
                loadingText={t('common__loading')}
            />
            <TileList
                headers={tileListHeaderOptions}
                rows={paginatedPages}
                filters={gridFilters}
                availableFilters={availableFilters}
                filtersOptions={JOBS_FILTER_OPTIONS}
                filtersCount={filtersCount}
                rowKey='taskUID'
                sortButtonName="common__filter"
                isLoading={isLoading}
                noRecordsMessage={noRecordsMessage}
                onFiltersAndSortChange={onFiltersAndSortChange}
                onFiltersAndSortReset={onFiltersReset}
                onFiltersReset={onFiltersReset}
            />
            <Paginate
                className="job-list-paginate"
                page={pageNumber}
                recordsPerPage={itemsPerPage}
                pageSizes={PAGE_DATA.PAGE_SIZES_PUBLIC}
                total={filteredRows.length}
                onChange={onPaginationChange}
                hideSinglePage
            />
        </div>
    );
};
