import i18n from 'i18next';

// returns error if value or array are empty
export const required = (fieldName) => (value) => {
    const message = `${fieldName} ${i18n.t('common__must-be-entered')}`;
    if (!value) {
        return message;
    }
    if (Array.isArray(value) && !value.length) {
        return message;
    }
};
