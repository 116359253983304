import { flatten, pluck } from 'ramda';

import { EventEmitter } from 'helpers/eventEmitter';
import { getAllDevices } from 'services/Device';

export class LoaderByAccount {
    constructor() {
        /*
            structure: {
                [accountId]: {
                    [brandId]: {
                        divices,
                        isLoading,
                        error,
                    }
                }
            }
        */
        this._accounts = {};
        this._eventEmitter = new EventEmitter();
    }

    isLoading() {
        return Object
            .keys(this._accounts)
            .some(account => Object
                .keys(this._accounts[account])
                .some(brand => this._accounts[account][brand].isLoading));
    }

    getDevices() {
        return flatten(
            Object
                .keys(this._accounts)
                .map(account => Object
                    .keys(this._accounts[account])
                    .map(brand => this._accounts[account][brand].devices))
        );
    }

    on(eventName, handler) {
        this._eventEmitter.on(eventName, handler);
    }

    off(eventName, handler) {
        this._eventEmitter.off(eventName, handler);
    }

    reset() {
        this._accounts = {};
    }

    async update(filters) {
        const { accountBrand } = filters;
        // convert to new 
        const brandPairs = flatten(
            accountBrand
                .map(({ accountId, brandIds }) => brandIds
                    .map(brandId => ({
                        accountId: accountId.toString(),
                        brandId: brandId.toString(),
                    }))
                )
        );

        const newBrands = brandPairs.filter(({ accountId, brandId }) => !this._accounts[accountId] ||
            !this._accounts[accountId][brandId]
        );

        const oldBrands = flatten(Object.keys(this._accounts).map(account => {
            const accountBrands = brandPairs.filter(({ accountId }) => accountId === account);
            const brands = pluck('brandId', accountBrands);

            const existedBrands = Object.keys(this._accounts[account]);
            return existedBrands.filter(brand => !brands.includes(brand))
                .map(brand => ({
                    accountId: account,
                    brandId: brand,
                }))
        }));

        // no modifications
        if (newBrands.length === 0 && oldBrands.length === 0) {
            return;
        }

        newBrands.forEach(({ accountId, brandId }) => {
            this._load(accountId, brandId, filters);
        });

        oldBrands.forEach(({ accountId, brandId }) => {
            this._remove(accountId, brandId);
        });

        const accountsToRemove = Object.keys(this._accounts).filter(
            account => Object.keys(this._accounts[account]).length === 0
        );

        accountsToRemove.forEach(account => delete this._accounts[account]);

        this._eventEmitter.trigger('changed');
    }

    removeDevice() {
        
    }

    _onLoadSuccess(accountId, brandId, devices)  {
        if (!this._accounts[accountId] || !this._accounts[accountId][brandId]) {
            return;
        }

        this._accounts[accountId][brandId].devices = devices;
        this._accounts[accountId][brandId].error = null;
        this._accounts[accountId][brandId].isLoading = false;
    }

    _onLoadFailed(accountId, brandId, error) {
        if (!this._accounts[accountId] || !this._accounts[accountId][brandId]) {
            return;
        }

        this._accounts[accountId][brandId].devices = [];
        this._accounts[accountId][brandId].error = error;
        this._accounts[accountId][brandId].isLoading = false;
    }

    _getLoadDeviceParams(accountId, brandId, filters) {
        const result = {};

        Object.keys(filters)
            .filter(key => filters[key] !== '')
            .forEach(key => {
                result[key] = filters[key];
            });

        result.storeBrandId = brandId;
        result.accountId = accountId;

        delete result.accountBrand;
        delete result.filterType;
        delete result.storeIds;

        return result;
    }

    _makeRequest(accountId, brandId, filters) {
        const params = this._getLoadDeviceParams(accountId, brandId, filters);

        return getAllDevices(params);
    }

    async _load(accountId, brandId, filters) {
        this._accounts[accountId] = this._accounts[accountId] || {};

        // avoid double loading
        if (this._accounts[accountId][brandId]) {
            return;
        }

        const request = this._makeRequest(accountId, brandId, filters);

        this._accounts[accountId][brandId] = {
            isLoading: true,
            devices: [],
            error: false,
            request,
        };

        try {
            const devices = await request;
            this._onLoadSuccess(accountId, brandId, devices);
        } catch(err) {
            this._onLoadFailed(accountId, brandId, err);
        }

        this._eventEmitter.trigger('changed');
    }

    _remove(accountId, brandId) {
        if (!this._accounts[accountId] || !this._accounts[accountId][brandId]) {
            return;
        }

        delete this._accounts[accountId][brandId];
    }
}
