import { useEffect, useCallback } from 'react';
import { getUsers } from 'services/Account';
import { useAsync } from 'hooks/useAsync';

export const useLoadUsers = (queryParams) => {
    const {
        run: runLoadUsers,
        isLoading: isUsersLoading = false,
        data: users,
        error: usersLoadingError,
    } = useAsync();

    const loadUsers = useCallback(async (params) => {
        runLoadUsers(getUsers(params).finally(params.onDone));
    }, []);

    useEffect(() => { loadUsers(queryParams); }, []);

    return { users, isUsersLoading, usersLoadingError, loadUsers };
};
