import React from 'react';
import { Trans } from 'react-i18next';

import { Grid } from 'components/Common/Grid';

import { usePeripheralDevices } from '../hooks';

export const PeripheralDevicesList = ({ data }) => {
    const { rows, headers } = usePeripheralDevices({ data });

    return (
        <Grid
            rows={rows}
            headers={headers}
            noRecordsMessage={
                <Trans i18nKey="common__device-peripheral-devices-absence" />
            }
        />
    );
};
