import React from 'react';
import { useTranslation } from 'react-i18next';

import './SnapshotOverwritenStatus.scss';

export const SnapshotOverwritenStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-snapshot-overwriten-cell">
            {t('common__task__status--snapshot-overwriten')}
        </span>
    );
};
