import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewDeviceLink } from 'library/ViewDeviceLink';
import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';

export const Actions = ({ Device_UID, Device_MainVersion, DeviceType_ID, Store_Number, onOverrideCountry }) => {
    const { t } = useTranslation();
    const onClick = useCallback(() => {
        onOverrideCountry(Device_UID);
    }, [Device_UID, onOverrideCountry]);

    if (Store_Number) {
        return (
            <ViewDeviceLink
                device={{
                    Device_UID,
                    Device_DeviceType_ID: DeviceType_ID,
                    Device_MainVersion,
                }}
            />
        );
    } else if (DeviceType_ID == CommonConstants.deviceType.nexeo.id && checkAccess('OverrideCountry')) {
        return (
            <span className="override-country-button" onClick={onClick}>
                {t('admin__systems__action__override-country')}
            </span>
        );
    } else {
        return t('common__na');
    }
};
