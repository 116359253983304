export const formDataValidator = (formConditions, t) => {
    const formErrorConditions = [
        {
            key: 'email2',
            condition: formConditions.email2,
            message: t('api-token-management__error--email2')
        }, {
            key: 'email3',
            condition: formConditions.email3,
            message: t('api-token-management__error--email3')
        }
    ];

    const formError = formErrorConditions.reduce(
            (errors, el) => (el.condition && (errors[el.key] = el.message), errors),
            {}
    );

    return formError;
};
