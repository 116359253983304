import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import './SendUpgradeConfirmPopup.scss';

export const SendUpgradeConfirmPopup = ({ onHide, onConfirm, upgradeTaskData, ...props }) => {
    const {
        modelName = '',
        deviceType = '',
        version = '',
    } = upgradeTaskData;

    const { t } = useTranslation();

    return <ConfirmPopupComponent
        {...props}
        onHide={onHide}
        title={t('common__double-checking')}
        message={t('admin-system-status__action__send-upgrade-zoom--confirm-message', { modelName, deviceType, version })}
        dialogClassName='hme-admin-system-status-upgrade-confirm'
        actions={[{
            children: t('common__no-go-back'),
            onClick: onHide,
        }, {
            children: t('common__continue'),
            variants: ['submit'],
            onClick: () => {
                onConfirm && onConfirm();
                onHide && onHide();
            }
        }]}
    />
};