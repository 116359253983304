/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from '../Modal';

import './ConfirmPopup.scss';

const ConfirmPopup = ({
    show = true,
    title,
    message = '',
    buttons = [
        { label: 'Confirm' },
        { label: 'Close' }
    ],
    dialogClassName = 'confirm-popup',
    ...props
}) => {
    const defaultTitle = i18n.t('common__confirmation-required');
    function customUI({ onClose }) {
        return (
            <Modal
                {...props}
                show={show}
                title={title || defaultTitle}
                actions={buttons.map((btn, i) => {
                    const { label, onClick, ...rest } = btn;
                    return {
                        children: label,
                        onClick: () => {
                            onClick && onClick();
                            onClose();
                        },
                        ...rest
                    };
                })}
                dialogClassName={dialogClassName}
                children={message}
            />
        );
    }
    confirmAlert({ customUI, overlayClassName: 'confirm-popup-overlay' });
};

export default ConfirmPopup;

export const ConfirmPopupComponent = ({
    title,
    message,
    dialogClassName,
    onConfirm,
    onHide,
    cancelVerb,
    confirmationVerb,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t(title || 'confirm-popup__default-title')}
            dialogClassName={classNames('confirm-popup', dialogClassName)}
            actions={[{
                children: cancelVerb || t('common__cancel'),
                onClick: onHide,
            }, {
                children: confirmationVerb || t('common__confirm'),
                variants: ['submit'],
                onClick: () => {
                    onConfirm && onConfirm();
                    onHide && onHide();
                },
            }]}
            onHide={onHide}
            {...props}
        >{message}</Modal>
    );
};

export const ConfirmPopupWithoutCancel = ({
    message,
    dialogClassName,
    onConfirm,
    onHide,
    confirmationVerb,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('confirm-popup__default-title')}
            dialogClassName={classNames('confirm-popup', dialogClassName)}
            actions={[{
                children: confirmationVerb || t('common__confirm'),
                variants: ['submit'],
                onClick: () => {
                    onConfirm && onConfirm();
                    onHide && onHide();
                }
            }]}
            backdrop={false}
            {...props}
        >{message}</Modal>
    );
};

export const ConfirmPopupComponentGeneric = ({
    title,
    actions,
    message,
    onHide,
    ...props
}) => (
    <Modal
        title={title}
        {...props}
        dialogClassName = "confirm-popup"
        actions={actions}
        onHide={onHide}
    >{message}</Modal>
);
