import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationsList } from 'library/NotificationsList';
import { TabPanel, Tab } from 'library/TabPanel';
import { Loader } from 'library/Loader';
import { SidebarOverlay } from 'library/SidebarOverlay';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { useLoadTextConnectGroupedStores } from './hooks/useLoadTextConnectGroupedStores';
import { processSendMessage } from './Controller';
import { validateMessageData } from './validators/message';
import { Header } from './Header';
import { Footer } from './Footer';
import { SendMessage } from './SendMessage';
import { History } from './History';
import { InfoMessage } from './SendMessage/StoresSection/InfoMessage';
import { getStoresTree } from './helpers/getStoresTree';
import { searchTree } from './helpers/searchTree';

import './TextConnect.scss';

const TEXT_CONNECT_TABS = {
    SEND_MESSAGE: 'SEND_MESSAGE',
    HISTORY: 'HISTORY',
};
const defaultTree = [];

export const TextConnect = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(TEXT_CONNECT_TABS.SEND_MESSAGE);

    const [selectedStores, setSelectedStores] = useState([]);
    const [storesTree, setStoresTree] = useState([]);
    const [displayedHierarchy, setDisplayedHierarchy] = useState([]);
    const [storesSearch, setStoresSearch] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [isSidebarOverlayShown, setIsSidebarOverlayShown] = useState(false);
    const [isOnlySelectedStoresShown, setIsOnlySelectedStoresShown] = useState(false);

    const { t } = useTranslation();

    const { groupedStores, isGroupedStoresLoading } = useLoadTextConnectGroupedStores();

    const onCancel = () => {
        navigate(-1);
    };

    const onApply = useCallback(async () => {
        const payload = {
            message,
            storeUids: selectedStores,
        };

        await processSendMessage({ payload, setIsLoading });

        setMessage('');
    }, [message, selectedStores, processSendMessage]);

    const onMessageChange = useCallback(
        (msg) => {
            setMessage(msg);

            const errors = validateMessageData(msg);

            if (errors) {
                return setValidationErrors(errors);
            }

            setValidationErrors(null);
        },
        [validateMessageData],
    );

    useEffect(() => {
        if (groupedStores?.length) {
            setStoresTree(getStoresTree(groupedStores));
        }
    }, [groupedStores]);

    useEffect(() => {
        setDisplayedHierarchy(searchTree(storesTree || defaultTree, storesSearch));
    }, [storesTree, storesSearch]);

    const toggleSidebarVisibility = useCallback(() => setIsSidebarOverlayShown((current) => !current), []);

    const isFooterShown = activeTab === TEXT_CONNECT_TABS.SEND_MESSAGE;
    const isSubmitEnabled = !!(selectedStores.length && message.trim() && !validationErrors);

    return (
        <div className="hme-components text-connect-wrapper">
            <NotificationsList />
            {isLoading || isGroupedStoresLoading ? (
                <Loader variants={['invert']} />
            ) : (
                <div className="text-connect">
                    <div className="text-connect__container">
                        <SidebarOverlay
                            title="text-n-connect__info-message__store-requirements"
                            icon={<InfoIcon />}
                            isShown={isSidebarOverlayShown}
                            toggleShown={toggleSidebarVisibility}
                            variants={['right']}
                        >
                            <InfoMessage />
                        </SidebarOverlay>
                        <div className="text-connect__content">
                            <Header title={'text-n-connect_title'} onClick={toggleSidebarVisibility} />
                            <TabPanel
                                activeTab={activeTab}
                                onActiveTabChange={setActiveTab}
                                className={`text-connect__${activeTab.toLowerCase()}`}
                            >
                                <Tab
                                    text={t('text-n-connect_tabs--send-message')}
                                    value={TEXT_CONNECT_TABS.SEND_MESSAGE}
                                >
                                    <SendMessage
                                        storesSearch={storesSearch}
                                        groupedStores={displayedHierarchy || defaultTree}
                                        isOnlySelectedStoresShown={isOnlySelectedStoresShown}
                                        toggleSelectedStoresOnly={setIsOnlySelectedStoresShown}
                                        selectedStores={selectedStores}
                                        onGroupStoreChange={setSelectedStores}
                                        message={message}
                                        validationErrors={validationErrors}
                                        onMessageChange={onMessageChange}
                                        onInfoClick={toggleSidebarVisibility}
                                        onSearchChange={setStoresSearch}
                                    />
                                </Tab>
                                <Tab text={t('text-n-connect_tabs--history')} value={TEXT_CONNECT_TABS.HISTORY}>
                                    <History />
                                </Tab>
                            </TabPanel>
                        </div>
                    </div>
                    {isFooterShown && (
                        <Footer isSubmitEnabled={isSubmitEnabled} onCancel={onCancel} onApply={onApply} />
                    )}
                </div>
            )}
        </div>
    );
};
