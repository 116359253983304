/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import "./AvatarBox.css";
import AvatarPopover from "./AvatarPopover";
import { avatarsApi, storesApi } from "../../api/avatarApi";
import AuthenticationService from "../../components/Security/AuthenticationService";
import { Config } from "../../Config";

import { getErrorMessage } from "../../components/LeaderBoard/helpers/responseHandler";

// when all files will be moved move postfix /$web to blibNitroStorageBaseURl variable
const blobStorageUrl = `${Config.blobNitroStorageBaseUrl}/$web`;
const avatarPageLoader = `${blobStorageUrl}/avatars/loader.png`;

class AvatarBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      groups: [],
      avatarImages: [],
      isOpen: false,
      selectedStore: null,
      selectedGroup: null,
      error: {}
    };

    this.show = this.show.bind(this);
    this.getTree = this.getTree.bind(this);
    this.getArray = this.getArray.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.assignAvatar = this.assignAvatar.bind(this);
    this.renderAvatar = this.renderAvatar.bind(this);
    this.getAvatarImagePath = this.getAvatarImagePath.bind(this);
    this.initialize = this.initialize.bind(this);
    this.setError = this.setError.bind(this);

    this.authService = new AuthenticationService(Config.authBaseUrl);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    // eslint-disable-next-line no-undef
    window.addEventListener("resize", this.updateWindowDimensions);

    await this.initialize();
  }

  componentWillUnmount() {
    // unsubscribe the event listener.
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async initialize() {
    this.setState({ isLoading: true });
    let avatarData = {};
    let groupedStores = {};

    try {
      ([avatarData, groupedStores] = await Promise.all([avatarsApi.get(), storesApi.get()]));
    } catch (error) {
      this.setError('avatars__error--missing-avatar', error);
      this.setState({ isLoading: false });
    }

    if (!avatarData.data || !groupedStores) {
      this.setError('avatars__error--missing-avatar');
    }

    this.setState({
      isLoading: false,
      avatarImages: avatarData.data || [],
      groups: groupedStores.data || [],
    });
  }

  getAvatarImagePath(avatarID) {
    // Create the avatar url from avatarID.
    const { avatarImages } = this.state;
    const avatarFileNameIndex = avatarImages.findIndex(avatarObj => avatarObj.AvatarID === avatarID);
    const avatarFileName = avatarImages[avatarFileNameIndex].AvatarFileName;
    return `${blobStorageUrl}/avatars/${avatarFileName}`;
  }

  getArray(data, group) {
    return data.map(store => {
      return (
        <div className="avatar-wrapper" key={store.StoreNumber}>
          <div className="store-name"> {store.StoreName || store.StoreNumber}</div>
          <div className="center-dots">
            <div className="item green blue purple" />
          </div>
          <div className="avatar-img">
            <a onClick={() => this.show(store.StoreNumber, group)} href="#">
              <img className={`img-fluid avatar-image`} src={this.getAvatarImagePath(store.AvatarID)} />
            </a>
            <div className={this.state.selectedStore !== store.StoreNumber ? " hidden" : "show-line"} />
          </div>
        </div>
      );
    });
  }

  getTree() {
    const { groups } = this.state;

    return groups.map(group => {
      return (
        <div key={group.GroupID}>
          <h3 className="store-group"> {group.GroupName} </h3>
          {this.getArray(group.Stores, group.GroupID)}
        </div>
      );
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  async assignAvatar(avatarID) {
    const { groups, selectedGroup, selectedStore } = this.state;

    const groupIndex = groups.findIndex(group => selectedGroup === group.GroupID);
    const storeList = groups[groupIndex].Stores;
    const index = storeList.findIndex(store => selectedStore === store.StoreNumber);
    groups[groupIndex].Stores[index].AvatarID = avatarID;

    const store = groups[groupIndex].Stores[index];
    this.hide();

    const { StoreUID } = store;
    const assignmentFailedError = StoreUID ? 'avatars__error--assignment-failed-store': '';
    const errorPayload = StoreUID ? { storeUID: StoreUID } : null;

    // Get the user for logging the action.
    let MasqueradeUser = this.authService.getMasqueradeAdminUser();
    avatarsApi
      .update({ storeUID: StoreUID, avatarID, MasqueradeUser })
      .then(result => {
        if (result.status) {
          this.setState({ groups });
        } else {
          this.setError(assignmentFailedError, null, errorPayload);
        }
      })
      .catch(error => {
        this.setError(assignmentFailedError, error, errorPayload);
      });
  }
  setError(errorMessage, serverResponse, payload) {
    const error = {
      status: true,
      message: getErrorMessage(errorMessage, serverResponse),
      payload,
    };

    this.setState({ error });
    window.setTimeout(() => {
      this.setState({ error: {} });
    }, 3000);
  }

  show(store, group) {
    this.setState({ isOpen: true, selectedStore: store, selectedGroup: group });
  }

  hide() {
    this.setState({ isOpen: false });
  }

  renderAvatar(avatar) {
    return (
      <div key={avatar} className='hme-avatarbox__item'>
        <a href="#">
          <img className="list-images" alt="avatar-image" src={this.getAvatarImagePath(avatar)} onClick={() => this.assignAvatar(avatar)} />
        </a>
      </div>
    );
  }

  // Loader for avatar assigment page.
  showLoaderMonster() {
    return <img className="swing" src={avatarPageLoader} />;
  }

  render() {
    const { t } = this.props;
    const { error, avatarImages, width, height, isLoading } = this.state;

    const AvatarPanel = () => isLoading
        ? this.showLoaderMonster()
        : avatarImages.length
          ? this.getTree()
          : <h3 className="no-records-message">{t('common__error--no-records-found')}</h3>;

    return (
      <div className="container-fluid">
        <main role="main" className="edit-avatar-page">
          <h3 className="border-bottom border-gray pb-2 mb-1">{t('sub-header--lb__avatars')}</h3>
          <h5 className="border-gray">{t('common__leaderboard-stores')}</h5>
          {error.status && (
            <div className="alert alert-danger avatar-alert" role="alert">
              {t(error.message, error.payload)}
            </div>
          )}
          <div className="panel panel-default avatar-box">
            <div className="panel-body">
                <AvatarPanel />
            </div>
          </div>
          <div>
            <AvatarPopover visible={this.state.isOpen} onClose={this.hide.bind(this)} width={(width * 2) / 3} height={(height * 2) / 3}>
              <div className='hme-avatarbox__content'>
                {avatarImages.map(avatar => {
                  return this.renderAvatar(avatar.AvatarID);
                })}
              </div>
            </AvatarPopover>
          </div>
        </main>
      </div>
    );
  }
}

export default compose(withTranslation())(AvatarBox);
