import React from 'react';
import classNames from 'classnames';

import './Loader.scss';

export const Loader = ({ variants = [], className = '', ...props }) => (
    <div {...props} className={classNames(
        'hme-loader-icon',
        variants.map(variant => `hme-loader-${variant}-icon`),
        className
    )}></div>
);