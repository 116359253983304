import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { compose, cond, T } from 'ramda';
import { Trans } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { InfoSection } from 'pages/SystemStatus/Common/InfoSection';
import { DeviceStatus } from 'library/DeviceStatus';
import { StoreNumberLink } from 'library/StoreNumberLink';
import { Address } from 'library/Address';
import { deviceConfig } from 'constants/device';
import { Header } from 'pages/SystemStatus/Common/Header';
import { PageTitle } from 'pages/SystemStatus/Common/PageTitle';
import { useDevicesGrid } from './hooks';
import { DevicesGrid } from 'pages/SystemStatus/Common/DevicesGrid';
import { getStoreCountryName } from 'pages/SystemStatus/Common/helpers';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { useLoadDevice } from 'pages/SystemStatus/Common/hooks/useLoadDevice';
import { useLoadPeripheralDevices } from 'pages/SystemStatus/Common/hooks/useLoadPeripheralDevices';
import { DeviceInfoLoading } from 'pages/SystemStatus/Common/DeviceInfoLoading';
import { DeviceInfoError } from 'pages/SystemStatus/Common/DeviceInfoError';

const { getLaneType } = deviceConfig;

export const getDeviceStatusInfoFields = ({ device, storeCountry }) => {
    if (!device) return [];

    return [
        {
            label: <Trans i18nKey="common__status" />,
            children: <DeviceStatus
                online={device.Device_IsActive}
                isPreconfigured={device.Device_IsPreconfigured}
                deviceType={device.Device_DeviceType_ID}
                name={device.Device_Name || device.DeviceType_Name}
                version={device.Device_MainVersion}
                deviceUid={device.Device_UID}
                hideDeviceName
            />
        },
        {
            label: <Trans i18nKey="common__store__name" />,
            children: device.Store_Name || '-'
        },
        {
            label: <Trans i18nKey="common__store__number--text" />,
            children: <StoreNumberLink storeUID={device.Store_UID} as="span">{device.Store_Number}</StoreNumberLink>
        },
        {
            label: <Trans i18nKey="common__brand" />,
            children: device.Brand_Name || ''
        },
        {
            label: null,
            children: <Address
                value={{
                    line1: device.Store_AddressLine1,
                    line2: device.Store_AddressLine2,
                    line3: device.Store_AddressLine3,
                    line4: device.Store_AddressLine4,
                    city: device.Store_Locality ?? '',
                    zipCode: device.Store_PostCode ?? '',
                    region: device.Store_Region ?? '',
                    country: storeCountry
                }}
                isReadOnly
            />
        },
        {
            label: <Trans i18nKey="common__account__email" />,
            children: <span>{device.User_EmailAddress}</span>
        },
        {
            label: <Trans i18nKey="common__timezone" />,
            children: device.Timezone || '-'
        },
        {
            label: <Trans i18nKey="system-status__lane-config" />,
            children: getLaneType(device.Device_LaneConfig_ID)
        },
        {
            label: <Trans i18nKey="common__settings__version" />,
            children: device.Device_SettingVersion
        }
    ];
};

const SystemStatusUI = ({ device, peripheralDevices, countries }) => {
    const title = useMemo(() => (
        <PageTitle
            deviceTypeTranslation="common__device__type--nexeo"
            storeName={device?.Store_Name}
            storeNumber={device?.Store_Number}
        />
    ), [device]);

    const storeCountry = useMemo(() => getStoreCountryName(device, countries), [device, countries]);

    const StatusInfoFields = getDeviceStatusInfoFields({ device, storeCountry });

    const deviceGridProps = useDevicesGrid({ device, peripheralDevices });

    return <div className="hme-system-status">
        <Header title={title} />

        <div className="hme-system-status__content">
            <InfoSection
                Fields={StatusInfoFields}
                className="hme-system-status__section-item"
            />

            <DevicesGrid
                {...deviceGridProps}
                className="hme-system-status__section-item hme-system-status__device-grid"
            />
        </div>
    </div>;
};

const SystemStatusComponent = cond([
    [({ isLoading }) => isLoading, () => <DeviceInfoLoading />],
    [({ error }) => error !== null, () => <DeviceInfoError />],
    [T, (props) => <SystemStatusUI {...props} />]
]);

const SystemStatusLoader = () => {
    const { duid } = useParams();

    const { countries, isCountriesLoading } = useLoadCountries();
    const { device, isDeviceLoading, deviceLoadingError } = useLoadDevice(duid);
    const {
        peripheralDevices,
        isPeripheralDevicesLoading,
        peripheralDevicesLoadingError
    } = useLoadPeripheralDevices(duid);

    const isDataLoading = isDeviceLoading || isPeripheralDevicesLoading || isCountriesLoading;

    const error = deviceLoadingError || peripheralDevicesLoadingError;

    return <SystemStatusComponent
        device={device}
        countries={countries}
        peripheralDevices={peripheralDevices}
        error={error}
        isLoading={isDataLoading}
    />;
};

export const SystemStatus = compose(
        withHMELayout({
            contentClasses: ['public-zoom-nexeo-system-status-page']
        })
)(SystemStatusLoader);
