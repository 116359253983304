import { useReducer, useEffect } from 'react';

import { useSafeDispatch } from 'hooks/useSafeDispatch';
import { CUSTOMER_NOTIFICATION_TYPE_IDS } from 'constants/customerNotifications';

import { formReducer } from './reducer';

export const useForm = ({ notification, onNotificationChange }) => {
    const [notificationState, unsafeDispatch] = useReducer(formReducer, [], () => {
        return {
            isEnabled: notification.isEnabled,
            type: Number(notification.customerNotificationTypeID || CUSTOMER_NOTIFICATION_TYPE_IDS.banner),
            texts: Object.entries(notification.texts).map(([langKey, value]) => ({ langKey, value })),
            initialNotification: notification
        };
    });

    const dispatch = useSafeDispatch(unsafeDispatch);

    useEffect(() => {
        onNotificationChange({
            ...notificationState,
            texts: notificationState.texts.reduce((textAccumulator, { langKey, value }) => {
                textAccumulator[langKey.toLowerCase()] = value;

                return textAccumulator;
            }, {})
        });
    }, [notificationState, onNotificationChange]);


    return [notificationState, dispatch];
};
