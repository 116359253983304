import React from 'react';
import { Loader as LoaderIcon } from 'library/Loader';
import { Label } from 'library/Label';

import './Loader.scss';

export const Loader = ({ loadingMessage }) => (
    <div className="hme-auto-complete-suggestions-loader">
        <LoaderIcon variants={['invert']} />
        <Label>{loadingMessage}</Label>
    </div>
);
