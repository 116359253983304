import { CommonConstants } from 'Constants';
import { isDistributor } from 'services/Auth';
import { checkAccess } from 'actions/permissions';

const externalPermissions = [
    CommonConstants.externalPermissions.ManageRoles,
    CommonConstants.externalPermissions.ManageUsers
];

export const checkBaseAccessForAccounts = () => {
    return isDistributor() && externalPermissions.some((permission) => checkAccess(permission));
};
