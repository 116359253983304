/* eslint-disable valid-jsdoc */
import { useEffect } from 'react';

/**
 *  Hook that handles reference scrolling
 */
export const useOutsideScroll = (scrollableRef, handler, watching) => {
    useEffect(() => {
        if (scrollableRef.current) {
            scrollableRef.current.addEventListener('scroll', handler);
        }
        return () => {
            if (scrollableRef.current) {
                scrollableRef.current.removeEventListener('scroll', handler);
            }
        };
    }, [watching]);
};
