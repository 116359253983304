import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ADMIN_ROUTES } from 'constants/routes';
import { Title } from 'library/Title';
import { Button } from 'library/Button';

export const Header = () => {
    const { t } = useTranslation();

    return <div className="hme-token-management__header">
        <Title>{t('admin__token-management__page-title')}</Title>
        <Button
            as={Link}
            to={ADMIN_ROUTES.createAPIToken}
            className="hme-token-management__create-token-btn"
        >
            {t('admin__token-management__create-new-token')}
        </Button>
    </div>;
};
