import PropTypes from 'prop-types';

const TreeItem = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.any,
};

TreeItem.children = PropTypes.arrayOf(PropTypes.shape(TreeItem));

export const TreeItemProp = PropTypes.shape(TreeItem);
export const TreeItemsProp = PropTypes.arrayOf(TreeItemProp);
