import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';

import './Header.scss';

export const Header = () => {
    const { t } = useTranslation();

    return (
        <div className='hme-notifications-center__header'>
            <Title>{t('notifications-center__title')}</Title>
        </div>
    );
};
