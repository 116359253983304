import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components/Inputs';
import { Grid } from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { useScreenType } from 'globalState/GlobalStateProvider';

import { getMetricText } from '../helpers/getMetricText';
import { groupRounds } from './helpers/groupRounds';
import { getRows } from './helpers/getRows';
import { filterGroups } from './helpers/filterGroups';
import { sortGroups } from './helpers/sortGroups';
import { defaultSorting, MOBILE_SORT_OPTIONS } from '../Constants';
import { NoResults } from '../NoResults';

import './Round.scss';

export const Round = ({ rounds, isResultsLoading, metric }) => {
    const screenType = useScreenType();

    const [searchValue, setSearchValue] = useState('');
    const [searchString, setSearchString] = useState('');
    const [sortSelection, setSortSelection] = useState(defaultSorting);
    const { t } = useTranslation();

    const gridHeaders = useMemo(() => {
        if (!rounds || !rounds.length) {
            return [];
        }

        const metricText = getMetricText(metric);

        if (!screenType.isDesktop) {
            return [
                {
                    column: [
                        { text: 'contest__form__results__round__header--round', property: 'round' },
                        { text: 'dashboard__store-rank', property: 'rank' },
                        { text: 'common__store', property: 'store' }
                    ]
                },
                {
                    column: [
                        { text: metricText, property: 'metricString' },
                        { text: 'common__points', property: 'points' }
                    ]
                }
            ];
        }

        return [{
            text: 'contest__form__results__round__header--round',
            property: 'round',
            className: 'hme-grid-centered-cell',
            headerClassName: 'hme-grid-centered-cell',
            flex: 0.5,
        }, {
            text: 'dashboard__store-rank',
            property: 'rank',
            sortable: true,
            className: 'hme-grid-centered-cell hme-grid-cell--visible',
            headerClassName: 'hme-grid-centered-cell',
            flex: 1.5
        }, {
            text: 'common__store',
            property: 'store',
            flex: 2,
        }, {
            text: metricText,
            property: 'metricString',
            className: 'hme-grid-centered-cell',
            headerClassName: 'hme-grid-centered-cell',
        }, {
            text: 'common__points',
            property: 'points',
            className: 'hme-grid-centered-cell',
            headerClassName: 'hme-grid-centered-cell',
        }];
    }, [rounds && rounds[0] && rounds[0].roundsData, metric, screenType.isDesktop]);

    const groupedRounds = useMemo(() => rounds.length ? groupRounds(rounds) : [], [rounds]);

    const rows = useMemo(() => {
        const searchedRounds = searchString ? filterGroups(groupedRounds, searchString) : groupedRounds;
        const sortedRounds = sortGroups(searchedRounds, sortSelection);

        return getRows(sortedRounds);
    }, [groupedRounds, searchString, sortSelection]);

    const onSearchValueChange = useCallback((newValue) => {
        setSearchValue(newValue);

        if (newValue.length > 2) {
            setSearchString(newValue);
        }

        if (newValue.length === 0) {
            setSearchString('');
        }
    }, []);

    const onSearckKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            setSearchString(searchValue);
        }
    }, [searchValue]);

    if (!isResultsLoading && !rounds?.length) {
        return (
            <div className='hme-contest-view-edit-form__results__overall'>
                <NoResults />
            </div>
        );
    }

    return (
        <div className='hme-contest-view-edit-form__results__rounds'>
            <SearchInput
                value={searchValue}
                onChange={onSearchValueChange}
                placeholder={t('contest__form__results__overall__search-placeholder')}
                showClearIcon={true}
                onKeyDown={onSearckKeyDown}
            />
            {screenType.isDesktop ? (
                <Grid
                    headers={gridHeaders}
                    rows={rows}
                    isLoading={isResultsLoading}
                    rowKey='key'
                    noRecordsMessage={t('common__no-stores--found')}
                    sortSelection={sortSelection}
                    onSortChange={setSortSelection}
                />
             ) : (
                <TileList
                    headers={gridHeaders}
                    rows={rows}
                    isLoading={isResultsLoading}
                    rowKey="key"
                    sortOptions={MOBILE_SORT_OPTIONS}
                    noRecordsMessage={t('common__no-stores--found')}
                    sortSelection={sortSelection}
                    onSortChange={setSortSelection}
                    sortButtonName="common__sort"
                />
            )}
        </div>
    );
};
