import React, { Component } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';

class TextInput extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.searchClearHandler = this.searchClearHandler.bind(this);
  }

  onChange({ target: { value } }) {
    const { onChange, type } = this.props;
    // trim input on the left
    const trimmedValue = value.replace(/^\s+/, '');
    const firstWord = trimmedValue.split(' ')[0];
    const result = type === 'search' ? firstWord : trimmedValue;
    onChange(result);
  }

  searchClearHandler() {
    const { onChange } = this.props;
    onChange('');
  }

  render() {
    const { name, label, value, className, type, inputclassname, placeholder } = this.props;

    return (
      <div className={classNames(className, 'hme-input', 'text-input')}>
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <input
          {...R.omit(['className', 'inputclassname', 'label', 'type', 'placeholder'], this.props)}
          className={inputclassname}
          id={name}
          type={type || 'text'}
          placeholder={placeholder}
          onChange={this.onChange}
        />
        {(type === 'search' && value) && (
          <label
            onClick={this.searchClearHandler}
            className="hme-input__search-clear fa fa-trash"
          />
        )}
      </div>
    );
  }
}

export { TextInput };
