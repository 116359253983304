export const sortUsers = (users, sortSelection) => {
    const property = Object.keys(sortSelection)[0];

    if (!property) {
        return users;
    }

    const direction = sortSelection[property];
    const sortRows = [...users];

    return sortRows.sort((user1, user2) => {
        const value1 = user1[property];
        const value2 = user2[property];

        if (value1 === value2) {
            return 0;
        }

        return value1.localeCompare(value2) > 0 ? direction : -direction;
    });
};
