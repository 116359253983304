import { path, assocPath } from 'ramda';

export const trimValues = (obj, keys) => {
    const result = {
        ...obj
    };

    return keys.reduce((accum, key) => {
        const keyPath = key.split('.');

        const value = path(keyPath, obj);

        if (typeof value !== 'string') {
            return accum;
        }

        return assocPath(keyPath, value.trim(), accum);
    }, result);
};
