import { useState, useEffect, useCallback } from 'react';
import { getDeviceRecentUpgradeTasks } from '../Controller';

export const useLoadDeviceRecentUpgradeTasks = (deviceUID) => {
    const [deviceRecentUpgradeTasks, setDeviceRecentUpgradeTasks] = useState(null);
    const [isDeviceRecentUpgradeTasksLoading, setIsDeviceRecentUpgradeTasksLoading] = useState(true);
    const [deviceRecentUpgradeTasksLoadingError, setDeviceRecentUpgradeTasksLoadingError] = useState(null);

    const loadDeviceRecentUpgradeTasks = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceRecentUpgradeTasksLoading(true);
            setDeviceRecentUpgradeTasksLoadingError(null);
            const upgradeTasks = (await getDeviceRecentUpgradeTasks(duid)) || null;

            setDeviceRecentUpgradeTasks(upgradeTasks);
        } catch (e) {
            setDeviceRecentUpgradeTasks(null);
            setDeviceRecentUpgradeTasksLoadingError(e);
        }

        setIsDeviceRecentUpgradeTasksLoading(false);
    }, []);

    useEffect(() => {
        loadDeviceRecentUpgradeTasks(deviceUID);
    }, [deviceUID]);

    return {
        deviceRecentUpgradeTasks,
        isDeviceRecentUpgradeTasksLoading,
        deviceRecentUpgradeTasksLoadingError,
        loadDeviceRecentUpgradeTasks,
    };
};
