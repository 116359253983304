import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './ResetWidget.scss';

export const ResetWidget = ({
    Title,
    Rules,
    FormBody,
    FormFooter,
    className = '',
    onSubmit = () => {}
}) => {
    const { t } = useTranslation();

    return (
        <article className={classNames('hme-reset-password', className)}>
            <div className="hme-reset-password__header">{Title}</div>

            <div className="hme-reset-password__content">
                <>
                    <div className="hme-reset-password__info">
                        <div className="hme-reset-password__info-title">{t('my-account__password-requirements')}</div>
                        <div className="hme-reset-password__rules">{Rules}</div>
                    </div>
                    <form className="hme-reset-password__form" onSubmit={onSubmit}>
                        {FormBody}

                        <div className="hme-reset-password__form-footer">{FormFooter}</div>
                    </form>
                </>
            </div>
        </article>
    );
};
