import React, { useState, useEffect } from 'react';

import { CommonErrorModal } from './CommonErrorModal';
import { RowErrorModal } from './RowErrorModal';

import './UploadErrorModal.scss';

export const UploadErrorModal = ({ errors, onHide, onTryAgain, ...modalProps }) => {
    const [commonErrors, setCommonErrors] = useState([]);
    const [rowErrors, setRowErrors] = useState([]);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        setCommonErrors(errors.filter(({ isGeneral }) => isGeneral));
        setRowErrors(errors.filter(({ isGeneral }) => !isGeneral));
    }, [errors, setCommonErrors, setRowErrors]);

    useEffect(() => {
        setActions([{
            children: 'Close',
            onClick: onHide,
        }, {
            children: 'Try Again',
            onClick: onTryAgain,
            variants: ['submit'],
        }]);
    }, [onHide, onTryAgain]);

    return (
        <>
            {
                commonErrors.length ?
                    <CommonErrorModal errors={commonErrors} actions={actions} onHide={onHide} {...modalProps} /> :
                    <RowErrorModal errors={rowErrors} actions={actions} onHide={onHide} {...modalProps} />
            }
        </>
    );
};
