import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import "../Tiles.css";
import "./StoreRankingTile.css";
import * as actions from "../../../actions/dynamicHomeScreen";

const changeBadgeColor = (colorHex) => {
  return (
    <svg height="20" width="20">
      <circle cx="10" cy="10" r="10" fill={colorHex} />
    </svg>
  );
}

class StoreRankingTile extends Component {
  // fetch store ranking data from the Service
  componentDidMount() {
    this.props.getStoreRanking({});
  }

  // Logic to change the color dynamically

  // Render store rows
  renderStores (stores) {
    const { t } = this.props;

    return stores.map(store => {
      const number = store.storeNumber;
      const rank = store.storeRank;
      const name = store.storeName;
      const goal = store.GoalA;
      const averageTimeInSec = store.avg;

      let goalColor;
      const preferences = this.props.userPreferences;
      let ltGoalAColor, ltGoalBColor, gtGoalBColor;
      if (preferences && preferences.colors) {
        const colors = preferences.colors.split("|");
        ltGoalAColor = colors[0];
        ltGoalBColor = colors[1];
        gtGoalBColor = colors[2];
      }

      if (averageTimeInSec < store.GoalA) {
        goalColor = ltGoalAColor;
      } else if (averageTimeInSec < store.GoalB) {
        goalColor = ltGoalBColor;
      } else {
        goalColor = gtGoalBColor;
      }

      let storeTitle;

      if (name) {
        storeTitle = (
          <div className="col-md-5 col-5">
            <small className="Label_GrayLight_Small gray-62">#{number}</small>
            <p className="H4_Left text-overflow-ellipsis" title={name}>
              {name}
            </p>
          </div>
        );
      } else {
        storeTitle = (
          <div className="col-md-5 col-5">
            <p className="H4_Left">#{number}</p>
          </div>
        );
      }

      return (
        <div className="row rankrow" key={number}>
          <div className="col-md-3 col-3 H1_Left black text-center store-ranking-rank-size">{rank}</div>
          {storeTitle}
          <div className="store-ranking-inline-goal">
            <div className="store-ranking-tile-vertical-center">{changeBadgeColor(goalColor)}</div>
            <div className="store-ranking-tile-vertical-center">
              <small className="P2_Left">{averageTimeInSec}</small>
              <br />
              <small className="Label_GrayLight_Small gray-62">
                {
                  // TODO: use css text-transform to get uppercase
                }
                {t('common__goal').toUpperCase()}&nbsp;
                {goal}
              </small>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const { storeRanking } = this.props;
    const { t } = this.props;

    return (
      <div className="tile-box">
        <div className="tile__box-supporting-container">
          <h3 className="tile__title">{t('welcome__tile--store-ranking__title')}
            <br />
            <span className="H4_Left">
              {t('welcome__tile--store-ranking__header')}
            </span>
          </h3>
          {(!!storeRanking.topThree.length && (
            <div>
              <div className="text-center">
                <p className="Label_GrayDark_Large gray-62 text-uppercase">
                  {t('welcome__tile--store-ranking__top-stores', { count: 3 })}
                </p>
              </div>
              <div className="tile-box-supporting-container">{this.renderStores(storeRanking.topThree)}</div>
            </div>
          ))}
          {(!!storeRanking.bottomThree.length && (
            <div>
              <div className="text-center">
                <p className="Label_GrayDark_Large gray-62 text-uppercase">{t('welcome__tile--store-ranking__bottom-stores', { count: 3 })}</p>
              </div>
              <div className="tile-box-supporting-container">{this.renderStores(storeRanking.bottomThree)}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeRanking: state.dynamicHomeScreen.storeRanking,
    userPreferences: state.dynamicHomeScreen.userPreferences
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getStoreRanking: data => {
      actions.getStoreRanking(dispatch, data);
    },
  };
};

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(StoreRankingTile);
