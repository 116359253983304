import React from 'react';

import { CenterMessage } from '../CenterMessage';

import { ReactComponent as FailureIcon } from '../../../../assets/icons/errorIcon.svg';

export const CenterErrorMessage = ({ children }) => (
    <CenterMessage
        icon={<FailureIcon className="center-message-icon-large mb-4" />}
    >
        {children}
    </CenterMessage>
);
