import React, { useMemo } from 'react';
import { cond, T } from 'ramda';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';

import './TreeText.scss';

const GroupText = ({ item }) => {
    return (
        <div className='hme-view-user__access__tree-text hme-view-user__access__tree-text--group'>
            {item.Name}
        </div>
    );
};

const StoreText = ({ item }) => {
    const storeName = useMemo(() => {
        const separator = item.StoreNumber && item.Name ? '-' : '';

        return `${item.StoreNumber || ''}${separator}${item.Name || ''}`;
    }, [item]);

    return (
        <>
            <div className='hme-view-user__access__tree-text hme-view-user__access__tree-text--store'>
                <span className='hme-view-user__access__tree-text__store-name'>{storeName}</span>
                <span className='hme-view-user__access__tree-text__brand'>{item.Brand}</span>
            </div>
        </>
    );
};

export const TreeText = cond([
    [({ item }) => item.Type === storeHierarchyNodeTypes.GROUP, GroupText],
    [({ item }) => item.Type === storeHierarchyNodeTypes.STORE, StoreText],
    [T, () => null]
]);
