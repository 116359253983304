import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { getDataAsync, postDataAsync } from '../Api';

export const getLBSettingTemplate = async (templateUID) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBSettingTemplate;

    const response = await getDataAsync(`${url}?templateUID=${templateUID}`);

    return response.status ? response.data : [];
};

export const setLBSettingTemplate = async (data) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.setLBSettingTemplate;

    const response = await postDataAsync(url, data);

    return response.status ? response.data : (response && response.msg == 'TemplateNameDuplicate') ? 'TemplateNameDuplicate' : [];
};

export const getLanguages = async () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBTemplateLanguages;

    const response = await getDataAsync(url);

    return response.status ? response.data : [];
};
