import React, { useCallback } from 'react';

import { useResetPasswordRules } from 'hooks/useResetPassword';
import { ResetWidget } from 'library/Password';

import { Rules } from './Rules';
import { Title } from './Title';
import { Body } from './Body';
import { Footer } from './Footer';

import './ChangePasswordWidget.scss';


const DEFAULT_FIELDS = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};

export const ChangePasswordWidget = ({
    fields = DEFAULT_FIELDS,
    onFieldsChange,
    isPasswordChangeInProgress,
    onSubmit,
    onCancel
}) => {
    const { currentPassword, newPassword, confirmNewPassword } = fields;

    const { isAllValid, isSomeInvalid } = useResetPasswordRules({ newPassword, confirmNewPassword });

    const isSubmitDisabled = !currentPassword || !isAllValid;

    const handleSubmit = useCallback(
            async (evt) => {
                evt.preventDefault();

                if (!isSubmitDisabled || !isPasswordChangeInProgress) {
                    try {
                        await onSubmit();
                        onCancel();
                    } catch (err) {
                        // Do nothing when password update was unsuccessful
                    }
                }
            },
            [isSubmitDisabled, isPasswordChangeInProgress, onSubmit, onCancel]
    );
    
    return (
        <ResetWidget
            Title={<Title />}
            Rules={<Rules newPassword={newPassword} confirmNewPassword={confirmNewPassword} />}
            FormBody={<Body
                newPassword={newPassword}
                confirmNewPassword={confirmNewPassword}
                currentPassword={currentPassword}
                isPasswordChangeInProgress={isPasswordChangeInProgress}
                onFieldsChange={onFieldsChange}
                isAllValid={isAllValid}
                isSomeInvalid={isSomeInvalid}
            />}
            FormFooter={<Footer
                isSubmitDisabled={isSubmitDisabled}
                isPasswordChangeInProgress={isPasswordChangeInProgress}
                onCancel={onCancel}
            />}
            onSubmit={!isSubmitDisabled ? handleSubmit : (e) => {e.preventDefault()} }
            className="hme-change-password-form"
        />
    );
};
