export const CommonConstants = {
    apiUrls: {
        getGroupHierarchyTree: 'api/group/getAll',
        getSavedTemplates: 'api/reportTemplate/getAll',
        generateReport: 'api/report/generatereport',
        generateNewReport: 'api/newReports/generatereport',
        getSavedTemplateData: 'api/reportTemplate/get',
        deleteTemplate: 'api/reportTemplate/delete',
        createTemplate: 'api/reportTemplate/create',
        getAvailableGroups: 'api/group/availabledetails',
        editGroupDetails: 'api/group/editgroup',
        moveItems: 'api/group/move',
        addNewGroup: 'api/group/creategroup',
        addNewGroupByName: 'api/group',
        editGroupByName: 'api/group',
        deleteGroup: 'api/group/deletegroup',
        exportGroup: 'api/group/export',
        importGroup: 'api/group/import',
        auth: 'api/auth/login',
        getUserRoles: 'api/role/getAll',
        createUser: 'api/user/create',
        updateUser: 'api/user/update',
        getUser: 'api/user/get',
        deleteUser: 'api/user/delete',
        getAudit: 'api/user/getAudit',
        logUserOut: 'api/user/logUserOut',
        getStores: 'api/store/getAllStores',
        getAdminViewStores: 'api/store/getAdminViewStores',
        storeClassificationBase: 'api/store-classification',
        getSettingsDevices: 'api/store/settingsDevices',
        getDeviceUpgradeDate: 'api/device/getDeviceUpgradeDate',
        getUnregisteredDevices: 'api/device/getAllUnregisterdDevices',
        getAdminDevices: 'api/device/getAdminDevices',
        searchUserAccounts: 'api/device/searchUserAccounts',
        getAccountBrandDevices: 'api/device/getAccountBrandDevices',
        getMasterSettings: 'api/store/getMasterSettings',
        saveMasterSettings: 'api/store/saveMasterSettings',
        applyMasterSettings: 'api/store/applyMasterSettings',
        getStoreSystemTiers: 'api/store/systemTiers',
        getRolesList: 'api/role/get',
        getRoleById: 'api/role/getByID',
        createRole: 'api/role/create',
        updateRole: 'api/role/update',
        deleteRole: 'api/role/delete',
        getPermissions: 'api/permission/getAll',
        getPermissionsList: 'api/permission/getById',
        getUsers: 'api/user/getUsers',
        getCompanyUsers: 'api/user/getCompanyUsers',
        getAccountInfo: 'api/account/getAccountDetails',
        updateBrandSharing: 'api/account/brands',
        getCompany: 'api/company/getCompanyDetails',
        getDistributor: 'api/company/getDistributorList',
        getCountry: 'api/country/getAll',
        getBrands: 'api/brand/getall',
        getBrand: brandID => `api/brand/${brandID}`,
        getBrandDeviceUpgradeVersions: brandID => `api/brand/${brandID}/deviceUpgradeVersions`,
        saveBrandDeviceUpgradeVersions: brandID => `api/brand/${brandID}/deviceUpgradeVersions`,
        getBrandAllDeviceUpgradeVersions: 'api/brand/allDeviceUpgradeVersions',
        getAdminAcount: 'api/account/getAdminAccount',
        getAdminAccounts: 'api/account/getAdminAccounts',
        getAdminAccountsList: 'api/account/admin-accounts',
        getAccountFilters: 'api/account/filters',
        getRegion: 'api/region/getRegionList',
        saveCompany: 'api/company/saveCompany',
        activateAccount: 'api/account/activateAccount',
        accountValidate: 'api/account/validateUserAccount',
        cancelPremiumSubscription: '/api/account/cancelPremiumSubscription',
        changeAccountData: 'api/account/changeAccountData',
        changePassword: 'api/account/changePassword',
        agreeAccountTOS: 'api/account/agreeTOS',
        verifycredential: 'api/account/verifycredential',
        saveAdminAccount: 'api/account/saveAdminAccount',
        vaioProviders: 'api/vaio/providers',
        vaioProvidersActive: 'api/vaio/activeproviders',
        vaioProvider: 'api/vaio/provider',
        getCompanyLBVersion: 'api/leaderboard/getCompanyLBVersions',
        updateAdminAccount: 'api/account/updateAdminAccount',
        forgotPassword: 'api/user/forgotPassword',
        resetPassword: 'api/user/resetPassword',
        checkResetPasswordLinkExpiry: 'api/user/checkResetPasswordLinkExpiry',
        getDistributors: 'api/distributor/getAll',
        getDisplaySettings: 'api/displaysettings/getAll',
        getLeaderboard: 'api/Leaderboard/getAll',
        saveDisplaySettings: 'api/displaysettings/update',
        saveLeaderboard: 'api/leaderboard/update',
        getDashboard: 'api/dashboard/getDashboardData',
        downloadReport: 'api/dashboard/downloadReport',
        getStoreranking: 'api/dashboard/getStoreRanking',
        badLoginAttempt: 'api/user/badLoginAttempt',
        getUserDashboardPreferences: 'api/dashboard/getUsePreferences',
        getExpiredAccounts: 'api/account/getExpiredAccounts/',
        getRenewalAccounts: 'api/account/getRenewalAccounts/',
        getFreeAccounts: 'api/account/getFreeAccounts/',
        getStoreDevices: 'api/store/getStoreDevices',
        updateUpgradeLog: 'api/store/updateUpgradeLog',
        getDFAAccount: 'api/user/getDFAAccount',
        checkDFACode: 'api/user/checkDFACode',
        getStore: 'api/store/getStore',
        downloadStores: 'api/store/downloadAllStores',
        getAllTimeZones: 'api/timeZone/getAll',
        getDeviceUpgradeVersions: 'api/upgrade-versions/getAll',
        getSupportedDeviceUpgradeVersions: 'api/upgrade-versions/getAllSupportedVersions',
        getDevicePeripheralInfos: 'api/peripheral-infos/getAll',
        getDevicePeripheral: 'api/peripheral-infos/get-peripheral-devices',
        getPeripheralDevices: 'api/device/getPeripheralDevices',
        saveStore: 'api/store/saveStore',
        createPreInstallStore: 'api/store/createPreInstallStore',
        updatePreInstallStore: 'api/store/updatePreInstallStore',
        mergeDevicesInfo: 'api/store/mergeDevicesInfo',
        saveMergeDevices: 'api/store/saveMergeDevices',
        getLBGroups: 'api/lbGroup/getAll',
        getLBTemplates: 'api/lbTemplate/getAll',
        getLBgroup: 'api/lbGroup/get',
        getLBgroupsAvailable: 'api/lbGroup/getAvailableStores',
        getLBSettingTemplates: 'api/leaderboard/getLBSettingTemplates',
        saveLBGroup: 'api/lbGroup/save',
        deleteLBGroupUser: 'api/lbGroup/deleteLBGroupUser',
        checkDevice: 'api/device/checkDevice',
        createPreconfiguredDevice: 'api/device/createPreconfiguredDevice',
        unBindMail: 'api/device/unBindMail',
        setOverrideCountry: 'api/device/overrideCountry',
        getDeviceFilters: 'api/device/filters',
        sendAdminDevicesReport: 'api/device/sendAdminDevicesReport',
        deleteLBGroup: 'api/lbGroup/delete',
        updateLBGroupOwner: 'api/lbGroup/updateLBGroupOwner',
        removeGroup: 'api/lbGroup/removeGroup',
        removeTemplate: 'api/lbTemplate/remove',
        getLBGroupUsers: 'api/lbGroup/getLBGroupUsers',
        createLBGroup: 'api/lbgroup/createLBGroup',
        removeLBGroup: 'api/lbgroup/removeLBGroup',
        inviteLBGroupUser: 'api/lbGroup/inviteLBGroupUser',
        inviteResponse: 'api/lbgroup/inviteResponse',
        getLBSubscription: 'api/lbGroup/getLBSubscription',
        removeStore: 'api/store/removeStore',
        removeStoreDevice: 'api/device/removeStoreDevice',
        DownloadNewImage: 'api/device/downloadNewImage',
        UnBindRemoteDeviceWeb: 'api/device/unbindRemoteDeviceWeb',
        BindRemoteDeviceWeb: 'api/device/bindRemoteDeviceWeb',
        RestartDevice: 'api/device/restartDevice',
        ForceReconnect: 'api/device/forceReconnect',
        GetAccountDevices: 'api/device/getAccountDevices',
        GetAdminDevices: 'api/device/getDevices',
        GetAdminDevice: 'api/device/getDevice',
        GetDeviceRecentUpgradeTasks: 'api/device/getRecentUpgradeTasks',
        CreateStoreSettingsTask: 'api/tasks/createStoresTask',
        GetStoreSettingTasks: 'api/tasks/getStoresTasks',
        SearchDeviceStores: 'api/device/searchStores',
        UploadStoreSettingsTask: taskUid => `api/tasks/${taskUid}/uploadStoreTaskDevices`,
        GetStoreSettingTask: taskUid => `api/tasks/${taskUid}`,
        CancelStoreSettingTask: taskUid => `api/tasks/${taskUid}/cancel`,
        RedownloadStoreSettingTask: taskUid => `api/tasks/${taskUid}/redownloadFile`,
        getLatestInvite: 'api/lbGroup/getRecentInvite',
        lbGroupBase: 'api/lbGroup',
        inviteResponseFromCloud: 'api/lbGroup/inviteResponseFromCloud',
        createUserSession: 'api/user/createUserSession',
        logger: 'api/logger',
        getStoreRanking: 'api/dynamicHomescreen/getStoreRanking',
        getUserIP: 'api/user/getIP',
        createUpgradeTask: 'api/device/createUpgradeTask',
        createBatchUpgrade: 'api/scheduledUpgrade/create',
        getBatchUpgrades: 'api/scheduledUpgrade/getAll',
        getLBSettingTemplate: 'api/leaderboard/getLBSettingTemplate',
        setLBSettingTemplate: 'api/leaderboard/setLBSettingTemplate',
        getLBGroupNames: 'api/leaderboard/getLBGroupNames',
        getLBTemplateLanguages: 'api/lbTemplate/languages',
        getDistributorAccounts: 'api/distributor/accounts/getAll',
        getDistributorAccount: 'api/distributor/accounts/get',
        getDistributorAccountUsers: 'api/distributor/accounts/users/getAll',
        getDistributorAccountRoles: 'api/distributor/accounts/role/getAll',
        getDistributorAccountRolesList: 'api/distributor/accounts/role/getList',
        createDistributorAccountRole: 'api/distributor/accounts/role/create',
        getDistributorAccountRole: 'api/distributor/accounts/role/get',
        updateDistributorAccountRole: 'api/distributor/accounts/role/update',
        deleteDistributorAccountRole: 'api/distributor/accounts/role/delete',
        getDistributorAccountGroups: 'api/distributor/accounts/group/getAll',
        getDistributorAccountUser: 'api/distributor/accounts/users/get',
        createDistributorUserForAccount: 'api/distributor/accounts/users/create',
        editDistributorUserForAccount: 'api/distributor/accounts/users/edit',
        deleteDistributorUserFromAccount: 'api/distributor/accounts/users/delete',
        resendActivationEmail: 'api/account/resendActivationEmail',
        getDataFeedServiceAccounts: 'api/manage-datafeed/serviceAccounts',
        getDataFeedCloudAccounts: 'api/manage-datafeed/cloudAccounts',
        getDataFeedList: 'api/manage-datafeed/list',
        createServiceAccount: 'api/manage-datafeed/createServiceAccount',
        linkCloudAccount: 'api/manage-datafeed/linkCloudAccount',
        unlinkCloudAccount: 'api/manage-datafeed/unlinkCloudAccount',
        deleteServiceAccount: 'api/manage-datafeed/deleteServiceAccount',
        getServiceAccount: serviceAccountID => `api/manage-datafeed/serviceAccount/${serviceAccountID}`,
        getCloudAccount: cloudAccountID => `api/manage-datafeed/cloudAccount/${cloudAccountID}`,
        customerNotification: 'api/notifcations',
        createCustomerNotification: 'api/notifcations',
        getCustomerNotifications: 'api/notifcations',
        getEnabledCustomerNotification: 'api/notifcations/enabled',
        getPassedCustomerNotificationsByTypeId: (typeId, dateFromNow) => `api/notifcations/getPassedByType/${typeId}?dateFromNow=${dateFromNow}`,
        enableCustomerNotification: (id) => `api/notifcations/enable/${id}`,
        disableCustomerNotification: (id) => `api/notifcations/disable/${id}`,
        deleteCustomerNotification: (id) => `api/notifcations/${id}`,
        saveViewFranchise: 'api/account/viewFranchise',
        getManagementTokens: 'api/token',
        deleteManagementToken: (id) => `api/token/${id}`,
        getCloudAPI: 'api/token/cloudAPI',
        createAPIToken: 'api/token/create',
        updateAPITokenByUID: (uid) => `api/token/edit/${uid}`,
        getAPITokenByUID: (uid) => `api/token/${uid}`,
        renewAPIToken: 'api/token/renew',
        getPartnerUsersByEmail: 'api/token/partnerUsersByEmail',
        getTokenPartnersProviders: 'api/token/vaioProviders',
        announcementSettings: 'api/nitroannouncementsettings',
        leaderboardContests: 'api/contests',
        getContestResult: (uid) => `api/contests/${uid}/results`,
        getContestSessionResult: (uid) => `api/contests/${uid}/sessionresults`,
        getContest: (uid) => `api/contests/${uid}`,
        generatePDF: 'api/func-render-pdf',
        generateXLS: 'api/func-render-xls',
        createLeaderboardContests: 'api/contests/create',
        isStoresAvailableLeaderboardContests: 'api/contests/stores/available',
        getGroupedStores: 'api/store/getGroupedStores',
        notificationCenterBase: 'api/notification-center',
        getPartners: 'api/partners',
        createPartner: 'api/partners/',
        getPartner: (uid) => `api/partners/${uid}`,
        getPartnerTypes: 'api/partners/types',
        reactivatePartner: (uid) => `api/partners/${uid}/reactivate`,
        getPartnerUserDetails: 'api/partners/getPartnerUserDetails',
        updateCurrentPartner: 'api/partners/updateUser',
        validatePartner: 'api/partners/activatePartnerUser',
        verifyPartnerCredential: 'api/partners/verifyPartnerUserCredential',
        partnerUserAgreeTOS: 'api/partners/partnerUserAgreeTOS',
        changePartnerUserPassword: 'api/partners/changePartnerUserPassword',
        forgotPasswordPartner: 'api/partners/forgotPassword',
        resetPasswordPartner: 'api/partners/resetPassword',
        getPartnersVaioProvider: 'api/partners/vaioProvider',
        updatePartnersVaioProvider: 'api/partners/vaioProvider',
        getTextConnectStores: 'api/text-connect/stores',
        getTextConnectGroupedStores: 'api/text-connect/grouped-stores',
        sendTextConnectMessage: 'api/text-connect/send-message',
        getTextConnectMessages: 'api/text-connect/messages',
        getPartnersProviders: 'api/partners/providers',
        getPartnersProvider: (uid) => `api/partners/provider/${uid}`,
        updatePartnersProvider: 'api/partners/provider',
        getPartnerStores: 'api/partners/stores',
        getPartnerToken: 'api/partners/token',
        renewPartnerAPIToken: 'api/partners/renew',
        getSSOConfigDetails: 'api/sso/config',
        editSSOConfigDetails: 'api/sso/editSSOConfiguration',
        getAccountOwners: 'api/account/owners',
        createSSOConfigDetailsAdmin: 'api/sso/createSSOManagement',
        updateSSOConfigDetailsAdmin: 'api/sso/editSSOManagement',
        getSSOConfigDetailsAdmin: 'api/sso/allSSOManagement',
        deleteSSOConfigDetailsAdmin: 'api/sso/deleteSSOManagement',
        checkSSOAccount: 'api/sso/checkSSOAccount',
        logSSOAudit: 'api/sso/audit'
    },
    TimeMeasureValidations: {
        TwoMonths: 62,
        Month: 31,
        TwoWeeks: 14,
        Today: 0,
        ThreeMonths: 93
    },
    TimeMeasure: {
        Day: 1,
        Daypart: 2,
        Week: 3,
        RawCarData: 4
    },
    Type: {
        Group: 'group',
        Store: 'store'
    },
    Drilldown: [2, 4, 1],
    LaneTotalAvgColors: ['#2790b0', '#eb6841', '#95ba65', '#edc951', '#2b4e72', '#cc333f', '#727272', '#9c347d', '#ff0000', '#00d0f6'],
    LaneTotalAvgColorsImages: ['ldot_2790b0', 'ldot_eb6841', 'ldot_95ba65', 'ldot_edc951', 'ldot_2b4e72', 'ldot_cc333f', 'ldot_727272', 'ldot_9c347d', 'ldot_ff0000', 'ldot_00d0f6'],
    DefaultPermissionIndex: 5,
    isChecked: true,
    value: 'DZCV7UJ6BYVUQVF5B09WU7LI8NU1G0ZG',
    RemoveRoleID: 'FXLR16N255ZQMM6L8QY9LP7F3YN3WZRT',
    RemoveUserID: 'AL2SIAO4K69R7OF83X6HY4SKNUCXTBHQ',
    DashboardTimeMeasures: {
        t1Avg: 'current day avg',
        t1Cars: 'current day cars',
        t1Percent: 'current day percent',

        t2Avg: 'previous day avg',
        t2Cars: 'previous day cars',
        t2Percent: 'previous day percent',

        t3Avg: 'current month avg',
        t3Cars: 'current month cars',
        t3Percent: 'current month percent',

        t4Avg: 'previous month avg',
        t4Cars: 'previous month cars',
        t4Percent: 'previous month percent',

        t5Avg: 'current week avg',
        t5Cars: 'current week cars',
        t5Percent: 'current week percent',

        t6Avg: 'previous week avg',
        t6Cars: 'previous week cars',
        t6Percent: 'previous week percent',

        t7Avg: 'current daypart avg',
        t7Cars: 'current daypart cars',
        t7Percent: 'current daypart percent',

        t8Avg: 'previous dayaprt avg',
        t8Cars: 'previous daypart cars',
        t8Percent: 'previous daypart percent'
    },
    loginAttempts: {
        checkAttemptsTimeout: 20 // In minutes
    },
    currentCU50Version: '3.12',
    minSnapshotVersion: '4.2',
    StoreSettingsPage: '/settings/stores/viewdetails/', // old device designs page path
    AdminStoreSettingsPage: '/admin/settings/stores/edit',
    PublicStoreSettingsPage: '/settings/stores/edit', // redesigned device page path
    AdminEditAccountPage: '/admin/account',
    ProfilePage: '/profile',
    WelcomePage: '/welcome',
    NotificationsCenterPage: '/notifications/center',
    RemoteConnectionPermission: 'RemoteConnect',
    RemoveRole: 'RemoveRole',
    EditLeaderboard: 'EditLeaderboard',
    adminPermissions: {
        ViewAllUsers: 'ViewAllUsers',
        ViewAllStores: 'ViewAllStores',
        ViewAllRoles: 'ViewAllRoles',
        ViewAllReports: 'ViewAllReports',
        ViewAllDevices: 'ViewAllDevices',
        ViewAllDashboards: 'ViewAllDashboards',
        ViewAllAccounts: 'ViewAllAccounts',
        CreateAccounts: 'CreateAccounts',
        EditAccounts: 'EditAccounts',
        RemoveDevice: 'RemoveDevice',
        RemoveStore: 'RemoveStore',
        CreateCorpAccounts: 'CreateCorpAccounts',
        PerformDeviceUpdate: 'PerformDeviceUpdate',
        CreateSettingSnapshot: 'CreateSettingSnapshot',
        ApplyDeviceSettings: 'ApplyDeviceSettings',
        DeleteSettingSnapshot: 'DeleteDeviceSettings',
        PreconfigureDevice: 'PreconfigureDevice',
        MoveSubscription: 'MoveSubscription',
        EditDeviceAdvanced: 'EditDeviceAdvanced',
        DeviceUpgrades: 'DeviceUpgrades',
        Masquerade: 'Masquerade',
        CreateVaioProvider: 'CreateVaioProvider',
        ManageDXS: 'ManageDXS',
        CustomerNotification: 'CustomerNotification',
        HMEReports: 'HMEPBIReports',
        ViewSpeedOfServiceReport: 'ViewSpeedOfServiceReport',
        ManageBrandFirmwares: 'ManageBrandFirmwares',
        DeviceUpgradeApproval: 'DeviceUpgradeApproval',
        ManageTier: 'ManageTier',
        ManageToken: 'ManageToken',
        ManagePartners: 'ManagePartners',
        ManageNitroSubscription: 'ManageNitroSubscription',
        ManageSSO: 'ManageSSO'
    },
    userPermissions: {
        AddDevice: 'AddDevice',
        EditDeviceSettings: 'EditDeviceSettings',
        EditStoreAdvanced: 'EditStoreAdvanced',
        EditLeaderboard: 'EditLeaderboard',
        ViewLeaderboard: 'ViewLeaderboard',
        EditStoreBasic: 'EditStoreBasic',
        PerformDeviceRestart: 'PerformDeviceRestart',
        RemoteConnect: 'RemoteConnect',
        ViewAllStores: 'ViewAllStores',
        SmackTalk: 'SmackTalk',
        CustomizeSmackTalk: 'CustomizeSmackTalk',
        SendSmackTalkMessage: 'SendSmackTalkMessage',
        SendTextConnectMessage: 'SendTextConnectMessage',
        EditStoreMerge: 'EditStoreMerge',
        AddStore: 'AddStore',
        CreateCorpAccounts: 'CreateCorpAccounts',
        ManageDeviceSettings: 'ApplyDeviceSettings',
        ViewEditContests: 'ViewEditContests',
        ViewEditAwards: 'ViewEditAwards',
        ManageRole: 'ManageRole',
        ManageUser: 'ManageUser'
    },
    externalPermissions: {
        ManageDevicesBasic: 'ExtManageDevicesBasic',
        ManageDevicesAdvanced: 'ExtManageDevicesAdvanced',
        ManageRoles: 'ExtManageRoles',
        ManageUsers: 'ExtManageUsers',
        ManageStoresBasic: 'ExtManageStoresBasic',
        ManageStoresAdvanced: 'ExtManageStoresAdvanced',
    },
    publicAdminPermissions: [
        'common__apply-device-settings',
        'common__permission--edit-device-settings',
        'common__permission--edit-leaderboard',
        'common__permission--edit-store-advanced',
        'common__permission--perform-device-restart',
        'common__permission--send-smack-talk',
        'common__permission--customize-smack-talk',
        'common__permission--view-all-stores'
    ],
    publicUserPermissions: [
        'common__permission--manage-user',
        'common__permission--manage-role',
        'common__permission--edit-store-basic',
        'common__permission--remote-connect',
        'common__permission--view-leaderboard'
    ],
    upgradeType: {
        full: 'full',
        patch: 'patch'
    },
    deviceType: { // tbl_DeviceType
        zoom: {
            id: 1,
            name: 'ZOOM',
            displayName: 'ZOOM',
            upgradeTime: 30,
            entryVersion: '3.0.0',
            majorVersions: [2, 3],
            hardwareName: 'CU',
            camelCase: 'zoom',
        },
        zoomNitro: {
            id: 1,
            name: 'ZOOM Nitro',
            displayName: 'ZOOM Nitro',
            minMainVersion: 4,
            upgradeTime: 30,
            entryVersion: '4.0.0',
            minSnapshotVersion: '4.2',
            majorVersions: [4, 5],
            hardwareName: 'CU',
            camelCase: 'zoomNitro',
        },
        ion: {
            id: 2,
            name: 'ION',
            displayName: 'ION',
            upgradeTime: 15
        },
        eos: {
            id: 3,
            name: 'EOS',
            displayName: 'EOS',
            upgradeTime: 10
        },
        cib: {
            id: 4,
            name: 'CIB',
            displayName: 'CIB',
            upgradeTime: 30
        },
        nexeo: {
            id: 5,
            name: 'NEXEO',
            displayName: 'NEXEO',
            upgradeTime: 30,
            minSnapshotVersion: '0.58.15',
            camelCase: 'nexeo',
        },
        linkWear: {
            id: 6,
            name: 'LinkWear',
            displayName: 'LinkWear'
        },
        evd: {
            id: 14,
            name: 'ZOOM Nitro/EVD',
            displayName: 'Vision AI',
            hardwareName: 'VPU'
        },
    },
    deviceLimitation: {
        minUpgradableVersion: 3,
    },
    subscription: {
        basic: '3',
        basicWithUpgrade: '8',
        nitroEssentials: '8',
        premiumStore: '4',
        nitroData: '4',
        premiumStoreDriveThru: '6',
        nitroLeaderboard: '6',
        nitroGamification: '15',
        jtechCustomerOnly: '16',
        nexeoCustomerOnly: '17'
    },
    subscriptionLevels: {
        nitroEssentials: '3',
        nitroData: '1',
        nitroLeaderboard: '2',
        nitroGamification: '6',
    },
    subscriptionNames: {
        nitroEssentials: 'Nitro Essentials',
        nitroData: 'Nitro Data',
        nitroLeaderboard: 'Nitro Leaderboard',
        nitroGamification: 'Nitro Gamification',
        nexeoCustomerOnly: 'NEXEO Customer Only',
        jtechCustomerOnly: 'LinkWear Customer Only',
        premiumStoreDriveThru: 'Premium Plus'
    },
    fileFormats: [
        'PDF',
        'XLS'
    ],
    defaultDropdownOption: [{ 'text': 'common__none', 'value': -1 }],
    blackListWords: 'anal,anus,arse,ass,ballsack,balls,bastard,bitch,biatch,blowjob,blow job,bollock,bollok,boner,boob,bugger,bum,butt,buttplug,clitoris,cock,coon,crap,cunt,damn,dick,dildo,dyke,fag,feck,fellate,fellatio,felching,fuck,f u c k,fudgepacker,fudge packer,flange,Goddamn,God damn,hell,homo,jerk,jizz,knobend,knob end,labia,lmao,lmfao,muff,nigger,nigga,omg,obama,penis,piss,poop,prick,pube,pussy,queer,scrotum,sex,shit,s hit,sh1t,slut,smegma,spunk,trump,tit,tosser,turd,twat,vagina,wank,whore,wtf'
};
