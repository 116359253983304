import * as React from 'react';

import { ParticipatingStoreItem } from './ParticipatingStoreItem';

export const PartticipatingStoresCheckboxList = (props) => {
    const { isViewMyStoresOnlyChecked, isDefault, company, disabled, items = [], toggle } = props;

    return items.map((store, index) => (
        <ParticipatingStoreItem
            key={index}
            store={store}
            isViewMyStoresOnlyChecked={isViewMyStoresOnlyChecked}
            isDefault={isDefault}
            company={company}
            disabled={disabled}
            toggle={toggle}
        />));
};