import React, { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Label } from 'library/Label';
import { Button } from 'library/Button';
import { TreeItemsProp, Tree } from 'library/Tree';
import { useClickOutSide } from 'hooks/useClickOutSide';

import './TreeInput.scss';

const buttonVaraints = ['transparent'];

const getAllValues = (tree, values = []) => {
    tree.forEach((item) => {
        if (typeof item.value !== 'undefined' && !item.disabled) {
            values.push(item.value);
        }

        if (item.children && item.children.length) {
            getAllValues(item.children, values);
        }
    });

    return values;
};

export const TreeInput = ({
    label,
    value,
    tree,
    selectable = true,
    onChange,
    className,
    isGroupSelectable,
    hideSelectAllButton,
    emptyTreeMessage = 'common__no-stores--available',
    isAllExpanded = false,
    isExpandedDefault = false
}) => {
    const { t } = useTranslation();

    const ref = useRef(null);
    const allValues = useMemo(() => getAllValues(tree), [tree]);
    const [isFocused, setIsFocused] = useState(false);

    const areStoresExist = useMemo(() => Boolean(tree.length), [tree]);

    const onInnerCLick = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const onOuterClick = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const onSelectAll = useCallback(() => {
        onChange && onChange(allValues);
    }, [allValues, onChange]);

    const onDeselectAll = useCallback(() => {
        onChange && onChange([]);
    }, [onChange]);

    useClickOutSide(ref, onOuterClick);

    return (
        <div className={classNames('hme-tree-input', {
            'hme-tree-input--focused': isFocused && areStoresExist
        })} ref={ref} onClick={onInnerCLick}>
            <Label>{t(label)}</Label>
            {
                areStoresExist ?
                    <>
                        <div className={classNames(className, 'hme-tree-input__box')}>
                            <Tree
                                tree={tree}
                                selectable={selectable}
                                selection={value}
                                onSelectionChange={onChange}
                                isGroupSelectable={isGroupSelectable}
                                isAllExpanded={isAllExpanded}
                                isExpandedDefault={isExpandedDefault}
                            />
                        </div>
                        {
                            (!hideSelectAllButton  && selectable) && <div className='hme-tree-input__selection'>
                                <Button
                                    className='hme-tree-input__selection__select-all'
                                    variants={buttonVaraints}
                                    onClick={onSelectAll}
                                >
                                    {t('common__select-all')}
                                </Button>
                                <Button
                                    className='hme-tree-input__selection__deselect-all'
                                    variants={buttonVaraints}
                                    onClick={onDeselectAll}
                                >
                                    {t('common__deselect-all')}
                                </Button>
                            </div>
                        }
                    </> :
                    <div className="hme-tree-input__box">
                        <span className="hme-tree-input__box__no-stores">{t(emptyTreeMessage)}</span>
                    </div>
            }
        </div>
    );
};

TreeInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.any),
    selectable: PropTypes.bool,
    tree: TreeItemsProp,
    onChange: PropTypes.func,
    emptyTreeMessage: PropTypes.string
};
