export const formDataValidator = ({ serviceAccountName, brandID, cloudAccountID }) => {
    const formErrorsConditions = [
        {
            key: 'serviceAccountName',
            condition: !serviceAccountName,
            message: 'dxs-feeds__create-feed-form--validation--service-account-name',
        },
        {
            key: 'brandID',
            condition: !brandID,
            message: 'dxs-feeds__create-feed-form--validation--brand',
        },
        {
            key: 'cloudAccountID',
            condition: !cloudAccountID,
            message: 'dxs-feeds__create-feed-form--validation--account',
        },
    ];

    const formError = formErrorsConditions.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );

    return formError;
};

// form fields mapped to multiple validation messages
export const validationFieldsMessagesMap = {
    serviceName: ['serviceAccountName'],
    brandID: ['brandID'],
    accountID: ['cloudAccountID'],
};
