import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import Tree, { TreeNode } from 'rc-tree';
import { map, reject, contains, placeholder } from 'lodash/fp';
import * as R from 'ramda';

import 'rc-tree/assets/index.css';
import './TreeSelectInput.css';

class TreeSelectInputComponent extends Component {
  constructor(props) {
    super(props);

    this.renderNodes = this.renderNodes.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.filterCheckedKeys = this.filterCheckedKeys.bind(this);
    this.renderNodeTitle = this.renderNodeTitle.bind(this);
    this.lastSelectedIndex = null;
  }

  // returns only first layer children of tree
  getAvailableKeys(nodes) {
    const getKeys = nodes => {
      if (!nodes) {
        return null;
      }
      return nodes.map(node => node.id);
    }
    return nodes.reduce((acc, node) => {
      acc.push(String(node.id))
      const nodeKeys = getKeys(node.children);
      if (nodeKeys) {
        return acc.concat(nodeKeys);
      }
      return acc;
    }, []);
  }

  // used to omit some keys when checking node (e.g. for stores omiting store groups)
  filterCheckedKeys(keys) {
    const { omitRootNodes, tree } = this.props;

    if (omitRootNodes) {
      const rootKeys = map(node => String(node.id), tree);

      return reject(contains(placeholder, rootKeys), keys);
    }

    return keys;
  }

  onCheck(checkedKeys, event) {
    let { lastSelectedIndex } = this;
    const { onChange, rangeSelection, tree } = this.props;
    const { nativeEvent: { shiftKey }, node } = event;
    let filteredKeys = [];
    let selectedInRange = [];

    // selection with shift key if rangeSelection prop is present
    const availableKeys = this.getAvailableKeys(tree);
    const checkedNodeKey = node && node.props && node.props.eventKey;
    const currentSelectedIndex = availableKeys.indexOf(checkedNodeKey);
    if (checkedNodeKey && shiftKey && rangeSelection) {
      // selection with pressed shift button
      if (typeof(lastSelectedIndex) === 'undefined') {
        selectedInRange = [checkedNodeKey];
        this.lastSelectedIndex = currentSelectedIndex;
      } else if (currentSelectedIndex > lastSelectedIndex) {
        selectedInRange = [...availableKeys.slice(lastSelectedIndex, currentSelectedIndex), checkedNodeKey];
      } else if (currentSelectedIndex < lastSelectedIndex) {
        selectedInRange = [...availableKeys.slice(currentSelectedIndex, lastSelectedIndex + 1), checkedNodeKey];
      } else if (currentSelectedIndex === lastSelectedIndex) {
        selectedInRange = checkedKeys;
      }
      filteredKeys = this.filterCheckedKeys(selectedInRange);
    } else {
      filteredKeys = this.filterCheckedKeys(checkedKeys);
    }

    // set last selected index when user click on mode withot shift key pressed
    if (!shiftKey) {
      this.lastSelectedIndex = currentSelectedIndex;
    }

    onChange(filteredKeys);
  }

  renderNodeTitle(node) {
    const { renderNodeTitle = R.prop('name') } = this.props;
    return renderNodeTitle(node);
  }

  renderNodes(nodes) {
    const { disabled } = this.props;

    if (!nodes) {
      return null;
    }
    
    return nodes.map(node => (<TreeNode
        disabled={disabled}
        key={node.id}
        title={this.renderNodeTitle(node)}
      >
        {this.renderNodes(node.children)}
      </TreeNode>
    ));
  }

  render() {
    const {
      name,
      label,
      className,
      value,
      tree,
      flatten,
      strongRootNodes,
      defaultExpandAll,
      rangeSelection,
      t
    } = this.props;

    return (
      <div
        className={classNames(
          className,
          'hme-input tree-select-input',
          { 'tree-select-input--strong-root-nodes': strongRootNodes },
          { 'tree-select-input--insensitive-text-selection': rangeSelection },
        )}
      >
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        {tree && tree.length ? <Tree
          rangeSelection={rangeSelection}
          checkable
          showIcon={false}
          showLine
          selectable={false}
          defaultExpandAll={defaultExpandAll}
          checkedKeys={value || []}
          onCheck={this.onCheck}
        >
          {this.renderNodes(flatten ? R.reduce((acc, curr) => R.concat(acc, curr.children), [], tree) : tree)}
        </Tree> :
        <p>{t('tree-select-input__empty')}</p>}
      </div>
    );
  }
}

export const TreeSelectInput = R.compose(
  withTranslation()
)(TreeSelectInputComponent);
