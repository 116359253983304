/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import { Radio } from '../../library/Radio';
import { Label } from '../../library/Label';

import './RadioGroup.scss';
import { useTranslation } from 'react-i18next';

export const RadioGroup = ({
    label,
    items = [],
    variants = [],
    radioVariants,
    value,
    disabled,
    onChange
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={classNames(
                    'hme-radio-group',
                    variants.map((variant) => `hme-radio-group-${variant}`),
                    {
                        'hme-radio-group-disabled': disabled
                    }
            )}
        >
            <Label className="hme-label-radio-group">{t(label) || label}</Label>
            <div className="hme-radio-group-list">
                {
                    items.map(({ text, value: itemValue }) => (
                        <Radio
                            key={text}
                            label={t(text) || text}
                            variants={radioVariants}
                            checked={ value === itemValue }
                            disabled={disabled}
                            onChange={() => onChange && onChange(itemValue)}
                        />
                    ))
                }
            </div>
        </div>
    );
};

