import { CommonConstants } from 'Constants';
import { Config } from 'Config';
import { postDataAsync, getDataAsync, putDataAsync, deleteDataAsync } from 'Api';
import { commonErrors } from 'constants/errors';
import { CUSTOMER_NOTIFICATION_TYPES } from 'constants/customerNotifications';

const toCamelCase = (str = '') => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

const notificationTypeIdToTextAdaptor = (notificationTypeId) => {
    return CUSTOMER_NOTIFICATION_TYPES[notificationTypeId];
};

const notificationTextsAdaptor = (textsFromServer = []) => {
    return textsFromServer.reduce((acc, text) => {
        acc[text.CustomerNotificationLanguage] = text.CustomerNotificationText;

        return acc;
    }, {});
};

const notificationAdaptor = (notificationFromServer = {}) => {
    return {
        texts: notificationTextsAdaptor(
                notificationFromServer.CustomerNotificationTexts
        ),
        isEnabled: Boolean(
                notificationFromServer.IsCustomerNotificationEnabled
        ),
        type: notificationTypeIdToTextAdaptor(`${notificationFromServer.CustomerNotificationTypeID}`),
        ...Object.keys(notificationFromServer).reduce((adopted, key) => {
            adopted[toCamelCase(key)] = notificationFromServer[key];
            return adopted;
        }, {})
    };
};

const errors = {
    [commonErrors.INVALID_DATA]: 'common__error--invalid-data',
    [commonErrors.INTERNAL_SERVER]: 'common__error--internal-server',
    [commonErrors.NO_RECORDS_FOUND]: 'common__error--no-records-found'
};

export const createCustomerNotification = async (notification) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.customerNotification}`;

    let response;

    try {
        response = await postDataAsync(url, notification);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return;
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const getCustomerNotification = async (notificationId) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.customerNotification}/${notificationId}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return notificationAdaptor(response.notification);
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const editCustomerNotification = async ({ notificationId, texts, isEnabled, type } = {}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.customerNotification}/${notificationId}`;

    let response;

    try {
        response = await putDataAsync(url, { texts, isEnabled, type });
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return;
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const getCustomerNotifications = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getCustomerNotifications}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return (response.notifications || []).map((notification) => {
            return notificationAdaptor(notification);
        });
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const getPassedCustomerNotificationsByTypeId = async ({ typeId, dateFromNow }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPassedCustomerNotificationsByTypeId(typeId, dateFromNow)}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return (response.notifications || []).map((notification) => {
            return notificationAdaptor(notification);
        });
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const getEnabledCustomerNotifications = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getEnabledCustomerNotification}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return (response.notification || []).map((notification) => {
            return notificationAdaptor(notification);
        });
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const enableCustomerNotification = async (id) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.enableCustomerNotification(id)}`;

    let response;

    try {
        response = await postDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return;
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const disableCustomerNotification = async (id) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.disableCustomerNotification(id)}`;

    let response;

    try {
        response = await postDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return;
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

export const deleteCustomerNotification = async (id) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteCustomerNotification(id)}`;

    let response;

    try {
        response = await deleteDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return;
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
}
