import React, { useState, useEffect, useCallback } from 'react';
import { pluck, sum, pipe } from 'ramda';

import { Grid } from 'components/Common/Grid';
import { getAccountBrandDevices } from './AccountBrandGridController';

import './AccountBrandGrid.scss';

const gridHeaders = [{
    text: 'Brand',
    property: 'Brand_Name',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Number of devices',
    property: 'Devices_Count',
    className: 'hme-account-brand-cell',
    headerClassName: 'hme-account-brand-cell',
}];

const loadBrands = async ({
    account,
    sourceDevice,
    selectedDeviceType,
    setBrands,
    setIsLoading,
    onSelectedBrandIdsChange,
}) => {
    const brands = await getAccountBrandDevices({
        accountId: account.Account_ID,
        sourceDevice,
        selectedDeviceType,
    });

    setBrands(brands);
    setIsLoading(false);

    if (brands.length === 1) {
        onSelectedBrandIdsChange([brands[0].Brand_ID]);
    }
};

const getAllDevicesCount = pipe(
    pluck('Devices_Count'),
    sum
);

export const AccountBrandGrid = ({
    account,
    sourceDevice,
    selectedDeviceType,
    selectedBrandIds,
    onSelectedBrandIdsChange
}) => {
    const [brands, setBrands] = useState([]);
    const [deviceCount, setDeviceCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        loadBrands({
            account,
            sourceDevice,
            selectedDeviceType,
            setBrands,
            setIsLoading,
            onSelectedBrandIdsChange
        });
    }, [account, sourceDevice, selectedDeviceType, setBrands, setIsLoading]);

    useEffect(() => {
        setSelectedRows(brands.filter(({ Brand_ID }) => selectedBrandIds.includes(Brand_ID)));
    }, [brands, selectedBrandIds]);

    useEffect(() => {
        setDeviceCount(getAllDevicesCount(brands));
    }, [brands]);

    const onSelectionChange = useCallback(newBrands => {
        onSelectedBrandIdsChange(pluck('Brand_ID', newBrands));
    }, [onSelectedBrandIdsChange]);

    return (
        <div className='hme-account-brand'>
            <div className='hme-account-brand-info'>
                There are <span className='hme-account-brand-count'>{deviceCount}</span> devices
            </div>
            <div className='hme-account-brand-info'>
                across {' '}
                <span className='hme-account-brand-count'>{brands.length}</span>{' '}
                brands under this account.
            </div>
            <Grid
                rows={brands}
                headers={gridHeaders}
                rowKey='Brand_ID'
                isLoading={isLoading}
                selectable
                selection={selectedRows}
                onSelectionChange={onSelectionChange}
            />
        </div>
    );
};
