import React from 'react';
import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import { confirmAlert } from 'react-confirm-alert';

import { Config } from '../../Config';
import AuthenticationService from '../Security/AuthenticationService';
import { CommonConstants } from '../../Constants';
import Api from '../../Api';

import '../Footer/Footer.css';
import './action.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

class UnregisterSystem extends React.Component {
  constructor () {
    super()
    this.api = new Api()
    this.authService = new AuthenticationService(Config.authBaseUrl)
    this.unRegisterSystemPopup = this.unRegisterSystemPopup.bind(this)
    this.unRegisterSystem = this.unRegisterSystem.bind(this)
    this.removeStoreDevice = this.removeStoreDevice.bind(this)
  }

  // Page Reload

  pageRefresh () {
    window.location.reload()
  }

  isDisabled () {
    return this.props.display || this.props.deviceList.some(({ isActive }) => isActive);
  }

  // unRegisterSystemPopup
  unRegisterSystemPopup () {
    const { t } = this.props;

    if (!this.isDisabled()) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='popup'>
              <p className='popup_Title'>Confirmation Required</p>
              <p className='popup_message'>{t('admin__unregister-system__confirm--remove__message')}</p>
              <span className='popup_warning warningMsg'><strong className='warning'>WARNING:</strong> This action cannot be undone!</span>
              <div className='popup_buttons'>
                <div className='buttonSet' />
                <div className='popup-button'>
                  <button className='popup_buttons buttons' onClick={() => {
                    this.unRegisterSystem()
                    onClose()
                  }}>Confirm</button>
                  <button className='popup_buttons buttons' onClick={() => { onClose() }}>Close</button>
                </div>
              </div>
            </div>
          )
        }
      })
    }
  }

  // DTDS OutPut popup

  dtdsOutputPopupSuccess () {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='popup modal-dialog'>
            <p className='popup_Title'>Confirmation Required</p>
            <p className='popup_message successMsg'>Success! The requested system has been removed</p>
            <div className='popup_buttons'>
              <div className='buttonSet' />
              <div className='popup-button'>
                <button className='popup_buttons buttons' onClick={() => {
                  this.pageRefresh()
                  onClose()
                }}>Close</button>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  // Zoom Device Remove Error
  zoomDevicePopupError (doPageRefresh = true) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='popup'>
            <p className='popup_message'>Confirmation Required</p>
            <p className='popup_message warning'> Error! Something went wrong, please try your request again. </p>
            <div className='popup_buttons'>
              <div className='buttonSet' />
              <div className='popup-button'>
                <button className='popup_buttons buttons' onClick={() => {
                  if (doPageRefresh) this.pageRefresh();
                  onClose();
                }}>Close</button>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  // Remove store account details from DB
  removeStoreDevice({ DeviceUID, StoreID }) {
    const storeDetails = {
      DeviceUID,
      StoreID,
    };
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.removeStoreDevice;
    this.api.postData(url, storeDetails, data => {
      if (data.status) {
        this.dtdsOutputPopupSuccess();
      } else if (!data.status) {
        this.zoomDevicePopupError(false);
      }
    });
  }

  // DTDS call
  async unRegisterSystem () {
    const { deviceType } = CommonConstants;
    const allowedRemoveTypes = [
      deviceType.cib.name,
      deviceType.zoom.name,
      deviceType.eos.name,
      deviceType.ion.name,
      deviceType.nexeo.name,
      deviceType.evd.name,
    ];

    const {
      deviceList = [],
      deviceName,
      system = [],
    } = this.props
    const deviceNameUC = deviceName.toUpperCase();

    if (!allowedRemoveTypes.map(dn => dn.toUpperCase()).includes(deviceNameUC)) {
      return;
    }

    system.forEach(obj => {
      if (!deviceList.some(el => el.device_ID === obj.Device_ID) ) {
        return;
      }

      const {
        Device_UID: DeviceUID,
        Store_ID: StoreID,
      } = obj || {};
      this.removeStoreDevice({ DeviceUID, StoreID });
    });
  }

  render () {
    const { t } = this.props;

    return (
      <div>
        <a className='remove-system-device' onClick={this.unRegisterSystemPopup}>
          <span className={'remove-device-item ' + (this.isDisabled() ? 'disable-remove-device' : '')}>{t('admin__unregister-system__button')}</span>
        </a>
      </div >
    )
  }
}

export default compose(withTranslation())(UnregisterSystem);
