import React from 'react';
import { compose } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { accountTypes } from 'constants/accountTypes';
import { List } from '../../Common/List';

import './ListPage.scss';

const hasAccess = () => isAdmin();

export const AdminList = () => <List permissionType={accountTypes.ADMIN} />;

export const AdminListPage = compose(
    withHMELayout({
        contentClasses: ['admin-firmware-management-list-page'],
    }),
    authenticate,
    withAccess({
        checkAccess: hasAccess,
    }),
)(AdminList);
