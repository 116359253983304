import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { itemsProps } from 'library/SingleSelect';
import { Title } from 'library/Title';
import { SingleSelect } from 'library/SingleSelect';
import { TreeInput } from 'library/TreeInput';
import { TreeItemsProp } from 'library/Tree';
import { userProps } from '../types/userProps';

import './Access.scss';

export const Access = ({ roles, user, isAdminView, storesTree, onUserChange }) => {
    const { t } = useTranslation();

    const isDisabled = !!(isAdminView && user.isAccountOwner);

    // substitute role items for admins if user is account owner
    const roleItems = isDisabled ? [{ value: 'Account Owner', text: 'Account Owner' }] : roles;
    const roleValue = isDisabled ? 'Account Owner' : user.role;

    const onRoleChange = useCallback((role) => {
        onUserChange && onUserChange({
            ...user,
            role,
        });
    }, [user, onUserChange]);

    const onStoresChange = useCallback((newStores) => {
        onUserChange && onUserChange({
            ...user,
            stores: newStores,
        });
    }, [user, onUserChange]);

    return (
        <div className='hme-view-user__access'>
            <Title variants={['block']}>{t('add-user__access__title')}</Title>
            <SingleSelect
                label={t('common__role')}
                isRequired={true}
                value={roleValue}
                items={roleItems}
                onChange={onRoleChange}
                isDisabled={isDisabled}
                variants={['no-label']}
            />
            <TreeInput selectable={!isDisabled} label='add-user__label--store-access' tree={storesTree} value={user.stores} onChange={onStoresChange} />
        </div>
    );
};

Access.propTypes = {
    roles: itemsProps,
    user: userProps,
    isAdminView: PropTypes.bool,
    onUserChange: PropTypes.func,
    storesTree: TreeItemsProp,
};
