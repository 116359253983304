import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import '../Stores.css'
import { Config } from '../../../Config'
import { CommonConstants } from '../../../Constants'
import Api from '../../../Api'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { editStoreFieldsList } from './generateFieldsList';
import { validate, customValidate } from './helpers/storeValidation'
import { handleInputChange, generateTableBody } from './helpers/generateTableBody'
import { postData } from './helpers/postData'

const errorTKey = {
  'storealreadyexists': 'stores__error--already-exists',
};

// This componenet is used for the store detail popup and store detail page.
class EditStoreComponent extends Component { 
  constructor (props) {
    super(props);

    this.state = {
      requestErrors: [],
      errors: [],

      storeBrandID: 0,
      storeName: '',
      storeNumber: '',
      storeCountryID: 1,
      storeAXCustomerAccount: '',
      storeUID: '',
      fieldsList: [],
      isPending: false
    }

    this.api = new Api();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.generateTableBody = generateTableBody.bind(this);
    this.postData = postData.bind(this);
  }

  componentDidMount () {
    const { brands = [], storeDetails = {} } = this.props;
    const {
      Brand_Name: storeBrandName,
      Store_Name: storeName,
      Store_Number: storeNumber,
      Country_ID: storeCountryID,
      Store_AX_Customer_Account: storeAXCustomerAccount,
      Store_UID: storeUID,
    } = storeDetails;

    const { Id: storeBrandID = '0' } = brands.find(el => el.Name === storeBrandName);
    
    this.setState({
      storeBrandID,
      storeName,
      storeNumber,
      storeCountryID,
      storeAXCustomerAccount,
      storeUID,
    });
  }

  static getDerivedStateFromProps (nextProps) {
    const fieldsList = editStoreFieldsList(nextProps);
    return {
      fieldsList,
    }
  }

  handleSubmit () {
    const {
      fieldsList,
      storeUID,
      storeBrandID,
      storeName,
      storeNumber,
      storeCountryID,
      storeAXCustomerAccount
    } = this.state;
    const uuid = this.props.uuid;
    const stateValues = {
      uuid,
      storeBrandID,
      storeName,
      storeNumber,
      storeCountryID,
      storeAXCustomerAccount,
    };

    const { isValid, errors } = validate(fieldsList, stateValues);
    const { isCustomValid, customErrors } = customValidate(stateValues);

    if (!isValid || !isCustomValid) {
      return this.setState({
        errors: [...errors, ...customErrors],
        isValid: isValid && isCustomValid
      });
    }

    const url = Config.apiBaseUrl + CommonConstants.apiUrls.updatePreInstallStore;
    stateValues.storeUID = storeUID;

    // Making a service call
    this.postData(url, stateValues);
  }

  render () {
    const { t, close } = this.props;
    const { errors, isPending, requestErrors } = this.state;
    return (
      <div>
        <div className={'storePopupForm'}>
          <div className={`saveStoreErrors ${errors.length === 0 ? 'hide' : 'show'}`} >
            {errors.map(errorMessage => (<div>{errorMessage}</div>))}
            {requestErrors.map(errorMessage => (<div>{t(errorTKey[errorMessage], 'common__error--internal-server')}</div>))}
          </div>
        </div>
        <table className='user_form'>
          <tbody>
            {this.generateTableBody()}
          </tbody>
        </table>
        <div className={'add-store-save'}>
          <div className='submit-public' translate='' onClick={close} key='settingsStoresCancel'>
            {t('common__cancel')}
          </div>
          <input 
            type='submit' 
            value={t('common__save')}
            className='submit-public'
            onClick={this.handleSubmit}
            translate=''
            key='save'
            disabled={isPending ? 'disabled' : '' }
          />
        </div>
      </div>
    )
  }
}

export default compose(
  withTranslation()
)(EditStoreComponent);
