import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { withHMELayout } from 'HOCs/withHMELayout';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { compose, omit } from 'ramda';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NotificationsList } from 'library/NotificationsList';
import { AuthenticationSection } from './components/AuthenticationSection';
import { HMECompanySettings } from './components/HMECompanySettings';
import { useNavigate, useLocation } from 'react-router-dom';
import { formDataValidator } from './helpers/formDataValidator';
import { useTranslation } from 'react-i18next';
import { logSSOAudit, updateSSOInfo } from './services';
import { getProfile } from 'services/Auth';
import { CenterLoader } from 'components/Common/CenterLoader';
import { Config } from 'Config';
import { publicUserLogin, getIp } from 'services/Auth';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';
import { PUBLIC_ROUTES } from 'constants/routes';
import './SSOConfiguration.scss';
import { DEFAULT_IP } from 'constants/login';
import { SSO_AUDIT_ACTIONS, SSOTestStatus } from './helpers/constants';

const SSOConfigurationPageComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const profile = getProfile();
    const [formData, setFormData] = useState({
        authenticationInfo: {
            id: '',
            domain: '',
            authMethod: '',
            ssoTargetUrl: '',
            publicCertificatePayload: '',
            certKey: '',
            logoutRedirect: ''
        }
    });

    const [accountID, setAccountID] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isTested, setIsTested] = useState(false);
    const ipLoadingPromise = useMemo(() => getIp(), []);

    const onSave = useCallback(async () => {
        setIsSaving(true);
        const errors = formDataValidator(formData);
        if (errors.status) {
            setFormErrors(errors);
            throw new Error('Form validation failed');
        }

        setFormErrors({});
        try {
            const payload = {
                formData,
                accountID
            };

            await updateSSOInfo(payload);
            addSuccessNotification(t('settings_sso_configuration_save_success_message'));
        } catch (e) {
            addErrorNotification(t('settings_sso_configuration_save_error_message'));
            console.error('Could not save SSO info', e);
        }

        setIsSaving(false);
    }, [formData, t, accountID]);

    const resetFormErrors = useCallback(
            (dataInForm, updatedFields) => {
                const changedField = Object.keys(updatedFields).find(
                        (fieldKey) => updatedFields[fieldKey] !== dataInForm[fieldKey]
                );

                if (changedField) {
                    const formErrorsToRemove = [changedField];

                    if (Object.keys(formErrors).length === 2) { // status and one other field
                        setFormErrors({ ...omit(formErrorsToRemove, formErrors), status: false });
                        setIsSaving(false);
                    } else
                        setFormErrors(omit(formErrorsToRemove, formErrors));
                }
            },
            [formErrors]
    );

    const onCancel = useCallback(() => navigate('/'), []);

    const onTest = useCallback(async () => {
        const loginUrl = Config.authBaseUrl + Config.tokenPath;
        try {
            const ip = await ipLoadingPromise;
            setIsLoading(true);
            const loginResult = await publicUserLogin(loginUrl, {
                username: `_@${formData.authenticationInfo.domain}`, // for split function in backend. DO NOT REMOVE '_@'
                formData: JSON.stringify({
                    TargetURL: formData.authenticationInfo.ssoTargetUrl,
                    X509Key: formData.authenticationInfo.publicCertificatePayload
                }),
                isSSOTest: true,
                ip: ip && ip.clientIp || DEFAULT_IP,
                portalBaseUrl: `${window.location.origin}${PUBLIC_ROUTES.ssoConfiguration}`
            });

            if (loginResult.location) {
                window.location.replace(loginResult.location);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Could not test sso login', error);
            addErrorNotification(t('settings_sso_configuration_test_idp_unreachable_error_message'));
        }
    }, [formData, t, accountID]);

    const onAuthenticationChange = useCallback((data) => {
        resetFormErrors(formData.authenticationInfo, data);
        setFormData(
                (prevFormData) => ({
                    ...prevFormData,
                    authenticationInfo: {
                        ...prevFormData.authenticationInfo,
                        ...data
                    }
                })
        );
    }, [resetFormErrors]);

    useEffect(() => {
        setAccountID(profile.User_OwnerAccount_ID);
        if (location.search) {
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.get('test') === SSOTestStatus.SUCCESS) {
                addSuccessNotification(t('settings_sso_configuration_test_success_message'));
            } else if (searchParams.get('test') === SSOTestStatus.FAILURE) {
                addErrorNotification(t('settings_sso_configuration_test_idp_denial_error_message'));
            } else if (searchParams.get('test') === SSOTestStatus.UNREACHABLE) {
                addErrorNotification(t('settings_sso_configuration_test_idp_unreachable_error_message'));
            }
        }
    }, []);

    useEffect(() => {
        const { authenticationInfo: { SSOID: ssoId } = {} } = formData || {};
        if (location.search && ssoId) {
            const searchParams = new URLSearchParams(location.search);
            const testStatus = searchParams.get('test');
            if (testStatus && [SSOTestStatus.SUCCESS, SSOTestStatus.FAILURE, SSOTestStatus.UNREACHABLE].includes(testStatus)) {
                const { authenticationInfo: { id, SSOID, publicCertificatePayload, ...payload } = {} } = formData || {};
                const { masqueradedBy, User_EmailAddress: userEmail = '' } = profile || {};
                logSSOAudit(
                        {
                            ssoId,
                            action: testStatus === SSOTestStatus.SUCCESS ?
                        SSO_AUDIT_ACTIONS.SUCCESSFUL_TEST : SSO_AUDIT_ACTIONS.FAILED_TEST,
                            createdBy: masqueradedBy || userEmail,
                            payload
                        }
                );
            }
        }
    }, [formData]);

    return (
        <>
            {isLoading ? (
                <CenterLoader>{t('common__loading')}</CenterLoader>
            ) : (
                <>
                    <div className="hme-tab-panel-content">
                        <NotificationsList />
                        <Header title={'SSO Configuration'} />
                        <div className="sso-form-container">
                            <AuthenticationSection
                                onChange={onAuthenticationChange}
                                formErrors={formErrors}
                                accountID={accountID}
                                setIsLoading={setIsLoading}
                                setIsTested={setIsTested}
                            />
                            <HMECompanySettings
                            />
                        </div>
                    </div>
                    <Footer
                        saveDisabled={!isTested || isSaving}
                        testDisabled={isSaving}
                        onTest={onTest}
                        onSave={onSave}
                        onCancel={onCancel}
                    />
                </>
            )}
        </>
    );
};

export const SSOConfigurationPage = compose(
        withHMELayout({
            contentClasses: ['hme-sso-page']
        }),
        authenticate,
        withAccess({
            checkAccess: () => getProfile().IsAccountOwner && localStorage.getItem('isAccountSSO') === 'true'

        })
)(SSOConfigurationPageComponent);
