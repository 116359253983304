import { useCallback } from 'react';

export const useOnFileChange = ({ multiple = false, fileRef, onChange }) => {
    const onFileChange = useCallback(() => {
        if (!fileRef.current) {
            return;
        }

        const files = [];
        const filesList = fileRef.current.files;

        for (let i = 0; i < filesList.length; ++i) {
            files.push(filesList[i]);
        }

        onChange && onChange(multiple ? files : files[0]);
    }, [multiple, fileRef, onChange]);

    return {
        onFileChange,
    };
};
