import React from 'react';
import { Section } from 'library/Section';
import { CompanyInfo } from './CompanyInfo';

import './CompanySection.css';

export const CompanySection = ({
    companyInfoInitialValues,
    distributors,
    countries,
    brands,
    regions,
    isRegionsLoading,
    isCompanyTypeDisabled,
    isDistributorCreationPage,
    formErrors,
    onCompanyInfoChange,
    isReadOnly,

}) => {
    return (
        <Section className="hme-section-sm">
            <CompanyInfo
                initialValues={companyInfoInitialValues}
                isCompanyTypeDisabled={isCompanyTypeDisabled}
                isDistributorCreationPage={isDistributorCreationPage}
                distributors={distributors}
                countries={countries}
                regions={regions}
                isRegionsLoading={isRegionsLoading}
                brands={brands}
                formErrors={formErrors}
                onChange={onCompanyInfoChange}
                isReadOnly={isReadOnly}
            />
        </Section>
    );
};
