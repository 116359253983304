const FIRST_PART_LENGTH = 6;
const LAST_PART_LENGTH = 8;
const MIN_REQUIRED_LENGTH_TO_MASK = FIRST_PART_LENGTH + LAST_PART_LENGTH;

export const maskPublicKey = (publicKey) => {
    if (!publicKey || publicKey.length < MIN_REQUIRED_LENGTH_TO_MASK) {
        return publicKey;
    }

    const parts = publicKey.split('\n');

    let unMaskedTokenStart = '';
    let unMaskedTokenEnd = '';
    let maskedToken = '';

    if (parts.length < 3) {
        const tempToken = parts.join('');
        unMaskedTokenStart = tempToken.slice(0, FIRST_PART_LENGTH);
        unMaskedTokenEnd = tempToken.slice(-LAST_PART_LENGTH);
        maskedToken = tempToken.slice(FIRST_PART_LENGTH, -LAST_PART_LENGTH).replace(/./g, '*');
        return unMaskedTokenStart + maskedToken + unMaskedTokenEnd;
    } else {
        const unMaskedToken = parts.slice(1, parts.length - 1).join('');
        unMaskedTokenStart = unMaskedToken.substr( 0, FIRST_PART_LENGTH);
        unMaskedTokenEnd = unMaskedToken.substr(-LAST_PART_LENGTH);
        maskedToken = unMaskedToken.substr(0, unMaskedToken.length-MIN_REQUIRED_LENGTH_TO_MASK).replace(/./g, '*');

        const token = unMaskedTokenStart + maskedToken + unMaskedTokenEnd;

        return `${parts[0]}\n${token}\n${parts[parts.length - 1]}`;
    }
};
