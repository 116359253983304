import { validateAXAccountNumber } from 'helpers/Store/validateAXAccountNumber';

const arrayOfInputFields = ['settingsStoresStoreNumber', 'Store Number', 'AX Customer Account #'];

const getErrorText = (error) => {
    if (arrayOfInputFields.includes(error)) {
        return `You must enter a ${error}`;
    }
    return `You must select a ${error}`;
};

export const validate = (fieldsList = [], fieldsValues = {}) => {
  const errors = [];
  const checkRequired = (fieldName) => {
    const requiredFields = fieldsList.map((el) => el.isRequired && el.name || null).filter((el)=>el);
    return requiredFields.some((el) => el === fieldName);
  };

  const fieldChecker = (fieldName) => {
    const fieldValue = fieldsValues[fieldName];
    const field = fieldsList.find((el) => el.name === fieldName);
    const caption = field.caption;
    const isRequired = (!fieldValue || fieldValue === '0') && checkRequired(fieldName);

    if (isRequired) {
      errors.push(getErrorText(caption));
      return false;
    }

    return true;
  };

  const fields = fieldsList.map((el) => el.name).filter((el)=>el);
  const result = fields.map(fieldChecker);

  const isValid = result.every((el) => el);
  return { isValid, errors };
};

export const customValidate = (storeValues) => {
  const { storeAXCustomerAccount } = storeValues;
  const customErrors = [];
  if(storeAXCustomerAccount && storeAXCustomerAccount.length < 6 || !validateAXAccountNumber(storeAXCustomerAccount)) {
    customErrors.push('Account Number must be 6-10 numbers or the value EXC- and 4-6 numbers');
  }

  return {
    isCustomValid: !customErrors.length,
    customErrors
  }
};
