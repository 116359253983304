import React from 'react';

import { fetchGroupedStores } from 'services/LeaderboardContests';

import { STORE_TYPE, StoreHierarchyText } from '../StoreHierarchy';

export const prepareStoresTree = (store, storeIdsOfAnotherContest = []) => {
    const storeType = store.StoreUID ? STORE_TYPE.STORE : STORE_TYPE.GROUP;
    const keyValue = storeType === STORE_TYPE.STORE ? store.StoreUID : undefined;
    const name = storeType === STORE_TYPE.STORE ? store.StoreUID : store.GroupName;

    const treeMetadata = {
        item: Object.assign(store, { Type: storeType }),
        key: keyValue,
        value: keyValue,
        Name: name,
        text: <StoreHierarchyText hierarchyType={storeType} store={store} />,
        children: store.Stores ? store.Stores.map((innerStore) => prepareStoresTree(innerStore, storeIdsOfAnotherContest)) : [],
        disabled: storeIdsOfAnotherContest.includes(store.StoreUID)
    };

    // disable "group" selection if all stores inside are disabled (closed/participating in another contest)
    if (storeType === STORE_TYPE.GROUP) {
        treeMetadata.disabled = treeMetadata.children.every((c) => storeIdsOfAnotherContest.includes(c.StoreUID));
    }

    return Object.assign(store, treeMetadata);
};

export const getGroupedStores = async () => {
    const stores = await fetchGroupedStores();

    const preparedData = stores.map((store) => prepareStoresTree(store));

    return preparedData;
};
