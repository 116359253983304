import { timeLabelTranslations } from '../constants';

export const initialState = {
    settings: {},
    durationUnits: [],
    errors: {},
    highlightedErrorFields: {},
    isFormModified: false,
};

const announcementSettingsActionTypes = {
    UPDATE_VALUE: 'UPDATE_VALUE',
    UPDATE_UNIT: 'UPDATE_UNIT',
    SET_SETTINGS: 'SET_SETTINGS',
    SET_HIGHLIGHTED_ERROR_FIELDS: 'SET_HIGHLIGHTED_ERROR_FIELDS',
    UPDATE_MODIFIED_STATE: 'UPDATE_MODIFIED_STATE'
};

export const updateSettingValueActionCreator = ({ currentSettingType, error, valueKey, newValue }) => ({
    type: announcementSettingsActionTypes.UPDATE_VALUE,
    payload: { currentSettingType, error, valueKey, newValue: Number(newValue) }
});

export const updateSettingUnitActionCreator = ({ currentSettingType, error, unitKey, newUnit }) => ({
    type: announcementSettingsActionTypes.UPDATE_UNIT,
    payload: { currentSettingType, error, unitKey, newUnit }
});

export const updateFormModifiedStateActionCreator = ({ modifiedState }) => ({
    type: announcementSettingsActionTypes.UPDATE_MODIFIED_STATE,
    payload: { modifiedState }
});

export const setSettingActionCreator = ({ settings, durationUnits }) => ({
    type: announcementSettingsActionTypes.SET_SETTINGS,
    payload: { settings, durationUnits }
});

export const setHightlightedErrorFieldsActionCreator = ({ errors = {} }) => ({
    type: announcementSettingsActionTypes.SET_HIGHLIGHTED_ERROR_FIELDS,
    payload: { errors }
});

export const announcementSettingsReducer = (state = initialState, action) => {
    const { settings, errors, highlightedErrorFields } = state;

    switch (action.type) {
        case announcementSettingsActionTypes.UPDATE_VALUE: {
            const { currentSettingType, error, valueKey, newValue } = action.payload;

            const currentSettingData = settings[currentSettingType];

            return {
                ...state,
                settings: {
                    ...settings,
                    [currentSettingType]: {
                        ...currentSettingData,
                        [valueKey]: newValue,
                        error
                    }
                },
                errors: {
                    ...errors,
                    [currentSettingType]: error
                },
                isFormModified: true
            };
        }
        case announcementSettingsActionTypes.UPDATE_UNIT: {
            const { currentSettingType, error, unitKey, newUnit } = action.payload;

            const currentSettingData = settings[currentSettingType];

            return {
                ...state,
                settings: {
                    ...settings,
                    [currentSettingType]: {
                        ...currentSettingData,
                        [unitKey]: {
                            label: timeLabelTranslations.get(newUnit.toLowerCase()),
                            value: newUnit
                        },
                        error
                    }
                },
                errors: {
                    ...errors,
                    [currentSettingType]: error
                },
                isFormModified: true
            };
        }
        case announcementSettingsActionTypes.SET_SETTINGS: {
            const { settings: newSettings, durationUnits } = action.payload;

            return {
                ...state,
                settings: newSettings,
                durationUnits
            };
        }
        case announcementSettingsActionTypes.SET_HIGHLIGHTED_ERROR_FIELDS: {
            const { errors: newHighlightedErrorFields } = action.payload;

            return {
                ...state,
                highlightedErrorFields: {
                    ...highlightedErrorFields,
                    ...newHighlightedErrorFields
                }
            };
        }
        case announcementSettingsActionTypes.UPDATE_MODIFIED_STATE:
            const { modifiedState } = action.payload;
            return {
                ...state,
                isFormModified: modifiedState
            }
        default:
            return state;
    }
};
