import { pbiScheduler } from 'constants/pbiReports';

/**
 * Get the store id list using the store UID
 * @param {object} stores
 * @param {object} available
 * @return {object} store id list
 */
export const getStoresIds = (stores, available) => {
    const result = [];
    if (stores && stores.length > 0) {
        stores.forEach((store) => {
            const foundStore = available.find(({ Store_UID: storeUID }) => storeUID === store);
            if (foundStore) {
                const { Device_Details: deviceDetails } = foundStore;
                if (deviceDetails.length) {
                    result.push(deviceDetails[0].Device_Store_ID);
                }
            }
        });
    }
    return result;
};
/**
 * Get the UID store list using the store id
 * @param {object} stores store list
 * @param {object} available available store list
 * @return {object} uid store list
**/
export const getStoreUID = (stores, available) => {
    const result = [];
    if (stores && stores.length > 0) {
        stores.forEach((storeId) => {
            try {
                const storeFound = available.find(
                        ({ Device_Details: deviceDetails }) => deviceDetails[0].Device_Store_ID === parseInt(storeId)
                );
                if (storeFound) {
                    result.push(storeFound.Store_UID);
                }
            } catch (error) {
                console.error(error);
            }
        });
    }
    return result;
};
/**
* Create a list with the source data
* @param {object} source
* @param {object} translator
* @return {object} sources array with value and index properties
**/
export const formatSource = (source, translator) => (
    source.map((item, index) => (
        {
            text: translator(item),
            value: index
        }
    ))
);

/**
* Create an object with selection sources
* @param {t} translator
* @param {object} Selector Source Object
* @return {object} selector source object
**/
export const createSelectSourceObject = (translator) => {
    const formats = formatSource(pbiScheduler.formats, translator);
    const weekDays = formatSource(pbiScheduler.weekDays, translator);
    const monthDays = formatSource(pbiScheduler.monthDays, translator);
    const {
        RECURRENCE_VALUES, recurrences, reportTypes, hierarchyLevels, timeMeasures, timeFormats, pullInsList, daypartIDsList
    } = pbiScheduler;
    return {
        formats,
        weekDays,
        monthDays,
        recurrences,
        RECURRENCE_VALUES,
        reportTypes,
        hierarchyLevels,
        timeMeasures,
        timeFormats,
        pullInsList,
        daypartIDsList
    };
};

/**
 * This is function to calculate the number of levels present in
 * current branch. If there are no stores in the branch then
 * number of levels will be set to 0.
 * @param {object} branch
 * @returns {integer} level of current branch
 */

export const getHierarchyLevels = (branch) => {
    let level = 0;
    // iterate to last branch in hierarchy
    if (branch.children) {
        for (const it of branch.children) {
            level = Math.max(level, getHierarchyLevels(it));
        }
        /*
            if it's not empty group hierarchy
            then keep incrementing the level
            otherwise do not increment level count
            and leave it to be 0 for the branch.
        */
        if (level)
            return level + 1;
        else
            return 0;
    }
    /*
        if the last branch is group that means there are no stores
        in this branch so return level 0
        otherwise it's a store so increment the level count.
    */
    if (branch.key.includes('Group_') && !branch.children) {
        return 0;
    } else {
        return level + 1;
    }
};
