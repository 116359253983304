import { trimValues, hasAllRequired } from 'helpers';
import { validEmailRegexp } from 'constants/email';

export const trimmedKeys = ['userName', 'firstName', 'lastName'];
const requiredFields = ['userName', 'firstName', 'lastName', 'role'];

export const isUserValid = (user) => {
    const trimmedUser = trimValues(user, trimmedKeys);
    const hasAllRequiredFields = hasAllRequired(trimmedUser, requiredFields);

    if (!hasAllRequiredFields) {
        return false;
    }

    return trimmedUser.userName.length >= 3;
};

export const validateUserData = (user) => {
    if (!user.userName.match(validEmailRegexp)) {
        return ({ invalidEmailFormat: 'add-user__invalid--email' });
    }
    return null;
};
