import { useContext, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InputComponent } from 'components/Inputs';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { Button, BUTTON_VARIANTS } from 'library/Button';

import { HierarchyItemContext } from '../context/HierarchyItemContext';

import './AddItem.scss';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

export const AddItem = ({ defaultName }) => {
    const { t } = useTranslation();

    const parentRef = useRef(null);
    const [name, setName] = useState(defaultName);
    const {
        onItemAdd,
        onItemAddCancel,
        onItemEdit,
        onItemEditCancel,
    } = useContext(HierarchyItemContext);

    const onCancel = useCallback(() => {
        const cancel = defaultName ? onItemEditCancel : onItemAddCancel;

        cancel && cancel();
    }, [defaultName, onItemEditCancel, onItemAddCancel]);

    const onSave = useCallback(() => {
        const save = defaultName ? onItemEdit : onItemAdd;

        save && save(name.trim());
    }, [defaultName, name, onItemEdit, onItemAdd]);

    const onKeyDown = useCallback((event) => {
        if (event.key === 'Escape') {
            onCancel();

            return;
        }

        if (event.key === 'Enter') {
            onSave();
        }
    }, [onCancel, onSave]);

    useClickOutSide(parentRef, onCancel);

    const trimmedName = name.trim();
    const isSaveDisabled = trimmedName.length === 0 || trimmedName === defaultName;

    return (
        <div className='hme-manage-hierarchy__hierarchy__item--add-item' ref={parentRef}>
            <InputComponent value={name} onChange={setName} onKeyDown={onKeyDown} autoFocus={true} />
            <Button className='hme-manage-hierarchy__hierarchy__item--add-item__save' variants={buttonVariants} disabled={isSaveDisabled} onClick={onSave}>{t('common__save')}</Button>
            <Button variants={buttonVariants} onClick={onCancel}>{t('common__cancel')}</Button>
        </div>
    );
};
