import React, { useState, useEffect, useCallback } from 'react';
import { Nav, Navbar, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PARTNER_ROUTES } from 'constants/routes';
import { LanguageSelect, LanguageModal } from 'library/LanguageSelect';
import { isLoggedIn, isAdmin as checkIsAdmin, isPartner as checkIsPartner } from 'services/Auth';

import { MenuItem, ProfileMenuItem, AccountDropdown, AccountDropdownItem, LanguageItem } from '../MenuItem';
import { navigationService } from '../../Common/NavigationServices';
import { baseAuthService } from '../../Security/AuthenticationService';
import { MasqueradeHeader } from '../masqueradeHeader';
import { MoreDropdown } from '../MenuItem/MoreDropdown';

import './MainHeader.scss';

const isTabActive = (selected, { name, allowed }) => allowed ?
    allowed.includes(selected) :
    name === selected;

export const getActiveTab = tabs => {
    const selected = navigationService.getSelectedMenu();

    return tabs.filter(tab => isTabActive(selected, tab))[0] || null;
}

const getProfile = ({ userName, isMasquerade }) => (
    <ProfileMenuItem
        userName={userName}
        url={navigationService.getUrl('accounts')}
        classes={{ masquerade: isMasquerade }}
    >
        <div>
            { isMasquerade && <MasqueradeHeader /> }
        </div>
    </ProfileMenuItem>
);

const getPartnerProfile = ({ userName, isMasquerade }) => (
    <ProfileMenuItem
        userName={userName}
    >
        {isMasquerade && <MasqueradeHeader />}
    </ProfileMenuItem>
);


const isMenuShown = () => baseAuthService.isLoggedIn() &&
    baseAuthService.isShowHeader(window.location.pathname) &&
    !navigationService.isLoginPage();

const getHomeUrl = () => {
    if (isLoggedIn()) {
        return '/welcome';
    }

    return baseAuthService.getAppUrl();
};

export const MainHeader = ({ userName, menuItems, className, title, onLogout, children }) => {
    const [isLngModalOpen, setIsLngModalOpen] = useState(false);
    const { t } = useTranslation();
    const activeTab = getActiveTab(menuItems);
    const isMasquerade = baseAuthService.isMasquerade();
    const [homeUrl, setHomeUrl] = useState('');
    const show = isMenuShown();
    const isAdmin = checkIsAdmin();
    const isPartner = checkIsPartner();

    const profile = isPartner
        ? getPartnerProfile({
            userName,
            isMasquerade,
        })
        : getProfile({
            userName,
            isMasquerade,
        });

    const tabs = menuItems.map(tab => {
        const { name, tKey, icon, additionalClasses } = tab;
        return {
            url: navigationService.getUrl(name),
            label: tKey,
            isActive: tab === activeTab,
            additionalClasses,
            icon,
            name,
        };
    });

    const defaultAccountDropdown = () => {
        return (
            <AccountDropdown profile={profile}>
                <AccountDropdownItem
                    id={`${className}-accounts-user-dropdown-item`}
                    url={navigationService.getUrl("accounts")}
                    icon='icon-account'
                    label={t(isAdmin ? 'common__my-account' : 'my-account__account-info')}
                />
                {!baseAuthService.isAdmin() && <LanguageItem onClick={onLngItemClick} />}
                <AccountDropdownItem
                    id={`${className}-help-user-dropdown-item`}
                    url={navigationService.getUrl("help")}
                    icon='icon-help'
                    label={t('common__help')}
                />
                <Dropdown.Divider />
                <AccountDropdownItem
                    id={`${className}-log-out-user-dropdown-item`}
                    classes='showOnDesktop'
                    icon='icon-log-out'
                    label={t('account-dropdown__log-out')}
                    onClick={() => onLogout()}
                />
            </AccountDropdown>
        )
    }

    const partnerAccountDropdown = () => {
        return (
            <AccountDropdown profile={profile}>
                <LanguageItem onClick={onLngItemClick} />
                <AccountDropdownItem
                    id={`${className}-accounts-user-dropdown-item`}
                    url={navigationService.getUrl(PARTNER_ROUTES.accountInfoFull)}
                    icon="icon-account"
                    label={t('partner__account-info')}
                />
                <AccountDropdownItem
                    id={`${className}-help-user-dropdown-item`}
                    url={navigationService.getUrl("help")}
                    icon='icon-help'
                    label={t('common__help')}
                />
                <AccountDropdownItem
                    id={`${className}-log-out-user-dropdown-item`}
                    classes="showOnDesktop"
                    icon="icon-log-out"
                    label={t('account-dropdown__log-out')}
                    onClick={() => onLogout()}
                />
            </AccountDropdown>
        );
    };

    const renderAccountDropdown = () => isPartner ? partnerAccountDropdown() : defaultAccountDropdown();

    const onLngItemClick = useCallback(() => {
        setIsLngModalOpen(true);
    }, [setIsLngModalOpen]);

    const onLngModalHide = useCallback(() => {
        setIsLngModalOpen(false);
    }, [setIsLngModalOpen]);

    const onLngChange = useCallback(() => {
        setIsLngModalOpen(false);
    }, [setIsLngModalOpen]);

    useEffect(() => {
        setHomeUrl(getHomeUrl());
    }, [setHomeUrl]);

    return (
        <>
            <header className="reports-page-header hme-main-header">
                <Navbar expand="lg">
                    <Navbar.Brand>
                        <a href={`${homeUrl}`}>{title}</a>
                    </Navbar.Brand>
                    { children ? <span className="showOnMobile">{children}</span> : null }
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {show ? (<div>
                            <Nav className={className}>
                                {tabs.map(({ name, ...tab }) => (
                                    <MenuItem { ...tab} id={`${className}-${name}-menu-item`} key={name} />
                                ))}
                                <MoreDropdown menuItems={tabs} />
                                <>
                                    <span className="showOnDesktop">{children}</span>
                                    <span className="showOnDesktop">{profile}</span>
                                </>
                                {
                                    renderAccountDropdown()
                                }
                            </Nav>
                            <Nav className="showOnMobile">
                                <>
                                    <hr className="showOnMobile" />
                                    <Nav.Item onClick={() => onLogout()} className="headerMenu" id={`${className}-mobile-log-out-user-dropdown-item`}>
                                        <i className="icon icon-log-out" />
                                        {t('account-dropdown__log-out')}
                                    </Nav.Item>
                                </>
                            </Nav>
                        </div>) : (!baseAuthService.isAdmin() && <LanguageSelect />)}
                    </Navbar.Collapse>
                </Navbar>
            </header>
            <LanguageModal show={isLngModalOpen} onHide={onLngModalHide} onChange={onLngChange} />
        </>
    );
};
