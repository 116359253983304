export const gridHeaderOptions = [{
    text: 'apply-device-settings-status__grid-title__source-device',
    property: [{
        text: 'common__device__serial-number',
        property: 'sourceDevice.Device_SerialNumber',
        className: 'apply-job-serial-number',
        flex: 1.5,
    }
    , {
        text: 'common__device__product-id',
        property: 'sourceDevice.Device_Product_ID',
        className: 'hme-grid-cell-show-all-content apply-job-serial-number',
        flex: 1.5,
    }, {
        text: 'common__store__name',
        property: 'sourceDevice.Store_Name',
    }, {
        text: 'common__brand',
        property: 'sourceBrandName',
    }, {
        text: 'common__store__number',
        property: 'sourceDevice.Store_Number',
    }, {
        text: 'common__device__version',
        property: 'sourceDevice.Device_MainVersion',
    }, {
        text: 'apply-device-settings-status__grid-header__applied-settings',
        property: 'settingsGroupsText',
        flex: 2,
    }],
}, {
    text: 'apply-device-settings-status__grid-title__task-information',
    property: [{
        text: 'apply-device-settings-status__grid-header__devices-count',
        property: 'devicesCount',
        className: 'apply-job-device-count',
    }, {
        text: 'common__device-type',
        property: 'deviceTypeName',
        flex: 1,
    }, {
        text: 'apply-device-settings-status__grid-header__start-date',
        property: 'createdAtTime',
        flex: 1.5,
    }, {
        text: 'apply-device-settings-status__grid-header__applied-by',
        property: 'appliedBy',
        flex: 1.5,
    }, {
        text: 'apply-device-settings-status__grid-header__task-status',
        property: 'taskStatusCell',
        headerClassName: 'public-apply-device-settings-status-page-target-status-cell',
    }],
}];

export const tileListHeaderOptions = [
    {
        title: 'common__source-devices',
        column: [
            { text: 'common__device__serial-number', property: 'sourceDevice.Device_SerialNumber' },
            { text: 'common__device__product-id', property: 'sourceDevice.Device_Product_ID' },
            { text: 'common__store__name', property: 'sourceDevice.Store_Name' },
            { text: 'common__brand', property: 'sourceBrandName' },
            { text: 'common__store__number', property: 'sourceDevice.Store_Number' },
            { text: 'common__device__version', property: 'sourceDevice.Device_MainVersion' },
            { text: 'common__applied-settings-device', property: 'settingsGroupsText' },
        ],
    },
    {
        toggleable: true,
        title: 'common__target-devices',
        buttonShowText: 'apply-device-settings-status__show-target-devices',
        buttonHideText: 'apply-device-settings-status__hide-target-devices',
        subcolumnField: 'destinationDevices',
        subcolumn: [
            { text: 'common__device__serial-number', property: 'Device_SerialNumber' },
            { text: 'common__device__product-id', property: 'Device_Product_ID' },
            { text: 'common__store__name', property: 'Store_Name' },
            { text: 'common__brand', property: 'Brand_Name' },
            { text: 'common__store__number', property: 'Store_Number' },
            { text: 'common__device__version', property: 'Device_MainVersion' },
            { text: 'common__device-status', property: 'deviceStatusCell' },
        ],
    },
    {
        title: 'common__task-information',
        column: [
            { text: 'common__number-of-devices', property: 'devicesCount' },
            { text: 'common__device-type', property: 'deviceTypeName' },
            { text: 'common__start-date-time', property: 'createdAtTime' },
            { text: 'common__applied-by', property: 'appliedBy' },
            { text: 'common__task-status', property: 'taskStatusCell' },
        ],
    },
];

