import React from 'react';
import { compose } from 'ramda';

import { Form } from '../Form';
import { useCreate } from './hooks/useCreate';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';

import { NewLanguageEditor } from './NewLanguageEditor';

const CreateComponent = () => {
    const state = useCreate();

    return (
        <Form {...state} Editor={NewLanguageEditor} />
    );
};

const hasAccess = checkAccess(CommonConstants.adminPermissions.CustomerNotification);

export const Create = compose(
    withHMELayout(),
    withAccess({
        checkAccess: () => hasAccess
    }),
    authenticate
)(CreateComponent);
