import { CorporateBrandSharing } from 'pages/CorporateBrandSharing';
import { useCorporate } from './hooks/useCorporate';

export const Corporate = () => {
    const state = useCorporate();

    return (
        <CorporateBrandSharing {...state} />
    );
};
