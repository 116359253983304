import { useTranslation } from 'react-i18next';

import { ContactHME } from 'library/ContactHME';
import { VaioPartnerGuide } from 'library/VaioPartnerGuide';

import './Partner.scss';

export const Partner = () => {
    const { t } = useTranslation();

    return (
        <div className="hme-help-page hme-help-page--partner">
            <div className="hme-help__container">
                <p className="hme-help__header-text">{t('common__help')}</p>
                <ContactHME />
                <VaioPartnerGuide />
            </div>
        </div>
    );
};
