export const getQueryString = (paramsString) => {
    const searchParams = new URLSearchParams(paramsString);

    searchParams.delete('uuid');
    searchParams.delete('roleuid');
    searchParams.delete('isOwnerRole');
    searchParams.delete('isAccountOwner');

    return `?${searchParams.toString()}`;
};
