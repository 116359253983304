import React from 'react'
import './NoDevicesComponent.css'

export default ({ message }) => {
  return (
    <div>
      <div className='no-devices-message'>
        <span>{message}</span>
      </div>
    </div>
  )
}

