/* eslint-disable react/prop-types */
import './MessagesLanding.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'components/Common/Grid';
import { Slider } from 'library/Slider';
import { Button } from 'library/Button';
import { empty, pluck, uniq } from 'ramda';
import customSmackTalkApi from 'api/customSmackTalkApi';

import classNames from 'classnames';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { Config } from 'Config';
import { ReactComponent as CloseCross } from '../../../../assets/icons/close-cross.svg';
import { ReactComponent as Pencil } from '../../../../assets/icons/IconEditBlue.svg';
import { LandingTiles } from './LandingTiles';
import { LandingSubHeader } from './LandingSubHeader';
import { gridFiltersCounter } from '../../../../helpers/filters';
import { LocalStorageContext } from '../../../../HOCs/withLocalStorageContext';
import sortBySelection from '../../../../components/DeviceUpgrades/helpers/sortBySelection';
import { SpanSeparator } from '../../../../library/SpanSeparator';
import { useTranslation } from 'react-i18next';
import { categoryKeys, messageKeys, typeKeys } from '../../constants';
import { defaultUser, formatDateTime, isDefaultUser } from './helpers';

const gridHeaders = [{
    text: 'common__category',
    property: 'category',
    flex: '2',
    sortable: true
}, {
    text: 'common__message',
    property: 'message',
    flex: '7',
    sortable: true
}, {
    text: 'common__created-by',
    property: 'createdBy',
    flex: '7',
    sortable: true
}, {
    text: 'common__created-date-n-time',
    property: 'formattedDateTime',
    flex: '7',
    sortable: false
}, {
    text: 'common__actions',
    property: 'actions',
    flex: '4'
}];

const defaultCategoryIcon = 'icons/smacktalk-category-congratulate.png';
const CUSTOM_CATEGORY_ID = 4;

const getURL = ({ createdBy, createdByUUID })=> {
    if (isDefaultUser(createdBy)) return createdBy;
    return <a href={`/settings/users/user?uuid=${createdByUUID}`}>{createdBy}</a>;
};

export const MessagesLanding = ({ data, loader, onCreateMessage, onDeleteMessage }) => {
    const {
        getConfig = empty,
        updateConfig = empty
    } = useContext(LocalStorageContext);
    const [messages, setMessages] = useState([]);
    const [messagesPopulated, setMessagesPopulated] = useState([]);
    const [messagesFiltered, setMessagesFiltered] = useState([]);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [gridFilters, setGridFilters] = useState({
        category: [],
        status: []
    });
    const [availableFilters, setAvailableFilters] = useState([]);
    const [initialFilters, setinitialFilters] = useState([]);
    const [filtersCounter, setFiltersCounter] = useState(0);
    const [sortSelection, setSortSelection] = useState({});
    const [messagesSorted, setMessagesSorted] = useState([]);
    const { t } = useTranslation();

    const removeLabel = t('common__remove');
    const editLabel = t('common__edit');
    const noRecordsMessage = t('common__error--no-data-found');
    const sectionLables = {
        category: { title: t('common__category') },
        status: { title: t('smack-talk__tab__messages-landing--disabled-message').toUpperCase() }
    };

    const prepareMessages = async (data) => {
        try {
            const newMessages = data.map((el) => {
                const isCustom = el['SmackTalkCategoryID'] === CUSTOM_CATEGORY_ID;
                const message = {
                    category: el['CategoryDesc'],
                    categoryIconPath: el['CategoryIcon'],
                    message: isCustom ? el['MessageText'] : el['MessageKey'],
                    type: el['MessageType'],
                    status: !el['MessageExclusionID'],
                    messageId: Number(el['MessageID']),
                    isCustom,
                    createdBy: el['CreatedBy'],
                    createdByUUID: el['CreatedByUUID'],
                    createdDate: el['CreatedDate']
                };
                return message;
            });
            setMessages(newMessages);
            const categories = pluck('category', newMessages);
            const uniqCategories = uniq(categories).sort();
            const { filters: { status = [] } = {} } = getConfig() || {};
            const initialFilters = {
                category: uniqCategories,
                status: []
            };
            setinitialFilters(initialFilters);
            setGridFilters({ ...initialFilters, status });
            setAvailableFilters([
                {
                    property: 'category',
                    allText: t('common__all'),
                    items: uniqCategories.map((category) => ({
                        value: category,
                        text: t(categoryKeys[category])
                    }))
                },
                {
                    property: 'status',
                    allText: t('common__all'),
                    items: [{
                        value: 'hide',
                        text: t('smack-talk__tab__messages-landing--hide-disabled').toUpperCase()
                    }]
                }
            ]);
        } catch (e) {
            console.error(e);
        }
    };
    const onSliderChange = (value, checked) => {
        const clone = [...messages];
        const index = clone.findIndex((el) => el['messageId'] === value);
        if (!~index) {
            return;
        }
        const message = Object.assign({}, clone[index]);
        message['status'] = checked;
        clone[index] = message;
        setMessages(clone);
        (async () => {
            try {
                setUpdateLoader(true);
                await customSmackTalkApi.updateStatus({
                    MessageID: message.messageId,
                    newSmacktalkStatus: message.status
                });
            } catch (e) {
                console.error(e);
                await prepareMessages(data);
            } finally {
                setUpdateLoader(false);
            }
        })();
    };

    const onDeleteMessageClick = (message) => {
        setSelectedMessage(message);
        setShowConfirmPopup(true);
    };

    const addons = (message, icons = false) => (
        <>
            <Button
                variants={['transparent']}
                onClick={() => onDeleteMessageClick(message)}
            >
                &nbsp;{icons ? <CloseCross/> : ''}{removeLabel}
            </Button>
            <SpanSeparator />
            <Button
                variants={['transparent']}
                onClick={() => onCreateMessage(message)}
            >
                &nbsp;{icons ? <Pencil/> : ''}{editLabel}
            </Button>
        </>
    );
    const populateActions = (data = []) => {
        const newData = data.map((el) => {
            const row = { ...el };
            row['actions'] = (
                <>
                    <div className="hme-messages-action">
                        <Slider
                            checked={row.status}
                            onSliderChange={onSliderChange}
                            value={row['messageId']}
                            readOnly
                            label=' '
                        />
                    </div>
                    {row.isCustom ? addons(row) : ''}
                </>
            );
            const icon = row['categoryIconPath'] || defaultCategoryIcon;
            row['categoryIcon'] = (
                <>
                    <div className={classNames('hme-manage-messages-category', { active: row.status })}>
                        <img src={`${Config.blobNitroStorageBaseUrl}/${icon}`} alt={row['categoryName']} />
                    </div>
                </>
            );
            row['slider'] = (
                <div className="hme-messages-action">
                    <Slider
                        checked={row['status']}
                        onSliderChange={onSliderChange}
                        value={row['messageId']}
                        readOnly
                        label=' '
                    />
                </div>
            );
            row['editRemoveAddons'] = (
                row.isCustom ? addons(row, true) : ''
            );
            return row;
        });
        return newData;
    };

    const onConfirmDelete = async () => {
        if (selectedMessage) {
            await onDeleteMessage(selectedMessage);
            setSelectedMessage(null);
        }
    };

    const onCancelDelete = () => {
        setSelectedMessage(null);
        setShowConfirmPopup(false);
    };

    useEffect(() => {
        const status = gridFilters.status && gridFilters.status.length ? [true] : [true, false];
        const filters = { ...gridFilters, status };
        const filtered = messagesPopulated.filter(
                (message) => {
                    const keys = Object.keys(filters);
                    return keys
                            .map((k) => filters[k].includes(message[k]))
                            .every((m) => m);
                }
        );
        const translated = filtered.map((el) => {
            if (el.createdBy === defaultUser.email) {
                el.createdBy = defaultUser.name;
                el.createdByUUID = '#';
            }
            const message = {
                category: t(categoryKeys[el.category]),
                categoryIcon: el.categoryIcon,
                message: t(messageKeys[el.message]) || el.message,
                type: t(typeKeys[el.type]),
                status: el.status,
                messageId: el.messageId,
                actions: el.actions,
                createdBy: getURL(el),
                createdDate: el.createdDate,
                formattedDateTime: formatDateTime(el.createdDate, el.createdBy),
                slider: el.slider,
                editRemoveAddons: el.editRemoveAddons
            };
            return message;
        });
        setMessagesFiltered(translated);
    }, [gridFilters, messagesPopulated]);

    useEffect(() => {
        const populated = populateActions(messages);
        setMessagesPopulated(populated);
    }, [messages]);

    useEffect(() => {
        prepareMessages(data);
    }, [data, t]);

    useEffect(() => {
        setFiltersCounter(gridFiltersCounter(availableFilters, initialFilters, gridFilters));
        updateConfig({ filters: { status: gridFilters.status } });
    }, [availableFilters, gridFilters]);

    useEffect(()=>{
        setMessagesSorted(sortBySelection(messagesFiltered, sortSelection));
    }, [sortSelection, messagesFiltered]);

    const resetFilters = ()=>{
        setGridFilters(initialFilters);
    };

    return (
        <div className={classNames('custom-smacktalk-page-messages-landing', { 'progress-cursor': updateLoader })}>
            <ConfirmPopupComponent
                show={showConfirmPopup}
                title={t('common__double-checking')}
                dialogClassName="confirm-popup custom-smacktalk-page-messages-landing-popup"
                message={<>
                    <div className="custom-smacktalk-confirm-line">
                        {t('smack-talk__tab__messages-landing--are-you-sure')}
                    </div>
                </>}
                onHide={() => onCancelDelete()}
                actions={[{
                    children: t('common__no-go-back'),
                    onClick: () => onCancelDelete()
                }, {
                    children: t('common__continue'),
                    variants: ['submit'],
                    onClick: () => {
                        onConfirmDelete();
                        setShowConfirmPopup(false);
                    }
                }]}
            />
            <div className={classNames('hme-messages-landing-container', { 'disabled': updateLoader })}>
                {messages && Boolean(messages.length) &&
                    <LandingSubHeader
                        filtersCounter={filtersCounter}
                        resetFilters={resetFilters}
                        gridFilters={gridFilters}
                        setGridFilters={setGridFilters}
                        isManageMessages={true}
                    >
                        <Button onClick={() => onCreateMessage()}>
                            {t('smack-talk__tab__messages-landing--create-custom-message')}
                        </Button>
                    </LandingSubHeader>
                }
                <div className="hme-messages-landing-grid-wrapper">
                    <Grid
                        rows={messagesSorted}
                        headers={gridHeaders}
                        rowKey="messageId"
                        isLoading={loader}
                        filters={gridFilters}
                        availableFilters={availableFilters}
                        onFiltersChange={setGridFilters}
                        noRecordsMessage={noRecordsMessage}
                        onSortChange={setSortSelection}
                        sortSelection={sortSelection}
                        loadingText={t('common__loading')}
                    />
                </div>
            </div>
            <LandingTiles
                tiles={messagesSorted}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
                loader={updateLoader}
                isLoading={loader}
                availableFilters={availableFilters}
                sectionLables={sectionLables}
                filtersCounter={filtersCounter}
                resetFilters={resetFilters}
                noRecordsMessage={noRecordsMessage}
                createMessage={onCreateMessage}
            />
        </div>
    );
};
