import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Grid } from 'components/Common/Grid';

import './StoreGrid.css';

const gridHeaders = [
    {
        text: 'device-upgrades__grid__header--store-number',
        property: 'Store_Number',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'device-upgrades__grid__header--device-serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'device-upgrades__grid__header--brand',
        property: 'Brand_Name',
        className: 'hme-grid-cell-show-all-content',
    },
];

export const StoreGrid = ({ stores = [], selectedStores, onStoreSelection }) => {
    return (
        <div className="hme-create-device-upgrade-store">
            <div className="hme-create-device-upgrade-store-info">
                <Trans i18nKey={'device-upgrades__section__filter__store__count-devices'} deviceCount={stores.length}>
                    <span className="hme-create-device-upgrade-store-count">{{ deviceCount: stores.length }}</span> device was found.
                </Trans>
            </div>

            <Grid
                rows={stores}
                headers={gridHeaders}
                rowKey="Store_Number"
                isLoading={false}
                selectable
                selection={selectedStores}
                onSelectionChange={onStoreSelection}
            />
        </div>
    );
};
