import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GridActions } from 'library/GridActions';
import { BUTTON_VARIANTS, Button } from 'library/Button';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

const ViewLink = ({ uid, children }) => {
    return (
        <Button
            as={Link}
            to={uid}
            variants={buttonVariants}
            className="hme-contest-actions__view-link"
        >
            {children}
        </Button>
    );
};

const DefaultProviderActions = ({ uid }) => {
    const { t } = useTranslation();

    return (
        <GridActions className="hme-contest-actions">
            <ViewLink uid={uid}>{t('common__view-edit')}</ViewLink>
        </GridActions>
    );
};

const PROVIDER_STATUS_CELL_ACTIONS = {
    default: DefaultProviderActions
};

export const Actions = ({ uid, providerType = 'default' }) => {
    const Component = PROVIDER_STATUS_CELL_ACTIONS[providerType];

    return <Component uid={uid} />;
};
