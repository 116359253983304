import React from 'react';
import { useTranslation } from 'react-i18next';

import './ApplyInProgressStatus.scss';

export const ApplyInProgressStatus = () => {
    const { t } = useTranslation();

    return (
        <span className="apply-job-apply-in-progress-cell">
            {t('common__task__status--apply-in-progress')}
        </span>
    );
};
