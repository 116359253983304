import { useCallback } from 'react';

import { OnOff } from 'library/OnOff';
import { Label } from 'library/Label';

import './Brand.scss';

export const Brand = ({ brandId, brandName, brandShared, onChange }) => {
    const onBrandChange = useCallback((checked) => {
        onChange && onChange(brandId, checked);
    }, [brandId, onChange]);
    
    return (
        <div className='hme-brand-sharing__brand'>
            <Label>{ brandName }</Label>
            <OnOff checked={!!brandShared} onChange={onBrandChange} />
        </div>
    );
};
