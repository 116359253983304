import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';

import { isAdmin } from 'services/Auth';
import { PAGE_DATA } from 'constants/paginationDefault';
import { NotificationsList } from 'library/NotificationsList';
import { Header } from '../Common/Header';
import { List } from '../Common/List';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { Paginate } from 'library/Paginate';
import { useUsersList } from './hooks/useUsersList';
import { TileList } from 'library/TileList';
import { MOBILE_USERS_HEADERS } from '../../constants/mobileUsersHeders';
import { MOBILE_USERS_SORT_OPTIONS } from '../../constants/mobileUsersSortOptions';

import './UsersList.scss';

const PAGE_SIZES = isAdmin() ? PAGE_DATA.PAGE_SIZES_ADMIN : PAGE_DATA.PAGE_SIZES_PUBLIC;

const UsersListComponent = () => {
    const { t } = useTranslation();
    const state = useUsersList();

    const {
        isConfirmModalOpen,
        onCancelRemoveUser,
        onConfirmRemoveUser,
        recordsTotal,
        paginationData,
        onPaginateChange
    } = state;

    return (
        <div className="hme-users--list">
            <NotificationsList />
            <Header {...state} />
            <List {...state} />
            <TileList
                {...state}
                headers={MOBILE_USERS_HEADERS}
                noRecordsMessage="users__no-users-found"
                rowKey="User_UID"
                sortOptions={MOBILE_USERS_SORT_OPTIONS}
            />
            <Paginate
                total={recordsTotal}
                page={paginationData.pageNumber}
                onChange={onPaginateChange}
                recordsPerPage={paginationData.pageSize}
                pageSizes={PAGE_SIZES}
            />
            <ConfirmPopupComponentGeneric
                show={isConfirmModalOpen}
                title={t('common__double-checking')}
                message={t('add-user__confirm--remove')}
                actions={[{
                    children: t('common__popup--cancel-action'),
                    onClick: onCancelRemoveUser
                }, {
                    children: t('common__popup--confirm-action'),
                    variants: ['submit'],
                    className: 'hme-users--distributor-account--confirm-button',
                    onClick: onConfirmRemoveUser
                }]}
            />
        </div>
    );
};

export const UsersList = compose(withHMELayout())(UsersListComponent);
