import { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { getAdminAccounts } from 'services/Account';

const SEARCH_DEBOUNCE_TIMEOUT = 800;
const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;

export const useLoadAccounts = ({ search = '' } = {}) => {
    const [accounts, setAccounts] = useState([]);
    const [isAccountsLoading, setIsAccountsLoading] = useState(false);
    const [accountsLoadingError, setAccountsLoadingError] = useState(null);

    const loadAccounts = useCallback(async ({ search }) => {
        try {
            if (search.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH) {
                setAccounts([]);
                return;
            }

            setIsAccountsLoading(true);
            setAccountsLoadingError(null);

            const accountList =
                (await getAdminAccounts({
                    itemsPerPage: -1,
                    searchField: 'User_EmailAddress',
                    search,
                })) || [];

            setAccounts(accountList);
        } catch (e) {
            setAccounts([]);
            setAccountsLoadingError(e);
        }

        setIsAccountsLoading(false);
    }, []);

    const loadAccountsDebounced = useMemo(() => debounce(loadAccounts, SEARCH_DEBOUNCE_TIMEOUT), [loadAccounts]);

    useEffect(() => {
        setIsAccountsLoading(true);
        loadAccountsDebounced.cancel();
        loadAccountsDebounced({ search });
    }, [search]);

    return { accounts, isAccountsLoading, setAccounts, accountsLoadingError, loadAccountsDebounced };
};
