import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputComponent as Input } from 'components/Inputs';
import { accountTypes } from 'constants/accountTypes';
import { Label } from 'library/Label';
import { Slider } from 'library/Slider';
import { ErrorLabel } from 'library/ErrorLabel';

import './SettingsInfoForm.scss';

export const SettingsInfoForm = ({
    permissionType,
    deviceSettingsName,
    deviceSettingsVersionName,
    settingsInfo,
    formErrors,
    onSettingsInfoChange,
}) => {
    const { t } = useTranslation();
    const onSettingsNameChange = useCallback(
        (name) => {
            onSettingsInfoChange({
                ...settingsInfo,
                name,
            });
        },
        [settingsInfo, onSettingsInfoChange],
    );

    const onSettingsNameSuffixChange = useCallback(
        (nameSuffix) => {
            onSettingsInfoChange({
                ...settingsInfo,
                nameSuffix: nameSuffix.replace(/[^a-z0-9]/gi, ''),
            });
        },
        [settingsInfo, onSettingsInfoChange],
    );

    const onSettingsDescriptionChange = useCallback(
        (description) => {
            onSettingsInfoChange({
                ...settingsInfo,
                description,
            });
        },
        [settingsInfo, onSettingsInfoChange],
    );

    const onSettingsVersionChange = useCallback(
        (version) => {
            onSettingsInfoChange({
                ...settingsInfo,
                version,
            });
        },
        [settingsInfo, onSettingsInfoChange],
    );

    const onBrandApprovedChange = useCallback(
        (_, isBrandApproved) => {
            onSettingsInfoChange({
                ...settingsInfo,
                isBrandApproved,
            });
        },
        [settingsInfo, onSettingsInfoChange],
    );

    const onTestSnapshotChange = useCallback(
        (_, isTestSnapshot) => {
            onSettingsInfoChange({
                ...settingsInfo,
                isTestSnapshot,
            });
        },
        [settingsInfo, onSettingsInfoChange],
    );

    const isPublic = permissionType === accountTypes.PUBLIC;

    return (
        <div className="create-snapshot-setting-info-form">
            <div className="create-snapshot-setting-info-form-box">
                <Input
                    className="create-snapshot-info-name"
                    label="settings_snapshots__create_snapshot__form--name"
                    value={deviceSettingsName}
                    maxLength={100}
                    onChange={onSettingsNameChange}
                    isDisabled={!isPublic}
                />
                {formErrors.settingName && <ErrorLabel>{t(formErrors.settingName)}</ErrorLabel>}
            </div>
            {!isPublic && (
                <>
                    <div className="create-snapshot-setting-info-form-box">
                        <Input
                            className="create-snapshot-info-suffix"
                            label="settings_snapshots__create_snapshot__form--name-suffix"
                            value={settingsInfo.nameSuffix}
                            maxLength={30}
                            onChange={onSettingsNameSuffixChange}
                        />
                        {formErrors.settingName && <ErrorLabel>{t(formErrors.nameSuffix)}</ErrorLabel>}
                    </div>
                    <div className="create-snapshot-setting-info-form-box">
                        <Input
                            className="create-snapshot-info-description"
                            label="settings_snapshots__create_snapshot__form--description"
                            value={settingsInfo.description}
                            maxLength={100}
                            onChange={onSettingsDescriptionChange}
                        />
                        {formErrors.description && <ErrorLabel>{t(formErrors.description)}</ErrorLabel>}
                    </div>
                </>
            )}

            <div className="create-snapshot-setting-info-form-section">
                <div className="create-snapshot-setting-info-form-box create-snapshot-setting-info-form-settings-version">
                    <Input
                        className="create-snapshot-info-description"
                        label="common__snapshot__version"
                        value={deviceSettingsVersionName}
                        maxLength={50}
                        onChange={onSettingsVersionChange}
                        isDisabled
                    />
                    {formErrors.settingsVersion && <ErrorLabel>{t(formErrors.settingsVersion)}</ErrorLabel>}
                </div>
                {!isPublic && (
                    <div className="create-snapshot-setting-info-form-sliders">
                        <div className="create-snapshot-setting-info-form-box">
                            <Label>{t('settings_snapshots__create_snapshot__form--brand-approved')}</Label>
                            <Slider
                                label={
                                    settingsInfo.isBrandApproved
                                        ? t('common__checkbox--on')
                                        : t('common__checkbox--off')
                                }
                                value={settingsInfo.isBrandApproved}
                                checked={settingsInfo.isBrandApproved}
                                onSliderChange={onBrandApprovedChange}
                            />
                        </div>
                        <div className="create-snapshot-setting-info-form-box">
                            <Label>{t('settings_snapshots__create_snapshot__form--test-snapshot')}</Label>
                            <Slider
                                label={
                                    settingsInfo.isTestSnapshot ? t('common__checkbox--on') : t('common__checkbox--off')
                                }
                                value={settingsInfo.isTestSnapshot}
                                checked={settingsInfo.isTestSnapshot}
                                onSliderChange={onTestSnapshotChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
