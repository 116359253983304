import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';

class ErrorAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMultiple: false
        };
    }
    render() {
        const { className, t } = this.props;
        if (this.props.errorMessage && !this.props.errors) {
            if (this.props.errorMessage !== '' && this.props.errorMessage !== undefined && this.props.errorMessage !== null) {
                return (
                    <div className={className ? className : `alert alert-danger errors`}>
                        <InfoIcon className={`info-icon-white-svg me-4`} />
                        <span>
                            {t(this.props.errorMessage)}
                        </span>
                    </div>
                );
            }
        } else if (this.props.errors) {
            if (this.props.errors.length !== 0) {
                return (
                    <div className="alert alert-danger errors">
                        <ul>
                            {this.renderErrors(this.props.errors)}
                        </ul>
                    </div>
                );
            } else {
                return (
                    <div />
                );
            }
        } else {
            return (
                <div />
            );
        }
    }
    renderErrors(errors) {
        const { t } = this.props;

        if (errors) {
            const errorList = errors.map(function(error, index) {
                return (<li key={index}>{t(error)}</li>);
            });
            return errorList;
        }
    }
}

export default compose(
    withTranslation()
)(ErrorAlert);
