import { useEffect, useCallback } from 'react';

import { NotificationCenterService as service } from 'services/NotificationCenter';
import { addErrorNotification } from 'services/Notifications';
import { useAsync } from 'hooks/useAsync';

export const defaultNotifications = [];

export const useLoadNotifications = ({ changeHasUnread }) => {
    const {
        run,
        isLoading: isNotificationsLoading,
        data: notifications,
    } = useAsync({
        data: defaultNotifications
    });

    const loadNotifications = useCallback(() => {
        return run(service.fetchAll().then((notifications) => {
            if (!notifications.length) {
                changeHasUnread(false);
            }

            return notifications;
        }).catch(err => addErrorNotification(err.message)));
    }, [changeHasUnread]);

    useEffect(() => {
        loadNotifications();
    }, []);

    return {
        isNotificationsLoading,
        notifications: notifications || defaultNotifications,
        loadNotifications,
    };
}
