/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import * as R from 'ramda';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { withMasqueradeUser } from 'HOCs/withMasqueradeUser';
import { compose } from 'ramda';
import { TrophyCase } from '../TrophyCase/TrophyCase';
import './AwardsResettable.scss';
import { ReactComponent as CogIcon } from 'assets/icons/cog.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import storeApi from 'api/storeApi';
import awardApi from 'api/awardApi';
import pdfApi from '../../../api/pdfApi';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { ConfigureAwards } from './ConfigureAwards';
import { EnhancedFilter } from './HeaderMenu/EnhancedFilter/EnhancedFilter';
import { FilterInfo } from './FilterInfo/FilterInfo';
import { ResetHistory } from '../ResetHistory';
import { NotificationsList } from 'library/NotificationsList';
import { addNotification, dismissNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { useTranslation } from 'react-i18next';
import { DateLib } from '@hme-cloud/utility-common';
import { noOfPreviousMonths } from 'containers/Awards/AwardsResettable/config'

const RESET_AWARDS_RESTRICTION_TIME = 1; // days

const Awards = ({ masqueradeUser }) => {
    const { t, i18n } = useTranslation();
    const views = [
        { value: 'summary', label: t('common__summary').toUpperCase() },
        { value: 'detailed', label: t('common__details').toUpperCase() },
        { value: 'history', label: t('common__reset-history').toUpperCase(), Component: ResetHistory }
    ];

    const menuItems = ({ Filter }) => ([
        {
            label: t('awards__configure-awards'),
            Icon: CogIcon,
            name: 'config'
        },
        {
            label: `${t('common__download')} CSV`,
            Icon: DownloadIcon,
            name: 'csv'
        },
        {
            label: `${t('common__download')} PDF`,
            Icon: DownloadIcon,
            name: 'pdf'
        },
        {
            label: t('awards__awards-resettable--filter-awards'),
            Icon: Filter,
            name: 'filter'
        },
        {
            label: t('awards__awards-resettable--reset-awards-count'),
            Icon: RefreshIcon,
            name: 'reset'
        }
    ]);

    // Display Trophy case only for the current month by default on load
    const curDate = new DateLib().addMonths(-noOfPreviousMonths);
    let startMonthIdx = Number(curDate.format(DateLib.FORMAT_TYPES.SINGLE_MONTH_ONLY));

    const currDate = new DateLib();
    const curMonth = Number(currDate.format(DateLib.FORMAT_TYPES.SINGLE_MONTH_ONLY));

    if(startMonthIdx === 13) 
        startMonthIdx = 1;

    let noOfYears = 1;
    if(startMonthIdx + noOfPreviousMonths > 12)
        noOfYears = 2;

    const startYear = Number(currDate.format(DateLib.FORMAT_TYPES.YEAR_ONLY)) - noOfYears;
    const [year, setYear] = useState(currDate.format(DateLib.FORMAT_TYPES.YEAR_ONLY));
    const [month, setMonth] = useState(currDate.format(DateLib.FORMAT_TYPES.SINGLE_MONTH_ONLY));
    const [stores, setStores] = useState([]);
    const [allStores, setAllStores] = useState([]);
    const [groupedStores, setGroupedStores] = useState([]);
    const [storeTree, setStoreTree] = useState([]);
    const [typeOfView, setTypeOfView] = useState(views[0].value);
    const [trophyCase, setTrophyCase] = useState([]);
    const [loadingTrophyCase, setLoadingTrophyCase] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [isPopupShown, setIsPopupShown] = useState(false);
    const [isResetAvailable, setIsResetAvailable] = useState(false);
    const [disablePDF, setDisablePDF] = useState(false);

    const onHidePopup = () => setShowConfirmPopup(false);

    const getStoreUIDs = (newGroupedStores) => {
        return newGroupedStores.reduce(
                (allStores, { Stores }) => R.uniq(R.concat(allStores, R.pluck('StoreUID', Stores))),
                []
        );
    };
    const getStoreTree = (newGroupedStores) => newGroupedStores.map((group) => ({
        id: group.GroupID,
        name: group.GroupName,
        children: group.Stores.map((store) => ({
            text: store.StoreName ? `${store.StoreNumber}-${store.StoreName}`: store.StoreNumber,
            value: store.StoreUID
        }))
    }));

    const getDates = (newYear, newMonth) => {
        const isAllYears = String(newYear) === String(startYear);
        const unit = Number(newMonth) && !isAllYears ? 'month' : 'year';
        const selectedYear = new DateLib().toUTC().setYear(newYear);

        Number(newMonth) && selectedYear.setMonth(newMonth - 1);
        selectedYear.startOf(unit);
        const startDate = selectedYear.format();

        const selectedEndDate = isAllYears ? new DateLib().toUTC().endOfYear() : selectedYear.endOf(unit);

        const endDate = selectedEndDate.format();

        return [startDate, endDate];
    };

    const getTrophyCount = ( trophy ) => {
        let trophyCount = 0;
        trophy.forEach((val) => {
            trophyCount += (val.contests.length + val.awards.length);
        });
        console.log(`Trophy Count: `, trophyCount);
        return trophyCount;
    };

    const checkValidMonth = (mth, yr) => {
        const selectedMonth = Number(mth);
        const curYear = Number(currDate.format(DateLib.FORMAT_TYPES.YEAR_ONLY));
        const selectedYear = Number(yr) || curYear;

        if((selectedYear < curYear && selectedMonth >= startMonthIdx) || 
        (selectedYear === curYear && selectedMonth <= curMonth))
            return true;
        return false;
    }; 

    const updateAwards = async ({
        year: newYear = year,
        month: newMonth = month,
        stores: newStores = stores
    }) => {
        setLoadingTrophyCase(true);
        setYear(newYear);
        setMonth(newMonth);
        setStores(newStores);
        const [startDate, endDate] = getDates(newYear, newMonth);
        try {
            const newTrophyCase =  !Number(newMonth) || checkValidMonth(newMonth, newYear) ? await awardApi.getTropyCase({
                startDate,
                endDate,
                storeUIDs: newStores.join(',')
            }) : [] || [];
            // check if greater than 100 stores or more than 100 awards/contests then disable pdf download
            if (newTrophyCase && (newTrophyCase.length > 100 || getTrophyCount(newTrophyCase) > 100)) {
                setDisablePDF(true);
            } else {
                setDisablePDF(false);
            }
            setTrophyCase(newTrophyCase);
        } catch (e) {
            console.error(e);
        }
        setLoadingTrophyCase(false);
    };

    useEffect(() => {
        const getCompanyStores = async () => {
            try {
                const newGroupedStores = await storeApi.getStoresBelongsToCompany({ grouped: true });
                const allStores = getStoreUIDs(newGroupedStores);
                setAllStores(allStores);
                // Display Trophy case only for the current month by default
                updateAwards({ year, month, stores: allStores });
                setGroupedStores(newGroupedStores);
                const newStoreTree = getStoreTree(newGroupedStores);
                setStoreTree(newStoreTree);
            } catch (e) {
                console.error(e);
            }
        };
        getCompanyStores();
    }, []);

    useEffect(() => {
        const getHistory = async () => {
            try {
                const [{ ResetDate: resetDate } = {}] = await awardApi.awardsResetHistory() || [];
                const isResetRestricted = resetDate && new DateLib(resetDate)
                        .addDays(RESET_AWARDS_RESTRICTION_TIME)
                        .isAfter(new DateLib());
                setIsResetAvailable(!isResetRestricted);
            } catch (e) {
                console.error(e);
            }
        };
        getHistory();
    }, [allStores]);

    const onViewChange = (newTypeOfView) => {
        if (newTypeOfView === 'summary') {
            updateAwards({ stores });
        }
        setTypeOfView(newTypeOfView);
    };

    const generateFileName = (format, type) => {
        const timePart = new DateLib().format(DateLib.FORMAT_TYPES.DATE_UNDERSCORED);

        return `${t('awards__awards-resettable--history-filename')}_${type}_${timePart}.${format}`;
    };

    const downloadAwards = async (format) => {
        const localTime = new DateLib().format(DateLib.FORMAT_TYPES.MONTH_DATE_WITH_TIME);
        const [startDate, endDate] = getDates(year, month);
        const params = {
            startDate,
            endDate,
            storeUIDs: stores.join(','),
            localTime,
            typeOfView,
            language: i18n.resolvedLanguage,
        };
        setLoadingFile(true);
        try {
            if (format === 'pdf') {
                saveAs(
                        await pdfApi.downloadAwardTrophyCase(params),
                        generateFileName(format, typeOfView)
                );
            }

            if (format === 'csv') {
                fileDownload(
                        Buffer.from(await awardApi.getAwardHistory(params), 'binary'),
                        generateFileName(format, typeOfView)
                );
            }

            setLoadingFile(false);
        } catch (e) {
            console.error(e);
        }
        setLoadingFile(false);
    };

    const showDetails = (storeUID) => {
        updateAwards({ stores: [storeUID] });
        onViewChange('detailed');
    };

    const headerClick = (name) => {
        if (['csv', 'pdf'].includes(name)) {
            downloadAwards(name);
        }
        if (name === 'reset') {
            if (!isResetAvailable) {
                dismissNotification();
                addNotification({
                    message: t('awards__awards-resettable--reset-refuse'),
                    type: NOTIFICATION_TYPES.ERROR
                });
                return;
            }
            setShowConfirmPopup(true);
        }
        if (name === 'config') {
            setShowConfig(true);
        }
        if (name === 'filter') {
            setIsPopupShown(!isPopupShown);
        }
    };

    const onConfirm = async () => {
        try {
            const received = await await awardApi.resetAwards();
            if (!received['CompanyUID']) {
                throw Error();
            }
            dismissNotification();
            addNotification({
                message: t('awards__awards-resettable--count-reset'),
                type: NOTIFICATION_TYPES.SUCCESS
            });
            setAllStores([...allStores]);
        } catch (e) {
            dismissNotification();
            addNotification({
                message: t('common__error-processing-request'),
                type: NOTIFICATION_TYPES.ERROR
            });
        }
    };
    const onFilterChange = ({ year, month, stores, isPopupShown }) => {
        setIsPopupShown(isPopupShown);
        // eslint-disable-next-line no-undefined
        const shouldUpdateAwards = !loadingTrophyCase && (year || month !== undefined || stores);
        shouldUpdateAwards && updateAwards({ year, month, stores });
    };

    const clearFilters = () => {
        updateAwards({ year: startYear, month: 0, stores: allStores });
    };

    const Filter = () => {
        return (<EnhancedFilter
            items={storeTree}
            onChange={onFilterChange}
            isPopupShown={isPopupShown}
            setIsPopupShown={setIsPopupShown}
            year={year}
            month={month}
            selection={stores}
            loading={loadingTrophyCase}
            startYear={startYear}
        />);
    };

    return (
        <div>
            <div className="awards-resettable">
                <ConfirmPopupComponent
                    show={showConfirmPopup}
                    title={t('common__double-checking')}
                    message={<>
                        <div className="hme-awards-resettable-confirm-line">
                            {t('awards__awards-resettable--count-warning')}
                        </div>
                        <div>
                            {t('awards__awards-resettable--reset-warning')}
                        </div>
                    </>}
                    onHide={onHidePopup}
                    actions={[{
                        children: t('common__cancel'),
                        onClick: onHidePopup
                    }, {
                        children: t('common__reset'),
                        variants: ['submit'],
                        onClick: () => {
                            onConfirm && onConfirm();
                            onHidePopup && onHidePopup();
                        }
                    }]}
                />
                <ConfigureAwards
                    show={showConfig}
                    setShow={setShowConfig}
                    masqueradeUser={masqueradeUser}
                />
                {!showConfirmPopup && <NotificationsList />}
                <HeaderMenu
                    title={t('common__awards')}
                    menuItems={menuItems({ Filter })}
                    onClick={headerClick}
                    loading={loadingFile}
                    disablePDF={disablePDF}
                />
                <FilterInfo
                    year={year}
                    month={month}
                    selection={stores}
                    allStores={allStores}
                    groupedStores={groupedStores}
                    onClear={clearFilters}
                    startYear={startYear}
                />
                <TrophyCase
                    loading={loadingTrophyCase}
                    allStores={allStores}
                    trophyCase={trophyCase}
                    typeOfView={typeOfView}
                    onViewChange={onViewChange}
                    showDetails={showDetails}
                    views={views}
                    divider={''}
                />
            </div>
        </div>
    );
};

export const AwardsResettable = compose(
        withMasqueradeUser()
)(Awards);
