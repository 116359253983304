import { useState, useEffect, useCallback } from 'react';
import { getDeviceUpgradeVersions } from '../Controller';

export const useLoadDeviceUpgradeVersions = (deviceUID) => {
    const [deviceUpgradeVersions, setDeviceUpgradeVersions] = useState(null);
    const [isDeviceUpgradeVersionsLoading, setIsDeviceUpgradeVersionsLoading] = useState(true);
    const [deviceUpgradeVersionsLoadingError, setDeviceUpgradeVersionsLoadingError] = useState(null);

    const loadDeviceUpgradeVersions = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceUpgradeVersionsLoading(true);
            setDeviceUpgradeVersionsLoadingError(null);
            const deviceUpgradeVersions = (await getDeviceUpgradeVersions(duid)) || null;

            setDeviceUpgradeVersions(deviceUpgradeVersions);
        } catch (e) {
            console.error('Error while loading device settings', e);
            setDeviceUpgradeVersions(null);
            setDeviceUpgradeVersionsLoadingError(e);
        }

        setIsDeviceUpgradeVersionsLoading(false);
    }, []);

    useEffect(() => { loadDeviceUpgradeVersions(deviceUID); }, [deviceUID]);

    return { deviceUpgradeVersions, isDeviceUpgradeVersionsLoading, deviceUpgradeVersionsLoadingError, loadDeviceUpgradeVersions };
};
