import React from 'react';

import { TileItemField } from '../../../Common/TileItemField';
import { usePeripheralDevices } from '../hooks';

export const TilePeripheralDeviceItem = ({ data }) => {
    const { headers, rows } = usePeripheralDevices({ data });

    return rows.map((device) => {
        return (
            <div
                className="hme-system-status-devices-grid__tile-peripheral-device"
                key={device.serialNumber}
            >
                {headers.map(({ property, text, tabletScreenText }) => {
                    if (!device[property]) {
                        return null;
                    }

                    return (
                        <TileItemField
                            key={property}
                            device={device}
                            property={property}
                            text={tabletScreenText || text}
                        />
                    );
                })}
            </div>
        );
    });
};
