import React from 'react';
import { compose } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { isDistributor } from 'services/Auth';
import authenticate from 'components/Security/Authentication';
import { checkAccess } from 'actions/permissions';
import { accountTypes } from 'constants/accountTypes';
import { DeviceSettings } from '../DeviceSettings';
import { getSnapshotsList } from '../DeviceSettings/Controller';

import './DeviceSettingsPage.scss';

const loadSnapshots = async ({
    setIsSnapshotsLoading,
    setSnapshots,
    setSnapshotsTotal,
    pageNumber = 0,
    itemsPerPage,
    sortSelection,
    filters = {},
    appliedFilters = {},
}) => {
    try {
        setIsSnapshotsLoading(true);

        const [sortColumn] = Object.keys(sortSelection);
        let sortingType = sortSelection && sortSelection[sortColumn] === -1 ? 0 : 1;

        // define filters without checked "Show all items" option
        const appliedFields = Object.keys(filters).filter(
            (field) => appliedFilters[field] && filters[field].length !== appliedFilters[field].length,
        );

        const formattedAppliedFilters = appliedFields.reduce(
            (acc, field) => ({
                ...acc,
                [field]: appliedFilters[field],
            }),
            {},
        );

        const { snapshots = [], total = 0 } = await getSnapshotsList({
            pageNumber: pageNumber + 1,
            itemsPerPage,
            sortColumn,
            sortingType,
            appliedFilters: formattedAppliedFilters,
        });

        setSnapshotsTotal(total);
        setSnapshots(snapshots);
    } catch (e) {
        setSnapshots([]);
        setSnapshotsTotal(0);
    }

    setIsSnapshotsLoading(false);
};

const hasAccess =
    checkAccess(CommonConstants.userPermissions.ManageDeviceSettings) ||
    (isDistributor() && checkAccess(CommonConstants.externalPermissions.ManageDevicesAdvanced));

export const PublicDeviceSettings = () => (
    <DeviceSettings
        permissionType={isDistributor() ? accountTypes.DISTRIBUTOR : accountTypes.PUBLIC}
        loadSnapshots={loadSnapshots}
    />
);

export const DeviceSettingsPage = compose(
    withHMELayout({ variants: ['extended', 'device-settings'] }),
    authenticate,
    withAccess({
        checkAccess: () => hasAccess,
    }),
)(PublicDeviceSettings);
