import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { getDataAsync } from '../Api';

export const getCountries = async () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getCountry;

    const response = await getDataAsync(url);

    return response.status ? response.data : [];
};
