import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { equals } from 'ramda';

import { trimValues } from 'helpers';
import { useGlobalState } from 'globalState';

import { isUserValid, trimmedKeys, validateUserData } from '../../Common/validators/user';
import { getMasqueradeWarnings } from '../helpers';
import { load, saveUser, deleteUser, masqueradeUser, sendActivationEmail, checkSSOAccount } from '../Controller';
import { redirectToSettingsPage } from '../helpers/getRedirectionURL';

export const useEditUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMasquerading, setIsMasquerading] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [roles, setRoles] = useState([]);
    const [activityLogs, setActivityLogs] = useState([]);
    const [storesTree, setStoresTree] = useState([]);
    const [user, setUser] = useState({
        uid: null,
        userName: '',
        firstName: '',
        lastName: '',
        role: '',
        stores: [],
        isActive: 1
    });
    const [originUser, setOriginUser] = useState(null);
    const [groupStores, setGroupStores] = useState({});
    const [validationErrors, setValidationError] = useState({});
    const { unreadNotifications } = useGlobalState();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid');
    const accOwnerUID = searchParams.get('auuid');

    const { t } = useTranslation();

    const title = `${t('add-user__title--edit', {
        firstName: originUser?.firstName || '',
        lastName: originUser?.lastName || ''
    })}`;

    const isSaveButtonDisabled = useMemo(
            () => !isUserValid(user) || equals(trimValues(originUser, trimmedKeys), trimValues(user, trimmedKeys)),
            [user, originUser]
    );

    const onCancel = useCallback(() => {
        redirectToSettingsPage(navigate, accOwnerUID);
    }, [navigate]);

    const onSave = useCallback(() => {
        const errorData = validateUserData(user);
        if (errorData) {
            return setValidationError(errorData);
        }
        setValidationError({});
        return saveUser(user, groupStores, accOwnerUID, navigate, setIsLoading, setValidationError);
    }, [user, groupStores, navigate]);

    const onRemove = useCallback(() => {
        deleteUser(user.uid, accOwnerUID, navigate, setIsLoading);
    }, [user.uid, navigate]);

    const onMasquerade = useCallback(async () => {
        await masqueradeUser({ userEmail: user.userName, setIsLoading: setIsMasquerading, navigate });
        const isAccountSSO = await checkSSOAccount(user.userName);
        if (isAccountSSO) {
            window.localStorage.setItem('isAccountSSO', true);
        }
        unreadNotifications.resetUnread();
    }, [user.userName, navigate]);

    const onResendEmail = useCallback(() => {
        sendActivationEmail({ userEmail: user.userName, setIsSendingEmail });
    }, [user.userName]);

    useEffect(() => {
        load({
            userUid: uuid,
            setUser: setOriginUser,
            setRoles,
            setStoresTree,
            setGroupStores,
            setActivityLogs,
            setIsLoading,
            navigate,
            accOwnerUID
        });
    }, [uuid]);

    useEffect(() => {
        setUser({
            ...originUser
        });
    }, [originUser, setUser]);

    // defined for admins only
    const hasMasquerade = Boolean(user.isActive && user.isVerified && user.isTOSAccepted);
    const hasResendEmail = !user.isTOSAccepted && !user.isAccountOwner;
    const hasRemove = !user.isAccountOwner;
    const masqueradeWarnings = getMasqueradeWarnings(user);

    return {
        title,
        hasRemove,
        isLoading,
        isAdminView: true,
        hasMasquerade,
        hasResendEmail,
        isSendingEmail,
        isMasquerading,
        masqueradeWarnings,
        user,
        roles,
        activityLogs,
        storesTree,
        isSaveButtonDisabled,
        showStatus: true,
        onUserChange: setUser,
        onCancel,
        onSave,
        onRemove,
        onMasquerade,
        onResendEmail,
        validationErrors
    };
};
