import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components/Inputs';
import { Grid } from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { useScreenType } from 'globalState/GlobalStateProvider';

import { overallToRow } from './helpers/overallToRow';
import { getMetricText } from '../helpers/getMetricText';
import { NoResults } from '../NoResults';
import { MOBILE_SORT_OPTIONS, defaultSorting } from '../Constants';

import './Overall.scss';

export const Overall = ({ overall, isResultsLoading }) => {
    const screenType = useScreenType();

    const [searchValue, setSearchValue] = useState('');
    const [searchString, setSearchString] = useState('');
    const [sortSelection, setSortSelection] = useState(defaultSorting);
    const { t } = useTranslation();

    const gridHeaders = useMemo(() => {
        if (overall?.results?.length === 0) {
            return [];
        }

        const metricText = getMetricText(overall?.metric);

        if (!screenType.isDesktop) {
            return [
                {
                    column: [
                        { text: 'dashboard__store-rank', property: 'rank' },
                        { text: 'common__store', property: 'Store' }
                    ]
                },
                {
                    column: [
                        { text: metricText, property: 'metricValueString' },
                        { text: 'contest__result__total-points', property: 'Points' }
                    ]
                }
            ];
        }

        return [{
            text: 'dashboard__store-rank',
            property: 'rank',
            className: 'hme-grid-centered-cell hme-grid-cell--visible',
            headerClassName: 'hme-grid-centered-cell',
            sortable: true,
        }, {
            text: 'common__store',
            property: 'Store',
            flex: 2,
        }, {
            text: metricText,
            property: 'metricValueString',
            className: 'hme-grid-centered-cell',
            headerClassName: 'hme-grid-centered-cell',
        }, {
            text: 'contest__result__total-points',
            property: 'Points',
            className: 'hme-grid-centered-cell',
            headerClassName: 'hme-grid-centered-cell',
        }];
    }, [screenType.isDesktop, overall?.results?.length, overall?.metric]);

    const rows = useMemo(() => overall && overall.results ?
        overall.results.map((result, index) => overallToRow(result, index)) :
        []
    , [overall && overall.results]);

    const showedRows = useMemo(() => {
        const searchedRows = !searchString ? rows : rows
            .filter(({ Store }) => Store.toLowerCase().includes(searchString.toLowerCase()));

        return searchedRows
            .sort(({ Rank: rank1 }, { Rank: rank2 }) => (rank1 - rank2) * sortSelection.rank);
    }, [rows, searchString, sortSelection]);

    const onSearchValueChange = useCallback((newValue) => {
        setSearchValue(newValue);

        if (newValue.length > 2) {
            setSearchString(newValue);
        }

        if (newValue.length === 0) {
            setSearchString('');
        }
    }, []);

    const onSearckKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            setSearchString(searchValue);
        }
    }, [searchValue]);

    if (!isResultsLoading && !overall?.results?.length) {
        return (
            <div className='hme-contest-view-edit-form__results__overall'>
                <NoResults />
            </div>
        );
    }

    return (
        <div className='hme-contest-view-edit-form__results__overall'>
            <SearchInput
                value={searchValue}
                onChange={onSearchValueChange}
                placeholder={t('contest__form__results__overall__search-placeholder')}
                showClearIcon={true}
                onKeyDown={onSearckKeyDown}
            />
            {screenType.isDesktop ? (
                <Grid
                    headers={gridHeaders}
                    rows={showedRows}
                    isLoading={isResultsLoading}
                    rowKey="ContestResultID"
                    noRecordsMessage={t('common__no-stores--found')}
                    sortSelection={sortSelection}
                    onSortChange={setSortSelection}
                />
            ) : (
                <TileList
                    headers={gridHeaders}
                    rows={showedRows}
                    isLoading={isResultsLoading}
                    rowKey="ContestResultID"
                    noRecordsMessage={t('common__no-stores--found')}
                    sortOptions={MOBILE_SORT_OPTIONS}
                    sortSelection={sortSelection}
                    onSortChange={setSortSelection}
                    sortButtonName="common__sort"
                />
            )}
        </div>
    );
};
