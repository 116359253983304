import React from 'react'
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Tooltip } from 'react-tooltip';
import { getDisplaySetting, SaveDisplaySetting } from '../../actions/displaysettings'
import SortableBoard from '../LeaderBoard/SortableBoard'
import { arrayMove } from 'react-sortable-hoc'
import CommonLoader from '../Alerts/CommonLoader'
import CommonService from '../Common/Functions'

// CustomizeDashboard - Component
class CustomizeDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isValid: true,
      successMessage: '',
      saveSuccess: false,
      EmptyTableDropDown: [
        { name: 'Current Day', visible: true, id: 't1' },
        { name: 'Previous Day', visible: true, id: 't2' },
        { name: 'Current Month', visible: true, id: 't3' },
        { name: 'Previous Month', visible: true, id: 't4' },
        { name: 'Current Week', visible: true, id: 't5' },
        { name: 'Previous Week', visible: true, id: 't6' },
        { name: 'Current Daypart', visible: true, id: 't7' },
        { name: 'Previous Daypart', visible: true, id: 't8' }
      ],
      preferenceValues: {},
      firstTable: {
        id: 't0',
        disabled: true,
        header: 'Stores',
        columns: [
          {
            header: 'Rank',
            data: [1, 4, 8]
          },
          {
            header: 'Store',
            data: [14165, 55171, 66188]
          },
          {
            header: 'Goal',
            data: [210, 210, 210]
          }
        ]
      },
      showOverlay: {
        'opacity': '1',
        'display': 'none'
      },
      showAlert: false,
      tableData: [],
      renderEmptyContext: 3,
      showLoader: false
    }
    this.common = new CommonService()
    // this.convertReactToDBObject = this.convertReactToDBObject.bind(this)
    // this.convertDBObjectToReact = this.convertDBObjectToReact.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.onAlertOkClick = this.onAlertOkClick.bind(this)
    this.handleSortEndTable = this.handleSortEndTable.bind(this)
  }

  // CustomizeDashboard save function:
  // after saving records and change the state based on the result
  handleSave () {
    if (this.handleValidation()) {
      this.setState({ showLoader: true })
      let data = this.state.preferenceValues
      this.common.getDefaultDisplaySettings(data)
      let customizedashboard = this.common.convertReactToDBObject(this.state.firstTable, this.state.tableData)
      data.PreferenceDash = JSON.stringify(customizedashboard.PreferenceDash)
      data.PreferenceDashSortC = customizedashboard.PreferenceDashSortC
      data.DashboardOrder = customizedashboard.DashboardOrder
      this.props.SaveDisplaySetting(data, result => {
        if (result.status) {
          this.setState({ saveSuccess: true, successMessage: 'dashboard__customization-successful', showLoader: false })
        }
      })
    }
  }

  // Table Sort order function and change the table state
  handleSortEndTable (sortorder) {
    let tableData = this.state.tableData
    tableData = arrayMove(tableData, sortorder.oldIndex, sortorder.newIndex)
    this.setState({ tableData: tableData })
  }

  // On Displaying Alert
  onAlertOkClick () {
    let showAlert = false
    let showOverlay = {
      'opacity': '1',
      'display': 'none'
    }
    this.setState({ showAlert, showOverlay })
  }

  // This function used for Validating the input before saving the records
  // If any error occors update the error state and display
  handleValidation () {
    let isValid = true
    let showOverlay = this.state.showOverlay
    let showAlert = this.state.showAlert
    if (this.state.tableData.length === 0) {
      isValid = false
      showAlert = true
      showOverlay = {
        'opacity': '1',
        'display': 'block'
      }
    }
    this.setState({ isValid, showOverlay, showAlert })

    return isValid
  }

  //  Using React lifecycle method and run the function before page loads
  UNSAFE_componentWillMount () {
    this.props.getDisplaySetting()
  }

  //  Using React lifecycle method and set the new state before page loads
  UNSAFE_componentWillReceiveProps (newProp) {
    let preferenceValues = newProp.displaySettings
    if (
        newProp.displaySettings.PreferenceDash &&
        newProp.displaySettings.PreferenceDash !== this.props.displaySettings.PreferenceDash
      ) {
      const dbObject = JSON.parse(newProp.displaySettings.PreferenceDash)
      const PreferenceDashSortC = newProp.displaySettings.PreferenceDashSortC
      const preferenceStoreColumnName = newProp.displaySettings.PreferenceStoreColumnName
      const preference = PreferenceDashSortC ? PreferenceDashSortC.split('|')[0] : ''
      const sort = PreferenceDashSortC ? PreferenceDashSortC.split('|')[1] : ''
      const preferenceSort = { preference, sort, preferenceStoreColumnName }
      const { EmptyTableDropDown, tableData, firstTable } = this.state
      const tables = this.common.convertDBObjectToReact(dbObject, preferenceSort, { EmptyTableDropDown, tableData, firstTable })
      this.setState({ firstTable: tables.firstTable, tableData: tables.tableData, EmptyTableDropDown: tables.EmptyTableDropDown })
    }
    this.setState({ preferenceValues: preferenceValues })
  }

  //  Main Render function
  render () {
    const { t } = this.props;
    let showLoader = this.state.showLoader
    const customizeLeaderboardTips = () => {
      return (
        <p className='customHeader'>
          <Trans i18nKey={'dashboard__tips--create-customized-dashboard'}>
            <span translate=''>Text1</span><span translate='' className='blueText blueText_Space'>*</span><span translate=''>Text2</span>
          </Trans>
          <br /><br />
          <Trans i18nKey={'dashboard__tips--remove-kpi'}>
            <span translate='' key='toremovekpi'>Text1</span><span className='blueText blueText_Space'>**</span>
            <span translate='' key='fromatimemeasurement'>Text2</span>
          </Trans>
           <br /><br />
          <span translate='' key='toaddakpi'>{t('dashboard__tips--add-kpi')}</span> <br /><br />
          <span translate='' key='youmayalsoselectdefaultKPI'>{t('dashboard__tips--select-kpi')}</span> <br /><br />
          <span translate='' key='rearrangeanytimemeasurement'>{t('dashboard__tips--rearrange-time-measurement')}</span> <br />
          <p className='whiteLine textAlignment'>
            <Trans i18nKey={'dashboard__tips--valid-time-measurements'}>
              <span className='blueText'>* </span><span className='whiteText' translate='' key='validtimemeasurements'>Text</span>
            </Trans>
            <br /><br />
            <Trans i18nKey={'dashboard__tips--valid-kpis'}>
              <span className='blueText'>** </span><span className='whiteText' translate='' key='validkpis'>Text</span>
            </Trans>
            <br /><br />
            <span className='whiteText' translate='' key='carsarethetotal'>{t('dashboard__tips--cars-total-number')}</span>
          </p>
        </p>
      )
    }

    return (
      <section style={{ position: 'relative' }}>
        {/* Page loader function based on save success message */}
        <div className={showLoader ? 'hide' : this.state.saveSuccess ? 'show' : 'hide'}>
          <div className='forms clear'>
            <div className='appr' >
              <h4 translate='' key='recordsaved'>{t(this.state.successMessage)}</h4>
            </div>
          </div>
        </div>
        <div className={showLoader ? 'show' : 'hide'}>
          {/* Page CommonLoader function */}
          <CommonLoader showLoader={showLoader} message="common__loading__request-being-processed" />
        </div>
        <div id='leaderboard-container' className={'forms dash_cont relativecontainer dashboard-settings-container ' + (this.state.saveSuccess || showLoader ? 'hide' : 'show')} style={{ position: 'relative' }}>
          <div>
            {/* React Tooltip component for display the default messages */}
            <h5 className='customizeleaderboard'>
              <span>{t('dashboard__customize-dashboard')}</span>
              <a   data-tip data-tooltip-id='customizedash' data-tooltip-variant="error" > <span className='tip openTip'>?</span> </a>
              <Tooltip id='customizedash' place='right' type='dark' effect='solid' className=' tooltip Arrow viewTipCont leadertips tooltips toolTip_darker customizedash' >
               {customizeLeaderboardTips()}
              </Tooltip>
            </h5>
          </div>
          {/* SortableBoard component to displaing the records in sortorder */}
          <SortableBoard
            firstTable={this.state.firstTable}
            tableData={this.state.tableData}
            renderEmptyContext={this.state.renderEmptyContext}
            EmptyTableDropDown={this.state.EmptyTableDropDown}
            handleSortEndTable={this.handleSortEndTable}
            handleSortEnd={this.handleSortEnd} />
          <div className='customizedashboard-button'>
            <button type='button' className='submit' onClick={this.handleSave} >{t('dashboard__save-changes')}</button>
          &nbsp;|&nbsp;<a className='cancel_butt' href='/dashboard' translate='' key='cancel'>{t('common__cancel')}</a>
            <div className='sweet-overlay' tabindex='-1' style={this.state.showOverlay} />
          </div>
          {/* Displaying Alert component based on user clickevent */}
          <SweetAlert t={t} show={this.state.showAlert} onOkClick={this.onAlertOkClick} />
        </div>

      </section>
    )
  }
}
// SweetAlert - Component
class SweetAlert extends React.Component {
  // Displaying  Alert messages
  render () {
    const { t } = this.props;
    return (
      <div className={'sweet-alert showSweetAlert visible ' + (this.props.show ? 'show' : 'hide')} data-custom-className='' data-has-cancel-button='false' data-has-confirm-button='true' data-allow-outside-click='false' data-has-done-function='false' data-animation='none' data-timer='null'
        style={{ 'display': 'block', 'marginTop': '-112px' }}><div className='sa-icon sa-error' style={{ 'display': 'none' }}>
          <span className='sa-x-mark'>
            <span className='sa-line sa-left' />
            <span className='sa-line sa-right' />
          </span>
        </div><div className='sa-icon sa-warning' style={{ 'display': 'none' }}>
          <span className='sa-body' />
          <span className='sa-dot' />
        </div><div className='sa-icon sa-info' style={{ 'display': 'none' }} />
        <div className='sa-icon sa-success' style={{ 'display': 'none' }}>
          <span className='sa-line sa-tip' />
          <span className='sa-line sa-long' />

          <div className='sa-placeholder' />
          <div className='sa-fix' />
        </div><div className='sa-icon sa-custom' style={{ 'display': 'none' }} /><h2>{t('common__error')}</h2>
        <p style={{ 'display': 'block' }}>{t('dashboard__select-one-column')}</p>
        <fieldset>
          <input type='text' tabindex='3' placeholder='' />
          <div className='sa-input-error' />
        </fieldset><div className='sa-error-container'>
          <div className='icon'>!</div>
          <p>{t('common__not-valid')}</p>
        </div><div className='sa-button-container'>
          <button className='cancel' tabindex='2' style={{ 'display': 'none' }}>{t('common__cancel')}</button>
          <div className='sa-confirm-button-container'>
            <button className='confirm' tabindex='1' onClick={this.props.onOkClick}
              style={{
                display: 'inline-block',
                'background-color': 'rgb(212, 27, 62)',
                'box-shadow': 'rgba(212, 27, 62, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset'
              }}>
              {t('common__ok')}
            </button><div className='la-ball-fall' />
          </div>
        </div></div>
    )
  }
}

// Call Redux actions and set the initial state
function mapStateToProps (state) {
  return {
    displaySettings: state.displaysetting.displaySettings
  }
}

function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      getDisplaySetting,
      SaveDisplaySetting
    }, dispatch
  )
}

const CustomizeDashboardWithTranslation = compose(
  withTranslation()
)(CustomizeDashboard);

export default connect(mapStateToProps, matchDispatchToProps)(CustomizeDashboardWithTranslation)
