const getBrandsObject = (brands) => {
    return brands.reduce((val, { brandId, brandShared }) => {
        val[brandId] = brandShared;

        return val;
    }, {});
};

export const isBrandsChanged = (originBrands, brands) => {
    const originValues = getBrandsObject(originBrands);
    const brandValues = getBrandsObject(brands);

    return Object.keys(originValues).reduce((val, brandId) => {
        if (val) {
            return val;
        }

        return originValues[brandId] !== brandValues[brandId];
    }, false);
};
