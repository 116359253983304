import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { Header } from './Header';
import { General } from './General';
import { Permissions } from './Permissions';
import { Footer } from './Footer';
import { roleProp } from './types/roleProps';
import { permissionsProp } from './types/permissionsProps';

import './ViewEdit.scss';

export const ViewEdit = ({
    isLoading,
    title,
    role,
    permissions,
    isOwner,
    isSaveButtonDisabled,
    hasRemove,
    onCancel,
    onSave,
    onRoleChange,
    onRemove,
    isAccountDistributor
}) => {
    const { t } = useTranslation();

    const [isRemoveConfirmShown, setIsRemoveConfirmShown] = useState(false);

    const onRemoveClick = useCallback(() => {
        setIsRemoveConfirmShown(true);
    }, [setIsRemoveConfirmShown]);

    const onCancelRemoveRole = useCallback(() => {
        setIsRemoveConfirmShown(false);
    }, [setIsRemoveConfirmShown]);

    const onConfirmRemoveRole = useCallback(() => {
        setIsRemoveConfirmShown(false);

        onRemove && onRemove();
    }, [setIsRemoveConfirmShown, onRemove]);

    if (isLoading) {
        return (
            <div className='hme-view-role'>
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className='hme-view-role'>
            <NotificationsList />
            <Header title={title} isOwner={isOwner} hasRemove={hasRemove} onRemoveClick={onRemoveClick} />
            <div className='hme-view-role__sections'>
                <General role={role} onRoleChange={onRoleChange} isOwner={isOwner} />
                <Permissions
                    permissions={permissions}
                    role={role}
                    onRoleChange={onRoleChange}
                    isOwner={isOwner}
                    isAccountDistributor={isAccountDistributor}
                />
            </div>
            <Footer
                isSaveButtonDisabled={isSaveButtonDisabled}
                isOwner={isOwner}
                onCancel={onCancel}
                onSave={onSave}
            />
            <ConfirmPopupComponentGeneric
                show={isRemoveConfirmShown}
                title={t('common__double-checking')}
                message={t('roles__confirm__message')}
                actions={[{
                    children: t('common__popup--cancel-action'),
                    onClick: onCancelRemoveRole
                }, {
                    children: t('common__popup--confirm-action'),
                    variants: ['submit'],
                    className: 'hme-roles--distributor-account--confirm-button',
                    onClick: onConfirmRemoveRole
                }]}
            />
        </div>
    );
};

ViewEdit.propTypes = {
    isOwner: PropTypes.bool,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    role: roleProp,
    permissions: permissionsProp,
    isSaveButtonDisabled: PropTypes.bool,
    hasRemove: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onRoleChange: PropTypes.func,
    onRemove: PropTypes.func,
    isAccountDistributor: PropTypes.bool
};
