export const MOBILE_SORT_OPTIONS = [
    {
        label: 'apply-device-settings-new__tiles__sorting__device-serial-number__asc',
        value: { Device_SerialNumber: 1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__device-serial-number__desc',
        value: { Device_SerialNumber: -1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__version__asc',
        value: { Device_MainVersion: 1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__version__desc',
        value: { Device_MainVersion: -1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__brand__asc',
        value: { Brand_Name: 1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__brand__desc',
        value: { Brand_Name: -1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__store-name__asc',
        value: { Store_Name: 1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__store-name__desc',
        value: { Store_Name: -1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__store-number__asc',
        value: { Store_Number: 1 },
    },
    {
        label: 'apply-device-settings-new__tiles__sorting__store-number__desc',
        value: { Store_Number: -1 },
    },
];
