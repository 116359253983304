import React from 'react';
import classNames from 'classnames';

import { ReactComponent as Icon } from 'assets/icons/down.svg';

import './Expand.scss';

export const Expand = ({ expanded, onExpandChange, className = '' }) => (
    <div className={classNames('hme-expand-icon', {
        'hme-expand-icon-expanded': expanded,
    }, className)} onClick={onExpandChange}>
        <Icon className='hme-expand-icon__icon'/>
    </div>
);
