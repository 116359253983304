import * as country from '../../actionTypes/Country/Country'
// set the country redux initialState
const initialState = {
  countries: [
    {
      'Id': 0,
      'Name': '',
      'Abbreviation': ''
    }
  ]
}
// call companyDetails redux action & change the companyDetails  state
export default function companyDetails (state = initialState, action) {
  switch (action.type) {
    case country.GET_COUNTRY:
      state.countries = action.countries
      return {
        ...state
      }
    default:
      return state
  }
}
