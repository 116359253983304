import {Config} from '../Config';
import axios from 'axios';
import AuthenticationService from '../components/Security/AuthenticationService';
const authService = new AuthenticationService(Config.authBaseUrl);

const base = 'avatars';

const paths = {
    get: `${base}/getAll`,
    update: `${base}/saveAvatar`
};

const axiosConfig = {
    baseURL: `${Config.apiBaseUrl}api/`,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json'
    }
};

const axiosInstance = axios.create(axiosConfig);

const api = {
    request(options) {
        options.headers = Object.assign(options.headers || {}, {
            Authorization: `Bearer ${authService.getToken()}`
        });
        return axiosInstance(options)
                .then((res) => res.data);
    }
};

export const storesApi = {
    get() {
        return api.request({
            url: `${base}/getCompanyStores`,
            method: 'GET'
        });
    }
};

export const avatarsApi = {
    get() {
        return api.request({
            url: paths.get,
            method: 'GET'
        });
    },

    update(data) {
        return api.request({
            url: paths.update,
            method: 'POST',
            data
        });
    }
};
