import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import { NOTIFICATION_TYPE_OPTIONS } from 'constants/customerNotifications';
import { Title } from 'library/Title';
import { OnOff } from 'library/OnOff';
import { Label } from 'library/Label';
import { SaveFooter } from 'library/SaveFooter';
import { Loader } from 'library/Loader';
import { InfoBox, VARINATS as InfoBoxVarinats } from 'library/InfoBox';
import { NotificationsList } from 'library/NotificationsList';
import { Button } from 'library/Button';
import { lngItems } from 'library/LanguageSelect';
import { Toggle } from 'library/Toggle';

import { useForm, actionTypes } from './hooks/useForm';
import { REQUIRED_LANGUAGES } from '../Common/constants';
import { LanguageSelect } from '../Common/LanguageSelect';

import './Form.scss';

const infoBoxVarinats = [InfoBoxVarinats.WARNING];

const FormComponent = ({ title, notification, isSubmitable, Editor, onNotificationChange, onSave, onCancel }) => {
    const { t } = useTranslation();

    const [notificationState, dispatch] = useForm({ notification, onNotificationChange });

    const { texts, type, isEnabled, initialNotification } = notificationState;

    const textsOptions = useMemo(
            () =>
                texts.reduce((acc, { langKey, value }) => {
                    acc[langKey.toLowerCase()] = value;

                    return acc;
                }, {}),
            [texts]
    );

    const [isLanguageSelectShown, setIsLanguageSelectShown] = useState(false);

    const availableLanguages = useMemo(
            () =>
                lngItems.filter(({ value }) => {
                    const loweredLangKey = value.toLowerCase();
                    return !(loweredLangKey in REQUIRED_LANGUAGES || loweredLangKey in textsOptions);
                }),
            [textsOptions]
    );

    const isAddLanguageAvailable = availableLanguages.length > 0;

    const handleNotificationTypeChange = useCallback(
            (newType) => {
                dispatch(actionTypes.changeType({ newType: Number(newType) }));
            },
            [dispatch]
    );

    const handleNotificationEnableChange = useCallback(
            (newIsEnabled) => {
                dispatch(actionTypes.changeIsEnabled({ isEnabled: newIsEnabled }));
            },
            [dispatch]
    );

    const handleAddNewLanguage = useCallback(
            (langKey) => {
                const loweredLangKey = langKey.toLowerCase();

                dispatch(actionTypes.addNewLanguage({ langKey: loweredLangKey }));
                setIsLanguageSelectShown(false);
            },
            [dispatch]
    );

    const hideSelect = useCallback(() => setIsLanguageSelectShown(false), []);
    const showSelect = useCallback(() => setIsLanguageSelectShown(true), []);

    return (
        <div className="hme-customer-notification-form">
            <NotificationsList />

            <div className="hme-customer-notification-form__content">
                <Title className="hme-customer-notification-form__title">{t(title)}</Title>

                <div className="hme-customer-notification-form__header">
                    <div className="hme-customer-notification-form__header-item">
                        <Label>{t('admin__customer-notification__type-title')}</Label>
                        <Toggle
                            value={type}
                            onChange={handleNotificationTypeChange}
                            className="hme-customer-notification-form__type-switch"
                        >
                            {NOTIFICATION_TYPE_OPTIONS}
                        </Toggle>
                    </div>
                    <div className="hme-customer-notification-form__header-item">
                        <Label>{t('admin__customer-notification__create__notification__label')}</Label>
                        <OnOff
                            checked={isEnabled}
                            onChange={handleNotificationEnableChange}
                            className="hme-customer-notification-form__activation-switcher"
                        />
                        <InfoBox
                            message="admin__customer-notification__create__notification__warning"
                            className={classNames('hme-customer-notification-form__info-box', {
                                'hme-customer-notification-form__info-box--hidden': !isEnabled
                            })}
                            variants={infoBoxVarinats}
                        />
                    </div>
                </div>

                <div className="hme-customer-notification-form__content-container">
                    {texts.map(({ langKey, value }, idx) => {
                        const langKeyLowerCased = langKey.toLowerCase();
                        const currentLanguage = lngItems.find(({ value: languageShortcut }) => {
                            return languageShortcut.toLowerCase() === langKeyLowerCased.toLowerCase();
                        });

                        const isLanguageRequired = langKeyLowerCased in REQUIRED_LANGUAGES;

                        return (
                            <div key={langKeyLowerCased} className="hme-customer-notification-form__editor-wrapper">
                                <Editor
                                    initialNotification={initialNotification}
                                    isSelectShown={isLanguageRequired}
                                    currentLanguage={currentLanguage}
                                    onEdit={(newText) => {
                                        dispatch(actionTypes.changeText({ idx, text: newText }));
                                    }}
                                    onLanguageChange={(newLangKey) => {
                                        dispatch(actionTypes.changeLanguage({ idx, langKey: newLangKey.toLowerCase() }));
                                    }}
                                    onLanguageRemove={() => {
                                        dispatch(actionTypes.removelanguage({ idx, langKey: langKeyLowerCased }));
                                    }}
                                    value={value}
                                    languages={availableLanguages}
                                />
                            </div>
                        );
                    })}

                    {isLanguageSelectShown && (
                        <LanguageSelect
                            languages={availableLanguages}
                            onLanguageChange={handleAddNewLanguage}
                            onLanguageRemove={hideSelect}
                        />
                    )}

                    {isAddLanguageAvailable && (
                        <Button onClick={showSelect}>+ {t('admin__customer-notification__add-new-lang')}</Button>
                    )}
                </div>
            </div>

            <SaveFooter onSave={onSave} onCancel={onCancel} isSubmitEnabled={isSubmitable} />
        </div>
    );
};

export const Form = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <div className="hme-customer-notification-form">
                <Loader />
            </div>
        )
    ],
    [T, (props) => <FormComponent {...props} />]
]);
