import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GridActions } from 'library/GridActions';
import { Button } from 'library/Button';
import { PARTNER_ROUTES } from 'constants/routes';

const buttonVariants = ['transparent'];

const ActionsComponent = ({ tokenUID }) => {
    const { t } = useTranslation();

    return (
        <GridActions className="hme-partner-token-management__actions">
            <Button
                as={Link}
                to={PARTNER_ROUTES.editAPIToken(tokenUID)}
                variants={buttonVariants}
            >
                {t('common__view-edit')}
            </Button>
        </GridActions>
    );
};

export const Actions = memo(ActionsComponent);
