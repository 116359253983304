import React from 'react';
import { cond, T } from 'ramda';

import { BrandDevicesGrid } from './BrandDevicesGrid';
import { AccountDevicesGrid } from './AccountDevicesGrid';
import { StoreDevicesGrid } from './StoreDevicesGrid';
import { filterTypes } from '../../../constants/filterTypes';

import './DevicesList.scss';

export const DevicesList = cond([
    [({ filterType }) => filterType === filterTypes.BRAND, (props) => <BrandDevicesGrid {...props} />],
    [({ filterType }) => filterType === filterTypes.ACCOUNT, (props) => <AccountDevicesGrid {...props} />],
    [({ filterType }) => filterType === filterTypes.STORE, (props) => <StoreDevicesGrid {...props} />],
    [T, () => null],
]);
