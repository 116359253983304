import { useTranslation } from 'react-i18next';

import { isAdmin, getToken } from 'services/Auth';
import { Config } from 'Config';
import { Accordion } from 'library/Accordion';
import { ContactHME } from 'library/ContactHME';

const url = Config.hmeURL;
const path = isAdmin() ? '' : '/?token=' + getToken();
const trainingUrl = `${Config.trainingPortalBaseUrl}${path}`;

export const Client = () => {
    const { t } = useTranslation();

    const hmeInstructionsLink = () => {
        return (
            <span className="hme-help__documentation-link">
                <a
                    href={`${url}/${t('help__instruction-filename')}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('help__instruction-title')}
                </a>
            </span>
        );
    };

    return (
        <div className="hme-help-page">
            <div className="hme-help__container">
                <p className="hme-help__header-text">{t('common__help')}</p>
                <div className="hme-help__column-container">
                    <div className="hme-help__faq-column">
                        <p className="hme-help__faq-header-text">{t('help__faq__title')}</p>
                        <div className="faq clear">
                            <p className="hme-help__faq-category-text">{t('help__faq__subtitle--new-features')}</p>
                            <Accordion
                                className="hme-accordion-wide hme-accordion-wide-top"
                                title={t('help__faq__question--create-report-dashboard')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--contact-sales-representative')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>

                            <p key="password" className="hme-help__faq-category-text">{t('help__faq__subtitle--password')}</p>
                            <Accordion
                                className="hme-accordion-wide hme-accordion-wide-top"
                                title={t('help__faq__question--change-password')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--click-my-account')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--forgot-password')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--click-forgot-password')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>

                            <p className="hme-help__faq-category-text">{t('help__faq__subtitle--settings')}</p>
                            <Accordion
                                className="hme-accordion-wide hme-accordion-wide-top"
                                title={t('help__faq__question--set-dayparts')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--zoom-timer-dashboard-reports')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--roles')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--company-admin-role')}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--company-manager-role')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--permission-level')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--permission-level')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--update-user-list')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--access-user-list')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--access-zoom-settings-remotely')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--access-zoom-settings')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>

                            <p className="hme-help__faq-category-text">{t('help__faq__subtitle--subscription')}</p>
                            <Accordion
                                className="hme-accordion-wide hme-accordion-wide-top"
                                title={t('help__faq__question--subscription-auto-renew')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--subscription-auto-renew')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--subscription-expires')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--subscription-expires-support')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--subscription-cancel')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--subscription-cancel')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--add-store-subscription')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--add-store-subscription-charging')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>

                            <p className="hme-help__faq-category-text">{t('help__faq__subtitle--troubleshooting')}</p>
                            <Accordion
                                className="hme-accordion-wide hme-accordion-wide-top"
                                title={t('help__faq__question--reports-dashboard-no-content')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--premium-subscription')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--zoom-timer-not-showing')}
                                isExpanded={false}
                            >
                                <ol >
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--verify-firmware-version')}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--confirm-admin-status')}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--verify-timer-configuration')}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--verify-ports')}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--verify-active-account')}
                                        </span>
                                    </li>
                                </ol>

                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--technical-assistance')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--contact-us')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--inaccurate-info')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--report-inaccurate-info')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--unable-to-connect')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--unable-to-connect-instruction')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                            <Accordion
                                className="hme-accordion-wide"
                                title={t('help__faq__question--unavailable-system-status')}
                                isExpanded={false}
                            >
                                <ul>
                                    <li>
                                        <span className="hme-help-faq-answer-text">
                                            {t('help__faq__answer--unregistered-store-instruction')}
                                        </span>
                                    </li>
                                </ul>
                            </Accordion>
                        </div>
                    </div>
                    <div className={'hme-help__contact-column'}>
                        <ContactHME />
                        <div>
                            <p className="hme-help__contact-support-text">Documentation</p>
                            {hmeInstructionsLink()}
                        </div>
                        <div className="hme-help__training-portal-container">
                            <p className="training-portal-header">{t('welcome__tile--training__title')}</p>
                            <p className="training-portal-text">
                                {t('welcome__tile--training__content')}
                            </p>
                            <a
                                href={trainingUrl}
                                className="training-portal-link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('welcome__tile--training__link')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
