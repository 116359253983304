import * as leaderboard from '../../actionTypes/Leaderboard/Leaderboard'
// set the leaderboard redux initialState
const initialState = {
  leaderBoardPreference: {
    'CompanyID': '',
    'TimeFormat': 'ss',
    'PreferenceDash': '',
    'DashboardOrder': 'Current|Day, Previous|Day',
    'PreferencePrimeColors': '#00b027|#f3be08|#b40000',
    'PreferenceDashSort': 't1Avg | ASC',
    'PreferenceDashPullins': '4',
    'PreferenceDashSortC': 't1Avg | ASC',
    'PreferenceLanguage': 'en',
    'PreferenceDashMetric': '1'
  }
}
//  Leaderboard redux action & change the leaderboard  state
export default function leaderBoard (state = initialState, action) {
  switch (action.type) {
    case leaderboard.GET_LEADERBOARDPREFERENCE:
    case leaderboard.UPDATE_LEADERBOARDPREFERENCE:
      state.leaderBoardPreference = action.leaderBoardPreference
      return {
        ...state
      }
    default:
      return state
  }
}
