import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GridActions } from 'library/GridActions';
import { Button } from 'library/Button';
import { OnOff } from 'library/OnOff';
import { ADMIN_ROUTES } from 'constants/routes';
import { ssoConfigEnabled, isSSOEnabled as ssoEnabled } from './helpers';
import { updateSsoStatus } from '../../hooks/updateSSOStatus';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

const buttonVariants = ['transparent'];

const ActionsComponent = ({ openModal, domain, email, id, certKeyName, setDelCertKey, ssoDomainStatus, ssoConfigStatus }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isSSOEnabled, setIsSSOEnabled] = useState(false);
    const [showDisableModal, setShowDisableModal] = useState(false);

    const onSSOEnableChange = useCallback(async (confirmDisable = false) => {
        if (!ssoConfigEnabled(ssoConfigStatus)) {
            addErrorNotification(t('settings_sso_management_invalid_sso_configuration'));
            return;
        }

        if (isSSOEnabled && !showDisableModal && !confirmDisable) {
            setShowDisableModal(true);
            return;
        }

        const result = await updateSsoStatus(id, email, !isSSOEnabled);
        const { status = false } = result || {};

        if (status) {
            addSuccessNotification(t(`settings_sso_management_sso_${isSSOEnabled ? 'disabled' : 'enabled'}_success`));
            setIsSSOEnabled(!isSSOEnabled);
        }
    }, [isSSOEnabled, ssoConfigStatus, id, email, showDisableModal]);

    const routeToEdit = useCallback(() => {
        navigate(`${ADMIN_ROUTES.editSSO}`, { state: {
            domain,
            email,
            id
        } });
    }, [domain, email, id]);

    const removeElement = useCallback(() => {
        openModal();
        setDelCertKey({
            id,
            certKeyName
        });
    }, [openModal, id, certKeyName, setDelCertKey]);

    useEffect(() => {
        setIsSSOEnabled(ssoEnabled(ssoDomainStatus));
    }, [ssoDomainStatus]);

    return (
        <>
            <GridActions className="hme-token-management__actions">
                <div className="hme-on-off-button">
                    <OnOff
                        checked={isSSOEnabled}
                        onChange={onSSOEnableChange}
                        disabled={false}
                        isReadOnly={false}
                    />
                </div>
                <Button
                    variants={buttonVariants}
                    onClick={routeToEdit}
                >
                    {t('common__edit')}
                </Button>
                <Button
                    variants={buttonVariants}
                    onClick={removeElement}
                >
                    {t('common__delete')}
                </Button>
            </GridActions>
            <ConfirmPopupComponent
                show={showDisableModal}
                message={t('settings_sso_management_modal__confirm--disable-sso')}
                onConfirm={() => onSSOEnableChange(true)}
                confirmationVerb={t('common__popup--confirm-action')}
                onHide={() => setShowDisableModal(false)}
                title={t('common__double-checking')}
            />
        </>
    );
};

export const Actions = memo(ActionsComponent);
