import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div className="firmware-management__footer">{children}</div>
    );
};
