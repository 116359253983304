import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from 'library/Label';
import { ConditionalWrapper } from 'library/ConditionalWrapper';
import { Accordion } from 'library/Accordion';
import { Button } from 'library/Button';

import { LanguageSelect } from '../../Common/LanguageSelect';
import { Editor } from '../../Common/Editor';
import { REQUIRED_LANGUAGES } from '../../Common/constants';

export const NewLanguageEditor = ({
    initialNotification,
    currentLanguage,
    value,
    languages,
    isSelectShown,
    onLanguageChange,
    onLanguageRemove,
    onEdit
}) => {
    const [languagesToDisplay, setLanguagesToDisplay] = useState(languages);
    const { t } = useTranslation();

    useEffect(() => {
        setLanguagesToDisplay([...languages, currentLanguage]);
    }, [languages, currentLanguage]);

    const isEditable = !(currentLanguage.value.toLowerCase() in initialNotification.texts);
    const isDeletable = !(currentLanguage.value.toLowerCase() in REQUIRED_LANGUAGES);

    return (
        <ConditionalWrapper
            condition={isDeletable && !isEditable}
            wrapper={(children) => (
                <Accordion title={currentLanguage.text} isExpanded={false}>
                    {children}
                </Accordion>
            )}
        >
            {isEditable && !isSelectShown && (
                <LanguageSelect
                    value={currentLanguage.value}
                    languages={languagesToDisplay}
                    onLanguageChange={onLanguageChange}
                    onLanguageRemove={onLanguageRemove}
                    className="hme-customer-notification-form__editor-language-selector"
                />
            )}

            {!isDeletable && <Label className="hme-customer-notification-form__language-title">{currentLanguage.text}</Label>}

            <Editor state={value} onChange={onEdit} className="hme-customer-notification-form__editor" />

            {isDeletable && !isEditable && (
                <div className="hme-customer-notification-language-select hme-customer-notification-language-select--bottom">
                    <Button onClick={onLanguageRemove} className="hme-customer-notification-language-select__delete-btn">
                        - {t('admin__customer-notification__remove-lang')}
                    </Button>
                </div>
            )}
        </ConditionalWrapper>
    );
};
