import * as masterSettings from '../../actionTypes/MasterSettings/masterSettings'

const masterSettingsData = { settingsList:[],
  destinationList:[],
  masterSettings : {
    settingsList:[],
    destinationList:[],
    storeViewDetails: {
      systemStatus: [
        {
          Device_ID: null,
          Brand_Name: null,
          Store_UID: null,
          Store_Number: null,
          Store_AddressLine1: '',
          Store_Locality: '',
          Store_Region: '',
          Device_Name: '',
          Device_IsActive: null,
          Device_UID: null,
          LaneConfig_Name: null,
          Device_SerialNumber: null,
          Device_DeviceType_ID: 1,
          Device_MainVersion: null,
          Device_SettingVersion: null,
          Device_LaneConfig_ID: null,
          Store_Company_ID: null,
          Store_Brand_ID: null
        }
      ],
      totalDevices: 209,
      systemSettings: [
        {
          name: 'Name Settings Group',
          value: [
            {
              SettingInfo_Setting_ID: null,
              DeviceSetting_Device_ID: null,
              SettingInfo_Name: null,
              Device_Store_ID: null,
              DeviceSetting_SettingValue: null,
              SettingsGroup_ID: null,
              SettingsGroup_Name: null,
              SettingsGroup_Order: null
            }
          ]
        }
      ],
      status: true
    }}
}
export default function MasterSettings (state = masterSettingsData, action) {
  switch (action.type) {
    case masterSettings.GET_SETTINGS:
      state.masterSettings = action.masterSettings
      return {
        ...state
      }
    default:
      return state
  }
}
