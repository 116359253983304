import { CommonConstants } from 'Constants';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { checkAccess } from 'actions/permissions';
import { isDistributor, isFranchise, isCorporation } from 'services/Auth';
import { hasGamificationFeature, hasLeaderboardAccess as getLeaderboardAccess } from 'actions/permissions';

export const hasSubscription = (subscriptions, isNitro) => {
    const {
        SL: subscriptionLevel,
        IsNitro: isNitroProfile,
    } = baseAuthService.getProfile();

    return isNitro === isNitroProfile && subscriptions.includes(`${subscriptionLevel}`);
};

export const hasApplyDeviceSubscription = () => hasSubscription([
    CommonConstants.subscriptionLevels.nitroData,
    CommonConstants.subscriptionLevels.nitroLeaderboard,
    CommonConstants.subscriptionLevels.nitroGamification,
], 1);

export const isApplySettingsEnabled = () => checkAccess(
    CommonConstants.userPermissions.ManageDeviceSettings
) && hasApplyDeviceSubscription();

export const hasDistributorViewAllStoresAccess = () => isDistributor() && checkAccess('ViewAllStores');
export const hasDistributorManageDevicesAdvancedAccess = () => isDistributor() 
    && checkAccess(CommonConstants.externalPermissions.ManageDevicesAdvanced);

export const hasLeaderboardAccess = (permissionGroupName) => {
    const { accessLevel, hasSubscription } = getLeaderboardAccess();

    return permissionGroupName ? hasGamificationFeature(permissionGroupName) : accessLevel.length > 0 && hasSubscription;
};

export const hasTextConnectAccess = () => (isFranchise() || isCorporation()) && checkAccess(CommonConstants.userPermissions.SendTextConnectMessage);
