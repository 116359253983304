import React from 'react';
import { Route } from 'react-router-dom';

import { NewAccount } from 'pages/AccountInfo/NewAccount';
import { Help } from 'pages/Help';
import Forbidden from 'components/ErrorBoundary/Forbidden';
import Exception from 'components/ErrorBoundary/Exception';
import LearnMore from 'components/Tiles/components/LearnMore';
import WelcomeDynamic from 'components/Welcome/WelcomeDynamic';
import Logout from 'components/Security/Logout';
import ValidateAccount from 'components/Account/validateAccount';
import { Login } from 'pages/Login';

import { getLayout } from 'helpers/getLayout';
import authenticate from 'components/Security/Authentication';
import { getAuthenticate } from 'helpers/getAuthenticate';
import { COMMON_ROUTES, PUBLIC_ROUTES } from 'constants/routes';

export const commonRoutes = () => {
    return <>
        <Route path="/" element={getLayout(Login)} />
        <Route path={PUBLIC_ROUTES.newAccount} element={<NewAccount />} />
        <Route path="help" element={getAuthenticate(Help, '')} />
        <Route path={COMMON_ROUTES.forbidden} element={getLayout(Forbidden)} />
        <Route path={COMMON_ROUTES.exception} element={getLayout(authenticate(Exception, ''))} />
        <Route path="learnMore" element={getLayout(authenticate(LearnMore, ''))} />
        <Route path={COMMON_ROUTES.error} element={getLayout(authenticate(Error, ''))} />
        <Route path={COMMON_ROUTES.welcome} element={getLayout(authenticate(WelcomeDynamic, ''))} />
        <Route path={COMMON_ROUTES.logout} element={<Logout />} />
        <Route path="validateAccount" element={<ValidateAccount />} />
        <Route path="validatePartnerAccount" element={<ValidateAccount isPartnerActivation={true} />} />
    </>;
};
