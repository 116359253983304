/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './LeaderBoardSortableSections.scss';
import { arrayMove } from 'react-sortable-hoc';
import { SortableBoardNewTranslated } from './SortableBoardNew';

const defaultTable = [];
export const LeaderBoardSortableSections = ({ onChange, tableData: table = defaultTable, ...props }) => {
    const [tableData, setTableData] = useState(defaultTable);
    const handleSortEndTable = (sortorder) => {
        const newTableData = arrayMove(tableData, sortorder.oldIndex, sortorder.newIndex);
        setTableData(newTableData);
    };
    const onBoardChange = ( e ) => {
        onChange(e);
    };

    useEffect(() => {
        setTableData(table);
    }, [table]);

    return (
        <div className={`hme-leaderboard-sortable-sections ${props.isPreview ? 'pointer-events-none' : '' }`}>
            <SortableBoardNewTranslated
                handleSortEndTable={handleSortEndTable}
                tableData={tableData}
                onChange={onBoardChange}
                {...props}
            />
        </div>
    );
};
