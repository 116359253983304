import api from './api';

const paths = {
  getStoresBelongsToCompany: 'store/getGroupedCompanyStores'
}

const storeApi = {
  getStoresBelongsToCompany(requestParams) {
    let params = {};
    if(requestParams) {
      const { grouped } = requestParams;
      params = {
        grouped,
      }
    }
    return api.request({
      url: paths.getStoresBelongsToCompany,
      params,
      method: 'GET',
    });
  }
};

export default storeApi;
