import { Rank } from '../../Rank';

export const getRows = (groupedRounds) => {
    return groupedRounds.reduce((rows, group) => {
        const roundRows = group.stores.map((store, index) => {
            const className = store.rank === 1 ? 'hme-contest-view-edit-form__results__row--top' : '';

            return {
                rowClassName: className,
                tileItemClassName: className,
                round: index === 0 ? group.round : '',
                rank: <Rank rank={store.rank} />,
                store: store.store,
                metric: store.metric,
                metricString: String(store.metric),
                points: store.points,
                totalPoints: store.totalPoints,
                key: `store__${group.round}__${store.rank}`
            };
        });

        rows.push(...roundRows);

        return rows;
    }, []);
};
