import { reportDetails } from 'components/LandingPage/ReportLandingPage/reportConstant';
import { baseAuthService } from 'components/Security/AuthenticationService';

const filterReportsBySubscription = () => {
    const subscriptionID = baseAuthService.getProfile().Subscription_ID;
    const filteredReports = reportDetails.filter((report) =>
        report.subscription.includes(subscriptionID.toString())
    );
    return filteredReports;
};

export { filterReportsBySubscription };
