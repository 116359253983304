import { Config } from '../Config';
import axios from 'axios';
import AuthenticationService from '../components/Security/AuthenticationService';
import i18next from 'i18next';

const authService = new AuthenticationService(Config.authBaseUrl);

const axiosConfig = {
    baseURL: `${Config.apiBaseUrl}api/`,
    headers: {
        'Content-Type': 'application/json'
    }
};

const axiosInstance = axios.create(axiosConfig);
const contestAxiosInstance = axios.create();


const api = {
    request(options) {
        options.headers = Object.assign((options.headers || {}), {
            'Content-Language': i18next.resolvedLanguage,
            'Authorization': `Bearer ${authService.getToken()}`
        });
        return axiosInstance(options)
                .then((res) => options.responseType === 'blob' ? res.data : res.data.data);
    },

    contestRequest(options) {
        options.headers = Object.assign((options.headers || {}), {
            Authorization: `Bearer ${authService.getToken()}`
        });
        return contestAxiosInstance(options)
                .then((res) => {
                    return res.data;
                });
    }
};

export default api;
