// If you are going to add new format please check previous ones
// and if you can fild similar please contact your PO
// to check consistency of date formats
export const FORMAT_TYPES = {
    DATE_AND_TIME: 'MM/dd/yy h:mm a',
    DATE_ONLY: 'MM/dd/yyyy',
    TIME_WITH_DAY_PERIOD: 'HH:mm aaa',
    HOURS: 'H',
    FULL_YEAR_DATE_AND_TIME: 'MM/dd/yyyy h:mm a',
    SHORTENED_YEAR_DATE_AND_TIME_WITH_TIMEZONE: 'M/d/yyyy HH:mm xxx',
    SHORT_DATE_TIME: 'M/d/yy h:mm a',
    MONTH_DATE: 'MMM d,yyyy',
    MINUTES_SECONDS: 'mm:ss',
    SECONDS: 'ss',
    DEFAULT: "yyyy-MM-dd'T'HH:mm:ss'Z'",
    DEFAULT_ISO_WITH_TIMEZONE_OFFSET: `yyyy-MM-dd'T'HH:mm:ssXXX`,
    DATE_UNDERSCORED: 'yyyy_MM_dd',
    MONTH_DATE_WITH_TIME: 'MMM d, yyyy h:mm a',
    YEAR_ONLY: 'yyyy',
    MONTH_YEAR: 'MMMM yyyy',
    SHORTENED_MONTH_DATE_YEAR: 'M/d/yyyy',
    YEAR_MONTH_DATE: 'yyyy-MM-dd',
    FULL_HOURS_MINUTES: 'p',
    SHORTENED_HOURS_MINUTES: 'h:mm a',
    DATE_SELECTOR_DEFAULT: 'MM/dd/yy',
    CALENDAR_MONTH: 'M-yyyy',
    CALENDAR_DAY: 'd-M-yyyy',
    SINGLE_DAY_ONLY: 'd',
    SINGLE_MONTH_ONLY: 'M',
    SMACK_TALK_XL: 'M/dd/yyyy HH:mm a',
    SMACK_TALK_S: 'M/dd/yyyy | HH:mm a',
    FULL_DAY_OF_THE_WEEK: 'EEEE',
    DATE_FIRST_DATE_TIME: 'dd/MM/yyyy h:mm a'// TODO: add format in utility-common
};
