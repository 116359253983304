import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseNotification } from '../BaseNotification';
import { useNotificationsCenterContext } from '../../../Context';

export const LeaderboardDeclined = ({ notification, ...props }) => {
    const { id, readDate, sentDate, data: notificationData } = notification;

    const { t } = useTranslation();
    const data = useMemo(() => JSON.parse(notificationData), [notificationData]);

    const { readNotification: readNotificationContext } = useNotificationsCenterContext();

    const isRead = Boolean(readDate);

    useEffect(() => {
        if (!isRead && !readNotificationContext.isReading) {
            readNotificationContext.onRead(id);
        }
    }, []);

    return (
        <BaseNotification
            id={id}
            className='hme-notifications-center__notification--leaderboard-declined'
            title='notifications-center__notification__title--leaderboard-declined'
            sentDate={sentDate}
            isRead={isRead}
            body={t('notifications-center__notification__body--leaderboard-declined', {
                invitor: data.invitor
            })}
            {...props}
        />
    )
};
