export const mapBrandOptions = (brands) => [
    ...brands.map((brand) => ({
        text: brand.Name,
        value: brand.Id,
    })),
];

export const mapCountryOptions = (countries) => [
    ...countries.map((country) => ({
        text: country.Name,
        value: country.Id,
    })),
];

export const mapVaioProvidersOptions = (vaioProviders) => [
    ...(vaioProviders || []).map((provider) => ({
        text: provider.name,
        value: provider.id,
    })),
];
