import { CommonConstants } from 'Constants';
import { Config } from 'Config';
import { getDataAsync, getSearchString } from 'Api';

export const getPermissionsList = async ({ accountUuid = null, companyType } = {}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPermissionsList}`;

    const params = {};

    if (accountUuid) {
        params.auuid = accountUuid;
    }

    if (companyType) {
        params.companyType = companyType;
    }

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};
