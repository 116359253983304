export const addStoreFieldsList = (props) => {
  const vaioProviders = props && [{ value: 0, text: 'None' }, ...props.vaioProviders]
  const brands = props && props.brands || [];
  const countries = props && props.countries || [];
  if (brands[0] && brands[0].Id !== 0) {
    brands.unshift({ Id: 0, Name: '' });
  }

  return ([
    {
      html: 'storeBrandID',
      caption: 'Brand',
      isRequired: true,
      fieldType: 'select',
      name: 'storeBrandID',
      fieldId: 'storeBrandID',
      options: brands.map(el => {
        return {
          value: el.Id,
          slot: el.Name,
        }
      }),
      isDisabled: '',
    },
    {
      html: 'storeName',
      caption: 'Store Name',
      fieldType: 'input',
      name: 'storeName',
      fieldId: 'storeName',
      options: [],
      isDisabled: '',
    },
    {
      html: 'storeNumber',
      caption: 'Store Number',
      isRequired: true,
      fieldType: 'input',
      name: 'storeNumber',
      fieldId: 'storeNumber',
      options: [],
      isDisabled: '',
    },
    {
      html: 'storeCountryID',
      caption: 'Country',
      fieldType: 'select',
      name: 'storeCountryID',
      fieldId: 'storeCountryID',
      options: countries.map(el => {
        return {
          value: el.Id,
          slot: el.Name,
        }
      }),
      isDisabled: '',
    },
    {
      html: 'storeAXCustomerAccount',
      caption: 'AX Customer Account #',
      isRequired: true,
      maxlength: 10,
      fieldType: 'input',
      name: 'storeAXCustomerAccount',
      fieldId: 'storeAXCustomerAccount',
      options: [],
      isDisabled: ''
    },
    {
      html: 'vaioProviderId',
      caption: 'Select VAIO Provider',
      fieldType: 'select',
      name: 'vaioProviderId',
      fieldId: 'vaioProviderId',
      options: vaioProviders.map(el => {
        return {
          value: el.value,
          slot: el.text,
        }
      }),
      isDisabled: '',
    }
  ]);
}

export const editStoreFieldsList = (props) => {
  const brands = props && props.brands || [];
  const countries = props && props.countries || [];

  if (brands[0] && brands[0].Id !== 0) {
    brands.unshift({ Id: 0, Name: '' });
  }

  return ([
    {
      html: 'storeBrandID',
      caption: 'Brand',
      isRequired: true,
      fieldType: 'select',
      name: 'storeBrandID',
      fieldId: 'storeBrandID',
      options: brands.map(el => {
        return {
          value: el.Id,
          slot: el.Name,
        }
      }),
      isDisabled: '',
    },
    {
      html: 'storeName',
      caption: 'Store Name',
      fieldType: 'input',
      name: 'storeName',
      fieldId: 'storeName',
      options: [],
      isDisabled: '',
    },
    {
      html: 'storeNumber',
      caption: 'Store Number',
      isRequired: true,
      fieldType: 'input',
      name: 'storeNumber',
      fieldId: 'storeNumber',
      options: [],
      isDisabled: '',
    },
    {
      html: 'storeCountryID',
      caption: 'Country',
      fieldType: 'select',
      name: 'storeCountryID',
      fieldId: 'storeCountryID',
      options: countries.map(el => {
        return {
          value: el.Id,
          slot: el.Name,
        }
      }),
      isDisabled: '',
    },
    {
      html: 'storeAXCustomerAccount',
      caption: 'AX Customer Account #',
      isRequired: true,
      maxlength: 10,
      fieldType: 'input',
      name: 'storeAXCustomerAccount',
      fieldId: 'storeAXCustomerAccount',
      options: [],
      isDisabled: ''
    },
  ]);
}

export const addDeviceFieldsList = (props) => {
  const devices = props && props.devices || [];

  if (devices[0] && devices[0].Device_Name === '') {
    devices.unshift({ Device_Name: '' });
  }

  return ([
    {
      html: 'currentDeviceName',
      isRequired: true,
      fieldType: 'select',
      name: 'currentDeviceName',
      fieldId: 'currentDeviceName',
      options: devices.map(el => {
        return {
          value: el.Device_Name,
          slot: el.Device_Name,
        }
      }),
      isDisabled: '',
    },
  ]);
}
