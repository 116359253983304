import React from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import '../Footer/Footer.css'
import { Config } from '../../Config'
import AuthenticationService from '../Security/AuthenticationService'
import ActionLoader from '../Alerts/ActionLoader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { checkDevice, unBind, unBindEmail, reConnect } from '../../actions/actions'
import Error from '../Actions/Error'
import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';

class Reconnect extends React.Component {
  constructor (props) {
    super(props)
    this.authService = new AuthenticationService(Config.authBaseUrl);
    const { queryParams } = this.props;
    const duid = queryParams.get('duid');

    this.state = {
      showCommonLoader: true,
      deviceError: false,
      reConnectError: false,
      token: this.authService.getToken(),
      errorMessage: '',
      duid
    }
  }

  UNSAFE_componentWillMount () {
    if (this.state.duid) {
      let duid = this.state.duid
      let self = this
      this.props.checkDevice(duid, (device) => {
        if (device.status && device.data.DevicePresent === 1) {
          try {
            self.props.unBind(duid, (data) => {
              if (data.data) {
                self.props.unBindEmail(duid, () => {
                  console.log('emailSent')
                })
              }
            })
          } catch (err) {
            self.setState({
              errorMessage: 'device-connect__error--zomm-unreachable',
              reConnectError: true,
              showCommonLoader: false
            })
          }
          try {
            self.props.reConnect(duid, () => { })
          } catch (error) {
            self.setState({
              errorMessage: 'common__error--zoom-unreachable',
              reConnectError: true,
              showCommonLoader: false
            })
          }
        } else {
          this.setState({
            errorMessage: 'device-connect__error--bad-id',
            deviceError: true,
            showCommonLoader: false
          })
        }
      })
    }
  }

  render () {
    return (<section>
      {this.renderReconnect()}
      {<Error error={this.state.deviceError} />}
      {this.renderServiceError()}
    </section>)
  }

  renderServiceError () {
    const { t } = this.props;

    if (this.state.reConnectError) {
      return (<div className='col1'>
        <div className='forms clear'>
          <div className='error redbox_err_all redbox_marg'>
            <ul>
              <li>{t(this.state.errorMessage)}</li>
            </ul>
          </div>
        </div>
      </div>)
    } else {
      return (<div />)
    }
  }

  renderReconnect () {
    if (!this.state.deviceError && !this.state.reConnectError) {
      return (<section>
        <ActionLoader showLoader={this.state.showCommonLoader} message='device-connect__loading' />
        {/* <div className={'fteaser ' + (!this.state.showCommonLoader ? '' : 'hidden')}>
          <embed src='./remconn.cfm?duid=#URL.duid#' width='100%' height='815' />
        </div> */}
      </section>)
    } else {
      return (<div />)
    }
  }
}

Reconnect.propTypes = {
    queryParams: PropTypes.object
};

function mapStateToProps (state) {
  return {
    headers: state.headers,
    permissions: state.permissions
  }
}
function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      checkDevice: checkDevice,
      unBind: unBind,
      unBindEmail: unBindEmail,
      reConnect: reConnect
    }, dispatch
  )
}
export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter
)(Reconnect);
