/* eslint-disable react/prop-types */
import awardApi from 'api/awardApi';
import { Grid } from 'components/Common/Grid';
import { startYear } from 'containers/Awards/AwardsResettable/config';
import React, { useEffect, useState } from 'react';
import { DateLib } from '@hme-cloud/utility-common';

import './ResetDetails.scss';

const gridHeaders = [{
    text: 'common__stores',
    property: 'storeName'
}, {
    text: 'awards__count-before-reset',
    property: 'awardCount'
}];
export const ResetDetails = ({ data, resetHistory, allStores }) => {
    const [loadingTrophyCase, setLoadingTrophyCase] = useState(false);
    const [trophyCase, setTrophyCase] = useState([]);

    const getDates = () => {
        if (!resetHistory || !resetHistory.length || !data || !data['ResetDate']) {
            const now = new DateLib().toUTC().format();

            return [now, now];
        }

        const prevIndex = resetHistory.findIndex(({ AwardResetID }) => AwardResetID === data['AwardResetID']);
        const prevReset = ~prevIndex ? resetHistory[prevIndex + 1] : null;
        const startDate = prevReset && prevReset['ResetDate'] || new DateLib().toUTC().setYear(startYear).startOfYear().format();
        const endDate = data['ResetDate'];

        return [startDate, endDate];
    };
    useEffect(() => {
        const getTropyCase = async () => {
            setLoadingTrophyCase(true);
            const [startDate, endDate] = getDates();
            try {
                const trophyCase = await awardApi.getTropyCase({
                    startDate,
                    endDate,
                    storeUIDs: allStores.join(',')
                }) || [];
                const newTrophyCase = trophyCase
                        .map((el) => {
                            el.awardCount = el.awards && el.awards.length || 0;
                            el['StoreUID'] = el.Store['StoreUID'] || '';
                            el.storeName = el.Store['StoreName'] || el.Store['StoreNumber'];
                            return el;
                        })
                        .filter((el) => el.awardCount);
                setTrophyCase(newTrophyCase);
            } catch (e) {
                console.error(e);
            }
            setLoadingTrophyCase(false);
        };
        getTropyCase();
    }, []);
    return (
        <div className="hme-awards-reset-details-tab">
            <Grid
                rows={trophyCase || []}
                headers={gridHeaders}
                rowKey="StoreUID"
                isLoading={loadingTrophyCase}
            />
        </div>
    );
};
