import classNames from 'classnames';

import './FlexBreaker.scss';

export const FLEXBREAKER_VARIANTS = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
};

const defaultVariants = [FLEXBREAKER_VARIANTS.HORIZONTAL];

export const FlexBreaker = ({ variants = defaultVariants }) => {
    return (
        <div className={classNames('hme-flex-breaker', variants.map((variant) => `hme-flex-breaker--${variant}`))}></div>
    );
}