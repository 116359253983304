import React, { useCallback } from 'react';

import { StoreInput } from 'library/StoreInput';
import { getInputStores } from '../../CreateTaskController';

export const StoreForm = ({ sourceDevice, selectedDeviceType, filters, onFiltersChange }) => {
    const onStoreChange = useCallback((store) => {
        if (!store || filters.storeIds.includes(store.Store_ID)) {
            return;
        }

        onFiltersChange({
            ...filters,
            storeIds: [...filters.storeIds, store.Store_ID],
        });
    }, [filters, onFiltersChange]);

    const getStores = useCallback((mask) => {
        return getInputStores(mask, {
            ...filters,
            sourceDevice,
            selectedDeviceType,
        });
    }, [filters, sourceDevice, selectedDeviceType]);

    return (
        <StoreInput
            getStores={getStores}
            onStoreChange={onStoreChange}
            placeholder='common__placeholder__search-store-serial-product--short'
        />
    );
};
