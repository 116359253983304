import React from 'react';
import { Status } from '../List/Status';

export const ACCOUNTS_FILTER_OPTIONS = {
    Company_Type: {
        title: 'admin__accounts__grid__header--company-type',
        allText: 'distributors__accounts__grid__filter__company__types'
    },
    Country_Name: {
        title: 'common__country',
        allText: 'distributors__accounts__grid__filter__country__types'
    },
    Subscription_Name: {
        title: 'admin__distributors__grid__header--subscription',
        allText: 'distributors__accounts__grid__filter__subscription__types'
    },
    isActive: {
        title: 'account__section__form--account-status',
        allText: 'distributors__accounts__grid__filter__status__types',
        valuePropName: 'Account_IsActive',
        items: [{
            text: <Status isActive={false} />,
            value: 0,
        }, {
            text: <Status isActive={true} />,
            value: 1,
        }],
    }
};
