import semver from 'semver';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

// compare versions for "semver" type grid columns sorting
export default (a, b, order = 1) => {
    const versionA = BuildVersions.normalizeVersionString(a);
    const versionB = BuildVersions.normalizeVersionString(b);

    const [validAVersion, validBVersion] = [semver.valid(versionA), semver.valid(versionB)];

    // compare alphabetic values
    if (!validAVersion && !validBVersion) {
      return a.toLowerCase() > b.toLowerCase() 
          ? order
          : a.toLowerCase() < b.toLowerCase()
              ? -order
              : 0;
    }

    // compare alphabetic & semver values
    if (!validAVersion && validBVersion) {
        return 1;
    }

    // compare alphabetic & semver values
    if (validAVersion && !validBVersion) {
        return -1;
    }

    // compare semver values
    if (order === 1) {
        return semver.compare(versionA, versionB);
    }

    if (order === -1) {
        return semver.compare(versionB, versionA);
    }

    return 0;
};
