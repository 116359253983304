import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './ViewEditAction.scss';

const buttonVariants = ['transparent'];

export const ViewEditAction = ({ onRemoveClick, provider, canRemove }) => {
    const { t } = useTranslation();
    const providerURL = `/admin/editVaioProvider?puid=${provider.uid}`;

    return (
        <div className="vaio-providers__actions">
            <Link to={providerURL} className="vaio-providers__action-edit-user">
                <Button variants={buttonVariants}>{t('common__view-edit')}</Button>
            </Link>
            <span className="vaio-providers__actions-separator">|</span>
            <div className="vaio-providers__action-remove-user">
                <Button
                    variants={buttonVariants}
                    disabled={!canRemove}
                    onClick={() => onRemoveClick(provider)}
                >{t('common__remove')}</Button>
            </div>
        </div>
    );
};

ViewEditAction.propTypes = {
    onRemoveClick: PropTypes.func,
    provider: PropTypes.object,
    canRemove: PropTypes.bool
};
