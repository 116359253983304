import * as StoreRanking from '../actionTypes/StoreRanking/StoreRanking'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import AuthenticationService from '../components/Security/AuthenticationService'
import { translationKeysMap } from '../components/Dashboard/translationsMap';

const _ = require('underscore')

function getStoreRankingSuccess (stores) {
  return {
    type: StoreRanking.GET_STORERANKING,
    gaugeDetails: stores
  }
}

// this function return labels for x axis of lane transactions and lane total avg graphs
function formatDaypart (Daypart, StartTime, EndTime) {
  return Daypart + '\n' + StartTime + '\n' + '-\n' + EndTime
}

// this function adds current daypart label to specific column for lane transactions and lane total avg graphs
function addCurrentDP (data, rowId) {
  let currentDP = data.currentDaypart.length !== 0 ? data.currentDaypart[0].CurrentDayPart : null

  return currentDP === rowId ? 'dashboard__current-dp' : '';
}

function mobileLane (id) {
  return { Category_ID: id,
    Category_Name: null,
    Device_ID: null,
    EventType_ID: 'NA',
    EventType_Name: null,
    GoalA: null,
    GoalB: null,
    GoalC: null,
    GoalD: null,
    Sort: null,
    Value: 'NA'
  }
}

function pluckCategories (data, categories) {
  let gaugeDetails = []
  let detectors = data
  var DualLanes = _.filter(detectors.gaugeDetails, function (item) {
    return item.Category_ID === 1 || item.Category_ID === 2
  })
  if (DualLanes.length !== 1 && DualLanes.length % 2 !== 0) {
    if (_.where(detectors.gaugeDetails, { Category_ID: 1 }).length === 1) {
      detectors.gaugeDetails.push(mobileLane(1))
    } else if (_.where(detectors.gaugeDetails, { Category_ID: 2 }).length === 1) {
      detectors.gaugeDetails.push(mobileLane(2))
    }
  }

  categories.map(function (item, index) {
    if (item === 1 || item === 2) {
      let matchedData = _.sortBy(_.where(detectors.gaugeDetails, { Category_ID: item }), 'EventType_ID')
      matchedData.map(function (item, index) {
        item.Value = _.pluck(_.where(detectors.gaugeAvgTime, { EventType_ID: item.EventType_ID }), 'AvgTime')[0]
      })
      gaugeDetails.push({ 'title': 'Events', data: matchedData })
    } else {
      let matchedData = _.sortBy(_.where(detectors.gaugeDetails, { Category_ID: item }), 'EventType_ID')
      matchedData.map(function (item, index) {
        item.Value = _.pluck(_.where(detectors.gaugeAvgTime, { EventType_ID: item.EventType_ID }), 'AvgTime')[0]
      })
      matchedData.forEach((item, index) => {
        let detector = []
        detector.push(item)
        gaugeDetails.push({'title': 'Event', data: detector})
      })
    }
  })
  return gaugeDetails
}

// this function modifies data to object in required format for stacked bar chart with columns and rows
function formatLaneTransactions (data) {
  let dayparts = _.uniq(_.pluck(_.flatten(data.laneTrans), 'Daypart_ID'))
  let rows = []
  let columns = []
  dayparts.map(function (item, index) {
    let goals = _.pluck(_.where(data.laneTrans, { Daypart_ID: item }), 'CarCount')
    let title = formatDaypart(item, _.uniq(_.pluck(_.where(data.laneTrans, { Daypart_ID: item }), 'StartTime')), _.uniq(_.pluck(_.where(data.laneTrans, { Daypart_ID: item }), 'EndTime')))
    goals.unshift(addCurrentDP(data, item))
    goals.unshift(title)
    rows.push(goals)
  });

  // creating the object with translation keys and the dynamic data
  // for translating labels inside components

  data.laneTransGoals[0] = data.laneTransGoals[0] ? data.laneTransGoals[0] : {
    GoalA: null,
    GoalB: null,
  }

  const translationMap = {
    goal1: {
      tKey: 'dashboard__cars-transaction-goal--less',
      value: data.laneTransGoals[0].GoalA,
    },
    goal2: {
      tKey: 'dashboard__cars-transaction-goal--less',
      value: data.laneTransGoals[0].GoalB,
    },
    goal3: {
      tKey: 'dashboard__cars-transaction-goal--more-equal',
      value: data.laneTransGoals[0].GoalB,
    }
  };

  columns = [
    { label: 'title', type: 'string' },
    { 'type': 'string', role: 'annotation' },
    { label: 'goal1', type: 'number' },
    { label: 'goal2', type: 'number' },
    { label: 'goal3', type: 'number' }
  ];

  let colors = data.colorCode[0].ColourCode.split('|')
  let laneTrans = { rows: rows,
    columns: columns,
    translationMap,
    goalColors: colors }
  return laneTrans
}

// this function modifies data to object in required format for ranking table
function formatDashboard (data, state) {
  let psData = _.where(data.dashboard[0].rows, { storeUID: state.storeRanking.dashboardParams.suid })
  let ps = psData[0].storeRank
  if (data.dashboard[0].rows.length > 5) {
    let topStores = _.pluck(_.first(data.dashboard[0].rows, 3), 'storeRank')
    let bottomStores = _.pluck(_.last(data.dashboard[0].rows, 3), 'storeRank')
    let tables = {}
    if (parseInt(ps) === 1 || parseInt(ps) === 2) {
      tables = { 'topStores': topStores,
        'bottomStores': bottomStores,
        'centerStores': [1, 2, 3, 4, 5]
      }
    } else {
      tables = { 'topStores': topStores,
        'bottomStores': bottomStores,
        'centerStores': [(parseInt(ps) - 2), (parseInt(ps) - 1), parseInt(ps), (parseInt(ps) + 1), (parseInt(ps) + 2)]
      }
    }
    if (tables.length < 5) {
      tables = { 'topStores': topStores,
        'bottomStores': bottomStores,
        'centerStores': [(parseInt(ps) - 3), (parseInt(ps) - 2), (parseInt(ps) - 1), parseInt(ps), (parseInt(ps) + 1), (parseInt(ps) + 2), (parseInt(ps) + 3)]
      }
    }
    return tables
  } else {
    let topStores = _.pluck(data.dashboard[0].rows, 'storeRank')
    let tables = { 'topStores': topStores,
      'bottomStores': [],
      'centerStores': [] }
    return tables
  }
}

// this function modifies data to object in required format for stacked bar chart with columns and rows
function formatLaneTotalAvgTime (data, state) {
  let dayparts = _.uniq(_.pluck(_.flatten(data.laneTrans), 'Daypart_ID'))
  let rows = []
  let eventType = state.storeRanking.dashboardParams.eventType
  let event = _.where(data.gaugeDetails, { EventType_ID: parseInt(eventType) })
  dayparts.map(function (item, index) {
    let daypart = 'DP' + item + 'Avg'
    let EventsData = _.pluck(data.summerizedData, daypart)
    let title = formatDaypart(item, _.uniq(_.pluck(_.where(data.laneTrans, { Daypart_ID: item }), 'StartTime')), _.uniq(_.pluck(_.where(data.laneTrans, { Daypart_ID: item }), 'EndTime')))
    EventsData.unshift(event[0].GoalA)
    EventsData.unshift(addCurrentDP(data, item))
    EventsData.unshift(title)
    rows.push(EventsData)
  })
  let Events = _.pluck(data.summerizedData, 'EventName')

  let columns = [{ label: 'title', type: 'string' }]
  columns.push({ 'type': 'string', role: 'annotation' })
  columns.push({ label: 'common__goal', type: 'number' })

  Events.map(function (item, index) {
    let title = translationKeysMap[item] || item
    columns.push({ label: title, type: 'number' })
  })

  let colors = CommonConstants.LaneTotalAvgColors
  let laneTrans = { rows: rows,
    columns: columns,
    goalColors: colors,
    events: Events }
  return laneTrans
}

// returns all the data to plot graphs, gauges and store ranking table in store ranking screen.
export const getStoreRanking = (params, callback) => {
  let url = null
  return (dispatch, getState) => {
    const api = new Api()
    const state = getState()
    url = Config.apiBaseUrl + CommonConstants.apiUrls.getStoreranking + '?report=' + state.storeRanking.dashboardParams.report + '&eventType=' + state.storeRanking.dashboardParams.eventType + '&suid=' + state.storeRanking.dashboardParams.suid
    api.getData(url, data => {
      let categoriesData = data.gaugeDetails
      let categories = _.uniq(_.pluck(_.flatten(categoriesData), 'Category_ID'))
      let gaugeDetails = pluckCategories(data, categories)
      let laneTransactions = formatLaneTransactions(data)
      let laneTotalAvgTime = formatLaneTotalAvgTime(data, state)
      data.gaugeDetails = gaugeDetails
      data.laneTransactions = laneTransactions
      data.laneTotalAvgTime = laneTotalAvgTime
      dispatch(getStoreRankingSuccess(data))
      callback()
    })
  }
}

export const setDashBoardParams = (params) => {
  return {
    type: StoreRanking.SET_DASHBOARDPARAMS,
    dashboardParams: params
  }
}

function getDashboard (stores) {
  return {
    type: StoreRanking.GET_DASHBOARDATA,
    dashboard: stores
  }
}

export const getDashboardData = (callback) => {
  const authService = new AuthenticationService(Config.authBaseUrl)
  return (dispatch, getState) => {
    const api = new Api()
    const state = getState()
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.getDashboard + '?isAdmin=' + (authService.isAdmin() ? '1' : '0') + '&reportType=' + state.storeRanking.dashboardParams.report + '&eventType=' + state.storeRanking.dashboardParams.eventType + '&storeDashboard=1' + (state.storeRanking.dashboardParams.report === 'custom' ? '&suid=' + state.storeRanking.dashboardParams.suid : '') + `&store=${state.storeRanking.dashboardParams.suid}`
    url = authService.getUUID() ? url + '&uuid=' + authService.getUUID() : url
    api.getData(url, data => {
      let tables = formatDashboard(data, state)
      data.tables = tables
      dispatch(getDashboard(data))
      callback()
    }, error => {
      console.log('Error from dashboard action', error)
    })
  }
}
