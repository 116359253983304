/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox, CHECKBOX_VALUES } from 'library/Checkbox';
import { Label } from 'library/Label';
import './FilterSection.scss';
import { useTranslation } from 'react-i18next';

const defaultItems = [];
const defaultSelection = [];

const getAllSelectedValue = (items, selection) => {
    if (selection.length === 0) {
        return CHECKBOX_VALUES.UNCHECKED;
    }

    const isAllChecked = items.every(({ value }) => selection.includes(value));

    return isAllChecked ? CHECKBOX_VALUES.CHECKED : CHECKBOX_VALUES.PARTIAL;
};
export const FilterSection = ({
    items = defaultItems,
    selection = defaultSelection,
    allText,
    noItemsFoundText,
    searchValue,
    onSearchItemsChange,
    SearchItemsComponent,
    onChange,
    property
}) => {
    const { t } = useTranslation();

    const isMultipleItems = items.length > 1;
    const isItemsFoundWithSearch = !!(SearchItemsComponent && items.length);

    return (
        <div className="hme-joined-filters-popup-section">
            {
                SearchItemsComponent &&
                <div className='hme-grid-filter-cell-popup-search'>
                    <SearchItemsComponent value={searchValue} onChange={onSearchItemsChange} />
                </div>
            }
            {
            isMultipleItems || isItemsFoundWithSearch ?
                <div className="hme-joined-filters-popup-row">
                    <Checkbox
                        label={t(allText) || t('common__all')}
                        checked={getAllSelectedValue(items, selection)}
                        onChange={onChange && ((checked) => {
                            const newSelection = checked ? items.map(({ value }) => value) : [];
                            onChange({ [property]: newSelection });
                        })}
                    />
                </div> :
                <Label className="hme-joined-filters-popup-no-items-found">{noItemsFoundText}</Label>
            }
            {
                items.map(({ text, value }) => (
                    <div
                        className={'hme-joined-filters-popup-row hme-joined-filters-popup-item'}
                        key={value}
                    >
                        <Checkbox
                            label={text}
                            checked={selection.includes(value)}
                            onChange={onChange && ((checked, e) => {
                                e.stopPropagation();
                                const newSelection = checked ?
                                [...selection, value] :
                                selection.filter((selectedItem) => selectedItem !== value);
                                onChange({ [property]: newSelection });
                            })}
                        />
                        {isMultipleItems || isItemsFoundWithSearch ? <span className="hme-joined-filters-popup-only-label">
                            <span onClick={onChange && ((e) => {
                                e.stopPropagation();
                                onChange({ [property]: [value] });
                            })}>{t('common__only')}</span>
                        </span> : ''}
                    </div>
                ))
            }
        </div>
    );
};
