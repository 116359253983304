const getStatusNumber = (device) => {
    if (!device.Device_Name || !device.Device_MainVersion) {
        return -2;
    }

    if (device.Device_IsPreconfigured) {
        return -1;
    }

    return device.Device_IsActive;
};

export const sortByStatus = (rows, direction) => (
    rows.map((row) => ({
        ...row,
        Device_Details: row.Device_Details
            .map((device) => ({
                ...device,
                statusNumber: getStatusNumber(device),
            }))
            .sort((a, b) => {
                const firstValue = a.statusNumber;
                const secondValue = b.statusNumber;

                if (firstValue === secondValue) {
                    return 0;
                }

                return firstValue < secondValue ? direction : -direction;
            }),
    }))
    .sort((a, b) => {
        const firstValue = a.Device_Details.length ? a.Device_Details[0].statusNumber : -2;
        const secondValue = b.Device_Details.length ? b.Device_Details[0].statusNumber : -2;

        if (firstValue === secondValue) {
            return 0;
        }

        return firstValue < secondValue ? direction : -direction;
    })
);
