import PropTypes from 'prop-types';

export const availableFilterItemType = PropTypes.arrayOf(
    PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.number),
        ]),
    }),
);

const availableFilterType = PropTypes.shape({
    allText: PropTypes.string,
    property: PropTypes.string,
    items: availableFilterItemType
});

export const availableFiltersType = PropTypes.arrayOf(availableFilterType);
