import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isAdmin } from 'services/Auth';
import { NotificationsList } from 'library/NotificationsList';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { mobileHeadersType } from 'library/TileList';
import {Paginate} from "library/Paginate";
import { headersType } from 'components/Common/Grid';
import { PAGE_DATA } from "constants/paginationDefault";
import { Action as BaseActions } from './Action';
import { Header } from './Header';
import { List } from './List';

import './StatusPage.scss';

const PAGE_SIZES = isAdmin() ? PAGE_DATA.PAGE_SIZES_ADMIN : PAGE_DATA.PAGE_SIZES_PUBLIC;

export const StatusPage = ({
    hasCreateAccess,
    withAccountEmail = false,
    addRoleUrl,
    headers,
    mobileHeaders,
    rows,
    isLoading,
    baseViewLink,
    hasRemoveAccess,
    hasViewAccess,
    onRemove,
    Actions = BaseActions,
    recordsTotal,
    paginationData,
    onPaginateChange
}) => {
    const { t } = useTranslation();
    const [rowsWithActions, setRowsWithActions] = useState([]);
    const [removedRole, setRemovedRole] = useState(null);
    const [isRemoveConfirmShown, setIsRemoveConfirmShown] = useState(false);

    const onRemoveAction = useCallback((role) => {
        setRemovedRole(role);
        setIsRemoveConfirmShown(true);
    }, [setRemovedRole, setIsRemoveConfirmShown]);

    const onCancelRemove = useCallback(() => {
        setIsRemoveConfirmShown(false);
    }, [setIsRemoveConfirmShown]);

    const onConfirmRemove = useCallback(() => {
        setIsRemoveConfirmShown(false);

        onRemove && onRemove(removedRole);
    }, [onRemove, removedRole, t, setIsRemoveConfirmShown]);

    useEffect(() => {
        const newRows = rows.map((row) => ({
            ...row,
            Actions: <Actions
                role={row}
                viewLink={baseViewLink}
                hasRemoveAccess={hasRemoveAccess}
                hasViewAccess={hasViewAccess}
                onRemove={onRemoveAction}
            />
        }));

        setRowsWithActions(newRows);
    }, [rows, Actions, baseViewLink, hasRemoveAccess, hasViewAccess, onRemoveAction, setRowsWithActions]);

    return (
        <div className='hme-roles'>
            <NotificationsList />
            <Header hasCreateAccess={hasCreateAccess} addRoleUrl={addRoleUrl} withAccountEmail={withAccountEmail} />
            <List headers={headers} mobileHeaders={mobileHeaders} rows={rowsWithActions} isLoading={isLoading} />
            <Paginate
                total={recordsTotal}
                page={paginationData.pageNumber}
                onChange={onPaginateChange}
                recordsPerPage={paginationData.pageSize}
                pageSizes={PAGE_SIZES}
            />
            <ConfirmPopupComponentGeneric
                show={isRemoveConfirmShown}
                title={t('common__double-checking')}
                message={t('roles__confirm__message')}
                actions={[{
                    children: t('common__popup--cancel-action'),
                    onClick: onCancelRemove
                }, {
                    children: t('common__popup--confirm-action'),
                    variants: ['submit'],
                    className: 'hme-users--distributor-account--confirm-button',
                    onClick: onConfirmRemove
                }]}
            />
        </div>
    );
};

StatusPage.propTypes = {
    hasCreateAccess: PropTypes.bool,
    withAccountEmail: PropTypes.bool,
    addRoleUrl: PropTypes.string,
    headers: headersType,
    mobileHeaders: mobileHeadersType,
    rows: PropTypes.arrayOf(PropTypes.any),
    isLoading: PropTypes.bool,
    baseViewLink: PropTypes.string,
    hasRemoveAccess: PropTypes.bool,
    hasViewAccess: PropTypes.bool,
    onRemove: PropTypes.func,
    Actions: PropTypes.elementType,
};
