import * as storeDetails from '../../actionTypes/StoreDetails/storeDetails'
// set the storeDetails  redux initialState
const initialState = {
  storeDetails: {
    'storeList': [
    ],
    'userPermessions': [
    ],
    'status': null
  },
  adminStoreDetails: {
    'storeList': [
    ],
    'userPermessions': [
    ],
    'status': null
  },
  searchParams: { filter: null, criteria: null }
}

//  StoreDetails redux action & change the StoreDetails state
export default function StoreDetails (state = initialState, action) {
  switch (action.type) {
    case storeDetails.INIT_STORESDETAILS:
      state.storeDetails = action.storeDetails
      return {
        ...state
      }
    case storeDetails.SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.sortParams
      }
    case storeDetails.ADMIN_STORESDETAILS:
      state.adminStoreDetails = action.adminStoreDetails
      return {
        ...state
      }
    case storeDetails.PAGINATION_STORESDETAILS:
      return {
        ...state,
        paginationParams: action.paginationParams
      }
    case storeDetails.SET_SEARCH_PARAMS_STORES:
      return {
        ...state,
        searchParams: action.searchParams
      }
    default:
      return state
  }
}
