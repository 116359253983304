/* eslint-disable require-unicode-regexp */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import classNames from 'classnames';
import { InputComponent } from '..';
import './InputColorPicker.scss';

export const InputColorPicker = ({ value, onChange, className, ...inputProps }) => {
    const [color, setColor] = useState('#000000');
    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef();

    const onInputChange = (e) => {
        if (e === color) {
            return;
        }
        const colorWithHash = `#${String(e).replace(/[^\dabcdefABCDEF]/ug, '')}`.toUpperCase().slice(0, 7);
        setColor(colorWithHash);
        onChange(colorWithHash);
    };

    useEffect(() => {
        setColor(value);
    }, [value]);

    const handleOutsideClick = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setShowPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);

    const handleColorChange = (color) => {
        setColor(color);
        onChange(color);
    };

    const togglePicker = () => {
        setShowPicker((prevState) => !prevState);
    };

    return (
        <InputComponent
            {...inputProps}
            value={color}
            onChange={onInputChange}
            className={classNames('hme-input-color-picker', className)}
        >
            <div ref={pickerRef} className="color-preview-wrapper">
                <div
                    onClick={togglePicker}
                    className="color-preview"
                    style={{ backgroundColor: color }}
                />
                {showPicker && (
                    <div className="hexColorPickerWrapper" >
                        <HexColorPicker color={color} onChange={handleColorChange} />
                    </div>
                )}
            </div>
        </InputComponent>
    );
};
