import { addErrorNotification, dismissNotification } from 'services/Notifications';
import { verifyDFACode, getProfile } from 'services/Auth';
import { defaultNotificationOptions } from 'constants/login';
import { COMMON_ROUTES, PUBLIC_ROUTES } from 'constants/routes';

const notifyError = (message) => {
    dismissNotification();
    addErrorNotification(message, defaultNotificationOptions);
};

export const getEmailMask = () => {
    const userEmail = getProfile().User_EmailAddress;
    const [emailStart, emailEnd] = userEmail.split('@');

    const mask = emailStart.replace(/[A-Za-z0-9]/g, '*');

    return `${mask}@${emailEnd}`;
};

export const verifyCode = async (code) => {
    try {
        const response = await verifyDFACode(code);

        if (!response.status) {
            notifyError('common__error--internal-server');

            return false;
        }

        if (response.isExpired) {
            notifyError('dfa-code__error--expired');

            return false;
        }

        if (!response.isValid) {
            notifyError('dfa-code__error--invalid');

            return false;
        }

        return true;
    } catch (err) {
        notifyError('common__error--internal-server');
    }

    return false;
};

export const auth = () => {
    window.localStorage.setItem('isDFAVerified', 1);

    const TOSAgree = getProfile().Account_TOS_Agree;

    // TODO: check whether we have still can reach to this point after [CE-3337]
    if (TOSAgree === 0) {
        window.location.href = `/${PUBLIC_ROUTES.newAccount}`;
    } else {
        window.location.href = `/${COMMON_ROUTES.welcome}`;
    }
};
