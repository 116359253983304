const SSO_DOMAIN_STATUS_ENUMS = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED'
};

const SSO_CONFIG_STATUS_ENUMS = {
    VERFIED: 'VERIFIED',
    UNVERIFIED: 'UNVERIFIED'
};

const isSSOEnabled = (ssoDomainStatus = '') => ssoDomainStatus.toUpperCase() === SSO_DOMAIN_STATUS_ENUMS.ENABLED;

const ssoConfigEnabled = (ssoConfigStatus = '') => ssoConfigStatus.toUpperCase() === SSO_CONFIG_STATUS_ENUMS.VERFIED;

module.exports = {
    isSSOEnabled,
    ssoConfigEnabled,
    SSO_DOMAIN_STATUS_ENUMS,
};
