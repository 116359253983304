import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Label } from 'library/Label';
import { ReactComponent as SearchIcon } from '../../images/IconSearchField.svg';

import { SelectInput } from './SelectInput';
import { SelectDropdown } from './SelectDropdown';
import { itemProps } from './types/itemProps';

import './MultiSelect.scss';

const BAGE_HEIGHT = 44; // single bage element height with margin & padding
const DEFAULT_VISIBLE_ROWS = 3;
const MAX_VISIBLE_ROWS = 6;

export const MultiSelect = ({
    searchValue = '',
    searchMinSymbols = 3,
    items = [],
    selections = [],
    isLoading = false,
    isReadOnly = false,
    variants = [],
    className = '',
    label = '',
    labelTotal = '',
    isRequired = false,
    withSelectAll = false,
    withShowMore = true,
    placeholder = 'common__select-item',
    showMoreLabel = 'common__show-all-items',
    showLessLabel = 'common__show-less-items',
    noRecordsMessage = 'common__error--no-data-found',
    shouldClearSearchOnFocus = false,
    shouldClearSearchOnBlur = false,
    isDisabled = false,
    visibleRows = DEFAULT_VISIBLE_ROWS,
    onSearchValueChange,
    onSelectionChange,
}) => {
    const { t } = useTranslation();
    const [isDropdownShown, setIsDropdownShown] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const showMoreHeight =
        visibleRows > MAX_VISIBLE_ROWS ? DEFAULT_VISIBLE_ROWS * BAGE_HEIGHT : visibleRows * BAGE_HEIGHT;

    const deleteBadge = (value) => {
        onSelectionChange && onSelectionChange(selections.filter((item) => item.value !== value));
    };

    const onShowDropdown = () => {
        setIsDropdownShown(true);
    };

    const onHideDropdown = () => {
        setIsDropdownShown(false);
    };

    const onInputChange = (value) => {
        value.length >= searchMinSymbols ? onShowDropdown() : onHideDropdown();

        onSearchValueChange && onSearchValueChange(value);
    };

    const onInputClick = () => {
        if (searchValue.length >= searchMinSymbols) {
            onShowDropdown();
        }
    };

    const formattedPlaceholder = t(placeholder);

    const onFocus = () => {
        if (shouldClearSearchOnFocus && !isFocused && selections.length) {
            onSearchValueChange && onSearchValueChange('');
        }

        setIsFocused(true);

        if (searchValue.length >= searchMinSymbols) {
            onShowDropdown();
        }
    };

    const onBlur = () => {
        if (shouldClearSearchOnBlur && isFocused) {
            onSearchValueChange && onSearchValueChange('');
        }

        setIsFocused(false);
    };

    const isLabelVisible = label || (selections.length > 0 && labelTotal);

    return (
        <div
            className={classNames(
                'hme-multi-select__container',
                {
                    'hme-multi-select__required': isRequired,
                },
                className,
                variants.map((variant) => `hme-multi-select__${variant}-variant`),
                isDropdownShown ? 'hme-multi-select__container--dropdown-shown' : '',
            )}
        >
            {isLabelVisible && (
                <div className="hme-multi-select__head">
                    <span className="hme-single-select__box__star">*</span>
                    <div className="hme-multi-select__labels">
                        {label && <Label>{t(label)}</Label>}
                        {selections.length > 0 && labelTotal && (
                            <Label>
                                <span>{selections.length}</span> {t(labelTotal)}
                            </Label>
                        )}
                    </div>
                </div>
            )}
            <SelectInput
                selections={selections}
                onChange={onInputChange}
                onDelete={deleteBadge}
                onClick={onInputClick}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={formattedPlaceholder}
                value={searchValue}
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
                showMoreHeight={showMoreHeight}
                showMoreLabel={showMoreLabel}
                showLessLabel={showLessLabel}
                withShowMore={withShowMore}
                visibleRows={visibleRows}
                icon={<SearchIcon className="hme-multi-select__search-icon" />}
            />
            {!isDisabled && !isReadOnly && (
                <SelectDropdown
                    isLoading={isLoading}
                    show={isDropdownShown}
                    items={items}
                    selections={selections}
                    onChange={onSelectionChange}
                    onHide={onHideDropdown}
                    noRecordsMessage={noRecordsMessage}
                    withSelectAll={withSelectAll}
                />
            )}
        </div>
    );
};

MultiSelect.propTypes = {
    searchValue: PropTypes.string.isRequired,
    searchMinSymbols: PropTypes.number,
    items: PropTypes.arrayOf(itemProps).isRequired,
    selections: PropTypes.arrayOf(itemProps).isRequired,
    isLoading: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    variants: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    title: PropTypes.string,
    labelTotal: PropTypes.string,
    placeholder: PropTypes.string,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
    noRecordsMessage: PropTypes.string,
    showMoreHeight: PropTypes.number,
    shouldClearSearchOnFocus: PropTypes.bool,
    onSearchValueChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
};
