import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'ramda';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Button } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { reportEvents, embedType, pbiErrorMessage, downloadableReports, reportsWithoutTemplates } from 'constants/pbiReports';
import './index.css';
import withAccountEmail from 'HOCs/withAccountEmail';
import { withHMELayout } from 'HOCs/withHMELayout';
import CommonLoader from '../Alerts/CommonLoader';
import AccountErrorMessage from 'components/Common/AccountErrorMessage';
import { useCheckAccount } from 'hooks/useCheckAccount';
import _ from 'underscore';
import { ConfirmPopupWithoutCancel } from '../../library/ConfirmPopup/ConfirmPopup.js';
import { initiateDownloadRequest, getDownloadableFile, getDownloadReportStatus, getEmbedConfig, getStartOfTheWeek } from 'api/pbiReports';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { PbiTemplate } from './PbiTemplates/PbiTemplate';
import fileDownload from 'js-file-download';
import { Buffer } from 'buffer';
import { NotificationsList } from 'library/NotificationsList';
import { addErrorNotification, addSuccessNotification, dismissNotification } from 'services/Notifications';
import { ReportsHeader } from 'components/Header/ReportsHeader';
import { reportNames } from 'components/LandingPage/ReportLandingPage/reportConstant';
import { sendMetrics } from './helpers/SendMetrics';
import { getDateInfo } from './helpers/GetDateInfo';
import { COMMON_ROUTES } from 'constants/routes';
import { DateLib } from '@hme-cloud/utility-common';
import { startOfTheWeekFilter } from './PBIReportConstant';


// eslint-disable-next-line complexity
export const PbiReportScreen = ({ showOnlyReport=false }) => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const type = pathArray[pathArray.length - 1];
    const { t } = useTranslation();
    const [reportsLoaded, setReportsLoaded] = useState(false);
    const { loaded, rendered, error, firstRendered, unloaded } = reportEvents;
    const { report } = embedType;
    const [showCommonLoader, setShowCommonLoader] = useState(true);
    const [detailsChecked, setDetailsChecked] = useState({
        noStores: false,
        noPermissions: false,
        accountError: false,
        checked: false
    });
    const {
        noStores,
        noPermissions,
        accountError
    } = detailsChecked;
    const [reportConfig, setReportConfig] = useState({});
    const { reportId, embedUrl, embedToken } = reportConfig;
    const [showCancelModal, setShowCancelModal] = useState(false);
    const { TOKEN_EXPIRED } = pbiErrorMessage;
    const [startWatcher, setStartWatcher] = useState(false);
    const [isFirstRendered, setIsFirstRendered] = useState(false);
    const profile = baseAuthService.getProfile();
    const isAdmin = baseAuthService.isAdmin();
    const userEmail = baseAuthService.getUserEmail();
    const uuid = baseAuthService.getUUID();
    const [downloadButtonActive, setDownloadButtonActive] = useState(true);
    const [showTemplateButtons, setShowTemplateButtons] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const [iframeClassName, setIframeClassName] = useState('Embed-container-multi');
    const [pbiEventData, setPbiEventData] = useState({});
    const [startOfTheWeek, setStartOfTheWeek] = useState('Sunday');
    const isDownloadAllowed = downloadableReports.includes(reportNames[type]);
    const isTemplateSupported = reportsWithoutTemplates.includes(reportNames[type]);
    // ID for logAnalytics records
    const pbiMetricsEmail = useMemo(()=>
        isAdmin ? userEmail : profile.User_EmailAddress, [type]);
    const pbiMetricsID = useMemo(() =>
    isAdmin ? userEmail + uuid + new DateLib(new Date()).getUnixTime() :
        profile.User_EmailAddress + profile.User_UID + new DateLib().getUnixTime(), [type]
    );

    /**
     * verify if token is available
     * @param {object} expiresDate date string
     * @return {boolean} false if token is available
     */
    const checkTokenExpired = (expiresDate) => {
        const diff = new DateLib(expiresDate).diff(new DateLib().format(), 'minutes');
        if (diff < 3) {
            return false;
        }

        return true;
    };

    const handlePowerBiEvent = (eventType, eventData = pbiEventData, errorResponse = {}) => {
        sendMetrics({ pbiMetricsID, eventType, type, pbiMetricsEmail, eventData, errorResponse });
    };

    // Call API to get Power Bi Token
    const getReports = async () => {
        setShowCommonLoader(true);
        setStartWatcher(false);
        const localConfig = JSON.parse(localStorage.getItem(`pbi-token-${type}`));
        const isTokenAvaliable = checkTokenExpired(localConfig?.expiresDate);
        try {
            if (!localConfig || !isTokenAvaliable || (isAdmin && type==='rcd')) {
                const data = await getEmbedConfig({
                    reportType: type,
                    ownerEmail: userEmail || undefined
                });
                const expiresDate = new DateLib().addMinutes(20).format();
                const configToSave = {
                    token: data.embedToken,
                    expiresDate,
                    email: userEmail
                };
                localStorage.setItem(`pbi-token-${type}`, JSON.stringify(configToSave));
                setReportConfig(data);
                setReportsLoaded(false);
            } else {
                const config = await getEmbedConfig({
                    reportType: type,
                    hasToken: true,
                    ownerEmail: userEmail || undefined
                });
                setReportConfig({
                    ...config,
                    embedToken: localConfig.token
                });
                setReportsLoaded(false);
            }
        } catch (badRequest) {
            const errorResponse = {
                message: 'EnvironmentVariableError',
                detailedMessage: 'An environmental variable has an error and mismatch the real value of the resource.'
            };
            handlePowerBiEvent(error, {}, errorResponse);
        }
    };

    useCheckAccount(getReports, setShowCommonLoader, setDetailsChecked, detailsChecked, type);

    const refreshToken = async () => {
        const localConfig = JSON.parse(localStorage.getItem(`pbi-token-${type}`));
        const isTokenAvaliable = checkTokenExpired(localConfig?.expiresDate);
        try {
            if (!localConfig || !isTokenAvaliable) {
                const data = await getEmbedConfig({
                    reportType: type,
                    ownerEmail: userEmail || undefined
                });
                const expiresDate = new DateLib().addMinutes(20).format();
                const configToSave = {
                    token: data.embedToken,
                    expiresDate,
                    email: userEmail
                };
                localStorage.setItem(`pbi-token-${type}`, JSON.stringify(configToSave));
                await window.report.setAccessToken(data.embedToken);
            }
        } catch (badRequest) {
            const errorResponse = {
                message: 'EnvironmentVariableError',
                detailedMessage: 'An environmental variable has an error and mismatch the real value of the resource.'
            };
            handlePowerBiEvent(error, {}, errorResponse);
        }
    };
    /**
     * set the reportLoaded state when the report configuration object is updated
     * @param {object} reportConfig report configuration object
     */
    useEffect(() => {
        if (!_.isEmpty(reportConfig)) {
            setShowCommonLoader(false);
            setReportsLoaded(true);
            setStartWatcher(true);
        }

        // clean up before change report
        return () => {
            setReportsLoaded(false);
            if (isAdmin) {
                localStorage.removeItem('pbi-token-rcd');
            }
        };
    }, [reportConfig]);
    /**
     *  keep watching if the token expires mean while the user is using the rcd report
     * @param {boolean} startWatcher
     */
    useEffect(() => {
        const localConfig = JSON.parse(localStorage.getItem(`pbi-token-${type}`));
        let isTokenExpired = false;
        let tokenWatcher;
        if (isAdmin && localConfig?.email !== baseAuthService.getUserEmail() && type!=='rcd') {
            localStorage.removeItem(`pbi-token-${type}`);
            refreshToken();
        }
        tokenWatcher = setInterval(() => {
            const isTokenAvaliable = checkTokenExpired(localConfig.expiresDate);
            if (!baseAuthService.isLoggedIn()) {
                return (
                    <Navigate
                        to={{
                            pathname: COMMON_ROUTES.welcome,
                            state: { referrer: location  }
                        }} />
                );
            }
            if (!isTokenAvaliable) {
                isTokenExpired = true;
                if (type==='rcd') {
                    setShowCancelModal(true);
                } else {
                    refreshToken();
                }
            }
        }, 30000);
        if (!startWatcher || isTokenExpired) {
            clearInterval(tokenWatcher);
            tokenWatcher = false;
        }
        return () => {
            clearInterval(tokenWatcher);
            tokenWatcher = false;
            setShowTemplateButtons(false);
            setTemplateData({});
        };
    }, [startWatcher]);

    /**
     * Execute the unloaded event when the report is unmounted
     * @param {string} type report type
     */
    useEffect(() => {
        setIsFirstRendered(false);
        if (type === 'trendsHistory' ||
        type === 'outliers-client' || type === 'performance-analysis') {
            setIframeClassName('Embed-container-trends-dashboard');
        } else if (type === 'rcd') {
            setIframeClassName('Embed-container-rcd');
            // simulating this event due to limitation of
            // paginated report of not supporting pbi events.
            handlePowerBiEvent(loaded);
        } else {
            setIframeClassName(`Embed-container-${type}`);
        }
        const fetchStartOfTheWeek = async () => {
            const startDayData = await getStartOfTheWeek({ userUID: isAdmin ? uuid : profile.User_UID });
            if (startDayData.status && startDayData.startOfTheWeek) {
                setStartOfTheWeek(startDayData.startOfTheWeek);
            }
        };
        fetchStartOfTheWeek();
        return () => {
            handlePowerBiEvent(unloaded);
        };
    }, [type]);

    useEffect(() => {
        if (isFirstRendered) {
            handlePowerBiEvent(firstRendered);
        }
    }, [isFirstRendered]);

    const onReportLoaded = (event) => {
        console.log('report loaded');
        handlePowerBiEvent(event.type);
    };
    const onReportRendered = async (event) => {
        try {
            const reports = window.report;
            // Retrieve the page collection.
            const pages = await reports.getPages();
            // Retrieve the active page.
            const activePage = pages.filter(function(page) {
                return page.isActive;
            })[0];
            setTemplateData((prevValues)=> ({ ...prevValues, reportType: activePage.displayName }));
            const advancedCards = [];
            const dateIntervalSlicer = [];
            const visuals = await activePage.getVisuals();
            visuals.forEach((visual)=>{
                if (visual.title.toLowerCase().includes('version')) {
                    setTemplateData((prevValues)=> ({ ...prevValues, version: visual.title.slice(8) }));
                }
                if (visual.title === 'Initial Date' || visual.title === 'Final Date') {
                    advancedCards.push(visual);
                }
                if (visual.title === 'Date Filter') {
                    dateIntervalSlicer.push(visual);
                }
            });
            const reportDates = await getDateInfo(advancedCards, dateIntervalSlicer, setPbiEventData);
            setPbiEventData(reportDates);
            console.log('report rendered');
            handlePowerBiEvent(event.type, reportDates);
            // Retrieve the target visual.
            const slicers = visuals.filter((visual) =>
                visual.type === 'slicer'
            );
            slicers.map(async (slicer)=> {
                const { filters } = await slicer.getSlicerState();
                if (filters.length > 0) {
                    const { target, values, hierarchyData } = filters[0];
                    if (hierarchyData) {
                        setTemplateData((prevValues) => ({ ...prevValues, hierarchyData: hierarchyData }));
                    } else {
                        setTemplateData((prevValues) => ({ ...prevValues, [target.column]: values }));
                    }
                }
            });
        } catch (errors) {
            console.log('report rendered');
            // This is not report rendering error.
            // Only non-critical backend task failed.
            // Continue sending following successful metric collection request.
            handlePowerBiEvent(event.type);
        } finally {
            setIsFirstRendered(true);
            setShowTemplateButtons(!isTemplateSupported);
        }
    };

    const handleError = (event) => {
        const errorResponse = event.detail;
        console.error(errorResponse);
        if (errorResponse.message === TOKEN_EXPIRED) {
            setShowCancelModal(true);
        }
        handlePowerBiEvent(error, {}, errorResponse);
    };
    const handleconfirm = () => {
        getReports();
        setShowCancelModal(false);
    };
    const onDownLoad = async () => {
        setDownloadButtonActive(false);
        addSuccessNotification(t('common__task__status--download-in-progress'));
        const capturedBookmark = await window.report.bookmarksManager.capture();
        const pages = await window.report.getPages();
        // Retrieve the active page.
        const activePage = pages.filter(function(page) {
            return page.isActive;
        })[0];
        try {
            const { reportID, exportID } = await initiateDownloadRequest({
                report: capturedBookmark.state,
                reportType: type,
                pageName: activePage.name,
                user: isAdmin ? userEmail : profile.User_EmailAddress
            });
            if (reportID && exportID) {
                const interval = setInterval(async () => {
                    try {
                        const responseStatus = await getDownloadReportStatus({ reportID, exportID });
                        const status = responseStatus.status;
                        if (status) {
                            if (status === 'Failed') {
                                const errorConverting = JSON.stringify(responseStatus.data.error);
                                console.log(`PBI REPORTS CONTROLLER - Error converting the file ${errorConverting}`);
                                clearInterval(interval);
                                console.log(responseStatus.data.error);
                                setDownloadButtonActive(true);
                                dismissNotification();
                                addErrorNotification(t('common__task__status--download-failed'), { autoClose: 3000 });
                            } else if (status === 'Succeeded') {
                                clearInterval(interval);
                                const file = await getDownloadableFile({ reportID, exportID });
                                const output = Buffer.from(file.report.data);

                                const formattedCurrentTime = new DateLib().format(DateLib.FORMAT_TYPES.DATE_UNDERSCORED);
                                let fileName;
                                if (type === 'trends') {
                                    fileName = `${type}_report_${formattedCurrentTime}.pdf`;
                                } else if (type === 'trendsHistory') {
                                    fileName = `trends_dashboard_${formattedCurrentTime}.pdf`;
                                } else {
                                    fileName = `${type}_store_report_${formattedCurrentTime}.pdf`;
                                }

                                fileDownload(output, fileName);
                                setDownloadButtonActive(true);
                                dismissNotification();
                                addSuccessNotification(t('common__task__status--download-completed'), { autoClose: 3000 });
                            }
                        }
                    } catch (err) {
                        clearInterval(interval);
                        console.log(err);
                        setDownloadButtonActive(true);
                        dismissNotification();
                        addErrorNotification(t('common__task__status--download-failed'), { autoClose: 3000 });
                    }
                }, 8000);
            } else {
                const failed = t('common__task__status--download-failed');
                throw failed;
            }
        } catch (err) {
            setDownloadButtonActive(true);
            dismissNotification();
            addErrorNotification(t('common__task__status--download-failed'), { autoClose: 3000 });
        }
    };

    const cancelMessageWarning = (
        <div className="text-dark text-lg modal-message">
            <p>
                {t('pbiReports__modal-message')}
                <br />
                {t('pbiReports__modal-instructions-message')}
            </p>
        </div>
    );

    return (
        <section>
            <NotificationsList />
            {showCommonLoader && (<CommonLoader showLoader={showCommonLoader} message={'common__loading'} />)}
            {!showCommonLoader && (
                <>
                    {accountError &&
                        <div className="noStores">
                            <AccountErrorMessage {...{
                                noStores,
                                noPermissions,
                                t
                            }} />
                        </div>
                    }
                    {!accountError && (<>
                        { !showOnlyReport && (<div className="top-container">
                            <ReportsHeader isAdmin={isAdmin} />
                            {showTemplateButtons &&
                            <div className="top-container__buttons">
                                { isDownloadAllowed &&
                                <Button
                                    className={`mx-3  ${downloadButtonActive ? 'custom-color-btn' : 'custom-color-btn-disabled'}`}
                                    variant="primary"
                                    onClick={onDownLoad}
                                >
                                    <i className="fas fa-download"> </i> {t('common__download')}
                                </Button>}

                                { !isAdmin &&
                                <PbiTemplate
                                    reportType={type}
                                    templateData={templateData}
                                />}
                            </div>
                            }
                        </div>) }
                        <div>
                            <ConfirmPopupWithoutCancel
                                show={showCancelModal}
                                message={cancelMessageWarning}
                                onConfirm={() => handleconfirm()}
                                confirmationVerb={t('common__continue')}
                            />
                        </div>
                        <div
                            className={
                                `${!showOnlyReport && 'reportsPage'}
                                my-auto
                                report-${showCommonLoader === false ? 'show' : 'hide'}`
                            }
                        >
                            {
                                reportsLoaded &&
                                <PowerBIEmbed
                                    embedConfig={{
                                        type: report,
                                        id: reportId,
                                        embedUrl: embedUrl,
                                        tokenType: models.TokenType.Embed,
                                        permissions: models.Permissions.All,
                                        accessToken: embedToken,
                                        filters: [
                                            startOfTheWeekFilter(startOfTheWeek, type)
                                        ],
                                        settings: {
                                            panes: {
                                                filters: {
                                                    expanded: false,
                                                    visible: false
                                                },
                                                pageNavigation: {
                                                    visible: false
                                                }
                                            }
                                        }
                                    }}
                                    eventHandlers={
                                        new Map([
                                            [loaded, (event) => onReportLoaded(event)],
                                            [rendered, (event) => onReportRendered(event)],
                                            [error, (event) => handleError(event)]
                                        ])
                                    }
                                    cssClassName={`${iframeClassName} Embed-container`}
                                    getEmbeddedComponent={(embeddedReport) => {
                                        window.report = embeddedReport;
                                    }}
                                />
                            }
                        </div>
                    </>)}
                </>
            )}
        </section>
    );
};

export default compose(
        withHMELayout({
            contentClasses: ['pbi-report-screen']
        }),
        withAccountEmail
)(PbiReportScreen, { ownerClassName: 'pbi-report-screen__owner-email-wrapper' });
