import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthenticationService from '../Security/AuthenticationService';
import { Config } from '../../Config';
import { NavDropdown, Dropdown } from 'react-bootstrap';
import { CommonConstants } from '../../Constants';

import './more.css';

const { subscription } = CommonConstants;
const leaderBoardSubscription = [subscription.premiumStoreDriveThru, subscription.nitroLeaderboard];

const menuItems = {
  'common__leaderboards': '/leaderBoards/LBGroups',
  'sub-header--lb__templates': '/leaderBoards/LBTemplatesList',
  'common__contests': '/leaderBoards/contests',
  'common__awards': '/leaderBoards/awards',
  'common__smack-talk': '/leaderBoards/smackTalk',
  'sub-header--lb__avatars': '/leaderBoards/avatarBox',
  'common__announcement-settings': '/leaderBoards/announcementSettings',
  /**
   * TEMPORARY!
   * user story https://hmetest.tpondemand.com/entity/33780-gamification-hide-feedback-from-cloud-header
   */
  // LeaderboardFeedback: '/leaderBoards/feedback',
};
const userContext = new AuthenticationService(Config.authBaseUrl).getProfile();
const { IsNitro: isNitroEnabled, SL: subscriptionType } = userContext;
const isNitroOrGamifiedNitroLB = isNitroEnabled && (subscriptionType === 2 || subscriptionType === 6)

const LeaderBoardsHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={`subMenu menuBar hme-l-size ${(leaderBoardSubscription.includes(subscriptionType) || isNitroOrGamifiedNitroLB)? "" : "hidden"} `}>
      <ul id="leaderboard" className="subHeaders" >
        {
        Object.keys(menuItems).map(label => (
          <li key={label}>
            <NavLink
                    to={menuItems[label]}
                    className={({ isActive }) => (isActive ? 'headerMenu active_tab_sub' : 'headerMenu')}
              >
              {t(label)}
            </NavLink>
          </li>
        ))
        }
        {renderMoreMenu(t)}
      </ul>
    </div>
  );
};

const renderMoreMenu = (t) => {
  const title = (
    <span className="headerMenu">
      {t('main-header__item--more')}
      <span className="fa fa-chevron-down"></span>
    </span>
  );

  return (
    <NavDropdown className="more-l headerMenu" title={title} id="leaderboardMore">
      {
      Object.keys(menuItems).map(label => (
        <Dropdown.Item
          key={label}
          href={menuItems[label]}
          className="headerMenu shows"
        >
          {t(label)}
        </Dropdown.Item>
      ))
    }
    </NavDropdown>
  );
};

export { LeaderBoardsHeader };
