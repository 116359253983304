import React from 'react';
import { Modal } from 'library/Modal';

import './FeedFormModal.scss';

export const FeedFormModal = ({ title = '', onHide, children, ...modalProps }) => {
    return (
        <Modal {...modalProps} title={title} onHide={onHide} dialogClassName="hme-dxs-feed-modal-dialog">
            <div className="hme-dxs-feed-modal">{children}</div>
        </Modal>
    );
};
