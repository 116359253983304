export const getStoreDisplayName = (store) => {
    let name = store.StoreNumber;
    name = `${name}-${store.StoreName || store.StoreNumber}`;

    return name;
};

export const getEmailIdCount = (inputEmail) => {
    if (inputEmail) {
        const regexMatch = inputEmail.match(/@/g);
        return regexMatch ? regexMatch.length : 0;
    } else {
        return 0;
    }
};

export const translateFormat = (message, params) => {
    for (const key in params) {
        message = message.replace(`{${key}}`, params[key]);
    }

    return message;
};

export const fixTimeFormat =
    (template) => ({ ...template, TimeFormat: template && String(template['TimeFormat']).toLowerCase() });

export const addPoundSign =
    (template) => ({ ...template,
        GoalAColor: template['GoalAColor'] && template['GoalAColor'].charAt(0) != '#' ? `#${template['GoalAColor']}` : template['GoalAColor'],
        GoalBColor: template['GoalBColor'] && template['GoalBColor'].charAt(0) != '#' ? `#${template['GoalBColor']}` : template['GoalBColor'],
        GoalCColor: template['GoalCColor'] && template['GoalCColor'].charAt(0) != '#' ? `#${template['GoalCColor']}` : template['GoalCColor']
    });

export const removePoundSign =
    (template) => ({ ...template,
        GoalAColor: template['GoalAColor'] && template['GoalAColor'].charAt(0) === '#' ? template['GoalAColor'].substring(1) : template['GoalAColor'],
        GoalBColor: template['GoalBColor'] && template['GoalBColor'].charAt(0) === '#' ? template['GoalBColor'].substring(1) : template['GoalBColor'],
        GoalCColor: template['GoalCColor'] && template['GoalCColor'].charAt(0) === '#' ? template['GoalCColor'].substring(1) : template['GoalCColor']
    });
