/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { cond, T } from 'ramda';

import { Button } from 'library/Button';
import { Label } from 'library/Label';

import './Toggle.scss';
import '../Button/Button.scss';

const defaultVariants = [];

export const TOGGLE_VARIANTS = {
    ROUNDED: 'rounded',
    INLINE_LABEL: 'inline-label',
    WHITE: 'white'
};

// TODO: Handle empty children
const ToggleInput = ({ value, label, variants = defaultVariants, className, onChange, children, isDisabled, isPreview }) => {
    const { t } = useTranslation();
    return (
        <div className={classNames('hme-toggle', className, variants.map((variant) => `hme-toggle--${variant}`))}>
            <Label>{label}</Label>
            <div className="toggle-button-group btn-group">
                {
                    children.map(({ text, value: optionValue, disabled = false, key, className }) => (
                        <Button
                            key={key || text}
                            variants={value === optionValue ? ['submit'] : []}
                            disabled={disabled || isDisabled}
                            preview={isPreview}
                            onClick={(value !== optionValue && onChange) ? (() => onChange(optionValue)) : undefined}
                            className={className}
                        >{typeof(text) === 'string' ? t(text) : text}</Button>
                    ))
                }
            </div>
        </div>
    );
};

const ToggleInputReadOnly = ( { value, children } ) => {
    const { t } = useTranslation();

    const selectedItem = useMemo(() => children.find((item) => item.value === value) || null,[children]);
    const text = useMemo( () => selectedItem && selectedItem.value !== '' ? selectedItem.text : '-',[selectedItem]);

    return (
        <div>
            <span>{t(text)}</span>
        </div>
    );
};

export const Toggle = cond([
    [({ isReadOnly }) => isReadOnly, ToggleInputReadOnly],
    [T, ToggleInput]
]);
