import React, { Component } from 'react'
import '../Stores.css'
import './NexeoComponent.css'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { compose } from 'ramda';
import AuthenticationService from '../../Security/AuthenticationService'
import Unregistersystem from '../../Actions/Unregistersystem'
import { Config } from '../../../Config'
import NavigationService from '../../Common/NavigationServices'
import CommonFunctions from '../../Common/Functions'
import CommonStoreMethods from '../CommonStoreMethod'
import { CommonConstants } from '../../../Constants'
import { checkAccess } from '../../../actions/permissions'
import DeviceStatusImage from '../DeviceStatusImage'
import { createSystemPageURI } from '../../../helpers/createSystemPageURI';
const NEXEO_DEVICETYPE_ID = 5

const {
  deviceType: {
    nexeo
  } = {},
} = CommonConstants;

class NexeoComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      disableRemove: true,
      deviceIDs: [],
      deviceName: 'Nexeo'
    }
    this.navigate = new NavigationService()
    this.commonFunctions = new CommonFunctions()
    this.CommonStoreMethods = new CommonStoreMethods()
    this.handleViewDevice = this.handleViewDevice.bind(this)
    this.checkRemotePermission = this.checkRemotePermission.bind(this)
    this.renderRows = this.renderRows.bind(this)
    this.authService = new AuthenticationService(Config.authBaseUrl)
    this.state.url = this.authService.getColdFusionAppUrl(this.authService.isAdmin())
    this.state.token = this.authService.getToken()
  }
  handleViewDevice (id) {
    this.props.viewDevice(id)
  }

  checkRemotePermission (data = {}) {
    const { t } = this.props;

    const {
      Device_IsActive: deviceIsActive,
      Device_IsPreconfigured: deviceIsPreconfigured,
    } = data
    return deviceIsPreconfigured
      ? (<td>
          <span className='device-status deviceStatusText'>{t('common__device__status--pre-configured')}</span>
        </td>)
      : (<td className='device-status deviceStatusText'>
          <DeviceStatusImage isActive={Boolean(deviceIsActive)} />
          <span>
            {this.renderStatus(data)}
          </span>
        </td>)
  }
  // Checks for the enabling the remove system button.
  enableRemoveBtn (e, deviceId, active) {
    const disableRemove = this.commonFunctions.enableRemoveBtn(e, deviceId, active)
    const deviceIDs = this.state.deviceIDs
    const input = {
      isChecked: e.currentTarget.checked,
      device_ID: deviceId,
      isActive: active
    }
    deviceIDs.push(input)
    this.setState({
      disableRemove,
      deviceIDs,
    })
  }

  renderCheckbox({isAdmin, deviceID, deviceIsActive}) {
    const checkboxAttrs = {
      type: 'checkbox',
      name: 'checkbox',
      id: 'idname',
      className: 'device-check-input',
      value: deviceID,
      onChange: e => this.enableRemoveBtn(e, deviceID, deviceIsActive)
    }
    return isAdmin ? (<td className='device-checkbox'><input {...checkboxAttrs}/></td>) : '';
  }

  // Checking the device status and remote connect permission.
  renderStatus (device) {
    const isRemoteConnectionPermission = checkAccess(CommonConstants.userPermissions.RemoteConnect);
    return this.CommonStoreMethods.renderStatus(device, isRemoteConnectionPermission);
  }
  renderRows (isPreconfigured, deviceDetails) {
    const { t } = this.props;
    const isAdmin = this.authService.isAdmin()

    const rows = deviceDetails.map((data) => {
      const {
        Device_UID: deviceUID,
        Device_ID: deviceID,
        Device_IsActive: deviceIsActive,
        Device_MainVersion: deviceMainVersion,
        Device_SerialNumber: deviceSerialNumber,
        Device_SettingVersion: deviceSettingVersion,
        Device_DeviceType_ID: deviceTypeID,
      } = data;

      return (
        <tr key={deviceUID} className='device-row-data'>
          {this.renderCheckbox({isAdmin, deviceID, deviceIsActive})}
          <td className='device-main-version'>{isPreconfigured ? '' : deviceMainVersion}</td>
          <td className='device-serial-number'>{isPreconfigured ? '' : deviceSerialNumber}</td>
          {this.checkRemotePermission(data)}
          <td className='device-settings-version'>{deviceSettingVersion}</td>
          <td className='device-view'>
            <a href={createSystemPageURI({ deviceUID, deviceTypeID, deviceMainVersion })} className='device-view-link'>{t('stores__modal__view-details')}</a>
          </td>
        </tr>
      )
    })
    return rows
  }
  // Main render function. and get the Nexeo devices value
  render () {
    const {
      deviceIDs,
      disableRemove,
    } = this.state

    const { stores:
      {
        storeDetails: {
          User_EmailAddress: userEmailAddress,
          Store_UID: storeUID,
        } = {},
        Device_Details: deviceDetails,
      } = {},
      t,
    } = this.props;

    const nexeoDevices = deviceDetails.filter(function (d = {}) {
      const { Device_DeviceType_ID: deviceType } = d
      return deviceType === NEXEO_DEVICETYPE_ID
    })

    const devicesOnline = nexeoDevices.filter(d => d.Device_IsActive);
    const devicesPreconfigured = nexeoDevices.filter(d => d.Device_IsPreconfigured);

    let isPreconfigured = false;
    if (devicesPreconfigured.length > 0 && devicesOnline.length === 0) {
      isPreconfigured = true;
    }

    const devicesFiltered = nexeoDevices.filter(d => d.Device_IsPreconfigured == Number(isPreconfigured));

    const deviceListHeaders = this.CommonStoreMethods.renderDeviceListHeaders(true);
    const deviceListRows = this.renderRows(isPreconfigured, devicesFiltered);
    const isAdmin = this.authService.isAdmin();
    const deviceList = this.commonFunctions.removeDuplicateDevices(deviceIDs)
    const headerTKey = isPreconfigured ? 'common__device--pre-configured' : 'common__device--registered';
    const headerCaption = t(headerTKey, { deviceType: t('common__device__type--nexeo') });
    return (
      <div className='device-settings-nexeo'>
        <h4 className='header'>{headerCaption}</h4>
        <table className='device-list-table'>
          <tbody>
            {deviceListHeaders}
            {deviceListRows}
          </tbody>
        </table>
        <div className={'remove-sys ' + (isAdmin && checkAccess(CommonConstants.adminPermissions.RemoveDevice) ? 'show' : 'hide')}>
          <Unregistersystem
            userEmail={userEmailAddress}
            system={nexeoDevices}
            deviceList={deviceList}
            display={disableRemove}
            deviceName={this.props.device}
            deviceStoreUID={storeUID} />
        </div>
      </div>
    )
  }
}

// Setting the state from redux store.
function mapStateToProps (state) {
  return {
    stores: state.StorePopupDetails.storePopupDetails
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(NexeoComponent)
