import React from 'react';

import { Toggle as LibraryToggle } from 'library/Toggle';
import { FieldTitle } from '../FieldTitle';

export const Toggle = ({ value, onChange, children, tipText, label, containerRef, className = '' }) => {
    return (
        <LibraryToggle
            value={value}
            onChange={onChange}
            label={<FieldTitle title={label} tipText={tipText} container={containerRef} />}
            className={className}
        >
            {children}
        </LibraryToggle>
    );
};
