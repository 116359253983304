import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { Section } from 'library/Section';
import { TileList } from 'library/TileList';
import { Grid } from 'components/Common/Grid';

import './DevicesGrid.scss';

export const DevicesGrid = ({
    Title,
    headers,
    mobileHeaders = {},
    rows,
    rowKey,
    noRecordsMessage = 'system-status__title__system-devices__no-devices--found',
    isLoading = false,
    expandable = true,
    gridProps,
    tileProps = {},
    className
}) => {
    const { t } = useTranslation();

    return (
        <Section
            className={classNames('hme-system-status-devices-grid', className)}
        >
            {Title}
            <Grid
                rows={rows}
                rowKey={rowKey}
                isLoading={isLoading}
                headers={headers}
                noRecordsMessage={t(noRecordsMessage)}
                expandable={expandable}
                {...gridProps}
            />

            {!isEmpty(tileProps) && !isEmpty(mobileHeaders) && (
                <TileList
                    headers={mobileHeaders}
                    rows={rows}
                    isLoading={isLoading}
                    noRecordsMessage={noRecordsMessage}
                    className="hme-system-status-devices-grid__tile-list-wrapper"
                    {...tileProps}
                />
            )}
        </Section>
    );
};

DevicesGrid.propTypes = {
    Title: PropTypes.element.isRequired,
    headers: PropTypes.array.isRequired,
    mobileHeaders: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowKey: PropTypes.string.isRequired,
    noRecordsMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    expandable: PropTypes.bool,
    gridProps: PropTypes.object,
    tileProps: PropTypes.object,
    className: PropTypes.string
};
