import { useCallback } from 'react';

import { SaveFooter } from 'library/SaveFooter';
import { Loader } from 'library/Loader';
import { NotificationsList } from 'library/NotificationsList';
import { Header } from './Header';
import { Brand } from './Brand';

import './BrandSharing.scss';

export const BrandSharing = ({
    isLoading,
    isSubmitEnabled,
    brands,
    title,
    onSave,
    onCancel,
    onChange
}) => {
    const onBrandChange = useCallback((brandId, checked) => {
        const newBrands = brands.map((brand) => {
            if (brand.brandId !== brandId) {
                return brand;
            }

            return {
                ...brand,
                brandShared: +checked,
            };
        });

        onChange && onChange(newBrands);
    }, [brands, onChange]);

    if (isLoading) {
        return (
            <div className='hme-brand-sharing'>
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className='hme-brand-sharing'>
            <NotificationsList />
            <div className='hme-brand-sharing__content'>
                <Header title={title} />
                {
                    brands.map((brand) => (
                        <Brand {...brand} onChange={onBrandChange} key={brand.brandId} />
                    ))
                }
            </div>
            <SaveFooter
                isSubmitEnabled={isSubmitEnabled}
                onSave={onSave}
                onCancel={onCancel}
            />
        </div>
    );
};
