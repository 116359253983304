import React from 'react';
import { cond, T } from 'ramda';

import { GridCell } from '../../GridCell';
import { Checkbox } from 'library/Checkbox';

import './CheckboxCell.scss';

export const CheckboxCell = cond([
    [({ selectable }) => selectable, ({ checked, disabled, onChange, selectable, Icon = Checkbox, ...rest }) => (
        <GridCell className="hme-grid-cell-checkbox">
            <Icon checked={checked} onChange={onChange} disabled={disabled} {...rest}/>
        </GridCell>
    )],
    [T, () => null]
]);
