export const getAllInnerItems = (items) => {
    return items.reduce((acc, item) => {
        acc.push(item);

        if (!item.Children || item.Children === 0) {
            return acc;
        }

        const innerItems = getAllInnerItems(item.Children);

        return [...acc, ...innerItems];
    }, []);
};
