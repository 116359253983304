import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { trimValues } from 'helpers';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { removeRole, getRole, updateRole } from 'services/Roles';
import { getPermissionsList } from 'services/Permissions';
import { types as companyTypes } from 'constants/company';
import { getUserAccountDetails } from 'services/Account';

import { getPermissionItems } from '../../../Common/helpers/getPermissionItems';
import { isRoleValid, areRolesEqual, trimmedKeys } from '../../../Common/validators/role';
import { accountOwner } from '../../../../Status/Common/constants/accountOwner';

const SETTINGS_ROLES = '/settings/roles';


const load = async (accountUuid = null, roleUid, { setOriginRole, setPermissions, setIsLoading, isOwner, companyType } = {}) => {
    setIsLoading(true);

    try {
        const rolePromise = isOwner ?
            Promise.resolve({
                roles: {
                    ...accountOwner,
                    Role_Description: ''
                }
            }) : getRole(roleUid);
        const permissionsPromise = getPermissionsList({ accountUuid, companyType });
        const [role, permissions] = await Promise.all([rolePromise, permissionsPromise]);

        setOriginRole({
            uid: isOwner ? null : roleUid,
            name: role.roles.Role_Name,
            description: role.roles.Role_Description,
            permissions: isOwner ?
                permissions.map(({ Permission_UID }) => Permission_UID) :
                role.Permission.map(({ Permission_UID }) => Permission_UID)
        });

        setPermissions(getPermissionItems(permissions));
    } catch (err) {
        addErrorNotification(err.message);
    } finally {
        setIsLoading(false);
    }
};

const save = async (role, userUid, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await updateRole({ role: trimValues(role, trimmedKeys), userUid });

        navigate(SETTINGS_ROLES);
        setTimeout(() => {
            addSuccessNotification('add-role__success--updated');
        }, 1000);
    } catch (err) {
        addErrorNotification(err.message);
    } finally {
        setIsLoading(false);
    }
};

const remove = async (role, navigate, t, setIsLoading) => {
    setIsLoading(true);

    try {
        await removeRole({ role });

        navigate(SETTINGS_ROLES);
        setTimeout(() => {
            addSuccessNotification('add-role__success--deleted');
        }, 1000);
    } catch (err) {
        addErrorNotification(t(err.message, err.tOptions));
        setIsLoading(false);
    }
};

export const useViewEdit = () => {
    const { t } = useTranslation();
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [originRole, setOriginRole] = useState(null);
    const [accountInformation, setAccountInformation] = useState(null);
    const [role, setRole] = useState({
        name: '',
        description: '',
        permissions: []
    });

    const roleUid = useMemo(() => queryParams.get('roleuid'), [queryParams]);
    const isOwner = useMemo(() => queryParams.get('isAccountOwner'), [queryParams]);
    const userUid = useMemo(() => queryParams.get('uuid'), [queryParams]);

    const title = useMemo(() => {
        if (!originRole) {
            return '';
        }

        const tKey = isOwner ? 'add-role__header--edit__title--owner' : 'add-role__header--edit__title';

        return t(tKey, {
            roleName: originRole.name
        });
    }, [originRole, t, isOwner]);

    const getAccountInformation = useCallback(async (auuid) => {
        const fetchedAccountInfo = await getUserAccountDetails(auuid, false);

        setAccountInformation(fetchedAccountInfo);

        return fetchedAccountInfo;
    }, []);

    const isSaveButtonDisabled = useMemo(() => !isRoleValid(role) || areRolesEqual(
            trimValues(role, trimmedKeys),
            trimValues(originRole, trimmedKeys)
    ), [role, originRole]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onSave = useCallback(() => {
        save(role, userUid, navigate, setIsLoading);
    }, [role, userUid]);

    const onRemove = useCallback(() => {
        remove({
            ...role,
            Role_Name: originRole.name
        }, navigate, t, setIsLoading);
    }, [role, originRole, t, navigate]);

    useEffect(() => {
        (async () => {
            const accountData = await getAccountInformation(userUid);
            load(userUid, roleUid, { companyType: accountData.Company_Type, isOwner, setOriginRole, setPermissions, setIsLoading });
        })();
    }, [userUid, roleUid, isOwner]);

    useEffect(() => {
        if (!originRole) {
            return;
        }

        setRole({
            ...originRole
        });
    }, [originRole]);

    useEffect(() => {
        if (!isOwner) {
            return;
        }

        setRole({
            ...originRole,
            description: t('roles__grid__account-owner-desription')
        });
    }, [t, isOwner, originRole]);

    const isAccountDistributor = accountInformation?.Company_Type === companyTypes.DISTRIBUTOR ?? false;

    return {
        title,
        role,
        isLoading,
        isSaveButtonDisabled,
        permissions,
        isOwner,
        hasRemove: true,
        onCancel,
        onRemove,
        onSave,
        onRoleChange: setRole,
        isAccountDistributor
    };
};
