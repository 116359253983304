import { useTranslation } from 'react-i18next';

import { Config } from 'Config';
import { Title } from 'library/Title';
import { Separator, SEPARATOR_VARIANTS } from 'library/Separator';
import { Button, BUTTON_VARIANTS } from 'library/Button';

import './Header.scss';

const separatorVariants = [SEPARATOR_VARIANTS.TITLE];
const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

export const Header = ({ title }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-brand-sharing__header'>
            <Title>{t(title)}</Title>
            <Separator variants={separatorVariants} className='hme-brand-sharing__header-separator' />
            <Button
                className='hme-brand-sharing__header__agreement'
                as='a'
                variants={buttonVariants}
                href={t('footer__user-agreement__url',  { url: Config.storageUrl })}
                target='_blank'
                rel="noopener"
            >
                {t('profile__brand-sharing__agreement-link__text')}
            </Button>
        </div>
    );
};
