import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';

import { DeviceStatus } from './DeviceStatus/DeviceStatus';
import { SendUpgradeConfirmPopup } from './SendUpgradeConfirmPopup';

import { useSystemStatus } from './hooks/useSystemStatus';

import './SystemStatus.scss';

const SystemStatus = () => {
    const {
        device,
        deviceSettings,
        storeCountry,
        isDataLoading,
        error,
        deviceRecentUpgradeTasks,
        deviceUpgradeVersions,
        deviceScheduledUpgrade,
        isSendUpgradeConfirmShown,
        onSendUpgrade,
        onDeviceReboot,
        onDeviceReconnect,
        onLinkedDeviceReboot,
        onLinkedDeviceReconnect,
        onSendUpgradeConfirm,
        onSendUpgradeConfirmHide,
        upgradeTaskData
    } = useSystemStatus();

    return (
        <>
            <NotificationsList />
            <DeviceStatus
                device={device}
                deviceRecentUpgradeTasks={deviceRecentUpgradeTasks}
                deviceSettings={deviceSettings}
                storeCountry={storeCountry}
                isLoading={isDataLoading}
                error={error}
                onDeviceVersionUpgrade={onSendUpgrade}
                onDeviceReboot={onDeviceReboot}
                onDeviceReconnect={onDeviceReconnect}
                onLinkedDeviceReboot={onLinkedDeviceReboot}
                onLinkedDeviceReconnect={onLinkedDeviceReconnect}
                deviceUpgradeVersions={deviceUpgradeVersions}
                deviceScheduledUpgrade={deviceScheduledUpgrade}
            />
            <SendUpgradeConfirmPopup
                show={isSendUpgradeConfirmShown}
                onHide={onSendUpgradeConfirmHide}
                onConfirm={onSendUpgradeConfirm}
                upgradeTaskData={upgradeTaskData}
            />
        </>
    );
};

export const AdminZOOMSystemStatus = compose(
    withHMELayout({
        contentClasses: ['admin-zoom-system-status-page'],
    }),
)(SystemStatus);
