import React, { useState, useEffect } from 'react';
import { Dropdown } from 'library/Dropdown';

export const DeviceDropdown = ({ options, ...props }) => {
    const [dropdownOptions, setDropdownOptions] = useState([]);

    useEffect(() => {
        const formattedOptions = options.map(({ deviceUID, name, deviceType, version }) => ({
            text: name,
            value: { deviceUID, name, deviceType, version },
        }));

        setDropdownOptions(formattedOptions);
    }, [options, setDropdownOptions]);

    return <Dropdown {...props}>{dropdownOptions}</Dropdown>;
};
