export const vaioProviderFields = {
    name: 'name',
    keyExpiration: 'keyExpiration',
    tokenExpiration: 'tokenExpiration',
    locationType: 'locationType',
    audioMode: 'audioMode',
    outboundAudio: 'outboundAudio',
    isTest: 'isTest',
    url: 'url',
    httpsPort: 'httpsPort',
    wssPort: 'wssPort',
    reconnectionDelay: 'reconnectionDelay',
    botAudioSourceType: 'botAudioSourceType',
    botChannels: 'botChannels',
    botName: 'botName'
};

// todo: the keys with the _ERROR suffix are temporary in the current list,
// they should be removed after all text error messages on the backend changed to error keys
export const errorKeys = {
    PROVIDER_NAME_REQUIRED: 'vaio_provider_provider-name-required',
    PROVIDER_NAME_ALREADY_EXISTS: 'vaio_provider_provider-name-exists',
    PROVIDER_KEY_NOT_UPDATED: 'vaio_provider_provider-key-not-updated',
    PROVIDER_KEY_ACCESS_FAILED: 'vaio_provider_provider-key-access-failed',
    PROVIDER_NOT_FOUND: 'vaio_provider_provider-not-found',
    GET_PROVIDER_BY_NAME_ERROR: 'common__error--internal-server',
    SAVE_PROVIDER_CHANGE_HISTORY_ERROR: 'common__error--internal-server',
    UPDATE_PROVIDER_CHANGE_HISTORY_KEY_STATUS_ERROR: 'common__error--internal-server',
    GET_PROVIDER_CHANGE_HISTORY_ERROR: 'common__error--internal-server',
    UPDATE_PROVIDER_ERROR: 'common__error--internal-server',
    GET_PROVIDER_BY_UID_ERROR: 'common__error--internal-server',
    PROVIDER_ASSOCIATED_WITH_TOKENS: 'vaio_provider_partner-already-associated-with-token',
};
