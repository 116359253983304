import React from 'react';
import { useTranslation } from 'react-i18next';

export const ServiceError = ({ errorMessage }) => {
    const { t } = useTranslation();

    return (
        <div className='col1'>
            <div className='forms clear'>
                <div className='error redbox_err_all redbox_marg'>
                    <ul>
                        <li>{t(errorMessage)}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
