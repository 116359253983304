import React from 'react';
import PropTypes from 'prop-types';

export const DeviceVersions = ({ devices }) => (
    <div className="hme-stores-status__list__device-version">
        {
            devices.map((device) => (
                <div className="hme-stores-status__list__inner-cell" key={device.Device_ID}>
                    {device.Device_MainVersion}
                </div>
            ))
        }
    </div>
);

DeviceVersions.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object).isRequired
};
