import { path } from 'ramda';

export const hasAllRequired = (obj, keys) => {
    return !keys.length || keys.every((key) => {
        const keyPath = key.split('.');

        const value = path(keyPath, obj);

        if (Array.isArray(value)) {
            return !!value.length;
        }

        return !!value;
    });
};
