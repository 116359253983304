export const getCompanyRegion = ({ account, regions }) => {
  const foundCompanyRegion = (regions || []).find((region) => region.Abbreviation === account.Company_Region);

  const companyRegionId = foundCompanyRegion ? foundCompanyRegion.Id : '';
  const companyRegion = foundCompanyRegion ? foundCompanyRegion.Abbreviation : account.Company_Region;

  return {
      companyRegionId,
      companyRegion
  }
}
