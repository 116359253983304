import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equals } from 'ramda';
import PropTypes from 'prop-types';

import { Grid } from 'components/Common/Grid';
import { Label } from 'library/Label';
import { useIsFirstRender } from 'hooks/useIsFirstRender';
import { Radio } from 'library/Radio';

import { getProvidersLinkedToPartner } from '../../../../Controller';

import './ProviderForm.scss';

const gridHeaders = [
    {
        text: 'common__name',
        property: 'name',
        className: 'hme-provider-name-cell',
        headerClassName: 'hme-provider-name-cell'
    },
    {
        text: 'common__type',
        property: 'locationType',
        className: 'hme-store-input-view-device-serial-number-cell',
        headerClassName: 'hme-store-input-view-device-serial-number-cell'
    }
];

export const ProviderForm = ({
    formValues,
    onProviderChange
}) => {
    const { tokenManager, vaioProvider = null } = formValues;

    const { t } = useTranslation();

    const [selectedProvider, setSelectedProvider] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [providers, setProviders] = useState([]);

    const isFirstRender = useIsFirstRender();

    const initialVaioProvider = useRef(vaioProvider);

    const handleSelectionChange = useCallback((selectedProviders = []) => {
        const newSelectedProvider = selectedProviders.at(-1) || null;
        onProviderChange(newSelectedProvider);
        setSelectedProvider([newSelectedProvider].filter(Boolean));
    }, [onProviderChange]);

    useEffect(() => {
        if (!tokenManager.uid) {
            return;
        }

        setIsLoading(true);
        getProvidersLinkedToPartner({ partnerUID: tokenManager.uid })
            .then((fetchedProviders = []) => {
                if (initialVaioProvider.current) {
                    const previouslySelectedProvider = fetchedProviders.find((provider) => provider.id === initialVaioProvider.current.id);
                    setSelectedProvider(previouslySelectedProvider ? [previouslySelectedProvider] : []);
                    initialVaioProvider.current = null;
                }
                setProviders(fetchedProviders.map((provider) => {
                    return {
                        checkboxProps: { Icon: (props) => <Radio checked = {provider.id === vaioProvider.id} name="provider" {...props} /> },
                        ...provider
                    }
                }));
            }).catch(() => {
                // do nothing when error appears
            }).finally(() => {
                setIsLoading(false);
            });
    }, [tokenManager.uid]);

    useEffect(() => {
        if (selectedProvider.length === 0 && !isFirstRender) {
            onProviderChange(null);
        }
    }, [selectedProvider]);

    useEffect(() => {
        if (vaioProvider?.id && !equals(vaioProvider, initialVaioProvider.current)) {
            const previouslySelectedProvider = providers.find((provider) => provider.id === vaioProvider.id);
            setSelectedProvider(previouslySelectedProvider ? [previouslySelectedProvider] : []);
        }
    }, [vaioProvider, providers]);

    return (
        <div className="hme-api-management-provider-form">
            <Grid
                rowKey="id"
                rows={providers}
                headers={gridHeaders}
                isLoading={isLoading}
                selectable
                onSelectionChange={handleSelectionChange}
                selection={selectedProvider}
                noRecordsMessage={t('common__error--no-provider-found')}
            />
        </div>
    );
};

export const ProviderField = ({ formValues, onProviderChange }) => {
    const { t } = useTranslation();

    return <div>
        <label className="hme-form-value-required">*</label>
        <Label className="vaio-provider-form-label">{t('api-token-management__form--select-provider')}</Label>

        <ProviderForm formValues={formValues} onProviderChange={onProviderChange} />
    </div>;
};

ProviderForm.propTypes = {
    formValues: PropTypes.object,
    onProviderChange: PropTypes.func
};

ProviderField.propTypes = ProviderForm.propTypes;
