import { sendActivationEmail as sendEmail } from "services/Account";
import { addSuccessNotification, addErrorNotification } from "services/Notifications";

export const sendActivationEmail = async ({ account, setIsSendingEmail, navigate }) => {
  try {
    setIsSendingEmail(true);

    if (!account) {
      throw new Error('admin__user__no__uuid-error');
    }

    const successMessage = await sendEmail(account);
    addSuccessNotification(successMessage);
  } catch (err) {
    addErrorNotification(err.message); 
  }

  setIsSendingEmail(false);
};
