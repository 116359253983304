import React from 'react';
import classNames from 'classnames';

import { Section as LibrarySection } from 'library/Section';

import './Section.scss';

export const Section = ({ className = '', children, ...props }) => {
    return (
        <LibrarySection {...props} className={classNames('hme-account-info-section', className)}>
            <div className="hme-account-info-section__content">
                {
                    React.Children.map(children, (child) => {
                        const { className = '' } = child.props;

                        return React.cloneElement(child, { className: classNames(className, 'hme-account-info-section__content-item') });
                    })
                }
            </div>
        </LibrarySection>
    );
};
