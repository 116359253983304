import api from './api';

const basePath = 'emails-unblock';
const paths = {
    unblock: basePath
};

export const searchBlockedEmail = (data) => {
    const { email } = data || {};
    return api.request({
        url: `${paths.unblock}${email ? `?email=${email}` : ''}`,
        method: 'GET'
    });
};
export const unblockEmail = (data) => {
    return api.request({
        url: paths.unblock,
        method: 'POST',
        data
    });
};
