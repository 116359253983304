/* eslint-disable complexity */
import { DateLib } from '@hme-cloud/utility-common';

import { vaioSettings } from '../constants';

const { botAudioSourceTypes } = vaioSettings;

const botAudioSourceLabels = {
    [botAudioSourceTypes.unprocessed]: 'vaio-provider_audio-source--unprocessed',
    [botAudioSourceTypes.semiProcessed]: 'vaio-provider_audio-source--semi-processed',
    [botAudioSourceTypes.processed]: 'vaio-provider_audio-source--processed'
};

const sectionTitles = {
    GENERAL: 'common__general',
    DETAILS: 'common__details'
};

const generalSectionFields = [
    'name',
    'keyExpiration',
    'tokenExpiration',
    'locationType',
    'audioMode',
    'outboundAudio',
    'isTest'
];

const detailsSectionFields = [
    'url',
    'httpsPort',
    'wssPort',
    'reconnectionDelay',
    'botAudioSourceType',
    'botChannels',
    'botName'
];

const fieldsWithFullWidth = [
    'name',
    'keyExpiration',
    'tokenExpiration',
    'locationType',
    'audioMode',
    'outboundAudio',
    'url',
    'isTest'
];

const providerFields = generalSectionFields.concat(detailsSectionFields);

const fieldsLabelsMap = {
    name: 'new-vaio-provider__form--provider-name',
    keyExpiration: 'new-vaio-provider__form--key-expiration-date',
    tokenExpiration: 'new-vaio-provider__form--token-expiration',
    locationType: 'vaio-provider--location',
    audioMode: 'vaio-provider--inbound-audio-mode',
    outboundAudio: 'vaio-provider--outbound-audio-mode',
    url: 'new-vaio-provider__form--url',
    httpsPort: 'new-vaio-provider__form--https-port',
    wssPort: 'new-vaio-provider__form--wss-port',
    reconnectionDelay: 'new-vaio-provider__form--reconnection-time-interval',
    botAudioSourceType: 'new-vaio-provider__form--voice-agent-audio-source',
    botChannels: 'vaio-provider--bot-channels',
    botName: 'new-vaio-provider__form--bot-name',
    isTest: 'vaio-provider--test-provider'
};

const removeRows = (allRows, rowsToRemove) => {
    return allRows.filter(obj => !rowsToRemove.includes(obj.label));
}
// format configuration history from API response to the format that is used in the UI
export const mapToConfigurationHistory = (changeHistory, t, hiddenRows = []) => {
    const sections = {
        [sectionTitles.GENERAL]: [],
        [sectionTitles.DETAILS]: []
    };

    const prevConfiguration = JSON.parse(changeHistory.prevConfiguration);
    const newConfiguration = JSON.parse(changeHistory.newConfiguration);

    const fieldsBySections = providerFields.reduce((acc, field) => {
        const label = fieldsLabelsMap[field];
        const isFullWidth = fieldsWithFullWidth.includes(field);
        let isChanged = prevConfiguration[field] !== newConfiguration[field];
        let value = newConfiguration[field];

        // format view of the specific fields
        if (field === 'keyExpiration') {
            value = value ? new DateLib(value).format(DateLib.FORMAT_TYPES.DATE_ONLY) : null;
        }

        if (field === 'tokenExpiration') {
            value = `${value} ${value === 1 ? t('common__hour') : t('common__hours')}`;
        }

        if (field === 'reconnectionDelay') {
            value = `${value} ${
                value === 1 ? t('common__second').toLowerCase() : t('common__seconds').toLowerCase()
            }`;
        }

        if (field === 'httpsPort' || field === 'wssPort') {
            value = value || '-';
        }

        if (field === 'botAudioSourceType') {
            value = t(botAudioSourceLabels[value]);
        }

        if (field === 'isTest') {
            // todo: change isTest to boolean type in the DB - non-strict comparison is used for now
            isChanged = prevConfiguration[field] != newConfiguration[field];
            value = !value ? t('common__no') : t('common__yes');
        }

        if (generalSectionFields.includes(field)) {
            acc[sectionTitles.GENERAL].push({
                label,
                value,
                isFullWidth,
                isChanged
            });
        }

        if (detailsSectionFields.includes(field)) {
            acc[sectionTitles.DETAILS].push({
                label,
                value,
                isFullWidth,
                isChanged
            });
        }

        return acc;
    }, sections);

    fieldsBySections[sectionTitles.GENERAL].push({
        label: 'common__updated-by',
        value: changeHistory.createdBy,
        isFullWidth: true
    });

    fieldsBySections[sectionTitles.DETAILS].push({
        label: 'new-vaio-provider__form--key',
        value: '',
        isFullWidth: true,
        isChanged: changeHistory.isKeyChanged
    });

    fieldsBySections[sectionTitles.GENERAL] = removeRows(fieldsBySections[sectionTitles.GENERAL], hiddenRows)
    fieldsBySections[sectionTitles.DETAILS] = removeRows(fieldsBySections[sectionTitles.DETAILS], hiddenRows)

    return {
        data: Object.keys(fieldsBySections).map((title) => ({
            title,
            fields: fieldsBySections[title]
        })),
        changedAt: new DateLib(changeHistory.createdAt).format(DateLib.FORMAT_TYPES.DATE_ONLY)
    };
};
