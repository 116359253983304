import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import classNames from 'classnames';

import './SortableBoard.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';
import { translationKeysMap } from './translationsMap';
var _ = require('underscore');
export default class SortableBoard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: props.tableData,
      firstTable: props.firstTable,
      EmptyTableDropDown: props.EmptyTableDropDown,
      withActiveDropdown: false,
      toggleKey: null,
    }
    this.handleSortEnd = this.handleSortEnd.bind(this)
    this.handleSortEndTable = this.handleSortEndTable.bind(this)
    this.handleRemoveTableClick = this.handleRemoveTableClick.bind(this)
    this.handleAddItemClick = this.handleAddItemClick.bind(this)
    this.handleCogMenuClick = this.handleCogMenuClick.bind(this)
    this.columnAddMenuClick = this.columnAddMenuClick.bind(this)
  }


  handleSortEnd(sortorder) {
    let tables = this.state.tableData

    tables.map((item, i) => {
      if (item.header === sortorder.header) {
        item.columns = arrayMove(
          item.columns,
          sortorder.oldIndex,
          sortorder.newIndex
        );
      }
    });
    this.setState({ tableData: tables })
  }

  handleSortEndTable (sortorder) {
    let tableData = this.state.tableData
    tableData = arrayMove(tableData, sortorder.oldIndex, sortorder.newIndex)
    this.props.handleSortEndTable(sortorder)
    this.setState({ tableData: tableData })
  }

  handleRemoveTableClick(index, header) {
    const tableData = this.state.tableData
    tableData.splice(index, 1)
    let EmptyTableDropDown = this.state.EmptyTableDropDown
    EmptyTableDropDown.map((data) => {
      if (data.name === header) {
        data.visible = true
      }
    })

    this.setState({
      EmptyTableDropDown: EmptyTableDropDown,
      tableData: tableData,
      isThirdTabeFilled: !this.state.isThirdTabeFilled
    });
  }

  handleAddItemClick(e) {
    const item = e.target.attributes['alt'].nodeValue
    let EmptyTableDropDown = this.state.EmptyTableDropDown;
    let tableId = '';
    EmptyTableDropDown.map((data) => {
      if (data.name === item) {
        data.visible = false
        tableId = data.id
      }
    });
    let tableData = this.state.tableData;
    let newTable = {
      id: tableId,
      disabled: false,
      header: item,
      columns: [
        {
          isremove: false,
          default: false,
          sortasc: false,
          header: 'Cars',
          data: [66, 59, 85]
        },
        {
          isremove: false,
          default: false,
          sortasc: false,
          header: 'Avg',
          data: [165, 171, 166]
        },
        {
          isremove: false,
          default: false,
          sortasc: false,
          header: 'Goal%',
          data: [77, 71, 69]
        }
      ]
    }
    newTable.header = item
    // if (tableData.length === 0) {
    //   newTable.columns[1].default = true
    //   newTable.columns[1].sortasc = true
    // }
    tableData.push(newTable)
    this.setState({
      EmptyTableDropDown: EmptyTableDropDown,
      tableData: tableData,
      isThirdTabeFilled: !this.state.isThirdTabeFilled
    });
  }

  handleCogMenuClick({ header, buttontext, thead }) {
    let tables = this.state.tableData
    tables.map((item, i) => {
      if (item.header === thead) {
        item.columns.map((col, i) => {
          if (buttontext === 'removecolumm') {
            if (col.header === header) {
              col.isremove = true
            }
          } else {
            if (col.header === header) {
              col.default = true
              col.sortasc = !col.sortasc
            } else {
              col.default = false
            }
          }
        })
      } else {
        if (buttontext === 'setdefaultsort') {
          item.columns.map((col, i) => {
            col.default = false
          })
        }
      }
    });
    this.setState({ tableData: tables });

  }

  columnAddMenuClick(e, thead) {
    let tables = this.state.tableData
    tables.map((item, i) => {
      if (item.header === thead) {
        item.columns.map((col, i) => {
          if (col.header === e.target.attributes['alt'].nodeValue) {
            col.isremove = false
          }
        })
      }
    })
    this.setState({ tableData: tables });
  }

  onToggleDropdown = (withActiveDropdown, toggleKey) => {
    this.setState({ withActiveDropdown, toggleKey });
  }

  render() {
    const emptyslots = [];
    let firstTable = this.props.firstTable
    let hideGoal = this.props.hideGoal
    let tableData = this.state.tableData
    for (var i = 0; i < this.props.renderEmptyContext; i++) {
      emptyslots.push(
        <li
          id={'slot-' + (i + 1)}
          className={classNames(
            'ui-empty-slot',
            this.state.withActiveDropdown && this.state.toggleKey === i
                ? 'ui-empty-slot-active-dropdown'
                : '',
          )}
        >
          <UITableDropDownWithTranslation
            Items={this.props.EmptyTableDropDown}
            disabled={!(i === tableData.length)}
            onItemClick={this.handleAddItemClick}
            className='add-table dropdown'
            toggleClassName='btn btn-secondary btn-md dropdown-toggle'
            toggleButtonIcon='fa fa-plus'
            onToggleDropdown={this.onToggleDropdown}
            toggleKey={i}
          />
        </li>);
    }

    return (

      <div id='containment-wrapper' className='sortableboard customize-dashboard-container'>
        <div id='drags-container'>
          <ul className='table-slots'>
            <li className='ui-empty-slot' />
            {emptyslots}
          </ul>
          <ul className='sortable-tables ui-sortable'>
            <UITableDraggable
              header={firstTable.header}
              data={firstTable.columns}
              disabled={firstTable.disabled}
              hideGoal={hideGoal}
            />
            <SortableTableList
              items={tableData}
              onSortEnd={this.handleSortEndTable}
              // lockAxis={this.state.axis}
              axis='x'
              onRemove={this.handleRemoveTableClick}
              onColumnSortEnd={this.handleSortEnd}
              onCogMenuClick={this.handleCogMenuClick}
              useDragHandle
              columnAddMenuClick={this.columnAddMenuClick}
              hideGoal={hideGoal}
            />
          </ul>
        </div>
      </div>
    )
  }
}

class UITableDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.itemClick = this.itemClick.bind(this);
  }

  toggle() {
    if (this.props.onToggleDropdown) {
      this.props.onToggleDropdown(!this.state.dropdownOpen, this.props.toggleKey);
    }

    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  itemClick(e) {
    this.props.onItemClick(e);
    this.toggle();
  }

  render() {
    const { t } = this.props;
    const dropDownItems = items => {
      return items.map((item, i) => {
        //Don't render the addGoal link if hideGoal preference is true
        if (this.props.hideGoal === '1' && item.name === "Goal%"){
          return '';
        }

        return (
          <li className={(item.visible ? 'show' : 'hide')}>
            <a onClick={this.itemClick} alt={item.name}>
              {t(translationKeysMap[item.name] || item.name)}
            </a>
          </li>
        );
      });
    };
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className={this.props.className}>
        <DropdownToggle
          disabled={this.props.disabled ? 'disabled' : ''}
          className={this.props.toggleClassName} >
          <span className={this.props.toggleButtonIcon} />
        </DropdownToggle>
        <DropdownMenu>{dropDownItems(this.props.Items)}</DropdownMenu>
      </Dropdown>
    );
  }
}

const UITableDropDownWithTranslation = withTranslation()(UITableDropDown);

class UITableDraggable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headers: [],
      withActiveDropdown: false,
    }
    this.removeTable = this.removeTable.bind(this);
    this.columnAddMenuClick = this.columnAddMenuClick.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.onCogMenuClick = this.onCogMenuClick.bind(this)
  }

  removeTable() {
    this.props.onRemove()
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let header = this.props.header
    this.props.onColumnSortEnd({ oldIndex, newIndex, header })
  }

  onCogMenuClick = (cogmenu) => {
    if (cogmenu.buttontext === 'removecolumm') {
      this.state.headers.push({ name: cogmenu.header, visible: true })
      if (this.state.headers.length === 3) {
        this.removeTable()
      }
    }
    this.props.onCogMenuClick(cogmenu)
  }

  onToggleDropdown = (withActiveDropdown) => {
    this.setState({ withActiveDropdown });
  }

  columnAddMenuClick(e) {
    let headers = this.state.headers
    headers.map((item, i) => {
      if (item.name === e.target.attributes['alt'].nodeValue) {
        headers.splice(i, 1)
      }
    })
    this.props.columnAddMenuClick(e, this.props.header)
    this.setState({ headers: headers })
  }

  render() {
    let headers = []
    this.props.data.map(item => {
      if (item.isremove) {
        headers.push({ name: item.header, visible: true })
      }
    })

    const addCrossIcon = disabled => {
      if (disabled) {
        return ''
      } else {
        return (
          <div className='table-toolbar' >
            <button className='remove-widget' onClick={this.removeTable} />
          </div>
        )
      }
    }

    const disableColumns = (data) => {
      return data.map((item) => {
        if(item.header === 'Goal' && this.props.hideGoal === '1'){
          return '';
        }
        return <UIColumnDraggable columns={item} disabled={this.props.disabled} columncount={data.length} hideGoal={this.props.hideGoal} />
      })
    }

    const colSlotsCont = (data) => {
      return data.map((item, i) => {
        return <UIColumnSlotCont disabled={(i===headers.length)} headers={headers} columnAddMenuClick={this.columnAddMenuClick} hideGoal={this.props.hideGoal} />
      })
    }

    return (
      <li
        className={
          'dash-table ui-state-default ui-draggable' +
          (this.props.disabled ? 'disabled' : '') +
          (this.state.withActiveDropdown ? ' dash-table-active-dropdown' : '')
        }
        data-table-id='t0'
      >
        {addCrossIcon(this.props.disabled)}
        <table className='cont-table'>
          <tbody>
            <tr className='hd'>
              <th><DragHandle header={this.props.header} style={this.props.style} /></th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                <div className='col-cont'>
                  {this.props.disabled ? ''
                    :
                    <ul className='col-slots-cont'>
                      {colSlotsCont(this.props.data)}
                    </ul>
                  }
                  {this.props.disabled ?
                    <ul className='sortable-cols ui-sortable'>
                      {disableColumns(this.props.data)}
                    </ul>
                    :
                    <SortableList
                      items={this.props.data}
                      tableHeader={this.props.header}
                      onSortEnd={this.onSortEnd}
                      lockAxis='x'
                      axis='x'
                      onCogMenuClick={this.onCogMenuClick}
                      onToggleDropdown={this.onToggleDropdown}
                      useDragHandle={true}
                      hideGoal={this.props.hideGoal}
                    />}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  }
}

class UIColumnDraggableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onMenuClick = this.onMenuClick.bind(this)

  }

  onMenuClick(header, buttontext, thead) {
    this.props.onCogMenuClick({ header, buttontext, thead })
  }

  render() {
    const { i18n } = this.props;
    const LIColumn = item => {
      let style = {}
      if (this.props.columncount > 3) {
        style = { width: '24.7%' }
      }
      if (this.props.disabled || (!this.props.disabled && !item.isremove)) {
        return (
          <li style={style} data-colname='StoreRank' className='column'>
            <table className={'subrank ' + (item.default ? 'default-sort' : '')}>
              <thead>
                <tr className='subhd'>
                  <th>
                    {item.default ? <span onClick={() => this.onMenuClick(item.header, 'setdefaultsort', this.props.tableHeader)} 
                    className={(item.sortasc ? 'sort-asc' : 'sort-desc')}></span> : ''}
                    <DragColumnHeaderHandle header={item.header === 'Store' ? 'storeNumber' : item.header} />
                    {this.props.disabled ? '' :
                      <UITableDropDownWithTranslation
                        Items={[{ name: 'removecolumm', visible: true }, { name: 'setdefaultsort', visible: true }]}
                        disabled={false}
                        onItemClick={(e) => this.onMenuClick(item.header, e.target.attributes['alt'].nodeValue, this.props.tableHeader)}
                        className={'dropdown col-settings'}
                        toggleClassName='dropdown-toggle'
                        toggleButtonIcon='fa fa-cog'
                        onToggleDropdown={this.props.onToggleDropdown}
                      />}
                  </th>
                </tr>
              </thead>
              <tbody>{TRColumn(item.data)}</tbody>
            </table>
          </li>
        )
      } else {
        return ''
      }
    }

    const TRColumn = data => {
      return data.map((item, i) => {
        if (i % 2 === 0) {
          return (
            <tr>
              <td>{item}</td>
            </tr>
          );
        } else {
          return (
            <tr className='rshade'>
              <td>{item}</td>
            </tr>
          );
        }
      });
    };
    return LIColumn(this.props.columns);
  }
}

const UIColumnDraggable = compose(
  withTranslation()
)(UIColumnDraggableComponent);

class UIColumnSlotCont extends React.Component {

  render() {
    return (
      <li>
        <UITableDropDownWithTranslation
          Items={this.props.headers}
          disabled={this.props.disabled}
          className='dropdown add-column'
          toggleClassName='btn btn-secondary btn-sm dropdown-toggle'
          toggleButtonIcon='fa fa-plus'
          onItemClick={this.props.columnAddMenuClick}
          hideGoal={this.props.hideGoal}
          onToggleDropdown={this.props.onToggleDropdown}
        />
      </li>
    )
  }

}

const DragColumnHeaderHandle = SortableHandle(withTranslation()(({ header, style, clickHandle, t }) => (<a className='sort-link' onClick={clickHandle} style={style} title={t(translationKeysMap[header] || header)} >{t(translationKeysMap[header] || header)}</a>)));
const DragHandle = SortableHandle(withTranslation()(({ header, style, t }) => (<span className='theadspan' style={style} title={t(translationKeysMap[header] || header)}>{t(translationKeysMap[header] || header)}
  </span>)));

const SortableTableItem = SortableElement(({ idx, value, onRemove, onColumnSortEnd, onCogMenuClick, columnAddMenuClick, hideGoal }) => (
  <UITableDraggable
    header={value.header}
    data={value.columns}
    disabled={value.disabled}
    onRemove={() => onRemove(idx, value.header)}
    onColumnSortEnd={onColumnSortEnd}
    onCogMenuClick={onCogMenuClick}
    columnAddMenuClick={columnAddMenuClick}
    style={{ cursor: 'move' }}
    hideGoal={hideGoal}
  />
))

const SortableTableList = SortableContainer(({ items, onRemove, onColumnSortEnd, onCogMenuClick, columnAddMenuClick, hideGoal }) => {
  return (
    <ul className='sortable-tables ui-sortable'>
      {items.map((value, index) => (
        <SortableTableItem key={`item-${index}`} index={index} value={value}
          onRemove={onRemove}
          idx={index}
          onColumnSortEnd={onColumnSortEnd}
          onCogMenuClick={onCogMenuClick}
          columnAddMenuClick={columnAddMenuClick}
          hideGoal={hideGoal}
        />
      ))}
    </ul>
  );
});

const SortableItem = SortableElement(({ value, tableHeader, onCogMenuClick, onToggleDropdown }) => (
  <UIColumnDraggable columns={value} onCogMenuClick={onCogMenuClick} onToggleDropdown={onToggleDropdown} tableHeader={tableHeader} style={{ cursor: 'pointer' }}/>
));

const SortableList = SortableContainer(({ items, tableHeader, onRemove, onCogMenuClick, onToggleDropdown, hideGoal }) => {
  return (
    <ul className='sortable-cols ui-sortable'>
      {
      items.map((value, index) => {
        if (value.header == 'Goal%' && hideGoal === '1'){
          return ''
        }
        return (
        <SortableItem key={`item-${index}`}
          index={index}
          value={value}
          onCogMenuClick={onCogMenuClick}
          onToggleDropdown={onToggleDropdown}
          tableHeader={tableHeader}
          hideGoal={hideGoal} />
      )}
      )}
    </ul>
  );
});
