import React from 'react';

export const DeviceVersions = ({ devices }) => (
    <>
        {
            devices.map((device) => (
                <div className='hme-stores-status--partner__grid__inner-cell' key={device.Device_UID}>
                    {device.Device_MainVersion}
                </div>
            ))
        }
    </>
);
