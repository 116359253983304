/* eslint-disable react/display-name */
import React from 'react';
import { baseAuthService } from '../components/Security/AuthenticationService';
import exclusions from '../constants/subscriptionBasedExclusions/subscriptionBasedExclusions';

const excludedBySubscription = (name, exclusion = 'pages') => {
    const profile = baseAuthService.getProfile();
    const { SL: subscriptionLevel, Subscription_ID: subscriptionId } = profile || {};
    const subscription = subscriptionId || subscriptionLevel;
    const excluded = exclusions[exclusion][subscription] || [];
    return excluded.includes(name);
};

const filterBySubscription = ({ items = [], exclusion = 'pages', key }) => items.filter(
        (item) => {
            const name = key ? item[key] : item;
            return !excludedBySubscription(name, exclusion);
        }
);

export const withSubscriptionBasedExclusions = () => ( Component ) => ( props ) =>
    <Component
        {...props }
        excludedBySubscription={excludedBySubscription}
        filterBySubscription={filterBySubscription}
    />;
