import { Config } from 'Config';
import { DateLib } from '@hme-cloud/utility-common';

import { currentTimeZone } from '../Constants';

const prepareStartEndDates = ({ startDate, endDate, timeZone, scheduledTimePeriod }) => {
    const currentTimezoneOffset = DateLib.getTimezoneOffset(currentTimeZone);
    const selectedTzOffset = DateLib.getTimezoneOffset(timeZone.value);
    const sum = selectedTzOffset * -1 + currentTimezoneOffset;

    const StartDate = new DateLib(startDate)
        .startOfDay()
        .addHours(parseInt(scheduledTimePeriod.from, 10) || 0)
        .addMilliseconds(sum)
        .formatInTimeZone(DateLib.FORMAT_TYPES.DEFAULT_ISO_WITH_TIMEZONE_OFFSET, timeZone.value);

    const EndDate = new DateLib(endDate)
        .startOfDay()
        .addHours(parseInt(scheduledTimePeriod.to, 10) || 0)
        .addSeconds(-1)
        .addMilliseconds(sum)
        .formatInTimeZone(DateLib.FORMAT_TYPES.DEFAULT_ISO_WITH_TIMEZONE_OFFSET, timeZone.value);

    return {
        StartDate,
        EndDate
    };
};

export const prepareContestDTO = ({
    name,
    description,
    scheduledTimePeriod,
    recurrenceDays,
    contestStores,
    metric,
    metricDetails,
    reward,
    rewardImg,
    timeZone,
    endDate,
    startDate,
    language
}) => {
    return {
        ContestName: name,
        ContestDesc: description || null,
        ContestScheduleDayTimePeriods: scheduledTimePeriod.periods,
        ContestScheduleDays: recurrenceDays,
        ContestStatus: 0,
        ContestStores: contestStores,
        MasqueradeUser: null,
        Metric: metric,
        MetricDetail: metricDetails,
        RewardDesc: reward || null,
        RewardImage: rewardImg?.replace(`${Config.blobNitroStorageBaseUrl}/`, '') || null,
        TimeZone: timeZone.text,
        ZoneName: timeZone.value,
        language,
        ...prepareStartEndDates({ startDate, endDate, timeZone, scheduledTimePeriod })
    };
};
