import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent as Input } from 'components/Inputs';
import { SingleSelect as Select } from 'library/SingleSelect';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { useLoadAccounts } from '../hooks/useLoadAccounts';

import './FeedCreationForm.scss';

export const accountsResultHeaders = [
    {
        text: '',
        property: 'User_EmailAddress',
    },
];

export const FeedCreationForm = ({ brands, searchValue = '', withAccountSearch = false, formErrors, onFormChange }) => {
    const [serviceAccountName, setServiceAccountName] = useState('');
    const [brandID, setBrandID] = useState('');
    const [suggestedAccount, setSuggestedAccount] = useState(null);
    const [accountSearchValue, setAccountSearchValue] = useState(searchValue);
    const { t } = useTranslation();

    const { accounts, isAccountsLoading, loadAccountsDebounced } = useLoadAccounts({ search: accountSearchValue });

    useEffect(() => {
        if (accountSearchValue === suggestedAccount?.User_EmailAddress) {
            // cancel redundant request after the suggestion has been chosen
            loadAccountsDebounced.cancel();
        }

        // remove the suggested account if the search field has been cleared
        if (!accountSearchValue) {
            setSuggestedAccount(null);
        }
    }, [accountSearchValue]);

    const brandOptions = useMemo(() => brands.map(({ Id, Name }) => ({ value: Id, text: Name })), [brands]);

    useEffect(() => {
        onFormChange({
            serviceAccountName,
            brandID: +brandID,
            cloudAccountID: +suggestedAccount?.Account_ID,
        });
    }, [serviceAccountName, brandID, suggestedAccount]);

    useEffect(() => {
        if (suggestedAccount) {
            setAccountSearchValue(suggestedAccount.User_EmailAddress);
        }
    }, [suggestedAccount]);

    return (
        <div>
            <div className="hme-feed-form-section">
                <Input
                    value={serviceAccountName}
                    onChange={setServiceAccountName}
                    isRequired
                    label="common__service-account"
                    variants={['label-inside']}
                />
                {formErrors.serviceAccountName && <ErrorLabel>{t(formErrors.serviceAccountName)}</ErrorLabel>}
            </div>
            <div className="hme-feed-form-section">
                <Select
                    isRequired
                    variants={['label-inside']}
                    placeholder={t('common__select-item')}
                    value={+brandID}
                    label={t('common__brand')}
                    items={brandOptions}
                    onChange={setBrandID}
                />
                {formErrors.brandID && <ErrorLabel>{t(formErrors.brandID)}</ErrorLabel>}
            </div>
            {withAccountSearch && (
                <div className="hme-feed-form-section">
                    <AutoCompleteInput
                        isRequired
                        success={!!suggestedAccount}
                        value={accountSearchValue}
                        suggestions={accounts}
                        headers={accountsResultHeaders}
                        rowKey="Account_ID"
                        className="hme-account-input"
                        placeholder={t('common__account__email')}
                        isLoading={isAccountsLoading}
                        noSuggestionText={t('accounts__no-accounts')}
                        onChange={setAccountSearchValue}
                        onSuggested={setSuggestedAccount}
                    />
                    {formErrors.cloudAccountID && <ErrorLabel>{t(formErrors.cloudAccountID)}</ErrorLabel>}
                </div>
            )}
        </div>
    );
};
