import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileLogo } from '../MenuItem';

import HMECLOUDLogoAdmin from '../../../images/ProductLogo-2.png';

export const HOME_TAB_NAME = 'welcome';

const AdminDesktopLogo = () => <img height='20px' className='hidden-xxs-up' src={HMECLOUDLogoAdmin} alt='HME ADMIN Logo' />;
const AdminMobileLogo = () => <img height='20px' className='hidden-xxs-down' src={HMECLOUDLogoAdmin} alt='HME ADMIN Logo' />;

export const AdminTitle = ({ activeTab }) => {
    const { t } = useTranslation();

    const activeTabName = activeTab ? activeTab.name : HOME_TAB_NAME;

    const mobileLogo = activeTabName === HOME_TAB_NAME ? <AdminMobileLogo /> : <MobileLogo title={t(activeTab.tKey)} />;

    return <Fragment>
        <AdminDesktopLogo />
        {mobileLogo}
    </Fragment>;
};
