import * as headers from '../actionTypes/Headers/headers'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'

function setEmail (email) {
  return {
    type: headers.SET_USER_EMAIL,
    userEmail: email
  }
}

export const setUserEmail = (email) => {
  return (dispatch) => {
    dispatch(setEmail(email))
  }
}

export const setInviteResponseFromCloud = inviteResponseParams => {
  return {
    type: headers.SET_INVITE_RESPONSE,
    inviteResponseParams: inviteResponseParams
  };
};

export const inviteResponseFromCloud = (callBack) => {
  const api = new Api();
  const url = Config.apiBaseUrl + CommonConstants.apiUrls.inviteResponseFromCloud;
  return (dispatch, getState) => {
    const state = getState();
    // console.log("State: ", state)
    // if (!state.headers.inviteResponseParams.ParticipantID) {
    //   state.lbgroups.userEmail = "ParticipantID is required!";
    //   return;
    // }
    
      api.postData(url, state.headers.inviteResponseParams, result => {
        callBack(result);
      });
  };
};