const getValuesForSearch = (row, props) => props.map((prop) => row[prop]).map((value) => typeof value === 'string' ? value.toLowerCase() : value);

export const applySearchValue = (rows, props, searchValue, options = {}) => {
    const { minLength = 3 } = options;

    if (searchValue.length < minLength) {
        return rows;
    }

    const words = searchValue
        .split(' ')
        .filter((value) => value)
        .map(value => value.toLowerCase());

    return rows.filter((row) => {
        const values = getValuesForSearch(row, props).join(' ');

        return words.every((word) => values.includes(word));
    });
};
