import React from 'react';
import { Nav  } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { MenuIcon } from './MenuIcon';

export const MenuItem = ({ id, url, isActive = false, label, additionalClasses, icon }) => {
    const { t } = useTranslation();

    return (
        <Nav.Item className={classnames('justify', 'headerMenu', { active_tab: isActive }, additionalClasses)}>
            <Nav.Link href={url} id={id}>
                <MenuIcon icon={icon} additionalClasses='showOnMobile'/>
                {t(label)}
            </Nav.Link>
        </Nav.Item>
    )
};
