import { Translator } from 'library/Translator';

import { Type } from '../Type';
import { Actions } from '../Actions';

export const partnerToRow = (partner) => {
    const newPartner = {
        ...partner,
        types: <Type types={partner.types} />,
        owner: `${partner.FirstName} ${partner.LastName}`,
        status: <Translator tKey={partner.IsActive ? 'common__active' : 'common__inactive'} />,
        actions: <Actions uid={partner.PartnerUID} />,
        search_type: partner.types.map(({ name }) => name).join(' '),
    }

    newPartner.search_all = [
        newPartner.PartnerName,
        newPartner.search_type,
        newPartner.owner,
        newPartner.EmailAddress
    ].join(' ');

    return newPartner;
};
