import React, { useState, useEffect } from 'react';

import { ReactComponent as SortIcon } from '../../../../../assets/icons/sort.svg';
import classnames from 'classnames';
import './Sort.scss';

const defaultSortDirections = [0, 1, -1]
export const Sort = ({
    value,
    sortDirections = defaultSortDirections,
    children,
    onChange,
}) => {
    const getCurrentValue = value =>
        sortDirections.includes(value) ? value : sortDirections[0];
    const onClickHandler = () => {
        if (!sortDirections || !sortDirections.length) {
            return;
        }
        const index = sortDirections.indexOf(getCurrentValue(value));
        const nextValue = sortDirections[(index + 1) % sortDirections.length];
        onChange({ value: nextValue });
    }
    return (
        <div className='hme-grid-sort-component' onClick={onClickHandler}>
            {children}
            <SortIcon
                className={classnames('hme-grid-sort-cell-icon', {
                    'hme-grid-sort-hidden': (
                        !sortDirections || !sortDirections.length
                        || +getCurrentValue(value) === 0
                    ),
                    'hme-grid-sort-asc': +getCurrentValue(value) === 1,
                    'hme-grid-sort-desc': +getCurrentValue(value) === -1,
                })}
            />
        </div>
    );
};
