import { useMemo } from 'react';

import { isPartner } from 'services/Auth';
import { publicRules, partnerRules, isValidStatus, isInvalidStatus } from './validationRules';

export const useResetPasswordRules = ({ newPassword, confirmNewPassword }) => {
    const rules = useMemo(()=> isPartner() ? partnerRules : publicRules,[isPartner])

    const isAllValid = useMemo(
            () => rules.every(({ rule }) => isValidStatus(rule(newPassword, confirmNewPassword))),
            [newPassword, confirmNewPassword]
    );

    const isSomeInvalid = useMemo(
            () => rules.some(({ rule }) => isInvalidStatus(rule(newPassword, confirmNewPassword))),
            [newPassword, confirmNewPassword]
    );

    return {
        isAllValid,
        isSomeInvalid
    };
};
