import React from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CommonConstants } from 'Constants';
import { AccountComponent } from '../Account';
import { withAccess } from 'HOCs/withAccess';
import { checkBaseAccessForAccounts } from 'helpers/distributor';
import { TabPanel, Tab } from 'library/TabPanel';
import { withHMELayout } from 'HOCs/withHMELayout';
import { useAccess } from 'hooks/useAccess';
import authenticate from 'components/Security/Authentication';
import { Users } from './Users';
import { DistributorsRoles } from './DistributorsRoles/DistributorsRoles';

import { useDistributorsAccount } from './hooks/useDistributorsAccount';

import './DistributorsAccount.scss';

const DistributorsAccountComponent = () => {
    const {
        activeTab,
        onActiveTabChange,
        addUserRedirectionURL,
        editUserRedirectionURL,
        accountUuid
    } = useDistributorsAccount();
    const { t } = useTranslation();
    const hasUserPermission = useAccess(CommonConstants.externalPermissions.ManageUsers);
    const hasRolePermission = useAccess(CommonConstants.externalPermissions.ManageRoles);

    return (
        <div className={classNames('hme-distributors-account',
            {
                'account-page': activeTab === 'account',
                'hme-distributors-account--users': activeTab === 'users',
            }
        )}>
            <TabPanel
                activeTab={activeTab}
                onActiveTabChange={onActiveTabChange}
                variants={['subheader']}
            >
                <Tab text={t('admin__sub-header__account')} value="account">
                    <AccountComponent isDistributorMode={true} />
                </Tab>
                <Tab text={t('common__users')} value="users" hidden={!hasUserPermission}>
                    <Users
                        addUserRedirectionURL={addUserRedirectionURL}
                        editUserRedirectionURL={editUserRedirectionURL}
                        accountUuid={accountUuid}
                    />
                </Tab>
                <Tab text={t('common__roles')} value="roles" hidden={!hasRolePermission}>
                    <DistributorsRoles accountUuid={accountUuid} />
                </Tab>
            </TabPanel>
        </div>
    );
};

const hasAccess = checkBaseAccessForAccounts();

export const DistributorsAccount = compose(
    withHMELayout(),
    authenticate,
    withAccess({
        checkAccess: () => hasAccess
    })
)(DistributorsAccountComponent);
