import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import './Label.scss';

export const Label = cond([
    [
        ({ children }) => children,
        ({ className, children, as: Tag = 'div', ...props }) => (
            <Tag className={classNames('hme-label', className)} {...props}>{children}</Tag>
        )
    ],
    [T, () => null]
]);
