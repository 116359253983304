import { format as libFormat } from 'date-fns';
import * as locales from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions'

// If you are going to add new format please check previous ones
// and if you can fild similar please contact your PO
// to check consistency of date formats
export const FORMAT_TYPES = {
    DATE_AND_TIME: 'MM/dd/yy h:mm a',
};

const lngs = {
    'en-US': locales.enUS,
    'de': locales.de,
    'es': locales.es,
    'es-LA': locales.es,
    'fr': locales.fr,
    'fr-CA': locales.frCA,
    'it': locales.it,
    'ja': locales.ja,
    'pl': locales.pl,
    'pt-BR': locales.ptBR,
    'zh-TW': locales.zhTW,
};

export const setLocale = (lng) => {
    setDefaultOptions({
        locale: lngs[lng] || lngs['en-US'],
    });
};

export const format = (formatType, date = new Date()) => {
    // If it is null or empty string
    if (!date && date !== 0) {
        return '';
    }

    try {
        date = date instanceof Date ? date : new Date(date);

        return libFormat(date, formatType);
    } catch (err) {
        console.log(err);

        return '';
    }
};
