import React from 'react';

import { ViewEdit } from '../Common';
import { useCreateUser } from './hooks/useCreateUser';

export const DistributorCreateUser = () => {
    const state = useCreateUser();

    return (
        <ViewEdit {...state} />
    );
};
