import React, { Component } from 'react'
import './Stores.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import AuthenticationService from '../Security/AuthenticationService'
import { Config } from '../../Config'
import Unregistersystem from '../Actions/Unregistersystem'
import NavigationService from '../Common/NavigationServices'
import CommonFunctions from '../../components/Common/Functions'
import CommonStoreMethods from './CommonStoreMethod'
import { checkAccess } from '../../actions/permissions'
import { CommonConstants } from '../../Constants'
import DeviceStatusImage from './DeviceStatusImage'

class CIBComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showStores: this.props.showStores,
      disableRemove: true,
      deviceIDs: [],
      deviceName: 'CIB'
    }
    this.navigate = new NavigationService()
    this.commonFunctions = new CommonFunctions()
    this.CommonStoreMethods = new CommonStoreMethods()
    this.authService = new AuthenticationService(Config.authBaseUrl)
    this.enableRemoveBtn = this.enableRemoveBtn.bind(this)
    this.handleViewDevice = this.handleViewDevice.bind(this)
    this.checkRemotePermission = this.checkRemotePermission.bind(this)
    this.renderRows = this.renderRows.bind(this)
  }
  // Checks for the enabling the remove system button.
  enableRemoveBtn (e, deviceId, active) {
    const disableRemove = this.commonFunctions.enableRemoveBtn(e, deviceId, active)
    const deviceIDs = this.state.deviceIDs
    const input = {
      isChecked: e.currentTarget.checked,
      device_ID: deviceId,
      isActive: active
    }
    deviceIDs.push(input)
    this.setState({
      disableRemove,
      deviceIDs,
    })
  }
  handleViewDevice (id) {
    this.props.viewDevice(id)
  }

  checkRemotePermission (data) {
    const isActive = data.Device_IsActive === 1;
    return (
      <td className='deviceStatusText'>
        <DeviceStatusImage isActive={isActive} />
        <span>
          {this.renderStatus(data)}
        </span>
      </td>
    )
  }

  // Checking the device status and remote connect permission and the Device_Name CIB.
  renderStatus (device) {
    const isRemoteConnectionPermission = checkAccess(CommonConstants.userPermissions.RemoteConnect);
    return this.CommonStoreMethods.renderStatus(device, this.authService.isAdmin() && isRemoteConnectionPermission);
  }

  // Renders the data.
  renderRows () {
    const { t } = this.props;
    let row = this.props.stores.Device_Details
    row = row.filter(function (el) {
      return el.Device_Name !== 'EOS' && el.Device_Name !== 'ION' && el.Device_Name !== 'ZOOM'
    })
    let rows = row.map((data, index) => {
      return (
        <tr className='device-row-data'>
          <td className='device-checkbox'>
            <input type='checkbox' name='checkbox' id='idname' className={'device-check-input ' + (this.authService.isAdmin() ? 'show' : 'hide')}
              value={data.Device_ID} onChange={e => this.enableRemoveBtn(e, data.Device_ID, data.Device_IsActive)} />
          </td>
          <td >{data.Device_SettingVersion}</td>
          <td >{data.Device_SerialNumber}</td>
          {this.checkRemotePermission(data)}
          <td onClick={() => this.handleViewDevice(data.Device_UID)}><a className='device-view-link'> {t('stores__modal__view-details')} </a> </td>
        </tr>
      )
    })
    return rows
  }
  // Main render function.
  render () {
    const { t } = this.props;

    return (
      <div>
        <div>
          <h4 className='cib_header'>{t('stores__modal__cib-settings')}</h4>
          <table className='user_form'>
            <tbody>
              <tr>
                <th><label htmlFor='Store_Name'>{t('common__store__number--text')}:</label></th>
                <td><input type='text' maxLength='100' name='Store_Name' id='Store_Name' value={this.props.stores.storeDetails.Store_Number} disabled='disabled' />
                  <input type='hidden' name='Store_ID' id='Store_ID' value='112480' />
                </td>
              </tr>
              <tr>
                <th><label htmlFor='Store_Name'> {t('common__email')}:</label></th>
                <td><input type='text' maxLength='100' name='Store_Name' id='Store_Name' value={this.props.stores.storeDetails.User_EmailAddress} disabled='disabled' />
                  <input type='hidden' name='Store_ID' id='Store_ID' value='112480' />
                </td>
              </tr>
              <tr>
                <th><label htmlFor='Store_Name'> {t('common__brand')}:</label></th>
                <td> <select name='Store_Brand_ID' id='Store_Brand_ID' disabled='disabled'>
                  <option value="Checker's" selected='selected'>{this.props.stores.storeDetails.Brand_Name}</option>
                </select>
                </td>
              </tr>
              <tr>
                <th><label htmlFor='Store_Name'> {t('common__timezone')}</label></th>
                <td> <select name='Store_Brand_ID' id='Store_Brand_ID' disabled='disabled'>
                  <option value="Checker's" selected='selected'>{this.props.stores.storeDetails.Timezone}</option>
                </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 className='cib_header'>{t('common__device--registered', { deviceType: t('common__device__type--cib') })}</h4>
        <table className='device-list-table'>
          <tbody>
            {this.CommonStoreMethods.renderDeviceListHeaders()}
            {this.renderRows()}
          </tbody>
        </table>
        <div className={'remove-sys ' + (this.authService.isAdmin() && checkAccess(CommonConstants.adminPermissions.RemoveDevice) ? 'show' : 'hide')} >
          {/* Rendering the Unregistersystem Component */}
          <Unregistersystem
            userEmail={this.props.stores.storeDetails.User_EmailAddress}
            system={this.props.stores.Device_Details}
            deviceList={this.commonFunctions.removeDuplicateDevices(this.state.deviceIDs)}
            display={this.state.disableRemove}
            deviceName={this.state.deviceName}
            deviceStoreUID={this.props.stores.storeDetails.Store_UID} />
        </div >
      </div >
    )
  }
}
// Setting the state from redux store.
function mapStateToProps (state) {
  return {
    stores: state.StorePopupDetails.storePopupDetails
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(CIBComponent);
