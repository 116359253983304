import { Constant } from '@hme-cloud/utility-common';
import { DeviceUpgradeStatuses } from 'constants/NEXEOUpgradeStatuses';
import { upgradeStatuses } from 'constants/upgradeStatuses';

const { ScheduledUpgrade } = Constant;

export const checkIsScheduledUpgrade = ({ deviceScheduledUpgrade, currentUpgradeTaskStatus }) => {
    if (!deviceScheduledUpgrade) return false;

    // Pending_Approval won't yet have an upgradeTaskStatus. So return true if in this state.
    if (deviceScheduledUpgrade.DeviceUpgradeStatus === upgradeStatuses.PENDING_APPROVAL) {
        return true;
    }

    return (
        ScheduledUpgrade.IN_PROGRESS_STATUSES.includes(deviceScheduledUpgrade.DeviceUpgradeStatus) &&
        !( // Handle status between the hour nexeo completes download and it being updated in the DeviceUpgrade table
            currentUpgradeTaskStatus >= DeviceUpgradeStatuses.DOWNLOAD_FIRMWARE_COMPLETED &&
            currentUpgradeTaskStatus <= DeviceUpgradeStatuses.FIRMWARE_UPDATE_COMPLETED &&
            deviceScheduledUpgrade.DeviceUpgradeStatus !== upgradeStatuses.SCHEDULED
        ) &&
        !(
            deviceScheduledUpgrade.DeviceUpgradeStatus == upgradeStatuses.FAILED &&
            deviceScheduledUpgrade.RetryCount >= 3
        )
    );
};
