import { CommonConstants } from 'Constants';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

const { deviceType } = CommonConstants;

const IDS_SEPARATOR = ':';

export const deviceTypeIdGridKeyMap = {
    [deviceType.zoomNitro.id]: 'zoomNitro',
    [deviceType.nexeo.id]: 'nexeo',
    [NEXEO_PERIPHERAL_TYPES.IB7000.deviceTypeID]: 'IB7000',
};

// generate grid data format
const transformDeviceTypeVersionsToRows = (deviceTypeVersionsIds, deviceTypeVersionsIdsMap) => {
    if (!deviceTypeVersionsIds || !Object.keys(deviceTypeVersionsIds).length) {
        return [];
    }

    // max amount of removed versions for one device type
    const maxTotalDeviceTypeVersions = Math.max(
        ...Object.keys(deviceTypeVersionsIds).map((key) => deviceTypeVersionsIds[key].length),
    );

    return Array(maxTotalDeviceTypeVersions)
        .fill()
        .reduce((acc, _, index) => {
            // fill object with device type key and version semver number value
            const newItem = Object.keys(deviceTypeVersionsIds).reduce(
                (res, key) => ({
                    ...res,
                    [deviceTypeIdGridKeyMap[key]]: deviceTypeVersionsIdsMap[deviceTypeVersionsIds[key][index]] || '',
                }),
                {},
            );

            return [...acc, newItem];
        }, []);
};

// format flat objects with "deviceTypeID:versionID" keys and "selected" field values
const formatDeviceTypeUpgradeVersionsMap = (deviceTypeUpgradeVersions) =>
    deviceTypeUpgradeVersions.reduce((acc, curr) => {
        curr.versions.forEach((version) => {
            acc[`${curr.deviceTypeID}${IDS_SEPARATOR}${version.id}`] = version.selected;
        });

        return acc;
    }, {});

// format nested objects with "deviceTypeID" key and versions array values
const formatDeviceTypeUpgradeVersionsIds = (deviceTypeUpgradeVersions) =>
    deviceTypeUpgradeVersions.reduce((acc, key) => {
        const [deviceTypeID, versionID] = key.split(IDS_SEPARATOR);

        return {
            ...acc,
            [deviceTypeID]: acc[deviceTypeID] ? [...acc[deviceTypeID], versionID] : [versionID],
        };
    }, {});

const formatDeviceTypeUpgradeVersionsIdsMap = (deviceTypeUpgradeVersions) =>
    deviceTypeUpgradeVersions.reduce((acc, item) => {
        (item.versions || []).forEach(({ id, version }) => {
            acc[id] = version;
        });

        return acc;
    }, {});

export const formatAddAndRemoveVersions = (prevDeviceUpgradeVersions, currDeviceUpgradeVersions) => {
    // format hierarchical data into flat structure
    const prevDeviceUpgradeVersionsMap = formatDeviceTypeUpgradeVersionsMap(prevDeviceUpgradeVersions);
    const currDeviceUpgradeVersionsMap = formatDeviceTypeUpgradeVersionsMap(currDeviceUpgradeVersions);

    // filter upgrade versions for the adding and removing operations
    const deviceUpgradeVersionsToAdd = Object.keys(prevDeviceUpgradeVersionsMap).filter(
        (key) => !prevDeviceUpgradeVersionsMap[key] && currDeviceUpgradeVersionsMap[key],
    );

    const deviceUpgradeVersionsToRemove = Object.keys(prevDeviceUpgradeVersionsMap).filter(
        (key) => prevDeviceUpgradeVersionsMap[key] && !currDeviceUpgradeVersionsMap[key],
    );

    return {
        deviceUpgradeVersionsToAdd,
        deviceUpgradeVersionsToRemove,
    };
};

export const formatAddAndRemoveVersionRows = (prevDeviceUpgradeVersions, currDeviceUpgradeVersions) => {
    const { deviceUpgradeVersionsToAdd, deviceUpgradeVersionsToRemove } = formatAddAndRemoveVersions(
        prevDeviceUpgradeVersions,
        currDeviceUpgradeVersions,
    );

    // format back filtered flat structure into hierarchical one
    const deviceTypeVersionsToAddIds = formatDeviceTypeUpgradeVersionsIds(deviceUpgradeVersionsToAdd);
    const deviceTypeVersionsToRemoveIds = formatDeviceTypeUpgradeVersionsIds(deviceUpgradeVersionsToRemove);

    const deviceTypeVersionsIdsMap = formatDeviceTypeUpgradeVersionsIdsMap(currDeviceUpgradeVersions);

    // reformat hierarchical structure into grid rows format
    return {
        addUpgradeVersionsRows: transformDeviceTypeVersionsToRows(deviceTypeVersionsToAddIds, deviceTypeVersionsIdsMap),
        removeUpgradeVersionsRows: transformDeviceTypeVersionsToRows(
            deviceTypeVersionsToRemoveIds,
            deviceTypeVersionsIdsMap,
        ),
    };
};

const mapAllVersionsApprovedByDeviceType = (deviceUpgradeVersions) =>
    deviceUpgradeVersions.reduce(
        (acc, { deviceTypeID, allVersionsApproved }) => ({
            ...acc,
            [deviceTypeID]: allVersionsApproved,
        }),
        {},
    );

export const checkAllApprovedVersionsForChanges = (prevDeviceUpgradeVersions, currDeviceUpgradeVersions) => {
    const prevDeviceUpgradeVersionsApproved = mapAllVersionsApprovedByDeviceType(prevDeviceUpgradeVersions);
    const currDeviceUpgradeVersionsApproved = mapAllVersionsApprovedByDeviceType(currDeviceUpgradeVersions);

    return Object.keys(prevDeviceUpgradeVersionsApproved).some(
        (key) => prevDeviceUpgradeVersionsApproved[key] !== currDeviceUpgradeVersionsApproved[key],
    );
};

export const checkVersionsForChanges = (prevDeviceUpgradeVersions, currDeviceUpgradeVersions) => {
    const hasAllApprovedChanged = checkAllApprovedVersionsForChanges(
        prevDeviceUpgradeVersions,
        currDeviceUpgradeVersions,
    );

    const { deviceUpgradeVersionsToAdd, deviceUpgradeVersionsToRemove } = formatAddAndRemoveVersions(
        prevDeviceUpgradeVersions,
        currDeviceUpgradeVersions,
    );

    return !!(hasAllApprovedChanged || deviceUpgradeVersionsToAdd.length || deviceUpgradeVersionsToRemove.length);
};

export const formatAddUpgradeVersionsRowsWithAllApproved = (
    addUpgradeVersionsRows,
    initialAllVersionsApprovedMap,
    allVersionsApprovedMap,
    allApprovedLabel,
) => {
    // handle the case where the individual upgrade versions have not been selected
    if (!addUpgradeVersionsRows.length) {
        // format the rows with the 'all versions approved' label
        return [
            Object.keys(allVersionsApprovedMap).reduce((acc, key) => {
                const isAllApproved = allVersionsApprovedMap[key] && !initialAllVersionsApprovedMap[key];
                const upgradeVersionRow = {
                    ...acc,
                    [deviceTypeIdGridKeyMap[key]]: isAllApproved ? allApprovedLabel : '',
                };

                // filter out the rows with empty values
                return Object.values(upgradeVersionRow).some((value) => value) ? upgradeVersionRow : null;
            }, {}),
        ].filter((item) => item);
    }

    // handle the case with the selected individual upgrade versions
    return addUpgradeVersionsRows
        .map((item, index) => {
            // format the rows with the 'all versions approved' label
            return Object.keys(allVersionsApprovedMap).reduce((acc, key) => {
                // show the label for the first row only
                const allVersionsApprovedLabel = index ? '' : allApprovedLabel;
                const isAllApproved = allVersionsApprovedMap[key] && !initialAllVersionsApprovedMap[key];

                // if the all versions approved is true, show the label
                // otherwise show the version number
                const upgradeVersionRow = {
                    ...acc,
                    [deviceTypeIdGridKeyMap[key]]: isAllApproved
                        ? allVersionsApprovedLabel
                        : item[deviceTypeIdGridKeyMap[key]] || '',
                };

                // filter out the rows with empty values
                return Object.values(upgradeVersionRow).some((value) => value) ? upgradeVersionRow : null;
            }, {});
        })
        .filter((item) => item);
};

export const formatRemoveUpgradeVersionsRowsWithAllApproved = (
    removeUpgradeVersionsRows,
    initialAllVersionsApprovedMap,
    allVersionsApprovedMap,
    allApprovedLabel
) => {
    // handle the case where the individual upgrade versions have not been selected
    if (!removeUpgradeVersionsRows.length) {
        // format the rows with the 'all versions approved' label
        return [
            Object.keys(allVersionsApprovedMap).reduce((acc, key) => {
                const isNoneApproved = !allVersionsApprovedMap[key] && initialAllVersionsApprovedMap[key];
                const upgradeVersionRow = {
                    ...acc,
                    [deviceTypeIdGridKeyMap[key]]: isNoneApproved ? allApprovedLabel : '',
                };

                // filter out the rows with empty values
                return Object.values(upgradeVersionRow).some((value) => value) ? upgradeVersionRow : null;
            }, {}),
        ].filter((item) => item);
    }

    // handle the case with the selected individual upgrade versions
    return removeUpgradeVersionsRows
        .map((item) => {
            // format the rows with the 'all versions approved' label
            return Object.keys(allVersionsApprovedMap).reduce((acc, key) => {
                const isNoneApproved = !allVersionsApprovedMap[key] && initialAllVersionsApprovedMap[key];
                // if the 'all versions approved' is true, show the empty value
                // otherwise show the version number
                const upgradeVersionRow = {
                    ...acc,
                    [deviceTypeIdGridKeyMap[key]]: isNoneApproved ? allApprovedLabel : item[deviceTypeIdGridKeyMap[key]] || '',
                };

                // filter out the rows with empty values
                return Object.values(upgradeVersionRow).some((value) => value) ? upgradeVersionRow : null;
            }, {});
        })
        .filter((item) => item);
};
